<template>
  <div class="relative mapbox relative">
    <div class="absolute flex flex-col items-center group group1">
      <img class="pin1" src="@/assets/images/landingpage/mappin3.png" alt="map pin">
      <svg class="dot" width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="4.02734" cy="4.52686" r="4" fill="#00A859"/>
      </svg>
    </div>
    <div class="absolute flex flex-col items-center group group2">
      <img class="pin2" src="@/assets/images/landingpage/mappin2.png" alt="map pin">
      <svg class="dot" width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="4.02734" cy="4.52686" r="4" fill="#00A859"/>
      </svg>
    </div>
    <div class="absolute flex flex-col items-center group group3">
      <img class="pin3" src="@/assets/images/landingpage/mappin1.png" alt="map pin">
      <svg class="dot" width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="4.02734" cy="4.52686" r="4" fill="#00A859"/>
      </svg>
    </div>
    
    <svg class="mobilemap block md:hidden absolute left-0 top-0 w-full" width="375" height="341" viewBox="0 0 375 341" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M199.379 338.75C199.426 339.908 200.632 340.86 202.075 340.876C203.519 340.892 204.645 339.956 204.598 338.798C204.55 337.64 203.344 336.672 201.901 336.672C200.458 336.641 199.331 337.577 199.379 338.75Z" fill="#DEDEDE"/>
    <path d="M223.95 332.39C224.013 333.563 225.235 334.531 226.662 334.547C228.106 334.563 229.216 333.627 229.137 332.453C229.073 331.279 227.852 330.296 226.424 330.28C224.981 330.28 223.886 331.216 223.95 332.39Z" fill="#DEDEDE"/>
    <path d="M215.67 332.295C215.733 333.468 216.939 334.436 218.382 334.452C219.826 334.468 220.936 333.532 220.873 332.358C220.809 331.184 219.604 330.217 218.16 330.185C216.733 330.169 215.607 331.121 215.67 332.295Z" fill="#DEDEDE"/>
    <path d="M207.39 332.199C207.437 333.373 208.643 334.341 210.086 334.356C211.53 334.372 212.64 333.436 212.592 332.263C212.529 331.089 211.323 330.121 209.896 330.105C208.452 330.074 207.342 331.025 207.39 332.199Z" fill="#DEDEDE"/>
    <path d="M199.11 332.135C199.157 333.309 200.363 334.277 201.806 334.277C203.25 334.293 204.376 333.357 204.313 332.167C204.265 330.993 203.06 330.026 201.616 330.01C200.189 330.01 199.062 330.946 199.11 332.135Z" fill="#DEDEDE"/>
    <path d="M190.83 332.072C190.878 333.246 192.067 334.198 193.511 334.214C194.954 334.23 196.08 333.278 196.033 332.104C195.985 330.93 194.78 329.963 193.352 329.947C191.925 329.947 190.782 330.899 190.83 332.072Z" fill="#DEDEDE"/>
    <path d="M182.549 332.041C182.581 333.214 183.787 334.166 185.214 334.182C186.658 334.182 187.784 333.246 187.752 332.072C187.72 330.899 186.515 329.931 185.087 329.931C183.644 329.899 182.502 330.851 182.549 332.041Z" fill="#DEDEDE"/>
    <path d="M223.569 325.712C223.632 326.901 224.854 327.885 226.281 327.901C227.709 327.917 228.819 326.981 228.74 325.791C228.661 324.601 227.455 323.618 226.028 323.602C224.6 323.57 223.49 324.522 223.569 325.712Z" fill="#DEDEDE"/>
    <path d="M215.321 325.6C215.384 326.79 216.59 327.758 218.018 327.773C219.445 327.789 220.555 326.853 220.492 325.664C220.429 324.474 219.207 323.491 217.78 323.475C216.368 323.459 215.258 324.411 215.321 325.6Z" fill="#DEDEDE"/>
    <path d="M207.088 325.521C207.151 326.711 208.357 327.679 209.785 327.694C211.212 327.71 212.323 326.759 212.275 325.569C212.212 324.379 211.006 323.396 209.578 323.38C208.135 323.364 207.025 324.332 207.088 325.521Z" fill="#DEDEDE"/>
    <path d="M198.84 325.442C198.887 326.632 200.093 327.599 201.521 327.615C202.948 327.631 204.074 326.679 204.011 325.49C203.963 324.3 202.758 323.317 201.33 323.317C199.903 323.301 198.792 324.252 198.84 325.442Z" fill="#DEDEDE"/>
    <path d="M190.592 325.394C190.639 326.584 191.829 327.552 193.272 327.552C194.7 327.567 195.826 326.616 195.779 325.426C195.731 324.236 194.526 323.269 193.098 323.253C191.67 323.237 190.544 324.205 190.592 325.394Z" fill="#DEDEDE"/>
    <path d="M231.357 319.065C231.436 320.271 232.658 321.254 234.07 321.286C235.497 321.302 236.576 320.366 236.496 319.161C236.417 317.955 235.196 316.956 233.768 316.94C232.356 316.908 231.278 317.86 231.357 319.065Z" fill="#DEDEDE"/>
    <path d="M223.156 318.954C223.236 320.16 224.441 321.143 225.869 321.159C227.296 321.175 228.391 320.223 228.312 319.018C228.232 317.812 227.027 316.829 225.599 316.797C224.187 316.781 223.093 317.749 223.156 318.954Z" fill="#DEDEDE"/>
    <path d="M214.956 318.843C215.02 320.049 216.225 321.032 217.653 321.048C219.08 321.064 220.175 320.112 220.111 318.907C220.032 317.701 218.827 316.718 217.415 316.702C215.987 316.686 214.893 317.638 214.956 318.843Z" fill="#DEDEDE"/>
    <path d="M206.756 318.764C206.819 319.969 208.025 320.953 209.452 320.969C210.88 320.984 211.99 320.033 211.911 318.827C211.847 317.622 210.642 316.638 209.214 316.622C207.802 316.591 206.692 317.558 206.756 318.764Z" fill="#DEDEDE"/>
    <path d="M198.539 318.685C198.586 319.89 199.792 320.858 201.219 320.874C202.647 320.89 203.757 319.922 203.694 318.717C203.63 317.511 202.441 316.528 201.013 316.512C199.601 316.512 198.491 317.479 198.539 318.685Z" fill="#DEDEDE"/>
    <path d="M190.322 318.637C190.369 319.843 191.559 320.81 192.987 320.826C194.414 320.842 195.525 319.874 195.477 318.669C195.429 317.463 194.224 316.48 192.812 316.48C191.4 316.464 190.274 317.432 190.322 318.637Z" fill="#DEDEDE"/>
    <path d="M182.105 318.589C182.137 319.795 183.327 320.762 184.77 320.778C186.198 320.778 187.324 319.827 187.276 318.621C187.229 317.416 186.039 316.432 184.611 316.432C183.184 316.416 182.073 317.384 182.105 318.589Z" fill="#DEDEDE"/>
    <path d="M173.889 318.573C173.921 319.779 175.111 320.747 176.538 320.747C177.966 320.747 179.092 319.779 179.06 318.589C179.029 317.384 177.839 316.4 176.411 316.4C174.984 316.4 173.858 317.368 173.889 318.573Z" fill="#DEDEDE"/>
    <path d="M279.689 313.292C279.816 314.513 281.053 315.512 282.465 315.56C283.877 315.592 284.908 314.656 284.781 313.434C284.654 312.229 283.401 311.198 282.005 311.166C280.609 311.118 279.562 312.07 279.689 313.292Z" fill="#DEDEDE"/>
    <path d="M230.897 312.245C230.976 313.45 232.197 314.449 233.609 314.481C235.021 314.497 236.1 313.545 236.02 312.324C235.925 311.118 234.72 310.103 233.308 310.087C231.88 310.056 230.817 311.023 230.897 312.245Z" fill="#DEDEDE"/>
    <path d="M222.744 312.118C222.824 313.323 224.029 314.323 225.457 314.339C226.868 314.354 227.963 313.403 227.884 312.181C227.804 310.976 226.583 309.961 225.171 309.945C223.744 309.945 222.665 310.912 222.744 312.118Z" fill="#DEDEDE"/>
    <path d="M214.575 312.023C214.638 313.228 215.844 314.227 217.271 314.243C218.683 314.259 219.778 313.292 219.698 312.086C219.619 310.865 218.414 309.865 217.002 309.849C215.59 309.834 214.511 310.801 214.575 312.023Z" fill="#DEDEDE"/>
    <path d="M206.407 311.928C206.47 313.133 207.676 314.132 209.088 314.148C210.499 314.164 211.61 313.197 211.53 311.975C211.467 310.77 210.261 309.77 208.85 309.754C207.438 309.739 206.343 310.722 206.407 311.928Z" fill="#DEDEDE"/>
    <path d="M198.237 311.864C198.3 313.069 199.49 314.069 200.902 314.085C202.329 314.101 203.424 313.133 203.36 311.912C203.297 310.706 202.107 309.707 200.68 309.691C199.284 309.675 198.174 310.643 198.237 311.864Z" fill="#DEDEDE"/>
    <path d="M190.053 311.816C190.1 313.022 191.29 314.021 192.718 314.021C194.145 314.037 195.24 313.053 195.192 311.848C195.144 310.642 193.939 309.643 192.527 309.627C191.115 309.611 190.005 310.595 190.053 311.816Z" fill="#DEDEDE"/>
    <path d="M181.884 311.769C181.931 312.974 183.105 313.957 184.533 313.973C185.96 313.973 187.071 313.006 187.023 311.8C186.976 310.595 185.786 309.595 184.374 309.595C182.947 309.58 181.836 310.563 181.884 311.769Z" fill="#DEDEDE"/>
    <path d="M173.698 311.753C173.73 312.958 174.92 313.942 176.332 313.958C177.759 313.958 178.869 312.99 178.838 311.769C178.79 310.563 177.616 309.564 176.205 309.564C174.777 309.548 173.667 310.532 173.698 311.753Z" fill="#DEDEDE"/>
    <path d="M287.017 306.63C287.16 307.851 288.413 308.882 289.809 308.914C291.205 308.961 292.22 307.994 292.077 306.772C291.935 305.551 290.681 304.52 289.286 304.472C287.89 304.441 286.875 305.408 287.017 306.63Z" fill="#DEDEDE"/>
    <path d="M278.959 306.392C279.102 307.613 280.339 308.628 281.735 308.676C283.131 308.707 284.162 307.756 284.019 306.534C283.876 305.313 282.639 304.282 281.243 304.25C279.847 304.203 278.816 305.17 278.959 306.392Z" fill="#DEDEDE"/>
    <path d="M238.511 305.487C238.606 306.708 239.827 307.724 241.239 307.74C242.651 307.771 243.714 306.804 243.603 305.566C243.507 304.345 242.286 303.33 240.874 303.298C239.478 303.282 238.416 304.266 238.511 305.487Z" fill="#DEDEDE"/>
    <path d="M230.406 305.36C230.501 306.582 231.707 307.597 233.119 307.613C234.53 307.629 235.593 306.661 235.498 305.44C235.403 304.218 234.181 303.203 232.786 303.187C231.39 303.156 230.311 304.123 230.406 305.36Z" fill="#DEDEDE"/>
    <path d="M222.284 305.234C222.364 306.455 223.569 307.455 224.981 307.486C226.393 307.502 227.471 306.535 227.376 305.313C227.297 304.092 226.075 303.077 224.68 303.061C223.284 303.029 222.205 304.013 222.284 305.234Z" fill="#DEDEDE"/>
    <path d="M214.163 305.138C214.242 306.36 215.432 307.359 216.844 307.375C218.255 307.391 219.334 306.423 219.255 305.202C219.175 303.98 217.97 302.965 216.558 302.949C215.178 302.934 214.099 303.917 214.163 305.138Z" fill="#DEDEDE"/>
    <path d="M206.041 305.043C206.105 306.265 207.31 307.264 208.722 307.28C210.134 307.296 211.228 306.312 211.149 305.091C211.085 303.869 209.88 302.854 208.468 302.838C207.056 302.838 205.978 303.822 206.041 305.043Z" fill="#DEDEDE"/>
    <path d="M197.904 304.98C197.968 306.201 199.157 307.201 200.569 307.217C201.981 307.232 203.075 306.249 203.012 305.028C202.948 303.806 201.759 302.807 200.347 302.791C198.951 302.775 197.857 303.759 197.904 304.98Z" fill="#DEDEDE"/>
    <path d="M189.767 304.916C189.815 306.138 191.004 307.137 192.416 307.137C193.828 307.153 194.938 306.169 194.875 304.948C194.811 303.727 193.637 302.727 192.226 302.711C190.83 302.711 189.72 303.695 189.767 304.916Z" fill="#DEDEDE"/>
    <path d="M181.629 304.885C181.677 306.106 182.85 307.106 184.262 307.106C185.674 307.106 186.784 306.122 186.737 304.901C186.689 303.679 185.499 302.68 184.104 302.664C182.692 302.68 181.597 303.664 181.629 304.885Z" fill="#DEDEDE"/>
    <path d="M173.493 304.869C173.525 306.09 174.698 307.09 176.126 307.09C177.538 307.09 178.648 306.106 178.616 304.885C178.569 303.663 177.395 302.664 175.983 302.664C174.571 302.648 173.461 303.647 173.493 304.869Z" fill="#DEDEDE"/>
    <path d="M286.193 299.682C286.336 300.92 287.589 301.951 288.984 301.982C290.38 302.03 291.38 301.062 291.221 299.825C291.062 298.588 289.809 297.557 288.429 297.509C287.049 297.477 286.05 298.445 286.193 299.682Z" fill="#DEDEDE"/>
    <path d="M278.182 299.444C278.325 300.681 279.562 301.712 280.958 301.744C282.354 301.776 283.369 300.824 283.21 299.587C283.068 298.349 281.814 297.318 280.434 297.271C279.039 297.239 278.039 298.222 278.182 299.444Z" fill="#DEDEDE"/>
    <path d="M246.014 298.699C246.125 299.936 247.346 300.951 248.742 300.983C250.138 301.015 251.185 300.031 251.074 298.81C250.963 297.572 249.741 296.557 248.346 296.526C246.95 296.478 245.919 297.461 246.014 298.699Z" fill="#DEDEDE"/>
    <path d="M237.956 298.54C238.051 299.777 239.272 300.792 240.668 300.808C242.064 300.84 243.127 299.856 243.016 298.619C242.905 297.382 241.683 296.367 240.303 296.335C238.907 296.319 237.861 297.302 237.956 298.54Z" fill="#DEDEDE"/>
    <path d="M229.898 298.413C229.993 299.65 231.199 300.665 232.611 300.681C234.006 300.697 235.069 299.729 234.974 298.492C234.879 297.255 233.657 296.24 232.262 296.208C230.85 296.192 229.803 297.176 229.898 298.413Z" fill="#DEDEDE"/>
    <path d="M221.824 298.286C221.904 299.523 223.109 300.538 224.521 300.554C225.917 300.57 226.995 299.587 226.9 298.365C226.805 297.128 225.599 296.113 224.204 296.097C222.808 296.065 221.729 297.065 221.824 298.286Z" fill="#DEDEDE"/>
    <path d="M213.734 298.191C213.813 299.428 215.003 300.428 216.415 300.459C217.827 300.475 218.889 299.492 218.81 298.254C218.731 297.017 217.525 296.002 216.129 295.986C214.733 295.97 213.671 296.954 213.734 298.191Z" fill="#DEDEDE"/>
    <path d="M205.661 298.112C205.724 299.349 206.93 300.348 208.326 300.364C209.737 300.38 210.816 299.396 210.737 298.159C210.657 296.922 209.468 295.907 208.072 295.891C206.66 295.875 205.582 296.874 205.661 298.112Z" fill="#DEDEDE"/>
    <path d="M197.571 298.032C197.634 299.27 198.824 300.269 200.22 300.285C201.632 300.301 202.71 299.317 202.647 298.08C202.583 296.843 201.394 295.827 199.998 295.827C198.602 295.812 197.508 296.795 197.571 298.032Z" fill="#DEDEDE"/>
    <path d="M189.481 297.985C189.529 299.222 190.718 300.221 192.13 300.237C193.542 300.253 194.636 299.254 194.573 298.016C194.509 296.779 193.336 295.78 191.924 295.764C190.512 295.748 189.418 296.747 189.481 297.985Z" fill="#DEDEDE"/>
    <path d="M181.376 297.937C181.423 299.174 182.597 300.174 184.009 300.174C185.421 300.174 186.515 299.19 186.467 297.953C186.42 296.716 185.246 295.716 183.834 295.7C182.438 295.716 181.344 296.716 181.376 297.937Z" fill="#DEDEDE"/>
    <path d="M173.286 297.921C173.317 299.159 174.491 300.158 175.903 300.158C177.315 300.158 178.409 299.174 178.377 297.937C178.33 296.7 177.156 295.701 175.76 295.701C174.348 295.685 173.238 296.684 173.286 297.921Z" fill="#DEDEDE"/>
    <path d="M165.18 297.921C165.212 299.159 166.37 300.158 167.782 300.158C169.193 300.158 170.304 299.159 170.272 297.937C170.24 296.7 169.067 295.701 167.671 295.701C166.259 295.685 165.149 296.684 165.18 297.921Z" fill="#DEDEDE"/>
    <path d="M285.336 292.671C285.495 293.908 286.732 294.955 288.128 294.987C289.508 295.034 290.507 294.051 290.349 292.814C290.19 291.577 288.937 290.53 287.557 290.482C286.161 290.45 285.177 291.434 285.336 292.671Z" fill="#DEDEDE"/>
    <path d="M277.357 292.449C277.5 293.686 278.737 294.733 280.133 294.765C281.513 294.797 282.513 293.829 282.37 292.592C282.211 291.355 280.974 290.308 279.594 290.26C278.214 290.228 277.215 291.212 277.357 292.449Z" fill="#DEDEDE"/>
    <path d="M245.395 291.688C245.506 292.925 246.727 293.956 248.123 293.988C249.519 294.019 250.55 293.036 250.423 291.799C250.296 290.561 249.075 289.514 247.695 289.499C246.299 289.467 245.284 290.45 245.395 291.688Z" fill="#DEDEDE"/>
    <path d="M237.369 291.544C237.48 292.782 238.686 293.813 240.082 293.844C241.478 293.876 242.509 292.893 242.398 291.64C242.287 290.402 241.065 289.371 239.685 289.34C238.305 289.308 237.274 290.307 237.369 291.544Z" fill="#DEDEDE"/>
    <path d="M229.359 291.418C229.454 292.655 230.66 293.686 232.056 293.702C233.451 293.718 234.498 292.734 234.403 291.497C234.308 290.26 233.087 289.229 231.707 289.197C230.311 289.181 229.264 290.165 229.359 291.418Z" fill="#DEDEDE"/>
    <path d="M221.333 291.291C221.428 292.528 222.618 293.559 224.014 293.575C225.41 293.591 226.472 292.608 226.377 291.37C226.282 290.133 225.077 289.102 223.697 289.086C222.301 289.054 221.238 290.054 221.333 291.291Z" fill="#DEDEDE"/>
    <path d="M213.291 291.196C213.37 292.433 214.56 293.464 215.956 293.48C217.352 293.496 218.414 292.512 218.335 291.259C218.256 290.022 217.05 288.991 215.654 288.975C214.274 288.959 213.211 289.942 213.291 291.196Z" fill="#DEDEDE"/>
    <path d="M205.264 291.1C205.344 292.338 206.533 293.353 207.929 293.369C209.325 293.384 210.404 292.385 210.324 291.148C210.245 289.911 209.055 288.88 207.66 288.864C206.264 288.864 205.185 289.863 205.264 291.1Z" fill="#DEDEDE"/>
    <path d="M197.223 291.037C197.286 292.275 198.476 293.29 199.872 293.306C201.268 293.321 202.346 292.322 202.283 291.085C202.219 289.848 201.03 288.817 199.634 288.817C198.238 288.801 197.159 289.8 197.223 291.037Z" fill="#DEDEDE"/>
    <path d="M189.164 290.989C189.212 292.227 190.401 293.242 191.797 293.258C193.193 293.274 194.288 292.274 194.224 291.021C194.161 289.784 192.987 288.769 191.591 288.753C190.195 288.737 189.117 289.736 189.164 290.989Z" fill="#DEDEDE"/>
    <path d="M181.122 290.942C181.17 292.179 182.344 293.195 183.739 293.195C185.135 293.195 186.23 292.195 186.182 290.958C186.135 289.721 184.961 288.706 183.565 288.69C182.169 288.706 181.075 289.705 181.122 290.942Z" fill="#DEDEDE"/>
    <path d="M173.064 290.926C173.096 292.163 174.27 293.178 175.666 293.178C177.061 293.178 178.172 292.179 178.124 290.942C178.077 289.705 176.919 288.689 175.523 288.689C174.127 288.674 173.032 289.689 173.064 290.926Z" fill="#DEDEDE"/>
    <path d="M165.006 290.926C165.038 292.163 166.196 293.179 167.592 293.179C168.987 293.179 170.098 292.179 170.066 290.926C170.034 289.689 168.876 288.674 167.481 288.674C166.085 288.674 164.974 289.673 165.006 290.926Z" fill="#DEDEDE"/>
    <path d="M284.432 285.628C284.59 286.881 285.843 287.928 287.207 287.96C288.587 288.007 289.555 287.024 289.396 285.771C289.222 284.518 287.985 283.471 286.605 283.423C285.241 283.392 284.273 284.375 284.432 285.628Z" fill="#DEDEDE"/>
    <path d="M276.516 285.406C276.675 286.659 277.912 287.706 279.292 287.738C280.672 287.769 281.656 286.802 281.497 285.549C281.338 284.295 280.101 283.249 278.721 283.217C277.341 283.169 276.358 284.153 276.516 285.406Z" fill="#DEDEDE"/>
    <path d="M260.639 284.994C260.782 286.247 262.003 287.278 263.383 287.31C264.763 287.341 265.762 286.358 265.62 285.105C265.477 283.852 264.239 282.805 262.875 282.773C261.511 282.741 260.512 283.741 260.639 284.994Z" fill="#DEDEDE"/>
    <path d="M252.692 284.804C252.819 286.057 254.041 287.088 255.421 287.119C256.801 287.151 257.816 286.168 257.689 284.915C257.562 283.661 256.325 282.615 254.961 282.583C253.581 282.567 252.565 283.55 252.692 284.804Z" fill="#DEDEDE"/>
    <path d="M244.729 284.645C244.84 285.898 246.061 286.929 247.441 286.96C248.821 286.992 249.852 286.009 249.726 284.756C249.599 283.503 248.377 282.471 247.013 282.44C245.633 282.392 244.618 283.391 244.729 284.645Z" fill="#DEDEDE"/>
    <path d="M236.766 284.502C236.878 285.755 238.083 286.786 239.463 286.818C240.843 286.85 241.874 285.85 241.763 284.597C241.652 283.344 240.431 282.313 239.051 282.281C237.686 282.25 236.655 283.249 236.766 284.502Z" fill="#DEDEDE"/>
    <path d="M228.803 284.359C228.899 285.612 230.104 286.643 231.5 286.659C232.88 286.675 233.927 285.691 233.816 284.438C233.705 283.185 232.499 282.154 231.119 282.123C229.739 282.107 228.692 283.106 228.803 284.359Z" fill="#DEDEDE"/>
    <path d="M220.825 284.248C220.921 285.501 222.11 286.532 223.506 286.548C224.886 286.564 225.933 285.581 225.838 284.328C225.743 283.074 224.537 282.043 223.157 282.028C221.777 281.996 220.73 282.995 220.825 284.248Z" fill="#DEDEDE"/>
    <path d="M212.831 284.137C212.91 285.39 214.1 286.405 215.496 286.437C216.892 286.453 217.938 285.453 217.843 284.2C217.748 282.947 216.558 281.916 215.178 281.9C213.798 281.884 212.752 282.9 212.831 284.137Z" fill="#DEDEDE"/>
    <path d="M204.851 284.058C204.93 285.311 206.104 286.326 207.5 286.342C208.896 286.358 209.958 285.359 209.863 284.105C209.784 282.852 208.594 281.821 207.214 281.805C205.834 281.805 204.772 282.805 204.851 284.058Z" fill="#DEDEDE"/>
    <path d="M196.857 283.994C196.92 285.247 198.094 286.263 199.49 286.278C200.886 286.294 201.948 285.295 201.869 284.042C201.79 282.789 200.616 281.758 199.236 281.758C197.856 281.726 196.777 282.741 196.857 283.994Z" fill="#DEDEDE"/>
    <path d="M188.847 283.931C188.911 285.184 190.085 286.199 191.465 286.215C192.86 286.231 193.939 285.215 193.876 283.962C193.812 282.709 192.638 281.694 191.258 281.678C189.862 281.678 188.8 282.678 188.847 283.931Z" fill="#DEDEDE"/>
    <path d="M180.853 283.899C180.9 285.152 182.074 286.167 183.454 286.167C184.85 286.167 185.928 285.168 185.881 283.915C185.817 282.662 184.659 281.647 183.279 281.631C181.884 281.631 180.805 282.646 180.853 283.899Z" fill="#DEDEDE"/>
    <path d="M172.842 283.883C172.874 285.136 174.047 286.152 175.427 286.152C176.823 286.152 177.918 285.152 177.87 283.899C177.823 282.646 176.665 281.631 175.285 281.631C173.889 281.615 172.794 282.63 172.842 283.883Z" fill="#DEDEDE"/>
    <path d="M164.831 283.868C164.863 285.121 166.021 286.136 167.401 286.136C168.797 286.136 169.891 285.121 169.86 283.868C169.828 282.615 168.67 281.599 167.29 281.599C165.894 281.599 164.8 282.63 164.831 283.868Z" fill="#DEDEDE"/>
    <path d="M291.347 278.776C291.521 280.029 292.774 281.092 294.138 281.139C295.503 281.187 296.47 280.203 296.28 278.95C296.09 277.697 294.836 276.634 293.488 276.587C292.124 276.523 291.172 277.523 291.347 278.776Z" fill="#DEDEDE"/>
    <path d="M251.963 277.713C252.089 278.966 253.311 280.013 254.691 280.045C256.071 280.076 257.07 279.077 256.927 277.824C256.785 276.571 255.563 275.508 254.199 275.492C252.835 275.46 251.836 276.46 251.963 277.713Z" fill="#DEDEDE"/>
    <path d="M244.047 277.554C244.174 278.807 245.379 279.854 246.759 279.886C248.139 279.918 249.139 278.918 249.012 277.665C248.885 276.412 247.664 275.365 246.299 275.334C244.935 275.286 243.936 276.285 244.047 277.554Z" fill="#DEDEDE"/>
    <path d="M236.132 277.396C236.243 278.649 237.448 279.696 238.828 279.712C240.208 279.743 241.223 278.744 241.097 277.475C240.97 276.222 239.764 275.175 238.4 275.143C237.052 275.143 236.021 276.143 236.132 277.396Z" fill="#DEDEDE"/>
    <path d="M228.217 277.269C228.328 278.522 229.517 279.569 230.897 279.585C232.277 279.6 233.308 278.601 233.197 277.348C233.086 276.095 231.881 275.048 230.517 275.032C229.137 275.001 228.121 276 228.217 277.269Z" fill="#DEDEDE"/>
    <path d="M220.285 277.142C220.381 278.395 221.57 279.442 222.95 279.458C224.33 279.474 225.361 278.474 225.266 277.221C225.171 275.968 223.965 274.921 222.601 274.905C221.221 274.89 220.19 275.889 220.285 277.142Z" fill="#DEDEDE"/>
    <path d="M212.354 277.047C212.434 278.3 213.623 279.331 215.003 279.347C216.383 279.363 217.43 278.363 217.335 277.094C217.24 275.841 216.05 274.794 214.686 274.779C213.306 274.779 212.275 275.794 212.354 277.047Z" fill="#DEDEDE"/>
    <path d="M204.408 276.968C204.487 278.221 205.661 279.252 207.041 279.268C208.421 279.283 209.468 278.284 209.389 277.015C209.309 275.762 208.12 274.715 206.756 274.699C205.391 274.699 204.345 275.699 204.408 276.968Z" fill="#DEDEDE"/>
    <path d="M196.477 276.888C196.54 278.141 197.714 279.172 199.094 279.188C200.474 279.204 201.537 278.189 201.457 276.936C201.378 275.683 200.204 274.652 198.84 274.636C197.46 274.62 196.398 275.635 196.477 276.888Z" fill="#DEDEDE"/>
    <path d="M188.515 276.84C188.578 278.094 189.736 279.125 191.116 279.125C192.496 279.14 193.559 278.125 193.495 276.856C193.432 275.603 192.258 274.572 190.878 274.556C189.53 274.572 188.467 275.587 188.515 276.84Z" fill="#DEDEDE"/>
    <path d="M180.567 276.809C180.615 278.062 181.773 279.093 183.169 279.093C184.549 279.093 185.627 278.093 185.564 276.825C185.501 275.571 184.343 274.54 182.963 274.525C181.598 274.525 180.52 275.54 180.567 276.809Z" fill="#DEDEDE"/>
    <path d="M172.605 276.777C172.652 278.03 173.794 279.061 175.19 279.061C176.57 279.061 177.665 278.046 177.617 276.793C177.57 275.54 176.412 274.509 175.032 274.509C173.652 274.509 172.573 275.524 172.605 276.777Z" fill="#DEDEDE"/>
    <path d="M259.053 270.765C259.195 272.034 260.417 273.081 261.781 273.113C263.145 273.145 264.128 272.145 263.97 270.892C263.811 269.623 262.59 268.577 261.242 268.529C259.877 268.497 258.91 269.497 259.053 270.765Z" fill="#DEDEDE"/>
    <path d="M251.2 270.575C251.343 271.844 252.549 272.891 253.913 272.923C255.277 272.954 256.276 271.955 256.134 270.686C255.991 269.417 254.769 268.37 253.421 268.338C252.057 268.307 251.074 269.306 251.2 270.575Z" fill="#DEDEDE"/>
    <path d="M243.348 270.416C243.475 271.685 244.681 272.732 246.045 272.764C247.409 272.796 248.408 271.796 248.281 270.528C248.154 269.259 246.933 268.212 245.585 268.18C244.221 268.148 243.221 269.148 243.348 270.416Z" fill="#DEDEDE"/>
    <path d="M235.482 270.257C235.593 271.526 236.798 272.573 238.162 272.589C239.526 272.621 240.542 271.606 240.415 270.353C240.288 269.084 239.082 268.037 237.734 268.005C236.37 267.989 235.371 269.004 235.482 270.257Z" fill="#DEDEDE"/>
    <path d="M227.614 270.131C227.725 271.4 228.915 272.447 230.279 272.462C231.643 272.478 232.658 271.479 232.547 270.21C232.436 268.941 231.231 267.894 229.867 267.878C228.518 267.847 227.503 268.862 227.614 270.131Z" fill="#DEDEDE"/>
    <path d="M219.746 270.02C219.842 271.289 221.031 272.32 222.411 272.352C223.775 272.368 224.806 271.368 224.695 270.099C224.584 268.83 223.395 267.783 222.031 267.768C220.666 267.736 219.635 268.751 219.746 270.02Z" fill="#DEDEDE"/>
    <path d="M211.863 269.909C211.958 271.178 213.132 272.209 214.512 272.225C215.876 272.24 216.923 271.225 216.828 269.972C216.733 268.703 215.543 267.656 214.179 267.64C212.799 267.625 211.768 268.64 211.863 269.909Z" fill="#DEDEDE"/>
    <path d="M203.964 269.829C204.043 271.098 205.217 272.129 206.597 272.145C207.961 272.161 209.008 271.146 208.928 269.877C208.849 268.608 207.66 267.577 206.295 267.561C204.931 267.545 203.884 268.56 203.964 269.829Z" fill="#DEDEDE"/>
    <path d="M196.08 269.766C196.144 271.035 197.318 272.066 198.698 272.082C200.062 272.098 201.124 271.083 201.045 269.814C200.966 268.545 199.792 267.514 198.428 267.498C197.064 267.466 196.001 268.497 196.08 269.766Z" fill="#DEDEDE"/>
    <path d="M188.181 269.702C188.244 270.971 189.402 272.002 190.782 272.002C192.162 272.018 193.209 270.987 193.146 269.734C193.082 268.465 191.908 267.434 190.544 267.418C189.18 267.418 188.117 268.434 188.181 269.702Z" fill="#DEDEDE"/>
    <path d="M180.281 269.671C180.329 270.94 181.487 271.971 182.867 271.971C184.247 271.971 185.31 270.956 185.246 269.687C185.183 268.418 184.025 267.387 182.661 267.387C181.296 267.387 180.234 268.402 180.281 269.671Z" fill="#DEDEDE"/>
    <path d="M172.366 269.655C172.414 270.924 173.556 271.939 174.936 271.955C176.316 271.955 177.379 270.94 177.331 269.671C177.284 268.402 176.126 267.371 174.762 267.371C173.413 267.355 172.335 268.386 172.366 269.655Z" fill="#DEDEDE"/>
    <path d="M250.424 263.405C250.567 264.674 251.772 265.737 253.137 265.769C254.485 265.801 255.468 264.801 255.325 263.516C255.183 262.247 253.961 261.185 252.613 261.153C251.249 261.121 250.281 262.136 250.424 263.405Z" fill="#DEDEDE"/>
    <path d="M242.619 263.231C242.746 264.5 243.952 265.547 245.316 265.579C246.68 265.61 247.664 264.595 247.521 263.326C247.378 262.057 246.172 260.994 244.824 260.963C243.476 260.963 242.493 261.962 242.619 263.231Z" fill="#DEDEDE"/>
    <path d="M234.815 263.088C234.942 264.357 236.132 265.404 237.496 265.436C238.86 265.467 239.859 264.452 239.716 263.183C239.59 261.914 238.384 260.852 237.036 260.836C235.687 260.804 234.688 261.819 234.815 263.088Z" fill="#DEDEDE"/>
    <path d="M226.995 262.961C227.106 264.23 228.296 265.277 229.66 265.309C231.024 265.325 232.024 264.325 231.912 263.056C231.801 261.787 230.596 260.74 229.248 260.709C227.883 260.661 226.884 261.676 226.995 262.961Z" fill="#DEDEDE"/>
    <path d="M219.175 262.835C219.27 264.104 220.46 265.15 221.824 265.166C223.188 265.182 224.203 264.167 224.092 262.898C223.981 261.629 222.791 260.582 221.443 260.55C220.079 260.55 219.064 261.566 219.175 262.835Z" fill="#DEDEDE"/>
    <path d="M211.339 262.739C211.434 264.008 212.608 265.055 213.972 265.071C215.337 265.087 216.352 264.071 216.257 262.802C216.161 261.533 214.972 260.487 213.623 260.471C212.275 260.439 211.26 261.47 211.339 262.739Z" fill="#DEDEDE"/>
    <path d="M203.504 262.66C203.584 263.929 204.757 264.975 206.122 264.991C207.486 265.007 208.517 263.992 208.422 262.723C208.326 261.454 207.168 260.407 205.804 260.391C204.456 260.36 203.425 261.375 203.504 262.66Z" fill="#DEDEDE"/>
    <path d="M195.668 262.581C195.747 263.849 196.905 264.896 198.269 264.896C199.633 264.912 200.68 263.881 200.601 262.612C200.522 261.343 199.364 260.296 198 260.281C196.635 260.281 195.604 261.312 195.668 262.581Z" fill="#DEDEDE"/>
    <path d="M187.831 262.533C187.895 263.802 189.053 264.833 190.417 264.849C191.781 264.849 192.828 263.834 192.764 262.565C192.701 261.296 191.543 260.249 190.179 260.249C188.815 260.233 187.768 261.264 187.831 262.533Z" fill="#DEDEDE"/>
    <path d="M179.98 262.501C180.028 263.77 181.186 264.801 182.55 264.817C183.914 264.817 184.977 263.802 184.913 262.533C184.85 261.264 183.708 260.217 182.344 260.217C180.979 260.201 179.932 261.216 179.98 262.501Z" fill="#DEDEDE"/>
    <path d="M172.128 262.469C172.176 263.738 173.318 264.769 174.682 264.769C176.046 264.769 177.109 263.754 177.062 262.469C177.014 261.2 175.872 260.154 174.508 260.154C173.16 260.169 172.081 261.2 172.128 262.469Z" fill="#DEDEDE"/>
    <path d="M249.614 256.188C249.757 257.473 250.963 258.52 252.311 258.552C253.659 258.583 254.627 257.568 254.468 256.299C254.309 255.014 253.104 253.952 251.771 253.92C250.423 253.904 249.472 254.919 249.614 256.188Z" fill="#DEDEDE"/>
    <path d="M241.858 256.03C241.985 257.298 243.191 258.361 244.539 258.393C245.887 258.425 246.871 257.41 246.728 256.141C246.585 254.856 245.38 253.809 244.047 253.777C242.715 253.73 241.731 254.745 241.858 256.03Z" fill="#DEDEDE"/>
    <path d="M234.117 255.887C234.244 257.172 235.434 258.218 236.782 258.25C238.131 258.282 239.114 257.267 238.987 255.982C238.86 254.697 237.655 253.65 236.322 253.618C234.974 253.587 233.991 254.602 234.117 255.887Z" fill="#DEDEDE"/>
    <path d="M226.36 255.744C226.472 257.029 227.661 258.076 229.009 258.092C230.358 258.108 231.357 257.092 231.23 255.823C231.103 254.539 229.914 253.492 228.581 253.46C227.233 253.444 226.234 254.475 226.36 255.744Z" fill="#DEDEDE"/>
    <path d="M218.587 255.633C218.698 256.918 219.872 257.965 221.221 257.981C222.569 257.997 223.584 256.981 223.457 255.712C223.346 254.428 222.156 253.381 220.824 253.365C219.492 253.333 218.476 254.348 218.587 255.633Z" fill="#DEDEDE"/>
    <path d="M210.816 255.522C210.911 256.807 212.085 257.854 213.433 257.87C214.781 257.886 215.796 256.87 215.701 255.586C215.606 254.301 214.432 253.254 213.084 253.238C211.736 253.222 210.721 254.253 210.816 255.522Z" fill="#DEDEDE"/>
    <path d="M203.044 255.442C203.123 256.727 204.297 257.758 205.645 257.774C206.993 257.79 208.024 256.759 207.929 255.49C207.834 254.205 206.676 253.158 205.328 253.142C203.98 253.142 202.949 254.173 203.044 255.442Z" fill="#DEDEDE"/>
    <path d="M195.255 255.379C195.335 256.664 196.493 257.695 197.841 257.711C199.189 257.727 200.22 256.696 200.141 255.427C200.061 254.142 198.904 253.095 197.555 253.095C196.207 253.063 195.176 254.094 195.255 255.379Z" fill="#DEDEDE"/>
    <path d="M187.467 255.316C187.53 256.601 188.688 257.632 190.036 257.648C191.385 257.663 192.431 256.632 192.352 255.348C192.273 254.063 191.131 253.032 189.783 253.016C188.45 253.016 187.403 254.047 187.467 255.316Z" fill="#DEDEDE"/>
    <path d="M179.678 255.284C179.726 256.569 180.868 257.6 182.232 257.6C183.58 257.6 184.643 256.569 184.579 255.3C184.516 254.015 183.374 252.984 182.026 252.968C180.677 252.968 179.615 254.015 179.678 255.284Z" fill="#DEDEDE"/>
    <path d="M171.874 255.268C171.922 256.553 173.048 257.584 174.412 257.584C175.76 257.584 176.823 256.553 176.776 255.284C176.728 253.999 175.586 252.968 174.238 252.968C172.889 252.952 171.843 253.983 171.874 255.268Z" fill="#DEDEDE"/>
    <path d="M164.087 255.268C164.118 256.553 165.245 257.584 166.609 257.584C167.957 257.584 169.036 256.553 168.988 255.268C168.94 253.983 167.814 252.952 166.466 252.952C165.118 252.952 164.055 253.983 164.087 255.268Z" fill="#DEDEDE"/>
    <path d="M248.773 248.955C248.916 250.24 250.122 251.303 251.454 251.334C252.787 251.366 253.738 250.351 253.595 249.066C253.437 247.781 252.231 246.718 250.915 246.687C249.582 246.655 248.631 247.67 248.773 248.955Z" fill="#DEDEDE"/>
    <path d="M241.096 248.796C241.239 250.081 242.428 251.144 243.761 251.176C245.093 251.207 246.061 250.192 245.918 248.907C245.775 247.623 244.57 246.56 243.253 246.528C241.921 246.496 240.953 247.512 241.096 248.796Z" fill="#DEDEDE"/>
    <path d="M233.404 248.638C233.531 249.923 234.721 250.985 236.053 251.001C237.386 251.033 238.369 250.018 238.226 248.733C238.084 247.448 236.894 246.385 235.577 246.369C234.245 246.338 233.277 247.353 233.404 248.638Z" fill="#DEDEDE"/>
    <path d="M225.695 248.511C225.806 249.796 226.996 250.843 228.328 250.874C229.661 250.89 230.66 249.875 230.533 248.59C230.406 247.305 229.232 246.243 227.9 246.227C226.568 246.195 225.584 247.226 225.695 248.511Z" fill="#DEDEDE"/>
    <path d="M217.985 248.384C218.097 249.669 219.27 250.716 220.603 250.748C221.951 250.763 222.934 249.748 222.823 248.463C222.712 247.179 221.539 246.116 220.206 246.1C218.874 246.084 217.874 247.115 217.985 248.384Z" fill="#DEDEDE"/>
    <path d="M210.277 248.289C210.372 249.574 211.546 250.621 212.878 250.637C214.226 250.652 215.226 249.637 215.115 248.352C215.004 247.068 213.846 246.021 212.513 245.989C211.181 245.973 210.182 247.004 210.277 248.289Z" fill="#DEDEDE"/>
    <path d="M202.551 248.209C202.631 249.494 203.789 250.541 205.137 250.557C206.485 250.573 207.484 249.542 207.405 248.257C207.31 246.972 206.152 245.925 204.82 245.909C203.471 245.893 202.472 246.925 202.551 248.209Z" fill="#DEDEDE"/>
    <path d="M194.827 248.13C194.906 249.415 196.048 250.462 197.396 250.478C198.744 250.493 199.76 249.462 199.68 248.178C199.601 246.893 198.443 245.846 197.111 245.83C195.778 245.814 194.747 246.861 194.827 248.13Z" fill="#DEDEDE"/>
    <path d="M187.102 248.083C187.166 249.368 188.308 250.414 189.656 250.414C191.004 250.43 192.035 249.399 191.956 248.114C191.877 246.83 190.735 245.783 189.402 245.767C188.07 245.767 187.039 246.798 187.102 248.083Z" fill="#DEDEDE"/>
    <path d="M179.361 248.051C179.409 249.336 180.551 250.383 181.899 250.383C183.247 250.383 184.278 249.352 184.215 248.067C184.151 246.782 183.009 245.735 181.677 245.735C180.344 245.735 179.313 246.766 179.361 248.051Z" fill="#DEDEDE"/>
    <path d="M171.62 248.035C171.668 249.32 172.794 250.351 174.142 250.367C175.491 250.367 176.538 249.336 176.49 248.051C176.442 246.766 175.316 245.719 173.968 245.719C172.636 245.704 171.589 246.75 171.62 248.035Z" fill="#DEDEDE"/>
    <path d="M163.88 248.019C163.912 249.304 165.038 250.335 166.386 250.335C167.735 250.335 168.781 249.304 168.75 248.019C168.702 246.734 167.592 245.687 166.244 245.687C164.911 245.703 163.848 246.75 163.88 248.019Z" fill="#DEDEDE"/>
    <path d="M156.14 248.035C156.156 249.32 157.282 250.351 158.614 250.351C159.963 250.351 161.025 249.304 160.994 248.019C160.962 246.734 159.852 245.703 158.519 245.703C157.187 245.719 156.124 246.75 156.14 248.035Z" fill="#DEDEDE"/>
    <path d="M255.531 241.88C255.689 243.165 256.895 244.244 258.227 244.276C259.544 244.307 260.48 243.292 260.321 242.007C260.147 240.723 258.941 239.644 257.625 239.612C256.308 239.565 255.372 240.596 255.531 241.88Z" fill="#DEDEDE"/>
    <path d="M247.917 241.69C248.076 242.975 249.265 244.038 250.598 244.069C251.93 244.101 252.866 243.086 252.708 241.801C252.549 240.516 251.343 239.438 250.027 239.406C248.71 239.39 247.774 240.405 247.917 241.69Z" fill="#DEDEDE"/>
    <path d="M240.287 241.532C240.43 242.817 241.62 243.88 242.952 243.911C244.285 243.943 245.236 242.928 245.078 241.627C244.935 240.342 243.729 239.28 242.413 239.248C241.112 239.216 240.145 240.247 240.287 241.532Z" fill="#DEDEDE"/>
    <path d="M232.658 241.373C232.785 242.658 233.975 243.72 235.307 243.752C236.64 243.784 237.607 242.753 237.464 241.468C237.322 240.183 236.148 239.12 234.815 239.089C233.499 239.073 232.531 240.088 232.658 241.373Z" fill="#DEDEDE"/>
    <path d="M225.012 241.246C225.139 242.531 226.312 243.594 227.629 243.61C228.961 243.626 229.929 242.61 229.802 241.326C229.675 240.041 228.501 238.978 227.169 238.946C225.868 238.93 224.901 239.961 225.012 241.246Z" fill="#DEDEDE"/>
    <path d="M217.367 241.119C217.478 242.404 218.652 243.467 219.969 243.482C221.301 243.498 222.285 242.483 222.158 241.182C222.047 239.898 220.873 238.835 219.556 238.819C218.24 238.803 217.256 239.834 217.367 241.119Z" fill="#DEDEDE"/>
    <path d="M209.721 241.024C209.816 242.309 210.974 243.371 212.307 243.387C213.639 243.403 214.639 242.372 214.528 241.087C214.416 239.802 213.259 238.74 211.942 238.724C210.61 238.708 209.61 239.739 209.721 241.024Z" fill="#DEDEDE"/>
    <path d="M202.06 240.945C202.155 242.23 203.297 243.292 204.63 243.292C205.962 243.308 206.961 242.277 206.866 240.992C206.771 239.708 205.613 238.645 204.297 238.629C202.964 238.613 201.965 239.66 202.06 240.945Z" fill="#DEDEDE"/>
    <path d="M194.383 240.866C194.462 242.15 195.604 243.197 196.937 243.213C198.269 243.229 199.284 242.198 199.189 240.913C199.11 239.628 197.952 238.565 196.635 238.565C195.319 238.55 194.303 239.581 194.383 240.866Z" fill="#DEDEDE"/>
    <path d="M186.722 240.818C186.785 242.103 187.927 243.15 189.26 243.165C190.592 243.181 191.623 242.134 191.544 240.85C191.464 239.565 190.338 238.518 189.006 238.502C187.673 238.502 186.658 239.533 186.722 240.818Z" fill="#DEDEDE"/>
    <path d="M179.044 240.786C179.108 242.071 180.234 243.118 181.566 243.118C182.899 243.118 183.93 242.087 183.866 240.802C183.803 239.517 182.677 238.47 181.344 238.454C180.012 238.454 178.981 239.501 179.044 240.786Z" fill="#DEDEDE"/>
    <path d="M171.367 240.77C171.414 242.055 172.541 243.102 173.873 243.102C175.205 243.102 176.252 242.071 176.189 240.786C176.141 239.501 175.015 238.454 173.683 238.454C172.366 238.439 171.319 239.47 171.367 240.77Z" fill="#DEDEDE"/>
    <path d="M163.69 240.755C163.721 242.039 164.832 243.086 166.164 243.086C167.497 243.086 168.543 242.039 168.512 240.755C168.464 239.47 167.354 238.423 166.021 238.423C164.705 238.423 163.658 239.47 163.69 240.755Z" fill="#DEDEDE"/>
    <path d="M156.012 240.77C156.044 242.055 157.138 243.102 158.471 243.086C159.803 243.086 160.866 242.039 160.834 240.755C160.802 239.47 159.692 238.423 158.375 238.423C157.043 238.439 155.98 239.486 156.012 240.77Z" fill="#DEDEDE"/>
    <path d="M148.32 240.802C148.336 242.087 149.43 243.118 150.763 243.118C152.095 243.118 153.158 242.071 153.142 240.786C153.126 239.501 152.032 238.454 150.699 238.47C149.367 238.47 148.304 239.517 148.32 240.802Z" fill="#DEDEDE"/>
    <path d="M262.146 234.79C262.321 236.075 263.526 237.154 264.843 237.186C266.159 237.217 267.063 236.202 266.873 234.917C266.683 233.632 265.477 232.554 264.176 232.506C262.876 232.474 261.972 233.49 262.146 234.79Z" fill="#DEDEDE"/>
    <path d="M254.595 234.584C254.77 235.869 255.96 236.947 257.276 236.979C258.593 237.011 259.513 235.996 259.338 234.711C259.164 233.426 257.958 232.347 256.657 232.316C255.357 232.284 254.437 233.299 254.595 234.584Z" fill="#DEDEDE"/>
    <path d="M247.045 234.409C247.204 235.694 248.394 236.773 249.71 236.805C251.027 236.836 251.963 235.821 251.788 234.52C251.63 233.236 250.424 232.157 249.123 232.125C247.823 232.094 246.887 233.125 247.045 234.409Z" fill="#DEDEDE"/>
    <path d="M239.479 234.235C239.621 235.52 240.811 236.598 242.128 236.614C243.444 236.646 244.38 235.615 244.237 234.33C244.079 233.045 242.905 231.967 241.588 231.935C240.272 231.919 239.336 232.95 239.479 234.235Z" fill="#DEDEDE"/>
    <path d="M231.912 234.092C232.039 235.377 233.229 236.44 234.545 236.472C235.862 236.503 236.814 235.472 236.671 234.187C236.528 232.903 235.354 231.824 234.038 231.808C232.721 231.776 231.769 232.807 231.912 234.092Z" fill="#DEDEDE"/>
    <path d="M224.331 233.966C224.457 235.25 225.615 236.313 226.948 236.345C228.264 236.361 229.232 235.346 229.105 234.045C228.978 232.76 227.804 231.681 226.488 231.666C225.171 231.634 224.204 232.665 224.331 233.966Z" fill="#DEDEDE"/>
    <path d="M216.732 233.838C216.843 235.123 218.001 236.186 219.334 236.202C220.65 236.218 221.634 235.187 221.507 233.902C221.38 232.617 220.222 231.554 218.905 231.523C217.605 231.523 216.621 232.554 216.732 233.838Z" fill="#DEDEDE"/>
    <path d="M209.151 233.743C209.246 235.028 210.404 236.091 211.72 236.107C213.037 236.123 214.02 235.092 213.909 233.807C213.798 232.522 212.64 231.459 211.34 231.443C210.023 231.412 209.04 232.443 209.151 233.743Z" fill="#DEDEDE"/>
    <path d="M201.553 233.648C201.648 234.933 202.79 235.995 204.107 236.011C205.423 236.027 206.422 234.996 206.327 233.695C206.232 232.411 205.09 231.348 203.773 231.332C202.441 231.332 201.458 232.363 201.553 233.648Z" fill="#DEDEDE"/>
    <path d="M193.938 233.585C194.018 234.869 195.16 235.932 196.476 235.948C197.793 235.964 198.808 234.917 198.713 233.632C198.618 232.347 197.492 231.285 196.175 231.269C194.859 231.253 193.859 232.3 193.938 233.585Z" fill="#DEDEDE"/>
    <path d="M186.325 233.537C186.389 234.822 187.515 235.885 188.847 235.885C190.164 235.901 191.179 234.854 191.1 233.569C191.02 232.284 189.894 231.221 188.578 231.221C187.277 231.205 186.262 232.236 186.325 233.537Z" fill="#DEDEDE"/>
    <path d="M178.727 233.49C178.791 234.775 179.901 235.837 181.234 235.837C182.55 235.837 183.581 234.806 183.518 233.506C183.454 232.221 182.328 231.158 181.012 231.158C179.679 231.158 178.664 232.205 178.727 233.49Z" fill="#DEDEDE"/>
    <path d="M171.098 233.474C171.145 234.759 172.256 235.806 173.588 235.821C174.92 235.821 175.936 234.774 175.888 233.49C175.841 232.205 174.714 231.142 173.398 231.142C172.081 231.142 171.05 232.189 171.098 233.474Z" fill="#DEDEDE"/>
    <path d="M163.483 233.474C163.515 234.759 164.625 235.806 165.942 235.806C167.274 235.806 168.305 234.759 168.258 233.474C168.21 232.189 167.116 231.142 165.799 231.142C164.482 231.142 163.451 232.189 163.483 233.474Z" fill="#DEDEDE"/>
    <path d="M155.869 233.49C155.901 234.774 156.996 235.821 158.312 235.821C159.644 235.821 160.691 234.775 160.66 233.474C160.628 232.189 159.533 231.142 158.217 231.142C156.884 231.142 155.838 232.205 155.869 233.49Z" fill="#DEDEDE"/>
    <path d="M276.057 227.922C276.263 229.207 277.484 230.301 278.769 230.333C280.07 230.38 280.942 229.365 280.736 228.065C280.53 226.78 279.308 225.685 278.008 225.638C276.739 225.622 275.85 226.637 276.057 227.922Z" fill="#DEDEDE"/>
    <path d="M268.602 227.7C268.792 228.985 269.998 230.079 271.298 230.111C272.599 230.143 273.487 229.143 273.281 227.843C273.075 226.558 271.869 225.463 270.585 225.432C269.3 225.384 268.412 226.399 268.602 227.7Z" fill="#DEDEDE"/>
    <path d="M261.131 227.478C261.305 228.763 262.511 229.841 263.811 229.889C265.112 229.921 266.016 228.905 265.826 227.621C265.636 226.32 264.43 225.241 263.145 225.21C261.845 225.178 260.94 226.193 261.131 227.478Z" fill="#DEDEDE"/>
    <path d="M253.644 227.287C253.818 228.588 255.008 229.651 256.308 229.683C257.609 229.714 258.529 228.699 258.339 227.399C258.164 226.098 256.959 225.035 255.674 224.987C254.373 224.972 253.469 225.987 253.644 227.287Z" fill="#DEDEDE"/>
    <path d="M246.14 227.097C246.299 228.398 247.489 229.461 248.789 229.492C250.09 229.524 251.01 228.509 250.851 227.208C250.677 225.907 249.503 224.845 248.202 224.813C246.902 224.781 245.982 225.812 246.14 227.097Z" fill="#DEDEDE"/>
    <path d="M238.638 226.938C238.781 228.239 239.971 229.302 241.272 229.333C242.572 229.365 243.508 228.334 243.349 227.049C243.191 225.749 242.017 224.686 240.716 224.654C239.432 224.622 238.496 225.637 238.638 226.938Z" fill="#DEDEDE"/>
    <path d="M231.135 226.78C231.278 228.081 232.436 229.143 233.752 229.159C235.053 229.191 236.005 228.16 235.862 226.859C235.719 225.559 234.545 224.496 233.245 224.464C231.928 224.464 230.992 225.495 231.135 226.78Z" fill="#DEDEDE"/>
    <path d="M223.617 226.653C223.744 227.954 224.902 229.016 226.218 229.032C227.519 229.048 228.471 228.033 228.344 226.732C228.217 225.432 227.043 224.369 225.742 224.353C224.442 224.321 223.49 225.352 223.617 226.653Z" fill="#DEDEDE"/>
    <path d="M216.098 226.542C216.209 227.843 217.367 228.905 218.683 228.921C220 228.937 220.951 227.906 220.824 226.621C220.698 225.321 219.54 224.258 218.239 224.242C216.938 224.21 215.971 225.241 216.098 226.542Z" fill="#DEDEDE"/>
    <path d="M208.564 226.431C208.675 227.731 209.817 228.778 211.118 228.81C212.434 228.826 213.402 227.795 213.291 226.494C213.18 225.194 212.038 224.131 210.737 224.115C209.42 224.099 208.453 225.146 208.564 226.431Z" fill="#DEDEDE"/>
    <path d="M201.029 226.351C201.124 227.652 202.267 228.699 203.567 228.715C204.884 228.731 205.867 227.7 205.756 226.399C205.661 225.098 204.519 224.036 203.218 224.036C201.918 224.02 200.934 225.051 201.029 226.351Z" fill="#DEDEDE"/>
    <path d="M193.479 226.272C193.558 227.573 194.684 228.62 196.001 228.636C197.317 228.652 198.301 227.605 198.221 226.32C198.126 225.019 197 223.972 195.699 223.957C194.399 223.941 193.399 224.988 193.479 226.272Z" fill="#DEDEDE"/>
    <path d="M185.944 226.225C186.007 227.525 187.133 228.572 188.45 228.588C189.767 228.604 190.766 227.557 190.687 226.256C190.607 224.956 189.481 223.909 188.18 223.893C186.864 223.893 185.865 224.94 185.944 226.225Z" fill="#DEDEDE"/>
    <path d="M178.394 226.193C178.458 227.494 179.568 228.541 180.885 228.541C182.201 228.541 183.217 227.51 183.137 226.209C183.074 224.908 181.963 223.861 180.647 223.861C179.346 223.846 178.331 224.892 178.394 226.193Z" fill="#DEDEDE"/>
    <path d="M170.828 226.177C170.875 227.478 171.986 228.525 173.286 228.525C174.603 228.525 175.618 227.478 175.57 226.193C175.507 224.893 174.413 223.846 173.112 223.846C171.811 223.83 170.78 224.877 170.828 226.177Z" fill="#DEDEDE"/>
    <path d="M163.277 226.177C163.308 227.478 164.403 228.525 165.719 228.525C167.036 228.525 168.067 227.478 168.019 226.177C167.972 224.876 166.877 223.829 165.577 223.829C164.276 223.829 163.245 224.876 163.277 226.177Z" fill="#DEDEDE"/>
    <path d="M155.727 226.177C155.759 227.478 156.837 228.509 158.154 228.509C159.47 228.509 160.501 227.462 160.47 226.161C160.438 224.861 159.343 223.814 158.043 223.83C156.742 223.845 155.695 224.892 155.727 226.177Z" fill="#DEDEDE"/>
    <path d="M148.161 226.209C148.177 227.509 149.255 228.541 150.572 228.541C151.888 228.541 152.935 227.478 152.919 226.193C152.904 224.892 151.825 223.861 150.508 223.861C149.192 223.861 148.145 224.924 148.161 226.209Z" fill="#DEDEDE"/>
    <path d="M274.882 220.61C275.088 221.91 276.31 222.989 277.595 223.036C278.879 223.084 279.752 222.069 279.53 220.768C279.308 219.468 278.102 218.389 276.817 218.341C275.548 218.294 274.676 219.325 274.882 220.61Z" fill="#DEDEDE"/>
    <path d="M267.491 220.387C267.681 221.688 268.887 222.767 270.187 222.798C271.472 222.83 272.36 221.815 272.154 220.53C271.948 219.229 270.742 218.151 269.458 218.119C268.173 218.071 267.284 219.087 267.491 220.387Z" fill="#DEDEDE"/>
    <path d="M260.083 220.165C260.274 221.466 261.463 222.545 262.748 222.576C264.033 222.608 264.921 221.593 264.731 220.292C264.541 218.991 263.335 217.913 262.05 217.881C260.781 217.849 259.893 218.865 260.083 220.165Z" fill="#DEDEDE"/>
    <path d="M252.66 219.975C252.835 221.276 254.024 222.354 255.309 222.386C256.594 222.418 257.498 221.403 257.323 220.102C257.133 218.801 255.943 217.723 254.675 217.691C253.374 217.643 252.486 218.674 252.66 219.975Z" fill="#DEDEDE"/>
    <path d="M245.221 219.785C245.379 221.085 246.569 222.148 247.854 222.18C249.154 222.212 250.059 221.181 249.884 219.896C249.71 218.595 248.536 217.517 247.251 217.485C245.966 217.469 245.062 218.484 245.221 219.785Z" fill="#DEDEDE"/>
    <path d="M237.783 219.626C237.941 220.927 239.099 221.989 240.4 222.021C241.701 222.053 242.621 221.022 242.446 219.721C242.288 218.421 241.114 217.358 239.829 217.326C238.56 217.294 237.64 218.325 237.783 219.626Z" fill="#DEDEDE"/>
    <path d="M230.342 219.467C230.485 220.768 231.643 221.831 232.943 221.862C234.244 221.894 235.164 220.863 235.021 219.562C234.879 218.262 233.705 217.199 232.42 217.167C231.135 217.135 230.199 218.167 230.342 219.467Z" fill="#DEDEDE"/>
    <path d="M222.887 219.341C223.013 220.641 224.171 221.704 225.472 221.72C226.773 221.736 227.709 220.705 227.566 219.42C227.423 218.119 226.265 217.057 224.98 217.025C223.695 217.009 222.76 218.04 222.887 219.341Z" fill="#DEDEDE"/>
    <path d="M215.432 219.213C215.543 220.514 216.701 221.577 218.001 221.593C219.302 221.609 220.254 220.578 220.127 219.277C220 217.976 218.858 216.914 217.557 216.898C216.257 216.882 215.321 217.929 215.432 219.213Z" fill="#DEDEDE"/>
    <path d="M207.961 219.118C208.072 220.419 209.198 221.482 210.499 221.497C211.8 221.513 212.751 220.482 212.64 219.181C212.529 217.881 211.387 216.818 210.103 216.802C208.818 216.786 207.866 217.817 207.961 219.118Z" fill="#DEDEDE"/>
    <path d="M200.49 219.023C200.586 220.324 201.712 221.387 203.012 221.387C204.313 221.402 205.281 220.356 205.186 219.071C205.074 217.77 203.948 216.707 202.663 216.691C201.363 216.691 200.395 217.738 200.49 219.023Z" fill="#DEDEDE"/>
    <path d="M193.019 218.96C193.099 220.26 194.225 221.307 195.525 221.323C196.826 221.339 197.81 220.292 197.714 218.991C197.619 217.691 196.493 216.628 195.208 216.628C193.907 216.628 192.94 217.659 193.019 218.96Z" fill="#DEDEDE"/>
    <path d="M185.532 218.912C185.596 220.213 186.722 221.26 188.023 221.276C189.323 221.292 190.323 220.245 190.243 218.944C190.164 217.643 189.054 216.596 187.753 216.581C186.452 216.565 185.469 217.612 185.532 218.912Z" fill="#DEDEDE"/>
    <path d="M178.045 218.881C178.108 220.181 179.203 221.228 180.503 221.228C181.804 221.228 182.803 220.197 182.74 218.896C182.677 217.596 181.566 216.549 180.266 216.533C178.997 216.533 177.981 217.58 178.045 218.881Z" fill="#DEDEDE"/>
    <path d="M170.558 218.849C170.606 220.149 171.7 221.196 173.001 221.196C174.302 221.196 175.317 220.149 175.253 218.865C175.19 217.564 174.096 216.517 172.811 216.517C171.526 216.501 170.511 217.564 170.558 218.849Z" fill="#DEDEDE"/>
    <path d="M163.071 218.849C163.102 220.15 164.197 221.197 165.497 221.197C166.798 221.197 167.813 220.15 167.782 218.849C167.734 217.548 166.655 216.501 165.355 216.501C164.054 216.501 163.039 217.548 163.071 218.849Z" fill="#DEDEDE"/>
    <path d="M155.569 218.865C155.6 220.165 156.663 221.212 157.98 221.197C159.28 221.197 160.311 220.15 160.28 218.849C160.248 217.548 159.169 216.501 157.869 216.501C156.584 216.517 155.553 217.564 155.569 218.865Z" fill="#DEDEDE"/>
    <path d="M148.082 218.896C148.098 220.197 149.16 221.244 150.461 221.228C151.762 221.228 152.809 220.165 152.777 218.88C152.761 217.58 151.682 216.533 150.398 216.549C149.113 216.549 148.066 217.596 148.082 218.896Z" fill="#DEDEDE"/>
    <path d="M140.579 218.944C140.579 220.245 141.642 221.276 142.943 221.276C144.243 221.26 145.29 220.213 145.29 218.912C145.274 217.612 144.227 216.565 142.927 216.581C141.626 216.596 140.579 217.643 140.579 218.944Z" fill="#DEDEDE"/>
    <path d="M118.103 219.181C118.071 220.482 119.102 221.513 120.403 221.497C121.703 221.482 122.782 220.419 122.814 219.118C122.846 217.817 121.814 216.786 120.514 216.802C119.213 216.818 118.134 217.881 118.103 219.181Z" fill="#DEDEDE"/>
    <path d="M110.6 219.293C110.552 220.593 111.583 221.625 112.884 221.593C114.185 221.577 115.279 220.514 115.311 219.213C115.342 217.913 114.327 216.882 113.027 216.898C111.726 216.929 110.647 218.008 110.6 219.293Z" fill="#DEDEDE"/>
    <path d="M95.6259 219.578C95.5625 220.879 96.5618 221.894 97.8625 221.879C99.1632 221.847 100.273 220.784 100.321 219.483C100.385 218.183 99.3852 217.152 98.0846 217.183C96.7997 217.199 95.6894 218.278 95.6259 219.578Z" fill="#DEDEDE"/>
    <path d="M273.693 213.281C273.899 214.582 275.105 215.66 276.39 215.708C277.659 215.756 278.515 214.74 278.293 213.44C278.071 212.139 276.866 211.06 275.597 211.013C274.328 210.965 273.471 211.996 273.693 213.281Z" fill="#DEDEDE"/>
    <path d="M266.349 213.059C266.555 214.36 267.745 215.438 269.029 215.47C270.314 215.502 271.171 214.486 270.965 213.202C270.758 211.901 269.553 210.822 268.284 210.775C267.015 210.743 266.158 211.758 266.349 213.059Z" fill="#DEDEDE"/>
    <path d="M259.005 212.837C259.195 214.138 260.385 215.217 261.67 215.248C262.955 215.28 263.827 214.265 263.621 212.964C263.415 211.663 262.225 210.585 260.956 210.553C259.687 210.521 258.815 211.537 259.005 212.837Z" fill="#DEDEDE"/>
    <path d="M251.661 212.646C251.835 213.947 253.025 215.026 254.294 215.057C255.579 215.089 256.467 214.074 256.277 212.773C256.086 211.473 254.913 210.394 253.628 210.362C252.359 210.315 251.471 211.346 251.661 212.646Z" fill="#DEDEDE"/>
    <path d="M244.285 212.456C244.46 213.757 245.634 214.836 246.903 214.868C248.187 214.899 249.076 213.868 248.901 212.583C248.727 211.283 247.553 210.204 246.284 210.172C245.015 210.141 244.127 211.156 244.285 212.456Z" fill="#DEDEDE"/>
    <path d="M236.925 212.298C237.083 213.598 238.241 214.661 239.526 214.693C240.811 214.724 241.715 213.693 241.556 212.393C241.398 211.092 240.224 210.013 238.955 209.998C237.67 209.966 236.766 210.997 236.925 212.298Z" fill="#DEDEDE"/>
    <path d="M229.534 212.139C229.676 213.44 230.834 214.503 232.119 214.535C233.404 214.566 234.324 213.535 234.165 212.235C234.007 210.934 232.849 209.871 231.58 209.839C230.311 209.808 229.391 210.839 229.534 212.139Z" fill="#DEDEDE"/>
    <path d="M222.157 212.012C222.284 213.313 223.442 214.376 224.727 214.407C226.011 214.423 226.947 213.392 226.805 212.107C226.662 210.807 225.52 209.744 224.235 209.712C222.95 209.681 222.014 210.712 222.157 212.012Z" fill="#DEDEDE"/>
    <path d="M214.75 211.885C214.877 213.186 216.003 214.249 217.303 214.265C218.588 214.281 219.524 213.25 219.397 211.949C219.27 210.648 218.128 209.585 216.843 209.57C215.575 209.554 214.639 210.585 214.75 211.885Z" fill="#DEDEDE"/>
    <path d="M207.358 211.79C207.469 213.091 208.596 214.154 209.88 214.17C211.165 214.186 212.117 213.155 212.006 211.854C211.895 210.553 210.753 209.49 209.484 209.475C208.199 209.443 207.247 210.49 207.358 211.79Z" fill="#DEDEDE"/>
    <path d="M199.95 211.695C200.046 212.996 201.172 214.058 202.457 214.074C203.741 214.09 204.709 213.043 204.598 211.758C204.487 210.458 203.377 209.395 202.092 209.379C200.807 209.363 199.855 210.41 199.95 211.695Z" fill="#DEDEDE"/>
    <path d="M192.542 211.632C192.622 212.932 193.748 213.995 195.033 213.995C196.318 214.011 197.285 212.964 197.206 211.663C197.111 210.363 196 209.3 194.716 209.3C193.431 209.284 192.447 210.331 192.542 211.632Z" fill="#DEDEDE"/>
    <path d="M185.12 211.584C185.199 212.885 186.293 213.932 187.594 213.948C188.879 213.963 189.862 212.917 189.783 211.616C189.704 210.315 188.593 209.252 187.309 209.252C186.04 209.237 185.056 210.283 185.12 211.584Z" fill="#DEDEDE"/>
    <path d="M177.711 211.536C177.775 212.837 178.869 213.884 180.154 213.9C181.439 213.9 182.438 212.853 182.359 211.568C182.296 210.267 181.201 209.22 179.916 209.205C178.631 209.205 177.648 210.251 177.711 211.536Z" fill="#DEDEDE"/>
    <path d="M170.288 211.521C170.336 212.821 171.43 213.868 172.715 213.868C174 213.868 174.999 212.821 174.952 211.521C174.888 210.22 173.81 209.173 172.525 209.173C171.24 209.173 170.241 210.236 170.288 211.521Z" fill="#DEDEDE"/>
    <path d="M162.865 211.521C162.897 212.821 163.975 213.868 165.276 213.868C166.577 213.868 167.576 212.821 167.528 211.521C167.481 210.22 166.402 209.173 165.117 209.173C163.832 209.173 162.817 210.22 162.865 211.521Z" fill="#DEDEDE"/>
    <path d="M155.425 211.536C155.457 212.837 156.52 213.884 157.805 213.884C159.09 213.884 160.121 212.821 160.089 211.536C160.057 210.236 158.994 209.189 157.71 209.189C156.425 209.189 155.41 210.236 155.425 211.536Z" fill="#DEDEDE"/>
    <path d="M148.002 211.568C148.018 212.869 149.081 213.916 150.366 213.9C151.666 213.9 152.681 212.837 152.666 211.536C152.65 210.236 151.587 209.189 150.302 209.205C149.017 209.221 147.986 210.268 148.002 211.568Z" fill="#DEDEDE"/>
    <path d="M140.578 211.616C140.578 212.917 141.625 213.963 142.926 213.948C144.227 213.932 145.258 212.885 145.242 211.584C145.226 210.283 144.179 209.237 142.894 209.252C141.609 209.252 140.562 210.315 140.578 211.616Z" fill="#DEDEDE"/>
    <path d="M133.14 211.679C133.124 212.98 134.171 214.011 135.456 214.011C136.756 213.995 137.803 212.933 137.803 211.648C137.803 210.347 136.772 209.3 135.487 209.316C134.203 209.316 133.156 210.379 133.14 211.679Z" fill="#DEDEDE"/>
    <path d="M125.716 211.758C125.701 213.059 126.732 214.09 128.017 214.074C129.317 214.058 130.364 212.996 130.38 211.695C130.396 210.394 129.365 209.363 128.08 209.379C126.795 209.395 125.732 210.458 125.716 211.758Z" fill="#DEDEDE"/>
    <path d="M118.277 211.853C118.245 213.154 119.26 214.185 120.561 214.169C121.861 214.154 122.924 213.091 122.94 211.79C122.972 210.489 121.941 209.458 120.656 209.474C119.387 209.49 118.308 210.553 118.277 211.853Z" fill="#DEDEDE"/>
    <path d="M110.853 211.965C110.806 213.265 111.821 214.296 113.106 214.281C114.39 214.265 115.469 213.186 115.517 211.901C115.548 210.601 114.549 209.57 113.264 209.585C111.979 209.601 110.901 210.68 110.853 211.965Z" fill="#DEDEDE"/>
    <path d="M103.43 212.107C103.366 213.408 104.381 214.439 105.666 214.407C106.967 214.392 108.045 213.313 108.093 212.012C108.141 210.712 107.141 209.681 105.856 209.712C104.572 209.728 103.477 210.807 103.43 212.107Z" fill="#DEDEDE"/>
    <path d="M96.0058 212.25C95.9424 213.551 96.9259 214.582 98.2266 214.55C99.5114 214.518 100.622 213.456 100.669 212.155C100.733 210.854 99.7334 209.823 98.4486 209.855C97.1638 209.871 96.0693 210.949 96.0058 212.25Z" fill="#DEDEDE"/>
    <path d="M88.5836 212.409C88.5043 213.709 89.4877 214.725 90.7726 214.709C92.0574 214.677 93.1677 213.614 93.247 212.314C93.3263 211.013 92.3429 209.982 91.0581 210.014C89.7732 210.045 88.6629 211.108 88.5836 212.409Z" fill="#DEDEDE"/>
    <path d="M81.1588 212.583C81.0636 213.884 82.0312 214.899 83.3319 214.868C84.6167 214.836 85.7429 213.757 85.8223 212.456C85.9016 211.156 84.934 210.141 83.6492 210.172C82.3802 210.22 81.254 211.299 81.1588 212.583Z" fill="#DEDEDE"/>
    <path d="M372.879 210.823C373.259 212.123 374.56 213.234 375.781 213.313C377.003 213.392 377.685 212.409 377.304 211.108C376.908 209.807 375.607 208.697 374.385 208.618C373.18 208.538 372.498 209.522 372.879 210.823Z" fill="#DEDEDE"/>
    <path d="M365.805 210.378C366.17 211.679 367.47 212.789 368.707 212.869C369.945 212.948 370.627 211.965 370.262 210.664C369.881 209.363 368.581 208.253 367.359 208.174C366.122 208.094 365.44 209.078 365.805 210.378Z" fill="#DEDEDE"/>
    <path d="M286.97 206.461C287.208 207.761 288.429 208.84 289.682 208.903C290.935 208.951 291.76 207.936 291.506 206.635C291.252 205.334 290.031 204.24 288.794 204.192C287.557 204.161 286.732 205.16 286.97 206.461Z" fill="#DEDEDE"/>
    <path d="M279.72 206.207C279.942 207.507 281.163 208.586 282.416 208.634C283.685 208.681 284.51 207.666 284.272 206.365C284.034 205.065 282.829 203.986 281.576 203.938C280.322 203.891 279.498 204.906 279.72 206.207Z" fill="#DEDEDE"/>
    <path d="M272.472 205.953C272.694 207.254 273.883 208.332 275.152 208.38C276.421 208.427 277.262 207.412 277.024 206.112C276.802 204.811 275.596 203.732 274.343 203.685C273.09 203.637 272.25 204.652 272.472 205.953Z" fill="#DEDEDE"/>
    <path d="M265.207 205.715C265.413 207.016 266.603 208.094 267.872 208.142C269.141 208.19 269.997 207.158 269.775 205.858C269.553 204.557 268.363 203.478 267.11 203.431C265.841 203.399 265.001 204.43 265.207 205.715Z" fill="#DEDEDE"/>
    <path d="M257.926 205.509C258.117 206.809 259.306 207.888 260.575 207.92C261.844 207.951 262.701 206.936 262.51 205.636C262.304 204.335 261.13 203.256 259.862 203.224C258.593 203.193 257.736 204.208 257.926 205.509Z" fill="#DEDEDE"/>
    <path d="M250.63 205.303C250.804 206.603 251.994 207.682 253.263 207.714C254.532 207.745 255.404 206.73 255.214 205.429C255.023 204.129 253.85 203.05 252.581 203.018C251.328 202.987 250.455 204.002 250.63 205.303Z" fill="#DEDEDE"/>
    <path d="M243.333 205.128C243.508 206.429 244.665 207.507 245.934 207.539C247.203 207.571 248.092 206.54 247.917 205.239C247.743 203.938 246.569 202.86 245.316 202.828C244.047 202.796 243.174 203.827 243.333 205.128Z" fill="#DEDEDE"/>
    <path d="M236.036 204.954C236.195 206.254 237.353 207.317 238.622 207.349C239.891 207.381 240.795 206.35 240.621 205.049C240.462 203.748 239.288 202.67 238.035 202.654C236.766 202.622 235.878 203.653 236.036 204.954Z" fill="#DEDEDE"/>
    <path d="M228.724 204.811C228.867 206.111 230.025 207.174 231.294 207.206C232.563 207.238 233.467 206.207 233.324 204.906C233.165 203.605 232.023 202.542 230.754 202.511C229.47 202.479 228.565 203.51 228.724 204.811Z" fill="#DEDEDE"/>
    <path d="M221.396 204.668C221.523 205.969 222.665 207.032 223.949 207.064C225.218 207.079 226.138 206.048 225.996 204.764C225.853 203.463 224.711 202.4 223.442 202.368C222.173 202.337 221.269 203.368 221.396 204.668Z" fill="#DEDEDE"/>
    <path d="M214.068 204.557C214.195 205.858 215.321 206.92 216.59 206.936C217.859 206.952 218.795 205.921 218.668 204.62C218.541 203.32 217.399 202.257 216.13 202.241C214.877 202.225 213.941 203.256 214.068 204.557Z" fill="#DEDEDE"/>
    <path d="M206.74 204.446C206.851 205.747 207.977 206.81 209.246 206.825C210.515 206.841 211.467 205.81 211.34 204.51C211.229 203.209 210.103 202.146 208.834 202.13C207.565 202.114 206.629 203.161 206.74 204.446Z" fill="#DEDEDE"/>
    <path d="M199.395 204.366C199.49 205.667 200.6 206.73 201.885 206.746C203.154 206.762 204.106 205.715 204.011 204.43C203.9 203.129 202.789 202.066 201.52 202.051C200.251 202.019 199.3 203.066 199.395 204.366Z" fill="#DEDEDE"/>
    <path d="M192.051 204.303C192.13 205.604 193.24 206.667 194.525 206.667C195.81 206.682 196.762 205.636 196.666 204.335C196.571 203.034 195.461 201.971 194.192 201.956C192.923 201.956 191.971 203.002 192.051 204.303Z" fill="#DEDEDE"/>
    <path d="M184.707 204.24C184.786 205.541 185.88 206.603 187.149 206.603C188.434 206.619 189.402 205.572 189.322 204.272C189.243 202.971 188.149 201.908 186.88 201.908C185.611 201.908 184.627 202.939 184.707 204.24Z" fill="#DEDEDE"/>
    <path d="M177.362 204.208C177.426 205.509 178.504 206.556 179.789 206.571C181.074 206.587 182.058 205.525 181.978 204.24C181.915 202.939 180.82 201.892 179.551 201.876C178.266 201.86 177.299 202.907 177.362 204.208Z" fill="#DEDEDE"/>
    <path d="M170.003 204.192C170.05 205.493 171.129 206.54 172.414 206.54C173.698 206.54 174.682 205.493 174.619 204.192C174.555 202.892 173.476 201.845 172.207 201.829C170.938 201.845 169.955 202.892 170.003 204.192Z" fill="#DEDEDE"/>
    <path d="M162.643 204.192C162.675 205.493 163.754 206.54 165.023 206.54C166.307 206.54 167.307 205.493 167.259 204.192C167.212 202.892 166.149 201.845 164.88 201.845C163.611 201.829 162.612 202.892 162.643 204.192Z" fill="#DEDEDE"/>
    <path d="M155.283 204.208C155.315 205.509 156.362 206.555 157.646 206.555C158.931 206.555 159.93 205.493 159.899 204.208C159.867 202.907 158.804 201.86 157.535 201.86C156.266 201.844 155.251 202.907 155.283 204.208Z" fill="#DEDEDE"/>
    <path d="M147.923 204.24C147.939 205.541 148.986 206.588 150.271 206.572C151.555 206.572 152.571 205.509 152.555 204.208C152.539 202.908 151.476 201.861 150.207 201.861C148.938 201.877 147.907 202.939 147.923 204.24Z" fill="#DEDEDE"/>
    <path d="M140.562 204.287C140.562 205.588 141.609 206.635 142.878 206.619C144.163 206.603 145.194 205.556 145.178 204.256C145.162 202.955 144.131 201.908 142.862 201.924C141.594 201.924 140.562 202.987 140.562 204.287Z" fill="#DEDEDE"/>
    <path d="M133.203 204.351C133.187 205.652 134.218 206.683 135.503 206.683C136.788 206.667 137.819 205.604 137.819 204.319C137.819 203.018 136.788 201.971 135.519 201.987C134.25 201.987 133.203 203.05 133.203 204.351Z" fill="#DEDEDE"/>
    <path d="M125.842 204.43C125.827 205.731 126.842 206.762 128.127 206.746C129.411 206.73 130.458 205.667 130.474 204.367C130.49 203.066 129.475 202.035 128.19 202.035C126.905 202.067 125.858 203.129 125.842 204.43Z" fill="#DEDEDE"/>
    <path d="M118.466 204.525C118.434 205.826 119.45 206.857 120.718 206.841C122.003 206.825 123.066 205.763 123.082 204.462C123.114 203.161 122.099 202.13 120.83 202.146C119.561 202.162 118.514 203.225 118.466 204.525Z" fill="#DEDEDE"/>
    <path d="M111.107 204.636C111.06 205.937 112.059 206.968 113.344 206.952C114.628 206.936 115.691 205.858 115.739 204.573C115.771 203.272 114.771 202.241 113.502 202.257C112.233 202.273 111.155 203.336 111.107 204.636Z" fill="#DEDEDE"/>
    <path d="M103.747 204.763C103.683 206.064 104.682 207.095 105.951 207.063C107.236 207.048 108.315 205.969 108.362 204.668C108.41 203.368 107.427 202.337 106.158 202.352C104.889 202.4 103.81 203.463 103.747 204.763Z" fill="#DEDEDE"/>
    <path d="M96.4029 204.906C96.3394 206.207 97.307 207.238 98.5918 207.206C99.8767 207.174 100.955 206.111 101.019 204.811C101.082 203.51 100.099 202.479 98.8297 202.511C97.5608 202.542 96.4663 203.621 96.4029 204.906Z" fill="#DEDEDE"/>
    <path d="M89.0431 205.08C88.9638 206.381 89.9314 207.412 91.2162 207.38C92.501 207.349 93.5955 206.27 93.6748 204.985C93.7541 203.685 92.7865 202.654 91.5017 202.685C90.2328 202.701 89.1224 203.78 89.0431 205.08Z" fill="#DEDEDE"/>
    <path d="M81.6984 205.255C81.6032 206.556 82.5708 207.571 83.8397 207.539C85.1246 207.507 86.2191 206.429 86.3142 205.128C86.3935 203.827 85.4418 202.796 84.1728 202.828C82.9039 202.876 81.7935 203.954 81.6984 205.255Z" fill="#DEDEDE"/>
    <path d="M74.355 205.461C74.244 206.762 75.1957 207.777 76.4805 207.746C77.7653 207.714 78.8757 206.635 78.9709 205.335C79.066 204.034 78.1143 203.019 76.8454 203.05C75.5764 203.082 74.4502 204.161 74.355 205.461Z" fill="#DEDEDE"/>
    <path d="M67.0104 205.667C66.8994 206.968 67.8352 207.983 69.1042 207.951C70.389 207.92 71.4994 206.841 71.6104 205.54C71.7215 204.24 70.7856 203.224 69.5166 203.256C68.2477 203.288 67.1214 204.366 67.0104 205.667Z" fill="#DEDEDE"/>
    <path d="M30.3847 206.968C30.2102 208.269 31.0985 209.268 32.3674 209.22C33.6364 209.173 34.8102 208.078 34.9847 206.778C35.1591 205.477 34.2709 204.478 33.0019 204.525C31.7329 204.589 30.575 205.683 30.3847 206.968Z" fill="#DEDEDE"/>
    <path d="M1.23039 208.316C1.00832 209.617 1.84901 210.616 3.11798 210.553C4.38694 210.489 5.59246 209.395 5.79867 208.094C6.02074 206.794 5.18004 205.794 3.91108 205.858C2.67384 205.905 1.46832 207.016 1.23039 208.316Z" fill="#DEDEDE"/>
    <path d="M370.705 203.494C371.086 204.795 372.387 205.905 373.608 205.985C374.829 206.064 375.496 205.081 375.099 203.78C374.702 202.479 373.402 201.369 372.196 201.29C370.991 201.21 370.325 202.194 370.705 203.494Z" fill="#DEDEDE"/>
    <path d="M363.71 203.05C364.091 204.351 365.376 205.461 366.597 205.541C367.819 205.62 368.501 204.636 368.12 203.336C367.739 202.035 366.439 200.925 365.233 200.845C364.012 200.766 363.33 201.75 363.71 203.05Z" fill="#DEDEDE"/>
    <path d="M356.684 202.622C357.049 203.923 358.333 205.033 359.555 205.096C360.776 205.176 361.474 204.176 361.093 202.891C360.728 201.591 359.428 200.48 358.222 200.401C357.001 200.322 356.319 201.321 356.684 202.622Z" fill="#DEDEDE"/>
    <path d="M264.032 198.387C264.238 199.687 265.428 200.766 266.681 200.814C267.934 200.861 268.775 199.83 268.553 198.53C268.331 197.229 267.141 196.15 265.904 196.103C264.651 196.071 263.81 197.086 264.032 198.387Z" fill="#DEDEDE"/>
    <path d="M256.815 198.165C257.006 199.465 258.195 200.544 259.448 200.576C260.702 200.607 261.558 199.592 261.352 198.292C261.146 196.991 259.972 195.912 258.719 195.881C257.466 195.849 256.609 196.864 256.815 198.165Z" fill="#DEDEDE"/>
    <path d="M249.599 197.974C249.789 199.275 250.947 200.354 252.216 200.385C253.469 200.417 254.341 199.402 254.135 198.101C253.945 196.8 252.771 195.722 251.518 195.69C250.265 195.642 249.408 196.674 249.599 197.974Z" fill="#DEDEDE"/>
    <path d="M242.365 197.784C242.54 199.085 243.698 200.163 244.951 200.195C246.204 200.227 247.076 199.196 246.902 197.895C246.728 196.594 245.554 195.516 244.316 195.484C243.063 195.468 242.191 196.483 242.365 197.784Z" fill="#DEDEDE"/>
    <path d="M235.132 197.625C235.291 198.926 236.449 200.005 237.702 200.02C238.955 200.052 239.843 199.021 239.685 197.72C239.51 196.42 238.368 195.341 237.115 195.325C235.846 195.294 234.974 196.325 235.132 197.625Z" fill="#DEDEDE"/>
    <path d="M227.883 197.467C228.026 198.767 229.168 199.83 230.437 199.862C231.69 199.894 232.594 198.862 232.436 197.562C232.277 196.261 231.135 195.182 229.882 195.167C228.629 195.135 227.74 196.166 227.883 197.467Z" fill="#DEDEDE"/>
    <path d="M220.635 197.34C220.778 198.64 221.904 199.703 223.173 199.735C224.442 199.751 225.346 198.72 225.203 197.435C225.06 196.134 223.918 195.071 222.665 195.04C221.396 195.008 220.492 196.039 220.635 197.34Z" fill="#DEDEDE"/>
    <path d="M213.37 197.213C213.497 198.514 214.623 199.576 215.876 199.592C217.145 199.608 218.065 198.577 217.923 197.276C217.796 195.976 216.669 194.913 215.416 194.897C214.163 194.881 213.243 195.912 213.37 197.213Z" fill="#DEDEDE"/>
    <path d="M206.105 197.118C206.216 198.419 207.326 199.481 208.595 199.497C209.864 199.513 210.784 198.482 210.673 197.181C210.546 195.881 209.436 194.818 208.183 194.802C206.929 194.77 205.994 195.817 206.105 197.118Z" fill="#DEDEDE"/>
    <path d="M198.84 197.022C198.935 198.323 200.045 199.386 201.314 199.402C202.583 199.418 203.519 198.371 203.408 197.086C203.297 195.785 202.187 194.722 200.933 194.706C199.68 194.691 198.744 195.738 198.84 197.022Z" fill="#DEDEDE"/>
    <path d="M191.559 196.959C191.639 198.26 192.749 199.323 194.002 199.323C195.271 199.338 196.223 198.292 196.128 196.991C196.032 195.69 194.938 194.627 193.685 194.627C192.432 194.612 191.48 195.658 191.559 196.959Z" fill="#DEDEDE"/>
    <path d="M184.279 196.912C184.359 198.212 185.437 199.275 186.706 199.275C187.975 199.291 188.927 198.244 188.848 196.943C188.768 195.643 187.674 194.58 186.421 194.58C185.168 194.564 184.216 195.611 184.279 196.912Z" fill="#DEDEDE"/>
    <path d="M176.998 196.864C177.062 198.165 178.14 199.212 179.409 199.227C180.678 199.227 181.646 198.181 181.582 196.896C181.503 195.595 180.424 194.548 179.171 194.532C177.902 194.532 176.935 195.579 176.998 196.864Z" fill="#DEDEDE"/>
    <path d="M169.717 196.848C169.765 198.149 170.843 199.196 172.112 199.196C173.381 199.196 174.365 198.149 174.301 196.848C174.238 195.547 173.175 194.5 171.922 194.5C170.653 194.5 169.669 195.547 169.717 196.848Z" fill="#DEDEDE"/>
    <path d="M162.421 196.848C162.453 198.149 163.516 199.196 164.785 199.196C166.054 199.196 167.037 198.149 166.989 196.848C166.942 195.547 165.879 194.5 164.626 194.5C163.373 194.5 162.389 195.547 162.421 196.848Z" fill="#DEDEDE"/>
    <path d="M155.139 196.864C155.171 198.165 156.218 199.212 157.487 199.212C158.756 199.212 159.755 198.149 159.724 196.864C159.692 195.563 158.645 194.516 157.376 194.516C156.107 194.516 155.108 195.563 155.139 196.864Z" fill="#DEDEDE"/>
    <path d="M147.843 196.896C147.859 198.196 148.89 199.243 150.159 199.228C151.428 199.228 152.443 198.165 152.412 196.864C152.396 195.563 151.349 194.516 150.096 194.516C148.843 194.548 147.828 195.595 147.843 196.896Z" fill="#DEDEDE"/>
    <path d="M140.547 196.943C140.547 198.244 141.578 199.291 142.847 199.275C144.116 199.259 145.131 198.212 145.131 196.912C145.116 195.611 144.085 194.564 142.832 194.58C141.578 194.58 140.547 195.643 140.547 196.943Z" fill="#DEDEDE"/>
    <path d="M133.251 197.007C133.235 198.307 134.266 199.338 135.535 199.338C136.804 199.323 137.835 198.26 137.835 196.975C137.835 195.674 136.82 194.627 135.551 194.643C134.298 194.643 133.267 195.706 133.251 197.007Z" fill="#DEDEDE"/>
    <path d="M125.969 197.086C125.938 198.387 126.953 199.418 128.222 199.402C129.491 199.386 130.538 198.323 130.554 197.023C130.569 195.722 129.554 194.691 128.301 194.691C127.032 194.723 125.985 195.785 125.969 197.086Z" fill="#DEDEDE"/>
    <path d="M118.673 197.181C118.641 198.482 119.641 199.513 120.91 199.497C122.179 199.481 123.226 198.418 123.257 197.118C123.289 195.817 122.29 194.786 121.021 194.802C119.752 194.818 118.705 195.896 118.673 197.181Z" fill="#DEDEDE"/>
    <path d="M111.377 197.292C111.329 198.593 112.313 199.624 113.582 199.608C114.85 199.592 115.913 198.514 115.945 197.229C115.993 195.928 114.993 194.897 113.74 194.913C112.487 194.929 111.424 196.008 111.377 197.292Z" fill="#DEDEDE"/>
    <path d="M104.096 197.435C104.032 198.735 105.016 199.767 106.285 199.735C107.554 199.719 108.616 198.64 108.68 197.34C108.727 196.039 107.744 195.008 106.491 195.024C105.222 195.055 104.143 196.134 104.096 197.435Z" fill="#DEDEDE"/>
    <path d="M96.7995 197.578C96.7202 198.878 97.6878 199.909 98.9567 199.878C100.226 199.846 101.304 198.783 101.368 197.482C101.431 196.182 100.464 195.151 99.2105 195.182C97.9574 195.214 96.8788 196.277 96.7995 197.578Z" fill="#DEDEDE"/>
    <path d="M89.5188 197.736C89.4395 199.037 90.3912 200.068 91.6602 200.036C92.9291 200.005 94.0236 198.926 94.0871 197.641C94.1664 196.34 93.1988 195.309 91.9457 195.341C90.6926 195.373 89.5981 196.452 89.5188 197.736Z" fill="#DEDEDE"/>
    <path d="M82.2385 197.927C82.1433 199.227 83.095 200.242 84.364 200.211C85.633 200.179 86.7275 199.1 86.8227 197.8C86.9178 196.499 85.9661 195.468 84.6971 195.5C83.4282 195.547 82.3337 196.626 82.2385 197.927Z" fill="#DEDEDE"/>
    <path d="M74.9577 198.117C74.8466 199.418 75.7825 200.433 77.0515 200.401C78.3204 200.369 79.4308 199.291 79.526 197.99C79.6211 196.689 78.6853 195.674 77.4322 195.706C76.179 195.738 75.0687 196.816 74.9577 198.117Z" fill="#DEDEDE"/>
    <path d="M67.6761 198.339C67.5492 199.64 68.485 200.655 69.754 200.623C71.023 200.592 72.1333 199.497 72.2444 198.212C72.3554 196.912 71.4354 195.896 70.1664 195.928C68.9292 195.96 67.803 197.039 67.6761 198.339Z" fill="#DEDEDE"/>
    <path d="M16.9491 200.275C16.7429 201.575 17.5994 202.575 18.8525 202.511C20.1056 202.448 21.2953 201.353 21.4857 200.053C21.676 198.752 20.8353 197.753 19.5822 197.8C18.3291 197.88 17.1553 198.99 16.9491 200.275Z" fill="#DEDEDE"/>
    <path d="M9.73334 200.623C9.51127 201.924 10.3519 202.923 11.605 202.86C12.8582 202.797 14.0478 201.702 14.254 200.401C14.4602 199.101 13.6195 198.101 12.3823 198.165C11.1451 198.228 9.9554 199.323 9.73334 200.623Z" fill="#DEDEDE"/>
    <path d="M368.501 196.166C368.898 197.467 370.183 198.577 371.388 198.656C372.594 198.736 373.244 197.752 372.848 196.452C372.451 195.151 371.15 194.041 369.961 193.961C368.771 193.882 368.121 194.865 368.501 196.166Z" fill="#DEDEDE"/>
    <path d="M361.569 195.722C361.95 197.023 363.235 198.133 364.441 198.212C365.646 198.292 366.312 197.308 365.932 196.007C365.535 194.707 364.25 193.596 363.061 193.517C361.855 193.438 361.189 194.421 361.569 195.722Z" fill="#DEDEDE"/>
    <path d="M284.194 191.788C284.448 193.089 285.653 194.167 286.891 194.231C288.128 194.278 288.921 193.263 288.667 191.963C288.413 190.662 287.208 189.567 285.971 189.52C284.733 189.488 283.956 190.503 284.194 191.788Z" fill="#DEDEDE"/>
    <path d="M277.087 191.534C277.325 192.835 278.515 193.913 279.752 193.961C280.99 194.009 281.798 192.993 281.545 191.693C281.307 190.392 280.101 189.313 278.88 189.266C277.658 189.218 276.85 190.233 277.087 191.534Z" fill="#DEDEDE"/>
    <path d="M269.966 191.281C270.188 192.581 271.378 193.66 272.615 193.708C273.852 193.755 274.677 192.74 274.439 191.439C274.201 190.139 273.011 189.06 271.79 189.012C270.553 188.965 269.744 189.98 269.966 191.281Z" fill="#DEDEDE"/>
    <path d="M262.828 191.058C263.035 192.359 264.224 193.437 265.462 193.485C266.699 193.517 267.524 192.502 267.317 191.217C267.095 189.916 265.922 188.837 264.684 188.79C263.447 188.726 262.622 189.757 262.828 191.058Z" fill="#DEDEDE"/>
    <path d="M255.69 190.836C255.896 192.137 257.054 193.216 258.307 193.247C259.544 193.279 260.385 192.264 260.179 190.963C259.973 189.662 258.799 188.584 257.562 188.552C256.324 188.52 255.484 189.536 255.69 190.836Z" fill="#DEDEDE"/>
    <path d="M248.537 190.63C248.727 191.931 249.885 193.009 251.138 193.041C252.391 193.073 253.232 192.058 253.042 190.757C252.851 189.456 251.677 188.378 250.44 188.346C249.203 188.314 248.346 189.345 248.537 190.63Z" fill="#DEDEDE"/>
    <path d="M241.382 190.456C241.556 191.756 242.714 192.835 243.952 192.867C245.205 192.898 246.061 191.867 245.871 190.583C245.681 189.282 244.538 188.203 243.301 188.172C242.064 188.124 241.207 189.155 241.382 190.456Z" fill="#DEDEDE"/>
    <path d="M234.212 190.281C234.371 191.582 235.513 192.645 236.766 192.676C238.019 192.708 238.891 191.677 238.717 190.376C238.542 189.076 237.4 187.997 236.163 187.981C234.926 187.965 234.053 188.98 234.212 190.281Z" fill="#DEDEDE"/>
    <path d="M227.028 190.138C227.186 191.439 228.313 192.502 229.566 192.534C230.819 192.565 231.691 191.534 231.533 190.234C231.374 188.933 230.232 187.87 228.995 187.838C227.773 187.807 226.885 188.838 227.028 190.138Z" fill="#DEDEDE"/>
    <path d="M219.857 189.996C220 191.297 221.126 192.359 222.363 192.391C223.616 192.407 224.504 191.376 224.362 190.091C224.219 188.79 223.093 187.728 221.84 187.696C220.602 187.664 219.714 188.695 219.857 189.996Z" fill="#DEDEDE"/>
    <path d="M212.672 189.885C212.799 191.185 213.909 192.248 215.162 192.264C216.415 192.28 217.32 191.249 217.177 189.948C217.034 188.647 215.924 187.585 214.687 187.569C213.449 187.553 212.545 188.584 212.672 189.885Z" fill="#DEDEDE"/>
    <path d="M205.47 189.774C205.581 191.075 206.691 192.137 207.944 192.153C209.198 192.169 210.118 191.138 209.991 189.837C209.864 188.537 208.753 187.474 207.516 187.458C206.279 187.442 205.359 188.489 205.47 189.774Z" fill="#DEDEDE"/>
    <path d="M198.269 189.694C198.365 190.995 199.475 192.058 200.728 192.074C201.981 192.09 202.901 191.043 202.806 189.758C202.695 188.457 201.6 187.394 200.347 187.379C199.094 187.363 198.174 188.394 198.269 189.694Z" fill="#DEDEDE"/>
    <path d="M191.067 189.631C191.162 190.932 192.241 191.994 193.494 191.994C194.747 192.01 195.683 190.963 195.588 189.663C195.493 188.362 194.398 187.299 193.161 187.299C191.908 187.283 190.972 188.33 191.067 189.631Z" fill="#DEDEDE"/>
    <path d="M183.85 189.568C183.929 190.868 185.008 191.931 186.261 191.931C187.514 191.947 188.466 190.9 188.386 189.599C188.307 188.299 187.228 187.236 185.975 187.236C184.722 187.236 183.786 188.283 183.85 189.568Z" fill="#DEDEDE"/>
    <path d="M176.649 189.536C176.712 190.836 177.775 191.883 179.044 191.899C180.297 191.899 181.265 190.852 181.186 189.567C181.106 188.267 180.043 187.22 178.79 187.204C177.537 187.188 176.586 188.235 176.649 189.536Z" fill="#DEDEDE"/>
    <path d="M169.432 189.52C169.479 190.821 170.542 191.868 171.795 191.868C173.048 191.868 174.016 190.821 173.968 189.52C173.905 188.219 172.842 187.172 171.605 187.172C170.352 187.172 169.384 188.219 169.432 189.52Z" fill="#DEDEDE"/>
    <path d="M162.215 189.52C162.247 190.821 163.309 191.868 164.563 191.868C165.816 191.868 166.799 190.821 166.752 189.52C166.704 188.219 165.657 187.172 164.404 187.172C163.151 187.172 162.167 188.219 162.215 189.52Z" fill="#DEDEDE"/>
    <path d="M154.981 189.536C155.013 190.836 156.044 191.883 157.297 191.883C158.55 191.883 159.549 190.82 159.518 189.536C159.486 188.235 158.439 187.188 157.202 187.188C155.949 187.172 154.965 188.235 154.981 189.536Z" fill="#DEDEDE"/>
    <path d="M147.764 189.567C147.78 190.868 148.811 191.915 150.064 191.899C151.317 191.899 152.316 190.836 152.3 189.536C152.285 188.235 151.254 187.188 150 187.204C148.747 187.204 147.748 188.267 147.764 189.567Z" fill="#DEDEDE"/>
    <path d="M140.547 189.615C140.547 190.916 141.563 191.963 142.832 191.947C144.085 191.931 145.1 190.884 145.084 189.583C145.068 188.283 144.053 187.236 142.8 187.252C141.563 187.252 140.547 188.314 140.547 189.615Z" fill="#DEDEDE"/>
    <path d="M133.313 189.679C133.297 190.979 134.312 192.01 135.566 192.01C136.819 191.994 137.85 190.932 137.85 189.647C137.85 188.346 136.85 187.315 135.597 187.315C134.36 187.315 133.329 188.378 133.313 189.679Z" fill="#DEDEDE"/>
    <path d="M126.097 189.758C126.066 191.059 127.065 192.09 128.334 192.074C129.587 192.058 130.618 190.995 130.634 189.694C130.65 188.394 129.651 187.363 128.397 187.379C127.16 187.394 126.129 188.457 126.097 189.758Z" fill="#DEDEDE"/>
    <path d="M118.879 189.853C118.847 191.154 119.831 192.185 121.084 192.169C122.337 192.153 123.384 191.09 123.416 189.79C123.447 188.489 122.448 187.458 121.211 187.474C119.958 187.49 118.911 188.553 118.879 189.853Z" fill="#DEDEDE"/>
    <path d="M111.646 189.964C111.599 191.265 112.582 192.296 113.835 192.28C115.088 192.264 116.151 191.185 116.183 189.901C116.23 188.6 115.247 187.569 113.994 187.585C112.757 187.601 111.694 188.663 111.646 189.964Z" fill="#DEDEDE"/>
    <path d="M104.429 190.091C104.366 191.392 105.333 192.423 106.586 192.391C107.84 192.375 108.902 191.297 108.95 189.996C108.997 188.695 108.03 187.664 106.793 187.696C105.555 187.728 104.493 188.806 104.429 190.091Z" fill="#DEDEDE"/>
    <path d="M97.2111 190.249C97.1318 191.55 98.0994 192.581 99.3525 192.549C100.606 192.518 101.684 191.455 101.748 190.154C101.811 188.854 100.859 187.823 99.6063 187.854C98.3532 187.87 97.2904 188.949 97.2111 190.249Z" fill="#DEDEDE"/>
    <path d="M89.9948 190.408C89.8997 191.709 90.8514 192.74 92.1045 192.708C93.3576 192.676 94.4362 191.598 94.5155 190.313C94.5949 189.012 93.6431 187.981 92.4059 188.013C91.1686 188.029 90.09 189.107 89.9948 190.408Z" fill="#DEDEDE"/>
    <path d="M82.7933 190.582C82.6981 191.883 83.634 192.898 84.8871 192.867C86.1402 192.835 87.2346 191.756 87.3298 190.456C87.425 189.155 86.4892 188.14 85.2361 188.171C83.983 188.219 82.8885 189.298 82.7933 190.582Z" fill="#DEDEDE"/>
    <path d="M75.5755 190.789C75.4645 192.09 76.3844 193.105 77.6375 193.073C78.8906 193.041 79.9851 191.963 80.0961 190.662C80.1913 189.361 79.2713 188.346 78.0341 188.378C76.7968 188.41 75.6865 189.488 75.5755 190.789Z" fill="#DEDEDE"/>
    <path d="M68.374 190.995C68.2471 192.296 69.1671 193.311 70.4202 193.279C71.6733 193.248 72.7837 192.153 72.8947 190.868C73.0057 189.568 72.1016 188.552 70.8485 188.584C69.6112 188.616 68.5009 189.71 68.374 190.995Z" fill="#DEDEDE"/>
    <path d="M373.133 189.298C373.546 190.599 374.846 191.709 376.036 191.788C377.226 191.868 377.86 190.884 377.432 189.599C377.02 188.299 375.719 187.188 374.529 187.109C373.355 187.014 372.721 188.013 373.133 189.298Z" fill="#DEDEDE"/>
    <path d="M366.28 188.838C366.676 190.139 367.961 191.249 369.167 191.328C370.356 191.408 371.007 190.424 370.594 189.124C370.182 187.823 368.897 186.713 367.707 186.633C366.518 186.554 365.883 187.537 366.28 188.838Z" fill="#DEDEDE"/>
    <path d="M359.396 188.394C359.777 189.695 361.061 190.805 362.267 190.884C363.472 190.964 364.123 189.98 363.726 188.679C363.33 187.379 362.045 186.268 360.855 186.189C359.665 186.11 359.015 187.093 359.396 188.394Z" fill="#DEDEDE"/>
    <path d="M352.512 187.966C352.893 189.266 354.162 190.377 355.368 190.44C356.573 190.519 357.239 189.52 356.843 188.235C356.462 186.935 355.177 185.824 353.988 185.761C352.798 185.682 352.132 186.665 352.512 187.966Z" fill="#DEDEDE"/>
    <path d="M296.819 185.047C297.089 186.348 298.31 187.442 299.532 187.49C300.753 187.537 301.515 186.538 301.229 185.237C300.944 183.937 299.722 182.842 298.517 182.795C297.311 182.731 296.55 183.746 296.819 185.047Z" fill="#DEDEDE"/>
    <path d="M289.809 184.745C290.079 186.046 291.284 187.125 292.506 187.188C293.727 187.236 294.505 186.236 294.219 184.936C293.949 183.635 292.744 182.54 291.522 182.493C290.317 182.445 289.54 183.445 289.809 184.745Z" fill="#DEDEDE"/>
    <path d="M282.783 184.46C283.037 185.76 284.226 186.839 285.464 186.887C286.685 186.934 287.462 185.919 287.208 184.634C286.939 183.334 285.749 182.255 284.528 182.207C283.306 182.16 282.529 183.175 282.783 184.46Z" fill="#DEDEDE"/>
    <path d="M275.74 184.206C275.977 185.507 277.167 186.585 278.388 186.633C279.61 186.681 280.403 185.665 280.165 184.365C279.911 183.064 278.722 181.985 277.516 181.938C276.279 181.89 275.502 182.905 275.74 184.206Z" fill="#DEDEDE"/>
    <path d="M268.681 183.952C268.903 185.253 270.093 186.331 271.314 186.379C272.535 186.427 273.344 185.411 273.106 184.111C272.868 182.81 271.695 181.731 270.473 181.684C269.252 181.636 268.459 182.651 268.681 183.952Z" fill="#DEDEDE"/>
    <path d="M261.622 183.714C261.844 185.015 263.002 186.093 264.239 186.125C265.477 186.173 266.286 185.142 266.063 183.857C265.841 182.556 264.668 181.478 263.446 181.446C262.225 181.414 261.4 182.429 261.622 183.714Z" fill="#DEDEDE"/>
    <path d="M254.548 183.508C254.754 184.809 255.912 185.887 257.149 185.919C258.386 185.951 259.211 184.936 258.989 183.635C258.783 182.334 257.609 181.256 256.388 181.224C255.166 181.192 254.342 182.207 254.548 183.508Z" fill="#DEDEDE"/>
    <path d="M247.473 183.302C247.663 184.603 248.821 185.681 250.043 185.713C251.28 185.745 252.121 184.729 251.914 183.429C251.708 182.128 250.566 181.049 249.345 181.018C248.108 180.986 247.283 182.017 247.473 183.302Z" fill="#DEDEDE"/>
    <path d="M240.383 183.127C240.558 184.428 241.7 185.507 242.937 185.523C244.174 185.554 245.015 184.523 244.841 183.238C244.65 181.938 243.508 180.859 242.287 180.827C241.05 180.796 240.193 181.827 240.383 183.127Z" fill="#DEDEDE"/>
    <path d="M233.277 182.953C233.436 184.254 234.578 185.316 235.815 185.348C237.052 185.38 237.909 184.349 237.734 183.048C237.56 181.747 236.418 180.685 235.197 180.653C233.975 180.637 233.119 181.652 233.277 182.953Z" fill="#DEDEDE"/>
    <path d="M226.171 182.81C226.33 184.111 227.456 185.174 228.693 185.206C229.93 185.237 230.803 184.206 230.644 182.906C230.486 181.605 229.359 180.542 228.122 180.51C226.901 180.479 226.028 181.51 226.171 182.81Z" fill="#DEDEDE"/>
    <path d="M219.064 182.667C219.207 183.968 220.317 185.031 221.554 185.046C222.791 185.062 223.68 184.031 223.521 182.746C223.378 181.446 222.252 180.383 221.031 180.351C219.809 180.335 218.921 181.366 219.064 182.667Z" fill="#DEDEDE"/>
    <path d="M211.942 182.557C212.069 183.857 213.179 184.92 214.417 184.936C215.654 184.952 216.558 183.921 216.415 182.62C216.272 181.319 215.178 180.257 213.941 180.241C212.719 180.225 211.815 181.256 211.942 182.557Z" fill="#DEDEDE"/>
    <path d="M204.82 182.445C204.931 183.746 206.026 184.809 207.279 184.825C208.516 184.84 209.42 183.809 209.293 182.509C209.166 181.208 208.072 180.145 206.835 180.129C205.613 180.113 204.709 181.16 204.82 182.445Z" fill="#DEDEDE"/>
    <path d="M197.699 182.366C197.794 183.667 198.888 184.73 200.125 184.745C201.363 184.761 202.283 183.714 202.172 182.43C202.061 181.129 200.966 180.066 199.745 180.05C198.508 180.034 197.588 181.065 197.699 182.366Z" fill="#DEDEDE"/>
    <path d="M190.56 182.302C190.655 183.603 191.734 184.65 192.971 184.666C194.208 184.682 195.144 183.635 195.049 182.334C194.954 181.034 193.875 179.971 192.638 179.971C191.401 179.955 190.465 181.002 190.56 182.302Z" fill="#DEDEDE"/>
    <path d="M183.422 182.239C183.501 183.54 184.564 184.587 185.817 184.602C187.055 184.618 188.006 183.571 187.911 182.271C187.832 180.97 186.753 179.923 185.516 179.907C184.279 179.907 183.343 180.954 183.422 182.239Z" fill="#DEDEDE"/>
    <path d="M176.285 182.208C176.348 183.508 177.411 184.555 178.648 184.571C179.885 184.571 180.837 183.524 180.774 182.239C180.694 180.939 179.647 179.892 178.41 179.876C177.173 179.86 176.221 180.907 176.285 182.208Z" fill="#DEDEDE"/>
    <path d="M169.13 182.191C169.178 183.492 170.225 184.539 171.478 184.539C172.715 184.539 173.683 183.492 173.619 182.191C173.556 180.891 172.509 179.844 171.271 179.844C170.05 179.844 169.083 180.891 169.13 182.191Z" fill="#DEDEDE"/>
    <path d="M161.992 182.191C162.024 183.492 163.071 184.539 164.308 184.539C165.545 184.539 166.528 183.492 166.481 182.191C166.433 180.891 165.386 179.844 164.165 179.844C162.928 179.844 161.944 180.891 161.992 182.191Z" fill="#DEDEDE"/>
    <path d="M154.839 182.207C154.871 183.508 155.902 184.555 157.139 184.555C158.376 184.555 159.36 183.508 159.328 182.207C159.296 180.907 158.265 179.86 157.028 179.86C155.791 179.86 154.807 180.907 154.839 182.207Z" fill="#DEDEDE"/>
    <path d="M147.685 182.239C147.701 183.54 148.716 184.587 149.969 184.571C151.222 184.571 152.206 183.508 152.19 182.208C152.174 180.907 151.143 179.86 149.906 179.876C148.668 179.876 147.685 180.939 147.685 182.239Z" fill="#DEDEDE"/>
    <path d="M140.532 182.287C140.532 183.588 141.547 184.619 142.784 184.619C144.021 184.603 145.037 183.556 145.021 182.255C145.005 180.954 144.006 179.923 142.768 179.923C141.547 179.923 140.532 180.986 140.532 182.287Z" fill="#DEDEDE"/>
    <path d="M133.393 182.35C133.377 183.651 134.377 184.682 135.63 184.682C136.867 184.666 137.882 183.603 137.882 182.319C137.882 181.018 136.883 179.987 135.646 179.987C134.408 179.987 133.393 181.05 133.393 182.35Z" fill="#DEDEDE"/>
    <path d="M126.24 182.43C126.208 183.73 127.208 184.761 128.445 184.745C129.682 184.73 130.713 183.667 130.729 182.366C130.745 181.065 129.761 180.034 128.524 180.05C127.287 180.066 126.256 181.129 126.24 182.43Z" fill="#DEDEDE"/>
    <path d="M119.085 182.525C119.054 183.825 120.021 184.856 121.274 184.84C122.527 184.824 123.558 183.762 123.574 182.461C123.606 181.16 122.622 180.129 121.385 180.145C120.148 180.161 119.117 181.224 119.085 182.525Z" fill="#DEDEDE"/>
    <path d="M111.932 182.636C111.884 183.937 112.852 184.968 114.089 184.952C115.326 184.936 116.373 183.857 116.421 182.573C116.468 181.272 115.501 180.241 114.264 180.257C113.026 180.273 111.979 181.335 111.932 182.636Z" fill="#DEDEDE"/>
    <path d="M104.778 182.763C104.715 184.063 105.667 185.094 106.92 185.063C108.157 185.047 109.22 183.968 109.267 182.667C109.315 181.367 108.363 180.336 107.126 180.367C105.905 180.399 104.842 181.478 104.778 182.763Z" fill="#DEDEDE"/>
    <path d="M97.6404 182.922C97.5611 184.222 98.5128 185.253 99.75 185.222C100.987 185.19 102.05 184.127 102.129 182.826C102.193 181.526 101.257 180.495 100.02 180.526C98.7824 180.542 97.7197 181.621 97.6404 182.922Z" fill="#DEDEDE"/>
    <path d="M90.4862 183.08C90.391 184.381 91.3269 185.396 92.58 185.38C93.8172 185.348 94.8958 184.27 94.9751 182.985C95.0544 181.684 94.1185 180.669 92.8813 180.685C91.6599 180.716 90.5813 181.779 90.4862 183.08Z" fill="#DEDEDE"/>
    <path d="M83.3481 183.254C83.2529 184.555 84.1729 185.57 85.4101 185.539C86.6474 185.507 87.7419 184.428 87.8212 183.143C87.9164 181.843 86.9963 180.828 85.7591 180.859C84.5377 180.891 83.4591 181.97 83.3481 183.254Z" fill="#DEDEDE"/>
    <path d="M76.2103 183.46C76.0992 184.761 77.0192 185.776 78.2565 185.744C79.4937 185.713 80.5882 184.634 80.6992 183.333C80.8102 182.033 79.8903 181.018 78.653 181.049C77.4158 181.081 76.3372 182.16 76.2103 183.46Z" fill="#DEDEDE"/>
    <path d="M69.0881 183.667C68.9612 184.968 69.8653 185.983 71.1026 185.951C72.3398 185.919 73.4501 184.841 73.5612 183.54C73.6722 182.239 72.7681 181.224 71.5467 181.256C70.3095 181.303 69.2149 182.382 69.0881 183.667Z" fill="#DEDEDE"/>
    <path d="M-1.8951 186.696C-2.1489 187.997 -1.3558 188.981 -0.11856 188.917C1.10282 188.854 2.30835 187.743 2.54628 186.459C2.78421 185.158 1.99109 184.159 0.769712 184.238C-0.451667 184.301 -1.64131 185.412 -1.8951 186.696Z" fill="#DEDEDE"/>
    <path d="M370.801 181.985C371.213 183.286 372.514 184.396 373.688 184.476C374.861 184.555 375.48 183.571 375.068 182.287C374.639 180.986 373.354 179.876 372.181 179.796C371.007 179.701 370.388 180.685 370.801 181.985Z" fill="#DEDEDE"/>
    <path d="M364.012 181.525C364.409 182.826 365.694 183.936 366.883 184.016C368.073 184.095 368.692 183.111 368.279 181.811C367.867 180.51 366.582 179.4 365.408 179.32C364.235 179.241 363.616 180.225 364.012 181.525Z" fill="#DEDEDE"/>
    <path d="M357.207 181.065C357.603 182.366 358.872 183.476 360.062 183.54C361.252 183.619 361.886 182.636 361.49 181.335C361.093 180.034 359.808 178.924 358.634 178.861C357.445 178.797 356.81 179.781 357.207 181.065Z" fill="#DEDEDE"/>
    <path d="M350.37 180.637C350.751 181.938 352.02 183.048 353.21 183.111C354.399 183.191 355.05 182.191 354.669 180.907C354.288 179.606 353.004 178.511 351.83 178.432C350.656 178.353 350.006 179.352 350.37 180.637Z" fill="#DEDEDE"/>
    <path d="M343.535 180.225C343.899 181.525 345.168 182.62 346.358 182.699C347.548 182.778 348.214 181.779 347.833 180.494C347.452 179.194 346.199 178.099 345.01 178.02C343.836 177.94 343.17 178.924 343.535 180.225Z" fill="#DEDEDE"/>
    <path d="M302.181 178.036C302.467 179.336 303.688 180.431 304.894 180.478C306.099 180.542 306.829 179.527 306.528 178.242C306.226 176.941 305.005 175.847 303.815 175.799C302.626 175.736 301.896 176.735 302.181 178.036Z" fill="#DEDEDE"/>
    <path d="M295.249 177.718C295.535 179.019 296.74 180.098 297.946 180.161C299.151 180.209 299.897 179.209 299.611 177.909C299.326 176.608 298.12 175.529 296.915 175.466C295.709 175.418 294.964 176.434 295.249 177.718Z" fill="#DEDEDE"/>
    <path d="M288.302 177.433C288.571 178.734 289.761 179.812 290.982 179.86C292.188 179.908 292.949 178.908 292.68 177.608C292.394 176.307 291.204 175.228 289.999 175.181C288.778 175.117 288.032 176.132 288.302 177.433Z" fill="#DEDEDE"/>
    <path d="M281.339 177.147C281.593 178.448 282.783 179.527 284.004 179.574C285.21 179.622 285.987 178.607 285.717 177.322C285.448 176.021 284.258 174.943 283.052 174.895C281.847 174.847 281.086 175.847 281.339 177.147Z" fill="#DEDEDE"/>
    <path d="M274.359 176.878C274.597 178.179 275.787 179.257 276.992 179.305C278.214 179.353 278.991 178.337 278.737 177.037C278.483 175.736 277.31 174.657 276.104 174.61C274.899 174.578 274.121 175.593 274.359 176.878Z" fill="#DEDEDE"/>
    <path d="M267.38 176.64C267.618 177.941 268.776 179.019 269.997 179.067C271.219 179.114 272.012 178.099 271.758 176.798C271.52 175.498 270.346 174.419 269.141 174.372C267.935 174.324 267.158 175.339 267.38 176.64Z" fill="#DEDEDE"/>
    <path d="M260.385 176.402C260.607 177.703 261.765 178.781 262.986 178.813C264.207 178.845 265.001 177.829 264.778 176.545C264.556 175.244 263.383 174.165 262.177 174.134C260.972 174.086 260.178 175.101 260.385 176.402Z" fill="#DEDEDE"/>
    <path d="M253.389 176.18C253.596 177.481 254.754 178.559 255.975 178.591C257.196 178.623 258.005 177.607 257.799 176.323C257.577 175.022 256.435 173.943 255.214 173.912C253.992 173.88 253.183 174.895 253.389 176.18Z" fill="#DEDEDE"/>
    <path d="M246.378 175.99C246.569 177.29 247.711 178.353 248.932 178.401C250.154 178.432 250.978 177.417 250.772 176.117C250.566 174.816 249.424 173.753 248.218 173.706C247.013 173.674 246.188 174.689 246.378 175.99Z" fill="#DEDEDE"/>
    <path d="M239.368 175.799C239.542 177.1 240.684 178.163 241.906 178.194C243.127 178.226 243.968 177.195 243.777 175.91C243.587 174.609 242.445 173.547 241.239 173.515C240.018 173.483 239.177 174.514 239.368 175.799Z" fill="#DEDEDE"/>
    <path d="M232.34 175.64C232.499 176.941 233.641 178.004 234.863 178.036C236.084 178.067 236.94 177.036 236.75 175.751C236.576 174.451 235.449 173.388 234.228 173.356C233.023 173.309 232.166 174.34 232.34 175.64Z" fill="#DEDEDE"/>
    <path d="M225.298 175.482C225.456 176.783 226.567 177.846 227.788 177.877C229.01 177.909 229.866 176.878 229.707 175.577C229.549 174.277 228.423 173.214 227.217 173.182C226.012 173.166 225.155 174.197 225.298 175.482Z" fill="#DEDEDE"/>
    <path d="M218.255 175.355C218.398 176.656 219.508 177.718 220.73 177.734C221.951 177.75 222.823 176.735 222.681 175.434C222.522 174.134 221.428 173.071 220.206 173.055C218.985 173.023 218.128 174.054 218.255 175.355Z" fill="#DEDEDE"/>
    <path d="M211.212 175.228C211.339 176.529 212.434 177.592 213.671 177.607C214.892 177.623 215.781 176.592 215.638 175.307C215.495 174.007 214.401 172.944 213.179 172.928C211.974 172.912 211.085 173.943 211.212 175.228Z" fill="#DEDEDE"/>
    <path d="M204.17 175.133C204.281 176.434 205.376 177.496 206.597 177.512C207.818 177.528 208.723 176.497 208.596 175.196C208.469 173.896 207.374 172.833 206.169 172.817C204.947 172.801 204.043 173.832 204.17 175.133Z" fill="#DEDEDE"/>
    <path d="M197.111 175.038C197.222 176.339 198.301 177.385 199.522 177.401C200.744 177.417 201.664 176.386 201.537 175.085C201.426 173.785 200.347 172.738 199.126 172.722C197.904 172.706 197 173.753 197.111 175.038Z" fill="#DEDEDE"/>
    <path d="M190.053 174.974C190.148 176.275 191.211 177.322 192.448 177.338C193.685 177.354 194.589 176.307 194.494 175.022C194.399 173.721 193.32 172.674 192.099 172.659C190.878 172.643 189.957 173.69 190.053 174.974Z" fill="#DEDEDE"/>
    <path d="M182.978 174.926C183.057 176.227 184.12 177.274 185.341 177.29C186.579 177.306 187.499 176.259 187.419 174.958C187.34 173.657 186.277 172.611 185.056 172.595C183.834 172.595 182.899 173.626 182.978 174.926Z" fill="#DEDEDE"/>
    <path d="M175.92 174.895C175.983 176.196 177.03 177.242 178.267 177.242C179.504 177.242 180.44 176.211 180.361 174.911C180.282 173.61 179.235 172.563 178.013 172.563C176.792 172.547 175.856 173.594 175.92 174.895Z" fill="#DEDEDE"/>
    <path d="M168.845 174.863C168.893 176.164 169.939 177.211 171.161 177.211C172.398 177.211 173.334 176.164 173.286 174.879C173.223 173.579 172.192 172.532 170.971 172.532C169.733 172.532 168.797 173.579 168.845 174.863Z" fill="#DEDEDE"/>
    <path d="M161.77 174.863C161.802 176.164 162.833 177.211 164.07 177.211C165.307 177.211 166.259 176.164 166.212 174.863C166.164 173.563 165.133 172.516 163.912 172.516C162.69 172.531 161.723 173.578 161.77 174.863Z" fill="#DEDEDE"/>
    <path d="M154.696 174.879C154.727 176.18 155.742 177.227 156.98 177.211C158.217 177.211 159.185 176.164 159.153 174.863C159.121 173.563 158.106 172.532 156.869 172.532C155.631 172.532 154.664 173.595 154.696 174.879Z" fill="#DEDEDE"/>
    <path d="M147.622 174.911C147.638 176.212 148.637 177.243 149.874 177.243C151.111 177.243 152.095 176.18 152.063 174.895C152.047 173.595 151.032 172.563 149.811 172.563C148.589 172.563 147.606 173.626 147.622 174.911Z" fill="#DEDEDE"/>
    <path d="M140.532 174.958C140.532 176.259 141.531 177.29 142.768 177.29C144.006 177.274 144.989 176.227 144.989 174.926C144.973 173.626 143.974 172.595 142.752 172.595C141.531 172.611 140.532 173.673 140.532 174.958Z" fill="#DEDEDE"/>
    <path d="M133.457 175.022C133.441 176.323 134.44 177.354 135.662 177.338C136.899 177.322 137.898 176.275 137.898 174.974C137.898 173.674 136.915 172.643 135.693 172.659C134.472 172.674 133.473 173.737 133.457 175.022Z" fill="#DEDEDE"/>
    <path d="M126.382 175.102C126.35 176.402 127.334 177.433 128.571 177.417C129.808 177.402 130.823 176.339 130.839 175.054C130.855 173.753 129.872 172.722 128.65 172.738C127.413 172.754 126.398 173.817 126.382 175.102Z" fill="#DEDEDE"/>
    <path d="M119.292 175.212C119.261 176.513 120.228 177.544 121.449 177.528C122.687 177.512 123.702 176.449 123.734 175.149C123.765 173.848 122.798 172.817 121.576 172.833C120.355 172.849 119.34 173.911 119.292 175.212Z" fill="#DEDEDE"/>
    <path d="M112.217 175.323C112.169 176.624 113.121 177.655 114.358 177.623C115.596 177.607 116.627 176.545 116.658 175.244C116.706 173.943 115.738 172.912 114.517 172.944C113.312 172.96 112.265 174.023 112.217 175.323Z" fill="#DEDEDE"/>
    <path d="M105.142 175.45C105.079 176.751 106.03 177.766 107.252 177.75C108.489 177.734 109.52 176.656 109.584 175.371C109.631 174.07 108.695 173.055 107.474 173.071C106.253 173.087 105.206 174.149 105.142 175.45Z" fill="#DEDEDE"/>
    <path d="M98.0677 175.593C97.9884 176.894 98.9242 177.909 100.161 177.893C101.399 177.862 102.446 176.799 102.509 175.498C102.572 174.197 101.637 173.182 100.415 173.198C99.1939 173.23 98.147 174.309 98.0677 175.593Z" fill="#DEDEDE"/>
    <path d="M90.994 175.767C90.8988 177.068 91.8346 178.083 93.056 178.052C94.2933 178.02 95.356 176.957 95.4353 175.656C95.5146 174.356 94.5947 173.341 93.3733 173.372C92.1519 173.404 91.0891 174.467 90.994 175.767Z" fill="#DEDEDE"/>
    <path d="M83.9349 175.942C83.8238 177.243 84.7438 178.258 85.9811 178.226C87.2183 178.194 88.2811 177.116 88.3762 175.831C88.4714 174.53 87.5514 173.515 86.3301 173.547C85.1087 173.578 84.03 174.657 83.9349 175.942Z" fill="#DEDEDE"/>
    <path d="M76.8603 176.132C76.7493 177.433 77.6534 178.448 78.8748 178.417C80.112 178.385 81.1906 177.306 81.3017 176.006C81.4127 174.705 80.5086 173.69 79.2872 173.721C78.0658 173.769 76.9713 174.848 76.8603 176.132Z" fill="#DEDEDE"/>
    <path d="M69.8026 176.354C69.6757 177.655 70.564 178.67 71.8012 178.623C73.0226 178.591 74.1171 177.512 74.244 176.212C74.3708 174.911 73.4667 173.896 72.2454 173.943C71.024 173.975 69.9295 175.054 69.8026 176.354Z" fill="#DEDEDE"/>
    <path d="M375.163 175.149C375.591 176.434 376.892 177.56 378.05 177.639C379.208 177.719 379.811 176.751 379.366 175.45C378.922 174.165 377.637 173.039 376.479 172.96C375.322 172.881 374.735 173.864 375.163 175.149Z" fill="#DEDEDE"/>
    <path d="M368.453 174.673C368.865 175.958 370.15 177.084 371.324 177.163C372.498 177.242 373.1 176.259 372.672 174.974C372.244 173.689 370.959 172.563 369.801 172.484C368.627 172.404 368.025 173.388 368.453 174.673Z" fill="#DEDEDE"/>
    <path d="M361.729 174.213C362.141 175.498 363.41 176.624 364.584 176.703C365.758 176.782 366.36 175.799 365.948 174.514C365.535 173.229 364.251 172.103 363.093 172.024C361.935 171.944 361.316 172.928 361.729 174.213Z" fill="#DEDEDE"/>
    <path d="M354.986 173.769C355.383 175.054 356.652 176.164 357.825 176.243C358.999 176.323 359.634 175.339 359.221 174.039C358.825 172.754 357.54 171.643 356.382 171.564C355.208 171.485 354.59 172.468 354.986 173.769Z" fill="#DEDEDE"/>
    <path d="M348.229 173.325C348.61 174.61 349.879 175.72 351.053 175.799C352.227 175.879 352.861 174.879 352.48 173.594C352.084 172.31 350.831 171.199 349.657 171.12C348.483 171.057 347.833 172.04 348.229 173.325Z" fill="#DEDEDE"/>
    <path d="M341.456 172.912C341.82 174.197 343.089 175.308 344.263 175.387C345.437 175.466 346.087 174.467 345.707 173.182C345.326 171.897 344.073 170.787 342.899 170.708C341.725 170.628 341.075 171.628 341.456 172.912Z" fill="#DEDEDE"/>
    <path d="M300.516 170.723C300.818 172.008 302.023 173.103 303.213 173.166C304.402 173.229 305.132 172.214 304.815 170.929C304.513 169.645 303.292 168.55 302.118 168.487C300.929 168.439 300.215 169.438 300.516 170.723Z" fill="#DEDEDE"/>
    <path d="M293.647 170.406C293.933 171.691 295.138 172.785 296.328 172.849C297.518 172.896 298.263 171.897 297.962 170.612C297.676 169.327 296.471 168.233 295.281 168.185C294.091 168.122 293.362 169.121 293.647 170.406Z" fill="#DEDEDE"/>
    <path d="M286.764 170.12C287.033 171.405 288.223 172.5 289.428 172.547C290.634 172.595 291.364 171.596 291.094 170.295C290.808 169.01 289.619 167.916 288.429 167.868C287.24 167.82 286.494 168.82 286.764 170.12Z" fill="#DEDEDE"/>
    <path d="M279.879 169.835C280.133 171.12 281.323 172.214 282.528 172.262C283.734 172.309 284.479 171.31 284.209 170.009C283.94 168.725 282.75 167.63 281.56 167.583C280.371 167.535 279.609 168.55 279.879 169.835Z" fill="#DEDEDE"/>
    <path d="M272.979 169.581C273.233 170.866 274.407 171.961 275.596 172.008C276.802 172.056 277.563 171.041 277.309 169.756C277.056 168.471 275.882 167.376 274.692 167.329C273.487 167.281 272.725 168.281 272.979 169.581Z" fill="#DEDEDE"/>
    <path d="M266.064 169.328C266.302 170.612 267.46 171.707 268.666 171.739C269.871 171.786 270.648 170.771 270.41 169.47C270.173 168.186 268.999 167.091 267.809 167.059C266.604 167.028 265.826 168.043 266.064 169.328Z" fill="#DEDEDE"/>
    <path d="M259.147 169.089C259.369 170.374 260.527 171.453 261.733 171.5C262.938 171.548 263.731 170.533 263.493 169.232C263.255 167.947 262.113 166.869 260.908 166.821C259.718 166.789 258.925 167.805 259.147 169.089Z" fill="#DEDEDE"/>
    <path d="M252.216 168.883C252.422 170.168 253.564 171.247 254.77 171.294C255.975 171.326 256.784 170.311 256.562 169.026C256.34 167.741 255.198 166.662 253.992 166.615C252.803 166.567 252.01 167.582 252.216 168.883Z" fill="#DEDEDE"/>
    <path d="M245.284 168.677C245.474 169.962 246.616 171.041 247.822 171.072C249.028 171.104 249.836 170.089 249.646 168.804C249.44 167.519 248.298 166.441 247.108 166.409C245.903 166.377 245.078 167.392 245.284 168.677Z" fill="#DEDEDE"/>
    <path d="M238.336 168.503C238.511 169.788 239.653 170.866 240.858 170.898C242.064 170.93 242.889 169.914 242.698 168.614C242.508 167.329 241.382 166.25 240.176 166.219C238.971 166.187 238.146 167.202 238.336 168.503Z" fill="#DEDEDE"/>
    <path d="M231.374 168.328C231.548 169.613 232.658 170.692 233.88 170.723C235.085 170.755 235.926 169.724 235.751 168.439C235.577 167.154 234.451 166.076 233.245 166.044C232.04 166.012 231.215 167.043 231.374 168.328Z" fill="#DEDEDE"/>
    <path d="M224.425 168.185C224.584 169.47 225.694 170.549 226.899 170.564C228.105 170.596 228.962 169.565 228.787 168.28C228.628 166.995 227.518 165.917 226.313 165.901C225.107 165.869 224.266 166.884 224.425 168.185Z" fill="#DEDEDE"/>
    <path d="M217.446 168.043C217.589 169.327 218.683 170.406 219.905 170.422C221.11 170.438 221.982 169.423 221.824 168.122C221.665 166.837 220.571 165.758 219.365 165.743C218.176 165.727 217.303 166.758 217.446 168.043Z" fill="#DEDEDE"/>
    <path d="M210.482 167.932C210.609 169.216 211.704 170.279 212.909 170.311C214.131 170.327 214.987 169.296 214.86 168.011C214.718 166.726 213.623 165.663 212.418 165.632C211.228 165.6 210.356 166.631 210.482 167.932Z" fill="#DEDEDE"/>
    <path d="M203.503 167.82C203.614 169.105 204.693 170.168 205.914 170.184C207.136 170.2 208.008 169.169 207.881 167.884C207.754 166.599 206.676 165.536 205.47 165.52C204.265 165.505 203.392 166.536 203.503 167.82Z" fill="#DEDEDE"/>
    <path d="M196.525 167.741C196.636 169.026 197.698 170.089 198.92 170.105C200.141 170.121 201.029 169.089 200.918 167.789C200.807 166.504 199.729 165.441 198.523 165.425C197.302 165.409 196.413 166.441 196.525 167.741Z" fill="#DEDEDE"/>
    <path d="M189.529 167.677C189.624 168.962 190.687 170.025 191.892 170.041C193.114 170.057 194.018 169.01 193.907 167.725C193.812 166.44 192.749 165.377 191.543 165.362C190.338 165.346 189.434 166.377 189.529 167.677Z" fill="#DEDEDE"/>
    <path d="M182.534 167.614C182.613 168.899 183.66 169.962 184.881 169.962C186.103 169.978 187.023 168.931 186.927 167.646C186.832 166.361 185.785 165.298 184.58 165.298C183.374 165.283 182.454 166.329 182.534 167.614Z" fill="#DEDEDE"/>
    <path d="M175.539 167.583C175.602 168.868 176.649 169.93 177.855 169.93C179.076 169.93 179.996 168.899 179.932 167.599C179.853 166.314 178.822 165.251 177.617 165.251C176.411 165.251 175.475 166.298 175.539 167.583Z" fill="#DEDEDE"/>
    <path d="M168.544 167.567C168.591 168.852 169.622 169.915 170.844 169.915C172.065 169.915 173.001 168.868 172.938 167.583C172.874 166.298 171.843 165.251 170.638 165.235C169.432 165.235 168.496 166.266 168.544 167.567Z" fill="#DEDEDE"/>
    <path d="M161.548 167.567C161.58 168.852 162.611 169.899 163.832 169.899C165.054 169.899 166.005 168.852 165.958 167.567C165.91 166.282 164.895 165.235 163.674 165.235C162.452 165.22 161.501 166.266 161.548 167.567Z" fill="#DEDEDE"/>
    <path d="M154.537 167.583C154.569 168.868 155.568 169.915 156.789 169.915C158.011 169.915 158.962 168.868 158.931 167.567C158.899 166.282 157.884 165.235 156.678 165.235C155.473 165.235 154.521 166.282 154.537 167.583Z" fill="#DEDEDE"/>
    <path d="M147.542 167.614C147.558 168.899 148.557 169.946 149.778 169.946C151 169.946 151.967 168.883 151.951 167.598C151.935 166.314 150.936 165.267 149.715 165.267C148.493 165.267 147.526 166.314 147.542 167.614Z" fill="#DEDEDE"/>
    <path d="M140.532 167.662C140.532 168.947 141.515 169.993 142.737 169.978C143.958 169.962 144.941 168.915 144.926 167.63C144.91 166.345 143.926 165.298 142.721 165.314C141.515 165.314 140.532 166.361 140.532 167.662Z" fill="#DEDEDE"/>
    <path d="M133.521 167.725C133.505 169.01 134.488 170.057 135.71 170.041C136.931 170.025 137.914 168.978 137.93 167.677C137.93 166.393 136.963 165.346 135.741 165.362C134.536 165.377 133.537 166.424 133.521 167.725Z" fill="#DEDEDE"/>
    <path d="M126.525 167.805C126.493 169.089 127.461 170.136 128.682 170.121C129.903 170.105 130.903 169.042 130.918 167.757C130.934 166.472 129.967 165.425 128.761 165.441C127.556 165.457 126.556 166.504 126.525 167.805Z" fill="#DEDEDE"/>
    <path d="M119.513 167.9C119.466 169.185 120.433 170.216 121.655 170.2C122.876 170.184 123.891 169.121 123.923 167.836C123.955 166.551 123.003 165.52 121.782 165.536C120.576 165.552 119.561 166.615 119.513 167.9Z" fill="#DEDEDE"/>
    <path d="M112.519 168.011C112.472 169.296 113.408 170.327 114.629 170.311C115.85 170.295 116.866 169.232 116.913 167.932C116.961 166.647 116.009 165.616 114.803 165.632C113.582 165.663 112.567 166.726 112.519 168.011Z" fill="#DEDEDE"/>
    <path d="M105.507 168.138C105.444 169.422 106.38 170.453 107.601 170.438C108.823 170.422 109.854 169.343 109.917 168.058C109.981 166.773 109.045 165.742 107.823 165.758C106.602 165.79 105.571 166.853 105.507 168.138Z" fill="#DEDEDE"/>
    <path d="M98.5131 168.296C98.4338 169.581 99.3537 170.612 100.575 170.58C101.796 170.549 102.843 169.486 102.907 168.201C102.97 166.916 102.05 165.885 100.845 165.917C99.6393 165.933 98.5924 166.995 98.5131 168.296Z" fill="#DEDEDE"/>
    <path d="M91.518 168.455C91.4228 169.74 92.3428 170.771 93.5642 170.739C94.7856 170.708 95.8325 169.645 95.9277 168.344C96.007 167.059 95.1028 166.028 93.8814 166.06C92.6601 166.092 91.5973 167.17 91.518 168.455Z" fill="#DEDEDE"/>
    <path d="M84.5063 168.645C84.3953 169.93 85.2994 170.961 86.5208 170.93C87.7422 170.898 88.8049 169.819 88.9001 168.534C88.9953 167.25 88.0911 166.219 86.8856 166.25C85.6801 166.282 84.6173 167.345 84.5063 168.645Z" fill="#DEDEDE"/>
    <path d="M77.5265 168.836C77.4154 170.12 78.3037 171.151 79.5092 171.104C80.7306 171.072 81.8092 169.994 81.9044 168.709C82.0154 167.424 81.1272 166.393 79.9217 166.44C78.7161 166.472 77.6375 167.535 77.5265 168.836Z" fill="#DEDEDE"/>
    <path d="M70.5318 169.042C70.4049 170.327 71.2773 171.342 72.4987 171.31C73.7201 171.279 74.7987 170.2 74.9256 168.899C75.0525 167.615 74.1642 166.599 72.9587 166.631C71.7532 166.679 70.6587 167.757 70.5318 169.042Z" fill="#DEDEDE"/>
    <path d="M28.7186 170.676C28.5124 171.961 29.3214 172.976 30.5269 172.912C31.7324 172.865 32.8745 171.77 33.0807 170.469C33.2869 169.185 32.462 168.169 31.2724 168.233C30.0669 168.296 28.9248 169.375 28.7186 170.676Z" fill="#DEDEDE"/>
    <path d="M21.7716 171.009C21.5495 172.293 22.3585 173.293 23.564 173.245C24.7695 173.182 25.9274 172.087 26.1336 170.802C26.3398 169.518 25.5467 168.518 24.3412 168.566C23.1516 168.613 21.9936 169.708 21.7716 171.009Z" fill="#DEDEDE"/>
    <path d="M7.90867 171.707C7.65488 172.992 8.44799 173.991 9.65351 173.928C10.859 173.864 12.0328 172.77 12.2707 171.469C12.5087 170.184 11.7314 169.185 10.5259 169.248C9.32041 169.312 8.1466 170.406 7.90867 171.707Z" fill="#DEDEDE"/>
    <path d="M0.993101 172.071C0.739308 173.356 1.50069 174.356 2.70621 174.292C3.91172 174.229 5.08552 173.134 5.33932 171.834C5.59311 170.549 4.81586 169.549 3.62621 169.613C2.42069 169.692 1.24689 170.787 0.993101 172.071Z" fill="#DEDEDE"/>
    <path d="M372.705 167.852C373.133 169.137 374.434 170.247 375.576 170.343C376.734 170.422 377.305 169.454 376.861 168.169C376.416 166.885 375.132 165.774 373.99 165.679C372.847 165.6 372.276 166.567 372.705 167.852Z" fill="#DEDEDE"/>
    <path d="M366.075 167.377C366.503 168.661 367.772 169.772 368.93 169.851C370.088 169.93 370.675 168.963 370.246 167.662C369.818 166.377 368.533 165.267 367.391 165.188C366.233 165.108 365.646 166.092 366.075 167.377Z" fill="#DEDEDE"/>
    <path d="M359.411 166.916C359.824 168.201 361.093 169.312 362.25 169.391C363.408 169.47 364.011 168.487 363.583 167.202C363.155 165.917 361.886 164.807 360.744 164.727C359.602 164.648 358.999 165.632 359.411 166.916Z" fill="#DEDEDE"/>
    <path d="M352.734 166.472C353.131 167.757 354.4 168.867 355.557 168.947C356.715 169.026 357.334 168.043 356.922 166.758C356.509 165.473 355.24 164.363 354.098 164.283C352.94 164.204 352.337 165.187 352.734 166.472Z" fill="#DEDEDE"/>
    <path d="M346.04 166.044C346.421 167.329 347.69 168.439 348.848 168.518C350.006 168.598 350.64 167.614 350.244 166.314C349.847 165.029 348.594 163.918 347.436 163.839C346.278 163.76 345.66 164.743 346.04 166.044Z" fill="#DEDEDE"/>
    <path d="M339.33 165.616C339.711 166.9 340.948 168.011 342.122 168.074C343.296 168.154 343.93 167.154 343.549 165.869C343.169 164.585 341.916 163.474 340.758 163.411C339.6 163.347 338.965 164.331 339.33 165.616Z" fill="#DEDEDE"/>
    <path d="M332.621 165.219C332.985 166.504 334.223 167.614 335.396 167.678C336.57 167.741 337.221 166.758 336.84 165.473C336.475 164.188 335.222 163.078 334.064 163.014C332.906 162.935 332.256 163.934 332.621 165.219Z" fill="#DEDEDE"/>
    <path d="M325.878 164.838C326.227 166.123 327.465 167.218 328.638 167.297C329.812 167.36 330.478 166.377 330.114 165.092C329.749 163.807 328.512 162.713 327.354 162.634C326.196 162.554 325.529 163.538 325.878 164.838Z" fill="#DEDEDE"/>
    <path d="M292.029 163.125C292.315 164.41 293.505 165.505 294.694 165.552C295.884 165.6 296.598 164.601 296.296 163.316C295.995 162.031 294.805 160.936 293.632 160.889C292.458 160.841 291.728 161.841 292.029 163.125Z" fill="#DEDEDE"/>
    <path d="M285.209 162.824C285.479 164.109 286.668 165.203 287.858 165.251C289.047 165.299 289.777 164.299 289.492 163.014C289.206 161.73 288.016 160.635 286.843 160.588C285.669 160.54 284.939 161.539 285.209 162.824Z" fill="#DEDEDE"/>
    <path d="M278.403 162.554C278.657 163.839 279.847 164.918 281.021 164.965C282.21 165.013 282.956 164.014 282.686 162.713C282.416 161.428 281.243 160.35 280.069 160.302C278.879 160.254 278.134 161.254 278.403 162.554Z" fill="#DEDEDE"/>
    <path d="M271.569 162.285C271.823 163.569 272.981 164.648 274.17 164.696C275.36 164.743 276.121 163.728 275.852 162.443C275.598 161.158 274.424 160.08 273.25 160.032C272.076 159.985 271.315 161 271.569 162.285Z" fill="#DEDEDE"/>
    <path d="M264.731 162.031C264.969 163.316 266.127 164.394 267.316 164.442C268.506 164.49 269.268 163.474 269.03 162.19C268.792 160.905 267.634 159.826 266.444 159.779C265.254 159.747 264.493 160.746 264.731 162.031Z" fill="#DEDEDE"/>
    <path d="M257.879 161.809C258.101 163.094 259.243 164.172 260.448 164.22C261.638 164.252 262.415 163.252 262.193 161.967C261.955 160.683 260.813 159.604 259.624 159.572C258.434 159.509 257.657 160.524 257.879 161.809Z" fill="#DEDEDE"/>
    <path d="M251.027 161.587C251.233 162.871 252.375 163.95 253.565 163.982C254.754 164.014 255.547 162.998 255.325 161.714C255.103 160.429 253.961 159.35 252.787 159.318C251.614 159.287 250.821 160.302 251.027 161.587Z" fill="#DEDEDE"/>
    <path d="M244.158 161.396C244.348 162.681 245.49 163.76 246.68 163.792C247.87 163.823 248.679 162.808 248.473 161.523C248.266 160.238 247.14 159.16 245.95 159.128C244.761 159.08 243.968 160.096 244.158 161.396Z" fill="#DEDEDE"/>
    <path d="M237.29 161.206C237.48 162.491 238.59 163.57 239.796 163.601C240.986 163.633 241.81 162.618 241.62 161.333C241.43 160.048 240.304 158.97 239.114 158.938C237.924 158.906 237.115 159.921 237.29 161.206Z" fill="#DEDEDE"/>
    <path d="M230.406 161.047C230.58 162.332 231.691 163.395 232.88 163.427C234.086 163.458 234.911 162.427 234.72 161.142C234.546 159.858 233.435 158.795 232.246 158.763C231.072 158.731 230.247 159.747 230.406 161.047Z" fill="#DEDEDE"/>
    <path d="M223.521 160.889C223.68 162.174 224.774 163.236 225.98 163.268C227.185 163.3 228.01 162.269 227.852 160.984C227.677 159.699 226.583 158.636 225.393 158.605C224.203 158.573 223.363 159.604 223.521 160.889Z" fill="#DEDEDE"/>
    <path d="M216.637 160.762C216.78 162.047 217.875 163.109 219.08 163.141C220.286 163.157 221.126 162.142 220.968 160.857C220.809 159.572 219.715 158.509 218.525 158.478C217.335 158.446 216.495 159.461 216.637 160.762Z" fill="#DEDEDE"/>
    <path d="M209.738 160.635C209.864 161.92 210.943 162.983 212.149 162.999C213.354 163.014 214.211 161.983 214.068 160.699C213.925 159.414 212.847 158.351 211.657 158.335C210.467 158.319 209.611 159.35 209.738 160.635Z" fill="#DEDEDE"/>
    <path d="M202.837 160.54C202.948 161.825 204.027 162.888 205.233 162.903C206.438 162.919 207.311 161.888 207.184 160.603C207.057 159.319 205.978 158.256 204.789 158.24C203.583 158.224 202.711 159.255 202.837 160.54Z" fill="#DEDEDE"/>
    <path d="M195.922 160.444C196.033 161.729 197.08 162.792 198.286 162.808C199.491 162.824 200.364 161.793 200.253 160.508C200.142 159.223 199.079 158.16 197.889 158.144C196.7 158.129 195.811 159.16 195.922 160.444Z" fill="#DEDEDE"/>
    <path d="M189.006 160.381C189.102 161.666 190.148 162.729 191.354 162.729C192.56 162.744 193.448 161.698 193.353 160.413C193.257 159.128 192.195 158.065 191.005 158.065C189.815 158.065 188.911 159.096 189.006 160.381Z" fill="#DEDEDE"/>
    <path d="M182.089 160.334C182.169 161.618 183.216 162.665 184.421 162.681C185.627 162.697 186.531 161.65 186.436 160.365C186.34 159.081 185.309 158.034 184.104 158.018C182.914 158.002 182.01 159.049 182.089 160.334Z" fill="#DEDEDE"/>
    <path d="M175.175 160.302C175.238 161.587 176.269 162.634 177.475 162.649C178.68 162.649 179.6 161.618 179.521 160.334C179.441 159.049 178.41 158.002 177.221 157.986C176.015 157.97 175.111 159.001 175.175 160.302Z" fill="#DEDEDE"/>
    <path d="M168.243 160.27C168.291 161.555 169.322 162.602 170.527 162.602C171.733 162.602 172.653 161.571 172.605 160.27C172.542 158.985 171.526 157.938 170.321 157.938C169.131 157.954 168.195 158.985 168.243 160.27Z" fill="#DEDEDE"/>
    <path d="M161.326 160.27C161.358 161.555 162.373 162.602 163.578 162.602C164.784 162.602 165.72 161.555 165.672 160.27C165.625 158.985 164.609 157.938 163.42 157.938C162.214 157.938 161.278 158.985 161.326 160.27Z" fill="#DEDEDE"/>
    <path d="M154.395 160.286C154.427 161.571 155.426 162.618 156.631 162.618C157.837 162.618 158.789 161.571 158.757 160.286C158.725 159.001 157.726 157.954 156.52 157.954C155.315 157.954 154.363 159.001 154.395 160.286Z" fill="#DEDEDE"/>
    <path d="M147.463 160.318C147.479 161.603 148.462 162.65 149.668 162.634C150.873 162.634 151.841 161.587 151.809 160.286C151.793 159.001 150.794 157.954 149.604 157.97C148.415 157.986 147.447 159.033 147.463 160.318Z" fill="#DEDEDE"/>
    <path d="M140.532 160.365C140.532 161.65 141.515 162.697 142.721 162.681C143.926 162.665 144.894 161.618 144.894 160.333C144.878 159.049 143.91 158.018 142.705 158.018C141.515 158.033 140.532 159.08 140.532 160.365Z" fill="#DEDEDE"/>
    <path d="M133.599 160.429C133.583 161.714 134.551 162.745 135.757 162.745C136.962 162.729 137.946 161.682 137.946 160.397C137.946 159.112 136.978 158.081 135.788 158.081C134.599 158.097 133.615 159.144 133.599 160.429Z" fill="#DEDEDE"/>
    <path d="M126.668 160.508C126.636 161.793 127.604 162.824 128.809 162.808C130.015 162.792 131.014 161.745 131.03 160.444C131.046 159.16 130.094 158.129 128.888 158.144C127.699 158.176 126.699 159.223 126.668 160.508Z" fill="#DEDEDE"/>
    <path d="M119.736 160.619C119.688 161.904 120.64 162.935 121.845 162.919C123.051 162.903 124.05 161.84 124.082 160.556C124.114 159.271 123.162 158.24 121.972 158.256C120.782 158.271 119.783 159.318 119.736 160.619Z" fill="#DEDEDE"/>
    <path d="M112.804 160.73C112.756 162.015 113.692 163.046 114.898 163.03C116.103 163.014 117.118 161.952 117.166 160.667C117.214 159.382 116.278 158.351 115.072 158.367C113.883 158.383 112.867 159.446 112.804 160.73Z" fill="#DEDEDE"/>
    <path d="M105.888 160.857C105.825 162.142 106.745 163.173 107.95 163.141C109.156 163.125 110.187 162.063 110.235 160.762C110.298 159.477 109.362 158.446 108.172 158.478C106.967 158.509 105.952 159.572 105.888 160.857Z" fill="#DEDEDE"/>
    <path d="M98.9565 161C98.8772 162.285 99.7813 163.316 100.987 163.284C102.192 163.252 103.223 162.19 103.303 160.905C103.366 159.62 102.462 158.589 101.272 158.621C100.067 158.652 99.0358 159.715 98.9565 161Z" fill="#DEDEDE"/>
    <path d="M92.024 161.174C91.9288 162.459 92.8329 163.474 94.0384 163.459C95.2439 163.427 96.2908 162.364 96.3702 161.079C96.4495 159.794 95.5612 158.779 94.3557 158.795C93.166 158.811 92.1191 159.89 92.024 161.174Z" fill="#DEDEDE"/>
    <path d="M85.1085 161.349C84.9975 162.634 85.8857 163.649 87.0913 163.617C88.2968 163.585 89.3596 162.523 89.4547 161.222C89.5499 159.937 88.6616 158.922 87.472 158.954C86.2665 159.001 85.2195 160.064 85.1085 161.349Z" fill="#DEDEDE"/>
    <path d="M78.1932 161.555C78.0663 162.84 78.9546 163.855 80.1601 163.823C81.3656 163.792 82.4284 162.713 82.5394 161.428C82.6505 160.143 81.7781 159.128 80.5725 159.16C79.3829 159.192 78.3043 160.254 78.1932 161.555Z" fill="#DEDEDE"/>
    <path d="M370.23 160.588C370.674 161.872 371.959 162.983 373.101 163.062C374.243 163.141 374.814 162.174 374.354 160.889C373.91 159.604 372.625 158.494 371.483 158.414C370.357 158.319 369.802 159.287 370.23 160.588Z" fill="#DEDEDE"/>
    <path d="M363.663 160.112C364.092 161.397 365.361 162.507 366.503 162.586C367.645 162.665 368.216 161.698 367.788 160.413C367.359 159.128 366.074 158.018 364.932 157.939C363.806 157.843 363.235 158.811 363.663 160.112Z" fill="#DEDEDE"/>
    <path d="M357.064 159.636C357.476 160.92 358.745 162.031 359.887 162.11C361.029 162.189 361.616 161.206 361.204 159.921C360.776 158.636 359.507 157.526 358.381 157.447C357.238 157.383 356.652 158.351 357.064 159.636Z" fill="#DEDEDE"/>
    <path d="M350.465 159.192C350.862 160.476 352.131 161.587 353.273 161.666C354.431 161.745 355.018 160.762 354.605 159.477C354.193 158.192 352.94 157.082 351.798 157.018C350.656 156.939 350.053 157.907 350.465 159.192Z" fill="#DEDEDE"/>
    <path d="M343.835 158.763C344.231 160.048 345.484 161.158 346.626 161.222C347.784 161.301 348.387 160.318 347.991 159.033C347.594 157.748 346.341 156.653 345.199 156.574C344.057 156.495 343.454 157.478 343.835 158.763Z" fill="#DEDEDE"/>
    <path d="M337.204 158.351C337.585 159.636 338.822 160.73 339.98 160.809C341.138 160.889 341.757 159.905 341.376 158.62C340.995 157.336 339.742 156.241 338.6 156.162C337.442 156.082 336.824 157.066 337.204 158.351Z" fill="#DEDEDE"/>
    <path d="M330.558 157.939C330.923 159.224 332.16 160.318 333.318 160.397C334.476 160.461 335.111 159.477 334.73 158.192C334.349 156.908 333.112 155.813 331.97 155.734C330.828 155.67 330.193 156.654 330.558 157.939Z" fill="#DEDEDE"/>
    <path d="M323.897 157.558C324.246 158.842 325.483 159.937 326.641 160C327.799 160.064 328.449 159.08 328.085 157.796C327.72 156.511 326.498 155.416 325.34 155.353C324.182 155.289 323.532 156.273 323.897 157.558Z" fill="#DEDEDE"/>
    <path d="M317.218 157.193C317.567 158.478 318.773 159.572 319.947 159.636C321.105 159.699 321.771 158.716 321.422 157.431C321.073 156.146 319.851 155.051 318.693 154.988C317.536 154.909 316.869 155.908 317.218 157.193Z" fill="#DEDEDE"/>
    <path d="M310.525 156.828C310.858 158.113 312.064 159.207 313.222 159.271C314.395 159.334 315.062 158.335 314.713 157.05C314.38 155.765 313.158 154.671 312 154.607C310.874 154.56 310.192 155.543 310.525 156.828Z" fill="#DEDEDE"/>
    <path d="M303.831 156.495C304.148 157.78 305.353 158.874 306.511 158.922C307.685 158.985 308.367 157.986 308.034 156.701C307.701 155.416 306.511 154.322 305.353 154.274C304.196 154.211 303.513 155.21 303.831 156.495Z" fill="#DEDEDE"/>
    <path d="M297.106 156.162C297.407 157.447 298.597 158.541 299.771 158.589C300.944 158.652 301.642 157.653 301.325 156.368C301.008 155.083 299.818 154.005 298.66 153.941C297.502 153.878 296.804 154.877 297.106 156.162Z" fill="#DEDEDE"/>
    <path d="M290.379 155.845C290.665 157.129 291.855 158.208 293.028 158.271C294.202 158.319 294.9 157.32 294.615 156.035C294.313 154.75 293.123 153.671 291.966 153.608C290.808 153.576 290.094 154.56 290.379 155.845Z" fill="#DEDEDE"/>
    <path d="M270.156 155.02C270.41 156.305 271.568 157.383 272.742 157.431C273.915 157.479 274.661 156.479 274.407 155.194C274.153 153.91 272.98 152.831 271.822 152.783C270.648 152.72 269.902 153.735 270.156 155.02Z" fill="#DEDEDE"/>
    <path d="M263.382 154.766C263.62 156.051 264.762 157.129 265.952 157.161C267.126 157.209 267.887 156.194 267.633 154.909C267.38 153.624 266.238 152.545 265.064 152.514C263.906 152.482 263.145 153.481 263.382 154.766Z" fill="#DEDEDE"/>
    <path d="M256.61 154.544C256.832 155.829 257.974 156.908 259.148 156.939C260.321 156.987 261.099 155.972 260.861 154.687C260.623 153.402 259.481 152.323 258.323 152.292C257.149 152.244 256.388 153.259 256.61 154.544Z" fill="#DEDEDE"/>
    <path d="M249.837 154.322C250.043 155.607 251.185 156.669 252.359 156.717C253.533 156.749 254.326 155.749 254.104 154.465C253.882 153.18 252.755 152.117 251.582 152.069C250.392 152.022 249.615 153.037 249.837 154.322Z" fill="#DEDEDE"/>
    <path d="M243.032 154.116C243.238 155.4 244.348 156.463 245.538 156.495C246.728 156.527 247.505 155.511 247.299 154.227C247.092 152.942 245.966 151.879 244.792 151.847C243.634 151.831 242.841 152.847 243.032 154.116Z" fill="#DEDEDE"/>
    <path d="M236.243 153.941C236.433 155.226 237.543 156.289 238.717 156.32C239.907 156.352 240.7 155.337 240.51 154.052C240.319 152.767 239.209 151.704 238.035 151.673C236.861 151.641 236.052 152.656 236.243 153.941Z" fill="#DEDEDE"/>
    <path d="M229.438 153.767C229.613 155.052 230.707 156.114 231.897 156.146C233.086 156.178 233.895 155.163 233.705 153.878C233.531 152.593 232.42 151.53 231.246 151.499C230.073 151.467 229.264 152.498 229.438 153.767Z" fill="#DEDEDE"/>
    <path d="M222.617 153.624C222.776 154.909 223.87 155.972 225.06 155.988C226.25 156.019 227.074 154.988 226.9 153.704C226.725 152.419 225.647 151.356 224.457 151.34C223.283 151.324 222.459 152.339 222.617 153.624Z" fill="#DEDEDE"/>
    <path d="M215.812 153.497C215.955 154.782 217.034 155.845 218.223 155.861C219.413 155.877 220.254 154.861 220.095 153.577C219.936 152.292 218.858 151.229 217.684 151.213C216.494 151.181 215.654 152.212 215.812 153.497Z" fill="#DEDEDE"/>
    <path d="M208.975 153.37C209.102 154.655 210.181 155.702 211.371 155.733C212.56 155.749 213.401 154.734 213.258 153.449C213.115 152.164 212.037 151.118 210.863 151.086C209.705 151.07 208.848 152.085 208.975 153.37Z" fill="#DEDEDE"/>
    <path d="M202.155 153.275C202.282 154.56 203.329 155.607 204.518 155.623C205.708 155.638 206.565 154.607 206.438 153.323C206.311 152.038 205.248 150.991 204.074 150.975C202.9 150.959 202.028 151.99 202.155 153.275Z" fill="#DEDEDE"/>
    <path d="M195.319 153.18C195.43 154.464 196.477 155.511 197.666 155.527C198.856 155.543 199.728 154.512 199.617 153.227C199.506 151.942 198.443 150.896 197.27 150.88C196.08 150.88 195.208 151.911 195.319 153.18Z" fill="#DEDEDE"/>
    <path d="M188.483 153.116C188.578 154.401 189.609 155.448 190.814 155.464C192.004 155.48 192.892 154.449 192.781 153.164C192.686 151.879 191.639 150.832 190.449 150.816C189.276 150.801 188.387 151.832 188.483 153.116Z" fill="#DEDEDE"/>
    <path d="M181.646 153.069C181.725 154.354 182.756 155.401 183.946 155.401C185.135 155.401 186.023 154.369 185.944 153.085C185.849 151.8 184.818 150.753 183.644 150.753C182.454 150.753 181.566 151.784 181.646 153.069Z" fill="#DEDEDE"/>
    <path d="M174.793 153.037C174.857 154.322 175.872 155.369 177.077 155.369C178.267 155.369 179.171 154.338 179.092 153.053C179.012 151.768 177.997 150.721 176.808 150.721C175.634 150.705 174.73 151.752 174.793 153.037Z" fill="#DEDEDE"/>
    <path d="M167.941 153.005C167.988 154.29 169.004 155.337 170.193 155.337C171.383 155.337 172.303 154.306 172.239 153.021C172.176 151.736 171.161 150.69 169.987 150.69C168.813 150.69 167.893 151.736 167.941 153.005Z" fill="#DEDEDE"/>
    <path d="M161.104 153.005C161.136 154.29 162.135 155.321 163.34 155.321C164.53 155.321 165.466 154.274 165.418 153.005C165.371 151.721 164.371 150.69 163.182 150.69C161.992 150.69 161.056 151.721 161.104 153.005Z" fill="#DEDEDE"/>
    <path d="M154.252 153.021C154.284 154.306 155.267 155.337 156.457 155.337C157.647 155.337 158.583 154.29 158.551 153.005C158.519 151.721 157.52 150.69 156.346 150.69C155.156 150.705 154.221 151.736 154.252 153.021Z" fill="#DEDEDE"/>
    <path d="M147.383 153.053C147.399 154.338 148.366 155.369 149.572 155.369C150.761 155.369 151.713 154.322 151.697 153.037C151.681 151.752 150.698 150.721 149.508 150.721C148.335 150.737 147.383 151.768 147.383 153.053Z" fill="#DEDEDE"/>
    <path d="M140.532 153.1C140.532 154.385 141.499 155.416 142.689 155.4C143.879 155.384 144.846 154.338 144.83 153.069C144.814 151.784 143.863 150.753 142.673 150.769C141.499 150.769 140.532 151.815 140.532 153.1Z" fill="#DEDEDE"/>
    <path d="M133.679 153.164C133.663 154.449 134.615 155.48 135.82 155.464C137.01 155.448 137.993 154.401 137.993 153.116C137.993 151.832 137.042 150.801 135.852 150.816C134.662 150.832 133.695 151.879 133.679 153.164Z" fill="#DEDEDE"/>
    <path d="M126.826 153.244C126.795 154.528 127.746 155.559 128.936 155.544C130.126 155.528 131.109 154.481 131.125 153.196C131.141 151.911 130.205 150.88 129.015 150.896C127.842 150.912 126.858 151.975 126.826 153.244Z" fill="#DEDEDE"/>
    <path d="M119.973 153.354C119.926 154.639 120.862 155.67 122.067 155.638C123.257 155.623 124.256 154.56 124.288 153.291C124.32 152.006 123.384 150.975 122.194 151.007C121.004 151.007 120.005 152.069 119.973 153.354Z" fill="#DEDEDE"/>
    <path d="M113.122 153.465C113.074 154.75 113.994 155.765 115.184 155.749C116.374 155.733 117.389 154.671 117.42 153.386C117.468 152.101 116.548 151.086 115.358 151.102C114.169 151.118 113.169 152.18 113.122 153.465Z" fill="#DEDEDE"/>
    <path d="M106.269 153.592C106.205 154.877 107.109 155.892 108.299 155.876C109.489 155.86 110.504 154.798 110.567 153.513C110.631 152.228 109.711 151.213 108.537 151.229C107.347 151.245 106.332 152.307 106.269 153.592Z" fill="#DEDEDE"/>
    <path d="M99.417 153.735C99.3376 155.02 100.242 156.035 101.431 156.019C102.621 155.988 103.652 154.925 103.716 153.656C103.795 152.371 102.891 151.356 101.701 151.388C100.511 151.403 99.4963 152.45 99.417 153.735Z" fill="#DEDEDE"/>
    <path d="M92.5641 153.909C92.4689 155.194 93.3572 156.209 94.5469 156.178C95.7365 156.146 96.7676 155.083 96.8627 153.798C96.942 152.514 96.0538 151.498 94.88 151.53C93.6903 151.562 92.6593 152.625 92.5641 153.909Z" fill="#DEDEDE"/>
    <path d="M85.7115 154.084C85.6005 155.369 86.4888 156.384 87.6784 156.352C88.8681 156.32 89.915 155.258 90.0101 153.973C90.1053 152.688 89.2329 151.673 88.0433 151.705C86.8695 151.736 85.8226 152.799 85.7115 154.084Z" fill="#DEDEDE"/>
    <path d="M374.227 153.814C374.687 155.099 375.972 156.21 377.098 156.289C378.224 156.384 378.763 155.401 378.303 154.132C377.843 152.847 376.559 151.736 375.432 151.657C374.322 151.562 373.783 152.53 374.227 153.814Z" fill="#DEDEDE"/>
    <path d="M367.74 153.323C368.184 154.608 369.453 155.718 370.595 155.797C371.721 155.876 372.276 154.909 371.832 153.624C371.372 152.339 370.103 151.229 368.977 151.15C367.851 151.07 367.295 152.038 367.74 153.323Z" fill="#DEDEDE"/>
    <path d="M361.236 152.847C361.664 154.132 362.933 155.242 364.075 155.321C365.201 155.401 365.772 154.433 365.328 153.148C364.884 151.863 363.615 150.769 362.489 150.674C361.363 150.594 360.792 151.562 361.236 152.847Z" fill="#DEDEDE"/>
    <path d="M354.701 152.387C355.113 153.671 356.382 154.766 357.508 154.845C358.65 154.925 359.221 153.957 358.793 152.672C358.365 151.387 357.112 150.293 355.969 150.214C354.859 150.134 354.288 151.102 354.701 152.387Z" fill="#DEDEDE"/>
    <path d="M348.165 151.942C348.577 153.227 349.83 154.322 350.957 154.401C352.099 154.48 352.686 153.497 352.273 152.228C351.861 150.943 350.608 149.849 349.481 149.769C348.355 149.69 347.768 150.657 348.165 151.942Z" fill="#DEDEDE"/>
    <path d="M341.614 151.515C342.011 152.799 343.248 153.894 344.39 153.973C345.532 154.053 346.135 153.069 345.722 151.784C345.326 150.499 344.073 149.405 342.947 149.326C341.82 149.246 341.218 150.23 341.614 151.515Z" fill="#DEDEDE"/>
    <path d="M335.047 151.102C335.428 152.387 336.665 153.481 337.807 153.545C338.95 153.624 339.568 152.641 339.172 151.356C338.775 150.071 337.538 148.977 336.412 148.913C335.285 148.834 334.667 149.817 335.047 151.102Z" fill="#DEDEDE"/>
    <path d="M328.465 150.69C328.829 151.974 330.067 153.069 331.209 153.132C332.351 153.196 332.985 152.212 332.604 150.943C332.224 149.659 331.002 148.564 329.86 148.501C328.718 148.437 328.1 149.421 328.465 150.69Z" fill="#DEDEDE"/>
    <path d="M321.882 150.309C322.231 151.594 323.452 152.688 324.61 152.752C325.752 152.815 326.387 151.832 326.022 150.547C325.657 149.262 324.436 148.167 323.294 148.104C322.152 148.041 321.517 149.024 321.882 150.309Z" fill="#DEDEDE"/>
    <path d="M315.268 149.928C315.617 151.213 316.822 152.292 317.98 152.355C319.138 152.419 319.773 151.435 319.424 150.15C319.075 148.865 317.853 147.787 316.711 147.723C315.569 147.676 314.935 148.659 315.268 149.928Z" fill="#DEDEDE"/>
    <path d="M308.653 149.579C308.986 150.864 310.191 151.943 311.333 152.006C312.491 152.07 313.142 151.086 312.809 149.801C312.46 148.516 311.27 147.438 310.128 147.374C308.986 147.311 308.32 148.294 308.653 149.579Z" fill="#DEDEDE"/>
    <path d="M302.023 149.246C302.34 150.531 303.53 151.61 304.688 151.673C305.846 151.737 306.512 150.737 306.179 149.468C305.846 148.184 304.656 147.105 303.514 147.042C302.372 146.962 301.706 147.962 302.023 149.246Z" fill="#DEDEDE"/>
    <path d="M295.392 148.913C295.694 150.198 296.883 151.276 298.041 151.34C299.199 151.387 299.881 150.404 299.564 149.119C299.247 147.834 298.057 146.756 296.915 146.708C295.757 146.645 295.075 147.628 295.392 148.913Z" fill="#DEDEDE"/>
    <path d="M288.73 148.612C289.016 149.896 290.206 150.975 291.363 151.023C292.521 151.07 293.219 150.087 292.918 148.802C292.617 147.517 291.443 146.439 290.285 146.391C289.127 146.327 288.445 147.327 288.73 148.612Z" fill="#DEDEDE"/>
    <path d="M282.069 148.31C282.354 149.595 283.512 150.674 284.67 150.721C285.828 150.769 286.542 149.769 286.24 148.5C285.955 147.216 284.781 146.137 283.639 146.089C282.497 146.042 281.799 147.025 282.069 148.31Z" fill="#DEDEDE"/>
    <path d="M275.406 148.025C275.675 149.309 276.833 150.372 277.991 150.436C279.149 150.483 279.879 149.484 279.593 148.199C279.324 146.914 278.15 145.851 277.008 145.804C275.85 145.756 275.136 146.756 275.406 148.025Z" fill="#DEDEDE"/>
    <path d="M262.018 147.517C262.256 148.802 263.398 149.864 264.572 149.912C265.746 149.96 266.476 148.944 266.222 147.675C265.968 146.391 264.826 145.328 263.668 145.28C262.526 145.249 261.78 146.248 262.018 147.517Z" fill="#DEDEDE"/>
    <path d="M255.325 147.295C255.547 148.58 256.689 149.643 257.847 149.69C259.021 149.722 259.766 148.723 259.528 147.438C259.29 146.153 258.164 145.09 257.006 145.059C255.848 145.011 255.103 146.01 255.325 147.295Z" fill="#DEDEDE"/>
    <path d="M248.615 147.073C248.837 148.358 249.948 149.421 251.121 149.452C252.295 149.484 253.057 148.485 252.835 147.2C252.612 145.915 251.486 144.852 250.328 144.821C249.17 144.789 248.409 145.804 248.615 147.073Z" fill="#DEDEDE"/>
    <path d="M241.906 146.883C242.112 148.167 243.222 149.23 244.38 149.262C245.554 149.294 246.331 148.294 246.109 147.009C245.903 145.725 244.793 144.662 243.635 144.63C242.477 144.598 241.7 145.598 241.906 146.883Z" fill="#DEDEDE"/>
    <path d="M235.18 146.692C235.37 147.977 236.465 149.04 237.639 149.071C238.812 149.103 239.606 148.088 239.399 146.819C239.209 145.534 238.099 144.471 236.941 144.455C235.783 144.408 234.99 145.423 235.18 146.692Z" fill="#DEDEDE"/>
    <path d="M228.454 146.534C228.629 147.818 229.723 148.865 230.897 148.897C232.071 148.929 232.864 147.914 232.689 146.629C232.499 145.344 231.42 144.297 230.247 144.265C229.073 144.234 228.28 145.249 228.454 146.534Z" fill="#DEDEDE"/>
    <path d="M221.713 146.375C221.871 147.66 222.95 148.707 224.124 148.739C225.298 148.77 226.107 147.739 225.932 146.47C225.758 145.186 224.679 144.139 223.521 144.107C222.363 144.091 221.554 145.106 221.713 146.375Z" fill="#DEDEDE"/>
    <path d="M214.972 146.248C215.114 147.533 216.193 148.58 217.367 148.611C218.541 148.627 219.365 147.612 219.207 146.343C219.048 145.058 217.97 144.011 216.812 143.995C215.654 143.948 214.829 144.963 214.972 146.248Z" fill="#DEDEDE"/>
    <path d="M208.23 146.137C208.357 147.422 209.42 148.469 210.594 148.485C211.767 148.501 212.608 147.486 212.465 146.201C212.323 144.916 211.26 143.869 210.102 143.853C208.928 143.837 208.087 144.853 208.23 146.137Z" fill="#DEDEDE"/>
    <path d="M201.474 146.026C201.601 147.311 202.648 148.358 203.821 148.374C204.995 148.39 205.852 147.374 205.709 146.09C205.582 144.805 204.519 143.758 203.361 143.742C202.204 143.726 201.347 144.757 201.474 146.026Z" fill="#DEDEDE"/>
    <path d="M194.716 145.947C194.827 147.232 195.858 148.279 197.047 148.295C198.221 148.31 199.078 147.279 198.967 146.01C198.856 144.726 197.809 143.679 196.635 143.679C195.461 143.647 194.605 144.662 194.716 145.947Z" fill="#DEDEDE"/>
    <path d="M187.959 145.883C188.054 147.168 189.085 148.199 190.259 148.215C191.433 148.231 192.305 147.2 192.21 145.915C192.115 144.63 191.068 143.599 189.91 143.583C188.737 143.567 187.864 144.598 187.959 145.883Z" fill="#DEDEDE"/>
    <path d="M181.186 145.82C181.265 147.105 182.28 148.136 183.47 148.152C184.644 148.167 185.532 147.136 185.437 145.852C185.341 144.567 184.326 143.536 183.152 143.52C181.995 143.52 181.106 144.551 181.186 145.82Z" fill="#DEDEDE"/>
    <path d="M174.412 145.788C174.476 147.073 175.491 148.104 176.665 148.12C177.839 148.12 178.743 147.089 178.663 145.82C178.584 144.535 177.585 143.504 176.411 143.504C175.253 143.488 174.349 144.519 174.412 145.788Z" fill="#DEDEDE"/>
    <path d="M167.64 145.772C167.688 147.057 168.687 148.088 169.877 148.088C171.05 148.088 171.954 147.057 171.891 145.772C171.828 144.487 170.828 143.456 169.654 143.456C168.497 143.456 167.592 144.503 167.64 145.772Z" fill="#DEDEDE"/>
    <path d="M160.867 145.772C160.899 147.057 161.898 148.088 163.072 148.088C164.246 148.088 165.166 147.057 165.118 145.772C165.07 144.487 164.087 143.456 162.913 143.456C161.755 143.456 160.835 144.487 160.867 145.772Z" fill="#DEDEDE"/>
    <path d="M154.093 145.788C154.125 147.073 155.093 148.104 156.282 148.104C157.456 148.104 158.392 147.057 158.36 145.788C158.329 144.503 157.345 143.472 156.171 143.488C154.998 143.472 154.078 144.503 154.093 145.788Z" fill="#DEDEDE"/>
    <path d="M147.32 145.819C147.335 147.104 148.303 148.135 149.477 148.119C150.651 148.119 151.602 147.073 151.571 145.788C151.555 144.503 150.587 143.472 149.413 143.488C148.24 143.504 147.304 144.535 147.32 145.819Z" fill="#DEDEDE"/>
    <path d="M140.547 145.868C140.547 147.152 141.499 148.168 142.689 148.168C143.863 148.152 144.814 147.121 144.814 145.836C144.798 144.551 143.847 143.536 142.673 143.536C141.499 143.552 140.547 144.583 140.547 145.868Z" fill="#DEDEDE"/>
    <path d="M133.758 145.931C133.742 147.216 134.694 148.231 135.868 148.231C137.041 148.215 138.009 147.168 138.009 145.899C138.009 144.614 137.073 143.599 135.899 143.615C134.741 143.615 133.774 144.646 133.758 145.931Z" fill="#DEDEDE"/>
    <path d="M126.985 146.01C126.953 147.295 127.889 148.31 129.078 148.295C130.252 148.279 131.236 147.232 131.252 145.947C131.267 144.662 130.332 143.647 129.174 143.663C127.984 143.679 127.016 144.726 126.985 146.01Z" fill="#DEDEDE"/>
    <path d="M120.196 146.106C120.148 147.39 121.084 148.406 122.258 148.39C123.431 148.374 124.415 147.327 124.447 146.042C124.478 144.757 123.558 143.742 122.385 143.758C121.227 143.774 120.243 144.837 120.196 146.106Z" fill="#DEDEDE"/>
    <path d="M113.423 146.216C113.375 147.501 114.279 148.516 115.453 148.5C116.627 148.485 117.626 147.422 117.674 146.153C117.721 144.868 116.801 143.853 115.643 143.869C114.47 143.9 113.486 144.947 113.423 146.216Z" fill="#DEDEDE"/>
    <path d="M106.65 146.359C106.586 147.644 107.474 148.659 108.664 148.628C109.838 148.612 110.853 147.549 110.917 146.264C110.98 144.979 110.076 143.964 108.902 143.996C107.728 144.028 106.713 145.074 106.65 146.359Z" fill="#DEDEDE"/>
    <path d="M99.876 146.502C99.7967 147.787 100.685 148.802 101.859 148.77C103.033 148.738 104.048 147.691 104.127 146.407C104.206 145.122 103.318 144.107 102.144 144.138C100.971 144.17 99.9553 145.217 99.876 146.502Z" fill="#DEDEDE"/>
    <path d="M93.1038 146.661C93.0086 147.945 93.881 148.945 95.0707 148.929C96.2445 148.897 97.2755 147.834 97.3707 146.565C97.45 145.281 96.5776 144.281 95.4038 144.297C94.23 144.329 93.199 145.392 93.1038 146.661Z" fill="#DEDEDE"/>
    <path d="M86.3303 146.851C86.2193 148.136 87.0917 149.135 88.2655 149.103C89.4393 149.072 90.4862 148.009 90.5814 146.724C90.6765 145.439 89.82 144.44 88.6462 144.472C87.4724 144.503 86.4414 145.566 86.3303 146.851Z" fill="#DEDEDE"/>
    <path d="M371.641 146.581C372.101 147.866 373.37 148.961 374.496 149.056C375.607 149.135 376.146 148.183 375.67 146.899C375.21 145.614 373.925 144.519 372.815 144.424C371.721 144.345 371.181 145.296 371.641 146.581Z" fill="#DEDEDE"/>
    <path d="M365.218 146.09C365.662 147.359 366.931 148.469 368.057 148.548C369.183 148.628 369.722 147.66 369.262 146.391C368.802 145.106 367.533 144.012 366.423 143.932C365.313 143.853 364.773 144.821 365.218 146.09Z" fill="#DEDEDE"/>
    <path d="M358.777 145.614C359.205 146.883 360.474 147.993 361.6 148.072C362.727 148.151 363.266 147.184 362.838 145.915C362.394 144.646 361.125 143.536 360.014 143.456C358.904 143.377 358.349 144.345 358.777 145.614Z" fill="#DEDEDE"/>
    <path d="M352.321 145.154C352.75 146.423 354.003 147.533 355.113 147.612C356.239 147.692 356.794 146.724 356.366 145.439C355.938 144.17 354.685 143.06 353.559 142.98C352.464 142.917 351.909 143.885 352.321 145.154Z" fill="#DEDEDE"/>
    <path d="M345.866 144.71C346.278 145.978 347.516 147.089 348.642 147.152C349.768 147.232 350.339 146.264 349.927 144.979C349.514 143.71 348.261 142.6 347.151 142.536C346.025 142.473 345.453 143.441 345.866 144.71Z" fill="#DEDEDE"/>
    <path d="M339.378 144.281C339.774 145.55 341.012 146.645 342.138 146.724C343.264 146.804 343.851 145.82 343.454 144.551C343.042 143.282 341.805 142.188 340.694 142.108C339.568 142.029 338.981 143.013 339.378 144.281Z" fill="#DEDEDE"/>
    <path d="M332.874 143.869C333.255 145.138 334.492 146.232 335.619 146.312C336.745 146.391 337.348 145.407 336.951 144.123C336.555 142.854 335.333 141.759 334.207 141.68C333.097 141.616 332.494 142.6 332.874 143.869Z" fill="#DEDEDE"/>
    <path d="M326.371 143.472C326.736 144.741 327.958 145.836 329.1 145.915C330.242 145.979 330.845 145.011 330.464 143.726C330.083 142.457 328.862 141.363 327.736 141.299C326.609 141.22 326.007 142.188 326.371 143.472Z" fill="#DEDEDE"/>
    <path d="M319.851 143.076C320.216 144.345 321.422 145.439 322.564 145.503C323.706 145.566 324.324 144.583 323.96 143.314C323.595 142.045 322.373 140.95 321.247 140.887C320.105 140.823 319.486 141.807 319.851 143.076Z" fill="#DEDEDE"/>
    <path d="M313.317 142.711C313.666 143.98 314.872 145.074 316.014 145.138C317.156 145.201 317.79 144.218 317.425 142.949C317.076 141.68 315.871 140.585 314.729 140.522C313.603 140.459 312.968 141.442 313.317 142.711Z" fill="#DEDEDE"/>
    <path d="M306.765 142.362C307.099 143.631 308.288 144.725 309.43 144.789C310.572 144.852 311.223 143.869 310.874 142.584C310.525 141.315 309.335 140.221 308.209 140.173C307.083 140.094 306.432 141.077 306.765 142.362Z" fill="#DEDEDE"/>
    <path d="M300.215 142.013C300.532 143.282 301.721 144.376 302.863 144.424C304.006 144.487 304.672 143.488 304.339 142.219C304.006 140.95 302.816 139.872 301.69 139.808C300.548 139.761 299.897 140.744 300.215 142.013Z" fill="#DEDEDE"/>
    <path d="M293.647 141.696C293.949 142.965 295.138 144.044 296.28 144.107C297.422 144.17 298.089 143.171 297.771 141.902C297.454 140.633 296.28 139.555 295.138 139.491C294.012 139.428 293.33 140.427 293.647 141.696Z" fill="#DEDEDE"/>
    <path d="M287.065 141.378C287.367 142.647 288.525 143.726 289.683 143.789C290.825 143.837 291.507 142.854 291.205 141.585C290.904 140.316 289.73 139.237 288.588 139.189C287.446 139.126 286.764 140.109 287.065 141.378Z" fill="#DEDEDE"/>
    <path d="M280.482 141.093C280.767 142.362 281.925 143.441 283.067 143.488C284.209 143.536 284.907 142.552 284.622 141.267C284.336 139.998 283.178 138.92 282.036 138.872C280.894 138.825 280.196 139.824 280.482 141.093Z" fill="#DEDEDE"/>
    <path d="M273.884 140.807C274.153 142.076 275.295 143.155 276.453 143.202C277.595 143.25 278.309 142.251 278.024 140.982C277.738 139.713 276.596 138.634 275.454 138.586C274.312 138.555 273.614 139.538 273.884 140.807Z" fill="#DEDEDE"/>
    <path d="M267.268 140.553C267.522 141.822 268.664 142.901 269.822 142.949C270.98 142.996 271.694 141.997 271.424 140.728C271.155 139.459 270.013 138.38 268.871 138.349C267.729 138.285 267.015 139.284 267.268 140.553Z" fill="#DEDEDE"/>
    <path d="M260.654 140.315C260.892 141.584 262.034 142.663 263.176 142.695C264.334 142.742 265.064 141.743 264.81 140.458C264.556 139.189 263.43 138.126 262.288 138.079C261.146 138.031 260.416 139.03 260.654 140.315Z" fill="#DEDEDE"/>
    <path d="M254.024 140.078C254.246 141.347 255.372 142.409 256.53 142.457C257.688 142.489 258.434 141.489 258.18 140.22C257.942 138.952 256.816 137.889 255.674 137.841C254.547 137.809 253.802 138.809 254.024 140.078Z" fill="#DEDEDE"/>
    <path d="M247.394 139.872C247.616 141.14 248.727 142.203 249.884 142.251C251.042 142.283 251.788 141.283 251.566 140.014C251.344 138.745 250.233 137.683 249.075 137.651C247.933 137.587 247.188 138.587 247.394 139.872Z" fill="#DEDEDE"/>
    <path d="M240.764 139.665C240.97 140.934 242.064 141.997 243.222 142.029C244.38 142.061 245.142 141.061 244.935 139.776C244.729 138.508 243.619 137.445 242.477 137.413C241.319 137.397 240.557 138.396 240.764 139.665Z" fill="#DEDEDE"/>
    <path d="M234.117 139.491C234.307 140.76 235.401 141.823 236.559 141.854C237.717 141.886 238.495 140.871 238.288 139.602C238.082 138.333 237.003 137.27 235.846 137.239C234.703 137.207 233.926 138.206 234.117 139.491Z" fill="#DEDEDE"/>
    <path d="M227.454 139.317C227.629 140.586 228.707 141.648 229.865 141.68C231.023 141.712 231.816 140.697 231.626 139.428C231.436 138.159 230.357 137.096 229.215 137.08C228.073 137.033 227.28 138.048 227.454 139.317Z" fill="#DEDEDE"/>
    <path d="M220.793 139.174C220.951 140.443 222.03 141.506 223.188 141.521C224.346 141.553 225.155 140.538 224.98 139.269C224.806 138 223.743 136.937 222.585 136.921C221.443 136.89 220.634 137.905 220.793 139.174Z" fill="#DEDEDE"/>
    <path d="M214.131 139.031C214.274 140.3 215.337 141.347 216.51 141.378C217.668 141.394 218.477 140.379 218.319 139.11C218.16 137.841 217.097 136.794 215.939 136.762C214.797 136.747 213.988 137.762 214.131 139.031Z" fill="#DEDEDE"/>
    <path d="M200.791 138.824C200.918 140.093 201.949 141.14 203.123 141.156C204.281 141.172 205.121 140.157 204.994 138.888C204.868 137.619 203.821 136.572 202.663 136.556C201.505 136.524 200.664 137.555 200.791 138.824Z" fill="#DEDEDE"/>
    <path d="M194.097 138.73C194.208 139.999 195.239 141.046 196.397 141.061C197.555 141.077 198.412 140.062 198.285 138.777C198.174 137.508 197.143 136.461 195.985 136.446C194.843 136.446 194.002 137.461 194.097 138.73Z" fill="#DEDEDE"/>
    <path d="M187.42 138.666C187.515 139.935 188.53 140.982 189.704 140.998C190.862 141.014 191.734 139.982 191.623 138.714C191.512 137.445 190.497 136.398 189.339 136.398C188.181 136.382 187.325 137.397 187.42 138.666Z" fill="#DEDEDE"/>
    <path d="M180.726 138.618C180.805 139.887 181.82 140.934 182.978 140.934C184.136 140.95 185.009 139.919 184.929 138.65C184.834 137.381 183.835 136.334 182.677 136.334C181.519 136.318 180.646 137.349 180.726 138.618Z" fill="#DEDEDE"/>
    <path d="M160.644 138.571C160.676 139.84 161.659 140.871 162.833 140.871C164.007 140.871 164.911 139.84 164.863 138.571C164.816 137.302 163.832 136.271 162.674 136.271C161.516 136.255 160.612 137.286 160.644 138.571Z" fill="#DEDEDE"/>
    <path d="M153.95 138.571C153.982 139.84 154.949 140.871 156.107 140.871C157.281 140.871 158.185 139.84 158.154 138.555C158.122 137.286 157.154 136.255 155.996 136.255C154.838 136.271 153.918 137.302 153.95 138.571Z" fill="#DEDEDE"/>
    <path d="M147.24 138.603C147.256 139.872 148.208 140.903 149.382 140.903C150.555 140.903 151.475 139.856 151.46 138.587C151.444 137.318 150.476 136.287 149.318 136.303C148.16 136.303 147.24 137.334 147.24 138.603Z" fill="#DEDEDE"/>
    <path d="M140.547 138.65C140.547 139.919 141.483 140.95 142.657 140.934C143.831 140.918 144.767 139.887 144.751 138.618C144.735 137.349 143.799 136.318 142.641 136.334C141.483 136.35 140.547 137.381 140.547 138.65Z" fill="#DEDEDE"/>
    <path d="M133.837 138.714C133.821 139.983 134.757 141.014 135.915 140.998C137.088 140.982 138.04 139.951 138.04 138.666C138.04 137.397 137.104 136.366 135.962 136.382C134.804 136.414 133.852 137.445 133.837 138.714Z" fill="#DEDEDE"/>
    <path d="M127.144 138.809C127.112 140.078 128.032 141.109 129.206 141.093C130.38 141.077 131.331 140.03 131.347 138.761C131.363 137.492 130.443 136.477 129.285 136.477C128.127 136.493 127.176 137.524 127.144 138.809Z" fill="#DEDEDE"/>
    <path d="M120.434 138.904C120.386 140.173 121.306 141.188 122.464 141.172C123.638 141.157 124.606 140.11 124.637 138.841C124.669 137.572 123.765 136.557 122.607 136.572C121.449 136.588 120.481 137.635 120.434 138.904Z" fill="#DEDEDE"/>
    <path d="M113.739 139.015C113.676 140.284 114.58 141.299 115.754 141.284C116.928 141.268 117.911 140.221 117.959 138.936C118.006 137.667 117.102 136.652 115.944 136.668C114.786 136.699 113.803 137.746 113.739 139.015Z" fill="#DEDEDE"/>
    <path d="M107.047 139.142C106.983 140.411 107.871 141.426 109.029 141.41C110.203 141.394 111.187 140.332 111.25 139.063C111.314 137.794 110.425 136.779 109.267 136.794C108.109 136.826 107.11 137.873 107.047 139.142Z" fill="#DEDEDE"/>
    <path d="M100.336 139.285C100.257 140.554 101.129 141.569 102.303 141.537C103.477 141.506 104.476 140.459 104.555 139.19C104.635 137.921 103.746 136.906 102.588 136.937C101.431 136.969 100.431 138.016 100.336 139.285Z" fill="#DEDEDE"/>
    <path d="M93.643 139.459C93.5478 140.728 94.4043 141.743 95.5781 141.711C96.7519 141.68 97.7671 140.633 97.8464 139.348C97.9416 138.079 97.0692 137.064 95.9112 137.096C94.7533 137.127 93.7381 138.174 93.643 139.459Z" fill="#DEDEDE"/>
    <path d="M375.385 139.871C375.861 141.14 377.13 142.251 378.241 142.33C379.351 142.425 379.843 141.458 379.367 140.189C378.891 138.92 377.606 137.809 376.512 137.73C375.417 137.651 374.909 138.602 375.385 139.871Z" fill="#DEDEDE"/>
    <path d="M369.04 139.38C369.5 140.649 370.769 141.759 371.879 141.838C372.99 141.918 373.497 140.966 373.021 139.697C372.545 138.428 371.276 137.318 370.182 137.238C369.103 137.143 368.58 138.095 369.04 139.38Z" fill="#DEDEDE"/>
    <path d="M362.68 138.888C363.124 140.157 364.393 141.267 365.503 141.347C366.613 141.426 367.137 140.474 366.677 139.189C366.217 137.92 364.964 136.826 363.853 136.731C362.759 136.651 362.235 137.619 362.68 138.888Z" fill="#DEDEDE"/>
    <path d="M356.318 138.412C356.762 139.681 358.015 140.776 359.126 140.855C360.236 140.934 360.775 139.967 360.331 138.698C359.887 137.429 358.634 136.334 357.524 136.255C356.413 136.176 355.874 137.143 356.318 138.412Z" fill="#DEDEDE"/>
    <path d="M349.926 137.952C350.354 139.221 351.591 140.316 352.702 140.395C353.812 140.474 354.367 139.507 353.923 138.238C353.495 136.969 352.242 135.874 351.131 135.795C350.053 135.716 349.498 136.683 349.926 137.952Z" fill="#DEDEDE"/>
    <path d="M343.534 137.508C343.946 138.777 345.183 139.872 346.294 139.951C347.404 140.03 347.975 139.063 347.547 137.794C347.118 136.525 345.881 135.43 344.787 135.351C343.676 135.272 343.121 136.239 343.534 137.508Z" fill="#DEDEDE"/>
    <path d="M337.126 137.08C337.522 138.349 338.76 139.443 339.87 139.523C340.98 139.602 341.567 138.618 341.155 137.35C340.742 136.081 339.521 134.986 338.411 134.923C337.3 134.843 336.713 135.811 337.126 137.08Z" fill="#DEDEDE"/>
    <path d="M330.686 136.667C331.066 137.936 332.288 139.031 333.414 139.094C334.54 139.158 335.127 138.19 334.731 136.921C334.334 135.652 333.113 134.558 332.002 134.494C330.892 134.431 330.305 135.398 330.686 136.667Z" fill="#DEDEDE"/>
    <path d="M324.262 136.271C324.642 137.54 325.848 138.634 326.974 138.698C328.1 138.761 328.687 137.794 328.307 136.525C327.926 135.256 326.704 134.177 325.594 134.098C324.484 134.034 323.881 135.002 324.262 136.271Z" fill="#DEDEDE"/>
    <path d="M317.805 135.89C318.169 137.159 319.375 138.238 320.501 138.317C321.627 138.381 322.23 137.413 321.865 136.144C321.5 134.875 320.295 133.796 319.169 133.733C318.058 133.638 317.44 134.621 317.805 135.89Z" fill="#DEDEDE"/>
    <path d="M311.35 135.525C311.699 136.794 312.888 137.873 314.015 137.936C315.141 138 315.759 137.016 315.41 135.747C315.046 134.478 313.856 133.4 312.746 133.336C311.619 133.273 311.001 134.256 311.35 135.525Z" fill="#DEDEDE"/>
    <path d="M304.878 135.16C305.211 136.429 306.401 137.508 307.527 137.572C308.653 137.635 309.288 136.652 308.939 135.383C308.59 134.114 307.4 133.035 306.29 132.972C305.164 132.924 304.529 133.892 304.878 135.16Z" fill="#DEDEDE"/>
    <path d="M298.391 134.828C298.708 136.097 299.898 137.175 301.024 137.239C302.15 137.302 302.801 136.319 302.467 135.05C302.134 133.781 300.961 132.702 299.834 132.655C298.708 132.575 298.058 133.559 298.391 134.828Z" fill="#DEDEDE"/>
    <path d="M291.887 134.51C292.204 135.779 293.362 136.858 294.504 136.905C295.631 136.953 296.297 135.969 295.979 134.701C295.662 133.432 294.488 132.353 293.362 132.305C292.236 132.258 291.586 133.241 291.887 134.51Z" fill="#DEDEDE"/>
    <path d="M285.383 134.193C285.685 135.462 286.843 136.541 287.969 136.588C289.095 136.636 289.777 135.652 289.46 134.383C289.159 133.114 287.985 132.052 286.874 131.988C285.764 131.941 285.082 132.924 285.383 134.193Z" fill="#DEDEDE"/>
    <path d="M278.88 133.907C279.166 135.176 280.308 136.239 281.45 136.302C282.592 136.35 283.274 135.367 282.973 134.098C282.671 132.829 281.529 131.766 280.403 131.718C279.277 131.655 278.595 132.638 278.88 133.907Z" fill="#DEDEDE"/>
    <path d="M272.345 133.638C272.615 134.907 273.757 135.969 274.899 136.017C276.041 136.065 276.723 135.081 276.453 133.812C276.168 132.543 275.026 131.48 273.9 131.433C272.773 131.369 272.075 132.369 272.345 133.638Z" fill="#DEDEDE"/>
    <path d="M265.81 133.368C266.063 134.637 267.205 135.7 268.332 135.747C269.474 135.795 270.172 134.796 269.902 133.527C269.632 132.258 268.506 131.195 267.38 131.147C266.27 131.116 265.556 132.099 265.81 133.368Z" fill="#DEDEDE"/>
    <path d="M259.275 133.13C259.513 134.399 260.639 135.462 261.781 135.509C262.923 135.557 263.637 134.558 263.383 133.289C263.13 132.02 262.003 130.957 260.877 130.925C259.751 130.862 259.037 131.861 259.275 133.13Z" fill="#DEDEDE"/>
    <path d="M252.724 132.892C252.962 134.161 254.072 135.224 255.214 135.256C256.356 135.287 257.086 134.304 256.848 133.035C256.61 131.766 255.5 130.703 254.358 130.672C253.231 130.64 252.502 131.623 252.724 132.892Z" fill="#DEDEDE"/>
    <path d="M246.173 132.686C246.395 133.955 247.489 135.018 248.632 135.05C249.774 135.081 250.519 134.082 250.281 132.813C250.059 131.544 248.949 130.497 247.823 130.45C246.696 130.418 245.951 131.417 246.173 132.686Z" fill="#DEDEDE"/>
    <path d="M239.606 132.48C239.812 133.749 240.907 134.796 242.049 134.843C243.191 134.875 243.952 133.876 243.73 132.607C243.524 131.338 242.429 130.291 241.287 130.259C240.161 130.227 239.4 131.227 239.606 132.48Z" fill="#DEDEDE"/>
    <path d="M233.038 132.306C233.229 133.574 234.307 134.621 235.449 134.653C236.592 134.685 237.353 133.686 237.163 132.417C236.956 131.148 235.878 130.101 234.752 130.069C233.609 130.037 232.848 131.037 233.038 132.306Z" fill="#DEDEDE"/>
    <path d="M226.456 132.146C226.631 133.415 227.709 134.462 228.851 134.494C229.993 134.526 230.771 133.511 230.58 132.257C230.39 130.989 229.327 129.942 228.185 129.91C227.059 129.878 226.282 130.877 226.456 132.146Z" fill="#DEDEDE"/>
    <path d="M147.177 131.433C147.193 132.702 148.129 133.717 149.287 133.717C150.445 133.717 151.365 132.686 151.333 131.417C151.317 130.148 150.366 129.133 149.224 129.133C148.082 129.133 147.162 130.164 147.177 131.433Z" fill="#DEDEDE"/>
    <path d="M140.547 131.481C140.547 132.75 141.483 133.765 142.625 133.765C143.783 133.749 144.703 132.718 144.703 131.449C144.687 130.18 143.767 129.165 142.625 129.181C141.483 129.181 140.563 130.212 140.547 131.481Z" fill="#DEDEDE"/>
    <path d="M133.933 131.544C133.917 132.813 134.837 133.828 135.995 133.812C137.153 133.796 138.089 132.765 138.089 131.496C138.089 130.227 137.169 129.212 136.027 129.228C134.885 129.244 133.949 130.275 133.933 131.544Z" fill="#DEDEDE"/>
    <path d="M127.302 131.623C127.27 132.892 128.19 133.908 129.332 133.892C130.49 133.876 131.442 132.845 131.457 131.576C131.473 130.307 130.569 129.292 129.427 129.308C128.285 129.323 127.333 130.354 127.302 131.623Z" fill="#DEDEDE"/>
    <path d="M120.687 131.718C120.64 132.987 121.544 134.003 122.702 133.987C123.86 133.971 124.811 132.924 124.859 131.655C124.891 130.386 123.987 129.371 122.845 129.403C121.687 129.418 120.719 130.465 120.687 131.718Z" fill="#DEDEDE"/>
    <path d="M114.057 131.846C113.994 133.115 114.882 134.13 116.04 134.098C117.198 134.082 118.166 133.035 118.213 131.766C118.261 130.497 117.373 129.498 116.23 129.514C115.088 129.53 114.121 130.577 114.057 131.846Z" fill="#DEDEDE"/>
    <path d="M107.443 131.972C107.38 133.241 108.252 134.24 109.41 134.224C110.568 134.209 111.551 133.162 111.615 131.893C111.678 130.624 110.806 129.624 109.648 129.64C108.49 129.656 107.507 130.703 107.443 131.972Z" fill="#DEDEDE"/>
    <path d="M100.812 132.115C100.733 133.384 101.589 134.383 102.747 134.368C103.905 134.336 104.905 133.289 104.968 132.02C105.047 130.751 104.175 129.752 103.033 129.768C101.891 129.799 100.907 130.846 100.812 132.115Z" fill="#DEDEDE"/>
    <path d="M372.704 132.702C373.179 133.971 374.448 135.065 375.543 135.161C376.637 135.256 377.129 134.288 376.637 133.019C376.146 131.75 374.877 130.656 373.798 130.561C372.719 130.481 372.228 131.433 372.704 132.702Z" fill="#DEDEDE"/>
    <path d="M366.423 132.195C366.883 133.464 368.152 134.558 369.246 134.637C370.341 134.717 370.849 133.765 370.373 132.496C369.897 131.227 368.644 130.133 367.549 130.053C366.455 129.974 365.963 130.926 366.423 132.195Z" fill="#DEDEDE"/>
    <path d="M360.141 131.718C360.601 132.987 361.855 134.082 362.949 134.161C364.043 134.24 364.551 133.289 364.091 132.02C363.631 130.751 362.378 129.656 361.284 129.577C360.205 129.498 359.681 130.449 360.141 131.718Z" fill="#DEDEDE"/>
    <path d="M353.828 131.242C354.272 132.511 355.51 133.606 356.62 133.685C357.714 133.765 358.238 132.813 357.794 131.544C357.35 130.275 356.096 129.18 355.002 129.101C353.923 129.022 353.4 129.973 353.828 131.242Z" fill="#DEDEDE"/>
    <path d="M347.515 130.783C347.943 132.052 349.181 133.146 350.275 133.21C351.37 133.289 351.909 132.321 351.481 131.052C351.037 129.783 349.799 128.705 348.721 128.626C347.626 128.562 347.087 129.53 347.515 130.783Z" fill="#DEDEDE"/>
    <path d="M341.186 130.339C341.599 131.608 342.836 132.686 343.93 132.765C345.041 132.845 345.58 131.877 345.152 130.608C344.724 129.339 343.502 128.261 342.408 128.181C341.329 128.118 340.774 129.085 341.186 130.339Z" fill="#DEDEDE"/>
    <path d="M334.841 129.926C335.238 131.195 336.459 132.274 337.57 132.353C338.68 132.432 339.235 131.465 338.823 130.196C338.41 128.927 337.189 127.848 336.094 127.769C335 127.69 334.445 128.657 334.841 129.926Z" fill="#DEDEDE"/>
    <path d="M328.497 129.514C328.893 130.783 330.099 131.861 331.209 131.925C332.32 131.988 332.891 131.02 332.494 129.767C332.098 128.498 330.876 127.42 329.782 127.356C328.671 127.277 328.1 128.245 328.497 129.514Z" fill="#DEDEDE"/>
    <path d="M322.12 129.117C322.501 130.386 323.706 131.465 324.817 131.528C325.927 131.592 326.514 130.624 326.117 129.355C325.737 128.086 324.531 127.007 323.421 126.944C322.326 126.881 321.755 127.848 322.12 129.117Z" fill="#DEDEDE"/>
    <path d="M315.744 128.721C316.109 129.99 317.298 131.068 318.409 131.132C319.519 131.195 320.122 130.227 319.741 128.959C319.36 127.69 318.171 126.611 317.076 126.547C315.982 126.5 315.379 127.467 315.744 128.721Z" fill="#DEDEDE"/>
    <path d="M309.351 128.356C309.7 129.625 310.89 130.687 312 130.751C313.11 130.814 313.729 129.847 313.364 128.578C312.999 127.309 311.81 126.246 310.715 126.183C309.621 126.119 309.002 127.103 309.351 128.356Z" fill="#DEDEDE"/>
    <path d="M302.959 128.007C303.292 129.276 304.481 130.339 305.592 130.402C306.702 130.466 307.321 129.482 306.988 128.229C306.639 126.96 305.465 125.897 304.355 125.834C303.244 125.77 302.626 126.754 302.959 128.007Z" fill="#DEDEDE"/>
    <path d="M296.551 127.674C296.868 128.943 298.042 130.005 299.168 130.069C300.278 130.132 300.913 129.149 300.58 127.88C300.246 126.611 299.073 125.548 297.962 125.485C296.852 125.437 296.218 126.405 296.551 127.674Z" fill="#DEDEDE"/>
    <path d="M290.126 127.357C290.444 128.626 291.601 129.688 292.712 129.736C293.838 129.784 294.473 128.816 294.155 127.547C293.838 126.278 292.68 125.215 291.57 125.168C290.459 125.104 289.809 126.088 290.126 127.357Z" fill="#DEDEDE"/>
    <path d="M283.702 127.039C284.003 128.308 285.145 129.371 286.272 129.419C287.398 129.466 288.048 128.483 287.747 127.23C287.445 125.961 286.288 124.898 285.177 124.85C284.051 124.803 283.401 125.786 283.702 127.039Z" fill="#DEDEDE"/>
    <path d="M277.263 126.754C277.548 128.023 278.69 129.085 279.817 129.133C280.943 129.181 281.609 128.197 281.308 126.944C281.006 125.675 279.864 124.628 278.754 124.565C277.644 124.517 276.977 125.501 277.263 126.754Z" fill="#DEDEDE"/>
    <path d="M270.806 126.484C271.076 127.737 272.218 128.8 273.329 128.847C274.455 128.895 275.137 127.912 274.851 126.643C274.566 125.374 273.44 124.327 272.329 124.279C271.219 124.232 270.537 125.215 270.806 126.484Z" fill="#DEDEDE"/>
    <path d="M264.351 126.214C264.605 127.467 265.731 128.53 266.857 128.578C267.983 128.625 268.681 127.642 268.411 126.373C268.142 125.12 267.016 124.057 265.905 124.009C264.779 123.978 264.097 124.961 264.351 126.214Z" fill="#DEDEDE"/>
    <path d="M257.879 125.976C258.116 127.229 259.243 128.292 260.369 128.34C261.495 128.387 262.209 127.388 261.939 126.135C261.685 124.866 260.575 123.819 259.449 123.771C258.339 123.724 257.641 124.723 257.879 125.976Z" fill="#DEDEDE"/>
    <path d="M251.407 125.754C251.645 127.007 252.74 128.07 253.866 128.102C254.992 128.133 255.706 127.15 255.468 125.881C255.23 124.628 254.12 123.565 253.009 123.533C251.899 123.502 251.185 124.485 251.407 125.754Z" fill="#DEDEDE"/>
    <path d="M244.936 125.532C245.158 126.785 246.252 127.848 247.379 127.88C248.505 127.911 249.234 126.928 249.012 125.659C248.79 124.406 247.696 123.343 246.57 123.311C245.443 123.295 244.714 124.279 244.936 125.532Z" fill="#DEDEDE"/>
    <path d="M238.448 125.342C238.654 126.595 239.733 127.658 240.859 127.689C241.985 127.721 242.731 126.722 242.509 125.469C242.286 124.215 241.208 123.169 240.098 123.121C238.987 123.089 238.242 124.089 238.448 125.342Z" fill="#DEDEDE"/>
    <path d="M231.944 125.167C232.134 126.421 233.213 127.467 234.339 127.499C235.465 127.531 236.227 126.532 236.02 125.279C235.814 124.025 234.751 122.979 233.625 122.947C232.515 122.915 231.753 123.899 231.944 125.167Z" fill="#DEDEDE"/>
    <path d="M225.456 124.993C225.631 126.246 226.694 127.293 227.836 127.325C228.962 127.357 229.739 126.357 229.549 125.088C229.358 123.835 228.296 122.788 227.17 122.757C226.043 122.741 225.266 123.74 225.456 124.993Z" fill="#DEDEDE"/>
    <path d="M218.937 124.85C219.096 126.103 220.158 127.15 221.285 127.182C222.411 127.213 223.188 126.198 223.029 124.945C222.855 123.692 221.808 122.645 220.682 122.613C219.556 122.598 218.778 123.597 218.937 124.85Z" fill="#DEDEDE"/>
    <path d="M205.914 124.612C206.057 125.865 207.088 126.912 208.214 126.928C209.356 126.944 210.149 125.945 210.007 124.676C209.864 123.423 208.833 122.376 207.707 122.36C206.581 122.344 205.772 123.343 205.914 124.612Z" fill="#DEDEDE"/>
    <path d="M192.875 124.422C192.986 125.675 194.001 126.722 195.128 126.722C196.27 126.738 197.095 125.722 196.968 124.469C196.857 123.216 195.841 122.185 194.715 122.169C193.589 122.154 192.764 123.169 192.875 124.422Z" fill="#DEDEDE"/>
    <path d="M147.114 124.295C147.13 125.548 148.066 126.563 149.192 126.563C150.334 126.563 151.238 125.532 151.222 124.279C151.206 123.026 150.271 122.011 149.144 122.011C148.002 122.011 147.098 123.042 147.114 124.295Z" fill="#DEDEDE"/>
    <path d="M140.562 124.342C140.562 125.596 141.482 126.611 142.625 126.611C143.767 126.595 144.687 125.58 144.671 124.311C144.671 123.058 143.735 122.042 142.609 122.042C141.482 122.058 140.562 123.089 140.562 124.342Z" fill="#DEDEDE"/>
    <path d="M134.012 124.406C133.996 125.659 134.9 126.674 136.042 126.658C137.184 126.642 138.104 125.611 138.12 124.358C138.12 123.105 137.216 122.09 136.09 122.106C134.964 122.122 134.028 123.153 134.012 124.406Z" fill="#DEDEDE"/>
    <path d="M127.476 124.485C127.445 125.738 128.349 126.754 129.491 126.738C130.633 126.722 131.569 125.691 131.585 124.438C131.601 123.185 130.712 122.169 129.57 122.185C128.444 122.201 127.508 123.232 127.476 124.485Z" fill="#DEDEDE"/>
    <path d="M120.926 124.581C120.879 125.834 121.767 126.849 122.909 126.833C124.051 126.817 125.003 125.786 125.034 124.517C125.066 123.264 124.178 122.249 123.052 122.265C121.925 122.296 120.974 123.327 120.926 124.581Z" fill="#DEDEDE"/>
    <path d="M369.992 125.548C370.468 126.801 371.737 127.896 372.815 127.991C373.894 128.086 374.37 127.135 373.894 125.866C373.402 124.612 372.149 123.518 371.071 123.423C369.992 123.344 369.516 124.295 369.992 125.548Z" fill="#DEDEDE"/>
    <path d="M363.789 125.056C364.249 126.31 365.518 127.404 366.597 127.499C367.675 127.578 368.167 126.627 367.691 125.374C367.215 124.105 365.962 123.026 364.884 122.931C363.821 122.852 363.329 123.803 363.789 125.056Z" fill="#DEDEDE"/>
    <path d="M357.572 124.581C358.032 125.834 359.269 126.928 360.363 127.008C361.442 127.087 361.95 126.135 361.49 124.882C361.03 123.629 359.776 122.534 358.698 122.455C357.619 122.36 357.112 123.312 357.572 124.581Z" fill="#DEDEDE"/>
    <path d="M351.338 124.104C351.782 125.357 353.02 126.452 354.098 126.531C355.177 126.611 355.7 125.659 355.24 124.39C354.796 123.137 353.543 122.042 352.48 121.963C351.418 121.9 350.894 122.851 351.338 124.104Z" fill="#DEDEDE"/>
    <path d="M345.088 123.66C345.517 124.913 346.754 126.008 347.833 126.087C348.927 126.166 349.45 125.215 349.006 123.946C348.562 122.693 347.341 121.598 346.262 121.519C345.184 121.44 344.66 122.391 345.088 123.66Z" fill="#DEDEDE"/>
    <path d="M338.838 123.217C339.251 124.47 340.472 125.564 341.567 125.628C342.661 125.707 343.2 124.739 342.772 123.486C342.344 122.233 341.123 121.155 340.044 121.075C338.949 120.996 338.41 121.964 338.838 123.217Z" fill="#DEDEDE"/>
    <path d="M332.557 122.788C332.954 124.041 334.175 125.12 335.27 125.199C336.364 125.279 336.919 124.311 336.491 123.058C336.079 121.805 334.857 120.726 333.779 120.647C332.7 120.568 332.161 121.535 332.557 122.788Z" fill="#DEDEDE"/>
    <path d="M326.276 122.376C326.672 123.629 327.878 124.707 328.972 124.787C330.067 124.85 330.622 123.899 330.225 122.63C329.829 121.376 328.623 120.298 327.529 120.219C326.45 120.155 325.879 121.123 326.276 122.376Z" fill="#DEDEDE"/>
    <path d="M319.979 121.979C320.36 123.232 321.55 124.311 322.644 124.374C323.738 124.438 324.31 123.47 323.929 122.217C323.532 120.964 322.343 119.885 321.248 119.822C320.185 119.774 319.598 120.726 319.979 121.979Z" fill="#DEDEDE"/>
    <path d="M313.681 121.599C314.046 122.852 315.236 123.93 316.33 123.994C317.425 124.057 318.011 123.09 317.631 121.836C317.25 120.583 316.06 119.505 314.982 119.441C313.903 119.378 313.316 120.345 313.681 121.599Z" fill="#DEDEDE"/>
    <path d="M307.368 121.234C307.717 122.487 308.906 123.566 310.001 123.629C311.095 123.692 311.698 122.725 311.333 121.472C310.969 120.219 309.795 119.14 308.7 119.092C307.606 119.013 307.003 119.981 307.368 121.234Z" fill="#DEDEDE"/>
    <path d="M301.039 120.885C301.372 122.138 302.546 123.216 303.656 123.264C304.766 123.327 305.369 122.36 305.02 121.091C304.671 119.838 303.498 118.775 302.403 118.712C301.309 118.664 300.69 119.632 301.039 120.885Z" fill="#DEDEDE"/>
    <path d="M294.694 120.552C295.027 121.805 296.185 122.868 297.28 122.931C298.39 122.995 299.009 122.011 298.676 120.758C298.343 119.505 297.185 118.442 296.09 118.379C294.996 118.331 294.377 119.299 294.694 120.552Z" fill="#DEDEDE"/>
    <path d="M288.35 120.234C288.667 121.487 289.809 122.55 290.919 122.613C292.03 122.661 292.664 121.693 292.331 120.44C292.014 119.187 290.856 118.124 289.762 118.077C288.667 118.013 288.033 118.981 288.35 120.234Z" fill="#DEDEDE"/>
    <path d="M281.989 119.933C282.291 121.186 283.433 122.249 284.543 122.296C285.653 122.344 286.304 121.376 285.987 120.123C285.669 118.87 284.527 117.807 283.433 117.76C282.338 117.712 281.688 118.68 281.989 119.933Z" fill="#DEDEDE"/>
    <path d="M275.628 119.648C275.914 120.901 277.056 121.963 278.15 122.011C279.261 122.059 279.911 121.075 279.625 119.822C279.324 118.569 278.198 117.506 277.103 117.459C276.009 117.411 275.343 118.394 275.628 119.648Z" fill="#DEDEDE"/>
    <path d="M269.252 119.378C269.521 120.631 270.648 121.694 271.758 121.741C272.868 121.789 273.535 120.806 273.249 119.552C272.964 118.299 271.837 117.252 270.743 117.205C269.648 117.141 268.982 118.125 269.252 119.378Z" fill="#DEDEDE"/>
    <path d="M262.875 119.108C263.129 120.361 264.255 121.408 265.366 121.456C266.476 121.503 267.158 120.52 266.888 119.267C266.619 118.014 265.508 116.967 264.398 116.919C263.304 116.887 262.621 117.855 262.875 119.108Z" fill="#DEDEDE"/>
    <path d="M256.482 118.87C256.736 120.123 257.831 121.17 258.941 121.218C260.051 121.265 260.749 120.282 260.495 119.029C260.242 117.776 259.131 116.729 258.037 116.681C256.926 116.634 256.244 117.617 256.482 118.87Z" fill="#DEDEDE"/>
    <path d="M250.091 118.648C250.329 119.901 251.423 120.948 252.533 120.996C253.644 121.027 254.358 120.044 254.104 118.791C253.866 117.538 252.771 116.491 251.661 116.459C250.567 116.412 249.853 117.395 250.091 118.648Z" fill="#DEDEDE"/>
    <path d="M243.682 118.442C243.904 119.695 244.983 120.742 246.093 120.774C247.204 120.805 247.933 119.822 247.696 118.569C247.473 117.316 246.379 116.269 245.285 116.237C244.19 116.205 243.46 117.189 243.682 118.442Z" fill="#DEDEDE"/>
    <path d="M237.274 118.235C237.48 119.489 238.559 120.535 239.669 120.567C240.78 120.599 241.509 119.615 241.303 118.362C241.081 117.109 240.018 116.062 238.908 116.031C237.798 115.999 237.068 116.982 237.274 118.235Z" fill="#DEDEDE"/>
    <path d="M230.865 118.061C231.056 119.315 232.118 120.361 233.245 120.393C234.355 120.425 235.101 119.426 234.894 118.172C234.688 116.919 233.625 115.888 232.515 115.857C231.405 115.825 230.659 116.808 230.865 118.061Z" fill="#DEDEDE"/>
    <path d="M224.441 117.902C224.616 119.156 225.679 120.187 226.789 120.218C227.915 120.25 228.661 119.251 228.47 117.998C228.28 116.744 227.233 115.713 226.123 115.682C225.012 115.666 224.251 116.649 224.441 117.902Z" fill="#DEDEDE"/>
    <path d="M218.001 117.76C218.159 119.013 219.206 120.044 220.333 120.075C221.459 120.107 222.22 119.108 222.046 117.855C221.871 116.602 220.824 115.571 219.714 115.539C218.604 115.507 217.842 116.507 218.001 117.76Z" fill="#DEDEDE"/>
    <path d="M211.578 117.617C211.736 118.87 212.751 119.901 213.878 119.917C215.004 119.933 215.781 118.934 215.622 117.696C215.464 116.443 214.433 115.412 213.323 115.396C212.196 115.381 211.419 116.38 211.578 117.617Z" fill="#DEDEDE"/>
    <path d="M205.138 117.506C205.28 118.759 206.296 119.79 207.422 119.806C208.548 119.822 209.341 118.823 209.183 117.57C209.04 116.317 208.009 115.286 206.898 115.27C205.788 115.254 204.995 116.253 205.138 117.506Z" fill="#DEDEDE"/>
    <path d="M185.801 117.268C185.896 118.521 186.88 119.536 188.006 119.552C189.132 119.568 189.957 118.553 189.846 117.3C189.735 116.047 188.751 115.032 187.641 115.016C186.531 115.016 185.706 116.015 185.801 117.268Z" fill="#DEDEDE"/>
    <path d="M179.346 117.205C179.425 118.458 180.408 119.473 181.534 119.489C182.661 119.489 183.501 118.49 183.406 117.237C183.311 115.984 182.343 114.968 181.217 114.953C180.107 114.953 179.266 115.968 179.346 117.205Z" fill="#DEDEDE"/>
    <path d="M153.506 117.173C153.538 118.426 154.458 119.441 155.584 119.441C156.71 119.441 157.599 118.426 157.551 117.173C157.519 115.92 156.583 114.904 155.473 114.92C154.363 114.904 153.474 115.92 153.506 117.173Z" fill="#DEDEDE"/>
    <path d="M114.708 117.601C114.644 118.854 115.517 119.854 116.643 119.838C117.769 119.822 118.721 118.791 118.769 117.538C118.816 116.285 117.96 115.285 116.833 115.317C115.723 115.317 114.771 116.348 114.708 117.601Z" fill="#DEDEDE"/>
    <path d="M108.252 117.728C108.172 118.981 109.029 119.981 110.155 119.949C111.281 119.933 112.249 118.886 112.312 117.633C112.376 116.38 111.519 115.381 110.409 115.412C109.283 115.444 108.315 116.475 108.252 117.728Z" fill="#DEDEDE"/>
    <path d="M101.781 117.871C101.685 119.124 102.542 120.123 103.668 120.092C104.794 120.06 105.762 119.029 105.841 117.776C105.921 116.523 105.08 115.539 103.954 115.555C102.843 115.587 101.876 116.634 101.781 117.871Z" fill="#DEDEDE"/>
    <path d="M95.3244 118.045C95.2292 119.298 96.054 120.282 97.1802 120.266C98.3065 120.234 99.2899 119.203 99.3851 117.95C99.4802 116.697 98.6396 115.713 97.5292 115.729C96.403 115.745 95.4195 116.792 95.3244 118.045Z" fill="#DEDEDE"/>
    <path d="M373.402 118.949C373.894 120.203 375.163 121.297 376.226 121.392C377.289 121.487 377.749 120.536 377.241 119.283C376.733 118.029 375.48 116.935 374.418 116.856C373.371 116.76 372.911 117.696 373.402 118.949Z" fill="#DEDEDE"/>
    <path d="M367.28 118.442C367.756 119.695 369.009 120.789 370.087 120.869C371.15 120.964 371.626 120.012 371.134 118.759C370.642 117.506 369.389 116.411 368.327 116.332C367.264 116.253 366.804 117.189 367.28 118.442Z" fill="#DEDEDE"/>
    <path d="M361.141 117.95C361.617 119.203 362.854 120.282 363.933 120.377C364.995 120.456 365.487 119.52 365.011 118.267C364.535 117.014 363.282 115.935 362.22 115.84C361.157 115.761 360.681 116.697 361.141 117.95Z" fill="#DEDEDE"/>
    <path d="M354.986 117.475C355.446 118.728 356.684 119.806 357.746 119.901C358.825 119.981 359.317 119.029 358.841 117.776C358.381 116.523 357.144 115.444 356.081 115.365C355.034 115.27 354.542 116.222 354.986 117.475Z" fill="#DEDEDE"/>
    <path d="M348.832 117.014C349.276 118.267 350.513 119.346 351.576 119.425C352.655 119.504 353.147 118.553 352.702 117.3C352.242 116.046 351.021 114.968 349.958 114.889C348.88 114.809 348.388 115.761 348.832 117.014Z" fill="#DEDEDE"/>
    <path d="M342.645 116.554C343.073 117.807 344.294 118.886 345.373 118.965C346.452 119.044 346.959 118.093 346.531 116.84C346.087 115.587 344.865 114.508 343.803 114.429C342.74 114.349 342.216 115.301 342.645 116.554Z" fill="#DEDEDE"/>
    <path d="M336.459 116.126C336.871 117.379 338.093 118.458 339.171 118.521C340.25 118.6 340.773 117.649 340.345 116.396C339.917 115.142 338.695 114.064 337.633 114C336.57 113.921 336.046 114.873 336.459 116.126Z" fill="#DEDEDE"/>
    <path d="M330.257 115.698C330.669 116.951 331.875 118.03 332.953 118.093C334.032 118.173 334.571 117.205 334.159 115.952C333.746 114.699 332.541 113.636 331.462 113.557C330.399 113.493 329.86 114.445 330.257 115.698Z" fill="#DEDEDE"/>
    <path d="M324.055 115.301C324.452 116.554 325.641 117.617 326.736 117.696C327.814 117.76 328.37 116.808 327.973 115.555C327.577 114.302 326.371 113.239 325.308 113.16C324.214 113.081 323.659 114.048 324.055 115.301Z" fill="#DEDEDE"/>
    <path d="M317.821 114.905C318.202 116.158 319.392 117.221 320.47 117.284C321.549 117.348 322.12 116.396 321.723 115.143C321.327 113.89 320.137 112.827 319.074 112.763C318.012 112.7 317.441 113.652 317.821 114.905Z" fill="#DEDEDE"/>
    <path d="M311.604 114.524C311.968 115.777 313.158 116.84 314.237 116.903C315.315 116.967 315.902 115.999 315.521 114.762C315.141 113.509 313.967 112.446 312.888 112.383C311.81 112.319 311.223 113.271 311.604 114.524Z" fill="#DEDEDE"/>
    <path d="M305.354 114.159C305.703 115.412 306.876 116.475 307.971 116.539C309.065 116.602 309.652 115.635 309.287 114.397C308.923 113.144 307.749 112.081 306.67 112.018C305.592 111.955 305.005 112.906 305.354 114.159Z" fill="#DEDEDE"/>
    <path d="M299.104 113.81C299.453 115.063 300.611 116.126 301.69 116.174C302.784 116.237 303.371 115.27 303.022 114.016C302.673 112.763 301.515 111.716 300.436 111.653C299.358 111.605 298.755 112.573 299.104 113.81Z" fill="#DEDEDE"/>
    <path d="M292.839 113.477C293.172 114.73 294.314 115.777 295.409 115.841C296.503 115.904 297.106 114.937 296.773 113.683C296.44 112.43 295.282 111.383 294.203 111.32C293.125 111.272 292.506 112.24 292.839 113.477Z" fill="#DEDEDE"/>
    <path d="M286.574 113.16C286.891 114.413 288.034 115.46 289.128 115.523C290.223 115.571 290.841 114.603 290.524 113.366C290.191 112.113 289.065 111.066 287.97 111.018C286.876 110.955 286.257 111.922 286.574 113.16Z" fill="#DEDEDE"/>
    <path d="M280.292 112.858C280.593 114.111 281.719 115.158 282.814 115.206C283.908 115.254 284.543 114.286 284.226 113.049C283.908 111.796 282.782 110.749 281.703 110.701C280.625 110.654 279.99 111.621 280.292 112.858Z" fill="#DEDEDE"/>
    <path d="M273.995 112.573C274.281 113.826 275.407 114.873 276.501 114.92C277.596 114.968 278.246 114 277.945 112.747C277.643 111.494 276.517 110.447 275.438 110.4C274.36 110.368 273.709 111.335 273.995 112.573Z" fill="#DEDEDE"/>
    <path d="M267.697 112.303C267.967 113.556 269.077 114.603 270.187 114.651C271.282 114.698 271.948 113.731 271.663 112.477C271.377 111.224 270.267 110.193 269.172 110.146C268.078 110.082 267.427 111.066 267.697 112.303Z" fill="#DEDEDE"/>
    <path d="M261.4 112.049C261.67 113.287 262.764 114.349 263.859 114.381C264.953 114.429 265.635 113.445 265.35 112.208C265.08 110.955 263.97 109.924 262.891 109.876C261.797 109.829 261.131 110.812 261.4 112.049Z" fill="#DEDEDE"/>
    <path d="M255.088 111.811C255.341 113.049 256.436 114.096 257.53 114.143C258.625 114.191 259.307 113.207 259.053 111.954C258.799 110.717 257.705 109.67 256.61 109.622C255.516 109.591 254.834 110.574 255.088 111.811Z" fill="#DEDEDE"/>
    <path d="M248.758 111.589C248.996 112.827 250.074 113.873 251.185 113.921C252.279 113.953 252.977 112.985 252.739 111.732C252.501 110.495 251.407 109.448 250.328 109.416C249.218 109.369 248.52 110.336 248.758 111.589Z" fill="#DEDEDE"/>
    <path d="M242.429 111.383C242.651 112.62 243.729 113.667 244.824 113.699C245.918 113.731 246.632 112.747 246.394 111.51C246.156 110.273 245.093 109.226 243.999 109.194C242.92 109.163 242.222 110.13 242.429 111.383Z" fill="#DEDEDE"/>
    <path d="M236.1 111.193C236.306 112.43 237.369 113.477 238.479 113.509C239.59 113.541 240.303 112.557 240.081 111.32C239.859 110.083 238.796 109.036 237.702 109.004C236.608 108.957 235.894 109.94 236.1 111.193Z" fill="#DEDEDE"/>
    <path d="M229.772 111.002C229.962 112.24 231.009 113.287 232.119 113.318C233.23 113.35 233.959 112.367 233.753 111.113C233.547 109.876 232.5 108.845 231.405 108.813C230.295 108.782 229.565 109.765 229.772 111.002Z" fill="#DEDEDE"/>
    <path d="M223.427 110.844C223.601 112.081 224.648 113.128 225.758 113.144C226.869 113.176 227.598 112.176 227.424 110.939C227.234 109.702 226.187 108.671 225.092 108.639C223.982 108.623 223.236 109.607 223.427 110.844Z" fill="#DEDEDE"/>
    <path d="M217.066 110.701C217.224 111.938 218.255 112.969 219.366 113.001C220.476 113.033 221.222 112.034 221.047 110.796C220.873 109.559 219.842 108.528 218.747 108.496C217.653 108.465 216.907 109.464 217.066 110.701Z" fill="#DEDEDE"/>
    <path d="M210.721 110.574C210.879 111.811 211.894 112.843 213.005 112.874C214.115 112.89 214.876 111.907 214.718 110.654C214.559 109.416 213.528 108.385 212.434 108.369C211.323 108.338 210.562 109.321 210.721 110.574Z" fill="#DEDEDE"/>
    <path d="M204.36 110.463C204.502 111.7 205.502 112.731 206.612 112.747C207.722 112.763 208.5 111.78 208.357 110.527C208.214 109.289 207.199 108.258 206.104 108.242C204.994 108.227 204.217 109.21 204.36 110.463Z" fill="#DEDEDE"/>
    <path d="M198 110.368C198.127 111.605 199.126 112.636 200.236 112.652C201.347 112.668 202.14 111.669 201.997 110.431C201.87 109.194 200.871 108.163 199.76 108.147C198.666 108.131 197.873 109.115 198 110.368Z" fill="#DEDEDE"/>
    <path d="M191.623 110.273C191.734 111.51 192.717 112.541 193.828 112.557C194.938 112.573 195.747 111.573 195.62 110.336C195.493 109.099 194.51 108.084 193.415 108.068C192.321 108.036 191.512 109.035 191.623 110.273Z" fill="#DEDEDE"/>
    <path d="M185.247 110.209C185.342 111.447 186.325 112.478 187.436 112.478C188.546 112.493 189.355 111.494 189.26 110.241C189.149 109.004 188.181 107.989 187.071 107.973C185.976 107.973 185.167 108.972 185.247 110.209Z" fill="#DEDEDE"/>
    <path d="M178.885 110.162C178.965 111.399 179.932 112.414 181.043 112.43C182.153 112.446 182.978 111.431 182.883 110.194C182.787 108.956 181.82 107.941 180.725 107.925C179.631 107.925 178.79 108.925 178.885 110.162Z" fill="#DEDEDE"/>
    <path d="M166.117 110.114C166.164 111.352 167.116 112.367 168.226 112.367C169.337 112.367 170.193 111.368 170.13 110.114C170.066 108.877 169.115 107.862 168.02 107.862C166.926 107.862 166.069 108.877 166.117 110.114Z" fill="#DEDEDE"/>
    <path d="M159.741 110.114C159.788 111.352 160.708 112.367 161.819 112.367C162.929 112.367 163.785 111.352 163.738 110.114C163.69 108.877 162.754 107.862 161.66 107.862C160.565 107.862 159.709 108.877 159.741 110.114Z" fill="#DEDEDE"/>
    <path d="M121.433 110.448C121.386 111.685 122.258 112.684 123.369 112.668C124.479 112.652 125.415 111.621 125.447 110.384C125.478 109.147 124.622 108.148 123.511 108.163C122.401 108.179 121.465 109.194 121.433 110.448Z" fill="#DEDEDE"/>
    <path d="M115.041 110.558C114.977 111.796 115.834 112.795 116.944 112.779C118.055 112.763 119.006 111.732 119.054 110.495C119.102 109.258 118.245 108.258 117.151 108.29C116.04 108.29 115.104 109.305 115.041 110.558Z" fill="#DEDEDE"/>
    <path d="M108.649 110.685C108.569 111.923 109.41 112.922 110.536 112.89C111.647 112.874 112.598 111.843 112.662 110.59C112.725 109.353 111.885 108.354 110.774 108.386C109.68 108.417 108.728 109.432 108.649 110.685Z" fill="#DEDEDE"/>
    <path d="M102.272 110.828C102.177 112.066 103.018 113.065 104.128 113.033C105.238 113.001 106.206 111.97 106.285 110.733C106.364 109.496 105.54 108.512 104.429 108.528C103.319 108.56 102.351 109.591 102.272 110.828Z" fill="#DEDEDE"/>
    <path d="M95.8792 110.987C95.784 112.224 96.593 113.223 97.7033 113.192C98.8137 113.16 99.7971 112.129 99.8764 110.892C99.9716 109.654 99.1468 108.671 98.0523 108.687C96.9578 108.718 95.9902 109.749 95.8792 110.987Z" fill="#DEDEDE"/>
    <path d="M370.61 111.891C371.102 113.144 372.355 114.223 373.418 114.318C374.465 114.413 374.925 113.461 374.417 112.224C373.91 110.971 372.657 109.892 371.61 109.813C370.563 109.702 370.119 110.638 370.61 111.891Z" fill="#DEDEDE"/>
    <path d="M364.551 111.383C365.027 112.636 366.28 113.715 367.343 113.794C368.405 113.874 368.849 112.938 368.358 111.701C367.866 110.447 366.613 109.369 365.566 109.289C364.535 109.194 364.075 110.13 364.551 111.383Z" fill="#DEDEDE"/>
    <path d="M358.492 110.891C358.967 112.144 360.205 113.223 361.252 113.302C362.314 113.382 362.774 112.446 362.298 111.193C361.823 109.94 360.585 108.877 359.538 108.782C358.492 108.702 358.016 109.654 358.492 110.891Z" fill="#DEDEDE"/>
    <path d="M352.401 110.416C352.861 111.653 354.083 112.732 355.145 112.827C356.208 112.906 356.684 111.97 356.224 110.717C355.764 109.464 354.527 108.401 353.48 108.322C352.433 108.227 351.941 109.179 352.401 110.416Z" fill="#DEDEDE"/>
    <path d="M346.31 109.956C346.754 111.193 347.975 112.272 349.038 112.351C350.101 112.43 350.592 111.479 350.132 110.241C349.672 109.004 348.451 107.925 347.404 107.846C346.357 107.767 345.865 108.703 346.31 109.956Z" fill="#DEDEDE"/>
    <path d="M340.203 109.512C340.631 110.749 341.852 111.828 342.915 111.907C343.978 111.986 344.485 111.034 344.041 109.797C343.597 108.56 342.392 107.481 341.329 107.402C340.266 107.323 339.774 108.259 340.203 109.512Z" fill="#DEDEDE"/>
    <path d="M334.081 109.068C334.509 110.305 335.714 111.383 336.777 111.463C337.84 111.542 338.363 110.59 337.935 109.353C337.507 108.116 336.301 107.037 335.238 106.974C334.176 106.879 333.668 107.83 334.081 109.068Z" fill="#DEDEDE"/>
    <path d="M327.957 108.655C328.37 109.892 329.559 110.971 330.622 111.034C331.685 111.113 332.224 110.162 331.796 108.909C331.384 107.671 330.178 106.609 329.131 106.529C328.068 106.466 327.545 107.418 327.957 108.655Z" fill="#DEDEDE"/>
    <path d="M321.818 108.258C322.215 109.496 323.404 110.558 324.467 110.638C325.53 110.701 326.069 109.749 325.673 108.512C325.26 107.275 324.087 106.212 323.024 106.133C321.961 106.054 321.422 107.005 321.818 108.258Z" fill="#DEDEDE"/>
    <path d="M315.664 107.862C316.045 109.099 317.219 110.162 318.298 110.225C319.376 110.289 319.915 109.337 319.535 108.1C319.138 106.862 317.964 105.8 316.902 105.736C315.839 105.673 315.284 106.624 315.664 107.862Z" fill="#DEDEDE"/>
    <path d="M309.509 107.481C309.874 108.718 311.048 109.781 312.127 109.845C313.205 109.908 313.76 108.956 313.38 107.719C312.999 106.482 311.825 105.419 310.763 105.356C309.7 105.292 309.145 106.244 309.509 107.481Z" fill="#DEDEDE"/>
    <path d="M303.339 107.132C303.688 108.37 304.862 109.432 305.924 109.496C307.003 109.559 307.574 108.607 307.209 107.354C306.844 106.117 305.687 105.054 304.624 105.007C303.561 104.943 302.974 105.895 303.339 107.132Z" fill="#DEDEDE"/>
    <path d="M297.153 106.783C297.502 108.02 298.644 109.083 299.722 109.131C300.801 109.194 301.388 108.226 301.039 106.989C300.69 105.752 299.532 104.705 298.469 104.642C297.407 104.594 296.82 105.546 297.153 106.783Z" fill="#DEDEDE"/>
    <path d="M290.967 106.45C291.3 107.687 292.442 108.734 293.521 108.798C294.599 108.861 295.202 107.893 294.853 106.656C294.52 105.419 293.378 104.372 292.299 104.309C291.236 104.261 290.634 105.213 290.967 106.45Z" fill="#DEDEDE"/>
    <path d="M284.781 106.133C285.098 107.37 286.225 108.417 287.303 108.481C288.382 108.528 289.001 107.576 288.667 106.339C288.334 105.102 287.208 104.055 286.145 104.008C285.067 103.944 284.464 104.896 284.781 106.133Z" fill="#DEDEDE"/>
    <path d="M278.563 105.847C278.865 107.085 279.991 108.131 281.07 108.179C282.148 108.227 282.767 107.275 282.465 106.022C282.148 104.785 281.022 103.738 279.959 103.69C278.896 103.642 278.262 104.594 278.563 105.847Z" fill="#DEDEDE"/>
    <path d="M272.361 105.562C272.647 106.799 273.757 107.846 274.851 107.894C275.93 107.941 276.565 106.974 276.263 105.737C275.962 104.499 274.851 103.452 273.773 103.405C272.694 103.357 272.06 104.325 272.361 105.562Z" fill="#DEDEDE"/>
    <path d="M266.143 105.292C266.413 106.53 267.523 107.576 268.602 107.624C269.681 107.672 270.331 106.704 270.045 105.467C269.76 104.23 268.65 103.198 267.587 103.151C266.508 103.087 265.858 104.055 266.143 105.292Z" fill="#DEDEDE"/>
    <path d="M259.909 105.039C260.179 106.276 261.257 107.323 262.352 107.355C263.43 107.402 264.097 106.435 263.827 105.197C263.557 103.96 262.463 102.929 261.384 102.881C260.306 102.834 259.639 103.801 259.909 105.039Z" fill="#DEDEDE"/>
    <path d="M253.676 104.8C253.93 106.038 255.008 107.069 256.103 107.116C257.197 107.164 257.864 106.18 257.594 104.943C257.34 103.706 256.246 102.675 255.183 102.627C254.088 102.596 253.422 103.563 253.676 104.8Z" fill="#DEDEDE"/>
    <path d="M247.426 104.578C247.664 105.816 248.742 106.847 249.821 106.894C250.915 106.926 251.597 105.958 251.344 104.721C251.106 103.484 250.027 102.453 248.949 102.421C247.87 102.374 247.188 103.341 247.426 104.578Z" fill="#DEDEDE"/>
    <path d="M241.176 104.372C241.398 105.609 242.461 106.641 243.555 106.672C244.65 106.704 245.332 105.736 245.11 104.499C244.872 103.262 243.809 102.231 242.73 102.199C241.652 102.167 240.954 103.135 241.176 104.372Z" fill="#DEDEDE"/>
    <path d="M234.927 104.182C235.133 105.419 236.18 106.45 237.274 106.482C238.369 106.514 239.067 105.53 238.86 104.309C238.638 103.072 237.592 102.041 236.513 102.009C235.418 101.977 234.72 102.945 234.927 104.182Z" fill="#DEDEDE"/>
    <path d="M228.661 104.007C228.851 105.245 229.898 106.276 230.992 106.307C232.087 106.339 232.801 105.356 232.594 104.118C232.388 102.881 231.341 101.866 230.263 101.834C229.184 101.787 228.47 102.77 228.661 104.007Z" fill="#DEDEDE"/>
    <path d="M222.396 103.849C222.57 105.086 223.601 106.101 224.696 106.133C225.79 106.165 226.52 105.181 226.33 103.944C226.139 102.707 225.108 101.692 224.03 101.66C222.951 101.628 222.221 102.612 222.396 103.849Z" fill="#DEDEDE"/>
    <path d="M216.129 103.69C216.304 104.927 217.319 105.943 218.413 105.974C219.508 106.006 220.253 105.023 220.079 103.785C219.904 102.548 218.889 101.533 217.795 101.501C216.7 101.485 215.955 102.469 216.129 103.69Z" fill="#DEDEDE"/>
    <path d="M209.848 103.563C210.007 104.8 211.006 105.816 212.1 105.832C213.195 105.847 213.956 104.864 213.782 103.627C213.623 102.389 212.608 101.374 211.529 101.358C210.451 101.358 209.705 102.342 209.848 103.563Z" fill="#DEDEDE"/>
    <path d="M203.567 103.452C203.71 104.69 204.709 105.705 205.804 105.721C206.898 105.736 207.659 104.753 207.517 103.516C207.374 102.278 206.375 101.263 205.28 101.247C204.201 101.247 203.44 102.231 203.567 103.452Z" fill="#DEDEDE"/>
    <path d="M197.286 103.357C197.412 104.594 198.396 105.609 199.49 105.625C200.585 105.641 201.362 104.658 201.235 103.42C201.108 102.183 200.109 101.168 199.03 101.152C197.936 101.136 197.159 102.12 197.286 103.357Z" fill="#DEDEDE"/>
    <path d="M191.005 103.278C191.116 104.515 192.099 105.53 193.193 105.53C194.288 105.546 195.081 104.547 194.954 103.325C194.827 102.088 193.86 101.089 192.765 101.073C191.687 101.057 190.893 102.04 191.005 103.278Z" fill="#DEDEDE"/>
    <path d="M184.707 103.214C184.802 104.452 185.77 105.451 186.864 105.467C187.959 105.483 188.768 104.483 188.656 103.246C188.545 102.009 187.578 101.01 186.499 100.994C185.421 100.994 184.612 101.977 184.707 103.214Z" fill="#DEDEDE"/>
    <path d="M178.41 103.167C178.489 104.404 179.441 105.403 180.551 105.419C181.646 105.419 182.471 104.436 182.376 103.198C182.28 101.961 181.329 100.962 180.234 100.946C179.156 100.946 178.331 101.929 178.41 103.167Z" fill="#DEDEDE"/>
    <path d="M165.815 103.119C165.862 104.357 166.798 105.356 167.893 105.356C168.987 105.356 169.828 104.357 169.765 103.119C169.701 101.882 168.765 100.883 167.687 100.883C166.608 100.883 165.767 101.882 165.815 103.119Z" fill="#DEDEDE"/>
    <path d="M153.204 103.135C153.236 104.372 154.14 105.372 155.235 105.372C156.329 105.372 157.202 104.372 157.154 103.135C157.122 101.898 156.202 100.914 155.124 100.914C154.045 100.899 153.188 101.898 153.204 103.135Z" fill="#DEDEDE"/>
    <path d="M146.907 103.167C146.923 104.404 147.811 105.403 148.921 105.387C150.016 105.387 150.888 104.372 150.872 103.135C150.857 101.898 149.952 100.914 148.858 100.914C147.763 100.93 146.891 101.929 146.907 103.167Z" fill="#DEDEDE"/>
    <path d="M134.297 103.278C134.281 104.515 135.154 105.498 136.248 105.498C137.343 105.482 138.247 104.483 138.247 103.246C138.247 102.009 137.374 101.025 136.296 101.041C135.201 101.025 134.313 102.04 134.297 103.278Z" fill="#DEDEDE"/>
    <path d="M121.686 103.436C121.638 104.674 122.495 105.657 123.589 105.641C124.684 105.625 125.604 104.61 125.651 103.373C125.683 102.136 124.827 101.152 123.748 101.168C122.638 101.2 121.734 102.215 121.686 103.436Z" fill="#DEDEDE"/>
    <path d="M115.375 103.547C115.312 104.784 116.152 105.768 117.263 105.752C118.357 105.736 119.293 104.721 119.34 103.484C119.388 102.247 118.547 101.263 117.453 101.295C116.374 101.311 115.438 102.326 115.375 103.547Z" fill="#DEDEDE"/>
    <path d="M373.783 105.372C374.291 106.609 375.544 107.687 376.591 107.783C377.637 107.878 378.05 106.942 377.542 105.705C377.019 104.467 375.766 103.389 374.735 103.294C373.704 103.198 373.275 104.134 373.783 105.372Z" fill="#DEDEDE"/>
    <path d="M367.803 104.864C368.295 106.101 369.548 107.18 370.579 107.275C371.626 107.37 372.055 106.434 371.547 105.197C371.039 103.96 369.802 102.881 368.771 102.802C367.756 102.691 367.312 103.627 367.803 104.864Z" fill="#DEDEDE"/>
    <path d="M361.823 104.356C362.299 105.594 363.552 106.672 364.583 106.752C365.63 106.831 366.074 105.895 365.582 104.658C365.09 103.421 363.853 102.342 362.822 102.263C361.791 102.183 361.331 103.119 361.823 104.356Z" fill="#DEDEDE"/>
    <path d="M355.827 103.88C356.303 105.118 357.524 106.196 358.571 106.276C359.618 106.355 360.078 105.419 359.602 104.182C359.126 102.945 357.889 101.882 356.858 101.787C355.811 101.707 355.351 102.643 355.827 103.88Z" fill="#DEDEDE"/>
    <path d="M349.799 103.405C350.259 104.642 351.481 105.705 352.528 105.8C353.574 105.879 354.05 104.943 353.574 103.706C353.099 102.469 351.877 101.406 350.846 101.327C349.815 101.232 349.339 102.167 349.799 103.405Z" fill="#DEDEDE"/>
    <path d="M343.788 102.944C344.232 104.182 345.453 105.244 346.5 105.324C347.547 105.403 348.023 104.467 347.579 103.23C347.119 101.993 345.913 100.93 344.866 100.851C343.819 100.771 343.328 101.707 343.788 102.944Z" fill="#DEDEDE"/>
    <path d="M337.745 102.5C338.173 103.738 339.378 104.8 340.441 104.88C341.488 104.959 341.98 104.023 341.536 102.786C341.091 101.549 339.886 100.486 338.839 100.407C337.808 100.327 337.316 101.263 337.745 102.5Z" fill="#DEDEDE"/>
    <path d="M331.701 102.072C332.129 103.31 333.319 104.372 334.366 104.436C335.413 104.515 335.92 103.563 335.492 102.326C335.064 101.089 333.858 100.026 332.827 99.9626C331.78 99.8992 331.273 100.835 331.701 102.072Z" fill="#DEDEDE"/>
    <path d="M325.642 101.66C326.054 102.897 327.244 103.96 328.291 104.024C329.353 104.103 329.861 103.151 329.448 101.914C329.036 100.677 327.846 99.6297 326.799 99.5504C325.753 99.487 325.229 100.423 325.642 101.66Z" fill="#DEDEDE"/>
    <path d="M319.582 101.263C319.978 102.501 321.152 103.548 322.215 103.627C323.278 103.69 323.801 102.754 323.389 101.517C322.976 100.28 321.802 99.2331 320.755 99.1696C319.709 99.0745 319.185 100.026 319.582 101.263Z" fill="#DEDEDE"/>
    <path d="M313.491 100.867C313.872 102.104 315.046 103.151 316.108 103.214C317.171 103.278 317.711 102.342 317.314 101.105C316.917 99.8674 315.76 98.8205 314.697 98.757C313.65 98.6936 313.111 99.6453 313.491 100.867Z" fill="#DEDEDE"/>
    <path d="M307.415 100.502C307.78 101.739 308.954 102.786 310.001 102.85C311.064 102.913 311.619 101.961 311.238 100.74C310.858 99.5028 309.7 98.4559 308.653 98.3924C307.59 98.329 307.035 99.2649 307.415 100.502Z" fill="#DEDEDE"/>
    <path d="M301.308 100.137C301.657 101.374 302.815 102.421 303.878 102.485C304.941 102.548 305.496 101.596 305.131 100.359C304.766 99.122 303.608 98.0751 302.561 98.0275C301.514 97.9641 300.959 98.9158 301.308 100.137Z" fill="#DEDEDE"/>
    <path d="M295.218 99.804C295.567 101.041 296.709 102.088 297.771 102.136C298.834 102.199 299.405 101.247 299.056 100.01C298.707 98.773 297.565 97.7419 296.502 97.6785C295.44 97.6309 294.869 98.5668 295.218 99.804Z" fill="#DEDEDE"/>
    <path d="M289.095 99.4707C289.428 100.692 290.554 101.739 291.617 101.802C292.68 101.866 293.266 100.898 292.933 99.6769C292.6 98.4397 291.458 97.4087 290.411 97.3452C289.349 97.2976 288.762 98.2493 289.095 99.4707Z" fill="#DEDEDE"/>
    <path d="M282.972 99.1694C283.289 100.391 284.415 101.438 285.478 101.501C286.541 101.549 287.144 100.597 286.81 99.3757C286.477 98.1384 285.367 97.1074 284.304 97.0598C283.257 96.9805 282.655 97.9322 282.972 99.1694Z" fill="#DEDEDE"/>
    <path d="M276.85 98.868C277.151 100.089 278.261 101.136 279.34 101.184C280.403 101.231 281.021 100.28 280.704 99.0425C280.387 97.8052 279.276 96.7742 278.214 96.7266C277.151 96.6949 276.548 97.6466 276.85 98.868Z" fill="#DEDEDE"/>
    <path d="M270.711 98.5827C270.997 99.8041 272.107 100.851 273.17 100.899C274.233 100.946 274.867 99.9944 274.566 98.7572C274.264 97.5358 273.17 96.4889 272.107 96.4413C271.044 96.4096 270.426 97.3613 270.711 98.5827Z" fill="#DEDEDE"/>
    <path d="M264.572 98.3288C264.841 99.5501 265.936 100.597 267.015 100.629C268.077 100.676 268.728 99.7088 268.426 98.4874C268.141 97.266 267.046 96.235 265.984 96.1874C264.921 96.1398 264.286 97.0915 264.572 98.3288Z" fill="#DEDEDE"/>
    <path d="M258.418 98.0751C258.688 99.2965 259.766 100.328 260.829 100.375C261.908 100.423 262.542 99.4551 262.272 98.2337C262.003 97.0123 260.908 95.9813 259.861 95.9337C258.799 95.8861 258.148 96.8379 258.418 98.0751Z" fill="#DEDEDE"/>
    <path d="M252.264 97.8374C252.518 99.0588 253.58 100.09 254.659 100.137C255.738 100.185 256.388 99.2174 256.134 97.9801C255.88 96.7588 254.802 95.7277 253.739 95.696C252.66 95.6484 252.01 96.616 252.264 97.8374Z" fill="#DEDEDE"/>
    <path d="M246.093 97.615C246.331 98.8364 247.394 99.8674 248.472 99.8992C249.551 99.9309 250.217 98.9792 249.979 97.7419C249.725 96.5206 248.663 95.4895 247.6 95.4578C246.521 95.4261 245.855 96.3937 246.093 97.615Z" fill="#DEDEDE"/>
    <path d="M239.923 97.409C240.145 98.6304 241.192 99.6614 242.27 99.6931C243.349 99.7248 244.031 98.7572 243.793 97.5359C243.555 96.3145 242.508 95.2835 241.446 95.2517C240.383 95.22 239.701 96.1876 239.923 97.409Z" fill="#DEDEDE"/>
    <path d="M233.753 97.2185C233.959 98.4399 235.006 99.4709 236.084 99.5026C237.163 99.5344 237.861 98.5668 237.639 97.3454C237.417 96.124 236.386 95.1088 235.307 95.0771C234.228 95.0295 233.531 95.9971 233.753 97.2185Z" fill="#DEDEDE"/>
    <path d="M227.566 97.044C227.756 98.2654 228.787 99.2806 229.866 99.3123C230.944 99.344 231.658 98.3764 231.452 97.1392C231.246 95.9178 230.215 94.9026 229.152 94.8709C228.073 94.8551 227.36 95.8226 227.566 97.044Z" fill="#DEDEDE"/>
    <path d="M221.38 96.8852C221.555 98.1066 222.586 99.1218 223.664 99.1535C224.743 99.1852 225.457 98.2018 225.267 96.9804C225.076 95.759 224.061 94.7438 222.982 94.7121C221.92 94.6962 221.19 95.6638 221.38 96.8852Z" fill="#DEDEDE"/>
    <path d="M215.178 96.7425C215.337 97.9639 216.352 98.979 217.43 99.0107C218.509 99.0425 219.239 98.059 219.064 96.8377C218.89 95.6163 217.874 94.6011 216.812 94.5852C215.749 94.5535 215.019 95.5211 215.178 96.7425Z" fill="#DEDEDE"/>
    <path d="M208.992 96.6157C209.151 97.8371 210.15 98.8522 211.229 98.8681C212.307 98.884 213.053 97.9164 212.894 96.695C212.735 95.4736 211.736 94.4584 210.657 94.4426C209.579 94.4267 208.833 95.3943 208.992 96.6157Z" fill="#DEDEDE"/>
    <path d="M202.79 96.5046C202.932 97.726 203.916 98.7412 204.995 98.7571C206.073 98.7729 206.835 97.7895 206.676 96.5681C206.533 95.3467 205.534 94.3315 204.471 94.3157C203.408 94.3157 202.647 95.2833 202.79 96.5046Z" fill="#DEDEDE"/>
    <path d="M196.588 96.4096C196.714 97.631 197.682 98.6462 198.777 98.662C199.855 98.6779 200.632 97.6944 200.49 96.4731C200.363 95.2517 199.379 94.2524 198.301 94.2365C197.222 94.2207 196.461 95.1882 196.588 96.4096Z" fill="#DEDEDE"/>
    <path d="M190.37 96.3302C190.481 97.5515 191.449 98.5508 192.527 98.5667C193.606 98.5826 194.383 97.5991 194.272 96.3777C194.145 95.1564 193.178 94.157 192.115 94.1412C191.036 94.1253 190.259 95.1088 190.37 96.3302Z" fill="#DEDEDE"/>
    <path d="M184.168 96.2668C184.263 97.4882 185.215 98.4875 186.309 98.5034C187.388 98.5192 188.181 97.5358 188.07 96.2986C187.959 95.0772 187.007 94.0779 185.929 94.062C184.866 94.062 184.073 95.0455 184.168 96.2668Z" fill="#DEDEDE"/>
    <path d="M177.949 96.2191C178.029 97.4404 178.98 98.4398 180.059 98.4556C181.138 98.4715 181.947 97.4722 181.851 96.2508C181.756 95.0294 180.82 94.0301 179.742 94.0301C178.663 94.0142 177.87 94.9977 177.949 96.2191Z" fill="#DEDEDE"/>
    <path d="M171.732 96.1876C171.795 97.409 172.731 98.4083 173.81 98.4083C174.889 98.4083 175.713 97.4249 175.634 96.2035C175.555 94.9821 174.619 93.9828 173.556 93.9828C172.478 93.9828 171.669 94.9662 171.732 96.1876Z" fill="#DEDEDE"/>
    <path d="M165.514 96.1716C165.561 97.393 166.481 98.3923 167.576 98.3923C168.654 98.3923 169.495 97.4089 169.416 96.1875C169.352 94.9661 168.432 93.9668 167.354 93.9668C166.291 93.9668 165.45 94.9502 165.514 96.1716Z" fill="#DEDEDE"/>
    <path d="M159.295 96.1716C159.327 97.393 160.247 98.3923 161.326 98.3923C162.404 98.3923 163.245 97.393 163.197 96.1716C163.15 94.9502 162.23 93.9668 161.167 93.9668C160.088 93.9668 159.248 94.9502 159.295 96.1716Z" fill="#DEDEDE"/>
    <path d="M153.062 96.1875C153.094 97.4089 153.982 98.4082 155.077 98.3923C156.155 98.3923 157.012 97.393 156.98 96.1716C156.948 94.9502 156.044 93.9668 154.966 93.9668C153.887 93.9668 153.046 94.9661 153.062 96.1875Z" fill="#DEDEDE"/>
    <path d="M146.844 96.2193C146.86 97.4407 147.748 98.4242 148.827 98.4242C149.905 98.4242 150.778 97.4249 150.746 96.2035C150.73 94.9821 149.842 93.9987 148.763 93.9987C147.701 93.9987 146.828 94.998 146.844 96.2193Z" fill="#DEDEDE"/>
    <path d="M140.611 96.267C140.611 97.4883 141.483 98.4718 142.562 98.4718C143.64 98.4559 144.529 97.4566 144.513 96.2352C144.513 95.0138 143.625 94.0304 142.562 94.0304C141.499 94.0463 140.627 95.0456 140.611 96.267Z" fill="#DEDEDE"/>
    <path d="M134.393 96.3301C134.377 97.5515 135.233 98.535 136.328 98.5191C137.407 98.5032 138.295 97.5039 138.311 96.2825C138.311 95.0612 137.454 94.0777 136.376 94.0936C135.297 94.1094 134.409 95.1088 134.393 96.3301Z" fill="#DEDEDE"/>
    <path d="M128.159 96.4096C128.127 97.631 128.984 98.6144 130.062 98.5986C131.141 98.5827 132.045 97.5834 132.061 96.362C132.077 95.1406 131.236 94.1572 130.158 94.173C129.095 94.173 128.191 95.1882 128.159 96.4096Z" fill="#DEDEDE"/>
    <path d="M121.941 96.505C121.893 97.7264 122.734 98.7099 123.828 98.694C124.907 98.6782 125.827 97.663 125.859 96.4416C125.891 95.2202 125.05 94.2368 123.971 94.2526C122.893 94.2685 121.988 95.2837 121.941 96.505Z" fill="#DEDEDE"/>
    <path d="M115.707 96.6157C115.644 97.8371 116.468 98.8205 117.563 98.8047C118.642 98.7888 119.562 97.7737 119.625 96.5523C119.673 95.3309 118.848 94.3474 117.769 94.3792C116.69 94.3792 115.77 95.3785 115.707 96.6157Z" fill="#DEDEDE"/>
    <path d="M370.911 98.3922C371.419 99.6294 372.672 100.692 373.687 100.787C374.718 100.883 375.13 99.9467 374.607 98.7253C374.083 97.4881 372.846 96.4253 371.831 96.3301C370.816 96.235 370.403 97.155 370.911 98.3922Z" fill="#DEDEDE"/>
    <path d="M364.995 97.885C365.487 99.1222 366.724 100.185 367.755 100.28C368.786 100.375 369.214 99.4395 368.707 98.2022C368.199 96.965 366.962 95.9022 365.947 95.8229C364.932 95.7278 364.503 96.6477 364.995 97.885Z" fill="#DEDEDE"/>
    <path d="M359.078 97.3931C359.57 98.6304 360.791 99.6931 361.822 99.7724C362.854 99.8517 363.298 98.9317 362.79 97.6945C362.298 96.4573 361.061 95.3945 360.046 95.3152C359.031 95.2359 358.602 96.1559 359.078 97.3931Z" fill="#DEDEDE"/>
    <path d="M353.146 96.9012C353.622 98.1226 354.844 99.2012 355.875 99.2805C356.906 99.3598 357.35 98.4398 356.874 97.2026C356.398 95.9812 355.177 94.9185 354.146 94.8233C353.115 94.744 352.671 95.6798 353.146 96.9012Z" fill="#DEDEDE"/>
    <path d="M347.198 96.441C347.658 97.6624 348.864 98.7251 349.911 98.8044C350.942 98.8837 351.402 97.9479 350.942 96.7265C350.466 95.5051 349.26 94.4424 348.229 94.363C347.198 94.2837 346.738 95.2037 347.198 96.441Z" fill="#DEDEDE"/>
    <path d="M341.249 95.9813C341.694 97.2027 342.899 98.2654 343.946 98.3448C344.977 98.4241 345.453 97.4882 344.993 96.2668C344.533 95.0455 343.327 93.9827 342.296 93.9034C341.265 93.8241 340.805 94.7599 341.249 95.9813Z" fill="#DEDEDE"/>
    <path d="M335.285 95.5375C335.714 96.7589 336.919 97.8217 337.95 97.901C338.997 97.9803 339.473 97.0444 339.029 95.8231C338.585 94.6017 337.395 93.5389 336.364 93.4755C335.333 93.3962 334.841 94.3162 335.285 95.5375Z" fill="#DEDEDE"/>
    <path d="M329.305 95.1244C329.733 96.3458 330.907 97.4086 331.954 97.472C333.001 97.5513 333.493 96.6155 333.064 95.3782C332.636 94.1568 331.447 93.0941 330.416 93.0306C329.384 92.9672 328.877 93.903 329.305 95.1244Z" fill="#DEDEDE"/>
    <path d="M323.326 94.7121C323.739 95.9335 324.912 96.9962 325.959 97.0597C327.006 97.1231 327.514 96.1872 327.085 94.9659C326.673 93.7445 325.483 92.6976 324.452 92.6183C323.421 92.5548 322.914 93.4907 323.326 94.7121Z" fill="#DEDEDE"/>
    <path d="M317.33 94.3157C317.727 95.537 318.901 96.5839 319.948 96.6632C320.995 96.7267 321.502 95.7908 321.106 94.5694C320.693 93.3481 319.535 92.3012 318.488 92.2377C317.441 92.1584 316.934 93.0943 317.33 94.3157Z" fill="#DEDEDE"/>
    <path d="M311.318 93.9351C311.699 95.1565 312.857 96.2034 313.904 96.2668C314.95 96.3303 315.474 95.3944 315.077 94.173C314.681 92.9516 313.523 91.9047 312.492 91.8413C311.461 91.7779 310.937 92.7137 311.318 93.9351Z" fill="#DEDEDE"/>
    <path d="M305.305 93.5699C305.67 94.7912 306.828 95.8381 307.875 95.9016C308.922 95.965 309.461 95.0292 309.081 93.8078C308.7 92.5864 307.542 91.5395 306.511 91.4761C305.48 91.4126 304.941 92.3485 305.305 93.5699Z" fill="#DEDEDE"/>
    <path d="M299.295 93.2053C299.66 94.4266 300.802 95.4735 301.849 95.5211C302.895 95.5846 303.451 94.6328 303.086 93.4115C302.721 92.1901 301.579 91.1591 300.532 91.0956C299.485 91.048 298.93 91.9839 299.295 93.2053Z" fill="#DEDEDE"/>
    <path d="M293.251 92.8721C293.6 94.0935 294.726 95.1245 295.773 95.188C296.82 95.2514 297.391 94.2997 297.026 93.0783C296.677 91.857 295.535 90.8259 294.504 90.7625C293.473 90.7149 292.918 91.6508 293.251 92.8721Z" fill="#DEDEDE"/>
    <path d="M287.224 92.555C287.557 93.7763 288.683 94.8074 289.73 94.8708C290.777 94.9184 291.364 93.9826 291.015 92.7612C290.666 91.5398 289.556 90.5088 288.509 90.4612C287.462 90.3977 286.891 91.3336 287.224 92.555Z" fill="#DEDEDE"/>
    <path d="M281.18 92.2377C281.497 93.4591 282.607 94.4901 283.67 94.5377C284.717 94.5853 285.32 93.6494 284.987 92.428C284.654 91.2067 283.543 90.1756 282.496 90.1281C281.449 90.0805 280.863 91.0322 281.18 92.2377Z" fill="#DEDEDE"/>
    <path d="M275.121 91.9522C275.423 93.1736 276.533 94.2046 277.58 94.2522C278.643 94.2998 279.229 93.3481 278.912 92.1267C278.595 90.9053 277.5 89.8743 276.454 89.8267C275.423 89.795 274.82 90.7309 275.121 91.9522Z" fill="#DEDEDE"/>
    <path d="M269.062 91.6668C269.348 92.8882 270.442 93.9192 271.505 93.9668C272.568 94.0144 273.17 93.0627 272.869 91.8413C272.568 90.6199 271.473 89.6047 270.426 89.5571C269.379 89.5095 268.776 90.4613 269.062 91.6668Z" fill="#DEDEDE"/>
    <path d="M262.986 91.4129C263.272 92.6342 264.351 93.6494 265.397 93.697C266.46 93.7446 267.079 92.7929 266.793 91.5715C266.508 90.3501 265.429 89.3349 264.382 89.2874C263.335 89.2398 262.717 90.1915 262.986 91.4129Z" fill="#DEDEDE"/>
    <path d="M256.911 91.159C257.181 92.3804 258.244 93.3956 259.307 93.4432C260.369 93.4907 261.004 92.539 260.734 91.3176C260.464 90.0963 259.386 89.0811 258.339 89.0335C257.292 89.0018 256.658 89.9376 256.911 91.159Z" fill="#DEDEDE"/>
    <path d="M250.836 90.937C251.089 92.1584 252.152 93.1735 253.215 93.2211C254.278 93.2687 254.928 92.3011 254.658 91.0956C254.405 89.8742 253.342 88.8591 252.279 88.8273C251.232 88.7639 250.582 89.7156 250.836 90.937Z" fill="#DEDEDE"/>
    <path d="M244.745 90.7147C244.982 91.936 246.029 92.9512 247.092 92.9829C248.155 93.0147 248.805 92.0629 248.567 90.8416C248.313 89.6202 247.267 88.605 246.22 88.5733C245.173 88.5416 244.507 89.4933 244.745 90.7147Z" fill="#DEDEDE"/>
    <path d="M238.653 90.5088C238.875 91.7302 239.922 92.7454 240.985 92.7771C242.048 92.8088 242.714 91.8571 242.492 90.6357C242.254 89.4144 241.223 88.4151 240.16 88.3675C239.113 88.3357 238.431 89.2875 238.653 90.5088Z" fill="#DEDEDE"/>
    <path d="M232.563 90.3184C232.769 91.5397 233.8 92.5549 234.863 92.5866C235.926 92.6184 236.608 91.6508 236.386 90.4453C236.164 89.2239 235.133 88.2246 234.086 88.1928C233.039 88.1453 232.357 89.1129 232.563 90.3184Z" fill="#DEDEDE"/>
    <path d="M226.456 90.1441C226.646 91.3655 227.677 92.3648 228.74 92.3965C229.803 92.4282 230.5 91.4606 230.294 90.2551C230.088 89.0337 229.073 88.0344 228.01 88.0027C226.963 87.971 226.265 88.9386 226.456 90.1441Z" fill="#DEDEDE"/>
    <path d="M220.349 90.0013C220.524 91.2227 221.539 92.222 222.602 92.2537C223.665 92.2855 224.378 91.3179 224.188 90.0965C223.998 88.8751 222.983 87.8758 221.936 87.8441C220.873 87.8282 220.175 88.7799 220.349 90.0013Z" fill="#DEDEDE"/>
    <path d="M214.241 89.8586C214.416 91.08 215.415 92.0793 216.478 92.0952C217.54 92.1269 218.27 91.1593 218.08 89.9379C217.905 88.7165 216.906 87.7172 215.843 87.7014C214.796 87.6855 214.067 88.6372 214.241 89.8586Z" fill="#DEDEDE"/>
    <path d="M208.119 89.7314C208.278 90.9369 209.261 91.9521 210.324 91.968C211.387 91.9838 212.117 91.0162 211.958 89.8107C211.799 88.5893 210.8 87.6059 209.753 87.5742C208.706 87.5583 207.961 88.51 208.119 89.7314Z" fill="#DEDEDE"/>
    <path d="M201.996 89.6206C202.139 90.8261 203.106 91.8413 204.185 91.8571C205.248 91.873 205.993 90.9054 205.851 89.684C205.708 88.4626 204.724 87.4792 203.662 87.4633C202.599 87.4475 201.853 88.3992 201.996 89.6206Z" fill="#DEDEDE"/>
    <path d="M195.874 89.5253C196.001 90.7467 196.968 91.7301 198.031 91.746C199.094 91.7618 199.855 90.7942 199.712 89.5729C199.585 88.3515 198.618 87.368 197.555 87.3522C196.508 87.3522 195.747 88.3198 195.874 89.5253Z" fill="#DEDEDE"/>
    <path d="M189.751 89.4459C189.863 90.6673 190.814 91.6507 191.893 91.6666C192.956 91.6824 193.733 90.699 193.606 89.4935C193.479 88.2879 192.527 87.2886 191.465 87.2728C190.402 87.2569 189.64 88.2404 189.751 89.4459Z" fill="#DEDEDE"/>
    <path d="M183.612 89.3826C183.708 90.5881 184.659 91.5874 185.722 91.6033C186.785 91.6192 187.578 90.6357 187.467 89.4302C187.356 88.2247 186.42 87.2254 185.357 87.2254C184.294 87.1936 183.517 88.1771 183.612 89.3826Z" fill="#DEDEDE"/>
    <path d="M177.474 89.3351C177.553 90.5406 178.489 91.5399 179.552 91.5399C180.614 91.5399 181.408 90.5723 181.328 89.3509C181.233 88.1454 180.297 87.1461 179.25 87.1461C178.188 87.1461 177.394 88.1296 177.474 89.3351Z" fill="#DEDEDE"/>
    <path d="M171.351 89.3032C171.414 90.5087 172.334 91.5081 173.413 91.5081C174.475 91.5081 175.284 90.5246 175.205 89.3191C175.126 88.1136 174.206 87.1301 173.143 87.1143C172.08 87.1143 171.271 88.0977 171.351 89.3032Z" fill="#DEDEDE"/>
    <path d="M165.212 89.2876C165.26 90.4931 166.18 91.4924 167.243 91.4924C168.306 91.4924 169.13 90.5089 169.067 89.3034C169.003 88.0979 168.099 87.1144 167.037 87.1144C165.974 87.0986 165.149 88.082 165.212 89.2876Z" fill="#DEDEDE"/>
    <path d="M159.058 89.2875C159.09 90.493 159.994 91.4923 161.073 91.4923C162.135 91.4923 162.976 90.5089 162.928 89.2875C162.881 88.082 161.977 87.0985 160.914 87.0985C159.851 87.0985 159.026 88.082 159.058 89.2875Z" fill="#DEDEDE"/>
    <path d="M152.92 89.3033C152.951 90.5089 153.84 91.4923 154.902 91.4923C155.981 91.4923 156.806 90.5089 156.774 89.2875C156.742 88.082 155.854 87.0985 154.791 87.0985C153.745 87.1144 152.904 88.0978 152.92 89.3033Z" fill="#DEDEDE"/>
    <path d="M146.78 89.3351C146.796 90.5406 147.669 91.524 148.732 91.524C149.81 91.524 150.651 90.5247 150.635 89.3192C150.619 88.1137 149.731 87.1302 148.684 87.1302C147.621 87.1302 146.765 88.1137 146.78 89.3351Z" fill="#DEDEDE"/>
    <path d="M140.626 89.3824C140.626 90.5879 141.483 91.5714 142.561 91.5714C143.64 91.5555 144.496 90.572 144.48 89.3507C144.48 88.1451 143.608 87.1617 142.545 87.1776C141.498 87.1776 140.642 88.161 140.626 89.3824Z" fill="#DEDEDE"/>
    <path d="M134.487 89.4461C134.471 90.6516 135.328 91.6351 136.391 91.6192C137.469 91.6033 138.342 90.6199 138.342 89.3985C138.342 88.193 137.501 87.2095 136.438 87.2254C135.375 87.2413 134.503 88.2247 134.487 89.4461Z" fill="#DEDEDE"/>
    <path d="M128.349 89.5251C128.317 90.7306 129.158 91.714 130.237 91.6982C131.315 91.6823 132.188 90.683 132.219 89.4775C132.235 88.272 131.395 87.2885 130.332 87.3044C129.269 87.3203 128.381 88.3037 128.349 89.5251Z" fill="#DEDEDE"/>
    <path d="M122.194 89.6048C122.147 90.8103 122.972 91.7938 124.05 91.7779C125.129 91.762 126.017 90.7627 126.049 89.5572C126.081 88.3517 125.256 87.3841 124.193 87.4C123.146 87.4 122.242 88.3993 122.194 89.6048Z" fill="#DEDEDE"/>
    <path d="M116.056 89.7157C115.992 90.9213 116.817 91.9047 117.88 91.873C118.958 91.8571 119.863 90.8578 119.91 89.6364C119.958 88.4309 119.149 87.4633 118.086 87.4792C117.023 87.5109 116.119 88.5102 116.056 89.7157Z" fill="#DEDEDE"/>
    <path d="M109.902 89.8425C109.823 91.048 110.632 92.0314 111.71 91.9997C112.789 91.9838 113.709 90.9687 113.772 89.7631C113.836 88.5576 113.027 87.59 111.964 87.6059C110.901 87.6376 109.981 88.6369 109.902 89.8425Z" fill="#DEDEDE"/>
    <path d="M373.863 91.9678C374.386 93.1892 375.623 94.2519 376.638 94.3471C377.654 94.4423 378.05 93.5223 377.511 92.3009C376.987 91.0795 375.734 90.0168 374.735 89.9216C373.736 89.8423 373.339 90.7464 373.863 91.9678Z" fill="#DEDEDE"/>
    <path d="M368.024 91.4604C368.532 92.6818 369.769 93.7446 370.784 93.8398C371.799 93.9349 372.196 93.0149 371.688 91.7935C371.165 90.5722 369.928 89.5094 368.928 89.4142C367.929 89.3191 367.517 90.2391 368.024 91.4604Z" fill="#DEDEDE"/>
    <path d="M362.187 90.9528C362.679 92.1742 363.916 93.237 364.932 93.3163C365.947 93.3956 366.359 92.4756 365.852 91.2542C365.344 90.0328 364.123 88.9701 363.107 88.8908C362.108 88.8114 361.696 89.7314 362.187 90.9528Z" fill="#DEDEDE"/>
    <path d="M356.335 90.4616C356.827 91.683 358.048 92.7457 359.063 92.825C360.078 92.9044 360.507 91.9844 360.015 90.763C359.523 89.5416 358.302 88.4947 357.287 88.3995C356.271 88.3202 355.843 89.2402 356.335 90.4616Z" fill="#DEDEDE"/>
    <path d="M350.466 89.9852C350.942 91.2066 352.147 92.2694 353.178 92.3487C354.194 92.428 354.638 91.508 354.162 90.2866C353.686 89.0652 352.465 88.0183 351.449 87.939C350.434 87.8438 349.99 88.7638 350.466 89.9852Z" fill="#DEDEDE"/>
    <path d="M344.596 89.5255C345.056 90.7468 346.262 91.7937 347.293 91.873C348.308 91.9523 348.768 91.0324 348.292 89.811C347.816 88.5896 346.611 87.5427 345.611 87.4634C344.58 87.3841 344.136 88.3041 344.596 89.5255Z" fill="#DEDEDE"/>
    <path d="M338.711 89.0814C339.155 90.3027 340.361 91.3496 341.376 91.4289C342.407 91.5083 342.867 90.5724 342.407 89.3669C341.947 88.1455 340.757 87.0986 339.742 87.0193C338.727 86.94 338.267 87.86 338.711 89.0814Z" fill="#DEDEDE"/>
    <path d="M332.811 88.6371C333.239 89.8585 334.429 90.9054 335.46 90.9847C336.491 91.064 336.951 90.1281 336.507 88.9226C336.063 87.7012 334.873 86.6543 333.858 86.5909C332.859 86.4957 332.383 87.4316 332.811 88.6371Z" fill="#DEDEDE"/>
    <path d="M326.91 88.2246C327.339 89.446 328.512 90.4929 329.543 90.5563C330.574 90.6356 331.05 89.6998 330.622 88.4784C330.194 87.257 329.004 86.226 327.989 86.1466C326.974 86.0832 326.482 87.0032 326.91 88.2246Z" fill="#DEDEDE"/>
    <path d="M320.994 87.8121C321.406 89.0335 322.58 90.0645 323.611 90.1439C324.642 90.2073 325.134 89.2873 324.721 88.0659C324.293 86.8445 323.135 85.8135 322.12 85.7501C321.089 85.6707 320.581 86.6066 320.994 87.8121Z" fill="#DEDEDE"/>
    <path d="M315.077 87.4314C315.474 88.6369 316.632 89.6838 317.663 89.7472C318.694 89.8107 319.201 88.8907 318.789 87.6693C318.377 86.4479 317.219 85.4169 316.204 85.3534C315.188 85.29 314.681 86.21 315.077 87.4314Z" fill="#DEDEDE"/>
    <path d="M309.145 87.0506C309.525 88.2561 310.683 89.303 311.714 89.3665C312.745 89.4299 313.269 88.4941 312.872 87.2886C312.476 86.083 311.334 85.0361 310.303 84.9727C309.272 84.9092 308.764 85.8292 309.145 87.0506Z" fill="#DEDEDE"/>
    <path d="M303.212 86.6859C303.576 87.8914 304.719 88.9383 305.765 89.0018C306.796 89.0652 307.336 88.1294 306.955 86.9238C306.574 85.7183 305.432 84.6873 304.401 84.6238C303.37 84.5445 302.831 85.4804 303.212 86.6859Z" fill="#DEDEDE"/>
    <path d="M297.264 86.3372C297.629 87.5427 298.755 88.5738 299.786 88.6372C300.817 88.7007 301.356 87.7648 300.992 86.5434C300.627 85.3379 299.485 84.3069 298.469 84.2434C297.438 84.1959 296.899 85.1317 297.264 86.3372Z" fill="#DEDEDE"/>
    <path d="M291.3 86.0042C291.649 87.2097 292.759 88.2408 293.806 88.3042C294.837 88.3677 295.392 87.4318 295.043 86.2104C294.694 85.0049 293.568 83.9739 292.537 83.9104C291.506 83.8628 290.951 84.7987 291.3 86.0042Z" fill="#DEDEDE"/>
    <path d="M285.335 85.6867C285.668 86.8922 286.779 87.9233 287.826 87.9709C288.873 88.0184 289.428 87.0826 289.095 85.8771C288.746 84.6715 287.635 83.6405 286.604 83.5929C285.573 83.5453 285.002 84.4812 285.335 85.6867Z" fill="#DEDEDE"/>
    <path d="M279.372 85.3853C279.689 86.5908 280.8 87.6219 281.831 87.6695C282.878 87.717 283.449 86.7812 283.116 85.5757C282.783 84.3701 281.688 83.3391 280.657 83.2915C279.626 83.2439 279.055 84.1798 279.372 85.3853Z" fill="#DEDEDE"/>
    <path d="M273.392 85.1C273.693 86.3055 274.788 87.3365 275.835 87.3841C276.882 87.4317 277.469 86.4958 277.151 85.2903C276.834 84.0848 275.74 83.0696 274.709 83.022C273.677 82.9427 273.091 83.8786 273.392 85.1Z" fill="#DEDEDE"/>
    <path d="M267.411 84.8142C267.697 86.0197 268.791 87.0349 269.822 87.0825C270.869 87.13 271.472 86.1942 271.17 84.9728C270.869 83.7673 269.79 82.7521 268.759 82.7045C267.712 82.6728 267.11 83.6087 267.411 84.8142Z" fill="#DEDEDE"/>
    <path d="M261.416 84.5606C261.701 85.7661 262.764 86.7813 263.811 86.8289C264.858 86.8765 265.461 85.9248 265.175 84.7193C264.89 83.5137 263.811 82.4986 262.78 82.451C261.749 82.4193 261.146 83.3551 261.416 84.5606Z" fill="#DEDEDE"/>
    <path d="M255.42 84.3068C255.69 85.5124 256.752 86.5275 257.799 86.5751C258.846 86.6227 259.465 85.671 259.195 84.4655C258.925 83.2599 257.863 82.2448 256.832 82.213C255.785 82.1655 255.15 83.1013 255.42 84.3068Z" fill="#DEDEDE"/>
    <path d="M249.424 84.0847C249.678 85.2902 250.725 86.3054 251.772 86.3371C252.819 86.3847 253.453 85.433 253.199 84.2274C252.945 83.0219 251.883 82.0226 250.852 81.975C249.805 81.9433 249.17 82.8792 249.424 84.0847Z" fill="#DEDEDE"/>
    <path d="M243.412 83.8625C243.65 85.0681 244.697 86.0832 245.744 86.1149C246.791 86.1467 247.441 85.1949 247.188 83.9894C246.934 82.7839 245.903 81.7846 244.856 81.737C243.825 81.7212 243.174 82.657 243.412 83.8625Z" fill="#DEDEDE"/>
    <path d="M237.4 83.6726C237.622 84.8782 238.653 85.8775 239.7 85.9251C240.747 85.9568 241.413 85.0051 241.175 83.7995C240.937 82.594 239.906 81.5947 238.875 81.563C237.828 81.5154 237.178 82.4671 237.4 83.6726Z" fill="#DEDEDE"/>
    <path d="M231.373 83.4818C231.58 84.6873 232.595 85.6866 233.657 85.7183C234.704 85.7501 235.386 84.7983 235.164 83.5928C234.942 82.3873 233.927 81.388 232.88 81.3563C231.833 81.3404 231.167 82.2763 231.373 83.4818Z" fill="#DEDEDE"/>
    <path d="M225.346 83.3233C225.537 84.5288 226.552 85.5281 227.599 85.5599C228.646 85.5916 229.328 84.6399 229.121 83.4343C228.915 82.2288 227.9 81.2295 226.869 81.1978C225.838 81.1661 225.156 82.1178 225.346 83.3233Z" fill="#DEDEDE"/>
    <path d="M219.318 83.1649C219.492 84.3704 220.492 85.3697 221.554 85.3856C222.601 85.4173 223.299 84.4497 223.109 83.2442C222.918 82.0387 221.919 81.0394 220.872 81.0235C219.841 81.0076 219.143 81.9594 219.318 83.1649Z" fill="#DEDEDE"/>
    <path d="M213.29 83.0218C213.449 84.2273 214.448 85.2267 215.495 85.2425C216.542 85.2584 217.256 84.3067 217.081 83.1011C216.907 81.8956 215.924 80.9122 214.877 80.8804C213.83 80.8646 213.116 81.8163 213.29 83.0218Z" fill="#DEDEDE"/>
    <path d="M207.247 82.895C207.406 84.1005 208.373 85.0839 209.436 85.1157C210.483 85.1315 211.213 84.1798 211.038 82.9743C210.88 81.7687 209.896 80.7853 208.849 80.7694C207.818 80.7377 207.104 81.7053 207.247 82.895Z" fill="#DEDEDE"/>
    <path d="M201.219 82.7996C201.362 84.0051 202.33 84.9886 203.377 85.0044C204.424 85.0203 205.169 84.0527 205.011 82.8631C204.868 81.6575 203.9 80.6741 202.853 80.6582C201.806 80.6265 201.077 81.5941 201.219 82.7996Z" fill="#DEDEDE"/>
    <path d="M195.159 82.7046C195.286 83.9101 196.238 84.8935 197.301 84.9094C198.348 84.9252 199.093 83.9577 198.966 82.7521C198.824 81.5466 197.872 80.5632 196.841 80.5473C195.794 80.5314 195.048 81.499 195.159 82.7046Z" fill="#DEDEDE"/>
    <path d="M189.117 82.6254C189.228 83.8309 190.164 84.8144 191.226 84.8303C192.273 84.8461 193.035 83.8785 192.924 82.673C192.797 81.4675 191.861 80.484 190.814 80.484C189.767 80.4523 189.006 81.4199 189.117 82.6254Z" fill="#DEDEDE"/>
    <path d="M183.073 82.5619C183.169 83.7674 184.104 84.7508 185.151 84.7508C186.198 84.7667 186.976 83.7991 186.864 82.5936C186.753 81.3881 185.818 80.4205 184.787 80.4046C183.74 80.3887 182.978 81.3563 183.073 82.5619Z" fill="#DEDEDE"/>
    <path d="M177.013 82.5146C177.093 83.7201 178.013 84.7036 179.075 84.7036C180.138 84.7036 180.915 83.736 180.82 82.5463C180.725 81.3408 179.805 80.3732 178.758 80.3574C177.711 80.3415 176.934 81.3091 177.013 82.5146Z" fill="#DEDEDE"/>
    <path d="M170.955 82.4826C171.018 83.6881 171.938 84.6715 172.985 84.6715C174.048 84.6715 174.841 83.7039 174.762 82.4984C174.682 81.2929 173.778 80.3253 172.731 80.3094C171.684 80.3094 170.891 81.277 170.955 82.4826Z" fill="#DEDEDE"/>
    <path d="M164.895 82.4669C164.943 83.6724 165.847 84.64 166.91 84.6559C167.972 84.6559 168.765 83.6883 168.702 82.4827C168.639 81.2772 167.734 80.3096 166.687 80.3096C165.656 80.2938 164.847 81.2614 164.895 82.4669Z" fill="#DEDEDE"/>
    <path d="M158.836 82.4667C158.868 83.6722 159.756 84.6398 160.819 84.6398C161.881 84.6398 162.69 83.6563 162.643 82.4667C162.595 81.2612 161.707 80.2936 160.66 80.2936C159.613 80.2936 158.804 81.2612 158.836 82.4667Z" fill="#DEDEDE"/>
    <path d="M152.777 82.4825C152.809 83.6881 153.681 84.6556 154.728 84.6556C155.791 84.6556 156.616 83.6722 156.584 82.4667C156.552 81.2612 155.68 80.2936 154.633 80.2936C153.586 80.3094 152.761 81.277 152.777 82.4825Z" fill="#DEDEDE"/>
    <path d="M146.718 82.5144C146.733 83.7199 147.59 84.6875 148.653 84.6875C149.715 84.6875 150.556 83.704 150.524 82.4985C150.509 81.293 149.636 80.3254 148.589 80.3413C147.542 80.3413 146.702 81.3088 146.718 82.5144Z" fill="#DEDEDE"/>
    <path d="M140.658 82.562C140.658 83.7675 141.514 84.7351 142.561 84.7193C143.624 84.7034 144.465 83.7199 144.465 82.5303C144.465 81.3248 143.608 80.373 142.561 80.373C141.514 80.373 140.658 81.3565 140.658 82.562Z" fill="#DEDEDE"/>
    <path d="M134.583 82.6254C134.567 83.8309 135.408 84.7985 136.47 84.7826C137.533 84.7667 138.39 83.7833 138.406 82.5778C138.406 81.3723 137.565 80.4205 136.518 80.4205C135.471 80.4364 134.614 81.4198 134.583 82.6254Z" fill="#DEDEDE"/>
    <path d="M128.523 82.6888C128.492 83.8943 129.316 84.8619 130.379 84.846C131.442 84.8302 132.314 83.8467 132.33 82.6412C132.346 81.4357 131.521 80.484 130.474 80.4998C129.428 80.5157 128.555 81.4991 128.523 82.6888Z" fill="#DEDEDE"/>
    <path d="M110.33 83.0217C110.25 84.2272 111.044 85.1789 112.106 85.163C113.169 85.1472 114.073 84.1479 114.137 82.9424C114.2 81.7368 113.407 80.7851 112.36 80.801C111.313 80.8327 110.409 81.8161 110.33 83.0217Z" fill="#DEDEDE"/>
    <path d="M370.912 85.0841C371.435 86.3055 372.673 87.3524 373.672 87.4476C374.671 87.5427 375.052 86.6227 374.529 85.4172C374.005 84.1958 372.768 83.1489 371.769 83.0538C370.769 82.9586 370.389 83.8786 370.912 85.0841Z" fill="#DEDEDE"/>
    <path d="M365.138 84.5764C365.646 85.7819 366.883 86.8447 367.882 86.9398C368.882 87.035 369.278 86.115 368.755 84.8936C368.231 83.6881 367.01 82.6254 366.011 82.5461C365.027 82.4509 364.631 83.355 365.138 84.5764Z" fill="#DEDEDE"/>
    <path d="M359.364 84.0846C359.855 85.2901 361.077 86.3529 362.092 86.4322C363.091 86.5115 363.504 85.6074 362.996 84.386C362.488 83.1805 361.267 82.1177 360.268 82.0384C359.284 81.9591 358.872 82.8632 359.364 84.0846Z" fill="#DEDEDE"/>
    <path d="M353.591 83.593C354.083 84.7985 355.288 85.8612 356.303 85.9405C357.318 86.0199 357.731 85.1157 357.239 83.8943C356.747 82.6888 355.526 81.6419 354.527 81.5468C353.512 81.4674 353.099 82.3874 353.591 83.593Z" fill="#DEDEDE"/>
    <path d="M347.785 83.117C348.261 84.3225 349.466 85.3694 350.465 85.4487C351.481 85.528 351.909 84.608 351.417 83.4025C350.941 82.197 349.736 81.1501 348.736 81.0708C347.737 81.0073 347.309 81.9115 347.785 83.117Z" fill="#DEDEDE"/>
    <path d="M341.979 82.6728C342.439 83.8783 343.629 84.9252 344.644 85.0045C345.659 85.0838 346.087 84.1638 345.627 82.9583C345.152 81.7528 343.962 80.7059 342.963 80.6266C341.963 80.5473 341.519 81.4673 341.979 82.6728Z" fill="#DEDEDE"/>
    <path d="M336.173 82.2289C336.617 83.4344 337.807 84.4813 338.822 84.5606C339.837 84.6399 340.281 83.7199 339.821 82.5144C339.361 81.3089 338.172 80.262 337.172 80.1985C336.173 80.1034 335.713 81.0234 336.173 82.2289Z" fill="#DEDEDE"/>
    <path d="M330.336 81.8004C330.765 83.0059 331.954 84.0528 332.97 84.1162C333.985 84.1955 334.445 83.2755 334.001 82.07C333.556 80.8645 332.383 79.8335 331.367 79.7542C330.368 79.6748 329.908 80.5948 330.336 81.8004Z" fill="#DEDEDE"/>
    <path d="M324.516 81.3882C324.944 82.5937 326.102 83.6247 327.117 83.704C328.132 83.7833 328.608 82.8475 328.18 81.642C327.752 80.4364 326.578 79.4054 325.579 79.3261C324.563 79.2626 324.088 80.1826 324.516 81.3882Z" fill="#DEDEDE"/>
    <path d="M318.677 80.9756C319.09 82.1811 320.247 83.2121 321.263 83.2756C322.278 83.339 322.77 82.419 322.341 81.2135C321.913 80.008 320.755 78.977 319.756 78.9135C318.741 78.8659 318.265 79.7859 318.677 80.9756Z" fill="#DEDEDE"/>
    <path d="M312.825 80.5951C313.221 81.8006 314.379 82.8316 315.394 82.8951C316.41 82.9585 316.917 82.0385 316.505 80.833C316.092 79.6275 314.95 78.5964 313.935 78.533C312.92 78.4696 312.428 79.3895 312.825 80.5951Z" fill="#DEDEDE"/>
    <path d="M306.955 80.2302C307.336 81.4357 308.478 82.4667 309.509 82.5302C310.524 82.5936 311.032 81.6736 310.635 80.4681C310.239 79.2626 309.097 78.2474 308.081 78.184C307.082 78.1047 306.575 79.0247 306.955 80.2302Z" fill="#DEDEDE"/>
    <path d="M301.103 79.8656C301.468 81.0711 302.61 82.0863 303.625 82.1497C304.64 82.2132 305.164 81.2932 304.783 80.0876C304.402 78.8821 303.276 77.867 302.261 77.8035C301.246 77.7401 300.722 78.6601 301.103 79.8656Z" fill="#DEDEDE"/>
    <path d="M295.218 79.5165C295.583 80.722 296.693 81.7372 297.724 81.8006C298.755 81.8641 299.278 80.9282 298.914 79.7385C298.549 78.533 297.423 77.5179 296.407 77.4544C295.392 77.4068 294.869 78.3268 295.218 79.5165Z" fill="#DEDEDE"/>
    <path d="M289.349 79.1991C289.698 80.4046 290.809 81.4198 291.824 81.4832C292.855 81.5467 293.394 80.6108 293.029 79.4053C292.68 78.1998 291.57 77.1846 290.555 77.137C289.54 77.0736 289 77.9936 289.349 79.1991Z" fill="#DEDEDE"/>
    <path d="M283.464 78.8818C283.797 80.0873 284.892 81.1025 285.923 81.1501C286.954 81.1977 287.509 80.2777 287.16 79.0722C286.811 77.8666 285.717 76.8673 284.702 76.8039C283.686 76.7563 283.131 77.6763 283.464 78.8818Z" fill="#DEDEDE"/>
    <path d="M277.563 78.5806C277.881 79.7703 278.975 80.7854 280.006 80.8489C281.037 80.8965 281.592 79.9765 281.275 78.771C280.942 77.5654 279.863 76.5661 278.832 76.5185C277.817 76.4551 277.246 77.391 277.563 78.5806Z" fill="#DEDEDE"/>
    <path d="M271.663 78.2949C271.965 79.4845 273.043 80.4997 274.074 80.5473C275.106 80.5949 275.677 79.659 275.359 78.4694C275.042 77.2797 273.963 76.2645 272.948 76.2169C271.933 76.1694 271.362 77.1052 271.663 78.2949Z" fill="#DEDEDE"/>
    <path d="M265.763 78.0255C266.048 79.2151 267.127 80.2303 268.158 80.2779C269.189 80.3255 269.776 79.3896 269.474 78.2C269.173 77.0103 268.094 75.9951 267.079 75.9475C266.048 75.9 265.461 76.8358 265.763 78.0255Z" fill="#DEDEDE"/>
    <path d="M259.845 77.7714C260.131 78.9611 261.178 79.9763 262.209 80.0238C263.24 80.0714 263.843 79.1356 263.557 77.9301C263.271 76.7404 262.209 75.7252 261.194 75.6935C260.163 75.6459 259.56 76.5818 259.845 77.7714Z" fill="#DEDEDE"/>
    <path d="M253.929 77.5334C254.199 78.7231 255.245 79.7383 256.277 79.77C257.308 79.8176 257.926 78.8817 257.641 77.6762C257.371 76.4865 256.308 75.4872 255.293 75.4396C254.278 75.4079 253.659 76.3279 253.929 77.5334Z" fill="#DEDEDE"/>
    <path d="M247.996 77.2955C248.25 78.4852 249.281 79.4845 250.328 79.5321C251.359 79.5638 251.994 78.6279 251.724 77.4383C251.47 76.2486 250.423 75.2493 249.392 75.2017C248.377 75.17 247.743 76.1058 247.996 77.2955Z" fill="#DEDEDE"/>
    <path d="M242.065 77.0894C242.303 78.2791 243.334 79.2784 244.365 79.326C245.396 79.3577 246.031 78.4218 245.793 77.2322C245.539 76.0425 244.508 75.0432 243.493 75.0115C242.462 74.9639 241.827 75.8998 242.065 77.0894Z" fill="#DEDEDE"/>
    <path d="M236.132 76.8993C236.354 78.0889 237.37 79.0882 238.416 79.1199C239.448 79.1517 240.098 78.2158 239.86 77.0103C239.622 75.8206 238.607 74.8213 237.576 74.7896C236.561 74.7579 235.91 75.6937 236.132 76.8993Z" fill="#DEDEDE"/>
    <path d="M230.183 76.709C230.39 77.8986 231.405 78.8979 232.436 78.9296C233.467 78.9614 234.133 78.0096 233.911 76.82C233.689 75.6303 232.69 74.631 231.659 74.6152C230.643 74.5834 229.977 75.5193 230.183 76.709Z" fill="#DEDEDE"/>
    <path d="M224.251 76.55C224.441 77.7397 225.44 78.739 226.487 78.7548C227.518 78.7866 228.2 77.8348 227.994 76.6452C227.788 75.4555 226.789 74.4721 225.758 74.4403C224.727 74.4086 224.044 75.3603 224.251 76.55Z" fill="#DEDEDE"/>
    <path d="M218.303 76.3915C218.477 77.5811 219.477 78.5804 220.508 78.5963C221.539 78.628 222.237 77.6763 222.046 76.4866C221.856 75.297 220.872 74.3135 219.841 74.2818C218.795 74.266 218.112 75.2018 218.303 76.3915Z" fill="#DEDEDE"/>
    <path d="M212.339 76.2646C212.514 77.4542 213.481 78.4377 214.528 78.4694C215.559 78.4853 216.257 77.5494 216.083 76.3597C215.908 75.1701 214.925 74.1866 213.894 74.1708C212.879 74.1232 212.181 75.0749 212.339 76.2646Z" fill="#DEDEDE"/>
    <path d="M206.391 76.1379C206.549 77.3276 207.501 78.311 208.548 78.3269C209.579 78.3427 210.293 77.391 210.134 76.2014C209.976 75.0117 209.008 74.0283 207.977 74.0124C206.946 73.9965 206.232 74.9483 206.391 76.1379Z" fill="#DEDEDE"/>
    <path d="M200.426 76.0268C200.569 77.2165 201.521 78.1999 202.552 78.2158C203.598 78.2317 204.312 77.2799 204.169 76.0903C204.027 74.9006 203.059 73.9172 202.044 73.9013C201.013 73.8855 200.283 74.8372 200.426 76.0268Z" fill="#DEDEDE"/>
    <path d="M194.462 75.9314C194.589 77.121 195.525 78.1045 196.572 78.1204C197.619 78.1362 198.348 77.1845 198.206 75.9948C198.063 74.8052 197.127 73.8376 196.096 73.8217C195.065 73.8059 194.335 74.7417 194.462 75.9314Z" fill="#DEDEDE"/>
    <path d="M188.498 75.8684C188.609 77.0581 189.545 78.0415 190.576 78.0415C191.623 78.0574 192.369 77.0898 192.242 75.9002C192.115 74.7105 191.195 73.7429 190.164 73.7271C189.133 73.7271 188.387 74.6788 188.498 75.8684Z" fill="#DEDEDE"/>
    <path d="M182.518 75.8047C182.613 76.9944 183.533 77.962 184.58 77.9778C185.627 77.9937 186.373 77.0261 186.278 75.8364C186.167 74.6468 185.247 73.6792 184.216 73.6633C183.185 73.6633 182.423 74.6151 182.518 75.8047Z" fill="#DEDEDE"/>
    <path d="M176.554 75.7572C176.633 76.9468 177.553 77.9144 178.584 77.9303C179.631 77.9303 180.392 76.9786 180.297 75.7889C180.202 74.5993 179.298 73.6317 178.267 73.6317C177.236 73.6158 176.474 74.5675 176.554 75.7572Z" fill="#DEDEDE"/>
    <path d="M170.574 75.7252C170.637 76.9149 171.542 77.8825 172.588 77.8825C173.635 77.8825 174.413 76.9308 174.333 75.7411C174.254 74.5514 173.35 73.5839 172.319 73.5839C171.288 73.5839 170.51 74.5356 170.574 75.7252Z" fill="#DEDEDE"/>
    <path d="M164.594 75.7096C164.641 76.8993 165.53 77.8668 166.577 77.8668C167.623 77.8668 168.417 76.8993 168.353 75.7096C168.29 74.52 167.401 73.5524 166.37 73.5524C165.339 73.5682 164.546 74.52 164.594 75.7096Z" fill="#DEDEDE"/>
    <path d="M158.614 75.7098C158.646 76.8995 159.534 77.867 160.565 77.867C161.612 77.867 162.405 76.8995 162.358 75.7098C162.31 74.5202 161.438 73.5526 160.407 73.5684C159.391 73.5526 158.582 74.5202 158.614 75.7098Z" fill="#DEDEDE"/>
    <path d="M152.634 75.7254C152.665 76.9151 153.522 77.8827 154.569 77.8827C155.616 77.8827 156.425 76.9151 156.393 75.7254C156.361 74.5358 155.489 73.5841 154.458 73.5841C153.427 73.5682 152.618 74.5358 152.634 75.7254Z" fill="#DEDEDE"/>
    <path d="M146.654 75.757C146.669 76.9466 147.51 77.9142 148.557 77.8983C149.604 77.8983 150.429 76.9149 150.413 75.7252C150.397 74.5356 149.54 73.5839 148.509 73.5839C147.478 73.5997 146.638 74.5673 146.654 75.757Z" fill="#DEDEDE"/>
    <path d="M140.674 75.8046C140.674 76.9942 141.515 77.946 142.562 77.946C143.609 77.9301 144.449 76.9625 144.434 75.7729C144.434 74.5832 143.593 73.6315 142.546 73.6315C141.515 73.6473 140.674 74.6149 140.674 75.8046Z" fill="#DEDEDE"/>
    <path d="M134.694 75.868C134.678 77.0577 135.503 78.0094 136.549 78.0094C137.596 77.9935 138.453 77.0259 138.453 75.8363C138.469 74.6466 137.628 73.6949 136.597 73.7108C135.566 73.6949 134.709 74.6784 134.694 75.868Z" fill="#DEDEDE"/>
    <path d="M128.698 75.9316C128.666 77.1213 129.491 78.073 130.522 78.0571C131.569 78.0413 132.425 77.0578 132.457 75.884C132.473 74.6944 131.664 73.7426 130.633 73.7585C129.602 73.7744 128.745 74.7419 128.698 75.9316Z" fill="#DEDEDE"/>
    <path d="M122.718 76.0266C122.67 77.2162 123.479 78.1679 124.526 78.1521C125.573 78.1362 126.445 77.1528 126.477 75.9631C126.509 74.7735 125.7 73.8217 124.669 73.8376C123.638 73.8693 122.765 74.8369 122.718 76.0266Z" fill="#DEDEDE"/>
    <path d="M116.738 76.1376C116.675 77.3273 117.468 78.279 118.515 78.2631C119.562 78.2473 120.45 77.2638 120.498 76.0742C120.545 74.8845 119.752 73.9328 118.721 73.9645C117.69 73.9645 116.802 74.948 116.738 76.1376Z" fill="#DEDEDE"/>
    <path d="M110.758 76.2647C110.679 77.4544 111.472 78.4061 112.503 78.3902C113.55 78.3743 114.438 77.3909 114.518 76.2012C114.581 75.0116 113.804 74.0599 112.773 74.0916C111.726 74.0916 110.822 75.075 110.758 76.2647Z" fill="#DEDEDE"/>
    <path d="M98.7822 76.5502C98.6712 77.7399 99.4326 78.6916 100.479 78.6599C101.526 78.6282 102.446 77.6447 102.542 76.4551C102.637 75.2654 101.875 74.3296 100.844 74.3454C99.8133 74.3771 98.8933 75.3606 98.7822 76.5502Z" fill="#DEDEDE"/>
    <path d="M373.64 78.7706C374.179 79.9761 375.417 81.023 376.4 81.1182C377.384 81.2134 377.748 80.3093 377.209 79.1037C376.67 77.8982 375.433 76.8513 374.449 76.7561C373.481 76.661 373.117 77.5651 373.64 78.7706Z" fill="#DEDEDE"/>
    <path d="M367.962 78.2472C368.485 79.4527 369.707 80.4996 370.706 80.5948C371.69 80.6899 372.07 79.7858 371.547 78.5803C371.023 77.3748 369.786 76.3279 368.803 76.2486C367.803 76.1534 367.438 77.0417 367.962 78.2472Z" fill="#DEDEDE"/>
    <path d="M362.267 77.7556C362.775 78.9611 363.996 80.008 364.996 80.0873C365.995 80.1666 366.376 79.2625 365.852 78.0729C365.329 76.8673 364.123 75.8204 363.124 75.7411C362.14 75.646 361.744 76.5501 362.267 77.7556Z" fill="#DEDEDE"/>
    <path d="M356.556 77.2638C357.048 78.4693 358.269 79.5162 359.252 79.5955C360.252 79.6749 360.648 78.7707 360.141 77.5652C359.633 76.3597 358.428 75.3287 357.444 75.2335C356.445 75.1542 356.048 76.0583 356.556 77.2638Z" fill="#DEDEDE"/>
    <path d="M350.83 76.7882C351.322 77.9937 352.512 79.0248 353.511 79.1199C354.51 79.1992 354.907 78.2951 354.415 77.0896C353.923 75.8841 352.718 74.853 351.734 74.7737C350.751 74.6785 350.354 75.5827 350.83 76.7882Z" fill="#DEDEDE"/>
    <path d="M345.104 76.3121C345.58 77.5176 346.769 78.5486 347.769 78.6279C348.768 78.7072 349.181 77.8031 348.705 76.5976C348.229 75.3921 347.023 74.361 346.04 74.2817C345.056 74.2183 344.628 75.1224 345.104 76.3121Z" fill="#DEDEDE"/>
    <path d="M339.378 75.8681C339.838 77.0577 341.028 78.1046 342.027 78.1839C343.027 78.2632 343.455 77.3591 342.979 76.1536C342.503 74.9481 341.329 73.917 340.33 73.8536C339.331 73.7584 338.903 74.6784 339.378 75.8681Z" fill="#DEDEDE"/>
    <path d="M333.62 75.44C334.064 76.6296 335.238 77.6765 336.253 77.74C337.252 77.8193 337.696 76.8993 337.236 75.7096C336.776 74.52 335.603 73.4889 334.619 73.4096C333.62 73.3303 333.176 74.2345 333.62 75.44Z" fill="#DEDEDE"/>
    <path d="M327.878 75.0114C328.322 76.2011 329.48 77.2321 330.479 77.3114C331.479 77.3907 331.939 76.4707 331.495 75.2811C331.05 74.0914 329.877 73.0604 328.893 72.9811C327.894 72.9018 327.434 73.8218 327.878 75.0114Z" fill="#DEDEDE"/>
    <path d="M322.121 74.5992C322.549 75.7888 323.707 76.8199 324.706 76.8833C325.705 76.9468 326.165 76.0426 325.737 74.853C325.309 73.6633 324.151 72.6323 323.152 72.5688C322.152 72.5054 321.692 73.4095 322.121 74.5992Z" fill="#DEDEDE"/>
    <path d="M316.346 74.2024C316.759 75.3921 317.901 76.4231 318.916 76.4866C319.915 76.55 320.391 75.6459 319.979 74.4403C319.55 73.2507 318.408 72.2355 317.409 72.1562C316.41 72.1086 315.934 73.0128 316.346 74.2024Z" fill="#DEDEDE"/>
    <path d="M310.572 73.8221C310.969 75.0117 312.111 76.0428 313.11 76.1062C314.109 76.1697 314.601 75.2497 314.189 74.06C313.776 72.8703 312.634 71.8552 311.651 71.7917C310.652 71.7283 310.176 72.6324 310.572 73.8221Z" fill="#DEDEDE"/>
    <path d="M304.783 73.4569C305.164 74.6466 306.306 75.6618 307.305 75.7252C308.32 75.7887 308.812 74.8687 308.415 73.679C308.019 72.4894 306.893 71.4742 305.893 71.4107C304.894 71.3631 304.402 72.2673 304.783 73.4569Z" fill="#DEDEDE"/>
    <path d="M298.994 73.1082C299.359 74.2978 300.485 75.313 301.5 75.3765C302.515 75.4399 303.023 74.5199 302.642 73.3303C302.261 72.1406 301.135 71.1254 300.136 71.062C299.136 71.0144 298.613 71.9185 298.994 73.1082Z" fill="#DEDEDE"/>
    <path d="M293.188 72.7749C293.553 73.9645 294.663 74.9797 295.663 75.0273C296.678 75.0907 297.185 74.1707 296.82 72.9811C296.456 71.7914 295.345 70.7921 294.346 70.7287C293.347 70.6652 292.839 71.5852 293.188 72.7749Z" fill="#DEDEDE"/>
    <path d="M287.382 72.442C287.731 73.6317 288.826 74.6468 289.841 74.6944C290.856 74.7579 291.379 73.822 291.03 72.6482C290.681 71.4586 289.571 70.4593 288.572 70.3958C287.573 70.3482 287.033 71.2682 287.382 72.442Z" fill="#DEDEDE"/>
    <path d="M281.576 72.1406C281.909 73.3303 283.004 74.3296 284.019 74.393C285.034 74.4406 285.574 73.5206 285.241 72.331C284.892 71.1413 283.813 70.142 282.798 70.0944C281.783 70.031 281.243 70.951 281.576 72.1406Z" fill="#DEDEDE"/>
    <path d="M275.756 71.8392C276.073 73.0289 277.152 74.0282 278.167 74.0758C279.182 74.1234 279.737 73.2034 279.404 72.0137C279.071 70.8241 277.992 69.8247 276.993 69.7772C275.994 69.7454 275.439 70.6654 275.756 71.8392Z" fill="#DEDEDE"/>
    <path d="M269.934 71.5696C270.236 72.7592 271.314 73.7585 272.33 73.8061C273.345 73.8537 273.916 72.9337 273.599 71.7441C273.281 70.5544 272.218 69.5551 271.203 69.5075C270.188 69.4599 269.633 70.3799 269.934 71.5696Z" fill="#DEDEDE"/>
    <path d="M264.096 71.2999C264.382 72.4896 265.445 73.4889 266.46 73.5365C267.475 73.5841 268.062 72.6641 267.76 71.4744C267.459 70.2847 266.396 69.3013 265.397 69.2537C264.382 69.1903 263.811 70.1103 264.096 71.2999Z" fill="#DEDEDE"/>
    <path d="M258.259 71.0459C258.545 72.2355 259.592 73.219 260.607 73.2665C261.622 73.3141 262.209 72.3783 261.923 71.2045C261.638 70.0148 260.591 69.0314 259.576 68.9838C258.577 68.9362 257.99 69.8721 258.259 71.0459Z" fill="#DEDEDE"/>
    <path d="M252.422 70.8081C252.692 71.9819 253.723 72.9812 254.738 73.0288C255.753 73.0763 256.356 72.1405 256.086 70.9508C255.817 69.7612 254.77 68.7777 253.771 68.746C252.755 68.6984 252.153 69.6343 252.422 70.8081Z" fill="#DEDEDE"/>
    <path d="M246.569 70.586C246.823 71.7598 247.854 72.7591 248.869 72.7909C249.884 72.8226 250.503 71.9026 250.249 70.7129C249.995 69.5233 248.964 68.5398 247.949 68.5081C246.934 68.4764 246.331 69.4122 246.569 70.586Z" fill="#DEDEDE"/>
    <path d="M240.716 70.3797C240.953 71.5535 241.969 72.5528 243 72.5846C244.015 72.6163 244.649 71.6963 244.396 70.5066C244.142 69.3328 243.127 68.3494 242.127 68.3018C241.112 68.2701 240.478 69.2059 240.716 70.3797Z" fill="#DEDEDE"/>
    <path d="M234.863 70.1894C235.085 71.3632 236.1 72.3625 237.115 72.3943C238.13 72.426 238.781 71.4901 238.543 70.3163C238.305 69.1426 237.305 68.1591 236.29 68.1274C235.275 68.0798 234.641 68.9998 234.863 70.1894Z" fill="#DEDEDE"/>
    <path d="M229.009 70.0148C229.216 71.1886 230.215 72.172 231.246 72.2037C232.261 72.2355 232.912 71.2996 232.705 70.1258C232.483 68.952 231.484 67.9686 230.485 67.9368C229.438 67.9051 228.803 68.8251 229.009 70.0148Z" fill="#DEDEDE"/>
    <path d="M223.14 69.8403C223.33 71.0141 224.33 71.9976 225.345 72.0293C226.36 72.061 227.026 71.1252 226.82 69.9355C226.614 68.7617 225.63 67.7783 224.615 67.7465C223.6 67.7307 222.95 68.6665 223.14 69.8403Z" fill="#DEDEDE"/>
    <path d="M217.272 69.6977C217.446 70.8715 218.43 71.855 219.445 71.8867C220.476 71.9184 221.142 70.9667 220.952 69.7929C220.761 68.6191 219.778 67.6357 218.779 67.6198C217.763 67.5881 217.081 68.5239 217.272 69.6977Z" fill="#DEDEDE"/>
    <path d="M211.387 69.5549C211.561 70.7287 212.513 71.7122 213.544 71.728C214.575 71.7439 215.257 70.808 215.083 69.6342C214.908 68.4605 213.94 67.4929 212.925 67.4611C211.91 67.4453 211.228 68.3811 211.387 69.5549Z" fill="#DEDEDE"/>
    <path d="M205.518 69.4438C205.677 70.6176 206.628 71.6011 207.644 71.617C208.675 71.6328 209.373 70.697 209.198 69.5232C209.039 68.3494 208.088 67.3818 207.073 67.3501C206.057 67.3342 205.359 68.2701 205.518 69.4438Z" fill="#DEDEDE"/>
    <path d="M199.633 69.333C199.776 70.5068 200.712 71.4903 201.743 71.5061C202.774 71.522 203.472 70.5861 203.329 69.3965C203.186 68.2227 202.234 67.2551 201.219 67.2392C200.204 67.2234 199.49 68.1592 199.633 69.333Z" fill="#DEDEDE"/>
    <path d="M193.749 69.2536C193.876 70.4274 194.811 71.395 195.827 71.4109C196.858 71.4268 197.571 70.475 197.445 69.3012C197.318 68.1274 196.382 67.1599 195.367 67.144C194.351 67.1281 193.622 68.064 193.749 69.2536Z" fill="#DEDEDE"/>
    <path d="M187.864 69.1742C187.975 70.348 188.895 71.3156 189.926 71.3314C190.957 71.3473 191.687 70.3956 191.56 69.2218C191.433 68.048 190.513 67.0804 189.498 67.0645C188.498 67.0487 187.753 68.0004 187.864 69.1742Z" fill="#DEDEDE"/>
    <path d="M181.978 69.1107C182.074 70.2845 182.978 71.2521 184.009 71.268C185.04 71.2838 185.785 70.3321 185.674 69.1583C185.563 67.9845 184.659 67.0169 183.644 67.0169C182.629 67.0011 181.883 67.9369 181.978 69.1107Z" fill="#DEDEDE"/>
    <path d="M176.078 69.0633C176.158 70.2371 177.062 71.2046 178.093 71.2046C179.124 71.2046 179.885 70.2688 179.79 69.0791C179.695 67.9053 178.807 66.9536 177.776 66.9377C176.761 66.9536 175.999 67.8895 176.078 69.0633Z" fill="#DEDEDE"/>
    <path d="M170.193 69.0316C170.257 70.2054 171.145 71.173 172.176 71.173C173.207 71.173 173.968 70.2213 173.889 69.0475C173.81 67.8737 172.921 66.922 171.906 66.9061C170.891 66.922 170.13 67.8579 170.193 69.0316Z" fill="#DEDEDE"/>
    <path d="M164.294 69.0315C164.341 70.2053 165.229 71.1729 166.245 71.1729C167.276 71.1729 168.053 70.2211 167.989 69.0473C167.926 67.8736 167.054 66.9218 166.038 66.9218C165.023 66.906 164.246 67.8418 164.294 69.0315Z" fill="#DEDEDE"/>
    <path d="M158.391 69.0316C158.423 70.2054 159.295 71.1572 160.326 71.1572C161.357 71.1572 162.15 70.2054 162.103 69.0158C162.055 67.842 161.183 66.8903 160.168 66.8903C159.152 66.8903 158.359 67.842 158.391 69.0316Z" fill="#DEDEDE"/>
    <path d="M152.491 69.0475C152.507 70.2213 153.364 71.173 154.395 71.173C155.426 71.173 156.235 70.2054 156.187 69.0316C156.156 67.8579 155.299 66.9061 154.284 66.9061C153.285 66.9061 152.476 67.8578 152.491 69.0475Z" fill="#DEDEDE"/>
    <path d="M146.59 69.0632C146.606 70.237 147.447 71.1887 148.478 71.1887C149.509 71.1887 150.318 70.2211 150.302 69.0473C150.286 67.8736 149.445 66.9218 148.414 66.9377C147.399 66.9377 146.59 67.8894 146.59 69.0632Z" fill="#DEDEDE"/>
    <path d="M140.69 69.1108C140.69 70.2846 141.515 71.2363 142.546 71.2363C143.577 71.2205 144.402 70.2688 144.402 69.095C144.402 67.9212 143.561 66.9694 142.546 66.9853C141.531 66.9853 140.706 67.937 140.69 69.1108Z" fill="#DEDEDE"/>
    <path d="M111.186 69.5709C111.107 70.7447 111.884 71.6805 112.915 71.6647C113.946 71.6488 114.835 70.6654 114.898 69.4916C114.961 68.3178 114.184 67.3819 113.169 67.3978C112.138 67.4136 111.25 68.3971 111.186 69.5709Z" fill="#DEDEDE"/>
    <path d="M105.27 69.6977C105.175 70.8715 105.936 71.8073 106.967 71.7915C107.998 71.7598 108.902 70.7922 108.981 69.6184C109.061 68.4446 108.299 67.5087 107.284 67.5405C106.269 67.5563 105.365 68.5239 105.27 69.6977Z" fill="#DEDEDE"/>
    <path d="M370.626 71.9822C371.166 73.1718 372.387 74.2187 373.37 74.3139C374.354 74.4091 374.703 73.5049 374.163 72.3153C373.624 71.1256 372.403 70.0787 371.435 69.9836C370.452 69.9042 370.103 70.7925 370.626 71.9822Z" fill="#DEDEDE"/>
    <path d="M365.012 71.4744C365.535 72.664 366.757 73.7109 367.724 73.7902C368.708 73.8854 369.072 72.9813 368.533 71.7916C368.01 70.602 366.788 69.5551 365.821 69.4757C364.853 69.3964 364.488 70.2847 365.012 71.4744Z" fill="#DEDEDE"/>
    <path d="M359.38 70.9824C359.888 72.1721 361.093 73.219 362.077 73.2983C363.06 73.3776 363.441 72.4893 362.917 71.2997C362.394 70.11 361.188 69.079 360.221 68.9838C359.237 68.9045 358.872 69.7928 359.38 70.9824Z" fill="#DEDEDE"/>
    <path d="M353.733 70.5067C354.225 71.6964 355.431 72.7274 356.414 72.8226C357.397 72.9019 357.778 72.0136 357.271 70.8081C356.763 69.6184 355.557 68.5874 354.59 68.5081C353.622 68.4129 353.242 69.3171 353.733 70.5067Z" fill="#DEDEDE"/>
    <path d="M348.086 70.0309C348.578 71.2205 349.767 72.2515 350.751 72.3309C351.734 72.4102 352.131 71.506 351.639 70.3164C351.147 69.1267 349.958 68.0957 348.974 68.0164C347.991 67.9529 347.594 68.8412 348.086 70.0309Z" fill="#DEDEDE"/>
    <path d="M342.424 69.571C342.9 70.7606 344.073 71.7917 345.073 71.871C346.056 71.9503 346.469 71.0461 345.977 69.8565C345.501 68.6668 344.311 67.6517 343.328 67.5723C342.36 67.493 341.948 68.3972 342.424 69.571Z" fill="#DEDEDE"/>
    <path d="M336.761 69.1264C337.221 70.316 338.394 71.3312 339.378 71.4105C340.361 71.4898 340.79 70.5857 340.314 69.396C339.838 68.2064 338.664 67.1912 337.696 67.1119C336.713 67.0485 336.301 67.9526 336.761 69.1264Z" fill="#DEDEDE"/>
    <path d="M331.082 68.6985C331.527 69.8882 332.7 70.9034 333.684 70.9827C334.667 71.062 335.111 70.1578 334.651 68.9682C334.191 67.7785 333.033 66.7634 332.05 66.6999C331.067 66.6206 330.638 67.5247 331.082 68.6985Z" fill="#DEDEDE"/>
    <path d="M325.403 68.2858C325.832 69.4755 326.99 70.4906 327.989 70.5541C328.972 70.6334 329.417 69.7293 328.972 68.5396C328.528 67.35 327.37 66.3348 326.387 66.2713C325.403 66.2079 324.975 67.112 325.403 68.2858Z" fill="#DEDEDE"/>
    <path d="M319.709 67.8893C320.137 69.079 321.279 70.0942 322.279 70.1576C323.278 70.2211 323.722 69.3169 323.294 68.1431C322.865 66.9535 321.707 65.9542 320.724 65.8748C319.741 65.8114 319.296 66.7155 319.709 67.8893Z" fill="#DEDEDE"/>
    <path d="M314.015 67.5089C314.427 68.6827 315.569 69.6979 316.553 69.7772C317.552 69.8406 318.012 68.9365 317.584 67.7627C317.171 66.5889 316.029 65.5737 315.046 65.5103C314.078 65.4151 313.602 66.3193 314.015 67.5089Z" fill="#DEDEDE"/>
    <path d="M308.321 67.1279C308.717 68.3017 309.843 69.3168 310.843 69.3803C311.842 69.4437 312.318 68.5396 311.906 67.3658C311.493 66.192 310.367 65.1768 309.384 65.1134C308.4 65.0499 307.908 65.9541 308.321 67.1279Z" fill="#DEDEDE"/>
    <path d="M302.61 66.7632C302.99 67.937 304.116 68.9522 305.116 69.0156C306.115 69.0791 306.591 68.175 306.21 66.9853C305.814 65.8115 304.703 64.7963 303.704 64.7488C302.705 64.6853 302.213 65.5894 302.61 66.7632Z" fill="#DEDEDE"/>
    <path d="M296.884 66.4301C297.248 67.6039 298.359 68.6191 299.358 68.6667C300.357 68.7301 300.849 67.8101 300.468 66.6363C300.088 65.4625 298.977 64.4632 297.994 64.3998C297.01 64.3363 296.519 65.2404 296.884 66.4301Z" fill="#DEDEDE"/>
    <path d="M291.157 66.0971C291.522 67.2709 292.617 68.2702 293.616 68.3336C294.615 68.3971 295.123 67.4771 294.758 66.3033C294.393 65.1295 293.283 64.1302 292.3 64.0667C291.316 64.0033 290.808 64.9074 291.157 66.0971Z" fill="#DEDEDE"/>
    <path d="M285.431 65.78C285.78 66.9538 286.859 67.9531 287.858 68.0165C288.857 68.0641 289.381 67.16 289.016 65.9862C288.667 64.8124 287.572 63.8131 286.589 63.7655C285.605 63.6862 285.082 64.6062 285.431 65.78Z" fill="#DEDEDE"/>
    <path d="M279.689 65.4624C280.022 66.6362 281.101 67.6355 282.1 67.6831C283.099 67.7307 283.639 66.8266 283.29 65.6528C282.941 64.479 281.862 63.4797 280.879 63.4321C279.895 63.3845 279.356 64.2886 279.689 65.4624Z" fill="#DEDEDE"/>
    <path d="M273.947 65.1771C274.264 66.3508 275.343 67.3502 276.342 67.3977C277.341 67.4453 277.881 66.5412 277.563 65.3674C277.23 64.1936 276.167 63.2102 275.168 63.1626C274.185 63.0991 273.63 64.0033 273.947 65.1771Z" fill="#DEDEDE"/>
    <path d="M268.205 64.9072C268.506 66.081 269.569 67.0645 270.569 67.1121C271.568 67.1597 272.123 66.2397 271.806 65.0659C271.489 63.8921 270.426 62.9086 269.442 62.861C268.459 62.8293 267.904 63.7334 268.205 64.9072Z" fill="#DEDEDE"/>
    <path d="M262.448 64.6377C262.733 65.8115 263.796 66.7949 264.795 66.8425C265.794 66.8901 266.366 65.9701 266.064 64.7963C265.763 63.6225 264.716 62.6391 263.717 62.5915C262.733 62.5598 262.162 63.4798 262.448 64.6377Z" fill="#DEDEDE"/>
    <path d="M256.689 64.3999C256.959 65.5737 258.005 66.5572 259.005 66.6048C260.004 66.6524 260.591 65.7324 260.305 64.5586C260.02 63.3848 258.973 62.4013 257.99 62.3696C256.99 62.322 256.403 63.2261 256.689 64.3999Z" fill="#DEDEDE"/>
    <path d="M250.932 64.1619C251.201 65.3357 252.232 66.3191 253.232 66.3509C254.231 66.3984 254.834 65.4784 254.548 64.3046C254.279 63.1309 253.248 62.1633 252.248 62.1157C251.249 62.084 250.662 62.9881 250.932 64.1619Z" fill="#DEDEDE"/>
    <path d="M245.157 63.9398C245.41 65.1136 246.426 66.0971 247.425 66.1288C248.424 66.1605 249.027 65.2405 248.773 64.0826C248.519 62.9088 247.488 61.9412 246.505 61.9095C245.506 61.8619 244.903 62.7819 245.157 63.9398Z" fill="#DEDEDE"/>
    <path d="M239.383 63.7334C239.621 64.9072 240.621 65.8748 241.636 65.9224C242.635 65.9541 243.254 65.0341 243.016 63.8603C242.762 62.6865 241.763 61.7189 240.763 61.6872C239.764 61.6555 239.146 62.5755 239.383 63.7334Z" fill="#DEDEDE"/>
    <path d="M233.61 63.5592C233.832 64.733 234.832 65.7006 235.831 65.7323C236.846 65.764 237.465 64.844 237.227 63.6702C236.989 62.4964 235.989 61.5289 235.006 61.4971C234.007 61.4654 233.372 62.3854 233.61 63.5592Z" fill="#DEDEDE"/>
    <path d="M227.82 63.3689C228.026 64.5268 229.009 65.5103 230.024 65.542C231.04 65.5737 231.674 64.6537 231.452 63.4799C231.23 62.3061 230.247 61.3386 229.247 61.3227C228.248 61.291 227.613 62.211 227.82 63.3689Z" fill="#DEDEDE"/>
    <path d="M222.031 63.21C222.221 64.3838 223.204 65.3514 224.204 65.3672C225.219 65.3989 225.869 64.4631 225.663 63.3051C225.457 62.1314 224.489 61.1796 223.49 61.1479C222.491 61.132 221.84 62.052 222.031 63.21Z" fill="#DEDEDE"/>
    <path d="M216.24 63.0674C216.414 64.2253 217.382 65.1929 218.397 65.2246C219.412 65.2564 220.062 64.3205 219.872 63.1626C219.682 61.9888 218.714 61.0371 217.715 61.0053C216.731 60.9895 216.065 61.9095 216.24 63.0674Z" fill="#DEDEDE"/>
    <path d="M210.451 62.9407C210.626 64.0986 211.577 65.0662 212.577 65.0979C213.592 65.1138 214.258 64.1938 214.083 63.02C213.909 61.862 212.957 60.8945 211.958 60.8786C210.959 60.8469 210.277 61.7669 210.451 62.9407Z" fill="#DEDEDE"/>
    <path d="M204.646 62.8138C204.804 63.9717 205.74 64.9393 206.755 64.9552C207.771 64.971 208.453 64.051 208.294 62.8772C208.135 61.7193 207.184 60.7517 206.184 60.7358C205.185 60.7358 204.503 61.6558 204.646 62.8138Z" fill="#DEDEDE"/>
    <path d="M198.856 62.7185C198.999 63.8764 199.919 64.844 200.934 64.8599C201.949 64.8758 202.647 63.9399 202.488 62.782C202.346 61.624 201.41 60.6564 200.41 60.6406C199.411 60.6247 198.713 61.5606 198.856 62.7185Z" fill="#DEDEDE"/>
    <path d="M193.051 62.6392C193.178 63.7971 194.098 64.7647 195.113 64.7805C196.128 64.7964 196.842 63.8605 196.699 62.7026C196.556 61.5447 195.636 60.593 194.637 60.5771C193.638 60.5454 192.924 61.4654 193.051 62.6392Z" fill="#DEDEDE"/>
    <path d="M187.245 62.5595C187.356 63.7174 188.26 64.685 189.275 64.685C190.29 64.7009 191.004 63.765 190.893 62.5912C190.766 61.4333 189.862 60.4816 188.863 60.4657C187.848 60.4657 187.134 61.4016 187.245 62.5595Z" fill="#DEDEDE"/>
    <path d="M181.439 62.4963C181.534 63.6542 182.438 64.6059 183.453 64.6218C184.468 64.6376 185.198 63.6859 185.103 62.528C184.992 61.37 184.103 60.4183 183.104 60.4025C182.073 60.4025 181.343 61.3383 181.439 62.4963Z" fill="#DEDEDE"/>
    <path d="M175.619 62.4489C175.698 63.6068 176.586 64.5585 177.601 64.5744C178.617 64.5744 179.362 63.6385 179.267 62.4806C179.172 61.3226 178.283 60.3709 177.284 60.3709C176.285 60.3551 175.539 61.2909 175.619 62.4489Z" fill="#DEDEDE"/>
    <path d="M169.812 62.433C169.876 63.5909 170.748 64.5426 171.763 64.5426C172.779 64.5426 173.54 63.6068 173.461 62.4488C173.381 61.2909 172.509 60.3392 171.51 60.3392C170.494 60.3233 169.733 61.2592 169.812 62.433Z" fill="#DEDEDE"/>
    <path d="M163.99 62.4173C164.038 63.5752 164.91 64.5269 165.926 64.5269C166.941 64.5269 167.718 63.5911 167.655 62.4173C167.591 61.2593 166.735 60.3076 165.719 60.3076C164.704 60.3076 163.943 61.2435 163.99 62.4173Z" fill="#DEDEDE"/>
    <path d="M158.17 62.4171C158.202 63.575 159.058 64.5268 160.073 64.5268C161.089 64.5268 161.866 63.575 161.818 62.4171C161.771 61.2592 160.914 60.3233 159.915 60.3233C158.915 60.3075 158.138 61.2433 158.17 62.4171Z" fill="#DEDEDE"/>
    <path d="M105.778 63.0835C105.683 64.2414 106.428 65.1773 107.443 65.1455C108.458 65.1297 109.347 64.1621 109.426 62.9883C109.505 61.8304 108.76 60.9104 107.76 60.9262C106.761 60.958 105.873 61.9255 105.778 63.0835Z" fill="#DEDEDE"/>
    <path d="M99.9563 63.226C99.8452 64.384 100.591 65.3198 101.606 65.2881C102.621 65.2564 103.525 64.2888 103.62 63.1309C103.716 61.9729 102.97 61.0529 101.971 61.0688C100.971 61.1005 100.067 62.0681 99.9563 63.226Z" fill="#DEDEDE"/>
    <path d="M373.165 65.7799C373.704 66.9696 374.941 68.0006 375.893 68.0958C376.861 68.1909 377.194 67.3027 376.639 66.113C376.083 64.9234 374.862 63.8923 373.91 63.7971C372.959 63.702 372.626 64.5903 373.165 65.7799Z" fill="#DEDEDE"/>
    <path d="M367.612 65.2721C368.151 66.4617 369.357 67.4928 370.324 67.5721C371.292 67.6673 371.641 66.7631 371.102 65.5893C370.562 64.3997 369.341 63.3686 368.389 63.2893C367.422 63.1942 367.088 64.0824 367.612 65.2721Z" fill="#DEDEDE"/>
    <path d="M362.061 64.7648C362.584 65.9386 363.79 66.9696 364.757 67.0648C365.725 67.1441 366.074 66.2558 365.55 65.082C365.027 63.8924 363.821 62.8772 362.854 62.782C361.886 62.7027 361.537 63.591 362.061 64.7648Z" fill="#DEDEDE"/>
    <path d="M356.494 64.2732C357.001 65.447 358.207 66.478 359.174 66.5732C360.142 66.6525 360.507 65.7642 359.999 64.5904C359.476 63.4167 358.286 62.3856 357.319 62.3063C356.351 62.2111 355.986 63.0994 356.494 64.2732Z" fill="#DEDEDE"/>
    <path d="M350.926 63.8129C351.418 64.9867 352.607 66.0177 353.591 66.097C354.558 66.1763 354.939 65.288 354.431 64.1142C353.924 62.9404 352.734 61.9094 351.767 61.8301C350.799 61.7349 350.418 62.6232 350.926 63.8129Z" fill="#DEDEDE"/>
    <path d="M345.342 63.3531C345.834 64.5269 347.007 65.5579 347.975 65.6372C348.942 65.7166 349.339 64.8283 348.847 63.6545C348.356 62.4807 347.166 61.4655 346.214 61.3862C345.247 61.2752 344.85 62.1634 345.342 63.3531Z" fill="#DEDEDE"/>
    <path d="M339.742 62.8931C340.218 64.0668 341.392 65.082 342.36 65.1613C343.327 65.2406 343.74 64.3524 343.248 63.1627C342.772 61.9889 341.598 60.9737 340.631 60.8944C339.679 60.831 339.282 61.7193 339.742 62.8931Z" fill="#DEDEDE"/>
    <path d="M334.143 62.4644C334.603 63.6382 335.761 64.6533 336.744 64.7326C337.712 64.812 338.124 63.9078 337.664 62.734C337.188 61.5602 336.03 60.5451 335.063 60.4816C334.095 60.4023 333.683 61.2906 334.143 62.4644Z" fill="#DEDEDE"/>
    <path d="M328.544 62.0522C328.989 63.226 330.147 64.2412 331.13 64.3046C332.113 64.384 332.526 63.4798 332.066 62.306C331.606 61.1322 330.448 60.1329 329.48 60.0536C328.529 59.9902 328.1 60.8784 328.544 62.0522Z" fill="#DEDEDE"/>
    <path d="M322.928 61.6399C323.356 62.8137 324.514 63.813 325.482 63.8923C326.465 63.9557 326.894 63.0675 326.449 61.8937C326.005 60.7199 324.863 59.7206 323.896 59.6413C322.928 59.5778 322.5 60.4661 322.928 61.6399Z" fill="#DEDEDE"/>
    <path d="M317.314 61.2432C317.742 62.4169 318.868 63.4163 319.852 63.4797C320.835 63.5432 321.279 62.6549 320.835 61.4811C320.407 60.3073 319.265 59.308 318.297 59.2445C317.346 59.1811 316.901 60.0694 317.314 61.2432Z" fill="#DEDEDE"/>
    <path d="M311.699 60.8626C312.111 62.0364 313.238 63.0357 314.221 63.0992C315.205 63.1626 315.649 62.2743 315.236 61.1005C314.824 59.9267 313.682 58.9274 312.714 58.864C311.731 58.8005 311.287 59.7047 311.699 60.8626Z" fill="#DEDEDE"/>
    <path d="M306.068 60.4979C306.464 61.6717 307.591 62.671 308.558 62.7345C309.542 62.7979 310.002 61.8938 309.605 60.7359C309.193 59.5621 308.082 58.5786 307.115 58.5152C306.131 58.4359 305.671 59.3241 306.068 60.4979Z" fill="#DEDEDE"/>
    <path d="M300.421 60.1489C300.801 61.3068 301.912 62.3062 302.895 62.3696C303.879 62.4331 304.355 61.5289 303.958 60.371C303.561 59.1972 302.451 58.2137 301.484 58.1503C300.516 58.0868 300.04 58.9751 300.421 60.1489Z" fill="#DEDEDE"/>
    <path d="M294.774 59.7998C295.139 60.9577 296.249 61.957 297.232 62.0205C298.216 62.0839 298.708 61.1798 298.327 60.0219C297.946 58.8639 296.852 57.8646 295.868 57.817C294.901 57.7536 294.409 58.6419 294.774 59.7998Z" fill="#DEDEDE"/>
    <path d="M289.127 59.4826C289.492 60.6405 290.57 61.6398 291.554 61.6874C292.537 61.7509 293.045 60.8467 292.664 59.6729C292.299 58.515 291.205 57.5316 290.237 57.4681C289.27 57.4205 288.778 58.3247 289.127 59.4826Z" fill="#DEDEDE"/>
    <path d="M283.48 59.1652C283.829 60.3231 284.908 61.3225 285.891 61.37C286.875 61.4176 287.382 60.5293 287.033 59.3556C286.684 58.1976 285.606 57.2142 284.622 57.1507C283.639 57.119 283.131 58.0073 283.48 59.1652Z" fill="#DEDEDE"/>
    <path d="M277.817 58.8642C278.15 60.0222 279.213 61.0056 280.212 61.069C281.196 61.1166 281.719 60.2125 281.386 59.0546C281.037 57.8966 279.974 56.9132 279.007 56.8656C278.007 56.818 277.484 57.7063 277.817 58.8642Z" fill="#DEDEDE"/>
    <path d="M272.139 58.5943C272.456 59.7522 273.519 60.7357 274.502 60.7833C275.486 60.8309 276.025 59.9267 275.692 58.7688C275.359 57.6109 274.312 56.6274 273.329 56.5798C272.361 56.5322 271.822 57.4205 272.139 58.5943Z" fill="#DEDEDE"/>
    <path d="M266.476 58.325C266.777 59.4829 267.824 60.4664 268.823 60.514C269.807 60.5616 270.362 59.6574 270.045 58.4995C269.727 57.3416 268.68 56.374 267.697 56.3264C266.714 56.2629 266.174 57.1671 266.476 58.325Z" fill="#DEDEDE"/>
    <path d="M260.797 58.055C261.083 59.2129 262.129 60.1964 263.113 60.2281C264.096 60.2757 264.667 59.3715 264.366 58.2136C264.065 57.0557 263.034 56.0881 262.05 56.0405C261.067 56.0088 260.512 56.897 260.797 58.055Z" fill="#DEDEDE"/>
    <path d="M255.119 57.8169C255.388 58.9749 256.419 59.9424 257.418 59.99C258.402 60.0376 258.973 59.1176 258.687 57.9597C258.402 56.8018 257.371 55.8342 256.387 55.7866C255.404 55.7549 254.833 56.659 255.119 57.8169Z" fill="#DEDEDE"/>
    <path d="M249.424 57.595C249.694 58.7529 250.709 59.7205 251.693 59.7681C252.676 59.7998 253.263 58.8957 252.993 57.7378C252.724 56.5798 251.709 55.6123 250.725 55.5805C249.742 55.5329 249.171 56.4371 249.424 57.595Z" fill="#DEDEDE"/>
    <path d="M243.73 57.3732C243.983 58.5311 244.983 59.4987 245.982 59.5304C246.981 59.5622 247.568 58.658 247.314 57.5001C247.061 56.3422 246.045 55.3746 245.062 55.3429C244.079 55.327 243.492 56.2153 243.73 57.3732Z" fill="#DEDEDE"/>
    <path d="M238.035 57.1825C238.273 58.3405 239.272 59.3081 240.256 59.3398C241.255 59.3715 241.858 58.4674 241.604 57.3094C241.366 56.1515 240.367 55.1998 239.383 55.1681C238.416 55.1205 237.797 56.0246 238.035 57.1825Z" fill="#DEDEDE"/>
    <path d="M232.341 56.992C232.563 58.1499 233.546 59.1016 234.545 59.1492C235.545 59.181 236.163 58.2609 235.925 57.1189C235.687 55.9609 234.704 55.0092 233.721 54.9775C232.737 54.9299 232.118 55.8341 232.341 56.992Z" fill="#DEDEDE"/>
    <path d="M226.63 56.8178C226.836 57.9757 227.82 58.9274 228.803 58.9591C229.803 58.9909 230.421 58.0709 230.215 56.9288C229.993 55.7709 229.025 54.8191 228.042 54.7874C227.059 54.7557 226.424 55.6757 226.63 56.8178Z" fill="#DEDEDE"/>
    <path d="M220.936 56.6591C221.126 57.817 222.094 58.7687 223.093 58.8005C224.092 58.8322 224.727 57.9122 224.521 56.7542C224.314 55.5963 223.347 54.6446 222.363 54.6287C221.38 54.597 220.729 55.517 220.936 56.6591Z" fill="#DEDEDE"/>
    <path d="M215.225 56.5166C215.4 57.6746 216.352 58.6263 217.351 58.6421C218.35 58.6739 219.001 57.7539 218.81 56.5959C218.62 55.438 217.668 54.5021 216.685 54.4704C215.685 54.4546 215.035 55.3746 215.225 56.5166Z" fill="#DEDEDE"/>
    <path d="M209.499 56.3894C209.658 57.5474 210.61 58.4991 211.609 58.5149C212.608 58.5308 213.275 57.6108 213.1 56.4687C212.926 55.3108 211.974 54.3749 210.99 54.3432C210.007 54.3274 209.341 55.2474 209.499 56.3894Z" fill="#DEDEDE"/>
    <path d="M203.789 56.2786C203.948 57.4365 204.868 58.3882 205.867 58.4041C206.866 58.42 207.532 57.5 207.374 56.3579C207.215 55.2 206.279 54.2641 205.296 54.2482C204.313 54.2165 203.63 55.1207 203.789 56.2786Z" fill="#DEDEDE"/>
    <path d="M198.062 56.1833C198.205 57.3412 199.125 58.2771 200.125 58.2929C201.124 58.3088 201.806 57.3888 201.663 56.2309C201.52 55.0729 200.6 54.1371 199.601 54.1212C198.618 54.1212 197.92 55.0254 198.062 56.1833Z" fill="#DEDEDE"/>
    <path d="M192.337 56.0879C192.464 57.23 193.368 58.1817 194.367 58.1976C195.366 58.2135 196.064 57.2935 195.937 56.1355C195.811 54.9776 194.891 54.0417 193.907 54.0259C192.924 54.0259 192.226 54.9459 192.337 56.0879Z" fill="#DEDEDE"/>
    <path d="M186.61 56.0248C186.721 57.1669 187.625 58.1186 188.608 58.1344C189.608 58.1503 190.321 57.2144 190.195 56.0724C190.068 54.9303 189.179 53.9786 188.196 53.9786C187.213 53.9469 186.515 54.8669 186.61 56.0248Z" fill="#DEDEDE"/>
    <path d="M180.884 55.9614C180.979 57.1034 181.868 58.0552 182.867 58.0552C183.866 58.071 184.596 57.1352 184.485 55.9931C184.374 54.851 183.502 53.9152 182.502 53.8993C181.519 53.8993 180.789 54.8193 180.884 55.9614Z" fill="#DEDEDE"/>
    <path d="M175.158 55.9295C175.238 57.0716 176.11 58.0233 177.109 58.0233C178.109 58.0233 178.838 57.1033 178.759 55.9454C178.664 54.8033 177.791 53.8674 176.808 53.8516C175.809 53.8516 175.079 54.7716 175.158 55.9295Z" fill="#DEDEDE"/>
    <path d="M169.432 55.8975C169.495 57.0396 170.368 57.9913 171.367 57.9913C172.366 57.9913 173.112 57.0713 173.033 55.9133C172.953 54.7713 172.097 53.8354 171.097 53.8354C170.114 53.8195 169.353 54.7395 169.432 55.8975Z" fill="#DEDEDE"/>
    <path d="M163.691 55.8818C163.738 57.0239 164.595 57.9598 165.594 57.9756C166.593 57.9756 167.355 57.0398 167.291 55.8977C167.228 54.7556 166.371 53.8198 165.388 53.8198C164.404 53.8039 163.643 54.7398 163.691 55.8818Z" fill="#DEDEDE"/>
    <path d="M157.963 55.8819C157.995 57.024 158.836 57.9598 159.835 57.9598C160.835 57.9598 161.612 57.024 161.564 55.8819C161.517 54.7398 160.676 53.804 159.692 53.804C158.693 53.804 157.916 54.7398 157.963 55.8819Z" fill="#DEDEDE"/>
    <path d="M152.222 55.8977C152.237 57.0398 153.078 57.9756 154.078 57.9756C155.077 57.9756 155.854 57.0398 155.822 55.8818C155.791 54.7398 154.966 53.8039 153.966 53.8198C152.983 53.8198 152.19 54.7556 152.222 55.8977Z" fill="#DEDEDE"/>
    <path d="M146.479 55.9292C146.495 57.0713 147.304 58.0071 148.303 58.0071C149.303 58.0071 150.096 57.0554 150.08 55.9133C150.064 54.7713 149.239 53.8354 148.256 53.8513C147.272 53.8513 146.463 54.7713 146.479 55.9292Z" fill="#DEDEDE"/>
    <path d="M140.738 55.9771C140.738 57.1192 141.547 58.055 142.546 58.0392C143.545 58.0392 144.354 57.0874 144.354 55.9454C144.354 54.8033 143.545 53.8833 142.546 53.8833C141.547 53.8833 140.754 54.8192 140.738 55.9771Z" fill="#DEDEDE"/>
    <path d="M112.043 56.405C111.964 57.5471 112.709 58.4671 113.724 58.4512C114.724 58.4354 115.596 57.4836 115.659 56.3257C115.723 55.1836 114.977 54.2636 113.978 54.2795C112.979 54.3112 112.106 55.2629 112.043 56.405Z" fill="#DEDEDE"/>
    <path d="M3.21197 61.37C2.87887 62.5279 3.40231 63.4321 4.40162 63.3528C5.40093 63.2893 6.46368 62.2741 6.79679 61.1162C7.12989 59.9583 6.59058 59.07 5.60713 59.1334C4.62368 59.1969 3.56093 60.1962 3.21197 61.37Z" fill="#DEDEDE"/>
    <path d="M-2.48138 61.7668C-2.83034 62.9406 -2.32276 63.8288 -1.32345 63.7495C-0.324141 63.6861 0.754478 62.6709 1.10344 61.513C1.45241 60.3392 0.928967 59.4668 -0.054481 59.5302C-1.03793 59.5937 -2.13241 60.593 -2.48138 61.7668Z" fill="#DEDEDE"/>
    <path d="M370.087 59.1181C370.626 60.2918 371.847 61.3229 372.799 61.4022C373.751 61.4974 374.084 60.6091 373.529 59.4353C372.974 58.2615 371.768 57.2463 370.816 57.1512C369.881 57.056 369.547 57.9443 370.087 59.1181Z" fill="#DEDEDE"/>
    <path d="M364.598 58.6103C365.122 59.7841 366.343 60.7993 367.295 60.8945C368.247 60.9738 368.58 60.1014 368.04 58.9276C367.501 57.7538 366.296 56.7386 365.344 56.6434C364.408 56.5641 364.075 57.4365 364.598 58.6103Z" fill="#DEDEDE"/>
    <path d="M359.11 58.1184C359.634 59.2922 360.823 60.3073 361.791 60.3867C362.743 60.466 363.092 59.5936 362.568 58.4198C362.045 57.246 360.839 56.2308 359.888 56.1515C358.936 56.0722 358.603 56.9446 359.11 58.1184Z" fill="#DEDEDE"/>
    <path d="M353.622 57.6424C354.13 58.8162 355.319 59.8314 356.271 59.9107C357.223 59.99 357.588 59.1176 357.064 57.9438C356.541 56.77 355.367 55.7707 354.415 55.6755C353.463 55.5962 353.099 56.4686 353.622 57.6424Z" fill="#DEDEDE"/>
    <path d="M348.102 57.1828C348.594 58.3566 349.783 59.3559 350.735 59.4353C351.703 59.5146 352.067 58.6263 351.56 57.4684C351.052 56.2946 349.878 55.2953 348.927 55.2159C347.975 55.1366 347.61 56.009 348.102 57.1828Z" fill="#DEDEDE"/>
    <path d="M342.598 56.7226C343.09 57.8805 344.248 58.8957 345.215 58.975C346.183 59.0543 346.563 58.166 346.056 57.0081C345.564 55.8502 344.39 54.835 343.439 54.7557C342.487 54.6922 342.106 55.5647 342.598 56.7226Z" fill="#DEDEDE"/>
    <path d="M337.078 56.2946C337.553 57.4525 338.711 58.4677 339.679 58.547C340.647 58.6263 341.027 57.738 340.551 56.5801C340.076 55.4222 338.902 54.407 337.95 54.3435C336.998 54.2484 336.602 55.1366 337.078 56.2946Z" fill="#DEDEDE"/>
    <path d="M331.542 55.8661C332.002 57.024 333.16 58.0392 334.112 58.1027C335.079 58.182 335.476 57.2937 335 56.1358C334.524 54.9778 333.382 53.9785 332.43 53.8992C331.494 53.8199 331.082 54.7082 331.542 55.8661Z" fill="#DEDEDE"/>
    <path d="M326.006 55.4535C326.45 56.6114 327.592 57.6107 328.56 57.69C329.527 57.7693 329.94 56.8811 329.48 55.7231C329.02 54.5652 327.878 53.5659 326.926 53.5024C325.974 53.4231 325.562 54.2955 326.006 55.4535Z" fill="#DEDEDE"/>
    <path d="M320.47 55.0572C320.899 56.2152 322.041 57.2145 323.008 57.2779C323.976 57.3414 324.404 56.4531 323.96 55.2952C323.516 54.1372 322.39 53.1379 321.422 53.0745C320.455 53.011 320.042 53.8993 320.47 55.0572Z" fill="#DEDEDE"/>
    <path d="M314.918 54.6764C315.347 55.8343 316.473 56.8337 317.44 56.8971C318.408 56.9605 318.836 56.0723 318.408 54.9143C317.98 53.7564 316.853 52.773 315.902 52.7095C314.934 52.6302 314.506 53.5185 314.918 54.6764Z" fill="#DEDEDE"/>
    <path d="M309.367 54.2955C309.779 55.4535 310.889 56.4369 311.857 56.5004C312.825 56.5638 313.269 55.6755 312.856 54.5176C312.444 53.3597 311.318 52.3762 310.366 52.3128C309.414 52.2652 308.97 53.1376 309.367 54.2955Z" fill="#DEDEDE"/>
    <path d="M303.815 53.9465C304.212 55.1045 305.322 56.0879 306.29 56.1514C307.258 56.2148 307.718 55.3265 307.305 54.1686C306.893 53.0107 305.798 52.0272 304.831 51.9638C303.879 51.9003 303.419 52.7886 303.815 53.9465Z" fill="#DEDEDE"/>
    <path d="M298.247 53.5976C298.628 54.7555 299.722 55.739 300.706 55.8024C301.673 55.8659 302.149 54.9776 301.753 53.8197C301.356 52.6617 300.262 51.6941 299.31 51.6307C298.326 51.5672 297.866 52.4397 298.247 53.5976Z" fill="#DEDEDE"/>
    <path d="M292.68 53.2648C293.045 54.4227 294.139 55.3903 295.107 55.4537C296.074 55.5172 296.55 54.6289 296.17 53.471C295.789 52.313 294.694 51.3455 293.743 51.282C292.791 51.2344 292.315 52.1068 292.68 53.2648Z" fill="#DEDEDE"/>
    <path d="M287.098 52.9474C287.462 54.0895 288.525 55.073 289.509 55.1364C290.476 55.184 290.968 54.2957 290.603 53.1536C290.238 52.0116 289.16 51.0281 288.192 50.9805C287.24 50.9171 286.749 51.7895 287.098 52.9474Z" fill="#DEDEDE"/>
    <path d="M281.529 52.646C281.878 53.7881 282.94 54.7715 283.908 54.8191C284.876 54.8667 285.383 53.9784 285.034 52.8363C284.685 51.6943 283.607 50.7108 282.655 50.6632C281.687 50.6156 281.18 51.4881 281.529 52.646Z" fill="#DEDEDE"/>
    <path d="M275.93 52.3445C276.263 53.4866 277.326 54.47 278.293 54.5176C279.261 54.5652 279.784 53.6769 279.435 52.5348C279.086 51.3928 278.04 50.4252 277.072 50.3776C276.12 50.3141 275.613 51.2024 275.93 52.3445Z" fill="#DEDEDE"/>
    <path d="M270.346 52.0749C270.663 53.217 271.71 54.1845 272.694 54.2321C273.661 54.2797 274.185 53.3914 273.867 52.2335C273.534 51.0914 272.487 50.1238 271.536 50.0763C270.552 50.0445 270.029 50.9328 270.346 52.0749Z" fill="#DEDEDE"/>
    <path d="M264.747 51.8054C265.048 52.9474 266.095 53.915 267.063 53.9626C268.046 54.0102 268.569 53.106 268.268 51.964C267.951 50.8219 266.92 49.8543 265.952 49.8067C264.985 49.775 264.445 50.6633 264.747 51.8054Z" fill="#DEDEDE"/>
    <path d="M259.147 51.5514C259.433 52.6934 260.464 53.661 261.447 53.7086C262.431 53.7562 262.97 52.852 262.669 51.71C262.367 50.5679 261.336 49.6003 260.369 49.5686C259.401 49.521 258.862 50.4093 259.147 51.5514Z" fill="#DEDEDE"/>
    <path d="M253.548 51.3137C253.818 52.4557 254.849 53.4233 255.817 53.455C256.8 53.5026 257.355 52.5985 257.07 51.4564C256.784 50.3143 255.769 49.3626 254.801 49.315C253.834 49.2833 253.263 50.1716 253.548 51.3137Z" fill="#DEDEDE"/>
    <path d="M247.932 51.0915C248.202 52.2336 249.201 53.1853 250.185 53.2329C251.168 53.2646 251.739 52.3763 251.454 51.2343C251.184 50.0922 250.169 49.1405 249.201 49.1088C248.25 49.0612 247.663 49.9494 247.932 51.0915Z" fill="#DEDEDE"/>
    <path d="M242.318 50.8856C242.572 52.0277 243.571 52.9794 244.539 53.027C245.522 53.0587 246.093 52.1704 245.839 51.0283C245.586 49.8863 244.586 48.9346 243.619 48.9028C242.651 48.8552 242.064 49.7435 242.318 50.8856Z" fill="#DEDEDE"/>
    <path d="M236.703 50.6947C236.941 51.8368 237.924 52.7885 238.908 52.8203C239.891 52.852 240.478 51.9478 240.24 50.8058C240.002 49.6637 239.003 48.712 238.035 48.6803C237.068 48.6644 236.465 49.5527 236.703 50.6947Z" fill="#DEDEDE"/>
    <path d="M231.088 50.5206C231.31 51.6626 232.278 52.6143 233.261 52.6461C234.245 52.6778 234.847 51.7737 234.609 50.6316C234.371 49.4895 233.404 48.5537 232.436 48.5219C231.469 48.4744 230.866 49.3785 231.088 50.5206Z" fill="#DEDEDE"/>
    <path d="M225.456 50.346C225.662 51.4881 226.63 52.4398 227.613 52.4556C228.597 52.4874 229.215 51.5832 228.993 50.4412C228.771 49.2991 227.82 48.3632 226.836 48.3315C225.869 48.3156 225.25 49.2039 225.456 50.346Z" fill="#DEDEDE"/>
    <path d="M219.826 50.1874C220.016 51.3295 220.968 52.2654 221.951 52.2971C222.935 52.3288 223.569 51.4247 223.363 50.2826C223.157 49.1405 222.205 48.2047 221.237 48.1729C220.27 48.1571 219.635 49.0612 219.826 50.1874Z" fill="#DEDEDE"/>
    <path d="M214.195 50.0606C214.369 51.2027 215.321 52.1385 216.305 52.1703C217.288 52.202 217.922 51.282 217.732 50.1558C217.542 49.0137 216.606 48.0778 215.622 48.062C214.655 48.0144 214.02 48.9185 214.195 50.0606Z" fill="#DEDEDE"/>
    <path d="M208.563 49.9336C208.722 51.0757 209.658 52.0116 210.641 52.0274C211.625 52.0433 212.275 51.1391 212.101 49.9971C211.926 48.855 210.99 47.9191 210.023 47.9033C209.055 47.8874 208.389 48.7916 208.563 49.9336Z" fill="#DEDEDE"/>
    <path d="M202.917 49.8229C203.075 50.965 203.995 51.9008 204.979 51.9167C205.962 51.9326 206.628 51.0284 206.47 49.8864C206.311 48.7443 205.391 47.8243 204.424 47.7926C203.44 47.7767 202.774 48.6808 202.917 49.8229Z" fill="#DEDEDE"/>
    <path d="M197.285 49.7116C197.428 50.8537 198.332 51.7895 199.316 51.8054C200.299 51.8212 200.981 50.9171 200.823 49.775C200.68 48.633 199.76 47.713 198.792 47.6971C197.825 47.6812 197.143 48.5854 197.285 49.7116Z" fill="#DEDEDE"/>
    <path d="M191.638 49.6324C191.765 50.7745 192.653 51.6945 193.637 51.7103C194.62 51.7262 195.302 50.8062 195.175 49.68C195.048 48.5379 194.144 47.6179 193.177 47.6021C192.209 47.6021 191.511 48.5062 191.638 49.6324Z" fill="#DEDEDE"/>
    <path d="M185.992 49.5686C186.103 50.7107 186.991 51.6307 187.975 51.6466C188.958 51.6624 189.656 50.7424 189.545 49.6162C189.418 48.4741 188.546 47.5542 187.562 47.5383C186.595 47.5224 185.881 48.4266 185.992 49.5686Z" fill="#DEDEDE"/>
    <path d="M180.345 49.5054C180.44 50.6316 181.313 51.5675 182.296 51.5834C183.279 51.5992 183.993 50.6792 183.882 49.5372C183.771 48.3951 182.899 47.4751 181.931 47.4751C180.964 47.4751 180.25 48.3792 180.345 49.5054Z" fill="#DEDEDE"/>
    <path d="M174.698 49.4734C174.778 50.5997 175.65 51.5355 176.633 51.5355C177.617 51.5355 178.347 50.6314 178.251 49.4893C178.156 48.3631 177.3 47.4272 176.316 47.4272C175.349 47.4272 174.619 48.3314 174.698 49.4734Z" fill="#DEDEDE"/>
    <path d="M169.051 49.442C169.115 50.5682 169.971 51.504 170.955 51.504C171.938 51.504 172.684 50.584 172.604 49.4578C172.525 48.3316 171.668 47.4116 170.701 47.3958C169.717 47.3958 168.972 48.3157 169.051 49.442Z" fill="#DEDEDE"/>
    <path d="M163.388 49.4263C163.435 50.5525 164.276 51.4884 165.26 51.4884C166.243 51.4884 166.989 50.5684 166.925 49.4422C166.862 48.3159 166.021 47.396 165.053 47.396C164.086 47.3801 163.34 48.3001 163.388 49.4263Z" fill="#DEDEDE"/>
    <path d="M157.742 49.4261C157.774 50.5523 158.614 51.4882 159.598 51.4882C160.581 51.4882 161.343 50.5682 161.295 49.4261C161.247 48.2999 160.423 47.3799 159.439 47.3799C158.456 47.3799 157.694 48.2999 157.742 49.4261Z" fill="#DEDEDE"/>
    <path d="M152.078 49.442C152.11 50.5682 152.919 51.4882 153.902 51.4882C154.886 51.4882 155.663 50.5682 155.632 49.4261C155.6 48.2999 154.775 47.3799 153.807 47.3799C152.824 47.3957 152.063 48.3157 152.078 49.442Z" fill="#DEDEDE"/>
    <path d="M146.432 49.4737C146.448 50.5999 147.241 51.5199 148.24 51.5199C149.224 51.5199 150.017 50.584 149.985 49.4578C149.969 48.3316 149.16 47.4116 148.177 47.4274C147.193 47.4274 146.416 48.3474 146.432 49.4737Z" fill="#DEDEDE"/>
    <path d="M140.769 49.5215C140.769 50.6477 141.562 51.5677 142.545 51.5677C143.529 51.5677 144.322 50.6318 144.322 49.4898C144.322 48.3636 143.513 47.4436 142.545 47.4594C141.562 47.4594 140.769 48.3953 140.769 49.5215Z" fill="#DEDEDE"/>
    <path d="M135.106 49.5845C135.09 50.7107 135.868 51.6307 136.851 51.6148C137.834 51.599 138.643 50.679 138.659 49.5369C138.675 48.4107 137.882 47.4907 136.914 47.5065C135.931 47.5224 135.122 48.4424 135.106 49.5845Z" fill="#DEDEDE"/>
    <path d="M67.2016 51.3773C67.0112 52.5194 67.6615 53.4077 68.645 53.376C69.6284 53.3284 70.5802 52.3767 70.7546 51.2346C70.9291 50.0925 70.2946 49.2042 69.3112 49.2518C68.3277 49.2836 67.3919 50.2353 67.2016 51.3773Z" fill="#DEDEDE"/>
    <path d="M61.5379 51.615C61.3317 52.7571 61.9662 53.6454 62.9496 53.6136C63.9331 53.566 64.8848 52.6143 65.091 51.4723C65.2814 50.3302 64.6469 49.4419 63.6793 49.4895C62.6959 49.5212 61.7441 50.4888 61.5379 51.615Z" fill="#DEDEDE"/>
    <path d="M10.7632 54.4229C10.43 55.5809 10.9694 56.4533 11.9528 56.3898C12.9363 56.3264 13.9832 55.3429 14.3004 54.185C14.6177 53.0271 14.0783 52.1546 13.1108 52.2181C12.1432 52.2974 11.0804 53.2809 10.7632 54.4229Z" fill="#DEDEDE"/>
    <path d="M5.1323 54.8193C4.79919 55.9772 5.3068 56.8496 6.29025 56.7862C7.27369 56.7227 8.33645 55.7234 8.66956 54.5814C9.00266 53.4234 8.47919 52.551 7.51161 52.6145C6.52816 52.6779 5.48126 53.6614 5.1323 54.8193Z" fill="#DEDEDE"/>
    <path d="M-0.482199 55.1998C-0.831164 56.3577 -0.323576 57.2301 0.659872 57.1667C1.64332 57.1032 2.70607 56.1039 3.05504 54.946C3.404 53.788 2.89641 52.9156 1.91297 52.9791C0.929517 53.0584 -0.133233 54.0577 -0.482199 55.1998Z" fill="#DEDEDE"/>
    <path d="M372.434 53.0108C372.989 54.1688 374.195 55.1998 375.146 55.2791C376.082 55.3743 376.4 54.5019 375.828 53.3281C375.273 52.1702 374.052 51.155 373.116 51.0598C372.196 50.9805 371.879 51.8529 372.434 53.0108Z" fill="#DEDEDE"/>
    <path d="M367.026 52.5193C367.565 53.6772 368.771 54.6924 369.722 54.7876C370.658 54.8827 370.991 53.9945 370.436 52.8365C369.881 51.6786 368.691 50.6634 367.755 50.5841C366.804 50.489 366.486 51.3455 367.026 52.5193Z" fill="#DEDEDE"/>
    <path d="M361.6 52.0275C362.124 53.1854 363.329 54.2006 364.265 54.2799C365.217 54.3592 365.534 53.4868 364.995 52.3288C364.455 51.1709 363.266 50.1557 362.33 50.0764C361.41 49.9971 361.077 50.8695 361.6 52.0275Z" fill="#DEDEDE"/>
    <path d="M356.176 51.5359C356.699 52.6938 357.889 53.709 358.824 53.7883C359.776 53.8676 360.109 52.9952 359.586 51.8373C359.062 50.6793 357.873 49.68 356.937 49.6007C356.001 49.5214 355.652 50.3938 356.176 51.5359Z" fill="#DEDEDE"/>
    <path d="M350.751 51.0759C351.259 52.2338 352.433 53.2332 353.384 53.3125C354.336 53.3918 354.685 52.5194 354.162 51.3614C353.654 50.2035 352.464 49.2042 351.528 49.1249C350.593 49.0456 350.244 49.918 350.751 51.0759Z" fill="#DEDEDE"/>
    <path d="M345.31 50.6317C345.802 51.7897 346.976 52.789 347.927 52.8683C348.879 52.9476 349.228 52.0752 348.736 50.9172C348.229 49.7593 347.071 48.76 346.119 48.6966C345.167 48.6014 344.803 49.4738 345.31 50.6317Z" fill="#DEDEDE"/>
    <path d="M339.854 50.1874C340.33 51.3453 341.504 52.3446 342.44 52.4081C343.391 52.4874 343.756 51.615 343.264 50.457C342.773 49.2991 341.615 48.3156 340.679 48.2363C339.743 48.157 339.378 49.0294 339.854 50.1874Z" fill="#DEDEDE"/>
    <path d="M334.413 49.7591C334.889 50.9012 336.031 51.9005 336.983 51.9798C337.934 52.0591 338.315 51.1708 337.839 50.0288C337.363 48.8708 336.205 47.8874 335.269 47.8239C334.318 47.7446 333.937 48.6012 334.413 49.7591Z" fill="#DEDEDE"/>
    <path d="M328.957 49.3467C329.417 50.4887 330.559 51.488 331.511 51.5515C332.462 51.6308 332.859 50.7425 332.383 49.6005C331.923 48.4584 330.781 47.4591 329.829 47.3956C328.878 47.3163 328.497 48.1887 328.957 49.3467Z" fill="#DEDEDE"/>
    <path d="M323.484 48.9344C323.928 50.0765 325.07 51.0758 326.022 51.1392C326.974 51.2027 327.37 50.3303 326.926 49.1882C326.466 48.0461 325.34 47.0627 324.388 46.9834C323.436 46.9199 323.04 47.7923 323.484 48.9344Z" fill="#DEDEDE"/>
    <path d="M318.012 48.5537C318.44 49.6958 319.566 50.6792 320.518 50.7585C321.469 50.822 321.882 49.9496 321.438 48.8075C320.994 47.6654 319.867 46.682 318.932 46.6185C317.996 46.5392 317.583 47.4116 318.012 48.5537Z" fill="#DEDEDE"/>
    <path d="M312.539 48.173C312.968 49.3151 314.078 50.2986 315.03 50.362C315.981 50.4255 316.41 49.553 315.981 48.411C315.553 47.2689 314.443 46.2855 313.491 46.222C312.539 46.1586 312.127 47.031 312.539 48.173Z" fill="#DEDEDE"/>
    <path d="M307.067 47.8081C307.479 48.9502 308.574 49.9336 309.541 49.9971C310.493 50.0605 310.937 49.1881 310.509 48.046C310.097 46.904 308.986 45.9364 308.035 45.8729C307.083 45.7936 306.655 46.666 307.067 47.8081Z" fill="#DEDEDE"/>
    <path d="M301.579 47.4593C301.976 48.6014 303.07 49.569 304.022 49.6324C304.974 49.6958 305.418 48.8234 305.021 47.6814C304.609 46.5393 303.514 45.5717 302.578 45.5083C301.627 45.4448 301.183 46.3172 301.579 47.4593Z" fill="#DEDEDE"/>
    <path d="M296.075 47.126C296.456 48.2681 297.55 49.2357 298.502 49.2991C299.454 49.3626 299.914 48.4743 299.533 47.3322C299.136 46.1902 298.058 45.2226 297.106 45.175C296.154 45.1116 295.694 45.984 296.075 47.126Z" fill="#DEDEDE"/>
    <path d="M290.586 46.7927C290.951 47.9348 292.03 48.9023 292.997 48.9499C293.949 49.0134 294.425 48.1251 294.044 46.983C293.664 45.841 292.585 44.8892 291.633 44.8258C290.681 44.7941 290.221 45.6665 290.586 46.7927Z" fill="#DEDEDE"/>
    <path d="M285.083 46.4918C285.432 47.6338 286.51 48.5856 287.462 48.649C288.414 48.6966 288.905 47.8242 288.54 46.6821C288.176 45.54 287.113 44.5883 286.161 44.5407C285.209 44.4773 284.734 45.3497 285.083 46.4918Z" fill="#DEDEDE"/>
    <path d="M279.578 46.1903C279.927 47.3165 280.973 48.2841 281.941 48.3316C282.909 48.3792 283.4 47.5068 283.036 46.3647C282.687 45.2227 281.624 44.271 280.672 44.2234C279.72 44.1916 279.229 45.0641 279.578 46.1903Z" fill="#DEDEDE"/>
    <path d="M274.058 45.9046C274.391 47.0308 275.438 47.9984 276.39 48.046C277.357 48.0936 277.849 47.2212 277.516 46.0791C277.183 44.9529 276.136 43.9853 275.184 43.9377C274.248 43.906 273.741 44.7784 274.058 45.9046Z" fill="#DEDEDE"/>
    <path d="M268.554 45.6351C268.871 46.7613 269.902 47.7289 270.87 47.7765C271.837 47.8241 272.345 46.9358 272.028 45.8096C271.694 44.6834 270.663 43.7317 269.712 43.6841C268.744 43.6365 268.237 44.5089 268.554 45.6351Z" fill="#DEDEDE"/>
    <path d="M263.033 45.3813C263.335 46.5075 264.366 47.4593 265.318 47.5068C266.285 47.5544 266.809 46.6661 266.491 45.5399C266.174 44.4137 265.159 43.462 264.207 43.4144C263.255 43.3668 262.732 44.2551 263.033 45.3813Z" fill="#DEDEDE"/>
    <path d="M257.515 45.1433C257.8 46.2695 258.815 47.2212 259.783 47.2688C260.751 47.3164 261.29 46.4281 260.989 45.3019C260.687 44.1757 259.672 43.2239 258.72 43.1922C257.753 43.1288 257.213 44.0012 257.515 45.1433Z" fill="#DEDEDE"/>
    <path d="M251.977 44.9054C252.247 46.0316 253.262 46.9833 254.23 47.015C255.197 47.0626 255.737 46.1743 255.451 45.0481C255.166 43.9219 254.166 42.9702 253.215 42.9385C252.247 42.9067 251.708 43.7792 251.977 44.9054Z" fill="#DEDEDE"/>
    <path d="M246.442 44.683C246.712 45.8093 247.695 46.761 248.663 46.7927C249.631 46.8244 250.186 45.9362 249.916 44.8099C249.646 43.6837 248.647 42.7479 247.695 42.7003C246.744 42.6844 246.188 43.5568 246.442 44.683Z" fill="#DEDEDE"/>
    <path d="M240.906 44.4769C241.16 45.6031 242.143 46.5389 243.111 46.5865C244.078 46.6183 244.649 45.73 244.396 44.6038C244.142 43.4776 243.158 42.5417 242.191 42.51C241.239 42.4783 240.668 43.3665 240.906 44.4769Z" fill="#DEDEDE"/>
    <path d="M235.371 44.3026C235.609 45.4288 236.576 46.3646 237.544 46.3963C238.511 46.4281 239.098 45.5398 238.845 44.4136C238.607 43.2874 237.623 42.3515 236.672 42.3198C235.72 42.2881 235.133 43.1763 235.371 44.3026Z" fill="#DEDEDE"/>
    <path d="M229.834 44.1121C230.057 45.2383 231.024 46.1742 231.992 46.2059C232.959 46.2376 233.562 45.3494 233.324 44.2232C233.086 43.097 232.134 42.1611 231.167 42.1452C230.199 42.1135 229.612 43.0018 229.834 44.1121Z" fill="#DEDEDE"/>
    <path d="M224.283 43.9539C224.489 45.0801 225.441 46.016 226.409 46.0477C227.376 46.0795 227.979 45.1912 227.773 44.065C227.551 42.9388 226.599 42.0188 225.647 41.987C224.68 41.9553 224.077 42.8277 224.283 43.9539Z" fill="#DEDEDE"/>
    <path d="M218.732 43.8109C218.922 44.9371 219.874 45.8729 220.841 45.8888C221.809 45.9205 222.427 45.0164 222.221 43.906C222.015 42.7798 221.079 41.8598 220.127 41.8281C219.16 41.7964 218.541 42.6846 218.732 43.8109Z" fill="#DEDEDE"/>
    <path d="M213.179 43.6682C213.354 44.7944 214.29 45.7144 215.257 45.7461C216.225 45.7619 216.859 44.8737 216.669 43.7475C216.479 42.6213 215.543 41.7013 214.591 41.6854C213.639 41.6695 213.005 42.5419 213.179 43.6682Z" fill="#DEDEDE"/>
    <path d="M207.627 43.5411C207.786 44.6673 208.706 45.5873 209.673 45.619C210.641 45.6349 211.291 44.7466 211.117 43.6204C210.942 42.4942 210.022 41.5742 209.07 41.5583C208.103 41.5425 207.452 42.4307 207.627 43.5411Z" fill="#DEDEDE"/>
    <path d="M202.06 43.4462C202.219 44.5724 203.123 45.4924 204.091 45.5083C205.058 45.5241 205.708 44.6359 205.55 43.5097C205.391 42.3835 204.487 41.4635 203.519 41.4476C202.568 41.4317 201.917 42.32 202.06 43.4462Z" fill="#DEDEDE"/>
    <path d="M196.508 43.3509C196.651 44.4771 197.539 45.3971 198.507 45.413C199.474 45.4288 200.14 44.5247 199.998 43.4143C199.855 42.2881 198.951 41.384 197.999 41.3681C197.031 41.3364 196.365 42.2247 196.508 43.3509Z" fill="#DEDEDE"/>
    <path d="M190.94 43.2714C191.067 44.3976 191.956 45.3176 192.923 45.3335C193.891 45.3494 194.573 44.4452 194.446 43.3349C194.319 42.2087 193.431 41.3045 192.463 41.2887C191.496 41.2569 190.814 42.1452 190.94 43.2714Z" fill="#DEDEDE"/>
    <path d="M185.373 43.1925C185.484 44.3187 186.357 45.2387 187.324 45.2387C188.292 45.2545 188.99 44.3504 188.863 43.2242C188.736 42.098 187.864 41.1938 186.912 41.178C185.96 41.178 185.262 42.0821 185.373 43.1925Z" fill="#DEDEDE"/>
    <path d="M179.805 43.1445C179.901 44.2707 180.757 45.1748 181.741 45.1907C182.708 45.2065 183.422 44.3024 183.311 43.1762C183.2 42.05 182.343 41.1458 181.376 41.1458C180.424 41.13 179.71 42.0183 179.805 43.1445Z" fill="#DEDEDE"/>
    <path d="M174.238 43.0972C174.317 44.2076 175.174 45.1276 176.141 45.1434C177.109 45.1434 177.823 44.2393 177.743 43.1289C177.648 42.0027 176.808 41.0986 175.84 41.0986C174.872 41.0827 174.159 41.9869 174.238 43.0972Z" fill="#DEDEDE"/>
    <path d="M168.67 43.0813C168.734 44.1916 169.574 45.1116 170.542 45.1116C171.51 45.1116 172.239 44.2075 172.16 43.0971C172.081 41.9868 171.24 41.0668 170.288 41.0668C169.337 41.0668 168.607 41.955 168.67 43.0813Z" fill="#DEDEDE"/>
    <path d="M163.103 43.0653C163.151 44.1756 163.992 45.0956 164.959 45.0956C165.927 45.0956 166.672 44.1915 166.609 43.0653C166.545 41.9549 165.721 41.0508 164.753 41.0349C163.785 41.0508 163.04 41.9549 163.103 43.0653Z" fill="#DEDEDE"/>
    <path d="M157.519 43.0651C157.551 44.1755 158.376 45.0955 159.343 45.0955C160.311 45.0955 161.056 44.1913 161.025 43.0651C160.977 41.9548 160.152 41.0507 159.2 41.0507C158.233 41.0507 157.487 41.9548 157.519 43.0651Z" fill="#DEDEDE"/>
    <path d="M151.952 43.081C151.968 44.1913 152.776 45.1113 153.76 45.0955C154.727 45.0955 155.489 44.1755 155.457 43.0651C155.425 41.9389 154.616 41.0507 153.649 41.0507C152.681 41.0665 151.92 41.9707 151.952 43.081Z" fill="#DEDEDE"/>
    <path d="M146.369 43.1129C146.385 44.2232 147.178 45.1432 148.145 45.1273C149.113 45.1273 149.89 44.2073 149.874 43.097C149.858 41.9867 149.065 41.0825 148.098 41.0825C147.13 41.0825 146.369 42.0025 146.369 43.1129Z" fill="#DEDEDE"/>
    <path d="M84.9978 44.3503C84.855 45.4765 85.5212 46.3647 86.4888 46.333C87.4564 46.3013 88.3605 45.3654 88.5033 44.2392C88.646 43.113 87.964 42.2406 87.0122 42.2723C86.0447 42.304 85.1405 43.224 84.9978 44.3503Z" fill="#DEDEDE"/>
    <path d="M79.4139 44.5403C79.2553 45.6665 79.9056 46.5548 80.8891 46.5072C81.8567 46.4755 82.7767 45.5238 82.9194 44.4134C83.0781 43.2872 82.4119 42.4148 81.4443 42.4465C80.4926 42.4941 79.5726 43.43 79.4139 44.5403Z" fill="#DEDEDE"/>
    <path d="M73.8467 44.7625C73.6722 45.8887 74.3225 46.777 75.2901 46.7294C76.2577 46.6976 77.1777 45.7459 77.3522 44.6197C77.5108 43.4935 76.8764 42.6211 75.9088 42.6528C74.9412 42.7004 74.0212 43.6363 73.8467 44.7625Z" fill="#DEDEDE"/>
    <path d="M68.2637 44.9844C68.0734 46.1106 68.7079 46.9989 69.6755 46.9513C70.6431 46.9196 71.5789 45.9678 71.7534 44.8416C71.9279 43.7154 71.2934 42.843 70.3416 42.8747C69.3899 42.9223 68.4541 43.8582 68.2637 44.9844Z" fill="#DEDEDE"/>
    <path d="M62.6954 45.2225C62.4892 46.3487 63.1078 47.237 64.0912 47.1894C65.0588 47.1418 66.0106 46.1901 66.2009 45.0797C66.3913 43.9535 65.7726 43.0811 64.8051 43.1287C63.8375 43.1446 62.8857 44.0963 62.6954 45.2225Z" fill="#DEDEDE"/>
    <path d="M23.7223 47.253C23.4209 48.3792 23.9602 49.2675 24.9278 49.2041C25.8953 49.1406 26.9105 48.173 27.2119 47.0468C27.4974 45.9206 26.9581 45.0482 25.9905 45.1117C25.0388 45.1592 24.0078 46.1268 23.7223 47.253Z" fill="#DEDEDE"/>
    <path d="M18.155 47.6018C17.8378 48.7439 18.3771 49.6163 19.3447 49.5529C20.3123 49.4894 21.3433 48.5218 21.6447 47.3798C21.946 46.2377 21.4226 45.3811 20.455 45.4446C19.5033 45.508 18.4723 46.4756 18.155 47.6018Z" fill="#DEDEDE"/>
    <path d="M12.6034 47.9671C12.2702 49.1091 12.7937 49.9816 13.7613 49.9181C14.7289 49.8547 15.7758 48.8871 16.093 47.745C16.4102 46.6029 15.8868 45.7464 14.9351 45.8098C13.9675 45.8574 12.9206 46.825 12.6034 47.9671Z" fill="#DEDEDE"/>
    <path d="M7.0509 48.3317C6.71779 49.4738 7.22538 50.3462 8.19297 50.2827C9.16055 50.2193 10.2074 49.2358 10.5405 48.0938C10.8737 46.9517 10.3661 46.0951 9.39848 46.1586C8.44675 46.222 7.384 47.2055 7.0509 48.3317Z" fill="#DEDEDE"/>
    <path d="M1.50033 48.7282C1.15136 49.8702 1.64308 50.7427 2.61067 50.6792C3.57825 50.6158 4.64101 49.6323 4.98998 48.4902C5.33894 47.3482 4.83136 46.4916 3.87964 46.5551C2.91205 46.6185 1.84929 47.5861 1.50033 48.7282Z" fill="#DEDEDE"/>
    <path d="M374.656 46.9835C375.211 48.1414 376.432 49.1407 377.352 49.2359C378.288 49.3311 378.574 48.4586 378.003 47.3007C377.431 46.1428 376.226 45.1435 375.306 45.0483C374.386 44.969 374.085 45.8255 374.656 46.9835Z" fill="#DEDEDE"/>
    <path d="M369.309 46.4758C369.865 47.6338 371.054 48.6331 371.99 48.7283C372.926 48.8076 373.227 47.951 372.656 46.7931C372.101 45.6352 370.896 44.6358 369.976 44.5565C369.056 44.4614 368.754 45.3179 369.309 46.4758Z" fill="#DEDEDE"/>
    <path d="M363.964 45.9841C364.504 47.1262 365.693 48.1414 366.629 48.2207C367.565 48.3 367.866 47.4434 367.327 46.2855C366.772 45.1276 365.582 44.1441 364.662 44.049C363.742 43.9696 363.425 44.8421 363.964 45.9841Z" fill="#DEDEDE"/>
    <path d="M358.603 45.5082C359.127 46.6503 360.316 47.6496 361.252 47.7447C362.188 47.8241 362.505 46.9675 361.966 45.8096C361.427 44.6675 360.253 43.6682 359.317 43.5889C358.397 43.4937 358.08 44.3503 358.603 45.5082Z" fill="#DEDEDE"/>
    <path d="M353.242 45.0321C353.765 46.1742 354.939 47.1735 355.875 47.2528C356.811 47.3321 357.144 46.4756 356.62 45.3176C356.097 44.1756 354.923 43.1762 353.987 43.0969C353.067 43.0335 352.734 43.89 353.242 45.0321Z" fill="#DEDEDE"/>
    <path d="M347.88 44.5881C348.387 45.7302 349.545 46.7295 350.481 46.8088C351.417 46.8881 351.75 46.0157 351.242 44.8736C350.735 43.7315 349.561 42.7481 348.641 42.6688C347.721 42.5895 347.372 43.446 347.88 44.5881Z" fill="#DEDEDE"/>
    <path d="M342.503 44.144C342.995 45.2861 344.153 46.2696 345.089 46.3489C346.024 46.4282 346.373 45.5558 345.882 44.4137C345.374 43.2716 344.216 42.2882 343.296 42.2089C342.376 42.1454 342.011 43.002 342.503 44.144Z" fill="#DEDEDE"/>
    <path d="M337.125 43.7157C337.601 44.8577 338.759 45.8412 339.695 45.9205C340.631 45.9998 340.995 45.1274 340.504 43.9853C340.012 42.8432 338.87 41.8598 337.934 41.7964C337.014 41.717 336.649 42.5736 337.125 43.7157Z" fill="#DEDEDE"/>
    <path d="M331.749 43.3031C332.225 44.4452 333.367 45.4286 334.302 45.4921C335.238 45.5714 335.619 44.699 335.143 43.5569C334.667 42.4148 333.525 41.4472 332.589 41.3679C331.654 41.3045 331.273 42.161 331.749 43.3031Z" fill="#DEDEDE"/>
    <path d="M326.355 42.8909C326.815 44.0171 327.941 45.0005 328.877 45.0798C329.813 45.1433 330.194 44.2867 329.734 43.1447C329.274 42.0026 328.131 41.035 327.211 40.9716C326.291 40.8923 325.895 41.7647 326.355 42.8909Z" fill="#DEDEDE"/>
    <path d="M320.962 42.4946C321.406 43.6208 322.533 44.6042 323.468 44.6677C324.404 44.7311 324.801 43.8746 324.357 42.7325C323.897 41.6063 322.786 40.6228 321.851 40.5594C320.915 40.5118 320.518 41.3684 320.962 42.4946Z" fill="#DEDEDE"/>
    <path d="M315.569 42.1138C315.997 43.24 317.108 44.2234 318.059 44.2869C318.995 44.3503 319.408 43.4779 318.964 42.3517C318.519 41.2255 317.409 40.2579 316.473 40.1945C315.537 40.131 315.141 40.9876 315.569 42.1138Z" fill="#DEDEDE"/>
    <path d="M310.16 41.7486C310.573 42.8748 311.683 43.8424 312.619 43.9058C313.555 43.9693 313.983 43.0969 313.555 41.9707C313.126 40.8444 312.016 39.8769 311.096 39.8134C310.16 39.7658 309.748 40.6224 310.16 41.7486Z" fill="#DEDEDE"/>
    <path d="M304.75 41.3999C305.163 42.5261 306.257 43.4937 307.193 43.5572C308.129 43.6206 308.557 42.7482 308.145 41.622C307.732 40.4958 306.638 39.5282 305.702 39.4806C304.766 39.4172 304.354 40.2737 304.75 41.3999Z" fill="#DEDEDE"/>
    <path d="M299.343 41.0668C299.739 42.1931 300.818 43.1606 301.769 43.2082C302.705 43.2717 303.149 42.3993 302.753 41.2731C302.356 40.1468 301.262 39.1951 300.326 39.1317C299.39 39.0682 298.946 39.9406 299.343 41.0668Z" fill="#DEDEDE"/>
    <path d="M293.917 40.7337C294.298 41.8599 295.377 42.8116 296.312 42.875C297.264 42.9385 297.708 42.0661 297.312 40.9399C296.915 39.8137 295.852 38.8619 294.917 38.8143C293.997 38.7509 293.537 39.6074 293.917 40.7337Z" fill="#DEDEDE"/>
    <path d="M288.492 40.4164C288.857 41.5426 289.92 42.4944 290.872 42.542C291.823 42.5895 292.283 41.733 291.903 40.6068C291.522 39.4806 290.459 38.5288 289.523 38.4813C288.587 38.4337 288.127 39.2902 288.492 40.4164Z" fill="#DEDEDE"/>
    <path d="M283.067 40.115C283.416 41.2413 284.479 42.193 285.43 42.2406C286.382 42.2881 286.842 41.4157 286.477 40.3054C286.112 39.1792 285.05 38.2433 284.13 38.1799C283.178 38.1323 282.718 38.9888 283.067 40.115Z" fill="#DEDEDE"/>
    <path d="M277.643 39.8296C277.992 40.9399 279.023 41.8917 279.974 41.9551C280.926 42.0027 281.402 41.1303 281.053 40.0199C280.704 38.8937 279.657 37.9579 278.721 37.9103C277.785 37.8468 277.294 38.7034 277.643 39.8296Z" fill="#DEDEDE"/>
    <path d="M272.203 39.5441C272.536 40.6544 273.567 41.6061 274.519 41.6537C275.47 41.7013 275.962 40.8289 275.629 39.7185C275.296 38.6082 274.265 37.6565 273.313 37.6089C272.361 37.5772 271.87 38.4337 272.203 39.5441Z" fill="#DEDEDE"/>
    <path d="M266.762 39.2902C267.079 40.4005 268.11 41.3522 269.046 41.3998C269.997 41.4474 270.505 40.575 270.172 39.4647C269.855 38.3543 268.824 37.4184 267.888 37.3709C266.952 37.3074 266.444 38.164 266.762 39.2902Z" fill="#DEDEDE"/>
    <path d="M261.32 39.0363C261.622 40.1467 262.637 41.0984 263.588 41.1301C264.54 41.1777 265.048 40.3053 264.746 39.1791C264.429 38.0688 263.414 37.1329 262.478 37.0853C261.526 37.0536 261.019 37.926 261.32 39.0363Z" fill="#DEDEDE"/>
    <path d="M255.864 38.7985C256.15 39.9088 257.149 40.8447 258.101 40.8923C259.052 40.9399 259.576 40.0674 259.274 38.9412C258.973 37.8309 257.974 36.895 257.038 36.8633C256.102 36.8157 255.579 37.6881 255.864 38.7985Z" fill="#DEDEDE"/>
    <path d="M250.424 38.5766C250.693 39.6869 251.693 40.6228 252.644 40.6704C253.596 40.7021 254.135 39.8297 253.85 38.7193C253.564 37.609 252.565 36.6731 251.629 36.6414C250.677 36.5938 250.138 37.4662 250.424 38.5766Z" fill="#DEDEDE"/>
    <path d="M244.968 38.3703C245.221 39.4807 246.205 40.4165 247.157 40.4483C248.108 40.48 248.663 39.6076 248.394 38.4972C248.124 37.3869 247.141 36.4669 246.205 36.4193C245.253 36.3876 244.698 37.26 244.968 38.3703Z" fill="#DEDEDE"/>
    <path d="M239.51 38.1639C239.764 39.2743 240.732 40.2101 241.683 40.2419C242.635 40.2736 243.19 39.4012 242.936 38.2908C242.683 37.1805 241.715 36.2605 240.763 36.2288C239.812 36.197 239.256 37.0536 239.51 38.1639Z" fill="#DEDEDE"/>
    <path d="M234.038 37.9897C234.276 39.1001 235.228 40.0201 236.18 40.0518C237.131 40.0835 237.702 39.2111 237.464 38.1007C237.226 36.9904 236.259 36.0704 235.323 36.0387C234.387 36.007 233.816 36.8794 234.038 37.9897Z" fill="#DEDEDE"/>
    <path d="M228.581 37.815C228.803 38.9254 229.755 39.8454 230.707 39.8771C231.658 39.9088 232.245 39.0205 232.007 37.9261C231.785 36.8157 230.834 35.8957 229.882 35.864C228.946 35.8323 228.359 36.7047 228.581 37.815Z" fill="#DEDEDE"/>
    <path d="M223.109 37.6561C223.315 38.7664 224.251 39.6864 225.203 39.7182C226.154 39.7499 226.757 38.8616 226.535 37.7513C226.313 36.6409 225.377 35.7209 224.441 35.7051C223.505 35.6733 222.903 36.5458 223.109 37.6561Z" fill="#DEDEDE"/>
    <path d="M217.637 37.514C217.827 38.6243 218.763 39.5443 219.715 39.5602C220.667 39.5919 221.285 38.7036 221.079 37.5933C220.873 36.483 219.953 35.5788 219.001 35.5471C218.05 35.5312 217.447 36.4036 217.637 37.514Z" fill="#DEDEDE"/>
    <path d="M201.203 37.1485C201.346 38.2588 202.25 39.163 203.202 39.1788C204.154 39.1947 204.804 38.3064 204.645 37.2119C204.487 36.1016 203.583 35.1975 202.647 35.1816C201.711 35.1657 201.06 36.054 201.203 37.1485Z" fill="#DEDEDE"/>
    <path d="M195.732 37.0694C195.874 38.1798 196.747 39.0839 197.714 39.0998C198.666 39.1156 199.332 38.2274 199.174 37.1329C199.031 36.0225 198.143 35.1184 197.191 35.1184C196.255 35.0867 195.589 35.9591 195.732 37.0694Z" fill="#DEDEDE"/>
    <path d="M190.243 36.9903C190.37 38.1006 191.243 39.0048 192.194 39.0206C193.146 39.0365 193.812 38.1482 193.685 37.0379C193.558 35.9275 192.686 35.0393 191.734 35.0234C190.798 35.0075 190.116 35.88 190.243 36.9903Z" fill="#DEDEDE"/>
    <path d="M184.755 36.9269C184.866 38.0372 185.722 38.9413 186.69 38.9413C187.642 38.9572 188.324 38.0689 188.213 36.9586C188.102 35.8482 187.229 34.96 186.277 34.9441C185.326 34.9441 184.659 35.8165 184.755 36.9269Z" fill="#DEDEDE"/>
    <path d="M179.267 36.8634C179.362 37.9579 180.218 38.862 181.17 38.8779C182.122 38.8937 182.82 37.9896 182.709 36.8951C182.598 35.7847 181.757 34.8965 180.805 34.8806C179.869 34.8806 179.187 35.7689 179.267 36.8634Z" fill="#DEDEDE"/>
    <path d="M173.778 36.8315C173.858 37.926 174.699 38.8301 175.65 38.846C176.602 38.846 177.316 37.9577 177.221 36.8632C177.125 35.7687 176.285 34.8646 175.349 34.8646C174.413 34.8487 173.699 35.7211 173.778 36.8315Z" fill="#DEDEDE"/>
    <path d="M168.29 36.7999C168.354 37.8944 169.178 38.7985 170.146 38.7985C171.098 38.7985 171.811 37.9102 171.748 36.7999C171.669 35.7054 170.844 34.8013 169.892 34.8013C168.94 34.8171 168.227 35.7054 168.29 36.7999Z" fill="#DEDEDE"/>
    <path d="M162.801 36.7999C162.849 37.8944 163.673 38.7985 164.625 38.7985C165.577 38.7985 166.307 37.9102 166.243 36.7999C166.18 35.7054 165.371 34.8013 164.419 34.8013C163.483 34.8013 162.753 35.6895 162.801 36.7999Z" fill="#DEDEDE"/>
    <path d="M157.313 36.7997C157.345 37.8942 158.154 38.7984 159.121 38.7984C160.073 38.7984 160.819 37.8942 160.771 36.7997C160.724 35.7053 159.915 34.817 158.963 34.817C158.011 34.8011 157.266 35.6894 157.313 36.7997Z" fill="#DEDEDE"/>
    <path d="M47.3567 39.6708C47.1187 40.7811 47.6898 41.6535 48.6415 41.6059C49.5932 41.5583 50.5608 40.6066 50.7987 39.4963C51.0366 38.3859 50.4497 37.5294 49.5139 37.577C48.5622 37.6245 47.5946 38.5604 47.3567 39.6708Z" fill="#DEDEDE"/>
    <path d="M41.869 39.9562C41.6152 41.0665 42.1703 41.9389 43.1379 41.8913C44.0896 41.8437 45.0731 40.892 45.3269 39.7817C45.5807 38.6713 45.0096 37.8148 44.0579 37.8624C43.1062 37.91 42.1228 38.8458 41.869 39.9562Z" fill="#DEDEDE"/>
    <path d="M36.3816 40.2578C36.1119 41.3681 36.6671 42.2405 37.6188 42.193C38.5706 42.1454 39.5699 41.1936 39.8237 40.0674C40.0775 38.9571 39.5223 38.1005 38.5864 38.1481C37.6347 38.1957 36.6513 39.1474 36.3816 40.2578Z" fill="#DEDEDE"/>
    <path d="M30.8927 40.5752C30.6072 41.6855 31.1465 42.5579 32.1141 42.4945C33.0658 42.4469 34.0651 41.4793 34.3506 40.369C34.6203 39.2586 34.081 38.4021 33.1293 38.4496C32.1776 38.4972 31.1782 39.449 30.8927 40.5752Z" fill="#DEDEDE"/>
    <path d="M25.405 40.8924C25.1036 42.0186 25.6429 42.8752 26.5946 42.8117C27.5464 42.7483 28.5615 41.7966 28.847 40.6862C29.1325 39.5759 28.6091 38.7193 27.6574 38.7669C26.7056 38.8304 25.7064 39.7821 25.405 40.8924Z" fill="#DEDEDE"/>
    <path d="M19.9165 41.2253C19.5993 42.3515 20.1227 43.2081 21.0744 43.1446C22.0261 43.0812 23.0572 42.1295 23.3586 41.0033C23.6599 39.8771 23.1365 39.0364 22.1848 39.084C21.2489 39.1633 20.2337 40.115 19.9165 41.2253Z" fill="#DEDEDE"/>
    <path d="M14.4435 41.5904C14.1263 42.7166 14.6339 43.5731 15.5856 43.5097C16.5373 43.4462 17.5684 42.4786 17.8856 41.3683C18.2028 40.2421 17.6953 39.3855 16.7435 39.449C15.7918 39.5124 14.7608 40.4642 14.4435 41.5904Z" fill="#DEDEDE"/>
    <path d="M8.95617 41.955C8.62306 43.0812 9.11478 43.9377 10.0665 43.8743C11.0182 43.8108 12.0651 42.8433 12.3982 41.7171C12.7313 40.5908 12.2238 39.7343 11.2879 39.7977C10.3362 39.8612 9.30513 40.8288 8.95617 41.955Z" fill="#DEDEDE"/>
    <path d="M3.48291 42.3199C3.13395 43.4461 3.62568 44.3026 4.5774 44.2392C5.52912 44.1757 6.57601 43.2081 6.92498 42.0819C7.27395 40.9557 6.78222 40.0992 5.83049 40.1626C4.89463 40.2419 3.83188 41.1936 3.48291 42.3199Z" fill="#DEDEDE"/>
    <path d="M-1.98955 42.7164C-2.35437 43.8426 -1.87851 44.6992 -0.926789 44.6357C0.0249348 44.5723 1.0877 43.5888 1.43667 42.4626C1.78563 41.3364 1.30975 40.4799 0.373888 40.5592C-0.561974 40.6226 -1.62472 41.5902 -1.98955 42.7164Z" fill="#DEDEDE"/>
    <path d="M371.482 40.4957C372.037 41.6378 373.243 42.6371 374.163 42.7164C375.083 42.7957 375.369 41.9391 374.797 40.7971C374.226 39.655 373.037 38.6557 372.117 38.5764C371.197 38.4971 370.911 39.3536 371.482 40.4957Z" fill="#DEDEDE"/>
    <path d="M366.201 40.0037C366.756 41.1458 367.946 42.1292 368.866 42.2244C369.786 42.3037 370.071 41.4472 369.516 40.3051C368.961 39.163 367.771 38.1796 366.851 38.1003C365.947 38.021 365.646 38.8617 366.201 40.0037Z" fill="#DEDEDE"/>
    <path d="M360.919 39.5281C361.458 40.6702 362.648 41.6537 363.568 41.733C364.488 41.8123 364.789 40.9557 364.25 39.8295C363.71 38.6874 362.521 37.704 361.617 37.6247C360.681 37.5454 360.379 38.3861 360.919 39.5281Z" fill="#DEDEDE"/>
    <path d="M355.621 39.068C356.144 40.1942 357.318 41.1935 358.238 41.2729C359.158 41.3522 359.475 40.4956 358.936 39.3694C358.397 38.2273 357.223 37.2598 356.319 37.1804C355.415 37.0853 355.097 37.926 355.621 39.068Z" fill="#DEDEDE"/>
    <path d="M350.323 38.608C350.83 39.7342 352.004 40.7177 352.924 40.797C353.844 40.8763 354.177 40.0197 353.654 38.8935C353.13 37.7673 351.972 36.7839 351.052 36.7046C350.132 36.6252 349.815 37.4818 350.323 38.608Z" fill="#DEDEDE"/>
    <path d="M345.025 38.1642C345.533 39.2904 346.691 40.2739 347.611 40.3532C348.531 40.4325 348.864 39.5759 348.356 38.4497C347.849 37.3235 346.691 36.3401 345.771 36.2766C344.867 36.1815 344.518 37.038 345.025 38.1642Z" fill="#DEDEDE"/>
    <path d="M339.727 37.7359C340.219 38.8621 341.361 39.8456 342.297 39.909C343.217 39.9883 343.565 39.1318 343.058 38.0056C342.55 36.8794 341.408 35.9118 340.488 35.8325C339.584 35.769 339.235 36.6097 339.727 37.7359Z" fill="#DEDEDE"/>
    <path d="M334.413 37.3233C334.889 38.4495 336.031 39.4171 336.951 39.4964C337.871 39.5757 338.236 38.7191 337.744 37.5929C337.252 36.4667 336.11 35.4991 335.206 35.4357C334.286 35.3405 333.937 36.1971 334.413 37.3233Z" fill="#DEDEDE"/>
    <path d="M329.1 36.9109C329.56 38.0371 330.702 39.0047 331.622 39.0682C332.542 39.1316 332.907 38.275 332.431 37.1647C331.955 36.0385 330.829 35.0868 329.909 35.0075C329.004 34.944 328.624 35.8006 329.1 36.9109Z" fill="#DEDEDE"/>
    <path d="M323.785 36.5304C324.245 37.6566 325.356 38.6084 326.292 38.6877C327.212 38.7511 327.592 37.8946 327.132 36.7842C326.672 35.658 325.546 34.7063 324.626 34.6428C323.706 34.5477 323.325 35.4042 323.785 36.5304Z" fill="#DEDEDE"/>
    <path d="M318.456 36.1497C318.9 37.2601 320.01 38.2277 320.946 38.2911C321.882 38.3546 322.262 37.498 321.802 36.3877C321.358 35.2615 320.248 34.3097 319.328 34.2463C318.392 34.1828 318.011 35.0235 318.456 36.1497Z" fill="#DEDEDE"/>
    <path d="M313.126 35.7688C313.554 36.8791 314.665 37.8467 315.585 37.9101C316.521 37.9736 316.901 37.117 316.473 36.0067C316.029 34.8963 314.934 33.9446 314.014 33.8812C313.094 33.8177 312.698 34.6584 313.126 35.7688Z" fill="#DEDEDE"/>
    <path d="M307.797 35.4198C308.209 36.5302 309.303 37.4819 310.239 37.5453C311.175 37.6088 311.572 36.7522 311.143 35.6419C310.715 34.5315 309.621 33.5798 308.701 33.5164C307.781 33.4529 307.368 34.3095 307.797 35.4198Z" fill="#DEDEDE"/>
    <path d="M302.451 35.0708C302.863 36.1811 303.942 37.1328 304.862 37.1963C305.798 37.2597 306.21 36.4032 305.798 35.2928C305.385 34.1825 304.307 33.2308 303.387 33.1832C302.467 33.1197 302.054 33.9604 302.451 35.0708Z" fill="#DEDEDE"/>
    <path d="M297.105 34.7377C297.502 35.8481 298.565 36.7998 299.501 36.8474C300.436 36.9108 300.865 36.0543 300.452 34.9439C300.056 33.8336 298.977 32.8977 298.057 32.8343C297.153 32.7867 296.725 33.6433 297.105 34.7377Z" fill="#DEDEDE"/>
    <path d="M291.76 34.4203C292.141 35.5307 293.204 36.4824 294.14 36.53C295.076 36.5934 295.504 35.721 295.123 34.6265C294.727 33.5162 293.68 32.5803 292.744 32.5327C291.824 32.4693 291.38 33.3258 291.76 34.4203Z" fill="#DEDEDE"/>
    <path d="M286.414 34.1195C286.779 35.2298 287.841 36.1657 288.761 36.2291C289.697 36.2767 290.141 35.4202 289.777 34.3098C289.396 33.1995 288.349 32.2636 287.429 32.216C286.509 32.1684 286.049 33.0091 286.414 34.1195Z" fill="#DEDEDE"/>
    <path d="M281.068 33.8333C281.417 34.9437 282.464 35.8795 283.4 35.9271C284.336 35.9747 284.796 35.1181 284.431 34.0078C284.066 32.8974 283.019 31.9774 282.099 31.914C281.179 31.8664 280.704 32.723 281.068 33.8333Z" fill="#DEDEDE"/>
    <path d="M275.708 33.548C276.041 34.6584 277.088 35.5942 278.023 35.6418C278.959 35.6894 279.435 34.8329 279.086 33.7225C278.737 32.6122 277.706 31.6922 276.786 31.6446C275.834 31.597 275.359 32.4536 275.708 33.548Z" fill="#DEDEDE"/>
    <path d="M270.346 33.2783C270.679 34.3728 271.694 35.3087 272.63 35.3562C273.566 35.4038 274.058 34.5473 273.709 33.4369C273.376 32.3425 272.345 31.4066 271.425 31.359C270.505 31.3273 270.013 32.1838 270.346 33.2783Z" fill="#DEDEDE"/>
    <path d="M264.985 33.0246C265.302 34.1191 266.317 35.055 267.253 35.1025C268.189 35.1501 268.681 34.2936 268.364 33.1832C268.046 32.0887 267.031 31.1529 266.095 31.1212C265.159 31.0736 264.668 31.9301 264.985 33.0246Z" fill="#DEDEDE"/>
    <path d="M259.607 32.787C259.909 33.8815 260.908 34.8173 261.844 34.8491C262.78 34.8966 263.287 34.0242 262.97 32.9297C262.653 31.8353 261.654 30.9153 260.734 30.8677C259.814 30.836 259.306 31.6925 259.607 32.787Z" fill="#DEDEDE"/>
    <path d="M254.246 32.5488C254.531 33.6433 255.531 34.5633 256.467 34.6108C257.402 34.6584 257.926 33.786 257.625 32.6915C257.323 31.597 256.34 30.677 255.404 30.6453C254.468 30.5977 253.945 31.4543 254.246 32.5488Z" fill="#DEDEDE"/>
    <path d="M248.869 32.3424C249.139 33.4368 250.122 34.3568 251.058 34.4044C251.994 34.4361 252.533 33.5796 252.248 32.4851C251.962 31.3906 250.979 30.4706 250.059 30.4389C249.123 30.3913 248.583 31.2479 248.869 32.3424Z" fill="#DEDEDE"/>
    <path d="M243.492 32.1363C243.746 33.2308 244.73 34.1508 245.665 34.1826C246.601 34.2143 247.141 33.3577 246.871 32.2632C246.601 31.1688 245.634 30.2488 244.698 30.217C243.762 30.1853 243.223 31.0419 243.492 32.1363Z" fill="#DEDEDE"/>
    <path d="M238.098 31.9461C238.352 33.0406 239.304 33.9606 240.239 33.9923C241.175 34.024 241.73 33.1675 241.477 32.073C241.223 30.9785 240.255 30.0744 239.335 30.0427C238.415 29.9951 237.86 30.8516 238.098 31.9461Z" fill="#DEDEDE"/>
    <path d="M232.722 31.7557C232.959 32.8502 233.911 33.7702 234.847 33.786C235.783 33.8178 236.354 32.9454 236.1 31.8667C235.862 30.7723 234.911 29.8681 233.975 29.8364C233.055 29.8205 232.484 30.6771 232.722 31.7557Z" fill="#DEDEDE"/>
    <path d="M221.935 31.4384C222.141 32.5329 223.061 33.4371 224.013 33.4688C224.949 33.5005 225.535 32.6281 225.329 31.5336C225.107 30.4391 224.187 29.535 223.251 29.5191C222.331 29.5033 221.744 30.3598 221.935 31.4384Z" fill="#DEDEDE"/>
    <path d="M216.558 31.296C216.748 32.3905 217.668 33.2946 218.604 33.3105C219.54 33.3422 220.143 32.4698 219.937 31.3753C219.73 30.2808 218.826 29.3926 217.89 29.3608C216.955 29.3608 216.352 30.2174 216.558 31.296Z" fill="#DEDEDE"/>
    <path d="M211.149 31.1688C211.323 32.2633 212.227 33.1674 213.179 33.1833C214.115 33.1991 214.733 32.3426 214.543 31.2481C214.353 30.1536 213.449 29.2653 212.513 29.2495C211.593 29.2336 210.974 30.0902 211.149 31.1688Z" fill="#DEDEDE"/>
    <path d="M205.756 31.0576C205.914 32.1521 206.819 33.0404 207.754 33.0721C208.69 33.0879 209.325 32.2155 209.15 31.1369C208.976 30.0424 208.087 29.1542 207.152 29.1383C206.216 29.1066 205.597 29.979 205.756 31.0576Z" fill="#DEDEDE"/>
    <path d="M200.363 30.9624C200.505 32.0569 201.394 32.9451 202.345 32.961C203.281 32.9768 203.916 32.1044 203.757 31.0258C203.599 29.9313 202.71 29.0431 201.774 29.0272C200.839 29.0113 200.204 29.8679 200.363 30.9624Z" fill="#DEDEDE"/>
    <path d="M194.954 30.8675C195.097 31.9461 195.969 32.8503 196.905 32.8661C197.841 32.882 198.491 32.0096 198.349 30.9151C198.206 29.8365 197.333 28.9323 196.398 28.9323C195.462 28.9165 194.827 29.7889 194.954 30.8675Z" fill="#DEDEDE"/>
    <path d="M189.56 30.8038C189.687 31.8824 190.543 32.7866 191.479 32.8024C192.415 32.8183 193.081 31.9459 192.954 30.8514C192.827 29.7728 191.955 28.8845 191.035 28.8686C190.083 28.8528 189.433 29.7093 189.56 30.8038Z" fill="#DEDEDE"/>
    <path d="M184.152 30.7403C184.263 31.819 185.103 32.7231 186.055 32.7231C186.991 32.739 187.673 31.8507 187.546 30.7721C187.435 29.6934 186.579 28.8052 185.643 28.7893C184.707 28.7893 184.041 29.6459 184.152 30.7403Z" fill="#DEDEDE"/>
    <path d="M178.743 30.6932C178.838 31.7718 179.679 32.6759 180.615 32.6759C181.551 32.6918 182.233 31.8035 182.138 30.7249C182.027 29.6463 181.202 28.758 180.266 28.7422C179.33 28.7263 178.648 29.5987 178.743 30.6932Z" fill="#DEDEDE"/>
    <path d="M173.333 30.6456C173.413 31.7242 174.238 32.6125 175.189 32.6284C176.125 32.6284 176.823 31.7559 176.728 30.6773C176.633 29.5987 175.808 28.7104 174.872 28.7104C173.936 28.6946 173.254 29.567 173.333 30.6456Z" fill="#DEDEDE"/>
    <path d="M167.924 30.6293C167.988 31.7079 168.813 32.5961 169.749 32.5961C170.7 32.5961 171.398 31.7237 171.319 30.6293C171.24 29.5506 170.431 28.6624 169.495 28.6624C168.559 28.6624 167.861 29.5348 167.924 30.6293Z" fill="#DEDEDE"/>
    <path d="M59.5554 32.9139C59.3333 34.0084 59.9361 34.8649 60.8719 34.8173C61.8237 34.7698 62.7436 33.8498 62.9499 32.7553C63.1561 31.6608 62.5692 30.8201 61.6333 30.8518C60.7133 30.8994 59.7775 31.8194 59.5554 32.9139Z" fill="#DEDEDE"/>
    <path d="M54.147 33.1676C53.9249 34.262 54.4959 35.1186 55.4477 35.071C56.3994 35.0234 57.3352 34.1034 57.5573 33.0089C57.7794 31.9144 57.1924 31.0738 56.2566 31.1055C55.3207 31.1372 54.369 32.0572 54.147 33.1676Z" fill="#DEDEDE"/>
    <path d="M48.7219 33.4211C48.484 34.5156 49.0392 35.3722 49.9909 35.3246C50.9427 35.277 51.8944 34.3411 52.1165 33.2466C52.3544 32.1522 51.7833 31.2956 50.8475 31.3432C49.9275 31.4066 48.9757 32.3266 48.7219 33.4211Z" fill="#DEDEDE"/>
    <path d="M43.3125 33.7068C43.0587 34.8013 43.6139 35.6578 44.5497 35.6102C45.5015 35.5626 46.4532 34.6268 46.707 33.5323C46.9608 32.4378 46.3897 31.5813 45.4697 31.6288C44.5339 31.6764 43.5662 32.5964 43.3125 33.7068Z" fill="#DEDEDE"/>
    <path d="M37.9038 33.9925C37.6342 35.087 38.1735 35.9435 39.1252 35.8959C40.0611 35.8484 41.0445 34.9125 41.3142 33.8022C41.568 32.7077 41.0286 31.8511 40.0928 31.9146C39.1569 31.9622 38.1735 32.898 37.9038 33.9925Z" fill="#DEDEDE"/>
    <path d="M32.495 34.2936C32.2094 35.3881 32.7488 36.2447 33.6846 36.1971C34.6205 36.1495 35.6198 35.1978 35.8894 34.1033C36.1591 33.0088 35.6356 32.1523 34.6998 32.2157C33.7639 32.2633 32.7805 33.1992 32.495 34.2936Z" fill="#DEDEDE"/>
    <path d="M27.0852 34.6107C26.7838 35.721 27.3072 36.5617 28.259 36.5141C29.1948 36.4507 30.1941 35.5148 30.4955 34.4203C30.781 33.3258 30.2576 32.4693 29.3217 32.5327C28.3859 32.5803 27.3866 33.5162 27.0852 34.6107Z" fill="#DEDEDE"/>
    <path d="M21.6769 34.944C21.3596 36.0543 21.8831 36.895 22.8189 36.8474C23.7548 36.784 24.77 35.8481 25.0713 34.7378C25.3727 33.6274 24.8651 32.7867 23.9293 32.8502C22.9934 32.8978 21.9941 33.8336 21.6769 34.944Z" fill="#DEDEDE"/>
    <path d="M16.268 35.2928C15.9508 36.4032 16.4425 37.2439 17.3942 37.1963C18.3301 37.1328 19.3611 36.1811 19.6625 35.0866C19.9797 33.9763 19.4721 33.1356 18.5363 33.199C17.6163 33.2466 16.6011 34.1825 16.268 35.2928Z" fill="#DEDEDE"/>
    <path d="M10.8743 35.6416C10.5412 36.7519 11.0329 37.5926 11.9688 37.545C12.9046 37.4816 13.9357 36.5299 14.2688 35.4195C14.6019 34.3092 14.1101 33.4685 13.1743 33.5319C12.2384 33.5954 11.2074 34.5312 10.8743 35.6416Z" fill="#DEDEDE"/>
    <path d="M5.46553 36.0067C5.11656 37.117 5.59242 37.9736 6.52828 37.8943C7.46414 37.8308 8.51105 36.8791 8.84415 35.7688C9.17726 34.6584 8.70138 33.8177 7.76552 33.8812C6.86138 33.9605 5.81449 34.8963 5.46553 36.0067Z" fill="#DEDEDE"/>
    <path d="M0.0721697 36.3877C-0.292658 37.498 0.183186 38.3546 1.11905 38.2753C2.05491 38.2118 3.11769 37.2442 3.46665 36.1339C3.81562 35.0235 3.35561 34.1828 2.41975 34.2463C1.48389 34.3256 0.436997 35.2773 0.0721697 36.3877Z" fill="#DEDEDE"/>
    <path d="M373.529 34.5631C374.1 35.6893 375.29 36.6886 376.21 36.7679C377.114 36.8631 377.383 36.0065 376.812 34.8803C376.241 33.7541 375.036 32.7706 374.147 32.6755C373.227 32.5962 372.958 33.4369 373.529 34.5631Z" fill="#DEDEDE"/>
    <path d="M368.31 34.0877C368.865 35.2139 370.055 36.1973 370.959 36.2925C371.863 36.3718 372.149 35.5311 371.578 34.4049C371.006 33.2787 369.817 32.2953 368.929 32.216C368.024 32.1208 367.755 32.9615 368.31 34.0877Z" fill="#DEDEDE"/>
    <path d="M363.092 33.6114C363.631 34.7376 364.821 35.721 365.725 35.8004C366.629 35.8797 366.915 35.039 366.36 33.9128C365.805 32.7866 364.631 31.819 363.727 31.7238C362.838 31.6445 362.553 32.4852 363.092 33.6114Z" fill="#DEDEDE"/>
    <path d="M357.873 33.1512C358.412 34.2774 359.586 35.245 360.49 35.3243C361.394 35.4036 361.696 34.5629 361.156 33.4367C360.617 32.3105 359.443 31.3429 358.539 31.2636C357.635 31.1843 357.334 32.025 357.873 33.1512Z" fill="#DEDEDE"/>
    <path d="M352.655 32.6917C353.179 33.8179 354.337 34.7855 355.257 34.8648C356.161 34.9441 356.478 34.1034 355.939 32.9772C355.399 31.851 354.241 30.8834 353.337 30.82C352.433 30.7407 352.132 31.5814 352.655 32.6917Z" fill="#DEDEDE"/>
    <path d="M347.419 32.2635C347.927 33.3738 349.085 34.3573 350.005 34.4207C350.909 34.5 351.226 33.6435 350.719 32.5331C350.195 31.4228 349.037 30.4552 348.149 30.3759C347.229 30.2966 346.912 31.1373 347.419 32.2635Z" fill="#DEDEDE"/>
    <path d="M342.186 31.835C342.693 32.9453 343.835 33.9129 344.739 33.9922C345.644 34.0716 345.977 33.215 345.469 32.1047C344.961 30.9943 343.819 30.0267 342.915 29.9633C342.011 29.8681 341.678 30.7088 342.186 31.835Z" fill="#DEDEDE"/>
    <path d="M336.951 31.4069C337.443 32.5173 338.569 33.4849 339.489 33.5483C340.409 33.6276 340.742 32.7711 340.235 31.6607C339.743 30.5504 338.601 29.5987 337.697 29.5194C336.793 29.4559 336.459 30.2966 336.951 31.4069Z" fill="#DEDEDE"/>
    <path d="M331.701 31.0102C332.177 32.1206 333.303 33.0723 334.223 33.1516C335.143 33.215 335.476 32.3743 335 31.264C334.509 30.1537 333.383 29.2019 332.478 29.1385C331.574 29.0592 331.225 29.8999 331.701 31.0102Z" fill="#DEDEDE"/>
    <path d="M326.466 30.6137C326.926 31.724 328.053 32.6757 328.973 32.7392C329.893 32.8026 330.242 31.9619 329.766 30.8516C329.29 29.7413 328.179 28.7895 327.275 28.7261C326.355 28.6785 325.99 29.5033 326.466 30.6137Z" fill="#DEDEDE"/>
    <path d="M321.215 30.2334C321.675 31.3437 322.786 32.2954 323.69 32.3589C324.61 32.4223 324.975 31.5816 324.515 30.4713C324.055 29.361 322.944 28.4251 322.04 28.3616C321.12 28.2982 320.755 29.1389 321.215 30.2334Z" fill="#DEDEDE"/>
    <path d="M315.95 29.868C316.394 30.9625 317.488 31.9142 318.408 31.9777C319.328 32.0411 319.709 31.2004 319.249 30.0901C318.805 28.9798 317.695 28.0439 316.79 27.9804C315.886 27.9329 315.522 28.7735 315.95 29.868Z" fill="#DEDEDE"/>
    <path d="M310.699 29.5191C311.127 30.6136 312.222 31.5653 313.142 31.6287C314.062 31.6922 314.442 30.8515 314.014 29.7411C313.57 28.6467 312.491 27.6949 311.587 27.6473C310.651 27.5839 310.271 28.4087 310.699 29.5191Z" fill="#DEDEDE"/>
    <path d="M305.433 29.17C305.845 30.2645 306.924 31.2162 307.844 31.2638C308.764 31.3273 309.161 30.4707 308.732 29.3762C308.304 28.2818 307.226 27.3459 306.321 27.2824C305.417 27.2349 305.021 28.0755 305.433 29.17Z" fill="#DEDEDE"/>
    <path d="M300.167 28.8371C300.563 29.9316 301.642 30.8675 302.562 30.9309C303.482 30.9944 303.894 30.1378 303.482 29.0433C303.07 27.9489 302.007 27.013 301.087 26.9654C300.167 26.902 299.77 27.7426 300.167 28.8371Z" fill="#DEDEDE"/>
    <path d="M294.9 28.52C295.297 29.6145 296.359 30.5503 297.264 30.5979C298.184 30.6614 298.596 29.8048 298.199 28.7103C297.803 27.6159 296.74 26.6959 295.836 26.6324C294.932 26.5848 294.504 27.4255 294.9 28.52Z" fill="#DEDEDE"/>
    <path d="M289.619 28.2185C289.999 29.313 291.046 30.2488 291.966 30.2964C292.886 30.344 293.314 29.5033 292.934 28.4088C292.553 27.3143 291.49 26.3943 290.586 26.3468C289.682 26.2833 289.238 27.124 289.619 28.2185Z" fill="#DEDEDE"/>
    <path d="M257.91 26.6165C258.212 27.6951 259.195 28.6151 260.131 28.6468C261.051 28.6944 261.558 27.8379 261.241 26.7592C260.94 25.6806 259.941 24.7765 259.036 24.7289C258.116 24.6972 257.609 25.5379 257.91 26.6165Z" fill="#DEDEDE"/>
    <path d="M252.612 26.3942C252.897 27.4728 253.881 28.3928 254.801 28.4245C255.721 28.4562 256.228 27.6155 255.943 26.5369C255.641 25.4583 254.674 24.5542 253.754 24.5224C252.834 24.4748 252.326 25.3155 252.612 26.3942Z" fill="#DEDEDE"/>
    <path d="M183.533 24.6492C183.644 25.7119 184.484 26.6002 185.404 26.6002C186.34 26.6161 186.991 25.7437 186.88 24.6809C186.769 23.6181 185.928 22.7457 185.008 22.7299C184.088 22.7299 183.438 23.5864 183.533 24.6492Z" fill="#DEDEDE"/>
    <path d="M71.4521 26.315C71.2617 27.3936 71.8645 28.2343 72.8004 28.2026C73.7362 28.1709 74.6245 27.2667 74.8148 26.1881C74.9893 25.1095 74.3866 24.2688 73.4666 24.3164C72.5466 24.3481 71.6424 25.2364 71.4521 26.315Z" fill="#DEDEDE"/>
    <path d="M66.1225 26.5372C65.9163 27.6158 66.5191 28.4565 67.4391 28.4248C68.375 28.393 69.2791 27.473 69.4695 26.4103C69.6598 25.3316 69.0729 24.491 68.1529 24.5385C67.2329 24.5544 66.3129 25.4585 66.1225 26.5372Z" fill="#DEDEDE"/>
    <path d="M60.7765 26.7751C60.5544 27.8537 61.1413 28.6944 62.0772 28.6627C63.013 28.6151 63.933 27.7109 64.1392 26.6323C64.3454 25.5537 63.7586 24.713 62.8386 24.7606C61.9186 24.7923 60.9985 25.6965 60.7765 26.7751Z" fill="#DEDEDE"/>
    <path d="M55.4311 27.013C55.2091 28.0916 55.7642 28.9323 56.7001 28.8847C57.6359 28.8371 58.5559 27.933 58.778 26.8544C59.0001 25.7757 58.4291 24.9351 57.5091 24.9826C56.6049 25.0302 55.6691 25.9344 55.4311 27.013Z" fill="#DEDEDE"/>
    <path d="M50.1014 27.2668C49.8635 28.3454 50.4186 29.1861 51.3545 29.1385C52.2903 29.0909 53.2262 28.1709 53.4642 27.0923C53.7021 26.0137 53.131 25.173 52.211 25.2206C51.291 25.284 50.3393 26.1881 50.1014 27.2668Z" fill="#DEDEDE"/>
    <path d="M44.7555 27.536C44.5017 28.6147 45.0411 29.4554 45.9769 29.4078C46.9128 29.3602 47.8645 28.4402 48.1024 27.3616C48.3562 26.2829 47.801 25.4423 46.881 25.4898C45.9769 25.5533 45.0252 26.4574 44.7555 27.536Z" fill="#DEDEDE"/>
    <path d="M39.426 27.8221C39.1563 28.9007 39.6956 29.7414 40.6156 29.6938C41.5515 29.6462 42.5032 28.7262 42.7729 27.6476C43.0267 26.569 42.5032 25.7283 41.5832 25.7759C40.6632 25.8234 39.6956 26.7434 39.426 27.8221Z" fill="#DEDEDE"/>
    <path d="M34.097 28.1234C33.8114 29.2021 34.3349 30.0428 35.2708 29.9952C36.2066 29.9476 37.1742 29.0117 37.4439 27.9331C37.7135 26.8545 37.1901 26.0138 36.2701 26.0614C35.3501 26.1248 34.3825 27.029 34.097 28.1234Z" fill="#DEDEDE"/>
    <path d="M28.7504 28.4243C28.4491 29.503 28.9725 30.3437 29.8925 30.2961C30.8284 30.2485 31.8119 29.3126 32.0974 28.234C32.3829 27.1554 31.8753 26.3147 30.9553 26.3623C30.0353 26.4257 29.0518 27.3457 28.7504 28.4243Z" fill="#DEDEDE"/>
    <path d="M23.4222 28.742C23.1049 29.8365 23.6125 30.6772 24.5484 30.6137C25.4842 30.5503 26.4835 29.6303 26.7691 28.5358C27.0704 27.4413 26.5629 26.6165 25.6429 26.6799C24.7387 26.7434 23.7394 27.6634 23.4222 28.742Z" fill="#DEDEDE"/>
    <path d="M18.092 29.075C17.7748 30.1695 18.2665 31.0102 19.1865 30.9467C20.1223 30.8833 21.1217 29.9474 21.4389 28.8688C21.7561 27.7743 21.2644 26.9495 20.3444 27.0129C19.4244 27.0605 18.4251 27.9964 18.092 29.075Z" fill="#DEDEDE"/>
    <path d="M12.7619 29.4239C12.4288 30.5184 12.9046 31.3591 13.8405 31.2956C14.7764 31.2322 15.7916 30.2963 16.1088 29.2018C16.4419 28.1073 15.9502 27.2825 15.0302 27.346C14.126 27.4094 13.095 28.3294 12.7619 29.4239Z" fill="#DEDEDE"/>
    <path d="M7.4325 29.789C7.08353 30.8835 7.55938 31.7242 8.47938 31.6607C9.41524 31.5973 10.4304 30.6614 10.7794 29.5669C11.1125 28.4724 10.6525 27.6476 9.73249 27.7111C8.81249 27.7587 7.78146 28.6945 7.4325 29.789Z" fill="#DEDEDE"/>
    <path d="M2.11923 30.1536C1.75441 31.2481 2.2144 32.0888 3.15026 32.0253C4.08612 31.9619 5.11717 31.0102 5.46613 29.9157C5.8151 28.8212 5.35511 27.9964 4.43511 28.0598C3.51511 28.1233 2.4682 29.0591 2.11923 30.1536Z" fill="#DEDEDE"/>
    <path d="M-3.21162 30.5342C-3.59231 31.6287 -3.1323 32.4694 -2.2123 32.406C-1.2923 32.3425 -0.229546 31.3908 0.135282 30.2963C0.50011 29.2018 0.0559638 28.377 -0.864036 28.4404C-1.78404 28.5039 -2.83093 29.4397 -3.21162 30.5342Z" fill="#DEDEDE"/>
    <path d="M375.449 28.6943C376.02 29.8205 377.226 30.7881 378.114 30.8832C379.002 30.9625 379.256 30.1219 378.669 29.0115C378.082 27.8853 376.893 26.9177 376.004 26.8384C375.132 26.7432 374.878 27.5839 375.449 28.6943Z" fill="#DEDEDE"/>
    <path d="M370.309 28.2185C370.88 29.3288 372.07 30.3122 372.958 30.3916C373.846 30.4709 374.116 29.6302 373.545 28.5198C372.974 27.3936 371.784 26.426 370.896 26.3467C370.008 26.2674 369.738 27.1081 370.309 28.2185Z" fill="#DEDEDE"/>
    <path d="M365.154 27.7586C365.709 28.8689 366.883 29.8365 367.787 29.9317C368.691 30.011 368.961 29.1703 368.39 28.06C367.819 26.9496 366.645 25.982 365.757 25.9027C364.884 25.8076 364.614 26.6324 365.154 27.7586Z" fill="#DEDEDE"/>
    <path d="M360.014 27.2984C360.553 28.4088 361.727 29.3763 362.631 29.4557C363.536 29.535 363.805 28.6943 363.25 27.5839C362.695 26.4736 361.537 25.506 360.633 25.4267C359.745 25.3474 359.459 26.1881 360.014 27.2984Z" fill="#DEDEDE"/>
    <path d="M354.859 26.8543C355.398 27.9646 356.556 28.9322 357.445 29.0115C358.349 29.0909 358.634 28.2502 358.095 27.1398C357.556 26.0295 356.398 25.0777 355.509 24.9984C354.605 24.9033 354.32 25.744 354.859 26.8543Z" fill="#DEDEDE"/>
    <path d="M349.688 26.4103C350.211 27.5207 351.369 28.4724 352.258 28.5517C353.162 28.631 353.463 27.7903 352.924 26.68C352.4 25.5696 351.242 24.6179 350.354 24.5386C349.466 24.4751 349.165 25.3 349.688 26.4103Z" fill="#DEDEDE"/>
    <path d="M344.534 25.982C345.041 27.0764 346.183 28.044 347.087 28.1075C347.991 28.1868 348.293 27.3461 347.769 26.2358C347.246 25.1254 346.104 24.1895 345.216 24.1102C344.327 24.0626 344.01 24.8875 344.534 25.982Z" fill="#DEDEDE"/>
    <path d="M339.361 25.5694C339.853 26.6639 340.995 27.6156 341.899 27.6949C342.804 27.7742 343.121 26.9335 342.613 25.8232C342.106 24.7287 340.979 23.777 340.075 23.7135C339.187 23.6501 338.854 24.4749 339.361 25.5694Z" fill="#DEDEDE"/>
    <path d="M334.191 25.1729C334.683 26.2674 335.809 27.2191 336.697 27.2825C337.601 27.346 337.934 26.5212 337.427 25.4108C336.935 24.3163 335.809 23.3646 334.921 23.3012C334.032 23.2536 333.699 24.0784 334.191 25.1729Z" fill="#DEDEDE"/>
    <path d="M329.02 24.7761C329.496 25.8706 330.606 26.8223 331.51 26.8857C332.415 26.9492 332.748 26.1244 332.272 25.0299C331.796 23.9354 330.67 22.9995 329.781 22.9361C328.877 22.8568 328.544 23.6975 329.02 24.7761Z" fill="#DEDEDE"/>
    <path d="M323.832 24.4115C324.292 25.506 325.403 26.4419 326.307 26.5053C327.211 26.5688 327.56 25.7281 327.084 24.6495C326.608 23.555 325.514 22.6191 324.61 22.5557C323.721 22.4922 323.372 23.3171 323.832 24.4115Z" fill="#DEDEDE"/>
    <path d="M303.086 23.0158C303.498 24.0945 304.577 25.0303 305.481 25.0779C306.385 25.1414 306.782 24.3007 306.354 23.2221C305.925 22.1434 304.862 21.2234 303.974 21.16C303.07 21.0965 302.674 21.9372 303.086 23.0158Z" fill="#DEDEDE"/>
    <path d="M297.883 22.6985C298.28 23.7771 299.342 24.6971 300.246 24.7605C301.151 24.824 301.547 23.9833 301.151 22.9047C300.738 21.826 299.675 20.906 298.787 20.8585C297.899 20.7791 297.486 21.6198 297.883 22.6985Z" fill="#DEDEDE"/>
    <path d="M292.696 22.3813C293.076 23.4599 294.139 24.3799 295.043 24.4275C295.947 24.4751 296.36 23.6502 295.963 22.5716C295.567 21.493 294.52 20.5888 293.616 20.5254C292.712 20.4778 292.299 21.3026 292.696 22.3813Z" fill="#DEDEDE"/>
    <path d="M256.229 20.5568C256.531 21.6196 257.498 22.5237 258.418 22.5554C259.322 22.5872 259.814 21.7623 259.513 20.6996C259.211 19.6368 258.228 18.7485 257.324 18.701C256.42 18.6692 255.928 19.4941 256.229 20.5568Z" fill="#DEDEDE"/>
    <path d="M250.995 20.3507C251.28 21.4135 252.248 22.3017 253.152 22.3493C254.056 22.381 254.564 21.5562 254.262 20.4935C253.961 19.4307 253.009 18.5424 252.105 18.5107C251.217 18.4472 250.725 19.2879 250.995 20.3507Z" fill="#DEDEDE"/>
    <path d="M77.781 20.0972C77.6066 21.16 78.2093 21.9848 79.1293 21.9531C80.0493 21.9213 80.9218 21.0331 81.0804 19.9703C81.239 18.9075 80.6362 18.0986 79.7321 18.1303C78.828 18.162 77.9555 19.0344 77.781 20.0972Z" fill="#DEDEDE"/>
    <path d="M67.2478 20.5095C67.0416 21.5723 67.6285 22.3971 68.5485 22.3654C69.4685 22.3336 70.3726 21.4295 70.563 20.3826C70.7533 19.3199 70.1664 18.495 69.2623 18.5426C68.3423 18.5585 67.454 19.4467 67.2478 20.5095Z" fill="#DEDEDE"/>
    <path d="M61.9815 20.7314C61.7595 21.7942 62.3305 22.619 63.2505 22.5873C64.1705 22.5397 65.0746 21.6514 65.2808 20.5887C65.487 19.5259 64.916 18.7011 64.0119 18.7487C63.1077 18.7804 62.2036 19.6687 61.9815 20.7314Z" fill="#DEDEDE"/>
    <path d="M56.7315 20.9696C56.5094 22.0323 57.0646 22.8572 57.9846 22.8254C58.9046 22.7779 59.8246 21.8896 60.0466 20.8268C60.2687 19.7641 59.7135 18.9392 58.7935 18.9868C57.8735 19.0185 56.9536 19.9068 56.7315 20.9696Z" fill="#DEDEDE"/>
    <path d="M51.4658 21.2073C51.2279 22.27 51.7672 23.0948 52.6872 23.0631C53.6072 23.0155 54.543 22.1114 54.7651 21.0645C55.003 20.0017 54.4479 19.1769 53.5437 19.2245C52.6396 19.2562 51.7037 20.1445 51.4658 21.2073Z" fill="#DEDEDE"/>
    <path d="M46.1989 21.4774C45.9452 22.5401 46.4845 23.365 47.4045 23.3174C48.3245 23.2698 49.2603 22.3657 49.5141 21.3029C49.752 20.2401 49.2286 19.4153 48.3086 19.4629C47.4045 19.5105 46.4527 20.4146 46.1989 21.4774Z" fill="#DEDEDE"/>
    <path d="M40.9488 21.7468C40.6791 22.8095 41.2026 23.6344 42.1226 23.5868C43.0426 23.5392 43.9943 22.6351 44.2481 21.5723C44.5019 20.5095 43.9784 19.6847 43.0743 19.7323C42.1702 19.7799 41.2184 20.684 40.9488 21.7468Z" fill="#DEDEDE"/>
    <path d="M35.6826 22.0318C35.3971 23.0946 35.9205 23.9353 36.8247 23.8718C37.7447 23.8242 38.7123 22.9042 38.9819 21.8415C39.2516 20.7787 38.744 19.9539 37.824 20.0015C36.9357 20.0649 35.9681 20.9691 35.6826 22.0318Z" fill="#DEDEDE"/>
    <path d="M30.4332 22.3333C30.1318 23.3961 30.6394 24.2368 31.5594 24.1733C32.4794 24.1257 33.447 23.2057 33.7325 22.143C34.018 21.0802 33.5104 20.2554 32.6062 20.303C31.7021 20.3664 30.7187 21.2706 30.4332 22.3333Z" fill="#DEDEDE"/>
    <path d="M25.1663 22.6511C24.865 23.7297 25.3566 24.5545 26.2608 24.4911C27.1808 24.4435 28.1643 23.5235 28.4656 22.4449C28.767 21.3821 28.2753 20.5573 27.3711 20.6049C26.467 20.6683 25.4836 21.5725 25.1663 22.6511Z" fill="#DEDEDE"/>
    <path d="M19.916 22.968C19.5988 24.0466 20.0746 24.8714 20.9946 24.808C21.9146 24.7446 22.914 23.8246 23.2154 22.7618C23.5326 21.6832 23.0409 20.8742 22.1367 20.9218C21.2326 20.9852 20.2333 21.8894 19.916 22.968Z" fill="#DEDEDE"/>
    <path d="M14.6655 23.3011C14.3324 24.3797 14.8083 25.2046 15.7124 25.1411C16.6324 25.0777 17.6318 24.1577 17.9649 23.0791C18.2821 22.0004 17.8221 21.1915 16.918 21.2391C15.998 21.3184 14.9986 22.2225 14.6655 23.3011Z" fill="#DEDEDE"/>
    <path d="M9.3989 23.6501C9.04993 24.7287 9.50995 25.5536 10.4299 25.4901C11.3499 25.4267 12.3651 24.5067 12.6982 23.4281C13.0313 22.3494 12.5713 21.5405 11.6672 21.5881C10.763 21.6515 9.74786 22.5715 9.3989 23.6501Z" fill="#DEDEDE"/>
    <path d="M4.14893 23.9993C3.7841 25.0779 4.24411 25.9028 5.14825 25.8393C6.06825 25.7759 7.08342 24.84 7.44824 23.7614C7.79721 22.6828 7.35306 21.8579 6.44893 21.9214C5.54479 22.0007 4.51376 22.9207 4.14893 23.9993Z" fill="#DEDEDE"/>
    <path d="M-1.1004 24.3799C-1.48109 25.4586 -1.03696 26.2834 -0.11696 26.2199C0.80304 26.1565 1.83409 25.2206 2.19891 24.142C2.56374 23.0634 2.11961 22.2386 1.21547 22.302C0.311334 22.3655 -0.719711 23.3013 -1.1004 24.3799Z" fill="#DEDEDE"/>
    <path d="M372.196 22.413C372.767 23.5234 373.957 24.491 374.845 24.5703C375.734 24.6496 375.972 23.8248 375.401 22.7144C374.814 21.6041 373.64 20.6523 372.752 20.573C371.863 20.4779 371.625 21.3027 372.196 22.413Z" fill="#DEDEDE"/>
    <path d="M367.12 21.9527C367.691 23.0472 368.865 24.0148 369.737 24.0941C370.625 24.1734 370.879 23.3485 370.308 22.2382C369.737 21.1279 368.563 20.1761 367.691 20.0968C366.803 20.0175 366.549 20.8423 367.12 21.9527Z" fill="#DEDEDE"/>
    <path d="M362.029 21.493C362.584 22.5875 363.742 23.555 364.63 23.6344C365.519 23.7137 365.773 22.8888 365.217 21.7785C364.662 20.684 363.488 19.7323 362.616 19.653C361.744 19.5737 361.474 20.3985 362.029 21.493Z" fill="#DEDEDE"/>
    <path d="M356.937 21.0642C357.476 22.1587 358.634 23.1105 359.523 23.1898C360.411 23.2691 360.68 22.4284 360.125 21.3339C359.57 20.2394 358.412 19.2877 357.54 19.2084C356.667 19.1449 356.398 19.9698 356.937 21.0642Z" fill="#DEDEDE"/>
    <path d="M351.846 20.6366C352.369 21.7311 353.527 22.6828 354.415 22.7463C355.303 22.8256 355.589 21.9849 355.05 20.8904C354.51 19.7959 353.368 18.8601 352.48 18.7808C351.592 18.7173 351.306 19.5422 351.846 20.6366Z" fill="#DEDEDE"/>
    <path d="M341.647 19.7957C342.154 20.8743 343.28 21.826 344.169 21.8895C345.057 21.9529 345.358 21.1281 344.851 20.0495C344.343 18.955 343.201 18.035 342.329 17.9557C341.44 17.8923 341.139 18.7171 341.647 19.7957Z" fill="#DEDEDE"/>
    <path d="M331.447 19.0184C331.923 20.097 333.049 21.0329 333.937 21.0963C334.825 21.1598 335.143 20.3349 334.651 19.2563C334.159 18.1777 333.049 17.2418 332.161 17.1784C331.288 17.1308 330.955 17.9398 331.447 19.0184Z" fill="#DEDEDE"/>
    <path d="M326.339 18.6539C326.815 19.7325 327.909 20.6684 328.813 20.7318C329.702 20.7953 330.035 19.9704 329.559 18.8918C329.083 17.8132 327.973 16.8932 327.1 16.8297C326.196 16.7504 325.863 17.5753 326.339 18.6539Z" fill="#DEDEDE"/>
    <path d="M321.215 18.2885C321.675 19.3671 322.77 20.2871 323.658 20.3506C324.547 20.414 324.895 19.5892 324.42 18.5106C323.944 17.4319 322.865 16.5119 321.977 16.4485C321.104 16.385 320.755 17.2099 321.215 18.2885Z" fill="#DEDEDE"/>
    <path d="M285.367 16.0526C285.732 17.1153 286.763 18.0195 287.667 18.0671C288.556 18.1147 288.968 17.2898 288.587 16.2429C288.207 15.1802 287.176 14.2919 286.287 14.2443C285.415 14.1967 285.002 15.0057 285.367 16.0526Z" fill="#DEDEDE"/>
    <path d="M280.245 15.7825C280.609 16.8453 281.624 17.7336 282.513 17.7811C283.401 17.8287 283.829 17.0039 283.465 15.957C283.1 14.8942 282.084 14.006 281.196 13.9584C280.308 13.9108 279.88 14.7356 280.245 15.7825Z" fill="#DEDEDE"/>
    <path d="M269.966 15.275C270.299 16.3219 271.298 17.226 272.187 17.2577C273.075 17.3053 273.535 16.4805 273.186 15.4336C272.837 14.3867 271.838 13.4984 270.965 13.4509C270.077 13.4191 269.633 14.2281 269.966 15.275Z" fill="#DEDEDE"/>
    <path d="M264.826 15.037C265.143 16.0839 266.143 16.9721 267.031 17.0197C267.919 17.0673 268.379 16.2425 268.046 15.1956C267.713 14.1487 266.729 13.2604 265.841 13.2287C264.969 13.1811 264.509 13.9901 264.826 15.037Z" fill="#DEDEDE"/>
    <path d="M259.687 14.815C259.988 15.8619 260.972 16.7502 261.876 16.7819C262.764 16.8295 263.24 16.0046 262.923 14.9577C262.606 13.9109 261.622 13.0384 260.734 12.9909C259.846 12.9433 259.386 13.7681 259.687 14.815Z" fill="#DEDEDE"/>
    <path d="M68.3747 14.5767C68.1684 15.6236 68.7395 16.4326 69.6436 16.4009C70.5477 16.3691 71.436 15.4809 71.6264 14.4498C71.8167 13.4029 71.2457 12.6098 70.3574 12.6415C69.4691 12.6733 68.5809 13.5298 68.3747 14.5767Z" fill="#DEDEDE"/>
    <path d="M63.188 14.7832C62.9818 15.8301 63.5369 16.6391 64.4411 16.6074C65.3452 16.5756 66.2494 15.6874 66.4397 14.6563C66.6459 13.6094 66.0749 12.8005 65.1866 12.8481C64.2983 12.8798 63.4101 13.7522 63.188 14.7832Z" fill="#DEDEDE"/>
    <path d="M58.0011 15.0215C57.779 16.0684 58.3183 16.8774 59.2225 16.8457C60.1266 16.7981 61.0307 15.9256 61.2528 14.8788C61.4749 13.8319 60.9197 13.0229 60.0314 13.0705C59.1431 13.1022 58.239 13.9746 58.0011 15.0215Z" fill="#DEDEDE"/>
    <path d="M52.8292 15.2592C52.5912 16.3061 53.1305 17.115 54.0347 17.0833C54.9388 17.0357 55.8589 16.1633 56.0968 15.1164C56.3347 14.0695 55.7954 13.2606 54.8913 13.3081C53.9871 13.3399 53.0671 14.2123 52.8292 15.2592Z" fill="#DEDEDE"/>
    <path d="M47.6426 15.5128C47.3888 16.5597 47.9123 17.3687 48.8164 17.337C49.7205 17.2894 50.6564 16.4011 50.8944 15.3542C51.1323 14.3073 50.6088 13.4984 49.7205 13.5459C48.8164 13.5935 47.8964 14.4659 47.6426 15.5128Z" fill="#DEDEDE"/>
    <path d="M42.4567 15.7829C42.187 16.8298 42.7104 17.6546 43.6146 17.607C44.5187 17.5594 45.4546 16.6711 45.7243 15.6242C45.9781 14.5773 45.4705 13.7684 44.5663 13.816C43.6622 13.8477 42.7263 14.7201 42.4567 15.7829Z" fill="#DEDEDE"/>
    <path d="M37.269 16.0524C36.9835 17.0993 37.4911 17.9241 38.3953 17.8765C39.2994 17.8289 40.2511 16.9407 40.5208 15.8779C40.7904 14.831 40.2829 14.022 39.3946 14.0696C38.5063 14.1172 37.5545 15.0055 37.269 16.0524Z" fill="#DEDEDE"/>
    <path d="M32.0827 16.3374C31.7813 17.3843 32.2889 18.2091 33.1931 18.1457C34.0972 18.0981 35.0648 17.194 35.3503 16.1471C35.6358 15.1002 35.1441 14.2912 34.24 14.3388C33.3517 14.4022 32.3841 15.2905 32.0827 16.3374Z" fill="#DEDEDE"/>
    <path d="M26.9107 16.6393C26.6093 17.6862 27.0852 18.511 27.9893 18.4476C28.8935 18.4 29.8611 17.4958 30.1624 16.4489C30.4638 15.402 29.9721 14.5931 29.0838 14.6407C28.1797 14.6883 27.2121 15.5924 26.9107 16.6393Z" fill="#DEDEDE"/>
    <path d="M21.7243 16.9567C21.407 18.0194 21.8829 18.8284 22.787 18.7649C23.6912 18.7174 24.6746 17.8132 24.9919 16.7505C25.3091 15.7036 24.8333 14.8946 23.9291 14.9422C23.025 15.0056 22.0415 15.8939 21.7243 16.9567Z" fill="#DEDEDE"/>
    <path d="M16.5371 17.2736C16.204 18.3364 16.664 19.1453 17.5682 19.0819C18.4723 19.0184 19.4716 18.1143 19.7888 17.0674C20.1061 16.0046 19.6461 15.2115 18.7578 15.2591C17.8695 15.3226 16.8702 16.2108 16.5371 17.2736Z" fill="#DEDEDE"/>
    <path d="M11.3659 17.6065C11.0169 18.6692 11.4769 19.4782 12.3811 19.4147C13.2852 19.3513 14.2845 18.4472 14.6335 17.3844C14.9666 16.3216 14.5224 15.5127 13.6183 15.5761C12.7142 15.6396 11.7148 16.5437 11.3659 17.6065Z" fill="#DEDEDE"/>
    <path d="M6.17948 17.956C5.81465 19.0187 6.2588 19.8277 7.16294 19.7801C8.06708 19.7167 9.08224 18.8125 9.43121 17.7498C9.78017 16.687 9.33604 15.878 8.44777 15.9415C7.55949 15.9891 6.54431 16.8932 6.17948 17.956Z" fill="#DEDEDE"/>
    <path d="M1.00893 18.3207C0.644098 19.3835 1.07237 20.2083 1.97651 20.1448C2.88065 20.0814 3.91169 19.1614 4.26065 18.0986C4.62548 17.0359 4.19721 16.2269 3.29307 16.2904C2.40479 16.3379 1.37375 17.2421 1.00893 18.3207Z" fill="#DEDEDE"/>
    <path d="M358.904 15.3385C359.459 16.4172 360.601 17.3689 361.49 17.4482C362.362 17.5275 362.616 16.7027 362.061 15.6082C361.505 14.5296 360.348 13.5937 359.475 13.5144C358.619 13.4351 358.365 14.2441 358.904 15.3385Z" fill="#DEDEDE"/>
    <path d="M353.876 14.9103C354.415 15.9889 355.557 16.9248 356.43 17.0041C357.302 17.0834 357.572 16.2586 357.032 15.18C356.493 14.1014 355.335 13.1655 354.479 13.102C353.606 13.0227 353.352 13.8317 353.876 14.9103Z" fill="#DEDEDE"/>
    <path d="M348.848 14.4978C349.371 15.5764 350.513 16.5123 351.386 16.5757C352.258 16.655 352.544 15.8302 352.004 14.7516C351.465 13.673 350.339 12.753 349.466 12.6737C348.61 12.6102 348.324 13.4192 348.848 14.4978Z" fill="#DEDEDE"/>
    <path d="M313.586 11.9281C314.03 12.975 315.109 13.895 315.981 13.9426C316.854 14.006 317.203 13.1812 316.758 12.1343C316.298 11.0715 315.236 10.1833 314.363 10.1198C313.475 10.0564 313.142 10.8653 313.586 11.9281Z" fill="#DEDEDE"/>
    <path d="M171.557 6.94756C171.637 7.96273 172.414 8.78756 173.302 8.78756C174.19 8.78756 174.841 7.97859 174.746 6.96342C174.666 5.94825 173.873 5.13928 173.001 5.13928C172.128 5.13928 171.478 5.94825 171.557 6.94756Z" fill="#DEDEDE"/>
    <path d="M161.357 6.93168C161.405 7.94685 162.166 8.77168 163.055 8.77168C163.943 8.77168 164.609 7.94685 164.561 6.94754C164.498 5.93236 163.737 5.1234 162.864 5.1234C161.992 5.10754 161.31 5.9165 161.357 6.93168Z" fill="#DEDEDE"/>
    <path d="M156.266 6.93156C156.298 7.94673 157.043 8.75569 157.948 8.75569C158.836 8.75569 159.518 7.93087 159.47 6.91569C159.423 5.90052 158.677 5.09155 157.805 5.09155C156.917 5.10741 156.234 5.93225 156.266 6.93156Z" fill="#DEDEDE"/>
    <path d="M79.7163 8.35921C79.5418 9.37438 80.1287 10.1833 81.017 10.1516C81.9052 10.1199 82.7618 9.26335 82.9204 8.24818C83.079 7.233 82.4921 6.4399 81.6197 6.47162C80.7473 6.50335 79.8907 7.34404 79.7163 8.35921Z" fill="#DEDEDE"/>
    <path d="M74.6084 8.54964C74.418 9.56481 74.989 10.3738 75.8932 10.3421C76.7815 10.3103 77.6539 9.45377 77.8283 8.4386C78.0028 7.42343 77.4318 6.63033 76.5435 6.66205C75.6552 6.67791 74.7987 7.53446 74.6084 8.54964Z" fill="#DEDEDE"/>
    <path d="M69.5012 8.75557C69.295 9.77074 69.866 10.5797 70.7543 10.548C71.6425 10.5163 72.515 9.6597 72.7053 8.62867C72.8957 7.6135 72.3246 6.82039 71.4522 6.85212C70.5798 6.88384 69.7074 7.72453 69.5012 8.75557Z" fill="#DEDEDE"/>
    <path d="M64.392 8.96198C64.1858 9.97716 64.7252 10.7861 65.6134 10.7544C66.5017 10.7227 67.39 9.85026 67.5962 8.83509C67.8024 7.81991 67.2472 7.02681 66.3748 7.05854C65.4865 7.09026 64.5982 7.93095 64.392 8.96198Z" fill="#DEDEDE"/>
    <path d="M59.2853 9.18403C59.0632 10.2151 59.6025 11.0082 60.4908 10.9764C61.3791 10.9447 62.2833 10.0723 62.4895 9.05713C62.7115 8.04196 62.1722 7.24886 61.2839 7.28058C60.4115 7.29644 59.5074 8.153 59.2853 9.18403Z" fill="#DEDEDE"/>
    <path d="M54.178 9.40609C53.9401 10.4371 54.4635 11.2302 55.3518 11.1985C56.2401 11.1509 57.1442 10.2944 57.3822 9.26333C57.6042 8.23229 57.0807 7.43919 56.2083 7.48678C55.32 7.5185 54.4159 8.39091 54.178 9.40609Z" fill="#DEDEDE"/>
    <path d="M49.0704 9.644C48.8166 10.675 49.3401 11.4681 50.2284 11.4364C51.1166 11.3888 52.0367 10.5164 52.2746 9.50124C52.5125 8.47021 52.0049 7.6771 51.1166 7.72469C50.2442 7.75641 49.3242 8.62883 49.0704 9.644Z" fill="#DEDEDE"/>
    <path d="M43.963 9.89753C43.6934 10.9286 44.201 11.7217 45.0893 11.6899C45.9775 11.6424 46.9134 10.7699 47.1672 9.73891C47.421 8.70788 46.9133 7.91477 46.0409 7.96236C45.1527 8.00995 44.2168 8.88236 43.963 9.89753Z" fill="#DEDEDE"/>
    <path d="M38.856 10.1674C38.5705 11.1985 39.0781 12.0074 39.9664 11.9598C40.8547 11.9123 41.7906 11.0398 42.0602 10.0088C42.3299 8.97777 41.8381 8.18467 40.9499 8.23226C40.0616 8.26398 39.1257 9.1364 38.856 10.1674Z" fill="#DEDEDE"/>
    <path d="M33.7473 10.4528C33.4618 11.4839 33.9377 12.2928 34.826 12.2452C35.7142 12.1976 36.666 11.3094 36.9515 10.2783C37.237 9.24731 36.7453 8.4542 35.8729 8.50179C34.9846 8.54938 34.0328 9.42179 33.7473 10.4528Z" fill="#DEDEDE"/>
    <path d="M28.6242 10.7385C28.3228 11.7695 28.7987 12.5785 29.6869 12.5309C30.5752 12.4833 31.5428 11.595 31.8283 10.564C32.1297 9.53295 31.6539 8.73985 30.7656 8.78743C29.8932 8.81916 28.9414 9.70743 28.6242 10.7385Z" fill="#DEDEDE"/>
    <path d="M23.5163 11.0401C23.1991 12.0711 23.6591 12.8801 24.5473 12.8325C25.4356 12.7849 26.4191 11.8967 26.7204 10.8498C27.0218 9.81872 26.5618 9.02561 25.6894 9.0732C24.817 9.12079 23.8494 9.9932 23.5163 11.0401Z" fill="#DEDEDE"/>
    <path d="M18.4094 11.3412C18.0763 12.3881 18.5363 13.1812 19.4245 13.1337C20.3128 13.0861 21.2962 12.1819 21.6135 11.1509C21.9307 10.1199 21.4866 9.32676 20.5983 9.37435C19.7259 9.42193 18.7425 10.3102 18.4094 11.3412Z" fill="#DEDEDE"/>
    <path d="M13.3018 11.6743C12.9528 12.7212 13.397 13.5143 14.2852 13.4667C15.1735 13.4033 16.1728 12.515 16.5059 11.4681C16.839 10.4212 16.3949 9.6281 15.5225 9.69154C14.6501 9.73913 13.6508 10.6274 13.3018 11.6743Z" fill="#DEDEDE"/>
    <path d="M-2.02228 12.7054C-2.40297 13.7523 -1.99055 14.5613 -1.10228 14.4978C-0.214 14.4344 0.817038 13.5303 1.18187 12.4834C1.54669 11.4365 1.13428 10.6434 0.261863 10.7068C-0.610551 10.7544 -1.62573 11.6585 -2.02228 12.7054Z" fill="#DEDEDE"/>
    <path d="M335.936 7.7089C336.444 8.7558 337.538 9.67579 338.411 9.73924C339.283 9.80269 339.569 8.99373 339.061 7.94683C338.553 6.88407 337.443 5.99579 336.586 5.93235C335.73 5.85304 335.444 6.64614 335.936 7.7089Z" fill="#DEDEDE"/>
    <path d="M311.064 6.01173C311.509 7.04277 312.571 7.94691 313.444 7.99449C314.316 8.05794 314.649 7.24897 314.205 6.20208C313.761 5.15518 312.698 4.28277 311.842 4.21932C310.969 4.17173 310.636 4.96484 311.064 6.01173Z" fill="#DEDEDE"/>
    <path d="M301.118 5.39264C301.531 6.42368 302.578 7.31195 303.45 7.35954C304.322 7.40713 304.671 6.61402 304.243 5.58299C303.815 4.55196 302.768 3.67954 301.911 3.63196C301.055 3.58437 300.69 4.36161 301.118 5.39264Z" fill="#DEDEDE"/>
    <path d="M276.184 4.0607C276.533 5.07587 277.532 5.94828 278.404 5.99587C279.277 6.04346 279.689 5.23449 279.324 4.21932C278.959 3.20414 277.96 2.34759 277.104 2.30001C276.247 2.25242 275.835 3.04552 276.184 4.0607Z" fill="#DEDEDE"/>
    <path d="M271.187 3.82242C271.536 4.83759 272.519 5.69414 273.376 5.74173C274.248 5.78932 274.661 4.98035 274.312 3.96518C273.963 2.95001 272.979 2.09345 272.123 2.06173C271.266 2.03 270.854 2.80725 271.187 3.82242Z" fill="#DEDEDE"/>
    <path d="M266.206 3.60049C266.539 4.61567 267.507 5.47222 268.379 5.5198C269.252 5.56739 269.68 4.75842 269.347 3.74325C269.014 2.72808 268.03 1.87153 267.174 1.8398C266.301 1.79222 265.873 2.58532 266.206 3.60049Z" fill="#DEDEDE"/>
    <path d="M261.21 3.37831C261.527 4.39348 262.495 5.25003 263.367 5.28176C264.24 5.31348 264.684 4.52038 264.351 3.5052C264.018 2.49003 263.066 1.64934 262.193 1.61762C261.337 1.58589 260.893 2.379 261.21 3.37831Z" fill="#DEDEDE"/>
    <path d="M256.213 3.17225C256.514 4.18743 257.466 5.02812 258.338 5.0757C259.211 5.10743 259.655 4.31432 259.353 3.29915C259.036 2.28398 258.084 1.44329 257.228 1.41156C256.371 1.37984 255.911 2.17294 256.213 3.17225Z" fill="#DEDEDE"/>
    <path d="M246.22 2.80765C246.506 3.80696 247.426 4.66351 248.298 4.69523C249.17 4.72696 249.646 3.93385 249.361 2.91868C249.075 1.90351 248.139 1.07868 247.283 1.04696C246.426 1.01523 245.95 1.79248 246.22 2.80765Z" fill="#DEDEDE"/>
    <path d="M241.223 2.63287C241.493 3.63218 242.413 4.47287 243.285 4.50459C244.158 4.53632 244.634 3.74321 244.364 2.72804C244.094 1.72873 243.174 0.888042 242.302 0.856318C241.445 0.840456 240.954 1.6177 241.223 2.63287Z" fill="#DEDEDE"/>
    <path d="M236.227 2.45832C236.481 3.45763 237.385 4.29832 238.258 4.33004C239.13 4.36176 239.622 3.56866 239.368 2.55349C239.114 1.55418 238.194 0.713488 237.338 0.697626C236.465 0.681764 235.974 1.459 236.227 2.45832Z" fill="#DEDEDE"/>
    <path d="M231.231 2.31568C231.468 3.31499 232.373 4.15568 233.245 4.18741C234.117 4.21913 234.625 3.42603 234.371 2.41086C234.117 1.41155 233.229 0.586721 232.357 0.554997C231.5 0.523273 230.993 1.31637 231.231 2.31568Z" fill="#DEDEDE"/>
    <path d="M196.176 1.53876C196.318 2.53807 197.143 3.34704 198.016 3.3629C198.888 3.37876 199.475 2.5698 199.316 1.58635C199.158 0.587037 198.333 -0.221929 197.476 -0.221929C196.62 -0.237792 196.033 0.539451 196.176 1.53876Z" fill="#DEDEDE"/>
    <path d="M186.165 1.41156C186.276 2.39501 187.085 3.21984 187.958 3.2357C188.83 3.25156 189.433 2.44259 189.306 1.45914C189.179 0.475697 188.37 -0.333269 187.514 -0.349131C186.641 -0.364993 186.038 0.428111 186.165 1.41156Z" fill="#DEDEDE"/>
    <path d="M176.126 1.33208C176.221 2.31553 176.998 3.14036 177.871 3.14036C178.743 3.14036 179.378 2.34726 179.267 1.34795C179.172 0.364498 178.378 -0.444468 177.522 -0.444468C176.665 -0.46033 176.047 0.332773 176.126 1.33208Z" fill="#DEDEDE"/>
    <path d="M161.088 1.28472C161.135 2.26817 161.881 3.07714 162.753 3.07714C163.626 3.07714 164.292 2.26817 164.229 1.28472C164.165 0.301275 163.42 -0.491828 162.563 -0.50769C161.691 -0.50769 161.04 0.301275 161.088 1.28472Z" fill="#DEDEDE"/>
    <path d="M95.7683 2.20447C95.6414 3.20378 96.2442 3.99688 97.1166 3.96516C97.989 3.93344 98.798 3.10861 98.9249 2.12516C99.0518 1.12585 98.4332 0.348608 97.5766 0.380332C96.7042 0.380332 95.8952 1.20516 95.7683 2.20447Z" fill="#DEDEDE"/>
    <path d="M90.7401 2.34741C90.5974 3.34672 91.1842 4.13982 92.0725 4.1081C92.9449 4.07637 93.7698 3.25155 93.8967 2.25224C94.0236 1.25293 93.4367 0.475683 92.5642 0.507407C91.7077 0.523269 90.8829 1.3481 90.7401 2.34741Z" fill="#DEDEDE"/>
    <path d="M80.6832 2.66442C80.5087 3.66373 81.0798 4.45683 81.968 4.42511C82.8404 4.39338 83.6811 3.55269 83.8556 2.55338C84.0142 1.55407 83.4432 0.776831 82.5707 0.808555C81.6983 0.840279 80.8577 1.66511 80.6832 2.66442Z" fill="#DEDEDE"/>
    <path d="M60.5549 3.44188C60.3328 4.44119 60.8562 5.23429 61.7445 5.20257C62.6169 5.17084 63.5052 4.31429 63.7273 3.31498C63.9493 2.31567 63.41 1.53843 62.5376 1.57015C61.6652 1.60188 60.7769 2.44257 60.5549 3.44188Z" fill="#DEDEDE"/>
    <path d="M55.5267 3.66404C55.2888 4.66335 55.8122 5.45646 56.6846 5.42473C57.557 5.39301 58.4612 4.53646 58.6832 3.53715C58.9053 2.53784 58.3977 1.7606 57.5253 1.79232C56.6529 1.82404 55.7488 2.66473 55.5267 3.66404Z" fill="#DEDEDE"/>
    <path d="M50.4823 3.90195C50.2285 4.91712 50.7361 5.69436 51.6244 5.66264C52.4968 5.61505 53.4168 4.77436 53.6547 3.75919C53.8927 2.75988 53.3851 1.96678 52.5127 2.01436C51.6403 2.04609 50.7361 2.88678 50.4823 3.90195Z" fill="#DEDEDE"/>
    <path d="M45.4533 4.1399C45.1837 5.15508 45.6913 5.93232 46.5637 5.90059C47.4361 5.85301 48.3561 4.99646 48.6099 3.99715C48.8637 2.98197 48.372 2.20473 47.4996 2.25232C46.6271 2.28404 45.7071 3.14059 45.4533 4.1399Z" fill="#DEDEDE"/>
    <path d="M40.4097 4.39368C40.1401 5.40885 40.6159 6.18609 41.5042 6.15437C42.3766 6.10678 43.3125 5.25023 43.5821 4.23506C43.8518 3.21989 43.3601 2.44265 42.4877 2.49023C41.6152 2.53782 40.6952 3.37851 40.4097 4.39368Z" fill="#DEDEDE"/>
    <path d="M35.3812 4.66297C35.0957 5.67814 35.5716 6.47124 36.444 6.42366C37.3164 6.37607 38.2682 5.51952 38.5378 4.50435C38.8233 3.48918 38.3474 2.71193 37.475 2.75952C36.6185 2.79124 35.6667 3.6478 35.3812 4.66297Z" fill="#DEDEDE"/>
    <path d="M25.309 5.21838C24.9917 6.23355 25.4517 7.02666 26.3241 6.97907C27.1965 6.93148 28.1641 6.05907 28.4655 5.0439C28.7669 4.02873 28.3227 3.25148 27.4503 3.29907C26.5938 3.34666 25.6262 4.20321 25.309 5.21838Z" fill="#DEDEDE"/>
    <path d="M20.281 5.51976C19.9479 6.53494 20.392 7.32804 21.2803 7.28045C22.1527 7.23287 23.1362 6.36046 23.4534 5.32942C23.7707 4.31425 23.3265 3.53701 22.4541 3.58459C21.5817 3.63218 20.5982 4.50459 20.281 5.51976Z" fill="#DEDEDE"/>
    </svg>
    <svg class="webmap hidden md:block absolute left-0 top-0 w-full" width="645" height="455" viewBox="0 0 645 455" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M321.168 451.221C321.231 452.763 322.837 454.031 324.76 454.052C326.683 454.073 328.183 452.827 328.119 451.284C328.056 449.742 326.45 448.453 324.527 448.453C322.605 448.411 321.105 449.657 321.168 451.221Z" fill="#DEDEDE"/>
<path d="M353.897 442.748C353.981 444.312 355.608 445.601 357.51 445.622C359.432 445.643 360.911 444.396 360.806 442.833C360.721 441.269 359.094 439.959 357.193 439.938C355.27 439.938 353.812 441.185 353.897 442.748Z" fill="#DEDEDE"/>
<path d="M342.868 442.622C342.952 444.185 344.558 445.474 346.481 445.495C348.403 445.516 349.882 444.27 349.798 442.706C349.713 441.143 348.108 439.854 346.185 439.812C344.283 439.79 342.783 441.058 342.868 442.622Z" fill="#DEDEDE"/>
<path d="M331.838 442.495C331.901 444.058 333.507 445.347 335.43 445.368C337.352 445.389 338.831 444.143 338.768 442.579C338.684 441.016 337.078 439.727 335.176 439.706C333.254 439.663 331.775 440.931 331.838 442.495Z" fill="#DEDEDE"/>
<path d="M320.81 442.41C320.873 443.973 322.479 445.262 324.401 445.262C326.324 445.283 327.824 444.036 327.74 442.452C327.676 440.888 326.071 439.599 324.148 439.578C322.246 439.578 320.746 440.825 320.81 442.41Z" fill="#DEDEDE"/>
<path d="M309.78 442.326C309.844 443.889 311.428 445.157 313.351 445.178C315.274 445.199 316.774 443.931 316.71 442.368C316.647 440.804 315.041 439.516 313.14 439.494C311.238 439.494 309.717 440.762 309.78 442.326Z" fill="#DEDEDE"/>
<path d="M298.75 442.283C298.793 443.847 300.398 445.114 302.3 445.136C304.223 445.136 305.723 443.889 305.681 442.326C305.638 440.762 304.033 439.473 302.131 439.473C300.208 439.431 298.687 440.699 298.75 442.283Z" fill="#DEDEDE"/>
<path d="M353.389 433.853C353.474 435.438 355.101 436.748 357.002 436.769C358.904 436.79 360.383 435.543 360.277 433.959C360.171 432.374 358.566 431.064 356.664 431.043C354.762 431.001 353.283 432.268 353.389 433.853Z" fill="#DEDEDE"/>
<path d="M342.403 433.705C342.487 435.289 344.093 436.578 345.995 436.599C347.896 436.621 349.375 435.374 349.291 433.789C349.206 432.205 347.579 430.895 345.678 430.874C343.797 430.852 342.318 432.12 342.403 433.705Z" fill="#DEDEDE"/>
<path d="M331.437 433.6C331.521 435.184 333.127 436.473 335.028 436.494C336.93 436.515 338.409 435.248 338.346 433.663C338.261 432.078 336.655 430.768 334.754 430.747C332.831 430.726 331.352 432.015 331.437 433.6Z" fill="#DEDEDE"/>
<path d="M320.45 433.494C320.513 435.079 322.119 436.368 324.02 436.389C325.922 436.41 327.422 435.142 327.338 433.557C327.274 431.973 325.668 430.663 323.767 430.663C321.865 430.642 320.386 431.909 320.45 433.494Z" fill="#DEDEDE"/>
<path d="M309.463 433.431C309.526 435.015 311.111 436.304 313.034 436.304C314.935 436.325 316.435 435.057 316.372 433.473C316.309 431.888 314.703 430.599 312.801 430.578C310.9 430.557 309.399 431.846 309.463 433.431Z" fill="#DEDEDE"/>
<path d="M363.763 425C363.869 426.606 365.496 427.916 367.376 427.958C369.278 427.98 370.715 426.733 370.609 425.127C370.503 423.521 368.876 422.19 366.975 422.169C365.094 422.127 363.658 423.395 363.763 425Z" fill="#DEDEDE"/>
<path d="M352.84 424.852C352.946 426.458 354.551 427.768 356.453 427.789C358.355 427.81 359.812 426.542 359.707 424.937C359.601 423.331 357.995 422.021 356.094 421.979C354.213 421.957 352.755 423.246 352.84 424.852Z" fill="#DEDEDE"/>
<path d="M341.917 424.705C342.001 426.31 343.607 427.62 345.508 427.642C347.41 427.663 348.868 426.395 348.783 424.789C348.678 423.183 347.072 421.873 345.191 421.852C343.29 421.831 341.832 423.099 341.917 424.705Z" fill="#DEDEDE"/>
<path d="M330.994 424.598C331.078 426.204 332.684 427.514 334.586 427.535C336.487 427.556 337.966 426.289 337.86 424.683C337.776 423.077 336.17 421.767 334.269 421.746C332.388 421.704 330.909 422.993 330.994 424.598Z" fill="#DEDEDE"/>
<path d="M320.049 424.494C320.112 426.099 321.718 427.388 323.62 427.409C325.521 427.43 327 426.142 326.916 424.536C326.831 422.93 325.246 421.62 323.345 421.599C321.464 421.599 319.985 422.888 320.049 424.494Z" fill="#DEDEDE"/>
<path d="M309.104 424.43C309.167 426.035 310.752 427.324 312.654 427.345C314.555 427.366 316.034 426.078 315.971 424.472C315.907 422.866 314.302 421.556 312.421 421.556C310.541 421.535 309.041 422.824 309.104 424.43Z" fill="#DEDEDE"/>
<path d="M298.159 424.366C298.201 425.972 299.786 427.261 301.709 427.282C303.61 427.282 305.11 426.014 305.047 424.408C304.984 422.803 303.399 421.493 301.497 421.493C299.596 421.472 298.117 422.76 298.159 424.366Z" fill="#DEDEDE"/>
<path d="M287.215 424.345C287.257 425.951 288.842 427.24 290.744 427.24C292.645 427.24 294.145 425.951 294.103 424.366C294.061 422.761 292.476 421.451 290.575 421.451C288.673 421.451 287.173 422.74 287.215 424.345Z" fill="#DEDEDE"/>
<path d="M428.142 417.309C428.311 418.936 429.959 420.267 431.84 420.331C433.72 420.373 435.094 419.126 434.925 417.5C434.756 415.894 433.086 414.52 431.227 414.478C429.368 414.415 427.973 415.682 428.142 417.309Z" fill="#DEDEDE"/>
<path d="M363.15 415.915C363.255 417.521 364.882 418.852 366.763 418.894C368.643 418.915 370.08 417.647 369.974 416.02C369.847 414.415 368.242 413.062 366.361 413.041C364.46 412.999 363.044 414.288 363.15 415.915Z" fill="#DEDEDE"/>
<path d="M352.291 415.746C352.397 417.352 354.003 418.683 355.904 418.704C357.785 418.725 359.242 417.458 359.137 415.831C359.031 414.225 357.404 412.873 355.524 412.852C353.622 412.852 352.185 414.14 352.291 415.746Z" fill="#DEDEDE"/>
<path d="M341.409 415.619C341.493 417.225 343.099 418.556 345.001 418.577C346.881 418.598 348.339 417.309 348.233 415.704C348.128 414.077 346.522 412.746 344.641 412.724C342.761 412.703 341.324 413.992 341.409 415.619Z" fill="#DEDEDE"/>
<path d="M330.529 415.493C330.613 417.098 332.219 418.429 334.1 418.451C335.98 418.472 337.459 417.183 337.353 415.556C337.269 413.95 335.663 412.619 333.783 412.598C331.902 412.577 330.444 413.887 330.529 415.493Z" fill="#DEDEDE"/>
<path d="M319.647 415.408C319.731 417.014 321.316 418.345 323.196 418.366C325.098 418.387 326.556 417.098 326.471 415.471C326.387 413.865 324.802 412.534 322.9 412.513C321.041 412.492 319.562 413.781 319.647 415.408Z" fill="#DEDEDE"/>
<path d="M308.745 415.344C308.809 416.95 310.393 418.281 312.295 418.281C314.196 418.302 315.654 416.992 315.591 415.386C315.527 413.78 313.922 412.449 312.041 412.428C310.161 412.407 308.682 413.717 308.745 415.344Z" fill="#DEDEDE"/>
<path d="M297.864 415.281C297.928 416.886 299.491 418.196 301.393 418.217C303.294 418.217 304.773 416.929 304.71 415.323C304.647 413.717 303.062 412.386 301.181 412.386C299.28 412.365 297.801 413.675 297.864 415.281Z" fill="#DEDEDE"/>
<path d="M286.961 415.26C287.003 416.866 288.588 418.176 290.468 418.197C292.37 418.197 293.849 416.908 293.806 415.281C293.743 413.675 292.18 412.344 290.299 412.344C288.398 412.323 286.919 413.633 286.961 415.26Z" fill="#DEDEDE"/>
<path d="M437.904 408.436C438.094 410.062 439.763 411.436 441.622 411.478C443.482 411.541 444.834 410.253 444.644 408.626C444.453 406.999 442.784 405.625 440.925 405.562C439.066 405.52 437.713 406.809 437.904 408.436Z" fill="#DEDEDE"/>
<path d="M427.17 408.119C427.36 409.745 429.008 411.098 430.867 411.161C432.727 411.203 434.1 409.936 433.91 408.309C433.72 406.682 432.072 405.308 430.212 405.266C428.353 405.203 426.98 406.492 427.17 408.119Z" fill="#DEDEDE"/>
<path d="M373.292 406.914C373.418 408.541 375.045 409.893 376.926 409.914C378.806 409.957 380.222 408.668 380.074 407.02C379.947 405.393 378.32 404.041 376.44 403.998C374.58 403.977 373.165 405.287 373.292 406.914Z" fill="#DEDEDE"/>
<path d="M362.497 406.745C362.623 408.372 364.229 409.724 366.11 409.745C367.99 409.767 369.406 408.478 369.279 406.851C369.152 405.224 367.525 403.872 365.666 403.851C363.807 403.808 362.37 405.097 362.497 406.745Z" fill="#DEDEDE"/>
<path d="M351.678 406.576C351.784 408.203 353.39 409.534 355.27 409.577C357.151 409.598 358.587 408.309 358.461 406.682C358.355 405.055 356.728 403.703 354.869 403.682C353.009 403.639 351.573 404.949 351.678 406.576Z" fill="#DEDEDE"/>
<path d="M340.86 406.449C340.966 408.076 342.55 409.407 344.431 409.429C346.311 409.45 347.748 408.161 347.642 406.534C347.537 404.907 345.931 403.555 344.05 403.534C342.212 403.513 340.775 404.823 340.86 406.449Z" fill="#DEDEDE"/>
<path d="M330.042 406.323C330.126 407.95 331.732 409.281 333.612 409.302C335.493 409.323 336.951 408.013 336.845 406.386C336.761 404.759 335.155 403.407 333.274 403.386C331.394 403.386 329.957 404.696 330.042 406.323Z" fill="#DEDEDE"/>
<path d="M319.204 406.238C319.288 407.865 320.873 409.196 322.753 409.217C324.634 409.238 326.092 407.929 326.007 406.302C325.923 404.675 324.338 403.344 322.457 403.323C320.598 403.301 319.14 404.611 319.204 406.238Z" fill="#DEDEDE"/>
<path d="M308.365 406.153C308.428 407.78 310.013 409.111 311.893 409.111C313.774 409.132 315.253 407.822 315.168 406.195C315.084 404.569 313.52 403.237 311.64 403.216C309.78 403.216 308.301 404.526 308.365 406.153Z" fill="#DEDEDE"/>
<path d="M297.525 406.112C297.588 407.738 299.152 409.07 301.032 409.07C302.913 409.07 304.392 407.76 304.328 406.133C304.265 404.506 302.68 403.175 300.821 403.154C298.94 403.175 297.483 404.485 297.525 406.112Z" fill="#DEDEDE"/>
<path d="M286.687 406.09C286.729 407.717 288.293 409.048 290.194 409.048C292.075 409.048 293.554 407.738 293.511 406.112C293.448 404.485 291.885 403.154 290.004 403.154C288.124 403.132 286.645 404.464 286.687 406.09Z" fill="#DEDEDE"/>
<path d="M436.805 399.181C436.995 400.83 438.664 402.203 440.524 402.245C442.383 402.309 443.714 401.02 443.503 399.372C443.292 397.724 441.623 396.35 439.784 396.287C437.946 396.245 436.615 397.533 436.805 399.181Z" fill="#DEDEDE"/>
<path d="M426.135 398.864C426.325 400.512 427.973 401.885 429.832 401.928C431.692 401.97 433.044 400.702 432.833 399.054C432.642 397.406 430.973 396.033 429.135 395.97C427.276 395.927 425.945 397.237 426.135 398.864Z" fill="#DEDEDE"/>
<path d="M383.287 397.871C383.435 399.519 385.062 400.872 386.921 400.914C388.78 400.956 390.175 399.646 390.027 398.019C389.879 396.371 388.252 395.019 386.393 394.977C384.534 394.913 383.16 396.223 383.287 397.871Z" fill="#DEDEDE"/>
<path d="M372.553 397.66C372.68 399.308 374.307 400.66 376.166 400.681C378.025 400.723 379.441 399.413 379.293 397.765C379.145 396.117 377.518 394.765 375.68 394.723C373.821 394.702 372.426 396.012 372.553 397.66Z" fill="#DEDEDE"/>
<path d="M361.82 397.491C361.947 399.139 363.553 400.491 365.433 400.512C367.292 400.533 368.708 399.244 368.581 397.596C368.454 395.948 366.828 394.596 364.968 394.554C363.088 394.533 361.693 395.843 361.82 397.491Z" fill="#DEDEDE"/>
<path d="M351.065 397.322C351.171 398.97 352.777 400.322 354.657 400.343C356.517 400.364 357.953 399.054 357.827 397.427C357.7 395.779 356.094 394.427 354.235 394.406C352.375 394.364 350.939 395.695 351.065 397.322Z" fill="#DEDEDE"/>
<path d="M340.289 397.195C340.395 398.843 341.98 400.174 343.86 400.217C345.74 400.238 347.156 398.928 347.05 397.28C346.945 395.632 345.339 394.279 343.48 394.258C341.62 394.237 340.205 395.547 340.289 397.195Z" fill="#DEDEDE"/>
<path d="M329.535 397.089C329.619 398.737 331.225 400.068 333.084 400.089C334.965 400.11 336.402 398.801 336.296 397.153C336.19 395.504 334.606 394.152 332.746 394.131C330.866 394.11 329.429 395.441 329.535 397.089Z" fill="#DEDEDE"/>
<path d="M318.76 396.984C318.844 398.632 320.429 399.963 322.288 399.984C324.169 400.005 325.606 398.695 325.521 397.047C325.436 395.399 323.852 394.047 321.993 394.047C320.133 394.026 318.675 395.336 318.76 396.984Z" fill="#DEDEDE"/>
<path d="M307.984 396.92C308.047 398.568 309.632 399.899 311.512 399.92C313.393 399.942 314.851 398.61 314.766 396.962C314.682 395.314 313.118 393.983 311.238 393.962C309.357 393.941 307.899 395.272 307.984 396.92Z" fill="#DEDEDE"/>
<path d="M297.187 396.857C297.25 398.505 298.814 399.836 300.694 399.836C302.575 399.836 304.033 398.526 303.969 396.878C303.906 395.23 302.342 393.899 300.462 393.878C298.603 393.899 297.145 395.23 297.187 396.857Z" fill="#DEDEDE"/>
<path d="M286.411 396.836C286.453 398.484 288.017 399.815 289.897 399.815C291.778 399.815 293.236 398.505 293.193 396.857C293.13 395.209 291.566 393.878 289.707 393.878C287.827 393.857 286.348 395.188 286.411 396.836Z" fill="#DEDEDE"/>
<path d="M275.615 396.836C275.657 398.484 277.199 399.815 279.08 399.815C280.96 399.815 282.439 398.484 282.397 396.857C282.355 395.209 280.791 393.878 278.932 393.878C277.051 393.857 275.572 395.188 275.615 396.836Z" fill="#DEDEDE"/>
<path d="M435.664 389.842C435.875 391.49 437.523 392.885 439.383 392.927C441.221 392.99 442.552 391.68 442.341 390.032C442.129 388.384 440.46 386.99 438.622 386.927C436.763 386.884 435.453 388.194 435.664 389.842Z" fill="#DEDEDE"/>
<path d="M425.036 389.547C425.226 391.195 426.874 392.589 428.734 392.632C430.572 392.674 431.903 391.385 431.713 389.737C431.501 388.089 429.853 386.695 428.015 386.631C426.177 386.589 424.846 387.899 425.036 389.547Z" fill="#DEDEDE"/>
<path d="M382.462 388.532C382.61 390.18 384.237 391.554 386.096 391.596C387.956 391.638 389.329 390.328 389.16 388.68C388.991 387.032 387.364 385.638 385.526 385.617C383.667 385.574 382.314 386.884 382.462 388.532Z" fill="#DEDEDE"/>
<path d="M371.771 388.342C371.919 389.99 373.525 391.364 375.384 391.406C377.244 391.448 378.617 390.138 378.469 388.469C378.321 386.821 376.694 385.448 374.856 385.405C373.018 385.363 371.645 386.694 371.771 388.342Z" fill="#DEDEDE"/>
<path d="M361.102 388.173C361.229 389.821 362.834 391.194 364.694 391.216C366.553 391.237 367.947 389.927 367.821 388.279C367.694 386.631 366.067 385.257 364.229 385.215C362.369 385.194 360.975 386.504 361.102 388.173Z" fill="#DEDEDE"/>
<path d="M350.411 388.004C350.537 389.652 352.122 391.026 353.981 391.047C355.841 391.068 357.256 389.758 357.13 388.11C357.003 386.462 355.397 385.089 353.559 385.067C351.7 385.025 350.284 386.356 350.411 388.004Z" fill="#DEDEDE"/>
<path d="M339.698 387.877C339.804 389.525 341.389 390.898 343.248 390.919C345.107 390.941 346.523 389.631 346.417 387.961C346.312 386.313 344.706 384.94 342.847 384.919C341.008 384.898 339.593 386.208 339.698 387.877Z" fill="#DEDEDE"/>
<path d="M329.007 387.75C329.113 389.398 330.697 390.75 332.557 390.771C334.416 390.793 335.853 389.462 335.747 387.814C335.641 386.165 334.057 384.792 332.197 384.771C330.338 384.771 328.901 386.102 329.007 387.75Z" fill="#DEDEDE"/>
<path d="M318.296 387.666C318.38 389.314 319.965 390.667 321.824 390.688C323.683 390.709 325.12 389.378 325.035 387.73C324.951 386.082 323.366 384.708 321.507 384.708C319.648 384.687 318.211 386.018 318.296 387.666Z" fill="#DEDEDE"/>
<path d="M307.562 387.603C307.625 389.251 309.21 390.603 311.069 390.624C312.928 390.645 314.386 389.314 314.302 387.645C314.217 385.997 312.654 384.645 310.794 384.624C308.935 384.602 307.498 385.934 307.562 387.603Z" fill="#DEDEDE"/>
<path d="M296.85 387.54C296.913 389.188 298.477 390.54 300.336 390.54C302.195 390.54 303.653 389.209 303.59 387.561C303.526 385.913 301.963 384.56 300.103 384.539C298.244 384.56 296.786 385.892 296.85 387.54Z" fill="#DEDEDE"/>
<path d="M286.116 387.518C286.158 389.166 287.722 390.518 289.581 390.518C291.44 390.518 292.92 389.187 292.856 387.539C292.793 385.891 291.25 384.539 289.391 384.539C287.532 384.518 286.074 385.87 286.116 387.518Z" fill="#DEDEDE"/>
<path d="M275.383 387.518C275.425 389.166 276.967 390.519 278.827 390.519C280.686 390.519 282.165 389.187 282.123 387.518C282.08 385.87 280.538 384.518 278.679 384.518C276.82 384.518 275.34 385.849 275.383 387.518Z" fill="#DEDEDE"/>
<path d="M434.459 380.461C434.67 382.13 436.34 383.525 438.157 383.567C439.995 383.631 441.284 382.321 441.072 380.651C440.84 378.982 439.192 377.588 437.354 377.524C435.537 377.482 434.248 378.792 434.459 380.461Z" fill="#DEDEDE"/>
<path d="M423.916 380.165C424.127 381.834 425.775 383.229 427.613 383.271C429.452 383.313 430.762 382.025 430.55 380.355C430.339 378.686 428.691 377.292 426.853 377.25C425.015 377.186 423.705 378.496 423.916 380.165Z" fill="#DEDEDE"/>
<path d="M402.766 379.616C402.957 381.285 404.584 382.659 406.422 382.701C408.26 382.743 409.591 381.433 409.401 379.764C409.211 378.095 407.563 376.7 405.746 376.658C403.929 376.616 402.597 377.947 402.766 379.616Z" fill="#DEDEDE"/>
<path d="M392.182 379.363C392.351 381.032 393.978 382.406 395.816 382.448C397.654 382.49 399.006 381.18 398.837 379.511C398.668 377.842 397.02 376.447 395.203 376.405C393.365 376.384 392.013 377.694 392.182 379.363Z" fill="#DEDEDE"/>
<path d="M381.575 379.151C381.723 380.82 383.35 382.194 385.188 382.236C387.026 382.278 388.4 380.968 388.23 379.299C388.061 377.63 386.435 376.257 384.618 376.214C382.779 376.151 381.427 377.482 381.575 379.151Z" fill="#DEDEDE"/>
<path d="M370.968 378.961C371.116 380.63 372.722 382.004 374.56 382.046C376.398 382.088 377.772 380.757 377.624 379.088C377.476 377.419 375.849 376.045 374.011 376.003C372.194 375.961 370.821 377.292 370.968 378.961Z" fill="#DEDEDE"/>
<path d="M360.362 378.771C360.489 380.44 362.094 381.813 363.954 381.835C365.792 381.856 367.186 380.546 367.038 378.877C366.891 377.207 365.285 375.834 363.447 375.792C361.608 375.771 360.214 377.102 360.362 378.771Z" fill="#DEDEDE"/>
<path d="M349.735 378.623C349.862 380.292 351.446 381.666 353.306 381.687C355.144 381.708 356.538 380.398 356.412 378.729C356.285 377.06 354.679 375.686 352.841 375.665C351.003 375.623 349.608 376.954 349.735 378.623Z" fill="#DEDEDE"/>
<path d="M339.086 378.475C339.191 380.144 340.776 381.496 342.635 381.539C344.495 381.56 345.889 380.229 345.762 378.559C345.636 376.89 344.051 375.517 342.213 375.496C340.375 375.475 338.98 376.827 339.086 378.475Z" fill="#DEDEDE"/>
<path d="M328.457 378.369C328.562 380.039 330.126 381.391 331.985 381.412C333.844 381.433 335.26 380.102 335.133 378.433C335.028 376.764 333.443 375.39 331.605 375.369C329.767 375.369 328.351 376.7 328.457 378.369Z" fill="#DEDEDE"/>
<path d="M317.808 378.285C317.893 379.954 319.456 381.306 321.316 381.328C323.175 381.349 324.59 380.018 324.485 378.348C324.379 376.679 322.816 375.306 320.977 375.306C319.139 375.264 317.703 376.616 317.808 378.285Z" fill="#DEDEDE"/>
<path d="M307.139 378.2C307.224 379.869 308.787 381.222 310.626 381.243C312.485 381.264 313.922 379.912 313.837 378.242C313.753 376.573 312.189 375.221 310.351 375.2C308.492 375.2 307.076 376.531 307.139 378.2Z" fill="#DEDEDE"/>
<path d="M296.491 378.158C296.554 379.827 298.118 381.179 299.956 381.179C301.815 381.179 303.252 379.848 303.188 378.179C303.104 376.51 301.562 375.158 299.723 375.136C297.864 375.136 296.427 376.489 296.491 378.158Z" fill="#DEDEDE"/>
<path d="M285.82 378.137C285.862 379.806 287.426 381.158 289.264 381.158C291.123 381.158 292.581 379.827 292.518 378.158C292.454 376.489 290.912 375.137 289.074 375.137C287.214 375.116 285.756 376.468 285.82 378.137Z" fill="#DEDEDE"/>
<path d="M275.15 378.116C275.193 379.785 276.735 381.137 278.573 381.137C280.432 381.137 281.89 379.785 281.848 378.116C281.806 376.447 280.263 375.095 278.425 375.095C276.566 375.095 275.108 376.468 275.15 378.116Z" fill="#DEDEDE"/>
<path d="M443.671 371.334C443.903 373.003 445.573 374.419 447.39 374.482C449.207 374.545 450.496 373.235 450.242 371.566C449.988 369.897 448.319 368.482 446.523 368.418C444.706 368.334 443.439 369.665 443.671 371.334Z" fill="#DEDEDE"/>
<path d="M391.21 369.918C391.379 371.587 393.006 372.982 394.844 373.024C396.682 373.066 398.013 371.735 397.823 370.066C397.633 368.397 396.006 366.981 394.189 366.96C392.372 366.918 391.041 368.249 391.21 369.918Z" fill="#DEDEDE"/>
<path d="M380.666 369.707C380.836 371.376 382.441 372.77 384.279 372.813C386.118 372.855 387.449 371.524 387.28 369.855C387.111 368.185 385.484 366.791 383.667 366.749C381.85 366.685 380.519 368.016 380.666 369.707Z" fill="#DEDEDE"/>
<path d="M370.123 369.496C370.271 371.165 371.877 372.559 373.715 372.58C375.553 372.623 376.905 371.292 376.736 369.601C376.567 367.932 374.962 366.538 373.145 366.495C371.349 366.495 369.975 367.826 370.123 369.496Z" fill="#DEDEDE"/>
<path d="M359.58 369.327C359.728 370.996 361.313 372.39 363.151 372.411C364.989 372.432 366.363 371.101 366.215 369.432C366.067 367.763 364.461 366.369 362.644 366.347C360.806 366.305 359.454 367.636 359.58 369.327Z" fill="#DEDEDE"/>
<path d="M349.016 369.158C349.143 370.827 350.727 372.221 352.566 372.242C354.404 372.263 355.777 370.932 355.65 369.263C355.524 367.594 353.918 366.2 352.101 366.178C350.263 366.157 348.889 367.488 349.016 369.158Z" fill="#DEDEDE"/>
<path d="M338.452 369.031C338.557 370.7 340.142 372.073 341.98 372.095C343.818 372.116 345.213 370.785 345.086 369.094C344.959 367.425 343.374 366.031 341.557 366.01C339.719 366.01 338.346 367.362 338.452 369.031Z" fill="#DEDEDE"/>
<path d="M327.866 368.925C327.972 370.594 329.536 371.968 331.374 371.989C333.212 372.01 334.606 370.679 334.501 368.989C334.395 367.319 332.81 365.925 330.993 365.904C329.176 365.904 327.782 367.235 327.866 368.925Z" fill="#DEDEDE"/>
<path d="M317.302 368.82C317.387 370.489 318.95 371.862 320.789 371.883C322.627 371.905 324.042 370.552 323.937 368.883C323.831 367.214 322.268 365.841 320.451 365.82C318.612 365.798 317.197 367.151 317.302 368.82Z" fill="#DEDEDE"/>
<path d="M306.696 368.756C306.781 370.425 308.323 371.798 310.161 371.798C312 371.82 313.415 370.467 313.331 368.777C313.246 367.108 311.683 365.735 309.845 365.713C308.049 365.735 306.633 367.087 306.696 368.756Z" fill="#DEDEDE"/>
<path d="M296.11 368.714C296.174 370.383 297.716 371.756 299.575 371.756C301.414 371.756 302.85 370.425 302.766 368.735C302.681 367.066 301.139 365.692 299.301 365.671C297.484 365.671 296.047 367.023 296.11 368.714Z" fill="#DEDEDE"/>
<path d="M285.504 368.672C285.567 370.341 287.088 371.714 288.948 371.714C290.786 371.714 292.244 370.362 292.18 368.693C292.117 367.024 290.575 365.65 288.737 365.65C286.898 365.65 285.462 367.003 285.504 368.672Z" fill="#DEDEDE"/>
<path d="M400.654 360.664C400.844 362.354 402.471 363.749 404.288 363.791C406.105 363.833 407.415 362.502 407.204 360.833C406.992 359.143 405.366 357.748 403.57 357.685C401.753 357.643 400.464 358.974 400.654 360.664Z" fill="#DEDEDE"/>
<path d="M390.195 360.41C390.385 362.101 391.991 363.495 393.808 363.537C395.625 363.58 396.956 362.248 396.766 360.558C396.576 358.868 394.949 357.473 393.153 357.431C391.336 357.389 390.026 358.72 390.195 360.41Z" fill="#DEDEDE"/>
<path d="M379.736 360.199C379.905 361.889 381.51 363.284 383.328 363.326C385.145 363.368 386.476 362.037 386.307 360.347C386.138 358.657 384.511 357.262 382.715 357.22C380.898 357.178 379.567 358.509 379.736 360.199Z" fill="#DEDEDE"/>
<path d="M369.257 359.987C369.405 361.678 371.01 363.072 372.828 363.093C374.645 363.135 375.997 361.783 375.828 360.114C375.659 358.424 374.053 357.029 372.257 356.987C370.44 356.966 369.109 358.318 369.257 359.987Z" fill="#DEDEDE"/>
<path d="M358.778 359.819C358.926 361.509 360.51 362.903 362.327 362.924C364.144 362.946 365.497 361.615 365.349 359.924C365.201 358.234 363.595 356.839 361.778 356.818C359.982 356.776 358.63 358.128 358.778 359.819Z" fill="#DEDEDE"/>
<path d="M348.298 359.671C348.424 361.361 350.009 362.735 351.847 362.777C353.664 362.798 355.038 361.467 354.89 359.777C354.742 358.086 353.157 356.692 351.34 356.671C349.523 356.628 348.15 357.981 348.298 359.671Z" fill="#DEDEDE"/>
<path d="M337.797 359.523C337.923 361.213 339.487 362.586 341.325 362.608C343.142 362.629 344.537 361.277 344.41 359.607C344.283 357.917 342.698 356.523 340.881 356.501C339.043 356.48 337.67 357.833 337.797 359.523Z" fill="#DEDEDE"/>
<path d="M327.275 359.417C327.381 361.107 328.944 362.481 330.782 362.502C332.599 362.523 333.994 361.171 333.888 359.48C333.783 357.79 332.198 356.417 330.381 356.396C328.564 356.374 327.17 357.727 327.275 359.417Z" fill="#DEDEDE"/>
<path d="M316.774 359.333C316.859 361.023 318.422 362.397 320.26 362.418C322.077 362.439 323.493 361.087 323.387 359.396C323.282 357.706 321.718 356.333 319.901 356.311C318.084 356.269 316.668 357.643 316.774 359.333Z" fill="#DEDEDE"/>
<path d="M306.252 359.248C306.336 360.938 307.879 362.312 309.717 362.312C311.555 362.333 312.949 360.959 312.865 359.29C312.78 357.6 311.217 356.227 309.4 356.206C307.583 356.206 306.167 357.558 306.252 359.248Z" fill="#DEDEDE"/>
<path d="M295.729 359.206C295.792 360.896 297.335 362.269 299.173 362.269C301.011 362.269 302.427 360.917 302.342 359.227C302.258 357.537 300.715 356.163 298.898 356.163C297.081 356.163 295.666 357.516 295.729 359.206Z" fill="#DEDEDE"/>
<path d="M285.186 359.185C285.25 360.875 286.771 362.227 288.609 362.248C290.447 362.248 291.863 360.896 291.8 359.206C291.736 357.516 290.194 356.142 288.377 356.142C286.581 356.121 285.144 357.495 285.186 359.185Z" fill="#DEDEDE"/>
<path d="M389.16 350.86C389.35 352.55 390.956 353.966 392.773 354.008C394.569 354.051 395.879 352.719 395.689 351.008C395.499 349.318 393.872 347.902 392.076 347.86C390.259 347.818 388.97 349.17 389.16 350.86Z" fill="#DEDEDE"/>
<path d="M378.764 350.628C378.933 352.318 380.539 353.713 382.356 353.755C384.173 353.797 385.483 352.445 385.293 350.755C385.103 349.065 383.497 347.649 381.701 347.607C379.905 347.607 378.595 348.938 378.764 350.628Z" fill="#DEDEDE"/>
<path d="M368.369 350.437C368.538 352.128 370.123 353.522 371.94 353.565C373.757 353.607 375.088 352.255 374.898 350.564C374.729 348.874 373.123 347.458 371.327 347.437C369.531 347.395 368.2 348.747 368.369 350.437Z" fill="#DEDEDE"/>
<path d="M357.953 350.268C358.101 351.959 359.686 353.353 361.503 353.395C363.32 353.416 364.651 352.085 364.503 350.395C364.355 348.705 362.749 347.31 360.953 347.268C359.136 347.205 357.805 348.557 357.953 350.268Z" fill="#DEDEDE"/>
<path d="M347.536 350.1C347.663 351.79 349.247 353.185 351.064 353.206C352.881 353.227 354.234 351.875 354.086 350.184C353.938 348.494 352.353 347.1 350.557 347.057C348.74 347.057 347.388 348.41 347.536 350.1Z" fill="#DEDEDE"/>
<path d="M337.099 349.973C337.225 351.663 338.789 353.057 340.606 353.078C342.423 353.1 343.775 351.747 343.648 350.057C343.522 348.367 341.937 346.972 340.141 346.951C338.345 346.909 336.993 348.282 337.099 349.973Z" fill="#DEDEDE"/>
<path d="M326.662 349.867C326.768 351.557 328.332 352.952 330.149 352.973C331.966 352.994 333.339 351.642 333.212 349.952C333.086 348.261 331.543 346.867 329.726 346.846C327.93 346.803 326.557 348.156 326.662 349.867Z" fill="#DEDEDE"/>
<path d="M316.224 349.761C316.33 351.452 317.872 352.846 319.689 352.846C321.506 352.867 322.9 351.494 322.795 349.804C322.689 348.113 321.147 346.719 319.33 346.698C317.513 346.698 316.139 348.071 316.224 349.761Z" fill="#DEDEDE"/>
<path d="M305.786 349.698C305.871 351.388 307.413 352.762 309.23 352.783C311.047 352.783 312.442 351.431 312.357 349.74C312.273 348.05 310.73 346.656 308.913 346.656C307.096 346.634 305.702 348.008 305.786 349.698Z" fill="#DEDEDE"/>
<path d="M295.328 349.656C295.392 351.346 296.934 352.719 298.751 352.741C300.568 352.741 301.984 351.388 301.899 349.698C301.815 348.008 300.293 346.613 298.476 346.613C296.659 346.592 295.265 347.944 295.328 349.656Z" fill="#DEDEDE"/>
<path d="M284.87 349.614C284.933 351.304 286.454 352.677 288.271 352.677C290.088 352.677 291.504 351.325 291.441 349.614C291.377 347.923 289.856 346.529 288.039 346.529C286.243 346.55 284.806 347.923 284.87 349.614Z" fill="#DEDEDE"/>
<path d="M388.082 341.247C388.273 342.958 389.878 344.353 391.674 344.395C393.47 344.437 394.759 343.085 394.548 341.395C394.336 339.683 392.731 338.268 390.956 338.225C389.16 338.204 387.892 339.556 388.082 341.247Z" fill="#DEDEDE"/>
<path d="M377.751 341.036C377.92 342.726 379.526 344.141 381.322 344.184C383.118 344.226 384.428 342.874 384.237 341.183C384.047 339.472 382.442 338.078 380.667 338.035C378.892 337.972 377.582 339.324 377.751 341.036Z" fill="#DEDEDE"/>
<path d="M367.44 340.845C367.609 342.557 369.194 343.951 370.99 343.993C372.785 344.036 374.095 342.684 373.926 340.972C373.757 339.261 372.152 337.866 370.377 337.824C368.581 337.782 367.271 339.134 367.44 340.845Z" fill="#DEDEDE"/>
<path d="M357.108 340.655C357.256 342.366 358.84 343.761 360.636 343.782C362.432 343.803 363.763 342.451 363.594 340.761C363.425 339.049 361.841 337.655 360.066 337.613C358.27 337.591 356.939 338.965 357.108 340.655Z" fill="#DEDEDE"/>
<path d="M346.754 340.507C346.902 342.219 348.466 343.613 350.262 343.634C352.058 343.656 353.41 342.303 353.241 340.613C353.093 338.902 351.508 337.507 349.733 337.486C347.959 337.444 346.606 338.796 346.754 340.507Z" fill="#DEDEDE"/>
<path d="M336.402 340.36C336.529 342.071 338.092 343.466 339.888 343.487C341.684 343.508 343.036 342.156 342.909 340.444C342.783 338.733 341.219 337.338 339.423 337.317C337.627 337.296 336.275 338.669 336.402 340.36Z" fill="#DEDEDE"/>
<path d="M326.049 340.253C326.155 341.965 327.718 343.338 329.514 343.359C331.31 343.38 332.684 342.007 332.557 340.317C332.43 338.605 330.888 337.211 329.092 337.19C327.296 337.19 325.922 338.563 326.049 340.253Z" fill="#DEDEDE"/>
<path d="M315.675 340.169C315.78 341.881 317.323 343.254 319.119 343.275C320.915 343.296 322.288 341.923 322.182 340.233C322.077 338.521 320.534 337.127 318.738 337.127C316.942 337.085 315.569 338.458 315.675 340.169Z" fill="#DEDEDE"/>
<path d="M305.301 340.085C305.385 341.796 306.927 343.17 308.723 343.191C310.519 343.212 311.914 341.839 311.808 340.127C311.703 338.416 310.181 337.042 308.385 337.021C306.611 337.021 305.216 338.395 305.301 340.085Z" fill="#DEDEDE"/>
<path d="M294.926 340.042C294.989 341.754 296.511 343.127 298.328 343.127C300.124 343.127 301.539 341.754 301.455 340.063C301.37 338.352 299.849 336.979 298.053 336.958C296.257 336.958 294.841 338.352 294.926 340.042Z" fill="#DEDEDE"/>
<path d="M284.531 340.021C284.595 341.732 286.095 343.106 287.912 343.106C289.708 343.106 291.123 341.732 291.06 340.042C290.997 338.331 289.475 336.957 287.679 336.957C285.883 336.936 284.489 338.309 284.531 340.021Z" fill="#DEDEDE"/>
<path d="M274.157 340.021C274.199 341.733 275.7 343.106 277.517 343.106C279.313 343.106 280.749 341.733 280.686 340.021C280.622 338.31 279.122 336.937 277.326 336.937C275.531 336.937 274.115 338.31 274.157 340.021Z" fill="#DEDEDE"/>
<path d="M386.962 331.612C387.152 333.324 388.758 334.739 390.533 334.781C392.308 334.824 393.575 333.471 393.385 331.76C393.174 330.049 391.568 328.633 389.815 328.591C388.04 328.549 386.772 329.901 386.962 331.612Z" fill="#DEDEDE"/>
<path d="M376.735 331.401C376.926 333.112 378.51 334.528 380.285 334.57C382.06 334.612 383.349 333.26 383.158 331.549C382.968 329.837 381.363 328.422 379.609 328.379C377.834 328.337 376.545 329.689 376.735 331.401Z" fill="#DEDEDE"/>
<path d="M366.49 331.19C366.659 332.901 368.244 334.317 370.018 334.338C371.793 334.38 373.103 333.028 372.913 331.316C372.723 329.605 371.138 328.189 369.385 328.168C367.61 328.126 366.321 329.478 366.49 331.19Z" fill="#DEDEDE"/>
<path d="M356.221 331.021C356.369 332.732 357.954 334.126 359.729 334.169C361.503 334.19 362.835 332.838 362.666 331.126C362.497 329.415 360.933 327.999 359.158 327.978C357.383 327.936 356.073 329.309 356.221 331.021Z" fill="#DEDEDE"/>
<path d="M345.952 330.852C346.1 332.563 347.663 333.957 349.438 334C351.234 334.021 352.544 332.669 352.396 330.957C352.248 329.246 350.684 327.83 348.91 327.809C347.135 327.788 345.804 329.161 345.952 330.852Z" fill="#DEDEDE"/>
<path d="M335.684 330.725C335.811 332.436 337.374 333.831 339.149 333.852C340.945 333.873 342.276 332.521 342.128 330.809C341.98 329.098 340.438 327.703 338.663 327.661C336.888 327.64 335.557 329.013 335.684 330.725Z" fill="#DEDEDE"/>
<path d="M325.394 330.619C325.499 332.33 327.042 333.725 328.838 333.746C330.633 333.767 331.965 332.394 331.859 330.682C331.732 328.971 330.19 327.576 328.415 327.555C326.619 327.534 325.288 328.907 325.394 330.619Z" fill="#DEDEDE"/>
<path d="M315.104 330.513C315.21 332.224 316.731 333.619 318.527 333.64C320.323 333.661 321.675 332.288 321.57 330.577C321.464 328.865 319.922 327.471 318.147 327.449C316.372 327.428 314.999 328.823 315.104 330.513Z" fill="#DEDEDE"/>
<path d="M304.815 330.45C304.9 332.161 306.421 333.556 308.217 333.556C310.013 333.577 311.386 332.204 311.281 330.492C311.175 328.781 309.654 327.386 307.879 327.365C306.104 327.365 304.731 328.739 304.815 330.45Z" fill="#DEDEDE"/>
<path d="M294.504 330.408C294.567 332.119 296.088 333.514 297.884 333.514C299.68 333.514 301.053 332.14 300.969 330.429C300.884 328.717 299.363 327.323 297.588 327.323C295.814 327.323 294.44 328.696 294.504 330.408Z" fill="#DEDEDE"/>
<path d="M284.193 330.387C284.256 332.098 285.756 333.472 287.552 333.493C289.348 333.493 290.743 332.12 290.679 330.408C290.616 328.697 289.116 327.302 287.32 327.302C285.545 327.281 284.151 328.676 284.193 330.387Z" fill="#DEDEDE"/>
<path d="M273.883 330.365C273.925 332.077 275.425 333.45 277.221 333.45C279.017 333.45 280.411 332.077 280.369 330.365C280.306 328.654 278.827 327.26 277.031 327.26C275.256 327.281 273.841 328.675 273.883 330.365Z" fill="#DEDEDE"/>
<path d="M263.572 330.387C263.594 332.098 265.094 333.471 266.869 333.471C268.664 333.471 270.08 332.077 270.038 330.366C269.996 328.654 268.517 327.281 266.742 327.281C264.967 327.302 263.551 328.675 263.572 330.387Z" fill="#DEDEDE"/>
<path d="M395.963 322.189C396.174 323.9 397.78 325.337 399.555 325.379C401.308 325.421 402.555 324.069 402.344 322.358C402.111 320.646 400.505 319.209 398.752 319.167C396.998 319.104 395.752 320.477 395.963 322.189Z" fill="#DEDEDE"/>
<path d="M385.821 321.935C386.033 323.646 387.617 325.062 389.392 325.104C391.167 325.146 392.413 323.794 392.202 322.083C391.991 320.371 390.385 318.935 388.631 318.892C386.878 318.871 385.631 320.223 385.821 321.935Z" fill="#DEDEDE"/>
<path d="M375.659 321.724C375.849 323.436 377.434 324.851 379.208 324.894C380.983 324.936 382.251 323.584 382.04 321.851C381.849 320.14 380.244 318.724 378.49 318.682C376.757 318.64 375.469 320.013 375.659 321.724Z" fill="#DEDEDE"/>
<path d="M365.496 321.512C365.665 323.224 367.25 324.639 369.025 324.682C370.8 324.724 372.088 323.35 371.898 321.639C371.708 319.928 370.145 318.512 368.37 318.47C366.616 318.449 365.327 319.801 365.496 321.512Z" fill="#DEDEDE"/>
<path d="M355.311 321.344C355.48 323.055 357.044 324.471 358.798 324.492C360.572 324.513 361.861 323.161 361.692 321.45C361.523 319.738 359.96 318.323 358.185 318.28C356.452 318.259 355.163 319.632 355.311 321.344Z" fill="#DEDEDE"/>
<path d="M345.129 321.174C345.276 322.886 346.84 324.301 348.594 324.323C350.368 324.344 351.678 322.992 351.509 321.259C351.361 319.548 349.798 318.132 348.044 318.111C346.291 318.09 344.981 319.463 345.129 321.174Z" fill="#DEDEDE"/>
<path d="M334.944 321.048C335.071 322.759 336.613 324.175 338.388 324.196C340.163 324.217 341.494 322.844 341.346 321.132C341.198 319.421 339.656 318.005 337.902 317.984C336.127 317.963 334.796 319.336 334.944 321.048Z" fill="#DEDEDE"/>
<path d="M324.739 320.942C324.866 322.654 326.387 324.069 328.162 324.069C329.936 324.091 331.268 322.717 331.141 321.006C331.014 319.294 329.472 317.879 327.718 317.858C325.943 317.837 324.612 319.231 324.739 320.942Z" fill="#DEDEDE"/>
<path d="M314.513 320.837C314.619 322.548 316.14 323.942 317.915 323.964C319.69 323.985 321.042 322.611 320.915 320.9C320.809 319.189 319.267 317.773 317.513 317.773C315.76 317.752 314.407 319.125 314.513 320.837Z" fill="#DEDEDE"/>
<path d="M304.308 320.773C304.392 322.484 305.914 323.879 307.689 323.9C309.463 323.921 310.837 322.527 310.731 320.815C310.625 319.104 309.125 317.709 307.35 317.688C305.576 317.688 304.223 319.062 304.308 320.773Z" fill="#DEDEDE"/>
<path d="M294.081 320.731C294.166 322.442 295.666 323.837 297.441 323.837C299.216 323.837 300.589 322.463 300.504 320.752C300.42 319.041 298.92 317.646 297.145 317.625C295.37 317.625 293.997 319.019 294.081 320.731Z" fill="#DEDEDE"/>
<path d="M283.855 320.71C283.918 322.421 285.419 323.816 287.193 323.816C288.968 323.816 290.363 322.443 290.278 320.731C290.215 319.02 288.715 317.625 286.94 317.625C285.186 317.604 283.792 318.977 283.855 320.71Z" fill="#DEDEDE"/>
<path d="M273.629 320.689C273.671 322.401 275.15 323.795 276.925 323.795C278.7 323.795 280.094 322.401 280.052 320.689C279.989 318.978 278.51 317.583 276.735 317.583C274.981 317.583 273.587 318.978 273.629 320.689Z" fill="#DEDEDE"/>
<path d="M263.403 320.71C263.445 322.422 264.903 323.816 266.678 323.795C268.452 323.795 269.868 322.401 269.826 320.689C269.784 318.978 268.305 317.583 266.551 317.583C264.776 317.604 263.361 318.999 263.403 320.71Z" fill="#DEDEDE"/>
<path d="M253.156 320.752C253.177 322.464 254.635 323.837 256.41 323.837C258.185 323.837 259.6 322.442 259.579 320.731C259.558 319.02 258.1 317.625 256.325 317.646C254.55 317.646 253.135 319.041 253.156 320.752Z" fill="#DEDEDE"/>
<path d="M404.774 312.745C405.007 314.456 406.612 315.893 408.366 315.935C410.12 315.977 411.324 314.625 411.071 312.914C410.817 311.202 409.211 309.766 407.479 309.702C405.746 309.66 404.542 311.012 404.774 312.745Z" fill="#DEDEDE"/>
<path d="M394.717 312.47C394.949 314.181 396.534 315.618 398.288 315.66C400.041 315.702 401.267 314.35 401.034 312.639C400.802 310.927 399.196 309.49 397.464 309.448C395.731 309.406 394.506 310.758 394.717 312.47Z" fill="#DEDEDE"/>
<path d="M384.66 312.237C384.871 313.948 386.456 315.385 388.21 315.427C389.963 315.469 391.21 314.117 390.978 312.385C390.766 310.673 389.16 309.237 387.428 309.194C385.695 309.152 384.449 310.525 384.66 312.237Z" fill="#DEDEDE"/>
<path d="M374.581 312.005C374.771 313.716 376.356 315.153 378.11 315.174C379.863 315.216 381.11 313.843 380.92 312.132C380.708 310.42 379.145 308.983 377.391 308.941C375.638 308.92 374.391 310.293 374.581 312.005Z" fill="#DEDEDE"/>
<path d="M364.503 311.814C364.672 313.526 366.256 314.941 368.01 314.984C369.764 315.026 371.031 313.653 370.841 311.941C370.651 310.23 369.088 308.793 367.334 308.772C365.58 308.73 364.313 310.103 364.503 311.814Z" fill="#DEDEDE"/>
<path d="M354.404 311.646C354.573 313.357 356.115 314.773 357.89 314.815C359.644 314.836 360.933 313.484 360.764 311.752C360.595 310.04 359.031 308.603 357.278 308.582C355.524 308.54 354.235 309.913 354.404 311.646Z" fill="#DEDEDE"/>
<path d="M344.283 311.477C344.431 313.188 345.973 314.604 347.748 314.625C349.502 314.646 350.812 313.273 350.643 311.561C350.474 309.85 348.931 308.434 347.177 308.392C345.445 308.392 344.135 309.765 344.283 311.477Z" fill="#DEDEDE"/>
<path d="M334.184 311.35C334.311 313.061 335.853 314.477 337.607 314.498C339.36 314.519 340.67 313.146 340.522 311.434C340.374 309.723 338.832 308.307 337.099 308.286C335.346 308.244 334.036 309.617 334.184 311.35Z" fill="#DEDEDE"/>
<path d="M324.063 311.223C324.19 312.934 325.711 314.35 327.465 314.371C329.219 314.392 330.55 313.019 330.423 311.286C330.296 309.575 328.775 308.159 327.021 308.138C325.246 308.138 323.936 309.511 324.063 311.223Z" fill="#DEDEDE"/>
<path d="M313.921 311.138C314.027 312.85 315.548 314.265 317.302 314.287C319.056 314.308 320.408 312.913 320.281 311.202C320.154 309.49 318.654 308.075 316.901 308.054C315.147 308.033 313.816 309.427 313.921 311.138Z" fill="#DEDEDE"/>
<path d="M303.78 311.075C303.864 312.786 305.364 314.202 307.139 314.202C308.893 314.223 310.245 312.829 310.139 311.117C310.034 309.406 308.534 307.99 306.78 307.99C305.047 307.969 303.695 309.342 303.78 311.075Z" fill="#DEDEDE"/>
<path d="M293.66 311.012C293.744 312.723 295.223 314.139 296.998 314.139C298.751 314.139 300.125 312.766 300.04 311.033C299.956 309.322 298.456 307.906 296.702 307.906C294.927 307.906 293.575 309.3 293.66 311.012Z" fill="#DEDEDE"/>
<path d="M283.496 310.991C283.56 312.702 285.039 314.097 286.813 314.118C288.588 314.118 289.94 312.723 289.877 311.012C289.814 309.301 288.314 307.885 286.56 307.885C284.806 307.885 283.433 309.279 283.496 310.991Z" fill="#DEDEDE"/>
<path d="M273.354 310.991C273.397 312.702 274.876 314.097 276.629 314.097C278.404 314.097 279.778 312.702 279.714 310.991C279.651 309.279 278.193 307.885 276.439 307.885C274.686 307.885 273.312 309.279 273.354 310.991Z" fill="#DEDEDE"/>
<path d="M263.212 311.012C263.255 312.723 264.712 314.118 266.466 314.118C268.241 314.118 269.635 312.723 269.593 310.991C269.551 309.279 268.093 307.885 266.339 307.885C264.565 307.885 263.17 309.301 263.212 311.012Z" fill="#DEDEDE"/>
<path d="M637.018 315.427C637.61 317.139 639.427 318.639 641.054 318.766C642.702 318.892 643.526 317.603 642.934 315.892C642.322 314.181 640.526 312.681 638.899 312.554C637.272 312.427 636.427 313.716 637.018 315.427Z" fill="#DEDEDE"/>
<path d="M423.304 303.595C423.578 305.307 425.205 306.765 426.917 306.807C428.649 306.87 429.811 305.518 429.537 303.786C429.262 302.074 427.635 300.616 425.903 300.553C424.212 300.532 423.029 301.884 423.304 303.595Z" fill="#DEDEDE"/>
<path d="M413.374 303.3C413.627 305.011 415.233 306.469 416.965 306.511C418.698 306.554 419.881 305.223 419.606 303.49C419.332 301.779 417.726 300.321 416.015 300.279C414.303 300.215 413.12 301.567 413.374 303.3Z" fill="#DEDEDE"/>
<path d="M403.422 303.004C403.654 304.715 405.26 306.152 406.993 306.216C408.725 306.258 409.93 304.906 409.676 303.194C409.422 301.462 407.817 300.025 406.105 299.983C404.373 299.94 403.168 301.293 403.422 303.004Z" fill="#DEDEDE"/>
<path d="M393.449 302.751C393.681 304.483 395.266 305.899 396.998 305.941C398.731 305.983 399.956 304.631 399.703 302.899C399.47 301.166 397.864 299.75 396.153 299.687C394.421 299.666 393.216 301.018 393.449 302.751Z" fill="#DEDEDE"/>
<path d="M383.455 302.497C383.666 304.23 385.251 305.645 386.983 305.687C388.716 305.73 389.941 304.378 389.73 302.645C389.498 300.912 387.934 299.497 386.202 299.455C384.469 299.412 383.244 300.786 383.455 302.497Z" fill="#DEDEDE"/>
<path d="M373.462 302.285C373.652 304.018 375.237 305.434 376.969 305.476C378.702 305.518 379.948 304.145 379.737 302.433C379.526 300.701 377.962 299.285 376.23 299.243C374.518 299.201 373.272 300.553 373.462 302.285Z" fill="#DEDEDE"/>
<path d="M363.467 302.075C363.658 303.807 365.2 305.223 366.954 305.244C368.686 305.286 369.954 303.913 369.764 302.18C369.574 300.448 368.01 299.032 366.278 298.99C364.524 298.99 363.277 300.363 363.467 302.075Z" fill="#DEDEDE"/>
<path d="M353.453 301.905C353.622 303.638 355.164 305.054 356.918 305.075C358.65 305.096 359.918 303.744 359.749 302.011C359.58 300.279 358.016 298.863 356.284 298.842C354.551 298.8 353.284 300.173 353.453 301.905Z" fill="#DEDEDE"/>
<path d="M343.438 301.757C343.586 303.49 345.128 304.906 346.882 304.927C348.635 304.948 349.903 303.575 349.734 301.863C349.565 300.131 348.023 298.715 346.29 298.694C344.558 298.652 343.269 300.025 343.438 301.757Z" fill="#DEDEDE"/>
<path d="M333.402 301.61C333.55 303.342 335.071 304.737 336.804 304.779C338.558 304.8 339.846 303.427 339.698 301.694C339.551 299.962 338.029 298.546 336.297 298.525C334.543 298.504 333.254 299.898 333.402 301.61Z" fill="#DEDEDE"/>
<path d="M323.366 301.504C323.493 303.236 325.014 304.631 326.747 304.652C328.501 304.673 329.811 303.3 329.663 301.567C329.536 299.835 328.015 298.419 326.282 298.419C324.55 298.398 323.24 299.771 323.366 301.504Z" fill="#DEDEDE"/>
<path d="M313.309 301.398C313.414 303.131 314.914 304.525 316.668 304.547C318.422 304.568 319.732 303.173 319.626 301.462C319.499 299.729 317.999 298.335 316.267 298.314C314.534 298.293 313.203 299.687 313.309 301.398Z" fill="#DEDEDE"/>
<path d="M303.272 301.335C303.357 303.067 304.857 304.462 306.611 304.483C308.364 304.504 309.696 303.11 309.59 301.377C309.484 299.645 307.984 298.25 306.252 298.229C304.498 298.229 303.167 299.623 303.272 301.335Z" fill="#DEDEDE"/>
<path d="M293.216 301.293C293.3 303.025 294.779 304.42 296.533 304.42C298.287 304.42 299.639 303.047 299.533 301.314C299.449 299.581 297.97 298.187 296.216 298.187C294.483 298.166 293.131 299.56 293.216 301.293Z" fill="#DEDEDE"/>
<path d="M283.137 301.272C283.2 303.004 284.679 304.399 286.412 304.399C288.165 304.399 289.518 303.004 289.454 301.293C289.37 299.56 287.912 298.166 286.179 298.166C284.447 298.145 283.073 299.539 283.137 301.272Z" fill="#DEDEDE"/>
<path d="M273.079 301.271C273.121 303.004 274.579 304.398 276.333 304.398C278.087 304.398 279.46 303.004 279.397 301.271C279.333 299.539 277.875 298.144 276.143 298.144C274.41 298.144 273.037 299.539 273.079 301.271Z" fill="#DEDEDE"/>
<path d="M263.022 301.272C263.065 303.004 264.501 304.377 266.255 304.377C268.009 304.377 269.382 302.983 269.34 301.25C269.298 299.518 267.84 298.123 266.107 298.145C264.375 298.166 262.98 299.56 263.022 301.272Z" fill="#DEDEDE"/>
<path d="M252.944 301.314C252.965 303.046 254.402 304.42 256.156 304.42C257.909 304.42 259.304 303.004 259.283 301.293C259.262 299.56 257.825 298.187 256.071 298.187C254.317 298.187 252.923 299.602 252.944 301.314Z" fill="#DEDEDE"/>
<path d="M421.74 293.856C422.015 295.588 423.641 297.025 425.353 297.088C427.064 297.152 428.226 295.799 427.931 294.067C427.635 292.334 426.029 290.898 424.318 290.834C422.627 290.771 421.465 292.144 421.74 293.856Z" fill="#DEDEDE"/>
<path d="M411.894 293.56C412.148 295.292 413.753 296.729 415.486 296.771C417.197 296.813 418.381 295.461 418.106 293.75C417.831 292.017 416.225 290.581 414.514 290.538C412.803 290.475 411.619 291.827 411.894 293.56Z" fill="#DEDEDE"/>
<path d="M402.027 293.264C402.281 294.996 403.866 296.433 405.577 296.475C407.288 296.517 408.472 295.165 408.218 293.433C407.965 291.7 406.359 290.263 404.647 290.221C402.957 290.179 401.774 291.531 402.027 293.264Z" fill="#DEDEDE"/>
<path d="M392.139 293.01C392.371 294.743 393.956 296.18 395.667 296.222C397.379 296.264 398.583 294.912 398.351 293.179C398.097 291.447 396.513 290.01 394.822 289.968C393.09 289.905 391.907 291.278 392.139 293.01Z" fill="#DEDEDE"/>
<path d="M382.23 292.757C382.441 294.489 384.026 295.905 385.737 295.947C387.469 295.989 388.674 294.616 388.441 292.905C388.209 291.172 386.645 289.735 384.934 289.693C383.223 289.672 382.018 291.024 382.23 292.757Z" fill="#DEDEDE"/>
<path d="M372.322 292.546C372.533 294.278 374.075 295.694 375.808 295.736C377.54 295.778 378.766 294.405 378.533 292.672C378.322 290.94 376.759 289.524 375.047 289.482C373.357 289.44 372.132 290.813 372.322 292.546Z" fill="#DEDEDE"/>
<path d="M362.411 292.334C362.601 294.066 364.143 295.482 365.876 295.524C367.608 295.567 368.834 294.193 368.644 292.461C368.454 290.728 366.89 289.312 365.179 289.27C363.467 289.228 362.221 290.601 362.411 292.334Z" fill="#DEDEDE"/>
<path d="M352.48 292.165C352.649 293.898 354.192 295.313 355.924 295.335C357.657 295.356 358.903 293.982 358.713 292.271C358.523 290.538 356.981 289.123 355.269 289.081C353.558 289.059 352.311 290.433 352.48 292.165Z" fill="#DEDEDE"/>
<path d="M342.55 291.996C342.698 293.728 344.24 295.144 345.973 295.165C347.705 295.186 348.973 293.813 348.804 292.08C348.635 290.348 347.114 288.932 345.381 288.911C343.649 288.89 342.402 290.284 342.55 291.996Z" fill="#DEDEDE"/>
<path d="M332.6 291.869C332.747 293.601 334.248 295.017 335.98 295.038C337.713 295.059 338.98 293.686 338.832 291.953C338.685 290.221 337.163 288.805 335.452 288.784C333.741 288.763 332.473 290.136 332.6 291.869Z" fill="#DEDEDE"/>
<path d="M322.648 291.742C322.775 293.475 324.275 294.891 326.008 294.891C327.74 294.912 329.029 293.517 328.902 291.806C328.754 290.073 327.254 288.658 325.543 288.636C323.81 288.636 322.521 290.031 322.648 291.742Z" fill="#DEDEDE"/>
<path d="M312.696 291.658C312.802 293.39 314.302 294.785 316.034 294.806C317.767 294.827 319.077 293.433 318.95 291.7C318.823 289.968 317.323 288.552 315.612 288.552C313.879 288.552 312.59 289.925 312.696 291.658Z" fill="#DEDEDE"/>
<path d="M302.724 291.595C302.809 293.327 304.309 294.722 306.041 294.743C307.774 294.764 309.105 293.369 308.999 291.637C308.894 289.904 307.415 288.51 305.682 288.489C303.95 288.468 302.64 289.862 302.724 291.595Z" fill="#DEDEDE"/>
<path d="M292.751 291.553C292.835 293.285 294.293 294.68 296.026 294.68C297.758 294.68 299.089 293.306 299.005 291.574C298.92 289.841 297.441 288.447 295.709 288.426C294.019 288.426 292.666 289.82 292.751 291.553Z" fill="#DEDEDE"/>
<path d="M282.778 291.51C282.841 293.242 284.299 294.637 286.032 294.637C287.764 294.637 289.117 293.242 289.032 291.531C288.948 289.799 287.49 288.404 285.778 288.404C284.067 288.383 282.715 289.799 282.778 291.51Z" fill="#DEDEDE"/>
<path d="M272.805 291.51C272.847 293.243 274.305 294.637 276.037 294.637C277.77 294.637 279.122 293.243 279.08 291.51C279.016 289.778 277.58 288.383 275.847 288.383C274.115 288.383 272.762 289.778 272.805 291.51Z" fill="#DEDEDE"/>
<path d="M262.811 291.531C262.854 293.264 264.269 294.658 266.023 294.637C267.755 294.637 269.129 293.243 269.086 291.51C269.044 289.778 267.607 288.383 265.875 288.383C264.163 288.404 262.79 289.799 262.811 291.531Z" fill="#DEDEDE"/>
<path d="M252.839 291.573C252.86 293.306 254.276 294.7 256.008 294.679C257.741 294.679 259.135 293.264 259.093 291.552C259.072 289.82 257.635 288.425 255.924 288.446C254.212 288.446 252.818 289.841 252.839 291.573Z" fill="#DEDEDE"/>
<path d="M242.845 291.637C242.845 293.369 244.261 294.743 245.993 294.743C247.726 294.722 249.12 293.327 249.12 291.595C249.099 289.862 247.705 288.468 245.972 288.489C244.24 288.51 242.845 289.904 242.845 291.637Z" fill="#DEDEDE"/>
<path d="M212.906 291.953C212.864 293.686 214.237 295.059 215.97 295.038C217.702 295.017 219.139 293.601 219.181 291.869C219.224 290.136 217.85 288.763 216.118 288.784C214.385 288.805 212.948 290.221 212.906 291.953Z" fill="#DEDEDE"/>
<path d="M202.912 292.102C202.849 293.834 204.222 295.207 205.955 295.165C207.687 295.144 209.145 293.728 209.187 291.996C209.23 290.263 207.877 288.89 206.145 288.911C204.412 288.953 202.976 290.39 202.912 292.102Z" fill="#DEDEDE"/>
<path d="M182.967 292.482C182.882 294.215 184.213 295.567 185.946 295.546C187.678 295.504 189.157 294.088 189.221 292.355C189.305 290.623 187.974 289.249 186.242 289.292C184.53 289.313 183.051 290.75 182.967 292.482Z" fill="#DEDEDE"/>
<path d="M564.59 291.193C565.097 292.926 566.851 294.405 568.499 294.51C570.147 294.616 571.055 293.306 570.527 291.595C569.999 289.862 568.245 288.383 566.618 288.278C564.991 288.151 564.083 289.461 564.59 291.193Z" fill="#DEDEDE"/>
<path d="M420.156 284.094C420.431 285.826 422.036 287.263 423.748 287.326C425.438 287.39 426.579 286.038 426.283 284.305C425.988 282.573 424.382 281.136 422.691 281.072C421.001 281.009 419.86 282.382 420.156 284.094Z" fill="#DEDEDE"/>
<path d="M410.373 283.798C410.647 285.53 412.232 286.967 413.943 287.009C415.655 287.052 416.796 285.699 416.521 283.988C416.246 282.255 414.641 280.819 412.95 280.755C411.26 280.713 410.119 282.065 410.373 283.798Z" fill="#DEDEDE"/>
<path d="M400.591 283.503C400.844 285.235 402.429 286.672 404.14 286.714C405.852 286.756 407.014 285.404 406.739 283.672C406.465 281.939 404.88 280.502 403.19 280.46C401.499 280.418 400.337 281.77 400.591 283.503Z" fill="#DEDEDE"/>
<path d="M390.808 283.249C391.041 284.981 392.625 286.418 394.315 286.46C396.027 286.503 397.21 285.15 396.957 283.418C396.703 281.685 395.14 280.248 393.428 280.206C391.738 280.143 390.555 281.516 390.808 283.249Z" fill="#DEDEDE"/>
<path d="M380.984 282.995C381.216 284.728 382.78 286.165 384.47 286.207C386.181 286.249 387.365 284.876 387.132 283.164C386.9 281.432 385.336 279.995 383.646 279.953C381.956 279.911 380.773 281.263 380.984 282.995Z" fill="#DEDEDE"/>
<path d="M371.179 282.784C371.391 284.516 372.933 285.932 374.644 285.974C376.356 286.016 377.56 284.643 377.349 282.91C377.138 281.178 375.574 279.741 373.884 279.72C372.172 279.678 370.968 281.051 371.179 282.784Z" fill="#DEDEDE"/>
<path d="M361.334 282.573C361.524 284.305 363.066 285.721 364.778 285.763C366.489 285.806 367.715 284.432 367.503 282.7C367.292 280.967 365.75 279.552 364.059 279.509C362.369 279.467 361.144 280.84 361.334 282.573Z" fill="#DEDEDE"/>
<path d="M351.509 282.404C351.678 284.136 353.22 285.552 354.932 285.594C356.643 285.615 357.89 284.242 357.7 282.531C357.509 280.798 355.988 279.383 354.277 279.34C352.565 279.298 351.319 280.671 351.509 282.404Z" fill="#DEDEDE"/>
<path d="M341.642 282.235C341.811 283.967 343.311 285.383 345.043 285.404C346.755 285.425 348.001 284.052 347.832 282.319C347.663 280.587 346.142 279.171 344.431 279.15C342.74 279.129 341.494 280.502 341.642 282.235Z" fill="#DEDEDE"/>
<path d="M331.796 282.108C331.944 283.841 333.444 285.256 335.156 285.277C336.867 285.299 338.135 283.925 337.987 282.193C337.839 280.46 336.318 279.045 334.628 279.023C332.916 278.981 331.648 280.376 331.796 282.108Z" fill="#DEDEDE"/>
<path d="M321.929 281.981C322.056 283.714 323.556 285.129 325.267 285.15C326.979 285.171 328.268 283.777 328.12 282.066C327.972 280.333 326.493 278.917 324.782 278.896C323.07 278.875 321.802 280.27 321.929 281.981Z" fill="#DEDEDE"/>
<path d="M312.062 281.896C312.167 283.629 313.667 285.045 315.379 285.045C317.09 285.066 318.379 283.671 318.273 281.939C318.147 280.206 316.668 278.791 314.956 278.791C313.245 278.769 311.935 280.164 312.062 281.896Z" fill="#DEDEDE"/>
<path d="M302.174 281.833C302.279 283.566 303.737 284.96 305.47 284.982C307.181 285.003 308.491 283.608 308.386 281.876C308.28 280.143 306.801 278.728 305.09 278.728C303.399 278.706 302.089 280.101 302.174 281.833Z" fill="#DEDEDE"/>
<path d="M292.306 281.77C292.391 283.502 293.849 284.897 295.56 284.918C297.271 284.918 298.602 283.523 298.497 281.812C298.412 280.079 296.954 278.685 295.243 278.664C293.532 278.664 292.222 280.058 292.306 281.77Z" fill="#DEDEDE"/>
<path d="M282.419 281.749C282.482 283.481 283.94 284.876 285.651 284.876C287.363 284.876 288.694 283.481 288.63 281.749C288.546 280.016 287.109 278.622 285.398 278.622C283.686 278.622 282.355 280.037 282.419 281.749Z" fill="#DEDEDE"/>
<path d="M272.53 281.749C272.573 283.481 274.009 284.876 275.742 284.876C277.475 284.876 278.806 283.481 278.742 281.749C278.679 280.016 277.242 278.622 275.531 278.622C273.819 278.622 272.467 280.016 272.53 281.749Z" fill="#DEDEDE"/>
<path d="M262.621 281.77C262.664 283.502 264.079 284.897 265.791 284.897C267.502 284.897 268.875 283.481 268.833 281.77C268.791 280.037 267.375 278.643 265.664 278.643C263.952 278.643 262.6 280.037 262.621 281.77Z" fill="#DEDEDE"/>
<path d="M252.733 281.812C252.754 283.545 254.169 284.939 255.881 284.918C257.613 284.918 258.966 283.502 258.944 281.77C258.923 280.037 257.508 278.643 255.796 278.664C254.085 278.685 252.712 280.08 252.733 281.812Z" fill="#DEDEDE"/>
<path d="M242.844 281.876C242.844 283.608 244.239 285.003 245.972 284.982C247.704 284.96 249.077 283.566 249.056 281.833C249.035 280.101 247.641 278.706 245.929 278.728C244.218 278.728 242.823 280.143 242.844 281.876Z" fill="#DEDEDE"/>
<path d="M232.936 281.96C232.915 283.693 234.309 285.066 236.021 285.066C237.753 285.045 239.148 283.63 239.148 281.918C239.148 280.186 237.774 278.791 236.063 278.812C234.351 278.812 232.957 280.228 232.936 281.96Z" fill="#DEDEDE"/>
<path d="M223.048 282.066C223.027 283.798 224.4 285.171 226.112 285.15C227.844 285.129 229.239 283.714 229.26 281.981C229.281 280.248 227.908 278.875 226.196 278.896C224.485 278.917 223.069 280.333 223.048 282.066Z" fill="#DEDEDE"/>
<path d="M213.138 282.192C213.096 283.925 214.448 285.298 216.181 285.277C217.913 285.256 219.329 283.84 219.35 282.108C219.392 280.375 218.019 279.002 216.307 279.023C214.617 279.044 213.18 280.46 213.138 282.192Z" fill="#DEDEDE"/>
<path d="M203.25 282.34C203.187 284.073 204.539 285.446 206.25 285.425C207.962 285.404 209.399 283.967 209.462 282.256C209.504 280.523 208.173 279.15 206.462 279.171C204.75 279.192 203.314 280.629 203.25 282.34Z" fill="#DEDEDE"/>
<path d="M193.362 282.531C193.278 284.263 194.63 285.637 196.341 285.594C198.074 285.573 199.511 284.136 199.574 282.404C199.637 280.671 198.306 279.298 196.595 279.34C194.883 279.361 193.426 280.798 193.362 282.531Z" fill="#DEDEDE"/>
<path d="M183.474 282.721C183.389 284.453 184.699 285.827 186.432 285.784C188.143 285.742 189.622 284.326 189.685 282.594C189.77 280.861 188.439 279.488 186.727 279.53C185.016 279.551 183.558 280.988 183.474 282.721Z" fill="#DEDEDE"/>
<path d="M173.586 282.932C173.48 284.664 174.79 286.017 176.502 285.995C178.213 285.953 179.692 284.538 179.798 282.805C179.903 281.073 178.594 279.699 176.882 279.741C175.171 279.784 173.692 281.199 173.586 282.932Z" fill="#DEDEDE"/>
<path d="M163.697 283.164C163.57 284.897 164.859 286.249 166.591 286.207C168.303 286.165 169.803 284.728 169.908 282.995C170.014 281.263 168.725 279.911 167.014 279.953C165.324 280.016 163.823 281.453 163.697 283.164Z" fill="#DEDEDE"/>
<path d="M635.856 286.988C636.49 288.721 638.307 290.221 639.892 290.348C641.476 290.475 642.258 289.186 641.603 287.474C640.948 285.742 639.152 284.242 637.568 284.115C635.983 283.967 635.222 285.256 635.856 286.988Z" fill="#DEDEDE"/>
<path d="M552.272 280.819C552.779 282.552 554.512 284.031 556.139 284.136C557.765 284.242 558.674 282.932 558.167 281.199C557.639 279.467 555.906 277.988 554.279 277.882C552.673 277.777 551.765 279.087 552.272 280.819Z" fill="#DEDEDE"/>
<path d="M542.849 280.227C543.335 281.96 545.068 283.439 546.716 283.545C548.364 283.65 549.273 282.34 548.787 280.608C548.279 278.875 546.547 277.396 544.92 277.291C543.272 277.185 542.364 278.495 542.849 280.227Z" fill="#DEDEDE"/>
<path d="M437.84 275.009C438.157 276.741 439.784 278.178 441.453 278.263C443.122 278.326 444.221 276.974 443.883 275.241C443.545 273.509 441.918 272.051 440.27 271.988C438.622 271.945 437.523 273.276 437.84 275.009Z" fill="#DEDEDE"/>
<path d="M428.183 274.67C428.479 276.403 430.106 277.84 431.775 277.903C433.466 277.967 434.564 276.614 434.247 274.882C433.93 273.149 432.325 271.712 430.655 271.649C428.986 271.586 427.888 272.938 428.183 274.67Z" fill="#DEDEDE"/>
<path d="M418.529 274.333C418.825 276.065 420.409 277.502 422.1 277.565C423.79 277.629 424.91 276.277 424.593 274.544C424.297 272.812 422.691 271.375 421.022 271.311C419.353 271.248 418.233 272.6 418.529 274.333Z" fill="#DEDEDE"/>
<path d="M408.851 274.016C409.126 275.748 410.711 277.185 412.401 277.248C414.091 277.312 415.232 275.938 414.936 274.206C414.641 272.473 413.056 271.037 411.387 270.973C409.697 270.931 408.577 272.304 408.851 274.016Z" fill="#DEDEDE"/>
<path d="M399.154 273.741C399.407 275.473 400.992 276.91 402.682 276.952C404.373 276.995 405.514 275.642 405.26 273.91C404.985 272.177 403.422 270.74 401.732 270.698C400.041 270.656 398.9 272.008 399.154 273.741Z" fill="#DEDEDE"/>
<path d="M389.434 273.466C389.667 275.199 391.251 276.636 392.942 276.678C394.632 276.72 395.794 275.368 395.541 273.635C395.287 271.903 393.723 270.466 392.033 270.424C390.364 270.382 389.202 271.734 389.434 273.466Z" fill="#DEDEDE"/>
<path d="M379.715 273.234C379.948 274.966 381.49 276.403 383.18 276.445C384.871 276.488 386.054 275.114 385.821 273.382C385.589 271.649 384.025 270.212 382.356 270.17C380.666 270.128 379.504 271.501 379.715 273.234Z" fill="#DEDEDE"/>
<path d="M369.996 273.002C370.207 274.734 371.75 276.15 373.44 276.192C375.13 276.234 376.335 274.861 376.102 273.128C375.891 271.396 374.327 269.959 372.658 269.938C370.968 269.896 369.785 271.269 369.996 273.002Z" fill="#DEDEDE"/>
<path d="M360.256 272.811C360.446 274.544 361.989 275.959 363.679 276.002C365.369 276.044 366.573 274.67 366.383 272.938C366.172 271.205 364.651 269.79 362.96 269.748C361.249 269.705 360.045 271.079 360.256 272.811Z" fill="#DEDEDE"/>
<path d="M350.495 272.622C350.664 274.354 352.185 275.77 353.896 275.812C355.587 275.833 356.812 274.46 356.622 272.748C356.432 271.016 354.911 269.6 353.22 269.558C351.53 269.516 350.326 270.889 350.495 272.622Z" fill="#DEDEDE"/>
<path d="M340.734 272.473C340.903 274.206 342.403 275.621 344.093 275.642C345.783 275.664 347.03 274.29 346.861 272.558C346.692 270.825 345.171 269.41 343.48 269.388C341.811 269.367 340.565 270.741 340.734 272.473Z" fill="#DEDEDE"/>
<path d="M330.973 272.326C331.121 274.058 332.621 275.474 334.311 275.495C336.001 275.516 337.269 274.143 337.1 272.41C336.952 270.678 335.452 269.262 333.762 269.241C332.071 269.22 330.825 270.614 330.973 272.326Z" fill="#DEDEDE"/>
<path d="M321.189 272.219C321.316 273.952 322.795 275.368 324.506 275.389C326.196 275.41 327.464 274.015 327.337 272.304C327.189 270.571 325.71 269.156 324.02 269.135C322.33 269.092 321.062 270.487 321.189 272.219Z" fill="#DEDEDE"/>
<path d="M311.407 272.135C311.512 273.867 312.991 275.283 314.703 275.283C316.414 275.304 317.682 273.91 317.555 272.177C317.428 270.445 315.949 269.029 314.259 269.008C312.569 269.008 311.301 270.402 311.407 272.135Z" fill="#DEDEDE"/>
<path d="M301.624 272.051C301.73 273.784 303.188 275.199 304.878 275.199C306.589 275.22 307.878 273.826 307.773 272.093C307.667 270.361 306.209 268.945 304.519 268.945C302.828 268.945 301.518 270.318 301.624 272.051Z" fill="#DEDEDE"/>
<path d="M291.841 272.008C291.926 273.741 293.363 275.135 295.074 275.157C296.785 275.178 298.095 273.762 297.99 272.051C297.905 270.318 296.447 268.924 294.757 268.903C293.046 268.881 291.757 270.276 291.841 272.008Z" fill="#DEDEDE"/>
<path d="M282.038 271.987C282.102 273.72 283.538 275.115 285.25 275.115C286.961 275.115 288.271 273.72 288.187 271.987C288.102 270.255 286.665 268.86 284.975 268.839C283.285 268.86 281.975 270.255 282.038 271.987Z" fill="#DEDEDE"/>
<path d="M272.235 271.987C272.278 273.72 273.714 275.114 275.405 275.114C277.116 275.114 278.447 273.72 278.384 271.987C278.32 270.255 276.905 268.86 275.214 268.86C273.524 268.839 272.193 270.255 272.235 271.987Z" fill="#DEDEDE"/>
<path d="M262.431 272.008C262.473 273.741 263.868 275.135 265.579 275.135C267.291 275.135 268.622 273.72 268.579 272.008C268.537 270.276 267.122 268.881 265.431 268.881C263.741 268.86 262.389 270.276 262.431 272.008Z" fill="#DEDEDE"/>
<path d="M252.628 272.051C252.649 273.783 254.043 275.178 255.755 275.157C257.466 275.157 258.818 273.741 258.797 272.009C258.776 270.276 257.36 268.882 255.67 268.882C253.98 268.903 252.607 270.318 252.628 272.051Z" fill="#DEDEDE"/>
<path d="M242.824 272.114C242.824 273.846 244.218 275.241 245.908 275.22C247.62 275.199 248.993 273.804 248.972 272.072C248.951 270.339 247.578 268.945 245.887 268.966C244.197 268.966 242.824 270.381 242.824 272.114Z" fill="#DEDEDE"/>
<path d="M233.021 272.199C233 273.931 234.373 275.305 236.084 275.305C237.796 275.283 239.169 273.868 239.169 272.156C239.169 270.424 237.796 269.029 236.105 269.051C234.415 269.051 233.021 270.466 233.021 272.199Z" fill="#DEDEDE"/>
<path d="M223.216 272.304C223.195 274.037 224.547 275.41 226.259 275.389C227.97 275.368 229.364 273.952 229.386 272.22C229.407 270.487 228.054 269.114 226.343 269.114C224.632 269.156 223.237 270.572 223.216 272.304Z" fill="#DEDEDE"/>
<path d="M213.391 272.431C213.348 274.164 214.701 275.537 216.391 275.516C218.102 275.495 219.518 274.079 219.539 272.347C219.581 270.614 218.229 269.241 216.539 269.262C214.849 269.283 213.454 270.699 213.391 272.431Z" fill="#DEDEDE"/>
<path d="M203.588 272.579C203.525 274.311 204.856 275.685 206.567 275.664C208.279 275.643 209.694 274.206 209.758 272.494C209.8 270.762 208.469 269.389 206.778 269.41C205.088 269.431 203.651 270.846 203.588 272.579Z" fill="#DEDEDE"/>
<path d="M193.784 272.748C193.7 274.481 195.031 275.854 196.721 275.812C198.432 275.791 199.869 274.354 199.932 272.621C199.996 270.889 198.686 269.515 196.996 269.537C195.305 269.6 193.869 271.016 193.784 272.748Z" fill="#DEDEDE"/>
<path d="M184.002 272.938C183.917 274.67 185.206 276.044 186.918 276.002C188.629 275.959 190.066 274.544 190.15 272.811C190.235 271.079 188.925 269.705 187.235 269.748C185.544 269.79 184.086 271.227 184.002 272.938Z" fill="#DEDEDE"/>
<path d="M174.199 273.17C174.093 274.903 175.382 276.276 177.094 276.234C178.805 276.192 180.263 274.755 180.368 273.044C180.474 271.311 179.185 269.938 177.474 269.98C175.784 270.001 174.305 271.438 174.199 273.17Z" fill="#DEDEDE"/>
<path d="M164.415 273.403C164.289 275.135 165.577 276.488 167.268 276.445C168.979 276.403 170.437 274.966 170.564 273.234C170.669 271.501 169.402 270.128 167.711 270.17C166.021 270.234 164.542 271.67 164.415 273.403Z" fill="#DEDEDE"/>
<path d="M154.634 273.678C154.486 275.41 155.754 276.763 157.465 276.72C159.176 276.678 160.655 275.241 160.782 273.509C160.909 271.776 159.641 270.424 157.951 270.466C156.261 270.509 154.761 271.945 154.634 273.678Z" fill="#DEDEDE"/>
<path d="M144.851 273.952C144.703 275.685 145.949 277.037 147.64 276.995C149.351 276.952 150.83 275.516 150.978 273.783C151.126 272.05 149.879 270.698 148.189 270.74C146.499 270.783 144.999 272.219 144.851 273.952Z" fill="#DEDEDE"/>
<path d="M96.0648 275.685C95.8324 277.417 97.0156 278.749 98.7059 278.685C100.396 278.622 101.96 277.164 102.192 275.431C102.424 273.699 101.241 272.368 99.551 272.431C97.8607 272.516 96.3183 273.973 96.0648 275.685Z" fill="#DEDEDE"/>
<path d="M57.231 277.481C56.9352 279.213 58.055 280.544 59.7453 280.46C61.4355 280.375 63.0413 278.917 63.316 277.185C63.6118 275.452 62.492 274.121 60.8017 274.206C59.1537 274.269 57.5479 275.748 57.231 277.481Z" fill="#DEDEDE"/>
<path d="M632.222 277.227C632.877 278.96 634.673 280.46 636.257 280.587C637.842 280.714 638.582 279.425 637.927 277.713C637.272 275.981 635.455 274.481 633.891 274.354C632.328 274.227 631.567 275.516 632.222 277.227Z" fill="#DEDEDE"/>
<path d="M549.377 271.058C549.884 272.79 551.617 274.269 553.244 274.375C554.87 274.481 555.758 273.171 555.23 271.438C554.701 269.706 552.969 268.227 551.363 268.121C549.757 268.015 548.87 269.325 549.377 271.058Z" fill="#DEDEDE"/>
<path d="M540.06 270.466C540.568 272.199 542.279 273.678 543.906 273.783C545.533 273.889 546.441 272.579 545.934 270.846C545.427 269.114 543.695 267.635 542.089 267.529C540.462 267.424 539.553 268.733 540.06 270.466Z" fill="#DEDEDE"/>
<path d="M530.7 269.896C531.186 271.628 532.898 273.107 534.524 273.192C536.151 273.297 537.081 271.966 536.574 270.255C536.088 268.522 534.355 267.043 532.75 266.938C531.123 266.832 530.214 268.163 530.7 269.896Z" fill="#DEDEDE"/>
<path d="M407.287 264.254C407.562 265.987 409.146 267.424 410.816 267.487C412.485 267.55 413.605 266.177 413.309 264.445C413.013 262.712 411.428 261.275 409.78 261.212C408.111 261.17 406.991 262.522 407.287 264.254Z" fill="#DEDEDE"/>
<path d="M397.674 263.958C397.927 265.691 399.512 267.128 401.181 267.17C402.85 267.212 403.991 265.86 403.716 264.128C403.442 262.395 401.878 260.958 400.209 260.916C398.54 260.874 397.399 262.226 397.674 263.958Z" fill="#DEDEDE"/>
<path d="M388.061 263.705C388.315 265.437 389.857 266.874 391.547 266.916C393.216 266.959 394.379 265.606 394.104 263.874C393.85 262.141 392.287 260.705 390.618 260.662C388.949 260.599 387.808 261.972 388.061 263.705Z" fill="#DEDEDE"/>
<path d="M378.427 263.451C378.659 265.184 380.201 266.621 381.871 266.663C383.54 266.705 384.702 265.332 384.469 263.599C384.237 261.867 382.673 260.43 381.025 260.388C379.356 260.367 378.194 261.719 378.427 263.451Z" fill="#DEDEDE"/>
<path d="M368.792 263.24C369.003 264.973 370.546 266.409 372.215 266.431C373.884 266.473 375.067 265.099 374.856 263.367C374.623 261.634 373.102 260.198 371.433 260.176C369.743 260.134 368.581 261.508 368.792 263.24Z" fill="#DEDEDE"/>
<path d="M359.136 263.029C359.326 264.762 360.847 266.177 362.537 266.219C364.207 266.262 365.411 264.888 365.2 263.156C364.988 261.423 363.467 259.986 361.798 259.965C360.129 259.923 358.946 261.296 359.136 263.029Z" fill="#DEDEDE"/>
<path d="M349.481 262.86C349.671 264.592 351.171 266.008 352.861 266.05C354.552 266.071 355.756 264.698 355.566 262.987C355.376 261.254 353.854 259.838 352.185 259.796C350.495 259.754 349.291 261.127 349.481 262.86Z" fill="#DEDEDE"/>
<path d="M339.804 262.691C339.973 264.423 341.473 265.839 343.142 265.86C344.832 265.881 346.058 264.508 345.868 262.775C345.699 261.043 344.198 259.627 342.529 259.606C340.86 259.585 339.635 260.958 339.804 262.691Z" fill="#DEDEDE"/>
<path d="M330.127 262.564C330.275 264.297 331.754 265.712 333.444 265.734C335.134 265.755 336.36 264.381 336.212 262.649C336.043 260.916 334.564 259.501 332.894 259.479C331.225 259.437 329.979 260.832 330.127 262.564Z" fill="#DEDEDE"/>
<path d="M320.45 262.437C320.577 264.17 322.056 265.585 323.746 265.606C325.436 265.627 326.683 264.233 326.535 262.522C326.387 260.789 324.908 259.373 323.239 259.352C321.57 259.331 320.323 260.726 320.45 262.437Z" fill="#DEDEDE"/>
<path d="M310.752 262.353C310.858 264.085 312.337 265.501 314.006 265.501C315.696 265.522 316.964 264.128 316.837 262.395C316.71 260.662 315.253 259.247 313.583 259.247C311.914 259.226 310.647 260.62 310.752 262.353Z" fill="#DEDEDE"/>
<path d="M301.055 262.29C301.16 264.022 302.597 265.438 304.287 265.438C305.978 265.459 307.245 264.065 307.14 262.332C307.034 260.599 305.576 259.184 303.907 259.184C302.238 259.163 300.97 260.557 301.055 262.29Z" fill="#DEDEDE"/>
<path d="M291.356 262.226C291.44 263.959 292.877 265.353 294.567 265.374C296.258 265.374 297.547 263.98 297.462 262.268C297.356 260.536 295.92 259.141 294.251 259.12C292.56 259.12 291.271 260.515 291.356 262.226Z" fill="#DEDEDE"/>
<path d="M281.657 262.205C281.721 263.937 283.158 265.332 284.848 265.332C286.538 265.332 287.848 263.937 287.764 262.205C287.679 260.472 286.264 259.078 284.594 259.078C282.904 259.078 281.594 260.472 281.657 262.205Z" fill="#DEDEDE"/>
<path d="M271.939 262.205C271.982 263.937 273.397 265.332 275.088 265.332C276.778 265.332 278.088 263.937 278.024 262.205C277.961 260.472 276.545 259.078 274.876 259.078C273.207 259.078 271.897 260.472 271.939 262.205Z" fill="#DEDEDE"/>
<path d="M262.24 262.226C262.282 263.958 263.677 265.353 265.367 265.353C267.057 265.353 268.388 263.937 268.346 262.226C268.304 260.493 266.909 259.099 265.219 259.099C263.529 259.099 262.197 260.493 262.24 262.226Z" fill="#DEDEDE"/>
<path d="M252.522 262.268C252.543 264.001 253.916 265.395 255.606 265.374C257.297 265.374 258.649 263.958 258.607 262.226C258.586 260.493 257.191 259.099 255.522 259.099C253.853 259.141 252.501 260.536 252.522 262.268Z" fill="#DEDEDE"/>
<path d="M242.803 262.332C242.803 264.065 244.176 265.459 245.866 265.438C247.557 265.417 248.909 264.022 248.909 262.29C248.888 260.557 247.514 259.163 245.845 259.184C244.176 259.184 242.803 260.599 242.803 262.332Z" fill="#DEDEDE"/>
<path d="M233.084 262.416C233.063 264.149 234.436 265.522 236.126 265.522C237.817 265.501 239.19 264.085 239.19 262.374C239.19 260.641 237.838 259.247 236.147 259.268C234.478 259.268 233.105 260.684 233.084 262.416Z" fill="#DEDEDE"/>
<path d="M223.385 262.522C223.343 264.254 224.695 265.628 226.386 265.606C228.076 265.585 229.47 264.17 229.491 262.437C229.512 260.705 228.16 259.331 226.491 259.331C224.801 259.374 223.406 260.789 223.385 262.522Z" fill="#DEDEDE"/>
<path d="M213.666 262.649C213.624 264.381 214.955 265.754 216.645 265.733C218.335 265.712 219.73 264.297 219.772 262.564C219.814 260.831 218.483 259.458 216.793 259.479C215.103 259.5 213.708 260.937 213.666 262.649Z" fill="#DEDEDE"/>
<path d="M203.948 262.796C203.884 264.529 205.194 265.902 206.884 265.881C208.575 265.86 209.99 264.423 210.033 262.712C210.096 260.979 208.765 259.606 207.096 259.627C205.427 259.648 204.011 261.085 203.948 262.796Z" fill="#DEDEDE"/>
<path d="M194.249 262.986C194.165 264.719 195.475 266.092 197.165 266.05C198.856 266.029 200.271 264.592 200.356 262.86C200.419 261.127 199.109 259.754 197.44 259.775C195.75 259.817 194.313 261.254 194.249 262.986Z" fill="#DEDEDE"/>
<path d="M184.53 263.177C184.424 264.909 185.713 266.283 187.403 266.24C189.094 266.198 190.53 264.782 190.615 263.05C190.7 261.317 189.411 259.944 187.742 259.986C186.072 260.029 184.636 261.444 184.53 263.177Z" fill="#DEDEDE"/>
<path d="M174.832 263.388C174.727 265.121 175.994 266.494 177.685 266.452C179.375 266.41 180.833 264.973 180.917 263.261C181.023 261.529 179.734 260.155 178.065 260.198C176.396 260.24 174.938 261.677 174.832 263.388Z" fill="#DEDEDE"/>
<path d="M165.135 263.642C165.008 265.374 166.276 266.726 167.966 266.684C169.656 266.642 171.114 265.205 171.241 263.473C171.368 261.74 170.1 260.367 168.41 260.409C166.719 260.472 165.261 261.909 165.135 263.642Z" fill="#DEDEDE"/>
<path d="M155.437 263.895C155.289 265.627 156.535 266.98 158.226 266.937C159.916 266.895 161.395 265.458 161.522 263.726C161.649 261.993 160.402 260.641 158.733 260.683C157.064 260.726 155.585 262.162 155.437 263.895Z" fill="#DEDEDE"/>
<path d="M145.738 264.191C145.569 265.924 146.816 267.276 148.506 267.234C150.196 267.191 151.675 265.733 151.823 264.022C151.971 262.289 150.745 260.937 149.055 260.979C147.407 261.022 145.907 262.458 145.738 264.191Z" fill="#DEDEDE"/>
<path d="M78.1688 266.769C77.8941 268.501 79.035 269.833 80.7042 269.748C82.3733 269.664 83.958 268.206 84.2115 266.473C84.4651 264.741 83.3452 263.409 81.6761 263.473C80.0069 263.579 78.4434 265.058 78.1688 266.769Z" fill="#DEDEDE"/>
<path d="M68.5567 267.234C68.2609 268.966 69.3807 270.297 71.0498 270.213C72.719 270.128 74.3036 268.67 74.5783 266.938C74.853 265.205 73.7332 263.874 72.0851 263.959C70.4371 264.043 68.8525 265.501 68.5567 267.234Z" fill="#DEDEDE"/>
<path d="M637.503 268.269C638.18 270.001 639.976 271.502 641.539 271.649C643.103 271.776 643.821 270.508 643.124 268.776C642.448 267.043 640.631 265.543 639.088 265.395C637.546 265.247 636.849 266.536 637.503 268.269Z" fill="#DEDEDE"/>
<path d="M628.525 267.487C629.18 269.219 630.976 270.719 632.54 270.846C634.103 270.973 634.821 269.684 634.167 267.973C633.512 266.24 631.694 264.74 630.152 264.613C628.61 264.465 627.87 265.754 628.525 267.487Z" fill="#DEDEDE"/>
<path d="M619.525 266.727C620.159 268.459 621.955 269.959 623.518 270.086C625.082 270.213 625.821 268.924 625.187 267.192C624.532 265.459 622.736 263.959 621.173 263.832C619.631 263.705 618.87 264.994 619.525 266.727Z" fill="#DEDEDE"/>
<path d="M610.481 265.987C611.115 267.719 612.89 269.22 614.474 269.346C616.059 269.473 616.819 268.184 616.164 266.452C615.531 264.719 613.735 263.219 612.171 263.092C610.608 262.966 609.847 264.254 610.481 265.987Z" fill="#DEDEDE"/>
<path d="M546.441 261.296C546.969 263.029 548.681 264.508 550.287 264.614C551.892 264.719 552.759 263.409 552.23 261.677C551.702 259.944 549.97 258.465 548.385 258.359C546.8 258.254 545.934 259.564 546.441 261.296Z" fill="#DEDEDE"/>
<path d="M537.208 260.705C537.715 262.437 539.427 263.916 541.032 264.022C542.638 264.128 543.526 262.818 543.018 261.085C542.49 259.353 540.779 257.874 539.194 257.768C537.588 257.662 536.701 258.972 537.208 260.705Z" fill="#DEDEDE"/>
<path d="M434.143 255.465C434.481 257.197 436.087 258.634 437.735 258.719C439.383 258.782 440.439 257.43 440.101 255.697C439.763 253.965 438.157 252.507 436.509 252.443C434.861 252.401 433.826 253.753 434.143 255.465Z" fill="#DEDEDE"/>
<path d="M424.677 255.127C424.994 256.859 426.579 258.296 428.227 258.359C429.875 258.423 430.952 257.07 430.614 255.338C430.297 253.605 428.691 252.169 427.064 252.105C425.438 252.042 424.36 253.394 424.677 255.127Z" fill="#DEDEDE"/>
<path d="M415.19 254.789C415.486 256.521 417.071 257.958 418.719 258.021C420.367 258.085 421.466 256.732 421.149 255C420.832 253.267 419.247 251.831 417.62 251.767C415.972 251.704 414.895 253.056 415.19 254.789Z" fill="#DEDEDE"/>
<path d="M405.683 254.493C405.958 256.225 407.542 257.662 409.191 257.725C410.839 257.768 411.937 256.415 411.663 254.704C411.367 252.971 409.803 251.535 408.155 251.471C406.507 251.387 405.409 252.76 405.683 254.493Z" fill="#DEDEDE"/>
<path d="M396.175 254.197C396.449 255.93 397.992 257.366 399.661 257.409C401.309 257.451 402.429 256.099 402.154 254.366C401.879 252.634 400.316 251.197 398.668 251.155C397.02 251.112 395.9 252.465 396.175 254.197Z" fill="#DEDEDE"/>
<path d="M386.647 253.923C386.9 255.655 388.443 257.092 390.112 257.134C391.781 257.176 392.901 255.824 392.647 254.092C392.394 252.359 390.83 250.922 389.182 250.88C387.534 250.838 386.393 252.211 386.647 253.923Z" fill="#DEDEDE"/>
<path d="M377.116 253.69C377.349 255.422 378.891 256.859 380.539 256.901C382.208 256.944 383.349 255.57 383.096 253.859C382.842 252.126 381.321 250.69 379.673 250.647C378.025 250.584 376.884 251.957 377.116 253.69Z" fill="#DEDEDE"/>
<path d="M367.566 253.457C367.778 255.19 369.299 256.606 370.968 256.648C372.637 256.69 373.799 255.317 373.567 253.584C373.334 251.852 371.813 250.415 370.165 250.394C368.517 250.373 367.355 251.725 367.566 253.457Z" fill="#DEDEDE"/>
<path d="M357.997 253.267C358.208 255 359.708 256.416 361.377 256.458C363.046 256.5 364.208 255.127 363.997 253.394C363.786 251.662 362.265 250.246 360.617 250.204C358.99 250.162 357.806 251.535 357.997 253.267Z" fill="#DEDEDE"/>
<path d="M348.445 253.078C348.635 254.81 350.135 256.226 351.783 256.268C353.452 256.289 354.636 254.916 354.445 253.204C354.255 251.472 352.755 250.056 351.086 250.014C349.438 249.972 348.255 251.345 348.445 253.078Z" fill="#DEDEDE"/>
<path d="M338.874 252.93C339.043 254.662 340.522 256.078 342.191 256.099C343.861 256.12 345.065 254.747 344.875 253.014C344.685 251.281 343.206 249.866 341.558 249.845C339.909 249.824 338.705 251.197 338.874 252.93Z" fill="#DEDEDE"/>
<path d="M329.281 252.782C329.429 254.514 330.908 255.93 332.577 255.951C334.246 255.972 335.472 254.599 335.303 252.866C335.134 251.134 333.655 249.718 332.007 249.697C330.359 249.676 329.133 251.07 329.281 252.782Z" fill="#DEDEDE"/>
<path d="M319.69 252.676C319.816 254.408 321.295 255.824 322.965 255.845C324.634 255.866 325.859 254.472 325.732 252.76C325.585 251.028 324.127 249.612 322.458 249.591C320.788 249.57 319.563 250.943 319.69 252.676Z" fill="#DEDEDE"/>
<path d="M310.097 252.591C310.224 254.324 311.66 255.74 313.329 255.74C314.999 255.761 316.245 254.366 316.118 252.634C315.992 250.901 314.534 249.486 312.886 249.486C311.217 249.464 309.97 250.859 310.097 252.591Z" fill="#DEDEDE"/>
<path d="M300.483 252.507C300.589 254.24 302.025 255.655 303.695 255.655C305.364 255.677 306.631 254.282 306.526 252.55C306.42 250.817 304.983 249.401 303.314 249.401C301.645 249.401 300.399 250.796 300.483 252.507Z" fill="#DEDEDE"/>
<path d="M290.891 252.464C290.975 254.197 292.391 255.591 294.081 255.612C295.75 255.612 297.039 254.218 296.933 252.507C296.828 250.774 295.412 249.38 293.743 249.358C292.074 249.337 290.806 250.732 290.891 252.464Z" fill="#DEDEDE"/>
<path d="M281.278 252.443C281.341 254.176 282.757 255.57 284.426 255.57C286.095 255.57 287.384 254.176 287.32 252.443C287.236 250.711 285.82 249.316 284.172 249.316C282.503 249.316 281.214 250.711 281.278 252.443Z" fill="#DEDEDE"/>
<path d="M271.665 252.443C271.707 254.176 273.123 255.57 274.792 255.57C276.461 255.57 277.771 254.176 277.708 252.443C277.644 250.711 276.25 249.316 274.581 249.316C272.911 249.316 271.601 250.711 271.665 252.443Z" fill="#DEDEDE"/>
<path d="M262.029 252.464C262.071 254.197 263.445 255.591 265.114 255.591C266.783 255.591 268.114 254.176 268.072 252.464C268.03 250.732 266.635 249.337 264.987 249.337C263.318 249.316 262.008 250.732 262.029 252.464Z" fill="#DEDEDE"/>
<path d="M252.416 252.507C252.437 254.239 253.81 255.634 255.479 255.612C257.149 255.612 258.48 254.197 258.459 252.464C258.437 250.732 257.064 249.337 255.395 249.358C253.726 249.358 252.395 250.774 252.416 252.507Z" fill="#DEDEDE"/>
<path d="M242.803 252.57C242.803 254.303 244.155 255.697 245.845 255.676C247.514 255.655 248.867 254.261 248.845 252.528C248.824 250.795 247.472 249.401 245.803 249.422C244.155 249.422 242.803 250.838 242.803 252.57Z" fill="#DEDEDE"/>
<path d="M233.167 252.655C233.146 254.387 234.498 255.761 236.167 255.761C237.837 255.74 239.21 254.324 239.21 252.612C239.21 250.88 237.879 249.507 236.21 249.507C234.562 249.507 233.188 250.922 233.167 252.655Z" fill="#DEDEDE"/>
<path d="M223.555 252.76C223.513 254.493 224.844 255.866 226.534 255.845C228.203 255.824 229.577 254.408 229.598 252.676C229.619 250.943 228.288 249.57 226.619 249.591C224.971 249.612 223.597 251.028 223.555 252.76Z" fill="#DEDEDE"/>
<path d="M213.941 252.888C213.899 254.62 215.209 255.993 216.878 255.972C218.547 255.951 219.941 254.536 219.984 252.803C220.026 251.07 218.695 249.697 217.047 249.718C215.378 249.739 213.983 251.155 213.941 252.888Z" fill="#DEDEDE"/>
<path d="M204.306 253.035C204.243 254.768 205.553 256.141 207.222 256.12C208.891 256.099 210.306 254.662 210.349 252.951C210.412 251.218 209.102 249.845 207.433 249.866C205.785 249.887 204.369 251.303 204.306 253.035Z" fill="#DEDEDE"/>
<path d="M194.693 253.204C194.608 254.937 195.897 256.31 197.566 256.268C199.235 256.247 200.651 254.81 200.714 253.078C200.778 251.345 199.489 249.972 197.841 250.014C196.193 250.056 194.777 251.493 194.693 253.204Z" fill="#DEDEDE"/>
<path d="M185.079 253.415C184.973 255.148 186.262 256.521 187.931 256.479C189.6 256.437 191.037 255.021 191.122 253.289C191.206 251.556 189.938 250.183 188.269 250.225C186.6 250.246 185.184 251.683 185.079 253.415Z" fill="#DEDEDE"/>
<path d="M175.467 253.627C175.34 255.359 176.608 256.733 178.277 256.69C179.946 256.648 181.383 255.211 181.488 253.5C181.594 251.767 180.326 250.394 178.678 250.436C177.03 250.457 175.594 251.894 175.467 253.627Z" fill="#DEDEDE"/>
<path d="M165.874 253.859C165.747 255.592 166.994 256.944 168.663 256.901C170.332 256.859 171.79 255.423 171.917 253.69C172.044 251.957 170.797 250.605 169.128 250.647C167.459 250.711 166.001 252.148 165.874 253.859Z" fill="#DEDEDE"/>
<path d="M156.26 254.134C156.112 255.866 157.337 257.219 159.006 257.176C160.675 257.134 162.133 255.697 162.281 253.965C162.408 252.232 161.183 250.88 159.535 250.922C157.887 250.965 156.408 252.401 156.26 254.134Z" fill="#DEDEDE"/>
<path d="M146.667 254.409C146.498 256.141 147.723 257.493 149.393 257.451C151.062 257.409 152.541 255.951 152.689 254.24C152.836 252.507 151.632 251.155 149.963 251.197C148.315 251.239 146.836 252.697 146.667 254.409Z" fill="#DEDEDE"/>
<path d="M633.701 258.507C634.377 260.24 636.173 261.74 637.736 261.867C639.279 261.994 639.976 260.726 639.279 258.993C638.581 257.261 636.786 255.761 635.243 255.613C633.701 255.507 633.025 256.796 633.701 258.507Z" fill="#DEDEDE"/>
<path d="M624.785 257.747C625.44 259.479 627.236 260.979 628.799 261.106C630.341 261.233 631.06 259.944 630.384 258.233C629.708 256.5 627.912 255 626.369 254.873C624.848 254.725 624.13 256.014 624.785 257.747Z" fill="#DEDEDE"/>
<path d="M615.868 256.986C616.523 258.718 618.298 260.219 619.862 260.345C621.425 260.472 622.144 259.183 621.489 257.472C620.834 255.739 619.038 254.239 617.495 254.112C615.932 253.964 615.213 255.253 615.868 256.986Z" fill="#DEDEDE"/>
<path d="M606.911 256.226C607.544 257.958 609.319 259.459 610.883 259.585C612.446 259.712 613.186 258.423 612.531 256.691C611.876 254.958 610.101 253.458 608.559 253.331C607.016 253.204 606.277 254.514 606.911 256.226Z" fill="#DEDEDE"/>
<path d="M561.716 252.782C562.287 254.514 564.019 255.993 565.604 256.099C567.188 256.204 568.012 254.894 567.442 253.183C566.872 251.45 565.139 249.971 563.554 249.845C561.991 249.76 561.167 251.07 561.716 252.782Z" fill="#DEDEDE"/>
<path d="M552.611 252.148C553.161 253.88 554.893 255.36 556.478 255.465C558.062 255.571 558.908 254.261 558.337 252.549C557.788 250.817 556.055 249.338 554.471 249.232C552.907 249.105 552.062 250.437 552.611 252.148Z" fill="#DEDEDE"/>
<path d="M543.482 251.535C544.011 253.268 545.722 254.747 547.328 254.853C548.912 254.958 549.779 253.648 549.229 251.916C548.68 250.183 546.969 248.704 545.384 248.598C543.799 248.493 542.954 249.803 543.482 251.535Z" fill="#DEDEDE"/>
<path d="M534.313 250.944C534.82 252.676 536.531 254.155 538.137 254.261C539.743 254.367 540.609 253.057 540.081 251.324C539.553 249.592 537.841 248.113 536.257 248.007C534.672 247.901 533.806 249.211 534.313 250.944Z" fill="#DEDEDE"/>
<path d="M525.144 250.373C525.651 252.106 527.341 253.585 528.947 253.669C530.553 253.775 531.44 252.444 530.912 250.732C530.405 249 528.693 247.521 527.109 247.436C525.524 247.331 524.637 248.641 525.144 250.373Z" fill="#DEDEDE"/>
<path d="M450.96 246.485C451.319 248.218 452.946 249.676 454.573 249.739C456.2 249.803 457.214 248.472 456.834 246.739C456.453 245.006 454.827 243.549 453.221 243.485C451.615 243.401 450.601 244.753 450.96 246.485Z" fill="#DEDEDE"/>
<path d="M441.623 246.084C441.982 247.816 443.587 249.253 445.214 249.337C446.841 249.401 447.877 248.07 447.496 246.337C447.137 244.605 445.531 243.147 443.904 243.083C442.299 243.02 441.263 244.351 441.623 246.084Z" fill="#DEDEDE"/>
<path d="M432.263 245.703C432.601 247.436 434.185 248.873 435.833 248.936C437.46 248.999 438.496 247.647 438.158 245.936C437.798 244.203 436.214 242.766 434.587 242.703C432.96 242.64 431.925 243.992 432.263 245.703Z" fill="#DEDEDE"/>
<path d="M422.882 245.366C423.199 247.098 424.783 248.535 426.41 248.598C428.037 248.662 429.093 247.309 428.776 245.577C428.438 243.844 426.854 242.408 425.248 242.344C423.6 242.281 422.565 243.633 422.882 245.366Z" fill="#DEDEDE"/>
<path d="M413.479 245.027C413.775 246.76 415.359 248.196 416.986 248.26C418.613 248.323 419.691 246.971 419.374 245.238C419.057 243.506 417.493 242.069 415.866 242.006C414.239 241.942 413.183 243.295 413.479 245.027Z" fill="#DEDEDE"/>
<path d="M404.077 244.71C404.373 246.443 405.915 247.88 407.563 247.922C409.211 247.985 410.289 246.612 409.993 244.9C409.697 243.168 408.134 241.731 406.507 241.689C404.88 241.647 403.781 242.999 404.077 244.71Z" fill="#DEDEDE"/>
<path d="M394.653 244.436C394.928 246.169 396.47 247.605 398.119 247.648C399.767 247.69 400.865 246.338 400.569 244.605C400.295 242.873 398.731 241.436 397.104 241.394C395.477 241.351 394.379 242.704 394.653 244.436Z" fill="#DEDEDE"/>
<path d="M385.23 244.161C385.483 245.893 387.026 247.33 388.653 247.372C390.301 247.415 391.42 246.062 391.146 244.33C390.871 242.597 389.35 241.161 387.723 241.118C386.075 241.076 384.976 242.449 385.23 244.161Z" fill="#DEDEDE"/>
<path d="M375.786 243.929C376.019 245.661 377.54 247.098 379.188 247.119C380.836 247.162 381.956 245.788 381.723 244.077C381.47 242.344 379.949 240.907 378.322 240.865C376.674 240.823 375.533 242.196 375.786 243.929Z" fill="#DEDEDE"/>
<path d="M366.321 243.696C366.532 245.429 368.053 246.845 369.701 246.887C371.349 246.929 372.49 245.556 372.258 243.823C372.025 242.091 370.504 240.675 368.877 240.633C367.25 240.612 366.109 241.964 366.321 243.696Z" fill="#DEDEDE"/>
<path d="M356.855 243.506C357.066 245.239 358.566 246.655 360.214 246.697C361.862 246.739 363.024 245.366 362.813 243.633C362.602 241.901 361.102 240.485 359.454 240.443C357.827 240.401 356.665 241.774 356.855 243.506Z" fill="#DEDEDE"/>
<path d="M347.388 243.315C347.578 245.048 349.057 246.464 350.705 246.485C352.353 246.506 353.537 245.133 353.325 243.421C353.135 241.689 351.635 240.273 350.008 240.231C348.381 240.21 347.198 241.583 347.388 243.315Z" fill="#DEDEDE"/>
<path d="M337.902 243.168C338.071 244.901 339.55 246.316 341.198 246.338C342.846 246.359 344.05 244.985 343.86 243.253C343.67 241.52 342.212 240.105 340.564 240.083C338.937 240.062 337.733 241.436 337.902 243.168Z" fill="#DEDEDE"/>
<path d="M328.416 243.02C328.564 244.753 330.021 246.168 331.691 246.189C333.339 246.21 334.543 244.837 334.374 243.105C334.205 241.372 332.747 239.956 331.099 239.935C329.472 239.914 328.268 241.309 328.416 243.02Z" fill="#DEDEDE"/>
<path d="M318.93 242.915C319.056 244.647 320.514 246.063 322.162 246.084C323.81 246.105 325.036 244.711 324.888 242.999C324.74 241.267 323.282 239.851 321.655 239.83C320.007 239.809 318.782 241.182 318.93 242.915Z" fill="#DEDEDE"/>
<path d="M309.42 242.83C309.547 244.562 310.984 245.957 312.632 245.978C314.28 245.999 315.527 244.605 315.4 242.872C315.273 241.139 313.836 239.724 312.188 239.724C310.54 239.703 309.294 241.097 309.42 242.83Z" fill="#DEDEDE"/>
<path d="M299.913 242.745C300.019 244.478 301.434 245.872 303.103 245.893C304.751 245.915 306.019 244.52 305.892 242.788C305.787 241.055 304.35 239.661 302.702 239.639C301.054 239.639 299.807 241.034 299.913 242.745Z" fill="#DEDEDE"/>
<path d="M290.406 242.703C290.49 244.436 291.906 245.83 293.554 245.851C295.202 245.851 296.47 244.457 296.385 242.746C296.28 241.013 294.885 239.619 293.237 239.597C291.589 239.576 290.321 240.971 290.406 242.703Z" fill="#DEDEDE"/>
<path d="M280.876 242.682C280.939 244.414 282.334 245.809 284.003 245.809C285.651 245.809 286.94 244.414 286.855 242.682C286.771 240.949 285.376 239.555 283.728 239.555C282.101 239.555 280.812 240.949 280.876 242.682Z" fill="#DEDEDE"/>
<path d="M271.368 242.682C271.41 244.414 272.805 245.809 274.453 245.809C276.101 245.809 277.411 244.414 277.347 242.682C277.284 240.949 275.889 239.555 274.263 239.555C272.615 239.555 271.305 240.949 271.368 242.682Z" fill="#DEDEDE"/>
<path d="M261.84 242.703C261.882 244.436 263.255 245.83 264.903 245.83C266.551 245.83 267.861 244.436 267.819 242.703C267.777 240.971 266.404 239.576 264.756 239.576C263.108 239.576 261.798 240.971 261.84 242.703Z" fill="#DEDEDE"/>
<path d="M252.311 242.746C252.332 244.478 253.684 245.873 255.353 245.851C257.022 245.851 258.332 244.436 258.311 242.703C258.29 240.971 256.917 239.576 255.269 239.597C253.621 239.597 252.311 241.013 252.311 242.746Z" fill="#DEDEDE"/>
<path d="M242.782 242.809C242.782 244.542 244.134 245.915 245.783 245.915C247.431 245.894 248.783 244.499 248.762 242.767C248.741 241.034 247.409 239.661 245.761 239.661C244.134 239.661 242.782 241.077 242.782 242.809Z" fill="#DEDEDE"/>
<path d="M233.274 242.894C233.253 244.626 234.584 245.999 236.253 245.999C237.901 245.978 239.253 244.563 239.253 242.851C239.253 241.119 237.922 239.745 236.274 239.745C234.626 239.745 233.274 241.161 233.274 242.894Z" fill="#DEDEDE"/>
<path d="M223.745 242.999C223.703 244.732 225.034 246.105 226.682 246.084C228.33 246.063 229.703 244.647 229.724 242.915C229.746 241.182 228.436 239.809 226.788 239.83C225.14 239.851 223.766 241.267 223.745 242.999Z" fill="#DEDEDE"/>
<path d="M214.216 243.126C214.174 244.858 215.462 246.231 217.132 246.21C218.801 246.189 220.174 244.774 220.195 243.041C220.237 241.309 218.927 239.935 217.279 239.956C215.631 239.977 214.258 241.393 214.216 243.126Z" fill="#DEDEDE"/>
<path d="M204.687 243.274C204.623 245.007 205.912 246.38 207.56 246.359C209.208 246.338 210.603 244.901 210.666 243.19C210.73 241.457 209.441 240.084 207.793 240.105C206.145 240.126 204.75 241.541 204.687 243.274Z" fill="#DEDEDE"/>
<path d="M195.159 243.443C195.074 245.175 196.342 246.549 198.011 246.506C199.659 246.485 201.075 245.049 201.138 243.316C201.202 241.584 199.934 240.21 198.286 240.252C196.659 240.295 195.243 241.731 195.159 243.443Z" fill="#DEDEDE"/>
<path d="M185.651 243.654C185.545 245.387 186.813 246.76 188.461 246.718C190.109 246.676 191.524 245.26 191.63 243.527C191.715 241.795 190.468 240.422 188.82 240.464C187.172 240.485 185.756 241.922 185.651 243.654Z" fill="#DEDEDE"/>
<path d="M176.121 243.865C175.994 245.598 177.241 246.95 178.91 246.929C180.558 246.887 181.995 245.45 182.101 243.739C182.206 242.006 180.96 240.654 179.312 240.675C177.685 240.717 176.248 242.133 176.121 243.865Z" fill="#DEDEDE"/>
<path d="M166.613 244.098C166.486 245.83 167.711 247.183 169.359 247.14C171.007 247.098 172.465 245.661 172.571 243.95C172.698 242.217 171.472 240.865 169.824 240.907C168.197 240.95 166.76 242.386 166.613 244.098Z" fill="#DEDEDE"/>
<path d="M157.105 244.372C156.957 246.105 158.182 247.457 159.83 247.415C161.478 247.372 162.936 245.936 163.084 244.203C163.232 242.471 162.007 241.118 160.359 241.161C158.711 241.203 157.274 242.64 157.105 244.372Z" fill="#DEDEDE"/>
<path d="M147.619 244.647C147.45 246.38 148.654 247.732 150.302 247.69C151.95 247.648 153.429 246.211 153.577 244.478C153.725 242.746 152.521 241.394 150.894 241.436C149.246 241.499 147.788 242.936 147.619 244.647Z" fill="#DEDEDE"/>
<path d="M53.0679 248.683C52.7298 250.415 53.7862 251.725 55.4342 251.641C57.0611 251.556 58.6669 250.077 58.9839 248.366C59.3008 246.633 58.2443 245.302 56.6174 245.408C54.9905 245.492 53.4059 246.971 53.0679 248.683Z" fill="#DEDEDE"/>
<path d="M43.6874 249.211C43.3282 250.944 44.3846 252.254 46.0115 252.169C47.6384 252.085 49.2442 250.606 49.5822 248.873C49.9203 247.14 48.885 245.83 47.2581 245.915C45.6312 246.021 44.0254 247.479 43.6874 249.211Z" fill="#DEDEDE"/>
<path d="M638.624 249.57C639.321 251.281 641.138 252.802 642.659 252.95C644.18 253.098 644.835 251.809 644.138 250.098C643.42 248.387 641.624 246.865 640.103 246.717C638.581 246.569 637.905 247.858 638.624 249.57Z" fill="#DEDEDE"/>
<path d="M629.834 248.767C630.511 250.5 632.328 252 633.849 252.127C635.37 252.275 636.046 250.986 635.349 249.253C634.652 247.542 632.856 246.021 631.334 245.894C629.813 245.767 629.137 247.056 629.834 248.767Z" fill="#DEDEDE"/>
<path d="M621.003 248.006C621.68 249.739 623.475 251.239 624.997 251.366C626.539 251.493 627.215 250.204 626.539 248.492C625.863 246.76 624.067 245.26 622.546 245.133C621.025 244.985 620.348 246.274 621.003 248.006Z" fill="#DEDEDE"/>
<path d="M612.171 247.225C612.826 248.957 614.601 250.457 616.144 250.584C617.686 250.711 618.383 249.422 617.728 247.711C617.052 245.978 615.277 244.478 613.756 244.351C612.214 244.224 611.516 245.513 612.171 247.225Z" fill="#DEDEDE"/>
<path d="M603.297 246.485C603.931 248.218 605.706 249.718 607.248 249.845C608.791 249.971 609.509 248.683 608.854 246.95C608.199 245.217 606.424 243.717 604.882 243.591C603.382 243.464 602.642 244.774 603.297 246.485Z" fill="#DEDEDE"/>
<path d="M594.403 245.767C595.037 247.499 596.791 248.978 598.354 249.105C599.897 249.232 600.636 247.943 600.002 246.21C599.368 244.478 597.594 242.999 596.051 242.872C594.509 242.745 593.769 244.034 594.403 245.767Z" fill="#DEDEDE"/>
<path d="M585.465 245.049C586.078 246.781 587.831 248.26 589.395 248.387C590.958 248.514 591.698 247.204 591.085 245.492C590.473 243.76 588.698 242.281 587.155 242.154C585.613 242.027 584.852 243.337 585.465 245.049Z" fill="#DEDEDE"/>
<path d="M567.548 243.696C568.14 245.428 569.872 246.907 571.436 247.034C572.999 247.14 573.781 245.851 573.189 244.118C572.598 242.386 570.865 240.907 569.302 240.78C567.738 240.653 566.956 241.963 567.548 243.696Z" fill="#DEDEDE"/>
<path d="M558.525 243.041C559.096 244.774 560.828 246.253 562.392 246.359C563.956 246.464 564.758 245.175 564.188 243.443C563.617 241.71 561.885 240.231 560.321 240.126C558.779 239.999 557.976 241.309 558.525 243.041Z" fill="#DEDEDE"/>
<path d="M549.504 242.407C550.054 244.14 551.786 245.619 553.35 245.725C554.913 245.83 555.737 244.52 555.188 242.809C554.617 241.076 552.906 239.597 551.342 239.492C549.779 239.365 548.955 240.675 549.504 242.407Z" fill="#DEDEDE"/>
<path d="M540.462 241.795C540.99 243.527 542.702 245.006 544.286 245.112C545.871 245.217 546.695 243.907 546.145 242.175C545.596 240.442 543.885 238.963 542.321 238.858C540.758 238.752 539.934 240.062 540.462 241.795Z" fill="#DEDEDE"/>
<path d="M531.397 241.182C531.926 242.914 533.616 244.393 535.201 244.478C536.785 244.584 537.63 243.274 537.102 241.541C536.574 239.809 534.863 238.33 533.299 238.245C531.714 238.161 530.869 239.471 531.397 241.182Z" fill="#DEDEDE"/>
<path d="M522.291 240.611C522.798 242.344 524.488 243.823 526.073 243.907C527.657 244.013 528.524 242.682 528.017 240.97C527.51 239.238 525.798 237.78 524.235 237.674C522.671 237.569 521.805 238.9 522.291 240.611Z" fill="#DEDEDE"/>
<path d="M513.185 240.062C513.671 241.794 515.361 243.252 516.946 243.358C518.53 243.464 519.418 242.133 518.911 240.421C518.404 238.689 516.734 237.231 515.15 237.125C513.586 237.019 512.699 238.329 513.185 240.062Z" fill="#DEDEDE"/>
<path d="M458.102 237.146C458.482 238.879 460.109 240.337 461.715 240.4C463.321 240.485 464.293 239.132 463.891 237.421C463.49 235.688 461.863 234.231 460.278 234.167C458.694 234.083 457.722 235.414 458.102 237.146Z" fill="#DEDEDE"/>
<path d="M448.868 236.724C449.249 238.456 450.854 239.893 452.46 239.978C454.066 240.041 455.059 238.71 454.679 236.977C454.298 235.245 452.692 233.808 451.087 233.724C449.481 233.66 448.488 235.012 448.868 236.724Z" fill="#DEDEDE"/>
<path d="M439.614 236.344C439.974 238.076 441.558 239.513 443.185 239.577C444.791 239.64 445.805 238.309 445.446 236.576C445.066 234.844 443.481 233.407 441.875 233.344C440.248 233.259 439.255 234.611 439.614 236.344Z" fill="#DEDEDE"/>
<path d="M430.34 235.963C430.678 237.696 432.263 239.132 433.89 239.196C435.496 239.259 436.531 237.907 436.172 236.196C435.813 234.463 434.228 233.026 432.622 232.963C431.016 232.899 430.002 234.231 430.34 235.963Z" fill="#DEDEDE"/>
<path d="M421.043 235.604C421.36 237.337 422.944 238.774 424.55 238.837C426.177 238.9 427.212 237.548 426.874 235.816C426.536 234.083 424.973 232.646 423.367 232.583C421.761 232.541 420.726 233.893 421.043 235.604Z" fill="#DEDEDE"/>
<path d="M411.746 235.287C412.063 237.02 413.605 238.456 415.232 238.52C416.859 238.583 417.916 237.231 417.578 235.498C417.261 233.766 415.697 232.329 414.091 232.266C412.486 232.202 411.45 233.555 411.746 235.287Z" fill="#DEDEDE"/>
<path d="M402.428 234.97C402.724 236.703 404.266 238.139 405.893 238.182C407.52 238.224 408.577 236.872 408.281 235.16C407.985 233.428 406.421 231.991 404.816 231.949C403.21 231.885 402.154 233.238 402.428 234.97Z" fill="#DEDEDE"/>
<path d="M393.111 234.674C393.386 236.407 394.928 237.844 396.555 237.886C398.182 237.928 399.259 236.576 398.985 234.865C398.689 233.132 397.168 231.695 395.541 231.653C393.914 231.611 392.836 232.963 393.111 234.674Z" fill="#DEDEDE"/>
<path d="M383.772 234.421C384.025 236.153 385.547 237.569 387.173 237.632C388.8 237.675 389.899 236.322 389.624 234.59C389.35 232.857 387.828 231.442 386.223 231.378C384.617 231.336 383.518 232.688 383.772 234.421Z" fill="#DEDEDE"/>
<path d="M374.434 234.167C374.666 235.9 376.187 237.315 377.814 237.358C379.441 237.4 380.561 236.027 380.307 234.315C380.054 232.583 378.533 231.167 376.927 231.125C375.3 231.082 374.18 232.456 374.434 234.167Z" fill="#DEDEDE"/>
<path d="M365.073 233.956C365.284 235.688 366.806 237.104 368.432 237.146C370.059 237.188 371.2 235.815 370.947 234.104C370.714 232.371 369.214 230.955 367.587 230.913C365.982 230.85 364.841 232.223 365.073 233.956Z" fill="#DEDEDE"/>
<path d="M355.692 233.745C355.903 235.478 357.382 236.893 359.009 236.936C360.636 236.978 361.777 235.605 361.566 233.872C361.355 232.14 359.854 230.724 358.249 230.682C356.643 230.661 355.502 232.034 355.692 233.745Z" fill="#DEDEDE"/>
<path d="M346.311 233.576C346.501 235.308 347.98 236.724 349.607 236.745C351.234 236.766 352.396 235.414 352.206 233.681C351.995 231.949 350.537 230.533 348.91 230.512C347.283 230.47 346.142 231.843 346.311 233.576Z" fill="#DEDEDE"/>
<path d="M336.93 233.407C337.099 235.139 338.557 236.555 340.205 236.576C341.832 236.597 343.015 235.224 342.825 233.512C342.635 231.78 341.177 230.364 339.55 230.343C337.944 230.322 336.761 231.695 336.93 233.407Z" fill="#DEDEDE"/>
<path d="M327.55 233.28C327.697 235.012 329.155 236.428 330.782 236.449C332.409 236.47 333.613 235.097 333.444 233.364C333.275 231.632 331.818 230.216 330.212 230.195C328.585 230.174 327.38 231.547 327.55 233.28Z" fill="#DEDEDE"/>
<path d="M318.147 233.153C318.295 234.886 319.732 236.28 321.359 236.302C322.985 236.323 324.211 234.949 324.042 233.217C323.894 231.484 322.457 230.09 320.83 230.069C319.203 230.047 317.999 231.442 318.147 233.153Z" fill="#DEDEDE"/>
<path d="M308.745 233.069C308.872 234.801 310.287 236.196 311.935 236.217C313.583 236.238 314.788 234.844 314.661 233.132C314.534 231.4 313.097 230.005 311.47 229.984C309.844 229.963 308.618 231.357 308.745 233.069Z" fill="#DEDEDE"/>
<path d="M299.321 233.005C299.427 234.737 300.842 236.132 302.469 236.153C304.117 236.174 305.343 234.78 305.237 233.047C305.132 231.315 303.716 229.92 302.089 229.899C300.462 229.899 299.216 231.272 299.321 233.005Z" fill="#DEDEDE"/>
<path d="M289.919 232.963C290.004 234.695 291.398 236.09 293.046 236.09C294.694 236.09 295.941 234.716 295.835 232.984C295.729 231.251 294.335 229.857 292.708 229.857C291.081 229.836 289.835 231.23 289.919 232.963Z" fill="#DEDEDE"/>
<path d="M280.495 232.921C280.559 234.653 281.953 236.048 283.58 236.048C285.228 236.048 286.475 234.653 286.411 232.942C286.327 231.209 284.954 229.815 283.327 229.815C281.679 229.815 280.432 231.209 280.495 232.921Z" fill="#DEDEDE"/>
<path d="M271.073 232.921C271.115 234.653 272.488 236.048 274.136 236.048C275.784 236.048 277.052 234.653 276.989 232.921C276.925 231.188 275.552 229.794 273.925 229.794C272.298 229.815 271.009 231.209 271.073 232.921Z" fill="#DEDEDE"/>
<path d="M261.649 232.942C261.691 234.675 263.043 236.069 264.691 236.048C266.339 236.048 267.628 234.653 267.586 232.921C267.544 231.188 266.192 229.815 264.544 229.815C262.896 229.815 261.607 231.231 261.649 232.942Z" fill="#DEDEDE"/>
<path d="M252.226 232.984C252.247 234.717 253.579 236.09 255.227 236.09C256.875 236.09 258.185 234.674 258.142 232.963C258.121 231.231 256.769 229.857 255.142 229.857C253.515 229.857 252.205 231.273 252.226 232.984Z" fill="#DEDEDE"/>
<path d="M242.782 233.047C242.782 234.78 244.113 236.153 245.761 236.153C247.409 236.132 248.719 234.737 248.719 233.005C248.698 231.272 247.367 229.899 245.74 229.899C244.113 229.92 242.782 231.336 242.782 233.047Z" fill="#DEDEDE"/>
<path d="M233.359 233.132C233.338 234.865 234.669 236.238 236.295 236.217C237.944 236.196 239.275 234.801 239.275 233.069C239.275 231.336 237.965 229.963 236.338 229.984C234.711 230.005 233.38 231.421 233.359 233.132Z" fill="#DEDEDE"/>
<path d="M223.935 233.238C223.892 234.971 225.202 236.344 226.85 236.323C228.498 236.302 229.851 234.886 229.872 233.175C229.893 231.442 228.583 230.069 226.956 230.09C225.308 230.111 223.956 231.527 223.935 233.238Z" fill="#DEDEDE"/>
<path d="M214.491 233.385C214.448 235.118 215.737 236.491 217.364 236.47C219.012 236.449 220.364 235.033 220.407 233.301C220.449 231.568 219.16 230.195 217.533 230.216C215.906 230.237 214.554 231.653 214.491 233.385Z" fill="#DEDEDE"/>
<path d="M205.067 233.534C205.003 235.266 206.271 236.639 207.919 236.597C209.567 236.576 210.94 235.16 210.983 233.428C211.046 231.695 209.757 230.322 208.13 230.364C206.525 230.385 205.13 231.801 205.067 233.534Z" fill="#DEDEDE"/>
<path d="M195.643 233.702C195.559 235.435 196.826 236.787 198.453 236.766C200.101 236.745 201.475 235.308 201.559 233.597C201.623 231.864 200.376 230.512 198.749 230.533C197.122 230.554 195.728 231.97 195.643 233.702Z" fill="#DEDEDE"/>
<path d="M186.22 233.893C186.114 235.626 187.361 236.978 189.009 236.957C190.657 236.914 192.052 235.499 192.136 233.766C192.221 232.034 190.974 230.682 189.347 230.703C187.72 230.745 186.326 232.182 186.22 233.893Z" fill="#DEDEDE"/>
<path d="M176.797 234.125C176.67 235.858 177.917 237.21 179.544 237.168C181.192 237.125 182.607 235.71 182.713 233.977C182.819 232.245 181.593 230.893 179.966 230.935C178.339 230.977 176.924 232.393 176.797 234.125Z" fill="#DEDEDE"/>
<path d="M167.395 234.357C167.247 236.09 168.472 237.442 170.121 237.4C171.769 237.358 173.184 235.921 173.311 234.209C173.438 232.477 172.212 231.125 170.585 231.167C168.958 231.209 167.522 232.646 167.395 234.357Z" fill="#DEDEDE"/>
<path d="M157.971 234.611C157.823 236.344 159.027 237.696 160.654 237.654C162.302 237.611 163.739 236.175 163.887 234.442C164.035 232.71 162.83 231.357 161.203 231.4C159.577 231.463 158.119 232.9 157.971 234.611Z" fill="#DEDEDE"/>
<path d="M148.57 234.907C148.401 236.639 149.584 237.992 151.232 237.928C152.859 237.886 154.317 236.449 154.486 234.717C154.655 232.984 153.45 231.632 151.824 231.695C150.197 231.738 148.739 233.174 148.57 234.907Z" fill="#DEDEDE"/>
<path d="M634.631 239.851C635.328 241.562 637.145 243.084 638.645 243.21C640.167 243.358 640.801 242.069 640.082 240.358C639.364 238.647 637.568 237.125 636.068 236.999C634.568 236.851 633.913 238.118 634.631 239.851Z" fill="#DEDEDE"/>
<path d="M625.926 239.048C626.623 240.76 628.419 242.281 629.919 242.408C631.441 242.555 632.096 241.267 631.398 239.534C630.701 237.823 628.905 236.301 627.405 236.175C625.884 236.048 625.229 237.337 625.926 239.048Z" fill="#DEDEDE"/>
<path d="M617.178 238.266C617.855 239.977 619.65 241.499 621.172 241.625C622.693 241.752 623.369 240.484 622.672 238.752C621.975 237.04 620.2 235.519 618.679 235.392C617.178 235.266 616.502 236.554 617.178 238.266Z" fill="#DEDEDE"/>
<path d="M608.432 237.506C609.108 239.217 610.862 240.717 612.404 240.865C613.925 240.992 614.623 239.703 613.946 237.992C613.27 236.28 611.496 234.78 609.974 234.632C608.453 234.505 607.756 235.773 608.432 237.506Z" fill="#DEDEDE"/>
<path d="M599.642 236.766C600.297 238.477 602.051 239.977 603.593 240.104C605.115 240.231 605.833 238.942 605.178 237.231C604.523 235.519 602.748 234.019 601.227 233.892C599.684 233.745 598.987 235.033 599.642 236.766Z" fill="#DEDEDE"/>
<path d="M590.81 236.027C591.444 237.738 593.198 239.238 594.74 239.365C596.282 239.492 597.001 238.203 596.346 236.47C595.691 234.759 593.937 233.259 592.416 233.132C590.916 233.026 590.176 234.315 590.81 236.027Z" fill="#DEDEDE"/>
<path d="M581.978 235.329C582.591 237.041 584.344 238.541 585.887 238.668C587.429 238.795 588.169 237.506 587.535 235.773C586.901 234.062 585.147 232.562 583.626 232.435C582.105 232.308 581.365 233.597 581.978 235.329Z" fill="#DEDEDE"/>
<path d="M573.104 234.632C573.717 236.343 575.449 237.843 576.991 237.97C578.534 238.097 579.294 236.787 578.682 235.075C578.069 233.364 576.315 231.864 574.794 231.737C573.252 231.61 572.512 232.899 573.104 234.632Z" fill="#DEDEDE"/>
<path d="M564.231 233.956C564.822 235.667 566.555 237.168 568.097 237.273C569.64 237.4 570.421 236.09 569.809 234.379C569.217 232.667 567.485 231.167 565.942 231.061C564.4 230.935 563.639 232.245 564.231 233.956Z" fill="#DEDEDE"/>
<path d="M555.315 233.301C555.885 235.013 557.618 236.513 559.16 236.618C560.703 236.724 561.506 235.435 560.914 233.703C560.322 231.991 558.611 230.491 557.069 230.385C555.526 230.28 554.744 231.59 555.315 233.301Z" fill="#DEDEDE"/>
<path d="M546.377 232.667C546.926 234.378 548.638 235.879 550.201 235.984C551.765 236.09 552.568 234.78 551.997 233.069C551.427 231.357 549.715 229.857 548.173 229.751C546.609 229.646 545.807 230.956 546.377 232.667Z" fill="#DEDEDE"/>
<path d="M537.419 232.054C537.969 233.766 539.659 235.266 541.223 235.371C542.786 235.477 543.589 234.167 543.04 232.456C542.49 230.744 540.779 229.244 539.237 229.139C537.694 229.033 536.87 230.343 537.419 232.054Z" fill="#DEDEDE"/>
<path d="M528.439 231.463C528.967 233.175 530.657 234.653 532.221 234.759C533.784 234.865 534.63 233.555 534.08 231.822C533.552 230.111 531.841 228.632 530.298 228.526C528.735 228.421 527.911 229.731 528.439 231.463Z" fill="#DEDEDE"/>
<path d="M519.438 230.871C519.945 232.583 521.636 234.062 523.199 234.167C524.763 234.273 525.608 232.942 525.101 231.231C524.573 229.519 522.903 228.04 521.34 227.935C519.776 227.85 518.91 229.16 519.438 230.871Z" fill="#DEDEDE"/>
<path d="M510.416 230.322C510.902 232.033 512.593 233.512 514.156 233.618C515.72 233.724 516.586 232.392 516.079 230.681C515.572 228.97 513.903 227.491 512.339 227.385C510.776 227.279 509.909 228.61 510.416 230.322Z" fill="#DEDEDE"/>
<path d="M455.884 227.406C456.286 229.118 457.892 230.576 459.476 230.66C461.061 230.745 462.033 229.392 461.61 227.681C461.209 225.97 459.582 224.512 458.018 224.427C456.434 224.364 455.483 225.695 455.884 227.406Z" fill="#DEDEDE"/>
<path d="M446.735 226.984C447.115 228.695 448.721 230.153 450.305 230.237C451.89 230.301 452.883 228.97 452.482 227.258C452.101 225.547 450.496 224.089 448.911 224.026C447.326 223.941 446.354 225.272 446.735 226.984Z" fill="#DEDEDE"/>
<path d="M437.565 226.603C437.924 228.314 439.509 229.772 441.115 229.836C442.72 229.899 443.692 228.568 443.333 226.835C442.953 225.124 441.368 223.666 439.784 223.603C438.199 223.539 437.206 224.87 437.565 226.603Z" fill="#DEDEDE"/>
<path d="M428.395 226.223C428.733 227.934 430.318 229.392 431.924 229.456C433.529 229.519 434.522 228.188 434.163 226.455C433.804 224.744 432.219 223.286 430.635 223.223C429.05 223.159 428.036 224.511 428.395 226.223Z" fill="#DEDEDE"/>
<path d="M419.205 225.885C419.543 227.596 421.106 229.054 422.691 229.118C424.297 229.181 425.311 227.829 424.973 226.117C424.635 224.406 423.071 222.948 421.487 222.885C419.881 222.821 418.867 224.152 419.205 225.885Z" fill="#DEDEDE"/>
<path d="M409.993 225.547C410.31 227.259 411.852 228.717 413.458 228.759C415.064 228.822 416.099 227.47 415.782 225.737C415.465 224.026 413.902 222.568 412.317 222.526C410.711 222.484 409.676 223.836 409.993 225.547Z" fill="#DEDEDE"/>
<path d="M400.78 225.23C401.076 226.941 402.618 228.378 404.224 228.441C405.83 228.505 406.886 227.152 406.569 225.42C406.252 223.708 404.731 222.272 403.125 222.208C401.541 222.166 400.484 223.518 400.78 225.23Z" fill="#DEDEDE"/>
<path d="M391.547 224.955C391.822 226.667 393.343 228.103 394.949 228.167C396.555 228.209 397.632 226.857 397.337 225.145C397.041 223.434 395.519 221.997 393.914 221.934C392.329 221.87 391.273 223.223 391.547 224.955Z" fill="#DEDEDE"/>
<path d="M382.314 224.681C382.568 226.392 384.089 227.829 385.695 227.871C387.3 227.913 388.378 226.561 388.124 224.85C387.85 223.138 386.328 221.701 384.744 221.659C383.138 221.617 382.039 222.969 382.314 224.681Z" fill="#DEDEDE"/>
<path d="M373.06 224.449C373.292 226.16 374.813 227.597 376.419 227.639C378.025 227.681 379.124 226.329 378.87 224.596C378.617 222.885 377.117 221.448 375.511 221.406C373.905 221.364 372.806 222.716 373.06 224.449Z" fill="#DEDEDE"/>
<path d="M363.785 224.216C364.017 225.927 365.496 227.364 367.123 227.406C368.729 227.448 369.849 226.075 369.616 224.364C369.384 222.652 367.884 221.216 366.278 221.173C364.672 221.131 363.574 222.504 363.785 224.216Z" fill="#DEDEDE"/>
<path d="M354.529 224.025C354.741 225.737 356.22 227.174 357.826 227.195C359.431 227.237 360.572 225.864 360.34 224.152C360.129 222.441 358.65 221.004 357.044 220.983C355.438 220.941 354.318 222.293 354.529 224.025Z" fill="#DEDEDE"/>
<path d="M345.234 223.835C345.424 225.547 346.882 226.984 348.509 227.005C350.114 227.026 351.276 225.674 351.065 223.941C350.854 222.23 349.396 220.793 347.79 220.772C346.206 220.751 345.044 222.124 345.234 223.835Z" fill="#DEDEDE"/>
<path d="M335.958 223.687C336.127 225.399 337.585 226.814 339.191 226.857C340.818 226.878 341.959 225.504 341.79 223.793C341.6 222.082 340.142 220.666 338.536 220.624C336.951 220.581 335.789 221.955 335.958 223.687Z" fill="#DEDEDE"/>
<path d="M326.662 223.54C326.81 225.251 328.246 226.667 329.873 226.688C331.5 226.709 332.662 225.336 332.493 223.624C332.324 221.913 330.887 220.497 329.282 220.476C327.676 220.455 326.514 221.828 326.662 223.54Z" fill="#DEDEDE"/>
<path d="M317.365 223.434C317.513 225.145 318.928 226.561 320.555 226.582C322.182 226.603 323.365 225.23 323.218 223.497C323.07 221.786 321.633 220.37 320.027 220.349C318.4 220.328 317.217 221.701 317.365 223.434Z" fill="#DEDEDE"/>
<path d="M308.047 223.349C308.174 225.061 309.59 226.476 311.195 226.497C312.822 226.518 314.027 225.124 313.879 223.413C313.752 221.701 312.336 220.286 310.731 220.264C309.125 220.243 307.92 221.617 308.047 223.349Z" fill="#DEDEDE"/>
<path d="M298.729 223.265C298.835 224.976 300.23 226.392 301.856 226.392C303.483 226.413 304.709 225.019 304.582 223.307C304.455 221.596 303.061 220.18 301.455 220.18C299.849 220.159 298.624 221.554 298.729 223.265Z" fill="#DEDEDE"/>
<path d="M289.412 223.223C289.497 224.934 290.891 226.35 292.497 226.35C294.124 226.35 295.349 224.976 295.265 223.244C295.159 221.532 293.786 220.117 292.18 220.117C290.574 220.117 289.328 221.511 289.412 223.223Z" fill="#DEDEDE"/>
<path d="M280.095 223.202C280.158 224.913 281.531 226.329 283.158 226.329C284.785 226.329 286.032 224.934 285.947 223.223C285.863 221.512 284.489 220.117 282.884 220.096C281.278 220.096 280.031 221.469 280.095 223.202Z" fill="#DEDEDE"/>
<path d="M270.777 223.202C270.819 224.913 272.192 226.308 273.819 226.308C275.446 226.308 276.714 224.913 276.65 223.202C276.587 221.491 275.235 220.096 273.608 220.096C271.981 220.075 270.713 221.47 270.777 223.202Z" fill="#DEDEDE"/>
<path d="M261.438 223.223C261.48 224.934 262.811 226.329 264.438 226.329C266.065 226.329 267.333 224.934 267.29 223.202C267.248 221.49 265.896 220.096 264.29 220.096C262.684 220.096 261.417 221.49 261.438 223.223Z" fill="#DEDEDE"/>
<path d="M252.12 223.265C252.141 224.976 253.472 226.371 255.099 226.371C256.726 226.371 258.015 224.955 257.994 223.244C257.972 221.532 256.641 220.138 255.014 220.138C253.388 220.138 252.099 221.532 252.12 223.265Z" fill="#DEDEDE"/>
<path d="M242.782 223.328C242.782 225.039 244.092 226.434 245.719 226.413C247.346 226.392 248.656 224.997 248.635 223.286C248.614 221.574 247.304 220.18 245.698 220.201C244.092 220.201 242.782 221.595 242.782 223.328Z" fill="#DEDEDE"/>
<path d="M233.443 223.413C233.422 225.124 234.732 226.518 236.359 226.497C237.986 226.476 239.296 225.082 239.317 223.349C239.317 221.638 238.028 220.243 236.401 220.264C234.795 220.286 233.464 221.68 233.443 223.413Z" fill="#DEDEDE"/>
<path d="M224.125 223.519C224.082 225.23 225.371 226.624 226.998 226.603C228.625 226.582 229.956 225.167 229.977 223.455C229.998 221.744 228.709 220.349 227.104 220.37C225.498 220.391 224.167 221.786 224.125 223.519Z" fill="#DEDEDE"/>
<path d="M214.786 223.645C214.723 225.357 216.011 226.73 217.638 226.709C219.265 226.688 220.617 225.272 220.66 223.561C220.702 221.849 219.434 220.476 217.807 220.497C216.202 220.518 214.849 221.934 214.786 223.645Z" fill="#DEDEDE"/>
<path d="M205.469 223.794C205.406 225.505 206.652 226.878 208.279 226.857C209.906 226.836 211.258 225.42 211.322 223.688C211.385 221.976 210.117 220.603 208.512 220.624C206.885 220.667 205.532 222.082 205.469 223.794Z" fill="#DEDEDE"/>
<path d="M196.13 223.962C196.046 225.673 197.292 227.047 198.919 227.026C200.546 227.005 201.919 225.568 202.004 223.856C202.088 222.145 200.842 220.772 199.215 220.793C197.588 220.835 196.215 222.251 196.13 223.962Z" fill="#DEDEDE"/>
<path d="M186.813 224.173C186.707 225.885 187.932 227.258 189.559 227.216C191.186 227.173 192.581 225.758 192.665 224.046C192.75 222.335 191.524 220.962 189.918 221.004C188.313 221.025 186.918 222.441 186.813 224.173Z" fill="#DEDEDE"/>
<path d="M177.495 224.385C177.369 226.096 178.594 227.47 180.221 227.427C181.848 227.385 183.242 225.97 183.369 224.237C183.475 222.526 182.27 221.152 180.643 221.195C179.017 221.237 177.601 222.674 177.495 224.385Z" fill="#DEDEDE"/>
<path d="M168.156 224.639C168.008 226.35 169.212 227.723 170.839 227.681C172.466 227.639 173.882 226.202 174.008 224.491C174.135 222.779 172.931 221.406 171.325 221.448C169.719 221.49 168.304 222.906 168.156 224.639Z" fill="#DEDEDE"/>
<path d="M158.858 224.892C158.71 226.603 159.893 227.977 161.499 227.913C163.126 227.871 164.563 226.434 164.69 224.723C164.838 223.011 163.654 221.638 162.049 221.701C160.443 221.744 159.006 223.159 158.858 224.892Z" fill="#DEDEDE"/>
<path d="M149.541 225.167C149.372 226.878 150.534 228.23 152.161 228.188C153.788 228.146 155.225 226.709 155.394 224.977C155.563 223.265 154.38 221.913 152.774 221.955C151.168 222.019 149.71 223.455 149.541 225.167Z" fill="#DEDEDE"/>
<path d="M93.846 227.343C93.5713 229.054 94.6488 230.406 96.2546 230.322C97.8604 230.259 99.3816 228.801 99.6563 227.068C99.931 225.357 98.8323 224.004 97.2476 224.089C95.6419 224.173 94.1206 225.61 93.846 227.343Z" fill="#DEDEDE"/>
<path d="M84.5919 227.786C84.2961 229.498 85.3736 230.829 86.9794 230.765C88.5852 230.681 90.1275 229.223 90.4022 227.512C90.6769 225.8 89.6204 224.469 88.0147 224.532C86.43 224.596 84.8877 226.054 84.5919 227.786Z" fill="#DEDEDE"/>
<path d="M66.1265 228.716C65.7885 230.428 66.8449 231.759 68.4507 231.674C70.0565 231.59 71.62 230.132 71.9369 228.399C72.2538 226.688 71.2185 225.357 69.6128 225.441C68.007 225.526 66.4435 226.984 66.1265 228.716Z" fill="#DEDEDE"/>
<path d="M56.9141 229.202C56.576 230.913 57.5902 232.245 59.196 232.16C60.8017 232.076 62.3652 230.618 62.7033 228.885C63.0414 227.174 62.0061 225.843 60.4214 225.927C58.8157 226.033 57.2521 227.491 56.9141 229.202Z" fill="#DEDEDE"/>
<path d="M630.595 230.132C631.314 231.843 633.11 233.344 634.61 233.491C636.11 233.639 636.723 232.35 636.004 230.639C635.286 228.928 633.49 227.406 631.99 227.28C630.511 227.132 629.877 228.421 630.595 230.132Z" fill="#DEDEDE"/>
<path d="M621.975 229.329C622.673 231.04 624.468 232.541 625.969 232.688C627.469 232.836 628.103 231.547 627.405 229.836C626.687 228.125 624.912 226.625 623.412 226.477C621.912 226.329 621.278 227.618 621.975 229.329Z" fill="#DEDEDE"/>
<path d="M613.311 228.547C613.988 230.259 615.784 231.759 617.284 231.907C618.784 232.033 619.439 230.766 618.742 229.033C618.044 227.322 616.269 225.822 614.769 225.674C613.29 225.547 612.635 226.836 613.311 228.547Z" fill="#DEDEDE"/>
<path d="M604.65 227.786C605.326 229.498 607.079 230.998 608.601 231.125C610.122 231.251 610.777 229.963 610.101 228.251C609.425 226.54 607.65 225.04 606.15 224.913C604.65 224.786 603.974 226.075 604.65 227.786Z" fill="#DEDEDE"/>
<path d="M595.944 227.047C596.599 228.758 598.353 230.258 599.874 230.385C601.395 230.512 602.071 229.223 601.416 227.512C600.74 225.8 598.986 224.3 597.486 224.173C595.965 224.047 595.289 225.314 595.944 227.047Z" fill="#DEDEDE"/>
<path d="M587.219 226.307C587.852 228.019 589.606 229.519 591.127 229.646C592.649 229.772 593.346 228.484 592.691 226.772C592.036 225.061 590.282 223.561 588.782 223.434C587.282 223.307 586.564 224.596 587.219 226.307Z" fill="#DEDEDE"/>
<path d="M578.449 225.61C579.083 227.321 580.816 228.822 582.337 228.948C583.858 229.075 584.577 227.786 583.943 226.054C583.309 224.342 581.555 222.842 580.055 222.715C578.555 222.589 577.815 223.877 578.449 225.61Z" fill="#DEDEDE"/>
<path d="M569.682 224.913C570.294 226.624 572.027 228.124 573.548 228.23C575.07 228.357 575.809 227.047 575.196 225.335C574.584 223.624 572.83 222.124 571.33 222.018C569.808 221.891 569.069 223.201 569.682 224.913Z" fill="#DEDEDE"/>
<path d="M560.871 224.237C561.463 225.948 563.195 227.448 564.717 227.554C566.259 227.66 566.998 226.371 566.386 224.659C565.773 222.948 564.062 221.448 562.54 221.342C561.019 221.237 560.28 222.525 560.871 224.237Z" fill="#DEDEDE"/>
<path d="M552.04 223.582C552.611 225.293 554.343 226.772 555.865 226.899C557.407 227.005 558.168 225.716 557.576 224.004C556.985 222.293 555.273 220.814 553.752 220.687C552.231 220.582 551.47 221.87 552.04 223.582Z" fill="#DEDEDE"/>
<path d="M543.209 222.948C543.779 224.659 545.47 226.138 547.012 226.244C548.554 226.35 549.336 225.061 548.766 223.328C548.195 221.617 546.484 220.138 544.963 220.032C543.42 219.927 542.638 221.237 543.209 222.948Z" fill="#DEDEDE"/>
<path d="M534.333 222.335C534.883 224.047 536.573 225.526 538.115 225.631C539.658 225.737 540.46 224.427 539.89 222.716C539.32 221.004 537.629 219.525 536.108 219.42C534.587 219.314 533.784 220.624 534.333 222.335Z" fill="#DEDEDE"/>
<path d="M525.44 221.744C525.968 223.455 527.658 224.934 529.2 225.04C530.743 225.145 531.567 223.835 531.017 222.124C530.468 220.413 528.778 218.934 527.257 218.828C525.714 218.722 524.911 220.032 525.44 221.744Z" fill="#DEDEDE"/>
<path d="M516.523 221.173C517.031 222.885 518.721 224.364 520.263 224.469C521.806 224.575 522.651 223.265 522.122 221.532C521.594 219.821 519.925 218.342 518.383 218.236C516.84 218.131 516.016 219.441 516.523 221.173Z" fill="#DEDEDE"/>
<path d="M507.585 220.603C508.092 222.314 509.74 223.793 511.304 223.878C512.867 223.983 513.712 222.652 513.205 220.941C512.698 219.229 511.029 217.75 509.487 217.666C507.944 217.581 507.099 218.891 507.585 220.603Z" fill="#DEDEDE"/>
<path d="M498.648 220.074C499.134 221.786 500.782 223.265 502.345 223.349C503.909 223.434 504.775 222.124 504.268 220.412C503.782 218.701 502.113 217.222 500.571 217.137C499.028 217.032 498.162 218.363 498.648 220.074Z" fill="#DEDEDE"/>
<path d="M489.668 219.567C490.132 221.279 491.78 222.737 493.344 222.842C494.907 222.927 495.795 221.617 495.309 219.905C494.823 218.194 493.175 216.736 491.633 216.63C490.09 216.525 489.203 217.835 489.668 219.567Z" fill="#DEDEDE"/>
<path d="M444.58 217.286C444.96 218.997 446.545 220.455 448.129 220.518C449.714 220.582 450.665 219.251 450.263 217.539C449.862 215.828 448.277 214.37 446.714 214.307C445.15 214.243 444.178 215.574 444.58 217.286Z" fill="#DEDEDE"/>
<path d="M435.495 216.884C435.854 218.596 437.438 220.053 439.023 220.117C440.608 220.18 441.58 218.849 441.199 217.138C440.819 215.426 439.234 213.968 437.671 213.905C436.107 213.842 435.135 215.173 435.495 216.884Z" fill="#DEDEDE"/>
<path d="M426.43 216.525C426.768 218.237 428.353 219.673 429.916 219.737C431.501 219.8 432.494 218.469 432.135 216.736C431.775 215.025 430.212 213.588 428.648 213.525C427.064 213.461 426.071 214.793 426.43 216.525Z" fill="#DEDEDE"/>
<path d="M417.325 216.166C417.663 217.877 419.206 219.314 420.79 219.377C422.375 219.441 423.389 218.088 423.03 216.377C422.692 214.666 421.128 213.229 419.565 213.165C418.001 213.102 416.987 214.454 417.325 216.166Z" fill="#DEDEDE"/>
<path d="M408.218 215.828C408.535 217.539 410.077 218.976 411.662 219.039C413.247 219.103 414.261 217.751 413.944 216.039C413.627 214.328 412.085 212.891 410.5 212.828C408.915 212.785 407.901 214.116 408.218 215.828Z" fill="#DEDEDE"/>
<path d="M399.09 215.532C399.386 217.243 400.908 218.68 402.513 218.743C404.098 218.786 405.133 217.455 404.837 215.743C404.521 214.032 402.999 212.595 401.415 212.553C399.83 212.468 398.795 213.821 399.09 215.532Z" fill="#DEDEDE"/>
<path d="M389.963 215.236C390.238 216.947 391.759 218.384 393.344 218.426C394.929 218.469 395.985 217.116 395.689 215.405C395.393 213.694 393.872 212.257 392.309 212.215C390.745 212.172 389.689 213.525 389.963 215.236Z" fill="#DEDEDE"/>
<path d="M380.814 214.983C381.068 216.694 382.589 218.131 384.174 218.173C385.758 218.215 386.836 216.863 386.561 215.152C386.287 213.44 384.787 212.003 383.202 211.961C381.617 211.898 380.561 213.25 380.814 214.983Z" fill="#DEDEDE"/>
<path d="M371.665 214.729C371.919 216.441 373.398 217.877 375.004 217.92C376.588 217.962 377.687 216.61 377.433 214.898C377.18 213.187 375.68 211.75 374.095 211.708C372.51 211.665 371.433 213.018 371.665 214.729Z" fill="#DEDEDE"/>
<path d="M362.496 214.518C362.729 216.229 364.208 217.645 365.792 217.687C367.398 217.729 368.497 216.356 368.243 214.644C368.011 212.933 366.532 211.517 364.947 211.475C363.384 211.433 362.285 212.785 362.496 214.518Z" fill="#DEDEDE"/>
<path d="M353.326 214.306C353.537 216.018 354.995 217.433 356.601 217.476C358.207 217.518 359.305 216.145 359.094 214.433C358.862 212.722 357.404 211.306 355.819 211.264C354.234 211.222 353.115 212.595 353.326 214.306Z" fill="#DEDEDE"/>
<path d="M344.156 214.137C344.346 215.849 345.804 217.264 347.41 217.307C349.016 217.328 350.136 215.976 349.924 214.264C349.713 212.553 348.255 211.137 346.671 211.095C345.086 211.053 343.966 212.405 344.156 214.137Z" fill="#DEDEDE"/>
<path d="M334.966 213.968C335.135 215.68 336.572 217.095 338.177 217.117C339.783 217.138 340.924 215.764 340.734 214.053C340.544 212.342 339.107 210.926 337.522 210.905C335.938 210.884 334.797 212.257 334.966 213.968Z" fill="#DEDEDE"/>
<path d="M325.775 213.842C325.923 215.553 327.359 216.969 328.965 216.99C330.571 217.011 331.733 215.638 331.564 213.926C331.395 212.215 329.958 210.799 328.373 210.778C326.768 210.757 325.606 212.13 325.775 213.842Z" fill="#DEDEDE"/>
<path d="M316.563 213.715C316.711 215.426 318.106 216.842 319.711 216.863C321.317 216.884 322.479 215.511 322.331 213.799C322.183 212.088 320.768 210.672 319.183 210.651C317.599 210.63 316.415 212.003 316.563 213.715Z" fill="#DEDEDE"/>
<path d="M307.351 213.63C307.478 215.342 308.872 216.757 310.478 216.757C312.084 216.778 313.267 215.384 313.14 213.672C313.013 211.961 311.598 210.545 310.013 210.545C308.429 210.545 307.224 211.919 307.351 213.63Z" fill="#DEDEDE"/>
<path d="M298.137 213.567C298.243 215.278 299.637 216.673 301.243 216.694C302.849 216.715 304.053 215.321 303.927 213.609C303.8 211.898 302.426 210.503 300.821 210.482C299.236 210.461 298.032 211.856 298.137 213.567Z" fill="#DEDEDE"/>
<path d="M288.927 213.525C289.012 215.236 290.385 216.631 291.991 216.652C293.596 216.652 294.822 215.278 294.716 213.567C294.611 211.856 293.237 210.461 291.653 210.44C290.047 210.419 288.843 211.792 288.927 213.525Z" fill="#DEDEDE"/>
<path d="M279.694 213.482C279.757 215.194 281.13 216.588 282.736 216.588C284.342 216.588 285.567 215.215 285.504 213.482C285.42 211.771 284.067 210.376 282.462 210.376C280.877 210.398 279.63 211.771 279.694 213.482Z" fill="#DEDEDE"/>
<path d="M270.48 213.482C270.523 215.194 271.875 216.588 273.481 216.588C275.086 216.588 276.333 215.194 276.27 213.482C276.206 211.771 274.854 210.376 273.269 210.376C271.663 210.376 270.417 211.771 270.48 213.482Z" fill="#DEDEDE"/>
<path d="M261.248 213.503C261.29 215.215 262.621 216.609 264.227 216.609C265.833 216.609 267.101 215.215 267.058 213.503C267.016 211.792 265.685 210.397 264.079 210.397C262.474 210.397 261.206 211.792 261.248 213.503Z" fill="#DEDEDE"/>
<path d="M252.015 213.546C252.036 215.258 253.346 216.652 254.952 216.631C256.558 216.631 257.846 215.237 257.804 213.504C257.783 211.793 256.452 210.398 254.867 210.419C253.283 210.44 251.994 211.835 252.015 213.546Z" fill="#DEDEDE"/>
<path d="M242.782 213.609C242.782 215.32 244.092 216.715 245.698 216.694C247.304 216.673 248.593 215.278 248.593 213.567C248.571 211.855 247.283 210.482 245.677 210.482C244.092 210.503 242.782 211.898 242.782 213.609Z" fill="#DEDEDE"/>
<path d="M233.549 213.694C233.527 215.405 234.816 216.779 236.422 216.779C238.028 216.758 239.338 215.363 239.338 213.652C239.338 211.94 238.049 210.567 236.464 210.567C234.88 210.588 233.57 211.982 233.549 213.694Z" fill="#DEDEDE"/>
<path d="M224.315 213.799C224.273 215.511 225.562 216.884 227.168 216.863C228.774 216.842 230.105 215.447 230.126 213.715C230.147 212.003 228.879 210.63 227.273 210.651C225.689 210.693 224.358 212.088 224.315 213.799Z" fill="#DEDEDE"/>
<path d="M215.082 213.947C215.018 215.658 216.286 217.032 217.892 217.011C219.498 216.989 220.829 215.574 220.871 213.862C220.913 212.151 219.646 210.778 218.061 210.799C216.476 210.82 215.145 212.214 215.082 213.947Z" fill="#DEDEDE"/>
<path d="M205.849 214.095C205.785 215.807 207.032 217.18 208.637 217.159C210.243 217.138 211.595 215.722 211.659 214.011C211.722 212.299 210.476 210.926 208.87 210.947C207.285 210.968 205.933 212.384 205.849 214.095Z" fill="#DEDEDE"/>
<path d="M196.637 214.264C196.552 215.976 197.778 217.349 199.384 217.307C200.989 217.286 202.363 215.87 202.426 214.137C202.511 212.426 201.264 211.053 199.679 211.095C198.074 211.137 196.721 212.553 196.637 214.264Z" fill="#DEDEDE"/>
<path d="M187.403 214.454C187.298 216.166 188.502 217.539 190.108 217.497C191.714 217.455 193.087 216.039 193.193 214.328C193.277 212.616 192.073 211.243 190.488 211.285C188.882 211.327 187.509 212.743 187.403 214.454Z" fill="#DEDEDE"/>
<path d="M178.169 214.687C178.043 216.398 179.247 217.75 180.853 217.729C182.458 217.687 183.853 216.272 183.959 214.56C184.064 212.849 182.881 211.496 181.275 211.518C179.691 211.539 178.296 212.975 178.169 214.687Z" fill="#DEDEDE"/>
<path d="M168.958 214.919C168.81 216.631 169.993 217.983 171.599 217.941C173.205 217.898 174.62 216.483 174.747 214.75C174.874 213.039 173.691 211.686 172.106 211.729C170.5 211.792 169.106 213.208 168.958 214.919Z" fill="#DEDEDE"/>
<path d="M159.747 215.194C159.578 216.905 160.761 218.257 162.366 218.215C163.972 218.173 165.388 216.736 165.536 215.025C165.684 213.313 164.522 211.961 162.916 212.003C161.331 212.045 159.894 213.461 159.747 215.194Z" fill="#DEDEDE"/>
<path d="M635.053 221.258C635.793 222.969 637.589 224.469 639.068 224.617C640.547 224.765 641.138 223.497 640.399 221.786C639.659 220.075 637.863 218.574 636.384 218.427C634.905 218.257 634.314 219.546 635.053 221.258Z" fill="#DEDEDE"/>
<path d="M626.539 220.434C627.257 222.145 629.053 223.645 630.532 223.793C632.011 223.941 632.624 222.652 631.884 220.941C631.145 219.23 629.37 217.729 627.891 217.581C626.412 217.455 625.82 218.722 626.539 220.434Z" fill="#DEDEDE"/>
<path d="M617.981 219.631C618.678 221.342 620.474 222.842 621.953 222.99C623.432 223.138 624.066 221.849 623.347 220.138C622.629 218.427 620.854 216.926 619.375 216.779C617.896 216.652 617.283 217.919 617.981 219.631Z" fill="#DEDEDE"/>
<path d="M609.424 218.849C610.121 220.561 611.896 222.061 613.375 222.187C614.875 222.314 615.509 221.046 614.812 219.335C614.115 217.624 612.34 216.124 610.861 215.997C609.361 215.87 608.727 217.138 609.424 218.849Z" fill="#DEDEDE"/>
<path d="M600.826 218.089C601.502 219.8 603.255 221.3 604.756 221.427C606.256 221.554 606.911 220.286 606.213 218.553C605.516 216.842 603.762 215.342 602.283 215.215C600.804 215.088 600.15 216.377 600.826 218.089Z" fill="#DEDEDE"/>
<path d="M592.205 217.349C592.86 219.06 594.613 220.56 596.114 220.687C597.614 220.814 598.29 219.525 597.614 217.814C596.938 216.102 595.184 214.602 593.705 214.475C592.226 214.349 591.55 215.637 592.205 217.349Z" fill="#DEDEDE"/>
<path d="M583.563 216.609C584.218 218.321 585.951 219.821 587.451 219.948C588.951 220.074 589.648 218.786 588.993 217.074C588.338 215.363 586.585 213.863 585.084 213.736C583.605 213.63 582.908 214.898 583.563 216.609Z" fill="#DEDEDE"/>
<path d="M574.9 215.912C575.534 217.624 577.266 219.103 578.766 219.23C580.267 219.356 580.985 218.067 580.33 216.356C579.696 214.645 577.942 213.166 576.442 213.039C574.963 212.912 574.266 214.201 574.9 215.912Z" fill="#DEDEDE"/>
<path d="M566.195 215.215C566.808 216.927 568.54 218.406 570.061 218.532C571.583 218.659 572.301 217.37 571.667 215.638C571.033 213.926 569.322 212.447 567.801 212.321C566.3 212.215 565.582 213.504 566.195 215.215Z" fill="#DEDEDE"/>
<path d="M557.491 214.539C558.082 216.25 559.815 217.729 561.336 217.856C562.857 217.962 563.597 216.673 562.984 214.961C562.371 213.25 560.66 211.771 559.139 211.644C557.618 211.539 556.878 212.827 557.491 214.539Z" fill="#DEDEDE"/>
<path d="M548.744 213.905C549.336 215.617 551.047 217.096 552.568 217.201C554.09 217.307 554.85 216.018 554.238 214.307C553.646 212.595 551.935 211.116 550.413 211.011C548.913 210.884 548.174 212.173 548.744 213.905Z" fill="#DEDEDE"/>
<path d="M539.997 213.271C540.568 214.983 542.258 216.462 543.779 216.567C545.3 216.673 546.061 215.384 545.491 213.673C544.92 211.961 543.209 210.482 541.688 210.377C540.187 210.25 539.427 211.539 539.997 213.271Z" fill="#DEDEDE"/>
<path d="M531.207 212.637C531.756 214.349 533.447 215.828 534.968 215.933C536.489 216.039 537.271 214.729 536.721 213.018C536.151 211.306 534.461 209.827 532.961 209.721C531.439 209.637 530.658 210.926 531.207 212.637Z" fill="#DEDEDE"/>
<path d="M522.417 212.046C522.945 213.757 524.636 215.236 526.157 215.342C527.699 215.447 528.481 214.137 527.932 212.426C527.382 210.715 525.713 209.236 524.192 209.151C522.671 209.045 521.868 210.334 522.417 212.046Z" fill="#DEDEDE"/>
<path d="M513.585 211.475C514.114 213.186 515.783 214.665 517.304 214.75C518.846 214.855 519.649 213.546 519.121 211.834C518.593 210.123 516.924 208.665 515.402 208.559C513.881 208.454 513.078 209.764 513.585 211.475Z" fill="#DEDEDE"/>
<path d="M504.754 210.926C505.261 212.637 506.909 214.095 508.451 214.201C509.994 214.306 510.818 212.996 510.311 211.285C509.804 209.573 508.134 208.116 506.613 208.01C505.071 207.904 504.247 209.214 504.754 210.926Z" fill="#DEDEDE"/>
<path d="M495.901 210.377C496.386 212.088 498.034 213.546 499.577 213.652C501.119 213.736 501.964 212.426 501.457 210.715C500.95 209.003 499.302 207.546 497.781 207.44C496.26 207.355 495.415 208.665 495.901 210.377Z" fill="#DEDEDE"/>
<path d="M487.027 209.869C487.492 211.581 489.14 213.038 490.683 213.123C492.225 213.208 493.091 211.898 492.605 210.186C492.119 208.475 490.492 207.017 488.95 206.932C487.408 206.848 486.541 208.158 487.027 209.869Z" fill="#DEDEDE"/>
<path d="M478.132 209.383C478.597 211.095 480.203 212.553 481.766 212.637C483.308 212.722 484.196 211.412 483.731 209.7C483.266 207.989 481.639 206.531 480.097 206.447C478.554 206.341 477.667 207.672 478.132 209.383Z" fill="#DEDEDE"/>
<path d="M469.216 208.898C469.66 210.609 471.266 212.067 472.808 212.151C474.372 212.236 475.259 210.905 474.794 209.194C474.351 207.482 472.724 206.024 471.181 205.94C469.681 205.876 468.773 207.186 469.216 208.898Z" fill="#DEDEDE"/>
<path d="M460.299 208.454C460.721 210.165 462.327 211.623 463.87 211.686C465.433 211.771 466.342 210.44 465.898 208.728C465.454 207.017 463.87 205.559 462.327 205.496C460.785 205.411 459.876 206.742 460.299 208.454Z" fill="#DEDEDE"/>
<path d="M451.341 208.01C451.743 209.721 453.328 211.179 454.891 211.243C456.455 211.327 457.384 209.996 456.962 208.285C456.539 206.573 454.954 205.136 453.412 205.052C451.87 204.967 450.94 206.298 451.341 208.01Z" fill="#DEDEDE"/>
<path d="M442.382 207.588C442.762 209.299 444.347 210.736 445.911 210.82C447.474 210.884 448.404 209.553 448.023 207.841C447.622 206.13 446.037 204.693 444.495 204.608C442.953 204.566 442.002 205.876 442.382 207.588Z" fill="#DEDEDE"/>
<path d="M415.444 206.489C415.782 208.2 417.324 209.637 418.888 209.701C420.451 209.764 421.444 208.433 421.106 206.721C420.768 205.01 419.205 203.573 417.663 203.51C416.099 203.425 415.106 204.778 415.444 206.489Z" fill="#DEDEDE"/>
<path d="M406.421 206.151C406.738 207.862 408.259 209.299 409.844 209.341C411.408 209.405 412.422 208.052 412.084 206.341C411.746 204.63 410.224 203.193 408.661 203.151C407.119 203.108 406.104 204.439 406.421 206.151Z" fill="#DEDEDE"/>
<path d="M397.4 205.855C397.696 207.567 399.217 209.004 400.78 209.046C402.344 209.109 403.379 207.757 403.062 206.046C402.745 204.334 401.224 202.897 399.682 202.855C398.118 202.792 397.104 204.144 397.4 205.855Z" fill="#DEDEDE"/>
<path d="M388.378 205.559C388.653 207.271 390.174 208.686 391.737 208.75C393.301 208.792 394.357 207.461 394.062 205.749C393.766 204.038 392.266 202.622 390.702 202.559C389.118 202.496 388.082 203.848 388.378 205.559Z" fill="#DEDEDE"/>
<path d="M379.313 205.285C379.588 206.996 381.067 208.412 382.652 208.454C384.236 208.496 385.272 207.144 384.997 205.432C384.722 203.721 383.222 202.305 381.659 202.263C380.116 202.242 379.06 203.594 379.313 205.285Z" fill="#DEDEDE"/>
<path d="M370.271 205.052C370.524 206.764 372.003 208.179 373.567 208.221C375.152 208.264 376.208 206.911 375.954 205.2C375.701 203.489 374.222 202.073 372.658 202.031C371.095 201.988 370.017 203.341 370.271 205.052Z" fill="#DEDEDE"/>
<path d="M361.207 204.82C361.439 206.531 362.897 207.947 364.482 207.989C366.066 208.031 367.144 206.679 366.89 204.968C366.658 203.256 365.179 201.841 363.615 201.799C362.052 201.756 360.974 203.13 361.207 204.82Z" fill="#DEDEDE"/>
<path d="M352.121 204.63C352.333 206.342 353.79 207.757 355.375 207.778C356.96 207.821 358.058 206.447 357.826 204.736C357.594 203.024 356.157 201.609 354.572 201.588C353.009 201.567 351.91 202.919 352.121 204.63Z" fill="#DEDEDE"/>
<path d="M343.057 204.461C343.247 206.172 344.684 207.588 346.268 207.609C347.853 207.63 348.973 206.278 348.762 204.567C348.55 202.855 347.114 201.44 345.55 201.418C343.965 201.376 342.846 202.75 343.057 204.461Z" fill="#DEDEDE"/>
<path d="M333.95 204.291C334.119 206.003 335.556 207.397 337.141 207.439C338.725 207.461 339.845 206.108 339.655 204.397C339.465 202.686 338.028 201.291 336.465 201.249C334.922 201.228 333.781 202.58 333.95 204.291Z" fill="#DEDEDE"/>
<path d="M324.865 204.165C325.034 205.876 326.429 207.271 328.014 207.292C329.598 207.313 330.739 205.94 330.57 204.228C330.401 202.517 328.986 201.122 327.422 201.101C325.858 201.08 324.696 202.454 324.865 204.165Z" fill="#DEDEDE"/>
<path d="M315.759 204.038C315.907 205.749 317.302 207.144 318.886 207.165C320.471 207.186 321.633 205.813 321.485 204.101C321.337 202.39 319.922 200.995 318.358 200.974C316.773 200.974 315.611 202.347 315.759 204.038Z" fill="#DEDEDE"/>
<path d="M306.654 203.954C306.781 205.665 308.154 207.059 309.76 207.081C311.344 207.102 312.528 205.728 312.38 204.017C312.253 202.305 310.858 200.911 309.274 200.89C307.71 200.869 306.527 202.242 306.654 203.954Z" fill="#DEDEDE"/>
<path d="M297.547 203.89C297.652 205.602 299.026 206.996 300.61 206.996C302.195 206.996 303.378 205.623 303.272 203.911C303.146 202.2 301.772 200.805 300.209 200.805C298.624 200.805 297.441 202.179 297.547 203.89Z" fill="#DEDEDE"/>
<path d="M288.419 203.848C288.504 205.559 289.856 206.954 291.462 206.954C293.046 206.954 294.251 205.58 294.145 203.869C294.039 202.158 292.687 200.763 291.103 200.763C289.539 200.742 288.335 202.137 288.419 203.848Z" fill="#DEDEDE"/>
<path d="M279.291 203.806C279.355 205.517 280.707 206.912 282.292 206.912C283.876 206.912 285.102 205.538 285.017 203.827C284.933 202.115 283.581 200.721 282.017 200.721C280.454 200.721 279.228 202.115 279.291 203.806Z" fill="#DEDEDE"/>
<path d="M270.185 203.806C270.227 205.517 271.558 206.89 273.164 206.89C274.749 206.89 275.995 205.496 275.932 203.806C275.869 202.094 274.537 200.721 272.953 200.721C271.368 200.721 270.122 202.094 270.185 203.806Z" fill="#DEDEDE"/>
<path d="M261.058 203.827C261.1 205.538 262.41 206.912 263.995 206.912C265.58 206.912 266.826 205.517 266.784 203.806C266.742 202.094 265.411 200.721 263.847 200.721C262.263 200.742 261.016 202.115 261.058 203.827Z" fill="#DEDEDE"/>
<path d="M251.908 203.869C251.929 205.58 253.218 206.954 254.823 206.954C256.408 206.954 257.676 205.559 257.655 203.848C257.634 202.137 256.324 200.763 254.739 200.763C253.175 200.784 251.908 202.158 251.908 203.869Z" fill="#DEDEDE"/>
<path d="M242.782 203.932C242.782 205.644 244.071 207.017 245.656 206.996C247.24 206.975 248.529 205.58 248.508 203.89C248.487 202.179 247.219 200.805 245.635 200.826C244.071 200.826 242.782 202.221 242.782 203.932Z" fill="#DEDEDE"/>
<path d="M233.655 204.017C233.633 205.728 234.901 207.102 236.507 207.081C238.092 207.059 239.402 205.665 239.402 203.954C239.402 202.242 238.134 200.869 236.549 200.89C234.965 200.911 233.676 202.305 233.655 204.017Z" fill="#DEDEDE"/>
<path d="M224.526 204.123C224.484 205.834 225.752 207.208 227.336 207.187C228.921 207.165 230.231 205.771 230.252 204.059C230.273 202.348 229.027 200.975 227.442 200.996C225.879 201.017 224.569 202.433 224.526 204.123Z" fill="#DEDEDE"/>
<path d="M215.399 204.271C215.335 205.982 216.582 207.355 218.188 207.313C219.772 207.292 221.104 205.876 221.146 204.186C221.188 202.475 219.941 201.101 218.357 201.144C216.772 201.144 215.441 202.559 215.399 204.271Z" fill="#DEDEDE"/>
<path d="M206.272 204.418C206.208 206.13 207.434 207.482 209.019 207.461C210.603 207.44 211.955 206.024 211.998 204.313C212.061 202.601 210.836 201.249 209.251 201.27C207.666 201.291 206.335 202.707 206.272 204.418Z" fill="#DEDEDE"/>
<path d="M197.143 204.587C197.059 206.299 198.263 207.651 199.848 207.63C201.432 207.608 202.785 206.193 202.869 204.481C202.954 202.77 201.728 201.418 200.165 201.439C198.58 201.46 197.228 202.876 197.143 204.587Z" fill="#DEDEDE"/>
<path d="M188.016 204.778C187.911 206.489 189.115 207.841 190.7 207.82C192.284 207.778 193.658 206.362 193.742 204.672C193.848 202.961 192.643 201.608 191.059 201.651C189.474 201.672 188.122 203.066 188.016 204.778Z" fill="#DEDEDE"/>
<path d="M178.889 205.01C178.762 206.721 179.945 208.074 181.53 208.031C183.114 207.989 184.488 206.574 184.615 204.862C184.72 203.151 183.537 201.799 181.973 201.841C180.389 201.883 179.015 203.299 178.889 205.01Z" fill="#DEDEDE"/>
<path d="M169.761 205.242C169.613 206.954 170.796 208.306 172.38 208.264C173.965 208.221 175.36 206.806 175.486 205.094C175.613 203.383 174.451 202.031 172.866 202.073C171.303 202.115 169.908 203.531 169.761 205.242Z" fill="#DEDEDE"/>
<path d="M630.869 211.581C631.609 213.293 633.405 214.793 634.862 214.941C636.32 215.089 636.912 213.821 636.151 212.109C635.391 210.398 633.616 208.898 632.158 208.75C630.7 208.602 630.13 209.87 630.869 211.581Z" fill="#DEDEDE"/>
<path d="M622.44 210.757C623.159 212.468 624.955 213.968 626.413 214.116C627.892 214.264 628.483 212.975 627.744 211.285C627.004 209.574 625.229 208.074 623.772 207.947C622.293 207.778 621.701 209.067 622.44 210.757Z" fill="#DEDEDE"/>
<path d="M613.967 209.975C614.685 211.687 616.46 213.187 617.918 213.314C619.397 213.461 620.01 212.173 619.27 210.461C618.552 208.75 616.777 207.25 615.319 207.123C613.861 206.975 613.249 208.264 613.967 209.975Z" fill="#DEDEDE"/>
<path d="M571.309 206.235C571.943 207.947 573.675 209.426 575.175 209.553C576.675 209.679 577.351 208.391 576.718 206.679C576.063 204.968 574.33 203.489 572.851 203.362C571.351 203.256 570.675 204.545 571.309 206.235Z" fill="#DEDEDE"/>
<path d="M562.709 205.559C563.321 207.271 565.054 208.75 566.554 208.877C568.054 209.003 568.751 207.715 568.118 206.003C567.484 204.292 565.772 202.813 564.272 202.707C562.772 202.559 562.075 203.848 562.709 205.559Z" fill="#DEDEDE"/>
<path d="M554.068 204.883C554.68 206.595 556.392 208.074 557.892 208.179C559.392 208.306 560.11 206.996 559.498 205.306C558.885 203.594 557.173 202.115 555.673 202.01C554.194 201.883 553.476 203.172 554.068 204.883Z" fill="#DEDEDE"/>
<path d="M545.427 204.228C546.018 205.94 547.708 207.419 549.23 207.524C550.73 207.63 551.469 206.341 550.878 204.63C550.265 202.918 548.575 201.439 547.075 201.334C545.575 201.228 544.835 202.517 545.427 204.228Z" fill="#DEDEDE"/>
<path d="M536.764 203.595C537.334 205.306 539.024 206.785 540.546 206.891C542.046 206.996 542.806 205.707 542.215 203.996C541.623 202.285 539.933 200.827 538.433 200.7C536.933 200.594 536.172 201.883 536.764 203.595Z" fill="#DEDEDE"/>
<path d="M528.059 202.981C528.608 204.693 530.298 206.151 531.798 206.256C533.32 206.362 534.08 205.073 533.51 203.362C532.939 201.65 531.27 200.192 529.749 200.087C528.27 199.981 527.509 201.27 528.059 202.981Z" fill="#DEDEDE"/>
<path d="M519.353 202.39C519.902 204.101 521.571 205.559 523.072 205.665C524.593 205.77 525.375 204.46 524.825 202.77C524.276 201.059 522.607 199.601 521.107 199.495C519.607 199.389 518.825 200.678 519.353 202.39Z" fill="#DEDEDE"/>
<path d="M510.627 201.82C511.156 203.531 512.804 204.989 514.325 205.095C515.846 205.2 516.649 203.89 516.1 202.179C515.571 200.468 513.902 199.01 512.402 198.904C510.902 198.799 510.099 200.108 510.627 201.82Z" fill="#DEDEDE"/>
<path d="M501.881 201.271C502.388 202.982 504.036 204.44 505.557 204.524C507.078 204.63 507.902 203.32 507.374 201.609C506.846 199.897 505.198 198.439 503.698 198.355C502.198 198.249 501.374 199.559 501.881 201.271Z" fill="#DEDEDE"/>
<path d="M493.112 200.721C493.598 202.432 495.246 203.89 496.767 203.975C498.288 204.059 499.134 202.749 498.627 201.059C498.119 199.348 496.493 197.89 494.971 197.805C493.45 197.721 492.626 199.031 493.112 200.721Z" fill="#DEDEDE"/>
<path d="M484.344 200.214C484.809 201.925 486.436 203.383 487.978 203.467C489.499 203.552 490.344 202.242 489.858 200.531C489.372 198.819 487.746 197.361 486.224 197.277C484.703 197.192 483.858 198.502 484.344 200.214Z" fill="#DEDEDE"/>
<path d="M475.534 199.707C475.999 201.418 477.604 202.855 479.147 202.94C480.689 203.024 481.534 201.714 481.07 200.003C480.605 198.291 478.978 196.855 477.457 196.77C475.935 196.707 475.09 198.017 475.534 199.707Z" fill="#DEDEDE"/>
<path d="M466.723 199.242C467.166 200.953 468.772 202.39 470.293 202.475C471.836 202.559 472.702 201.249 472.258 199.538C471.794 197.826 470.209 196.39 468.688 196.305C467.166 196.221 466.279 197.531 466.723 199.242Z" fill="#DEDEDE"/>
<path d="M457.891 198.798C458.313 200.51 459.898 201.947 461.441 202.031C462.983 202.116 463.87 200.785 463.427 199.094C462.983 197.383 461.398 195.946 459.877 195.862C458.356 195.756 457.468 197.087 457.891 198.798Z" fill="#DEDEDE"/>
<path d="M449.059 198.354C449.46 200.066 451.045 201.502 452.587 201.587C454.13 201.65 455.038 200.34 454.615 198.629C454.193 196.918 452.608 195.481 451.087 195.417C449.545 195.333 448.636 196.643 449.059 198.354Z" fill="#DEDEDE"/>
<path d="M440.185 197.953C440.565 199.664 442.15 201.101 443.692 201.165C445.235 201.228 446.164 199.918 445.763 198.207C445.362 196.495 443.798 195.058 442.256 194.995C440.713 194.911 439.805 196.242 440.185 197.953Z" fill="#DEDEDE"/>
<path d="M431.311 197.552C431.692 199.263 433.234 200.7 434.776 200.763C436.319 200.826 437.27 199.495 436.868 197.805C436.488 196.094 434.924 194.657 433.403 194.594C431.882 194.53 430.952 195.84 431.311 197.552Z" fill="#DEDEDE"/>
<path d="M422.437 197.171C422.796 198.883 424.338 200.298 425.881 200.383C427.423 200.446 428.395 199.115 428.015 197.404C427.655 195.692 426.092 194.276 424.571 194.213C423.028 194.15 422.077 195.481 422.437 197.171Z" fill="#DEDEDE"/>
<path d="M404.604 196.495C404.921 198.206 406.443 199.622 408.006 199.685C409.57 199.749 410.542 198.396 410.203 196.706C409.865 194.995 408.344 193.579 406.802 193.516C405.281 193.473 404.287 194.804 404.604 196.495Z" fill="#DEDEDE"/>
<path d="M395.688 196.2C395.984 197.911 397.505 199.327 399.048 199.39C400.611 199.432 401.604 198.101 401.287 196.39C400.971 194.678 399.47 193.263 397.928 193.22C396.386 193.157 395.393 194.488 395.688 196.2Z" fill="#DEDEDE"/>
<path d="M386.751 195.904C387.047 197.615 388.526 199.031 390.089 199.073C391.653 199.115 392.667 197.784 392.371 196.073C392.075 194.361 390.575 192.946 389.033 192.904C387.49 192.861 386.476 194.214 386.751 195.904Z" fill="#DEDEDE"/>
<path d="M377.814 195.65C378.088 197.361 379.567 198.777 381.11 198.819C382.673 198.861 383.709 197.53 383.413 195.819C383.138 194.108 381.659 192.692 380.117 192.65C378.574 192.607 377.539 193.938 377.814 195.65Z" fill="#DEDEDE"/>
<path d="M368.855 195.396C369.109 197.108 370.567 198.523 372.13 198.566C373.694 198.608 374.75 197.256 374.476 195.565C374.222 193.854 372.743 192.438 371.201 192.417C369.658 192.354 368.602 193.706 368.855 195.396Z" fill="#DEDEDE"/>
<path d="M359.897 195.185C360.129 196.896 361.587 198.291 363.151 198.333C364.714 198.375 365.771 197.023 365.538 195.312C365.285 193.6 363.848 192.206 362.284 192.164C360.721 192.121 359.664 193.474 359.897 195.185Z" fill="#DEDEDE"/>
<path d="M350.917 194.974C351.128 196.685 352.565 198.08 354.129 198.122C355.692 198.164 356.77 196.791 356.537 195.101C356.305 193.389 354.868 191.995 353.326 191.953C351.783 191.932 350.706 193.284 350.917 194.974Z" fill="#DEDEDE"/>
<path d="M341.938 194.805C342.128 196.516 343.565 197.911 345.128 197.953C346.692 197.974 347.791 196.622 347.579 194.931C347.368 193.22 345.931 191.826 344.389 191.804C342.846 191.741 341.748 193.093 341.938 194.805Z" fill="#DEDEDE"/>
<path d="M332.958 194.657C333.127 196.369 334.543 197.763 336.106 197.785C337.67 197.806 338.79 196.453 338.599 194.742C338.409 193.031 336.994 191.636 335.451 191.615C333.888 191.594 332.768 192.946 332.958 194.657Z" fill="#DEDEDE"/>
<path d="M323.958 194.509C324.127 196.221 325.521 197.615 327.085 197.636C328.649 197.657 329.789 196.305 329.599 194.594C329.43 192.882 328.015 191.488 326.472 191.467C324.93 191.446 323.789 192.819 323.958 194.509Z" fill="#DEDEDE"/>
<path d="M314.956 194.404C315.104 196.115 316.478 197.51 318.062 197.531C319.626 197.552 320.767 196.178 320.619 194.488C320.471 192.777 319.076 191.382 317.513 191.382C315.949 191.34 314.808 192.692 314.956 194.404Z" fill="#DEDEDE"/>
<path d="M305.956 194.319C306.083 196.03 307.456 197.404 309.02 197.425C310.583 197.446 311.746 196.072 311.619 194.361C311.492 192.65 310.097 191.276 308.555 191.255C306.992 191.234 305.829 192.607 305.956 194.319Z" fill="#DEDEDE"/>
<path d="M296.934 194.235C297.039 195.946 298.392 197.319 299.976 197.34C301.54 197.362 302.723 195.988 302.596 194.277C302.469 192.565 301.117 191.192 299.554 191.171C298.011 191.171 296.828 192.544 296.934 194.235Z" fill="#DEDEDE"/>
<path d="M287.911 194.192C287.996 195.903 289.348 197.276 290.912 197.298C292.475 197.298 293.679 195.924 293.574 194.234C293.468 192.523 292.137 191.149 290.574 191.149C289.031 191.128 287.827 192.501 287.911 194.192Z" fill="#DEDEDE"/>
<path d="M278.891 194.171C278.954 195.882 280.286 197.256 281.87 197.256C283.434 197.256 284.638 195.882 284.554 194.171C284.469 192.46 283.138 191.086 281.574 191.086C280.032 191.086 278.828 192.481 278.891 194.171Z" fill="#DEDEDE"/>
<path d="M269.869 194.171C269.911 195.882 271.243 197.256 272.806 197.256C274.37 197.256 275.595 195.882 275.532 194.171C275.468 192.46 274.158 191.086 272.595 191.086C271.052 191.086 269.827 192.46 269.869 194.171Z" fill="#DEDEDE"/>
<path d="M260.847 194.192C260.889 195.903 262.178 197.277 263.763 197.277C265.326 197.277 266.573 195.882 266.531 194.192C266.488 192.48 265.178 191.107 263.615 191.128C262.051 191.107 260.826 192.48 260.847 194.192Z" fill="#DEDEDE"/>
<path d="M251.824 194.234C251.845 195.945 253.134 197.319 254.697 197.298C256.261 197.298 257.528 195.903 257.486 194.192C257.465 192.48 256.176 191.107 254.612 191.128C253.049 191.149 251.802 192.523 251.824 194.234Z" fill="#DEDEDE"/>
<path d="M242.803 194.298C242.803 196.009 244.07 197.362 245.655 197.362C247.219 197.341 248.486 195.967 248.486 194.256C248.465 192.544 247.197 191.192 245.634 191.192C244.07 191.213 242.803 192.587 242.803 194.298Z" fill="#DEDEDE"/>
<path d="M233.76 194.382C233.738 196.094 235.006 197.446 236.57 197.446C238.133 197.425 239.422 196.03 239.422 194.34C239.422 192.629 238.175 191.276 236.612 191.298C235.07 191.298 233.781 192.671 233.76 194.382Z" fill="#DEDEDE"/>
<path d="M224.737 194.488C224.695 196.2 225.942 197.552 227.526 197.531C229.09 197.509 230.4 196.115 230.421 194.404C230.442 192.692 229.196 191.34 227.653 191.361C226.068 191.382 224.78 192.777 224.737 194.488Z" fill="#DEDEDE"/>
<path d="M215.695 194.615C215.631 196.326 216.878 197.678 218.441 197.657C220.005 197.636 221.315 196.242 221.357 194.53C221.399 192.819 220.174 191.467 218.61 191.488C217.068 191.509 215.758 192.925 215.695 194.615Z" fill="#DEDEDE"/>
<path d="M206.672 194.763C206.609 196.474 207.813 197.826 209.377 197.805C210.94 197.784 212.271 196.368 212.335 194.678C212.398 192.967 211.173 191.614 209.63 191.635C208.067 191.678 206.757 193.072 206.672 194.763Z" fill="#DEDEDE"/>
<path d="M197.651 194.953C197.567 196.664 198.75 198.016 200.335 197.974C201.898 197.953 203.25 196.537 203.335 194.826C203.419 193.115 202.215 191.762 200.651 191.805C199.088 191.847 197.736 193.241 197.651 194.953Z" fill="#DEDEDE"/>
<path d="M188.629 195.143C188.523 196.854 189.706 198.206 191.27 198.164C192.833 198.122 194.185 196.727 194.291 195.016C194.397 193.305 193.214 191.952 191.65 191.995C190.087 192.037 188.734 193.431 188.629 195.143Z" fill="#DEDEDE"/>
<path d="M179.608 195.354C179.481 197.066 180.643 198.397 182.228 198.376C183.791 198.333 185.164 196.918 185.291 195.227C185.397 193.516 184.235 192.185 182.671 192.206C181.108 192.248 179.734 193.664 179.608 195.354Z" fill="#DEDEDE"/>
<path d="M170.585 195.608C170.438 197.319 171.6 198.651 173.163 198.608C174.727 198.566 176.121 197.15 176.248 195.439C176.375 193.728 175.234 192.396 173.67 192.439C172.107 192.481 170.733 193.897 170.585 195.608Z" fill="#DEDEDE"/>
<path d="M567.675 196.6C568.309 198.312 570.041 199.791 571.52 199.897C572.999 200.023 573.676 198.734 573.021 197.044C572.366 195.333 570.654 193.875 569.175 193.748C567.717 193.621 567.041 194.91 567.675 196.6Z" fill="#DEDEDE"/>
<path d="M559.16 195.925C559.794 197.615 561.506 199.094 562.985 199.221C564.464 199.348 565.161 198.059 564.506 196.347C563.872 194.636 562.161 193.178 560.682 193.051C559.224 192.925 558.548 194.213 559.16 195.925Z" fill="#DEDEDE"/>
<path d="M550.624 195.249C551.237 196.96 552.927 198.418 554.427 198.545C555.906 198.65 556.625 197.383 555.991 195.671C555.378 193.96 553.667 192.502 552.188 192.375C550.73 192.269 550.011 193.537 550.624 195.249Z" fill="#DEDEDE"/>
<path d="M542.068 194.594C542.659 196.284 544.349 197.763 545.85 197.869C547.35 197.974 548.068 196.686 547.455 194.995C546.843 193.284 545.152 191.826 543.673 191.72C542.194 191.615 541.476 192.904 542.068 194.594Z" fill="#DEDEDE"/>
<path d="M533.489 193.96C534.059 195.65 535.749 197.129 537.249 197.235C538.75 197.34 539.468 196.051 538.897 194.361C538.306 192.671 536.616 191.192 535.137 191.086C533.658 190.98 532.918 192.269 533.489 193.96Z" fill="#DEDEDE"/>
<path d="M524.89 193.347C525.46 195.037 527.129 196.516 528.608 196.622C530.108 196.728 530.848 195.439 530.277 193.727C529.707 192.037 528.038 190.558 526.538 190.452C525.08 190.368 524.34 191.657 524.89 193.347Z" fill="#DEDEDE"/>
<path d="M516.291 192.755C516.84 194.446 518.488 195.925 519.988 196.009C521.488 196.115 522.249 194.826 521.7 193.115C521.15 191.424 519.481 189.945 518.002 189.861C516.502 189.776 515.741 191.065 516.291 192.755Z" fill="#DEDEDE"/>
<path d="M507.649 192.185C508.177 193.875 509.825 195.333 511.325 195.439C512.825 195.544 513.607 194.234 513.079 192.544C512.529 190.854 510.881 189.396 509.402 189.29C507.902 189.185 507.121 190.495 507.649 192.185Z" fill="#DEDEDE"/>
<path d="M498.986 191.635C499.493 193.326 501.141 194.784 502.641 194.889C504.141 194.995 504.944 193.685 504.416 191.973C503.888 190.283 502.261 188.825 500.76 188.72C499.281 188.635 498.479 189.945 498.986 191.635Z" fill="#DEDEDE"/>
<path d="M490.324 191.107C490.81 192.798 492.437 194.256 493.958 194.361C495.479 194.446 496.282 193.157 495.775 191.446C495.268 189.755 493.641 188.297 492.141 188.213C490.641 188.107 489.838 189.396 490.324 191.107Z" fill="#DEDEDE"/>
<path d="M481.639 190.579C482.125 192.27 483.731 193.727 485.252 193.812C486.773 193.896 487.597 192.586 487.111 190.896C486.625 189.206 484.998 187.748 483.498 187.664C481.977 187.579 481.153 188.889 481.639 190.579Z" fill="#DEDEDE"/>
<path d="M472.935 190.093C473.4 191.783 475.005 193.241 476.527 193.326C478.048 193.41 478.893 192.1 478.407 190.41C477.942 188.72 476.336 187.262 474.815 187.177C473.315 187.093 472.47 188.403 472.935 190.093Z" fill="#DEDEDE"/>
<path d="M464.209 189.629C464.652 191.319 466.237 192.777 467.758 192.861C469.279 192.946 470.146 191.636 469.681 189.925C469.216 188.234 467.631 186.776 466.131 186.713C464.631 186.607 463.765 187.917 464.209 189.629Z" fill="#DEDEDE"/>
<path d="M455.482 189.164C455.905 190.854 457.49 192.312 459.011 192.375C460.532 192.46 461.42 191.129 460.976 189.438C460.532 187.748 458.947 186.311 457.447 186.227C455.926 186.163 455.06 187.473 455.482 189.164Z" fill="#DEDEDE"/>
<path d="M446.735 188.741C447.136 190.432 448.721 191.868 450.242 191.953C451.763 192.038 452.651 190.706 452.228 189.016C451.806 187.326 450.242 185.889 448.721 185.805C447.221 185.72 446.312 187.051 446.735 188.741Z" fill="#DEDEDE"/>
<path d="M437.968 188.318C438.369 190.009 439.911 191.445 441.454 191.53C442.975 191.593 443.884 190.283 443.482 188.593C443.081 186.903 441.517 185.466 439.996 185.403C438.475 185.318 437.566 186.628 437.968 188.318Z" fill="#DEDEDE"/>
<path d="M429.198 187.938C429.579 189.628 431.121 191.065 432.642 191.129C434.164 191.192 435.093 189.882 434.713 188.171C434.333 186.48 432.79 185.044 431.269 184.98C429.748 184.917 428.818 186.248 429.198 187.938Z" fill="#DEDEDE"/>
<path d="M420.409 187.557C420.768 189.248 422.29 190.684 423.832 190.748C425.353 190.811 426.304 189.48 425.924 187.79C425.543 186.099 424.022 184.663 422.501 184.599C420.98 184.557 420.05 185.867 420.409 187.557Z" fill="#DEDEDE"/>
<path d="M411.598 187.219C411.936 188.91 413.457 190.347 415 190.41C416.542 190.473 417.493 189.142 417.134 187.452C416.775 185.762 415.253 184.325 413.732 184.283C412.211 184.198 411.26 185.529 411.598 187.219Z" fill="#DEDEDE"/>
<path d="M402.787 186.902C403.104 188.593 404.626 190.029 406.147 190.072C407.689 190.135 408.661 188.804 408.323 187.093C407.985 185.402 406.485 183.987 404.964 183.923C403.442 183.86 402.471 185.191 402.787 186.902Z" fill="#DEDEDE"/>
<path d="M393.956 186.586C394.252 188.276 395.752 189.692 397.294 189.755C398.837 189.797 399.83 188.466 399.492 186.776C399.175 185.086 397.675 183.67 396.153 183.607C394.653 183.564 393.66 184.896 393.956 186.586Z" fill="#DEDEDE"/>
<path d="M385.125 186.311C385.421 188.001 386.9 189.417 388.442 189.48C389.984 189.523 390.977 188.192 390.682 186.501C390.386 184.811 388.907 183.395 387.364 183.353C385.843 183.269 384.85 184.6 385.125 186.311Z" fill="#DEDEDE"/>
<path d="M376.293 186.037C376.568 187.727 378.025 189.143 379.568 189.185C381.11 189.227 382.124 187.896 381.85 186.185C381.575 184.494 380.096 183.079 378.575 183.036C377.032 183.015 376.018 184.346 376.293 186.037Z" fill="#DEDEDE"/>
<path d="M367.439 185.804C367.693 187.494 369.151 188.91 370.693 188.952C372.235 188.994 373.271 187.642 372.996 185.952C372.721 184.262 371.284 182.846 369.742 182.804C368.221 182.761 367.186 184.093 367.439 185.804Z" fill="#DEDEDE"/>
<path d="M358.565 185.572C358.797 187.262 360.234 188.678 361.776 188.72C363.319 188.762 364.375 187.41 364.122 185.72C363.868 184.03 362.431 182.614 360.91 182.593C359.389 182.53 358.332 183.882 358.565 185.572Z" fill="#DEDEDE"/>
<path d="M349.692 185.382C349.903 187.072 351.34 188.488 352.882 188.509C354.424 188.551 355.502 187.199 355.269 185.509C355.037 183.818 353.621 182.403 352.079 182.382C350.558 182.339 349.48 183.692 349.692 185.382Z" fill="#DEDEDE"/>
<path d="M340.818 185.191C341.008 186.881 342.424 188.276 343.987 188.318C345.53 188.339 346.607 186.987 346.396 185.297C346.185 183.606 344.769 182.212 343.227 182.17C341.706 182.149 340.628 183.501 340.818 185.191Z" fill="#DEDEDE"/>
<path d="M323.048 184.916C323.217 186.607 324.59 188.001 326.154 188.022C327.696 188.043 328.816 186.691 328.647 185.001C328.478 183.311 327.084 181.916 325.541 181.895C323.999 181.853 322.879 183.226 323.048 184.916Z" fill="#DEDEDE"/>
<path d="M314.133 184.79C314.281 186.48 315.654 187.875 317.196 187.896C318.739 187.917 319.88 186.565 319.711 184.853C319.563 183.163 318.189 181.769 316.647 181.748C315.126 181.748 314.006 183.1 314.133 184.79Z" fill="#DEDEDE"/>
<path d="M305.238 184.705C305.364 186.396 306.717 187.79 308.28 187.811C309.823 187.832 310.985 186.459 310.837 184.769C310.689 183.079 309.337 181.684 307.794 181.684C306.252 181.663 305.111 183.015 305.238 184.705Z" fill="#DEDEDE"/>
<path d="M296.321 184.642C296.427 186.332 297.779 187.726 299.321 187.726C300.864 187.748 302.026 186.374 301.92 184.684C301.793 182.994 300.462 181.599 298.92 181.599C297.378 181.578 296.215 182.951 296.321 184.642Z" fill="#DEDEDE"/>
<path d="M269.572 184.579C269.615 186.269 270.925 187.642 272.488 187.642C274.052 187.642 275.256 186.269 275.193 184.579C275.129 182.888 273.819 181.515 272.277 181.515C270.734 181.494 269.53 182.867 269.572 184.579Z" fill="#DEDEDE"/>
<path d="M260.656 184.579C260.698 186.269 261.987 187.642 263.529 187.642C265.093 187.642 266.297 186.269 266.255 184.558C266.213 182.867 264.924 181.494 263.381 181.494C261.839 181.515 260.614 182.888 260.656 184.579Z" fill="#DEDEDE"/>
<path d="M251.718 184.621C251.739 186.311 253.007 187.685 254.571 187.685C256.134 187.685 257.36 186.29 257.338 184.6C257.317 182.91 256.028 181.536 254.486 181.557C252.944 181.557 251.718 182.931 251.718 184.621Z" fill="#DEDEDE"/>
<path d="M242.803 184.684C242.803 186.374 244.049 187.748 245.613 187.726C247.176 187.705 248.423 186.332 248.402 184.642C248.381 182.951 247.134 181.578 245.592 181.599C244.049 181.62 242.803 182.994 242.803 184.684Z" fill="#DEDEDE"/>
<path d="M233.865 184.769C233.843 186.459 235.09 187.832 236.632 187.811C238.196 187.79 239.464 186.417 239.464 184.705C239.464 183.015 238.217 181.642 236.696 181.663C235.153 181.705 233.886 183.078 233.865 184.769Z" fill="#DEDEDE"/>
<path d="M224.949 184.896C224.907 186.586 226.133 187.96 227.696 187.938C229.26 187.917 230.527 186.523 230.548 184.833C230.569 183.142 229.344 181.79 227.802 181.79C226.259 181.811 224.992 183.185 224.949 184.896Z" fill="#DEDEDE"/>
<path d="M216.012 185.023C215.948 186.713 217.174 188.065 218.716 188.044C220.28 188.023 221.568 186.628 221.611 184.938C221.653 183.248 220.449 181.896 218.906 181.917C217.364 181.938 216.075 183.332 216.012 185.023Z" fill="#DEDEDE"/>
<path d="M207.095 185.17C207.01 186.861 208.214 188.213 209.778 188.192C211.341 188.171 212.651 186.776 212.715 185.065C212.778 183.375 211.574 182.022 210.031 182.043C208.489 182.086 207.179 183.48 207.095 185.17Z" fill="#DEDEDE"/>
<path d="M198.18 185.339C198.095 187.029 199.278 188.382 200.821 188.361C202.384 188.339 203.694 186.924 203.779 185.234C203.863 183.543 202.68 182.191 201.138 182.212C199.595 182.254 198.264 183.649 198.18 185.339Z" fill="#DEDEDE"/>
<path d="M189.241 185.53C189.135 187.22 190.297 188.572 191.861 188.53C193.424 188.488 194.756 187.093 194.861 185.403C194.967 183.713 193.784 182.36 192.241 182.403C190.699 182.445 189.368 183.839 189.241 185.53Z" fill="#DEDEDE"/>
<path d="M180.326 185.762C180.199 187.452 181.34 188.804 182.903 188.762C184.467 188.72 185.819 187.325 185.925 185.614C186.051 183.924 184.889 182.572 183.347 182.614C181.805 182.656 180.452 184.05 180.326 185.762Z" fill="#DEDEDE"/>
<path d="M572.45 187.706C573.105 189.396 574.837 190.875 576.295 191.002C577.753 191.128 578.387 189.861 577.732 188.149C577.056 186.459 575.344 184.98 573.886 184.853C572.428 184.748 571.773 186.015 572.45 187.706Z" fill="#DEDEDE"/>
<path d="M564.041 187.008C564.696 188.699 566.408 190.178 567.866 190.304C569.323 190.431 569.978 189.142 569.323 187.452C568.668 185.762 566.957 184.283 565.499 184.156C564.041 184.029 563.386 185.297 564.041 187.008Z" fill="#DEDEDE"/>
<path d="M555.611 186.311C556.245 188.001 557.935 189.48 559.414 189.586C560.893 189.713 561.548 188.424 560.914 186.733C560.281 185.043 558.569 183.564 557.111 183.459C555.653 183.353 554.977 184.621 555.611 186.311Z" fill="#DEDEDE"/>
<path d="M547.159 185.656C547.772 187.347 549.462 188.826 550.941 188.931C552.42 189.037 553.096 187.769 552.462 186.079C551.828 184.389 550.138 182.91 548.68 182.804C547.243 182.677 546.546 183.945 547.159 185.656Z" fill="#DEDEDE"/>
<path d="M538.687 185.001C539.278 186.691 540.969 188.17 542.448 188.276C543.926 188.381 544.624 187.114 544.011 185.402C543.398 183.712 541.729 182.254 540.25 182.127C538.792 182.022 538.095 183.311 538.687 185.001Z" fill="#DEDEDE"/>
<path d="M530.214 184.367C530.805 186.058 532.474 187.515 533.953 187.621C535.432 187.727 536.151 186.438 535.559 184.748C534.967 183.057 533.298 181.599 531.819 181.494C530.34 181.388 529.622 182.677 530.214 184.367Z" fill="#DEDEDE"/>
<path d="M521.699 183.755C522.269 185.445 523.917 186.903 525.396 187.009C526.875 187.114 527.615 185.825 527.023 184.135C526.452 182.445 524.783 180.987 523.304 180.881C521.868 180.776 521.128 182.065 521.699 183.755Z" fill="#DEDEDE"/>
<path d="M513.184 183.163C513.734 184.854 515.382 186.311 516.861 186.417C518.34 186.523 519.1 185.234 518.53 183.544C517.959 181.853 516.311 180.395 514.853 180.29C513.374 180.184 512.635 181.473 513.184 183.163Z" fill="#DEDEDE"/>
<path d="M504.649 182.593C505.177 184.283 506.825 185.741 508.304 185.846C509.783 185.952 510.565 184.642 510.015 182.952C509.466 181.262 507.839 179.804 506.36 179.719C504.881 179.614 504.099 180.902 504.649 182.593Z" fill="#DEDEDE"/>
<path d="M496.07 182.043C496.578 183.734 498.204 185.191 499.705 185.276C501.205 185.36 501.986 184.072 501.458 182.381C500.93 180.691 499.303 179.233 497.824 179.149C496.345 179.064 495.563 180.353 496.07 182.043Z" fill="#DEDEDE"/>
<path d="M487.513 181.515C488.021 183.206 489.626 184.663 491.126 184.748C492.627 184.832 493.408 183.544 492.901 181.853C492.394 180.163 490.767 178.726 489.288 178.621C487.809 178.536 487.006 179.825 487.513 181.515Z" fill="#DEDEDE"/>
<path d="M478.913 181.008C479.399 182.698 481.005 184.135 482.505 184.241C484.005 184.325 484.808 183.036 484.322 181.346C483.836 179.656 482.23 178.219 480.73 178.134C479.251 178.008 478.427 179.318 478.913 181.008Z" fill="#DEDEDE"/>
<path d="M470.315 180.522C470.779 182.212 472.364 183.649 473.864 183.734C475.364 183.818 476.188 182.508 475.724 180.818C475.238 179.128 473.653 177.691 472.174 177.606C470.674 177.522 469.85 178.832 470.315 180.522Z" fill="#DEDEDE"/>
<path d="M461.694 180.036C462.138 181.726 463.722 183.163 465.222 183.248C466.723 183.332 467.568 182.022 467.103 180.332C466.638 178.642 465.053 177.205 463.574 177.12C462.074 177.057 461.229 178.346 461.694 180.036Z" fill="#DEDEDE"/>
<path d="M453.053 179.593C453.475 181.283 455.06 182.72 456.56 182.804C458.06 182.889 458.926 181.579 458.483 179.889C458.039 178.198 456.476 176.761 454.975 176.698C453.475 176.592 452.609 177.902 453.053 179.593Z" fill="#DEDEDE"/>
<path d="M444.39 179.17C444.813 180.86 446.355 182.297 447.876 182.36C449.376 182.424 450.264 181.114 449.841 179.423C449.419 177.733 447.855 176.296 446.355 176.233C444.855 176.17 443.989 177.48 444.39 179.17Z" fill="#DEDEDE"/>
<path d="M435.727 178.747C436.128 180.438 437.671 181.874 439.171 181.938C440.671 182.001 441.58 180.691 441.157 179.001C440.756 177.311 439.192 175.895 437.713 175.811C436.234 175.747 435.326 177.057 435.727 178.747Z" fill="#DEDEDE"/>
<path d="M427.065 178.367C427.445 180.057 428.967 181.473 430.488 181.557C432.009 181.621 432.918 180.311 432.516 178.62C432.115 176.93 430.594 175.515 429.093 175.451C427.593 175.367 426.685 176.677 427.065 178.367Z" fill="#DEDEDE"/>
<path d="M418.36 178.008C418.719 179.698 420.24 181.113 421.762 181.177C423.283 181.24 424.191 179.93 423.832 178.24C423.452 176.55 421.931 175.134 420.43 175.071C418.93 174.986 418.001 176.317 418.36 178.008Z" fill="#DEDEDE"/>
<path d="M409.654 177.648C409.992 179.339 411.514 180.754 413.014 180.818C414.535 180.881 415.465 179.55 415.105 177.86C414.746 176.169 413.246 174.754 411.746 174.69C410.267 174.648 409.316 175.958 409.654 177.648Z" fill="#DEDEDE"/>
<path d="M400.95 177.332C401.267 179.022 402.767 180.437 404.288 180.501C405.81 180.564 406.76 179.233 406.422 177.543C406.084 175.853 404.584 174.437 403.084 174.395C401.584 174.31 400.633 175.641 400.95 177.332Z" fill="#DEDEDE"/>
<path d="M392.224 177.014C392.541 178.705 394.02 180.12 395.541 180.163C397.062 180.205 398.034 178.895 397.717 177.205C397.4 175.514 395.921 174.099 394.4 174.056C392.9 174.014 391.928 175.324 392.224 177.014Z" fill="#DEDEDE"/>
<path d="M383.498 176.74C383.794 178.43 385.252 179.846 386.773 179.888C388.295 179.931 389.288 178.599 388.971 176.909C388.675 175.219 387.196 173.824 385.696 173.761C384.196 173.719 383.203 175.05 383.498 176.74Z" fill="#DEDEDE"/>
<path d="M374.75 176.465C375.025 178.155 376.483 179.55 378.004 179.613C379.526 179.655 380.54 178.324 380.244 176.634C379.969 174.944 378.511 173.549 376.99 173.507C375.49 173.465 374.476 174.796 374.75 176.465Z" fill="#DEDEDE"/>
<path d="M366.003 176.233C366.256 177.924 367.693 179.318 369.214 179.36C370.735 179.403 371.75 178.071 371.496 176.381C371.221 174.691 369.785 173.296 368.285 173.254C366.763 173.212 365.749 174.543 366.003 176.233Z" fill="#DEDEDE"/>
<path d="M357.235 176.021C357.467 177.712 358.904 179.106 360.425 179.148C361.946 179.191 362.982 177.838 362.728 176.169C362.475 174.479 361.059 173.084 359.538 173.042C358.038 173 357.002 174.331 357.235 176.021Z" fill="#DEDEDE"/>
<path d="M251.635 175.07C251.656 176.761 252.902 178.113 254.445 178.113C255.987 178.113 257.212 176.74 257.17 175.049C257.149 173.359 255.881 172.007 254.36 172.007C252.839 172.007 251.613 173.38 251.635 175.07Z" fill="#DEDEDE"/>
<path d="M242.803 175.134C242.803 176.825 244.049 178.177 245.571 178.177C247.113 178.156 248.338 176.782 248.338 175.092C248.317 173.402 247.092 172.05 245.571 172.071C244.049 172.071 242.824 173.444 242.803 175.134Z" fill="#DEDEDE"/>
<path d="M233.992 175.219C233.971 176.909 235.197 178.261 236.739 178.24C238.282 178.219 239.528 176.845 239.528 175.155C239.528 173.465 238.303 172.113 236.781 172.134C235.26 172.155 234.014 173.528 233.992 175.219Z" fill="#DEDEDE"/>
<path d="M225.16 175.325C225.117 177.015 226.343 178.367 227.864 178.346C229.407 178.325 230.674 176.951 230.695 175.261C230.717 173.571 229.512 172.219 227.991 172.24C226.47 172.261 225.202 173.634 225.16 175.325Z" fill="#DEDEDE"/>
<path d="M216.349 175.451C216.286 177.141 217.49 178.494 219.032 178.473C220.575 178.451 221.842 177.057 221.906 175.367C221.948 173.676 220.744 172.324 219.223 172.366C217.68 172.388 216.391 173.782 216.349 175.451Z" fill="#DEDEDE"/>
<path d="M207.518 175.621C207.433 177.311 208.617 178.663 210.159 178.621C211.701 178.6 212.99 177.205 213.054 175.515C213.117 173.825 211.934 172.494 210.413 172.515C208.891 172.536 207.602 173.93 207.518 175.621Z" fill="#DEDEDE"/>
<path d="M198.707 175.789C198.623 177.479 199.785 178.81 201.327 178.789C202.87 178.768 204.18 177.374 204.264 175.683C204.349 173.993 203.187 172.662 201.644 172.683C200.102 172.704 198.792 174.099 198.707 175.789Z" fill="#DEDEDE"/>
<path d="M189.875 175.979C189.77 177.67 190.911 179.001 192.453 178.98C193.995 178.937 195.327 177.543 195.411 175.853C195.517 174.162 194.355 172.831 192.833 172.852C191.312 172.895 190.002 174.289 189.875 175.979Z" fill="#DEDEDE"/>
<path d="M568.688 178.135C569.343 179.825 571.055 181.304 572.512 181.41C573.949 181.536 574.583 180.269 573.907 178.578C573.231 176.888 571.519 175.43 570.083 175.303C568.646 175.198 568.012 176.444 568.688 178.135Z" fill="#DEDEDE"/>
<path d="M560.364 177.437C561.019 179.127 562.709 180.585 564.167 180.712C565.625 180.839 566.259 179.571 565.604 177.881C564.949 176.191 563.237 174.733 561.8 174.606C560.364 174.479 559.73 175.747 560.364 177.437Z" fill="#DEDEDE"/>
<path d="M552.039 176.761C552.673 178.451 554.363 179.909 555.821 180.036C557.279 180.163 557.934 178.874 557.279 177.184C556.624 175.493 554.933 174.036 553.497 173.909C552.06 173.803 551.405 175.071 552.039 176.761Z" fill="#DEDEDE"/>
<path d="M543.673 176.085C544.286 177.776 545.976 179.233 547.434 179.339C548.892 179.445 549.568 178.177 548.934 176.487C548.3 174.796 546.631 173.339 545.173 173.233C543.715 173.127 543.06 174.395 543.673 176.085Z" fill="#DEDEDE"/>
<path d="M535.306 175.451C535.919 177.141 537.588 178.599 539.046 178.705C540.503 178.81 541.18 177.543 540.567 175.852C539.954 174.162 538.285 172.704 536.827 172.599C535.39 172.493 534.693 173.761 535.306 175.451Z" fill="#DEDEDE"/>
<path d="M526.897 174.817C527.488 176.507 529.136 177.965 530.615 178.071C532.073 178.176 532.771 176.909 532.179 175.218C531.587 173.528 529.918 172.07 528.46 171.965C527.024 171.859 526.326 173.127 526.897 174.817Z" fill="#DEDEDE"/>
<path d="M518.487 174.205C519.058 175.895 520.706 177.353 522.164 177.437C523.622 177.543 524.34 176.254 523.769 174.564C523.178 172.874 521.53 171.437 520.093 171.331C518.635 171.247 517.917 172.536 518.487 174.205Z" fill="#DEDEDE"/>
<path d="M510.057 173.613C510.607 175.304 512.255 176.74 513.713 176.846C515.192 176.952 515.91 175.663 515.339 173.972C514.769 172.282 513.142 170.845 511.684 170.74C510.247 170.655 509.508 171.944 510.057 173.613Z" fill="#DEDEDE"/>
<path d="M501.606 173.064C502.134 174.754 503.761 176.191 505.24 176.297C506.719 176.402 507.459 175.113 506.909 173.423C506.36 171.733 504.733 170.296 503.275 170.19C501.817 170.085 501.078 171.374 501.606 173.064Z" fill="#DEDEDE"/>
<path d="M493.155 172.514C493.683 174.204 495.289 175.641 496.768 175.726C498.247 175.81 499.008 174.521 498.479 172.852C497.951 171.162 496.324 169.725 494.866 169.641C493.387 169.535 492.627 170.824 493.155 172.514Z" fill="#DEDEDE"/>
<path d="M484.661 171.986C485.168 173.676 486.773 175.113 488.252 175.198C489.731 175.282 490.513 173.993 489.985 172.303C489.478 170.613 487.872 169.176 486.393 169.092C484.935 169.007 484.175 170.296 484.661 171.986Z" fill="#DEDEDE"/>
<path d="M476.167 171.458C476.653 173.148 478.238 174.585 479.717 174.669C481.196 174.754 481.999 173.465 481.492 171.775C480.985 170.084 479.4 168.648 477.942 168.563C476.484 168.5 475.681 169.788 476.167 171.458Z" fill="#DEDEDE"/>
<path d="M467.652 170.972C468.117 172.662 469.702 174.078 471.181 174.162C472.66 174.247 473.484 172.958 472.998 171.268C472.512 169.577 470.927 168.162 469.469 168.077C468.012 167.993 467.187 169.303 467.652 170.972Z" fill="#DEDEDE"/>
<path d="M459.137 170.507C459.581 172.197 461.165 173.613 462.644 173.698C464.123 173.782 464.947 172.472 464.504 170.803C464.039 169.113 462.475 167.697 460.996 167.613C459.517 167.528 458.693 168.838 459.137 170.507Z" fill="#DEDEDE"/>
<path d="M450.602 170.063C451.024 171.754 452.588 173.169 454.088 173.254C455.567 173.338 456.412 172.028 455.968 170.338C455.524 168.648 453.961 167.232 452.482 167.148C451.003 167.084 450.158 168.373 450.602 170.063Z" fill="#DEDEDE"/>
<path d="M442.044 169.641C442.467 171.331 444.009 172.747 445.488 172.81C446.988 172.874 447.833 171.585 447.411 169.895C446.988 168.204 445.446 166.789 443.967 166.725C442.488 166.641 441.622 167.951 442.044 169.641Z" fill="#DEDEDE"/>
<path d="M433.488 169.218C433.889 170.909 435.41 172.324 436.91 172.388C438.411 172.451 439.277 171.141 438.875 169.472C438.474 167.782 436.932 166.366 435.453 166.303C433.952 166.239 433.086 167.549 433.488 169.218Z" fill="#DEDEDE"/>
<path d="M424.91 168.838C425.291 170.528 426.812 171.944 428.312 172.007C429.812 172.071 430.699 170.761 430.298 169.091C429.897 167.401 428.375 166.007 426.896 165.922C425.417 165.859 424.53 167.169 424.91 168.838Z" fill="#DEDEDE"/>
<path d="M416.31 168.479C416.67 170.148 418.191 171.563 419.67 171.627C421.17 171.69 422.078 170.38 421.698 168.69C421.318 167 419.818 165.605 418.339 165.542C416.86 165.478 415.951 166.788 416.31 168.479Z" fill="#DEDEDE"/>
<path d="M407.711 168.119C408.049 169.788 409.549 171.204 411.049 171.267C412.55 171.331 413.479 170.021 413.12 168.331C412.761 166.661 411.261 165.246 409.782 165.182C408.282 165.14 407.373 166.45 407.711 168.119Z" fill="#DEDEDE"/>
<path d="M399.09 167.802C399.407 169.471 400.907 170.887 402.407 170.95C403.907 171.014 404.858 169.683 404.499 168.014C404.161 166.323 402.682 164.929 401.181 164.865C399.702 164.802 398.773 166.133 399.09 167.802Z" fill="#DEDEDE"/>
<path d="M390.47 167.507C390.787 169.176 392.245 170.591 393.745 170.634C395.245 170.676 396.196 169.366 395.879 167.676C395.562 166.006 394.083 164.591 392.604 164.549C391.125 164.506 390.174 165.816 390.47 167.507Z" fill="#DEDEDE"/>
<path d="M381.85 167.211C382.146 168.88 383.604 170.295 385.104 170.338C386.604 170.38 387.576 169.07 387.28 167.38C386.985 165.711 385.527 164.295 384.027 164.253C382.526 164.232 381.555 165.542 381.85 167.211Z" fill="#DEDEDE"/>
<path d="M373.208 166.957C373.482 168.626 374.919 170.042 376.419 170.084C377.919 170.126 378.912 168.795 378.617 167.126C378.321 165.457 376.884 164.062 375.405 163.999C373.926 163.957 372.933 165.288 373.208 166.957Z" fill="#DEDEDE"/>
<path d="M364.545 166.725C364.798 168.394 366.235 169.789 367.735 169.831C369.235 169.873 370.249 168.542 369.975 166.873C369.7 165.204 368.284 163.809 366.784 163.767C365.305 163.725 364.291 165.035 364.545 166.725Z" fill="#DEDEDE"/>
<path d="M355.903 166.493C356.135 168.162 357.551 169.556 359.072 169.599C360.572 169.641 361.608 168.31 361.354 166.62C361.101 164.95 359.685 163.556 358.185 163.514C356.685 163.493 355.649 164.824 355.903 166.493Z" fill="#DEDEDE"/>
<path d="M347.22 166.302C347.431 167.972 348.847 169.366 350.347 169.408C351.847 169.451 352.882 168.098 352.671 166.429C352.439 164.76 351.044 163.366 349.544 163.323C348.044 163.302 347.008 164.633 347.22 166.302Z" fill="#DEDEDE"/>
<path d="M329.873 165.986C330.063 167.655 331.437 169.049 332.937 169.07C334.458 169.091 335.515 167.76 335.325 166.07C335.134 164.401 333.761 163.006 332.261 162.985C330.761 162.964 329.683 164.295 329.873 165.986Z" fill="#DEDEDE"/>
<path d="M312.505 165.732C312.653 167.401 314.005 168.796 315.505 168.796C317.027 168.817 318.125 167.464 317.956 165.795C317.808 164.126 316.456 162.753 314.956 162.732C313.456 162.711 312.357 164.063 312.505 165.732Z" fill="#DEDEDE"/>
<path d="M251.55 165.563C251.571 167.232 252.817 168.584 254.317 168.584C255.839 168.584 257.043 167.211 257.022 165.542C257.001 163.873 255.754 162.521 254.254 162.521C252.733 162.521 251.528 163.894 251.55 165.563Z" fill="#DEDEDE"/>
<path d="M242.824 165.626C242.824 167.295 244.049 168.648 245.57 168.648C247.092 168.626 248.317 167.274 248.296 165.584C248.296 163.915 247.049 162.562 245.549 162.562C244.049 162.584 242.824 163.957 242.824 165.626Z" fill="#DEDEDE"/>
<path d="M234.097 165.71C234.076 167.38 235.281 168.732 236.802 168.711C238.323 168.689 239.549 167.316 239.57 165.647C239.57 163.978 238.365 162.626 236.865 162.647C235.365 162.668 234.119 164.041 234.097 165.71Z" fill="#DEDEDE"/>
<path d="M225.392 165.816C225.35 167.485 226.554 168.838 228.075 168.817C229.597 168.795 230.843 167.422 230.864 165.753C230.886 164.084 229.702 162.732 228.181 162.753C226.681 162.774 225.434 164.147 225.392 165.816Z" fill="#DEDEDE"/>
<path d="M216.667 165.943C216.604 167.613 217.787 168.965 219.308 168.944C220.829 168.923 222.097 167.549 222.139 165.859C222.182 164.19 220.998 162.838 219.498 162.859C217.998 162.901 216.73 164.274 216.667 165.943Z" fill="#DEDEDE"/>
<path d="M573.125 169.345C573.801 171.035 575.513 172.493 576.95 172.62C578.386 172.747 578.978 171.479 578.281 169.81C577.583 168.119 575.872 166.662 574.456 166.535C573.02 166.408 572.428 167.655 573.125 169.345Z" fill="#DEDEDE"/>
<path d="M564.906 168.627C565.582 170.317 567.272 171.775 568.709 171.902C570.146 172.029 570.737 170.761 570.061 169.092C569.385 167.401 567.674 165.944 566.258 165.817C564.843 165.69 564.23 166.937 564.906 168.627Z" fill="#DEDEDE"/>
<path d="M556.687 167.929C557.342 169.598 559.033 171.077 560.469 171.183C561.906 171.31 562.519 170.042 561.864 168.352C561.209 166.661 559.497 165.203 558.082 165.098C556.645 164.971 556.032 166.239 556.687 167.929Z" fill="#DEDEDE"/>
<path d="M548.427 167.232C549.061 168.902 550.751 170.359 552.188 170.486C553.624 170.613 554.258 169.345 553.624 167.655C552.969 165.986 551.3 164.528 549.863 164.401C548.427 164.296 547.793 165.563 548.427 167.232Z" fill="#DEDEDE"/>
<path d="M540.165 166.577C540.778 168.246 542.468 169.704 543.905 169.831C545.341 169.937 545.996 168.669 545.363 167C544.729 165.309 543.06 163.873 541.623 163.746C540.207 163.64 539.552 164.908 540.165 166.577Z" fill="#DEDEDE"/>
<path d="M531.883 165.943C532.496 167.613 534.144 169.071 535.602 169.176C537.039 169.282 537.715 168.014 537.102 166.345C536.489 164.676 534.82 163.218 533.383 163.112C531.947 162.985 531.271 164.253 531.883 165.943Z" fill="#DEDEDE"/>
<path d="M523.58 165.309C524.171 166.978 525.819 168.436 527.256 168.542C528.693 168.647 529.39 167.38 528.777 165.689C528.186 164.02 526.517 162.562 525.101 162.457C523.685 162.372 522.988 163.64 523.58 165.309Z" fill="#DEDEDE"/>
<path d="M515.255 164.717C515.825 166.387 517.473 167.844 518.91 167.95C520.368 168.056 521.065 166.788 520.473 165.098C519.882 163.429 518.255 161.971 516.818 161.865C515.381 161.759 514.684 163.027 515.255 164.717Z" fill="#DEDEDE"/>
<path d="M506.93 164.126C507.479 165.796 509.106 167.253 510.564 167.338C512.022 167.444 512.74 166.155 512.17 164.486C511.599 162.817 509.972 161.38 508.535 161.274C507.078 161.168 506.359 162.457 506.93 164.126Z" fill="#DEDEDE"/>
<path d="M498.563 163.556C499.092 165.225 500.719 166.662 502.176 166.767C503.634 166.873 504.374 165.584 503.803 163.915C503.254 162.246 501.627 160.809 500.19 160.704C498.754 160.598 498.035 161.887 498.563 163.556Z" fill="#DEDEDE"/>
<path d="M490.196 163.007C490.725 164.676 492.33 166.112 493.788 166.218C495.246 166.303 495.986 165.035 495.457 163.345C494.929 161.675 493.323 160.239 491.866 160.133C490.429 160.049 489.668 161.337 490.196 163.007Z" fill="#DEDEDE"/>
<path d="M481.809 162.478C482.316 164.147 483.901 165.584 485.359 165.669C486.817 165.753 487.577 164.464 487.07 162.795C486.542 161.126 484.957 159.689 483.5 159.605C482.084 159.541 481.302 160.809 481.809 162.478Z" fill="#DEDEDE"/>
<path d="M473.42 161.971C473.906 163.64 475.491 165.077 476.949 165.162C478.407 165.246 479.188 163.957 478.681 162.288C478.174 160.619 476.59 159.182 475.153 159.098C473.716 159.013 472.934 160.302 473.42 161.971Z" fill="#DEDEDE"/>
<path d="M465.011 161.486C465.475 163.155 467.06 164.592 468.518 164.676C469.976 164.761 470.779 163.472 470.293 161.803C469.807 160.133 468.243 158.697 466.785 158.633C465.328 158.528 464.525 159.816 465.011 161.486Z" fill="#DEDEDE"/>
<path d="M456.581 161.02C457.024 162.69 458.588 164.126 460.067 164.19C461.546 164.274 462.349 162.985 461.884 161.295C461.419 159.626 459.855 158.21 458.398 158.126C456.94 158.062 456.116 159.351 456.581 161.02Z" fill="#DEDEDE"/>
<path d="M448.129 160.577C448.573 162.246 450.115 163.662 451.573 163.746C453.052 163.831 453.876 162.521 453.432 160.851C452.989 159.182 451.446 157.767 449.988 157.682C448.531 157.619 447.707 158.908 448.129 160.577Z" fill="#DEDEDE"/>
<path d="M439.678 160.154C440.101 161.823 441.622 163.239 443.101 163.323C444.58 163.386 445.425 162.098 444.982 160.428C444.559 158.759 443.017 157.344 441.559 157.28C440.101 157.196 439.256 158.485 439.678 160.154Z" fill="#DEDEDE"/>
<path d="M431.206 159.753C431.608 161.422 433.129 162.837 434.608 162.901C436.087 162.964 436.953 161.675 436.531 160.006C436.108 158.337 434.587 156.921 433.129 156.858C431.671 156.795 430.805 158.083 431.206 159.753Z" fill="#DEDEDE"/>
<path d="M422.733 159.372C423.113 161.041 424.634 162.457 426.092 162.52C427.571 162.584 428.437 161.274 428.057 159.605C427.656 157.936 426.155 156.52 424.698 156.457C423.24 156.393 422.352 157.703 422.733 159.372Z" fill="#DEDEDE"/>
<path d="M414.239 159.013C414.598 160.683 416.098 162.098 417.577 162.162C419.056 162.225 419.944 160.915 419.563 159.246C419.183 157.577 417.683 156.182 416.225 156.119C414.767 156.034 413.88 157.344 414.239 159.013Z" fill="#DEDEDE"/>
<path d="M405.745 158.654C406.083 160.323 407.584 161.717 409.063 161.781C410.542 161.844 411.45 160.534 411.091 158.865C410.732 157.196 409.253 155.801 407.774 155.738C406.316 155.696 405.407 156.985 405.745 158.654Z" fill="#DEDEDE"/>
<path d="M397.231 158.337C397.569 160.006 399.027 161.401 400.506 161.464C401.985 161.527 402.915 160.217 402.576 158.548C402.238 156.879 400.759 155.485 399.302 155.421C397.823 155.358 396.914 156.668 397.231 158.337Z" fill="#DEDEDE"/>
<path d="M388.717 158.041C389.034 159.711 390.492 161.105 391.971 161.169C393.45 161.211 394.4 159.901 394.062 158.232C393.745 156.562 392.287 155.168 390.808 155.126C389.351 155.062 388.4 156.372 388.717 158.041Z" fill="#DEDEDE"/>
<path d="M380.181 157.766C380.476 159.436 381.913 160.83 383.392 160.872C384.871 160.915 385.843 159.605 385.526 157.936C385.23 156.266 383.772 154.872 382.315 154.83C380.857 154.787 379.885 156.097 380.181 157.766Z" fill="#DEDEDE"/>
<path d="M371.645 157.492C371.92 159.161 373.356 160.555 374.835 160.597C376.314 160.64 377.286 159.33 377.012 157.661C376.716 155.991 375.3 154.597 373.821 154.555C372.342 154.512 371.37 155.822 371.645 157.492Z" fill="#DEDEDE"/>
<path d="M363.108 157.26C363.362 158.929 364.777 160.323 366.277 160.366C367.756 160.408 368.75 159.077 368.475 157.408C368.2 155.738 366.785 154.365 365.306 154.323C363.827 154.281 362.834 155.59 363.108 157.26Z" fill="#DEDEDE"/>
<path d="M354.552 157.048C354.784 158.717 356.2 160.09 357.679 160.133C359.179 160.175 360.172 158.844 359.918 157.175C359.665 155.505 358.27 154.132 356.791 154.09C355.312 154.069 354.298 155.379 354.552 157.048Z" fill="#DEDEDE"/>
<path d="M345.973 156.858C346.184 158.527 347.578 159.9 349.079 159.943C350.579 159.985 351.593 158.654 351.36 156.985C351.128 155.316 349.734 153.942 348.255 153.9C346.776 153.858 345.761 155.189 345.973 156.858Z" fill="#DEDEDE"/>
<path d="M337.417 156.668C337.628 158.337 338.98 159.71 340.48 159.731C341.98 159.752 343.016 158.421 342.804 156.773C342.593 155.104 341.22 153.731 339.741 153.71C338.241 153.689 337.205 155.02 337.417 156.668Z" fill="#DEDEDE"/>
<path d="M328.838 156.52C329.028 158.189 330.38 159.563 331.881 159.584C333.381 159.605 334.437 158.274 334.226 156.605C334.036 154.936 332.662 153.562 331.183 153.541C329.704 153.52 328.648 154.851 328.838 156.52Z" fill="#DEDEDE"/>
<path d="M303.082 156.203C303.209 157.873 304.519 159.225 306.019 159.246C307.519 159.267 308.618 157.915 308.47 156.246C308.322 154.576 307.012 153.224 305.533 153.203C304.054 153.203 302.955 154.534 303.082 156.203Z" fill="#DEDEDE"/>
<path d="M294.483 156.119C294.589 157.788 295.899 159.14 297.399 159.161C298.899 159.161 300.019 157.83 299.892 156.161C299.765 154.492 298.476 153.14 296.976 153.118C295.497 153.118 294.377 154.471 294.483 156.119Z" fill="#DEDEDE"/>
<path d="M260.065 156.076C260.107 157.745 261.332 159.097 262.832 159.097C264.333 159.097 265.516 157.745 265.452 156.076C265.41 154.407 264.164 153.055 262.685 153.076C261.206 153.055 260.022 154.407 260.065 156.076Z" fill="#DEDEDE"/>
<path d="M208.385 156.647C208.3 158.316 209.462 159.647 210.962 159.626C212.463 159.605 213.73 158.232 213.794 156.562C213.857 154.893 212.716 153.562 211.216 153.604C209.737 153.604 208.469 154.978 208.385 156.647Z" fill="#DEDEDE"/>
<path d="M199.785 156.816C199.679 158.485 200.82 159.816 202.321 159.774C203.821 159.753 205.109 158.358 205.194 156.689C205.279 155.02 204.138 153.689 202.659 153.731C201.158 153.773 199.87 155.147 199.785 156.816Z" fill="#DEDEDE"/>
<path d="M191.165 157.006C191.039 158.675 192.18 160.006 193.68 159.964C195.18 159.922 196.469 158.548 196.574 156.879C196.68 155.21 195.56 153.9 194.06 153.921C192.581 153.963 191.292 155.358 191.165 157.006Z" fill="#DEDEDE"/>
<path d="M182.565 157.238C182.438 158.907 183.537 160.217 185.037 160.196C186.537 160.154 187.847 158.78 187.974 157.111C188.101 155.442 186.981 154.132 185.502 154.153C184.002 154.174 182.692 155.569 182.565 157.238Z" fill="#DEDEDE"/>
<path d="M569.239 159.858C569.936 161.527 571.626 162.985 573.042 163.112C574.457 163.239 575.028 161.992 574.331 160.302C573.633 158.633 571.922 157.175 570.527 157.048C569.133 156.942 568.541 158.189 569.239 159.858Z" fill="#DEDEDE"/>
<path d="M561.125 159.14C561.801 160.809 563.491 162.267 564.907 162.394C566.322 162.521 566.914 161.253 566.238 159.584C565.562 157.915 563.871 156.457 562.456 156.33C561.04 156.224 560.449 157.471 561.125 159.14Z" fill="#DEDEDE"/>
<path d="M552.97 158.443C553.625 160.112 555.315 161.57 556.731 161.696C558.146 161.823 558.759 160.555 558.083 158.886C557.407 157.217 555.738 155.759 554.322 155.654C552.928 155.527 552.315 156.773 552.97 158.443Z" fill="#DEDEDE"/>
<path d="M544.814 157.766C545.448 159.435 547.117 160.893 548.554 160.999C549.969 161.126 550.603 159.858 549.948 158.189C549.293 156.52 547.624 155.062 546.209 154.956C544.793 154.851 544.18 156.097 544.814 157.766Z" fill="#DEDEDE"/>
<path d="M536.637 157.111C537.271 158.78 538.919 160.217 540.356 160.344C541.771 160.45 542.426 159.203 541.792 157.534C541.159 155.865 539.489 154.428 538.074 154.301C536.658 154.196 536.024 155.442 536.637 157.111Z" fill="#DEDEDE"/>
<path d="M528.44 156.478C529.052 158.147 530.7 159.584 532.116 159.711C533.553 159.816 534.208 158.549 533.574 156.879C532.961 155.21 531.313 153.774 529.897 153.668C528.503 153.541 527.848 154.809 528.44 156.478Z" fill="#DEDEDE"/>
<path d="M520.241 155.865C520.833 157.534 522.481 158.971 523.896 159.076C525.333 159.182 525.988 157.914 525.397 156.245C524.784 154.576 523.157 153.139 521.741 153.033C520.305 152.928 519.65 154.196 520.241 155.865Z" fill="#DEDEDE"/>
<path d="M512.001 155.252C512.571 156.921 514.198 158.358 515.635 158.463C517.071 158.569 517.747 157.301 517.177 155.632C516.585 153.963 514.959 152.526 513.543 152.421C512.127 152.315 511.43 153.583 512.001 155.252Z" fill="#DEDEDE"/>
<path d="M503.76 154.681C504.31 156.351 505.937 157.787 507.373 157.872C508.81 157.977 509.507 156.71 508.937 155.041C508.366 153.371 506.74 151.935 505.324 151.85C503.908 151.745 503.211 153.012 503.76 154.681Z" fill="#DEDEDE"/>
<path d="M495.499 154.112C496.049 155.781 497.654 157.218 499.091 157.302C500.528 157.408 501.246 156.119 500.697 154.45C500.147 152.781 498.542 151.365 497.105 151.259C495.689 151.175 494.971 152.443 495.499 154.112Z" fill="#DEDEDE"/>
<path d="M487.239 153.584C487.767 155.253 489.352 156.668 490.809 156.774C492.246 156.858 492.986 155.591 492.458 153.922C491.929 152.252 490.324 150.837 488.908 150.731C487.45 150.626 486.711 151.914 487.239 153.584Z" fill="#DEDEDE"/>
<path d="M478.935 153.055C479.442 154.724 481.027 156.14 482.464 156.224C483.9 156.309 484.661 155.041 484.133 153.372C483.605 151.703 482.02 150.287 480.604 150.202C479.189 150.118 478.428 151.386 478.935 153.055Z" fill="#DEDEDE"/>
<path d="M470.652 152.548C471.138 154.217 472.723 155.633 474.16 155.717C475.596 155.802 476.378 154.513 475.871 152.865C475.364 151.196 473.801 149.78 472.364 149.696C470.927 149.611 470.145 150.879 470.652 152.548Z" fill="#DEDEDE"/>
<path d="M462.328 152.062C462.793 153.732 464.356 155.147 465.814 155.232C467.272 155.316 468.054 154.027 467.568 152.379C467.082 150.71 465.518 149.295 464.082 149.21C462.645 149.126 461.863 150.393 462.328 152.062Z" fill="#DEDEDE"/>
<path d="M454.003 151.597C454.468 153.266 456.01 154.682 457.447 154.745C458.905 154.83 459.687 153.541 459.222 151.872C458.757 150.202 457.215 148.808 455.778 148.723C454.341 148.66 453.538 149.949 454.003 151.597Z" fill="#DEDEDE"/>
<path d="M445.658 151.154C446.102 152.823 447.623 154.217 449.081 154.302C450.539 154.386 451.342 153.097 450.898 151.428C450.454 149.759 448.912 148.365 447.475 148.28C446.039 148.217 445.215 149.506 445.658 151.154Z" fill="#DEDEDE"/>
<path d="M437.313 150.731C437.735 152.4 439.257 153.794 440.714 153.879C442.172 153.942 442.996 152.653 442.574 151.005C442.13 149.336 440.63 147.942 439.172 147.878C437.714 147.794 436.89 149.083 437.313 150.731Z" fill="#DEDEDE"/>
<path d="M428.945 150.329C429.347 151.998 430.847 153.393 432.305 153.456C433.762 153.52 434.608 152.231 434.185 150.583C433.762 148.914 432.262 147.519 430.826 147.456C429.389 147.392 428.544 148.681 428.945 150.329Z" fill="#DEDEDE"/>
<path d="M420.557 149.949C420.937 151.618 422.437 153.012 423.895 153.076C425.353 153.139 426.219 151.85 425.818 150.181C425.416 148.512 423.916 147.117 422.48 147.054C421.043 147.012 420.177 148.301 420.557 149.949Z" fill="#DEDEDE"/>
<path d="M412.169 149.59C412.528 151.259 414.007 152.653 415.486 152.717C416.944 152.78 417.831 151.491 417.451 149.822C417.071 148.153 415.592 146.779 414.134 146.716C412.676 146.632 411.81 147.941 412.169 149.59Z" fill="#DEDEDE"/>
<path d="M403.781 149.252C404.14 150.9 405.598 152.315 407.056 152.357C408.514 152.421 409.422 151.111 409.042 149.463C408.683 147.794 407.204 146.42 405.767 146.357C404.309 146.294 403.422 147.604 403.781 149.252Z" fill="#DEDEDE"/>
<path d="M395.372 148.935C395.71 150.583 397.168 151.977 398.626 152.041C400.084 152.104 400.993 150.794 400.654 149.125C400.316 147.477 398.859 146.082 397.401 146.019C395.943 145.977 395.034 147.287 395.372 148.935Z" fill="#DEDEDE"/>
<path d="M386.941 148.639C387.258 150.287 388.695 151.681 390.174 151.745C391.632 151.787 392.562 150.498 392.245 148.829C391.928 147.181 390.47 145.786 389.033 145.744C387.554 145.681 386.624 146.97 386.941 148.639Z" fill="#DEDEDE"/>
<path d="M378.511 148.364C378.807 150.012 380.243 151.407 381.701 151.449C383.159 151.491 384.11 150.181 383.793 148.533C383.476 146.885 382.061 145.491 380.603 145.449C379.166 145.406 378.236 146.695 378.511 148.364Z" fill="#DEDEDE"/>
<path d="M370.081 148.111C370.356 149.759 371.771 151.154 373.25 151.196C374.729 151.238 375.68 149.928 375.384 148.28C375.088 146.632 373.673 145.238 372.215 145.195C370.757 145.132 369.806 146.442 370.081 148.111Z" fill="#DEDEDE"/>
<path d="M361.651 147.857C361.904 149.505 363.299 150.9 364.778 150.942C366.257 150.984 367.229 149.674 366.954 148.005C366.68 146.357 365.285 144.984 363.827 144.941C362.348 144.899 361.376 146.209 361.651 147.857Z" fill="#DEDEDE"/>
<path d="M353.2 147.646C353.432 149.294 354.827 150.689 356.306 150.71C357.785 150.752 358.756 149.421 358.524 147.773C358.27 146.125 356.876 144.751 355.418 144.709C353.939 144.688 352.946 145.998 353.2 147.646Z" fill="#DEDEDE"/>
<path d="M344.727 147.456C344.938 149.104 346.312 150.477 347.791 150.52C349.27 150.562 350.263 149.231 350.03 147.583C349.798 145.935 348.425 144.561 346.967 144.519C345.509 144.477 344.516 145.808 344.727 147.456Z" fill="#DEDEDE"/>
<path d="M336.275 147.286C336.486 148.935 337.838 150.308 339.317 150.35C340.796 150.371 341.811 149.061 341.599 147.392C341.388 145.744 340.015 144.371 338.557 144.35C337.078 144.307 336.064 145.617 336.275 147.286Z" fill="#DEDEDE"/>
<path d="M327.802 147.139C327.992 148.787 329.324 150.16 330.803 150.181C332.282 150.202 333.317 148.892 333.127 147.223C332.937 145.575 331.584 144.202 330.126 144.181C328.647 144.16 327.612 145.47 327.802 147.139Z" fill="#DEDEDE"/>
<path d="M319.33 147.012C319.499 148.66 320.83 150.033 322.309 150.054C323.788 150.076 324.845 148.745 324.655 147.096C324.486 145.448 323.154 144.075 321.676 144.054C320.218 144.033 319.161 145.343 319.33 147.012Z" fill="#DEDEDE"/>
<path d="M310.836 146.885C310.984 148.533 312.294 149.906 313.773 149.928C315.252 149.949 316.33 148.618 316.161 146.97C315.992 145.322 314.682 143.969 313.224 143.948C311.766 143.906 310.688 145.237 310.836 146.885Z" fill="#DEDEDE"/>
<path d="M302.344 146.801C302.47 148.449 303.78 149.822 305.259 149.822C306.738 149.843 307.816 148.512 307.689 146.843C307.541 145.195 306.252 143.843 304.773 143.822C303.315 143.822 302.238 145.153 302.344 146.801Z" fill="#DEDEDE"/>
<path d="M293.87 146.737C293.976 148.385 295.265 149.738 296.744 149.759C298.223 149.78 299.321 148.428 299.195 146.78C299.068 145.132 297.779 143.779 296.321 143.758C294.863 143.758 293.743 145.089 293.87 146.737Z" fill="#DEDEDE"/>
<path d="M276.862 146.674C276.925 148.322 278.193 149.675 279.672 149.675C281.151 149.675 282.292 148.343 282.207 146.674C282.123 145.026 280.855 143.674 279.397 143.674C277.939 143.674 276.799 145.026 276.862 146.674Z" fill="#DEDEDE"/>
<path d="M268.368 146.674C268.432 148.322 269.657 149.675 271.136 149.675C272.615 149.675 273.756 148.322 273.693 146.674C273.629 145.026 272.383 143.674 270.925 143.674C269.467 143.674 268.326 145.026 268.368 146.674Z" fill="#DEDEDE"/>
<path d="M217.343 147.118C217.28 148.766 218.442 150.097 219.921 150.076C221.4 150.055 222.647 148.681 222.689 147.033C222.731 145.385 221.59 144.054 220.111 144.075C218.632 144.096 217.386 145.449 217.343 147.118Z" fill="#DEDEDE"/>
<path d="M208.828 147.266C208.744 148.914 209.885 150.245 211.364 150.224C212.843 150.203 214.11 148.829 214.174 147.181C214.237 145.533 213.096 144.202 211.638 144.244C210.159 144.244 208.913 145.597 208.828 147.266Z" fill="#DEDEDE"/>
<path d="M200.314 147.435C200.208 149.083 201.328 150.414 202.828 150.372C204.307 150.351 205.575 148.977 205.659 147.308C205.744 145.66 204.624 144.329 203.145 144.371C201.687 144.414 200.419 145.766 200.314 147.435Z" fill="#DEDEDE"/>
<path d="M191.819 147.625C191.693 149.273 192.812 150.604 194.291 150.562C195.77 150.52 197.059 149.146 197.165 147.498C197.271 145.85 196.172 144.54 194.693 144.562C193.214 144.604 191.925 145.977 191.819 147.625Z" fill="#DEDEDE"/>
<path d="M183.305 147.836C183.178 149.484 184.255 150.815 185.734 150.773C187.213 150.73 188.523 149.357 188.629 147.709C188.756 146.061 187.657 144.751 186.199 144.772C184.741 144.814 183.452 146.188 183.305 147.836Z" fill="#DEDEDE"/>
<path d="M573.359 151.154C574.056 152.823 575.768 154.281 577.162 154.407C578.557 154.534 579.106 153.288 578.388 151.618C577.669 149.949 575.979 148.513 574.585 148.365C573.19 148.259 572.641 149.506 573.359 151.154Z" fill="#DEDEDE"/>
<path d="M565.33 150.435C566.027 152.104 567.717 153.541 569.112 153.667C570.506 153.794 571.077 152.548 570.38 150.879C569.682 149.209 567.992 147.773 566.598 147.646C565.203 147.519 564.654 148.766 565.33 150.435Z" fill="#DEDEDE"/>
<path d="M557.3 149.717C557.977 151.386 559.667 152.822 561.061 152.949C562.456 153.076 563.047 151.829 562.35 150.16C561.653 148.491 559.984 147.054 558.589 146.928C557.216 146.822 556.624 148.069 557.3 149.717Z" fill="#DEDEDE"/>
<path d="M549.251 149.041C549.906 150.71 551.575 152.147 552.991 152.273C554.385 152.4 554.998 151.132 554.322 149.484C553.646 147.815 551.977 146.379 550.582 146.273C549.188 146.125 548.596 147.372 549.251 149.041Z" fill="#DEDEDE"/>
<path d="M541.18 148.364C541.813 150.034 543.483 151.47 544.898 151.576C546.314 151.682 546.905 150.435 546.25 148.787C545.595 147.118 543.926 145.681 542.532 145.576C541.158 145.449 540.546 146.695 541.18 148.364Z" fill="#DEDEDE"/>
<path d="M533.109 147.709C533.742 149.378 535.39 150.815 536.785 150.921C538.201 151.026 538.813 149.78 538.179 148.111C537.546 146.441 535.898 145.026 534.503 144.899C533.109 144.793 532.475 146.061 533.109 147.709Z" fill="#DEDEDE"/>
<path d="M524.996 147.076C525.609 148.724 527.235 150.161 528.651 150.287C530.067 150.393 530.701 149.146 530.088 147.477C529.475 145.808 527.827 144.392 526.433 144.287C525.038 144.16 524.383 145.428 524.996 147.076Z" fill="#DEDEDE"/>
<path d="M516.881 146.463C517.473 148.111 519.1 149.548 520.516 149.653C521.931 149.759 522.586 148.491 521.973 146.843C521.361 145.195 519.734 143.759 518.339 143.653C516.945 143.547 516.29 144.794 516.881 146.463Z" fill="#DEDEDE"/>
<path d="M508.747 145.871C509.317 147.519 510.944 148.956 512.36 149.062C513.776 149.167 514.452 147.9 513.86 146.252C513.269 144.604 511.663 143.167 510.247 143.061C508.831 142.956 508.176 144.202 508.747 145.871Z" fill="#DEDEDE"/>
<path d="M500.592 145.28C501.163 146.928 502.769 148.365 504.184 148.47C505.6 148.576 506.297 147.308 505.727 145.66C505.156 144.012 503.55 142.575 502.135 142.491C500.719 142.364 500.043 143.632 500.592 145.28Z" fill="#DEDEDE"/>
<path d="M492.436 144.73C492.986 146.378 494.57 147.815 495.986 147.9C497.401 148.005 498.12 146.737 497.549 145.068C497 143.42 495.394 142.005 494 141.899C492.584 141.814 491.887 143.082 492.436 144.73Z" fill="#DEDEDE"/>
<path d="M484.259 144.202C484.787 145.85 486.372 147.266 487.787 147.371C489.203 147.456 489.921 146.188 489.393 144.54C488.844 142.892 487.28 141.476 485.865 141.371C484.449 141.265 483.731 142.533 484.259 144.202Z" fill="#DEDEDE"/>
<path d="M476.062 143.673C476.569 145.321 478.132 146.737 479.569 146.821C481.006 146.906 481.724 145.638 481.217 143.99C480.689 142.342 479.125 140.927 477.71 140.842C476.294 140.758 475.555 142.025 476.062 143.673Z" fill="#DEDEDE"/>
<path d="M467.863 143.167C468.349 144.815 469.912 146.23 471.349 146.315C472.786 146.399 473.525 145.131 473.018 143.483C472.511 141.835 470.948 140.42 469.532 140.335C468.117 140.251 467.377 141.518 467.863 143.167Z" fill="#DEDEDE"/>
<path d="M459.645 142.702C460.109 144.35 461.673 145.766 463.088 145.85C464.525 145.935 465.286 144.667 464.8 142.998C464.314 141.35 462.772 139.934 461.356 139.871C459.94 139.786 459.159 141.054 459.645 142.702Z" fill="#DEDEDE"/>
<path d="M451.405 142.237C451.869 143.885 453.391 145.3 454.827 145.364C456.264 145.448 457.046 144.159 456.581 142.511C456.116 140.863 454.574 139.469 453.158 139.384C451.743 139.321 450.961 140.589 451.405 142.237Z" fill="#DEDEDE"/>
<path d="M443.164 141.793C443.608 143.441 445.129 144.836 446.566 144.92C448.003 145.005 448.806 143.716 448.341 142.068C447.897 140.42 446.376 139.025 444.939 138.941C443.523 138.877 442.721 140.145 443.164 141.793Z" fill="#DEDEDE"/>
<path d="M434.925 141.371C435.348 143.019 436.848 144.414 438.284 144.498C439.721 144.562 440.545 143.294 440.102 141.646C439.658 139.998 438.158 138.603 436.742 138.54C435.305 138.455 434.502 139.723 434.925 141.371Z" fill="#DEDEDE"/>
<path d="M426.642 140.991C427.044 142.639 428.544 144.033 429.981 144.096C431.417 144.16 432.242 142.892 431.84 141.223C431.417 139.575 429.917 138.18 428.502 138.117C427.086 138.054 426.241 139.321 426.642 140.991Z" fill="#DEDEDE"/>
<path d="M418.381 140.61C418.761 142.258 420.24 143.653 421.698 143.716C423.135 143.78 423.98 142.491 423.579 140.843C423.177 139.195 421.698 137.8 420.262 137.737C418.825 137.673 417.98 138.962 418.381 140.61Z" fill="#DEDEDE"/>
<path d="M410.099 140.251C410.458 141.899 411.937 143.294 413.374 143.357C414.811 143.42 415.677 142.131 415.296 140.483C414.916 138.835 413.437 137.462 412.022 137.399C410.585 137.314 409.719 138.603 410.099 140.251Z" fill="#DEDEDE"/>
<path d="M401.795 139.913C402.154 141.561 403.591 142.956 405.048 142.998C406.485 143.061 407.373 141.772 407.013 140.124C406.654 138.476 405.196 137.103 403.76 137.04C402.323 136.976 401.436 138.265 401.795 139.913Z" fill="#DEDEDE"/>
<path d="M393.492 139.596C393.83 141.244 395.266 142.617 396.724 142.681C398.182 142.744 399.07 141.434 398.71 139.786C398.372 138.138 396.914 136.765 395.499 136.701C394.041 136.659 393.154 137.948 393.492 139.596Z" fill="#DEDEDE"/>
<path d="M385.167 139.3C385.484 140.948 386.92 142.321 388.357 142.385C389.815 142.427 390.723 141.138 390.385 139.49C390.068 137.842 388.632 136.469 387.195 136.427C385.758 136.363 384.85 137.652 385.167 139.3Z" fill="#DEDEDE"/>
<path d="M376.842 139.026C377.138 140.674 378.553 142.047 380.011 142.089C381.469 142.131 382.378 140.843 382.082 139.195C381.765 137.546 380.349 136.173 378.913 136.131C377.476 136.089 376.546 137.377 376.842 139.026Z" fill="#DEDEDE"/>
<path d="M368.517 138.772C368.792 140.42 370.186 141.793 371.644 141.836C373.102 141.878 374.032 140.568 373.757 138.941C373.461 137.293 372.067 135.92 370.63 135.877C369.172 135.835 368.243 137.124 368.517 138.772Z" fill="#DEDEDE"/>
<path d="M360.171 138.54C360.424 140.188 361.819 141.561 363.277 141.603C364.735 141.646 365.685 140.336 365.411 138.688C365.136 137.04 363.742 135.687 362.305 135.645C360.868 135.582 359.917 136.892 360.171 138.54Z" fill="#DEDEDE"/>
<path d="M351.826 138.328C352.059 139.976 353.432 141.328 354.89 141.371C356.348 141.413 357.32 140.103 357.066 138.455C356.813 136.807 355.439 135.455 354.003 135.412C352.566 135.37 351.594 136.68 351.826 138.328Z" fill="#DEDEDE"/>
<path d="M343.48 138.117C343.712 139.765 345.065 141.117 346.523 141.159C347.98 141.202 348.973 139.892 348.741 138.244C348.509 136.596 347.156 135.243 345.698 135.201C344.241 135.18 343.248 136.49 343.48 138.117Z" fill="#DEDEDE"/>
<path d="M335.113 137.948C335.324 139.596 336.655 140.948 338.113 140.969C339.571 140.99 340.585 139.68 340.353 138.032C340.141 136.384 338.789 135.032 337.352 135.011C335.916 135.011 334.923 136.321 335.113 137.948Z" fill="#DEDEDE"/>
<path d="M326.746 137.8C326.937 139.448 328.268 140.8 329.725 140.821C331.183 140.842 332.198 139.532 332.007 137.884C331.817 136.236 330.486 134.884 329.028 134.863C327.592 134.863 326.577 136.173 326.746 137.8Z" fill="#DEDEDE"/>
<path d="M318.379 137.673C318.548 139.321 319.858 140.673 321.316 140.695C322.774 140.716 323.809 139.406 323.64 137.758C323.471 136.11 322.14 134.757 320.704 134.736C319.246 134.715 318.21 136.025 318.379 137.673Z" fill="#DEDEDE"/>
<path d="M310.013 137.567C310.161 139.216 311.471 140.568 312.929 140.568C314.387 140.589 315.443 139.258 315.274 137.631C315.105 135.983 313.816 134.652 312.359 134.631C310.922 134.609 309.865 135.919 310.013 137.567Z" fill="#DEDEDE"/>
<path d="M301.624 137.483C301.751 139.131 303.039 140.463 304.497 140.484C305.955 140.505 307.033 139.174 306.885 137.526C306.737 135.878 305.448 134.547 304.011 134.525C302.575 134.525 301.497 135.835 301.624 137.483Z" fill="#DEDEDE"/>
<path d="M293.237 137.42C293.343 139.068 294.61 140.399 296.089 140.42C297.547 140.42 298.646 139.11 298.519 137.462C298.392 135.814 297.125 134.483 295.667 134.462C294.23 134.462 293.131 135.772 293.237 137.42Z" fill="#DEDEDE"/>
<path d="M276.46 137.357C276.523 139.005 277.77 140.336 279.228 140.336C280.686 140.336 281.806 139.005 281.721 137.357C281.637 135.709 280.39 134.377 278.953 134.377C277.516 134.377 276.397 135.709 276.46 137.357Z" fill="#DEDEDE"/>
<path d="M259.662 137.378C259.705 139.026 260.909 140.357 262.367 140.357C263.825 140.357 264.987 139.026 264.923 137.378C264.881 135.73 263.656 134.42 262.219 134.42C260.782 134.399 259.641 135.73 259.662 137.378Z" fill="#DEDEDE"/>
<path d="M251.275 137.42C251.296 139.068 252.479 140.399 253.958 140.378C255.416 140.378 256.578 139.025 256.557 137.377C256.536 135.729 255.331 134.419 253.873 134.419C252.416 134.441 251.254 135.772 251.275 137.42Z" fill="#DEDEDE"/>
<path d="M234.477 137.568C234.456 139.216 235.618 140.526 237.076 140.526C238.534 140.504 239.738 139.173 239.738 137.525C239.738 135.877 238.576 134.567 237.14 134.588C235.682 134.567 234.498 135.919 234.477 137.568Z" fill="#DEDEDE"/>
<path d="M217.68 137.779C217.616 139.427 218.757 140.737 220.215 140.716C221.673 140.695 222.899 139.342 222.962 137.694C223.004 136.046 221.863 134.736 220.427 134.758C218.948 134.8 217.743 136.152 217.68 137.779Z" fill="#DEDEDE"/>
<path d="M209.273 137.927C209.188 139.575 210.308 140.885 211.787 140.864C213.245 140.843 214.491 139.49 214.555 137.842C214.618 136.194 213.498 134.884 212.04 134.927C210.604 134.948 209.357 136.3 209.273 137.927Z" fill="#DEDEDE"/>
<path d="M8.99463 147.773C8.48755 149.421 9.22703 150.689 10.6638 150.562C12.1005 150.456 13.664 149.019 14.1711 147.35C14.6782 145.702 13.9176 144.434 12.502 144.561C11.0864 144.688 9.50171 146.104 8.99463 147.773Z" fill="#DEDEDE"/>
<path d="M0.754317 148.428C0.226104 150.076 0.944451 151.343 2.38119 151.217C3.81793 151.09 5.40257 149.653 5.90965 148.005C6.43786 146.357 5.69839 145.111 4.28278 145.216C2.86717 145.343 1.28253 146.78 0.754317 148.428Z" fill="#DEDEDE"/>
<path d="M569.365 141.793C570.062 143.441 571.752 144.899 573.147 145.026C574.52 145.153 575.069 143.906 574.351 142.258C573.633 140.61 571.942 139.152 570.569 139.026C569.175 138.899 568.646 140.124 569.365 141.793Z" fill="#DEDEDE"/>
<path d="M561.421 141.054C562.118 142.702 563.787 144.139 565.182 144.266C566.555 144.392 567.104 143.146 566.407 141.498C565.71 139.85 564.02 138.413 562.646 138.286C561.294 138.18 560.745 139.406 561.421 141.054Z" fill="#DEDEDE"/>
<path d="M553.477 140.357C554.153 142.005 555.822 143.441 557.217 143.568C558.611 143.695 559.161 142.448 558.484 140.8C557.787 139.152 556.118 137.715 554.745 137.589C553.371 137.462 552.801 138.709 553.477 140.357Z" fill="#DEDEDE"/>
<path d="M545.512 139.68C546.167 141.328 547.836 142.765 549.209 142.892C550.604 143.019 551.174 141.772 550.498 140.124C549.822 138.476 548.174 137.039 546.801 136.934C545.448 136.786 544.857 138.032 545.512 139.68Z" fill="#DEDEDE"/>
<path d="M537.545 139.005C538.179 140.653 539.848 142.089 541.221 142.195C542.616 142.301 543.207 141.054 542.552 139.406C541.898 137.758 540.25 136.321 538.876 136.216C537.503 136.11 536.89 137.357 537.545 139.005Z" fill="#DEDEDE"/>
<path d="M529.559 138.371C530.193 140.019 531.82 141.455 533.214 141.561C534.609 141.667 535.222 140.42 534.588 138.772C533.954 137.124 532.306 135.708 530.932 135.582C529.538 135.476 528.925 136.723 529.559 138.371Z" fill="#DEDEDE"/>
<path d="M521.53 137.737C522.143 139.385 523.77 140.8 525.164 140.927C526.559 141.033 527.192 139.786 526.559 138.138C525.925 136.49 524.298 135.074 522.924 134.969C521.551 134.842 520.917 136.089 521.53 137.737Z" fill="#DEDEDE"/>
<path d="M513.522 137.124C514.114 138.772 515.741 140.187 517.135 140.293C518.53 140.399 519.163 139.152 518.572 137.504C517.959 135.856 516.353 134.441 514.959 134.335C513.564 134.229 512.909 135.476 513.522 137.124Z" fill="#DEDEDE"/>
<path d="M505.473 136.532C506.044 138.18 507.649 139.596 509.065 139.701C510.459 139.807 511.114 138.56 510.523 136.912C509.931 135.264 508.326 133.849 506.931 133.743C505.558 133.637 504.903 134.884 505.473 136.532Z" fill="#DEDEDE"/>
<path d="M497.423 135.962C497.993 137.61 499.578 139.026 500.972 139.11C502.367 139.216 503.043 137.948 502.473 136.3C501.902 134.652 500.296 133.237 498.923 133.152C497.528 133.067 496.852 134.314 497.423 135.962Z" fill="#DEDEDE"/>
<path d="M489.351 135.413C489.901 137.061 491.485 138.477 492.88 138.561C494.296 138.667 494.972 137.399 494.422 135.751C493.873 134.103 492.288 132.709 490.894 132.603C489.499 132.518 488.802 133.765 489.351 135.413Z" fill="#DEDEDE"/>
<path d="M481.279 134.885C481.808 136.533 483.371 137.927 484.787 138.033C486.202 138.117 486.9 136.871 486.35 135.223C485.801 133.575 484.237 132.18 482.843 132.096C481.448 131.969 480.751 133.237 481.279 134.885Z" fill="#DEDEDE"/>
<path d="M473.167 134.356C473.674 136.004 475.238 137.399 476.653 137.483C478.069 137.568 478.787 136.321 478.259 134.673C477.731 133.025 476.188 131.63 474.773 131.546C473.378 131.461 472.66 132.729 473.167 134.356Z" fill="#DEDEDE"/>
<path d="M465.074 133.87C465.56 135.518 467.123 136.913 468.518 136.997C469.934 137.082 470.673 135.814 470.166 134.187C469.659 132.539 468.116 131.145 466.722 131.06C465.306 130.976 464.567 132.222 465.074 133.87Z" fill="#DEDEDE"/>
<path d="M456.94 133.384C457.404 135.032 458.947 136.427 460.362 136.511C461.778 136.596 462.518 135.328 462.032 133.68C461.546 132.032 460.003 130.638 458.609 130.574C457.214 130.49 456.475 131.757 456.94 133.384Z" fill="#DEDEDE"/>
<path d="M448.826 132.94C449.291 134.588 450.812 135.983 452.228 136.046C453.644 136.131 454.404 134.863 453.939 133.215C453.475 131.567 451.953 130.194 450.538 130.109C449.122 130.046 448.362 131.292 448.826 132.94Z" fill="#DEDEDE"/>
<path d="M440.671 132.497C441.114 134.124 442.614 135.518 444.03 135.603C445.446 135.687 446.227 134.398 445.784 132.771C445.34 131.123 443.819 129.75 442.424 129.666C441.009 129.602 440.227 130.87 440.671 132.497Z" fill="#DEDEDE"/>
<path d="M432.515 132.096C432.938 133.722 434.438 135.117 435.853 135.201C437.269 135.265 438.072 133.997 437.628 132.37C437.184 130.722 435.706 129.349 434.29 129.285C432.895 129.18 432.093 130.448 432.515 132.096Z" fill="#DEDEDE"/>
<path d="M424.36 131.694C424.761 133.321 426.24 134.715 427.677 134.779C429.092 134.842 429.916 133.574 429.494 131.926C429.071 130.278 427.592 128.905 426.177 128.842C424.761 128.799 423.958 130.067 424.36 131.694Z" fill="#DEDEDE"/>
<path d="M416.183 131.314C416.563 132.941 418.042 134.335 419.458 134.399C420.873 134.462 421.719 133.194 421.317 131.546C420.916 129.919 419.458 128.525 418.042 128.461C416.627 128.419 415.803 129.687 416.183 131.314Z" fill="#DEDEDE"/>
<path d="M408.006 130.976C408.365 132.603 409.823 133.997 411.26 134.039C412.676 134.103 413.542 132.814 413.14 131.187C412.76 129.56 411.302 128.187 409.887 128.123C408.471 128.06 407.626 129.328 408.006 130.976Z" fill="#DEDEDE"/>
<path d="M399.809 130.638C400.168 132.264 401.605 133.638 403.02 133.701C404.457 133.765 405.302 132.476 404.943 130.849C404.584 129.222 403.126 127.849 401.731 127.785C400.316 127.722 399.45 128.99 399.809 130.638Z" fill="#DEDEDE"/>
<path d="M391.611 130.321C391.949 131.948 393.365 133.321 394.802 133.385C396.238 133.448 397.105 132.159 396.766 130.511C396.428 128.884 394.992 127.511 393.576 127.469C392.139 127.405 391.273 128.694 391.611 130.321Z" fill="#DEDEDE"/>
<path d="M383.392 130.025C383.709 131.652 385.124 133.025 386.561 133.067C387.998 133.11 388.885 131.842 388.568 130.194C388.23 128.567 386.815 127.194 385.399 127.151C383.962 127.109 383.075 128.398 383.392 130.025Z" fill="#DEDEDE"/>
<path d="M375.173 129.75C375.469 131.377 376.863 132.751 378.3 132.793C379.737 132.835 380.645 131.546 380.328 129.919C380.011 128.293 378.617 126.919 377.201 126.877C375.786 126.835 374.877 128.124 375.173 129.75Z" fill="#DEDEDE"/>
<path d="M366.954 129.497C367.228 131.124 368.623 132.497 370.06 132.539C371.496 132.581 372.426 131.293 372.13 129.666C371.834 128.039 370.461 126.687 369.024 126.644C367.588 126.581 366.658 127.87 366.954 129.497Z" fill="#DEDEDE"/>
<path d="M358.713 129.264C358.966 130.891 360.34 132.243 361.776 132.286C363.213 132.328 364.164 131.039 363.889 129.391C363.615 127.764 362.241 126.412 360.826 126.37C359.389 126.349 358.438 127.637 358.713 129.264Z" fill="#DEDEDE"/>
<path d="M350.474 129.053C350.706 130.679 352.08 132.032 353.516 132.074C354.953 132.116 355.904 130.806 355.65 129.179C355.397 127.552 354.045 126.2 352.608 126.158C351.192 126.137 350.22 127.426 350.474 129.053Z" fill="#DEDEDE"/>
<path d="M342.212 128.863C342.423 130.49 343.775 131.842 345.212 131.884C346.649 131.926 347.621 130.616 347.388 128.99C347.156 127.363 345.804 126.01 344.388 125.989C342.973 125.947 342.001 127.236 342.212 128.863Z" fill="#DEDEDE"/>
<path d="M333.973 128.694C334.184 130.321 335.515 131.673 336.952 131.694C338.389 131.715 339.382 130.426 339.17 128.799C338.959 127.172 337.628 125.82 336.191 125.799C334.754 125.778 333.761 127.067 333.973 128.694Z" fill="#DEDEDE"/>
<path d="M325.711 128.546C325.901 130.173 327.211 131.525 328.648 131.546C330.085 131.567 331.099 130.257 330.887 128.63C330.697 127.003 329.366 125.651 327.951 125.63C326.535 125.63 325.521 126.919 325.711 128.546Z" fill="#DEDEDE"/>
<path d="M317.45 128.419C317.619 130.046 318.907 131.398 320.365 131.42C321.802 131.441 322.837 130.131 322.647 128.504C322.478 126.877 321.168 125.546 319.731 125.525C318.295 125.504 317.281 126.792 317.45 128.419Z" fill="#DEDEDE"/>
<path d="M309.167 128.313C309.315 129.94 310.604 131.271 312.041 131.293C313.478 131.314 314.513 130.004 314.365 128.377C314.196 126.75 312.907 125.419 311.492 125.398C310.055 125.377 309.019 126.687 309.167 128.313Z" fill="#DEDEDE"/>
<path d="M300.906 128.229C301.033 129.856 302.301 131.187 303.759 131.208C305.195 131.229 306.252 129.919 306.104 128.271C305.956 126.644 304.688 125.313 303.252 125.292C301.836 125.292 300.78 126.602 300.906 128.229Z" fill="#DEDEDE"/>
<path d="M292.623 128.166C292.729 129.792 293.996 131.123 295.433 131.145C296.87 131.166 297.947 129.835 297.821 128.208C297.694 126.581 296.447 125.25 295.011 125.25C293.574 125.229 292.517 126.539 292.623 128.166Z" fill="#DEDEDE"/>
<path d="M284.342 128.123C284.426 129.75 285.673 131.081 287.109 131.081C288.546 131.081 289.645 129.771 289.539 128.145C289.434 126.518 288.187 125.187 286.771 125.187C285.335 125.187 284.257 126.496 284.342 128.123Z" fill="#DEDEDE"/>
<path d="M276.059 128.102C276.122 129.729 277.348 131.06 278.805 131.06C280.242 131.06 281.362 129.75 281.256 128.124C281.172 126.497 279.946 125.166 278.51 125.166C277.094 125.166 275.974 126.475 276.059 128.102Z" fill="#DEDEDE"/>
<path d="M267.776 128.102C267.818 129.729 269.044 131.06 270.48 131.06C271.917 131.06 273.037 129.729 272.974 128.102C272.91 126.475 271.685 125.166 270.269 125.166C268.832 125.166 267.713 126.475 267.776 128.102Z" fill="#DEDEDE"/>
<path d="M259.473 128.124C259.515 129.75 260.698 131.082 262.156 131.06C263.593 131.06 264.734 129.729 264.692 128.102C264.649 126.475 263.445 125.166 262.008 125.166C260.572 125.166 259.452 126.497 259.473 128.124Z" fill="#DEDEDE"/>
<path d="M251.19 128.166C251.211 129.793 252.395 131.103 253.831 131.103C255.268 131.103 256.43 129.772 256.388 128.145C256.367 126.518 255.183 125.208 253.747 125.208C252.331 125.208 251.169 126.539 251.19 128.166Z" fill="#DEDEDE"/>
<path d="M242.888 128.229C242.888 129.856 244.05 131.166 245.486 131.166C246.923 131.145 248.106 129.814 248.085 128.187C248.085 126.56 246.902 125.25 245.486 125.25C244.071 125.271 242.909 126.602 242.888 128.229Z" fill="#DEDEDE"/>
<path d="M234.605 128.313C234.584 129.94 235.725 131.25 237.183 131.229C238.62 131.208 239.803 129.877 239.824 128.25C239.824 126.623 238.683 125.313 237.246 125.334C235.81 125.355 234.626 126.686 234.605 128.313Z" fill="#DEDEDE"/>
<path d="M226.301 128.419C226.259 130.046 227.4 131.356 228.837 131.335C230.274 131.314 231.478 129.983 231.499 128.356C231.52 126.729 230.4 125.419 228.964 125.44C227.548 125.44 226.344 126.792 226.301 128.419Z" fill="#DEDEDE"/>
<path d="M218.019 128.546C217.955 130.173 219.075 131.483 220.533 131.462C221.97 131.441 223.195 130.089 223.237 128.462C223.28 126.835 222.16 125.525 220.723 125.546C219.286 125.567 218.082 126.92 218.019 128.546Z" fill="#DEDEDE"/>
<path d="M209.716 128.694C209.631 130.321 210.73 131.631 212.188 131.609C213.624 131.588 214.85 130.236 214.934 128.609C214.998 126.982 213.899 125.672 212.462 125.715C211.025 125.715 209.8 127.046 209.716 128.694Z" fill="#DEDEDE"/>
<path d="M11.9095 138.434C11.4024 140.082 12.1207 141.328 13.5363 141.223C14.952 141.117 16.5155 139.68 17.0226 138.032C17.5297 136.384 16.7901 135.138 15.3957 135.264C13.98 135.37 12.4166 136.786 11.9095 138.434Z" fill="#DEDEDE"/>
<path d="M573.168 133.194C573.887 134.842 575.577 136.279 576.95 136.406C578.303 136.532 578.81 135.307 578.091 133.659C577.352 132.011 575.662 130.574 574.309 130.447C572.957 130.342 572.45 131.567 573.168 133.194Z" fill="#DEDEDE"/>
<path d="M565.351 132.476C566.069 134.124 567.738 135.561 569.112 135.687C570.485 135.814 570.992 134.589 570.274 132.941C569.555 131.293 567.865 129.856 566.513 129.729C565.161 129.602 564.632 130.828 565.351 132.476Z" fill="#DEDEDE"/>
<path d="M557.511 131.757C558.209 133.405 559.878 134.842 561.251 134.948C562.625 135.075 563.153 133.828 562.456 132.201C561.758 130.553 560.089 129.137 558.737 129.011C557.342 128.884 556.814 130.109 557.511 131.757Z" fill="#DEDEDE"/>
<path d="M549.652 131.06C550.328 132.708 551.997 134.124 553.349 134.251C554.722 134.377 555.272 133.131 554.575 131.504C553.877 129.856 552.229 128.44 550.877 128.313C549.525 128.187 548.976 129.412 549.652 131.06Z" fill="#DEDEDE"/>
<path d="M541.771 130.384C542.426 132.032 544.074 133.448 545.447 133.575C546.82 133.702 547.391 132.455 546.715 130.807C546.039 129.159 544.391 127.743 543.038 127.638C541.686 127.511 541.116 128.736 541.771 130.384Z" fill="#DEDEDE"/>
<path d="M533.89 129.729C534.545 131.377 536.172 132.793 537.546 132.898C538.919 133.004 539.511 131.778 538.834 130.13C538.179 128.482 536.531 127.067 535.179 126.961C533.827 126.856 533.256 128.081 533.89 129.729Z" fill="#DEDEDE"/>
<path d="M525.989 129.074C526.622 130.701 528.249 132.138 529.623 132.243C530.996 132.349 531.588 131.123 530.954 129.475C530.32 127.848 528.693 126.433 527.32 126.306C525.946 126.2 525.355 127.447 525.989 129.074Z" fill="#DEDEDE"/>
<path d="M518.065 128.461C518.678 130.088 520.284 131.503 521.678 131.609C523.052 131.715 523.664 130.468 523.052 128.841C522.418 127.214 520.812 125.799 519.439 125.693C518.065 125.587 517.453 126.813 518.065 128.461Z" fill="#DEDEDE"/>
<path d="M510.142 127.849C510.733 129.476 512.339 130.891 513.733 130.997C515.107 131.103 515.741 129.856 515.128 128.229C514.515 126.602 512.909 125.187 511.536 125.081C510.163 124.975 509.55 126.222 510.142 127.849Z" fill="#DEDEDE"/>
<path d="M502.197 127.257C502.767 128.884 504.373 130.3 505.746 130.406C507.141 130.511 507.775 129.265 507.183 127.638C506.592 126.011 505.007 124.595 503.633 124.511C502.26 124.405 501.605 125.631 502.197 127.257Z" fill="#DEDEDE"/>
<path d="M494.232 126.707C494.802 128.334 496.366 129.75 497.76 129.834C499.155 129.94 499.81 128.693 499.239 127.045C498.669 125.418 497.084 124.003 495.711 123.918C494.337 123.834 493.661 125.08 494.232 126.707Z" fill="#DEDEDE"/>
<path d="M486.267 126.158C486.817 127.785 488.38 129.201 489.775 129.285C491.169 129.37 491.845 128.123 491.275 126.496C490.725 124.869 489.141 123.475 487.767 123.369C486.394 123.285 485.718 124.531 486.267 126.158Z" fill="#DEDEDE"/>
<path d="M478.281 125.63C478.809 127.257 480.373 128.651 481.767 128.757C483.162 128.842 483.838 127.595 483.309 125.968C482.76 124.341 481.218 122.947 479.823 122.862C478.429 122.757 477.753 124.003 478.281 125.63Z" fill="#DEDEDE"/>
<path d="M470.272 125.123C470.779 126.75 472.321 128.144 473.716 128.229C475.11 128.314 475.808 127.067 475.279 125.44C474.751 123.813 473.209 122.419 471.835 122.334C470.462 122.25 469.765 123.496 470.272 125.123Z" fill="#DEDEDE"/>
<path d="M462.264 124.637C462.75 126.264 464.292 127.658 465.687 127.743C467.081 127.827 467.799 126.581 467.292 124.954C466.785 123.327 465.243 121.932 463.87 121.848C462.496 121.763 461.778 123.01 462.264 124.637Z" fill="#DEDEDE"/>
<path d="M454.257 124.151C454.743 125.778 456.264 127.172 457.659 127.236C459.053 127.32 459.793 126.053 459.307 124.426C458.821 122.799 457.3 121.425 455.905 121.341C454.511 121.278 453.771 122.524 454.257 124.151Z" fill="#DEDEDE"/>
<path d="M446.206 123.707C446.671 125.334 448.171 126.707 449.566 126.792C450.96 126.876 451.721 125.609 451.235 123.982C450.77 122.355 449.249 120.982 447.875 120.897C446.502 120.834 445.763 122.08 446.206 123.707Z" fill="#DEDEDE"/>
<path d="M438.179 123.285C438.622 124.912 440.122 126.285 441.517 126.369C442.911 126.433 443.693 125.186 443.228 123.559C442.764 121.933 441.285 120.559 439.89 120.496C438.496 120.411 437.735 121.658 438.179 123.285Z" fill="#DEDEDE"/>
<path d="M430.128 122.862C430.55 124.489 432.029 125.862 433.445 125.926C434.839 125.989 435.642 124.743 435.199 123.116C434.755 121.489 433.276 120.115 431.881 120.052C430.487 119.989 429.705 121.256 430.128 122.862Z" fill="#DEDEDE"/>
<path d="M422.058 122.482C422.459 124.109 423.938 125.482 425.333 125.546C426.748 125.609 427.53 124.341 427.107 122.714C426.685 121.087 425.227 119.714 423.832 119.651C422.459 119.608 421.656 120.855 422.058 122.482Z" fill="#DEDEDE"/>
<path d="M413.986 122.101C414.367 123.728 415.825 125.102 417.24 125.165C418.656 125.228 419.459 123.961 419.057 122.334C418.656 120.707 417.198 119.355 415.803 119.291C414.409 119.228 413.606 120.496 413.986 122.101Z" fill="#DEDEDE"/>
<path d="M405.894 121.763C406.274 123.39 407.711 124.743 409.106 124.806C410.521 124.869 411.345 123.602 410.965 121.975C410.585 120.348 409.148 118.996 407.753 118.932C406.359 118.869 405.535 120.137 405.894 121.763Z" fill="#DEDEDE"/>
<path d="M397.802 121.426C398.161 123.052 399.577 124.405 400.992 124.468C402.408 124.531 403.253 123.264 402.894 121.637C402.535 120.01 401.098 118.658 399.704 118.594C398.309 118.552 397.464 119.799 397.802 121.426Z" fill="#DEDEDE"/>
<path d="M389.709 121.13C390.047 122.757 391.463 124.109 392.879 124.172C394.294 124.236 395.161 122.947 394.801 121.341C394.463 119.714 393.048 118.362 391.632 118.32C390.238 118.235 389.371 119.503 389.709 121.13Z" fill="#DEDEDE"/>
<path d="M381.595 120.834C381.912 122.461 383.307 123.813 384.722 123.855C386.138 123.897 387.004 122.63 386.687 121.003C386.349 119.376 384.955 118.024 383.56 117.981C382.166 117.939 381.279 119.207 381.595 120.834Z" fill="#DEDEDE"/>
<path d="M373.482 120.559C373.777 122.186 375.172 123.539 376.588 123.581C378.003 123.623 378.891 122.355 378.595 120.728C378.278 119.102 376.904 117.77 375.489 117.707C374.094 117.665 373.186 118.933 373.482 120.559Z" fill="#DEDEDE"/>
<path d="M365.37 120.305C365.644 121.932 367.018 123.285 368.433 123.327C369.849 123.369 370.757 122.08 370.462 120.474C370.166 118.848 368.793 117.516 367.398 117.474C366.004 117.411 365.095 118.7 365.37 120.305Z" fill="#DEDEDE"/>
<path d="M357.235 120.073C357.488 121.7 358.861 123.031 360.277 123.074C361.693 123.116 362.622 121.827 362.348 120.221C362.073 118.594 360.721 117.263 359.305 117.221C357.911 117.179 356.981 118.468 357.235 120.073Z" fill="#DEDEDE"/>
<path d="M349.101 119.883C349.333 121.51 350.685 122.841 352.101 122.884C353.516 122.926 354.467 121.637 354.214 120.01C353.96 118.383 352.608 117.052 351.213 117.01C349.798 116.989 348.868 118.256 349.101 119.883Z" fill="#DEDEDE"/>
<path d="M340.965 119.693C341.197 121.32 342.529 122.651 343.944 122.672C345.36 122.714 346.332 121.426 346.078 119.799C345.846 118.172 344.515 116.841 343.099 116.82C341.705 116.798 340.733 118.066 340.965 119.693Z" fill="#DEDEDE"/>
<path d="M332.81 119.524C333.021 121.129 334.331 122.482 335.747 122.503C337.163 122.524 338.134 121.235 337.923 119.629C337.712 118.002 336.381 116.692 334.986 116.65C333.592 116.629 332.599 117.897 332.81 119.524Z" fill="#DEDEDE"/>
<path d="M324.654 119.376C324.844 120.982 326.133 122.334 327.569 122.355C328.985 122.376 329.978 121.088 329.788 119.461C329.598 117.834 328.288 116.524 326.872 116.503C325.457 116.482 324.464 117.749 324.654 119.376Z" fill="#DEDEDE"/>
<path d="M316.499 119.249C316.668 120.876 317.957 122.186 319.372 122.207C320.788 122.228 321.802 120.939 321.612 119.312C321.443 117.686 320.154 116.376 318.739 116.354C317.344 116.354 316.33 117.643 316.499 119.249Z" fill="#DEDEDE"/>
<path d="M308.343 119.143C308.491 120.77 309.759 122.08 311.196 122.101C312.611 122.123 313.647 120.813 313.478 119.207C313.309 117.601 312.041 116.27 310.625 116.249C309.21 116.228 308.196 117.538 308.343 119.143Z" fill="#DEDEDE"/>
<path d="M300.166 119.059C300.293 120.665 301.561 121.996 302.976 122.017C304.392 122.038 305.448 120.728 305.3 119.122C305.153 117.517 303.906 116.186 302.49 116.186C301.075 116.143 300.039 117.453 300.166 119.059Z" fill="#DEDEDE"/>
<path d="M291.99 118.996C292.095 120.602 293.342 121.933 294.758 121.933C296.173 121.933 297.23 120.644 297.124 119.017C296.997 117.411 295.751 116.08 294.356 116.08C292.94 116.08 291.884 117.39 291.99 118.996Z" fill="#DEDEDE"/>
<path d="M283.834 118.954C283.918 120.559 285.144 121.89 286.581 121.89C287.996 121.89 289.074 120.58 288.968 118.975C288.862 117.369 287.637 116.059 286.221 116.038C284.806 116.038 283.728 117.348 283.834 118.954Z" fill="#DEDEDE"/>
<path d="M275.657 118.933C275.721 120.538 276.946 121.87 278.362 121.87C279.777 121.87 280.876 120.56 280.792 118.954C280.707 117.348 279.503 116.038 278.087 116.038C276.672 116.017 275.573 117.327 275.657 118.933Z" fill="#DEDEDE"/>
<path d="M267.46 118.932C267.502 120.538 268.706 121.869 270.143 121.869C271.559 121.869 272.678 120.559 272.615 118.932C272.552 117.327 271.347 116.017 269.932 116.017C268.516 116.017 267.417 117.327 267.46 118.932Z" fill="#DEDEDE"/>
<path d="M259.283 118.953C259.325 120.559 260.508 121.869 261.924 121.869C263.361 121.869 264.46 120.559 264.417 118.932C264.375 117.327 263.192 116.017 261.776 116.017C260.382 116.038 259.262 117.348 259.283 118.953Z" fill="#DEDEDE"/>
<path d="M251.106 118.996C251.127 120.601 252.289 121.911 253.705 121.911C255.141 121.911 256.261 120.58 256.24 118.975C256.219 117.369 255.036 116.059 253.641 116.059C252.226 116.059 251.085 117.369 251.106 118.996Z" fill="#DEDEDE"/>
<path d="M242.908 119.059C242.908 120.665 244.049 121.975 245.486 121.975C246.923 121.954 248.064 120.644 248.042 119.017C248.042 117.411 246.88 116.101 245.465 116.122C244.07 116.122 242.929 117.432 242.908 119.059Z" fill="#DEDEDE"/>
<path d="M234.731 119.144C234.71 120.75 235.851 122.06 237.267 122.038C238.703 122.017 239.865 120.707 239.865 119.08C239.865 117.475 238.746 116.165 237.33 116.186C235.914 116.207 234.752 117.517 234.731 119.144Z" fill="#DEDEDE"/>
<path d="M226.555 119.249C226.513 120.855 227.632 122.165 229.069 122.144C230.506 122.122 231.668 120.791 231.71 119.186C231.731 117.58 230.612 116.27 229.196 116.291C227.78 116.312 226.597 117.622 226.555 119.249Z" fill="#DEDEDE"/>
<path d="M218.357 119.355C218.294 120.961 219.392 122.271 220.829 122.25C222.266 122.229 223.449 120.897 223.491 119.292C223.534 117.686 222.435 116.397 221.019 116.418C219.625 116.418 218.42 117.749 218.357 119.355Z" fill="#DEDEDE"/>
<path d="M210.18 119.503C210.095 121.109 211.194 122.419 212.61 122.376C214.046 122.355 215.251 121.024 215.314 119.397C215.377 117.791 214.3 116.503 212.884 116.524C211.469 116.566 210.264 117.897 210.18 119.503Z" fill="#DEDEDE"/>
<path d="M201.983 119.672C201.877 121.278 202.955 122.587 204.391 122.545C205.828 122.524 207.054 121.172 207.138 119.566C207.223 117.96 206.145 116.672 204.729 116.693C203.314 116.735 202.088 118.066 201.983 119.672Z" fill="#DEDEDE"/>
<path d="M569.069 123.94C569.787 125.567 571.478 127.004 572.809 127.13C574.161 127.257 574.647 126.032 573.907 124.405C573.168 122.778 571.499 121.341 570.168 121.214C568.837 121.088 568.351 122.313 569.069 123.94Z" fill="#DEDEDE"/>
<path d="M561.336 123.221C562.054 124.848 563.702 126.285 565.054 126.412C566.406 126.538 566.914 125.313 566.195 123.686C565.477 122.059 563.808 120.644 562.477 120.517C561.145 120.369 560.617 121.594 561.336 123.221Z" fill="#DEDEDE"/>
<path d="M553.582 122.503C554.28 124.13 555.928 125.545 557.28 125.672C558.632 125.799 559.16 124.573 558.442 122.946C557.745 121.32 556.076 119.904 554.745 119.777C553.413 119.672 552.885 120.876 553.582 122.503Z" fill="#DEDEDE"/>
<path d="M545.806 121.827C546.482 123.454 548.13 124.869 549.482 124.996C550.835 125.123 551.363 123.897 550.687 122.271C549.989 120.644 548.341 119.228 547.01 119.101C545.679 118.975 545.13 120.2 545.806 121.827Z" fill="#DEDEDE"/>
<path d="M538.031 121.151C538.686 122.778 540.334 124.193 541.686 124.299C543.039 124.405 543.588 123.179 542.912 121.552C542.236 119.925 540.609 118.51 539.257 118.404C537.926 118.298 537.376 119.524 538.031 121.151Z" fill="#DEDEDE"/>
<path d="M530.235 120.496C530.89 122.123 532.517 123.539 533.869 123.644C535.221 123.75 535.792 122.525 535.137 120.898C534.482 119.271 532.855 117.876 531.503 117.75C530.151 117.644 529.58 118.869 530.235 120.496Z" fill="#DEDEDE"/>
<path d="M522.418 119.862C523.052 121.489 524.658 122.904 526.031 123.01C527.383 123.116 527.975 121.89 527.341 120.263C526.707 118.636 525.08 117.242 523.728 117.136C522.376 117.009 521.784 118.235 522.418 119.862Z" fill="#DEDEDE"/>
<path d="M514.6 119.249C515.212 120.876 516.818 122.271 518.192 122.376C519.544 122.482 520.157 121.257 519.523 119.63C518.889 118.003 517.283 116.608 515.952 116.503C514.579 116.397 513.987 117.623 514.6 119.249Z" fill="#DEDEDE"/>
<path d="M506.761 118.658C507.353 120.285 508.958 121.679 510.311 121.785C511.684 121.89 512.297 120.644 511.684 119.038C511.071 117.411 509.487 116.017 508.134 115.911C506.782 115.805 506.169 117.031 506.761 118.658Z" fill="#DEDEDE"/>
<path d="M498.901 118.066C499.472 119.693 501.057 121.087 502.43 121.193C503.803 121.299 504.416 120.052 503.824 118.446C503.233 116.819 501.648 115.425 500.296 115.34C498.965 115.214 498.331 116.46 498.901 118.066Z" fill="#DEDEDE"/>
<path d="M491.041 117.517C491.612 119.143 493.175 120.538 494.549 120.622C495.922 120.728 496.556 119.482 495.985 117.855C495.415 116.228 493.83 114.854 492.478 114.749C491.126 114.664 490.471 115.89 491.041 117.517Z" fill="#DEDEDE"/>
<path d="M483.16 116.967C483.71 118.594 485.273 119.968 486.647 120.073C488.02 120.158 488.675 118.932 488.126 117.305C487.555 115.678 486.013 114.305 484.66 114.221C483.287 114.115 482.611 115.362 483.16 116.967Z" fill="#DEDEDE"/>
<path d="M475.28 116.46C475.808 118.066 477.35 119.46 478.724 119.545C480.097 119.629 480.773 118.404 480.224 116.777C479.675 115.15 478.132 113.777 476.78 113.692C475.428 113.608 474.752 114.833 475.28 116.46Z" fill="#DEDEDE"/>
<path d="M467.378 115.953C467.885 117.559 469.427 118.953 470.8 119.038C472.174 119.122 472.871 117.876 472.343 116.27C471.815 114.664 470.293 113.27 468.92 113.185C467.547 113.101 466.871 114.326 467.378 115.953Z" fill="#DEDEDE"/>
<path d="M459.475 115.467C459.961 117.073 461.482 118.467 462.877 118.552C464.25 118.636 464.968 117.39 464.461 115.784C463.954 114.178 462.433 112.805 461.059 112.72C459.686 112.615 458.968 113.861 459.475 115.467Z" fill="#DEDEDE"/>
<path d="M451.552 115.003C452.038 116.608 453.538 117.982 454.912 118.066C456.285 118.151 457.003 116.904 456.518 115.277C456.032 113.672 454.51 112.298 453.158 112.214C451.785 112.15 451.066 113.397 451.552 115.003Z" fill="#DEDEDE"/>
<path d="M443.608 114.559C444.073 116.165 445.552 117.538 446.946 117.622C448.319 117.707 449.059 116.46 448.594 114.834C448.129 113.228 446.629 111.854 445.256 111.77C443.882 111.707 443.143 112.953 443.608 114.559Z" fill="#DEDEDE"/>
<path d="M435.663 114.136C436.107 115.742 437.586 117.115 438.98 117.179C440.375 117.242 441.114 115.995 440.671 114.39C440.206 112.784 438.727 111.41 437.353 111.347C435.98 111.284 435.22 112.53 435.663 114.136Z" fill="#DEDEDE"/>
<path d="M427.72 113.735C428.143 115.34 429.622 116.714 430.995 116.777C432.39 116.841 433.15 115.594 432.707 113.988C432.263 112.382 430.805 111.009 429.432 110.946C428.058 110.882 427.298 112.129 427.72 113.735Z" fill="#DEDEDE"/>
<path d="M419.754 113.354C420.156 114.96 421.613 116.334 423.008 116.397C424.402 116.46 425.184 115.214 424.762 113.608C424.339 112.002 422.881 110.65 421.508 110.587C420.134 110.481 419.353 111.728 419.754 113.354Z" fill="#DEDEDE"/>
<path d="M411.788 112.974C412.168 114.58 413.626 115.932 414.999 115.995C416.394 116.059 417.197 114.812 416.795 113.185C416.394 111.579 414.957 110.227 413.584 110.164C412.189 110.122 411.387 111.368 411.788 112.974Z" fill="#DEDEDE"/>
<path d="M403.802 112.636C404.182 114.242 405.598 115.594 406.992 115.657C408.387 115.721 409.19 114.453 408.809 112.847C408.429 111.242 406.992 109.889 405.619 109.826C404.246 109.784 403.443 111.03 403.802 112.636Z" fill="#DEDEDE"/>
<path d="M395.816 112.298C396.175 113.904 397.59 115.256 398.985 115.319C400.379 115.383 401.203 114.115 400.844 112.509C400.485 110.904 399.069 109.551 397.696 109.509C396.302 109.446 395.456 110.692 395.816 112.298Z" fill="#DEDEDE"/>
<path d="M387.828 112.002C388.166 113.608 389.561 114.96 390.955 115.003C392.35 115.066 393.195 113.798 392.857 112.192C392.519 110.587 391.103 109.256 389.73 109.192C388.335 109.15 387.49 110.397 387.828 112.002Z" fill="#DEDEDE"/>
<path d="M379.821 111.706C380.138 113.312 381.533 114.664 382.927 114.707C384.322 114.749 385.188 113.481 384.85 111.876C384.512 110.27 383.138 108.939 381.744 108.875C380.371 108.854 379.504 110.101 379.821 111.706Z" fill="#DEDEDE"/>
<path d="M371.813 111.453C372.109 113.059 373.482 114.39 374.876 114.453C376.271 114.496 377.158 113.228 376.841 111.622C376.524 110.017 375.151 108.685 373.778 108.643C372.383 108.58 371.517 109.847 371.813 111.453Z" fill="#DEDEDE"/>
<path d="M363.785 111.199C364.059 112.805 365.412 114.136 366.827 114.178C368.222 114.22 369.13 112.953 368.834 111.347C368.539 109.741 367.186 108.41 365.792 108.368C364.397 108.347 363.51 109.593 363.785 111.199Z" fill="#DEDEDE"/>
<path d="M355.756 110.988C356.01 112.594 357.362 113.925 358.757 113.967C360.151 114.009 361.06 112.742 360.785 111.136C360.51 109.53 359.158 108.199 357.785 108.157C356.411 108.115 355.503 109.382 355.756 110.988Z" fill="#DEDEDE"/>
<path d="M347.727 110.777C347.959 112.383 349.291 113.714 350.706 113.735C352.101 113.777 353.03 112.488 352.777 110.883C352.523 109.277 351.192 107.946 349.798 107.925C348.424 107.903 347.495 109.171 347.727 110.777Z" fill="#DEDEDE"/>
<path d="M339.698 110.586C339.909 112.192 341.24 113.523 342.635 113.544C344.029 113.566 344.98 112.298 344.748 110.692C344.515 109.086 343.205 107.776 341.811 107.734C340.416 107.713 339.466 108.981 339.698 110.586Z" fill="#DEDEDE"/>
<path d="M331.648 110.417C331.86 112.023 333.148 113.333 334.564 113.375C335.958 113.397 336.93 112.129 336.698 110.523C336.487 108.917 335.177 107.607 333.782 107.586C332.409 107.544 331.458 108.833 331.648 110.417Z" fill="#DEDEDE"/>
<path d="M323.619 110.291C323.81 111.896 325.098 113.206 326.493 113.227C327.887 113.249 328.88 111.96 328.669 110.375C328.479 108.769 327.19 107.459 325.796 107.438C324.401 107.396 323.429 108.685 323.619 110.291Z" fill="#DEDEDE"/>
<path d="M315.547 110.164C315.716 111.77 316.984 113.08 318.4 113.101C319.794 113.122 320.787 111.833 320.618 110.227C320.428 108.622 319.16 107.312 317.787 107.291C316.392 107.269 315.399 108.558 315.547 110.164Z" fill="#DEDEDE"/>
<path d="M307.498 110.058C307.646 111.664 308.893 112.974 310.308 112.995C311.703 113.016 312.717 111.728 312.569 110.122C312.4 108.516 311.153 107.206 309.759 107.206C308.364 107.164 307.35 108.453 307.498 110.058Z" fill="#DEDEDE"/>
<path d="M299.448 109.974C299.575 111.58 300.822 112.89 302.216 112.89C303.611 112.911 304.646 111.622 304.498 110.016C304.35 108.41 303.103 107.121 301.73 107.1C300.336 107.079 299.321 108.368 299.448 109.974Z" fill="#DEDEDE"/>
<path d="M291.377 109.911C291.483 111.517 292.708 112.827 294.124 112.827C295.539 112.827 296.575 111.538 296.448 109.953C296.321 108.347 295.096 107.058 293.701 107.037C292.307 107.016 291.271 108.305 291.377 109.911Z" fill="#DEDEDE"/>
<path d="M283.306 109.868C283.391 111.474 284.616 112.784 286.01 112.784C287.426 112.784 288.483 111.495 288.377 109.889C288.271 108.283 287.067 106.995 285.672 106.973C284.278 106.973 283.221 108.262 283.306 109.868Z" fill="#DEDEDE"/>
<path d="M275.235 109.847C275.298 111.453 276.502 112.742 277.918 112.763C279.334 112.763 280.39 111.474 280.305 109.868C280.221 108.262 279.017 106.974 277.622 106.974C276.249 106.953 275.171 108.241 275.235 109.847Z" fill="#DEDEDE"/>
<path d="M267.163 109.847C267.206 111.453 268.389 112.742 269.804 112.742C271.22 112.742 272.298 111.432 272.234 109.847C272.171 108.241 270.988 106.952 269.593 106.952C268.199 106.952 267.121 108.241 267.163 109.847Z" fill="#DEDEDE"/>
<path d="M259.093 109.868C259.135 111.474 260.297 112.763 261.692 112.763C263.108 112.763 264.206 111.453 264.164 109.847C264.122 108.241 262.96 106.952 261.565 106.952C260.171 106.974 259.072 108.262 259.093 109.868Z" fill="#DEDEDE"/>
<path d="M251.022 109.91C251.043 111.516 252.184 112.805 253.599 112.805C255.015 112.805 256.135 111.495 256.093 109.889C256.072 108.284 254.909 106.995 253.515 107.016C252.121 107.016 251.001 108.305 251.022 109.91Z" fill="#DEDEDE"/>
<path d="M242.95 109.974C242.95 111.58 244.091 112.869 245.486 112.848C246.901 112.826 248.021 111.516 248.021 109.932C248.021 108.326 246.88 107.058 245.486 107.058C244.091 107.058 242.95 108.368 242.95 109.974Z" fill="#DEDEDE"/>
<path d="M234.858 110.058C234.837 111.664 235.957 112.953 237.373 112.932C238.788 112.911 239.929 111.601 239.95 109.995C239.95 108.389 238.83 107.121 237.436 107.121C236.041 107.142 234.9 108.452 234.858 110.058Z" fill="#DEDEDE"/>
<path d="M226.787 110.143C226.745 111.749 227.844 113.037 229.259 113.016C230.675 112.995 231.837 111.685 231.858 110.079C231.879 108.474 230.781 107.206 229.386 107.227C227.992 107.248 226.83 108.558 226.787 110.143Z" fill="#DEDEDE"/>
<path d="M202.553 110.586C202.447 112.192 203.504 113.46 204.919 113.439C206.335 113.417 207.539 112.086 207.624 110.481C207.708 108.875 206.652 107.607 205.257 107.628C203.863 107.67 202.658 108.98 202.553 110.586Z" fill="#DEDEDE"/>
<path d="M572.597 115.488C573.336 117.115 575.005 118.531 576.337 118.657C577.668 118.784 578.133 117.58 577.372 115.953C576.611 114.326 574.942 112.91 573.632 112.784C572.322 112.657 571.857 113.861 572.597 115.488Z" fill="#DEDEDE"/>
<path d="M564.97 114.749C565.688 116.376 567.357 117.791 568.688 117.918C570.02 118.045 570.506 116.84 569.766 115.214C569.027 113.587 567.357 112.171 566.047 112.044C564.716 111.918 564.252 113.122 564.97 114.749Z" fill="#DEDEDE"/>
<path d="M557.322 114.03C558.04 115.657 559.688 117.073 561.019 117.179C562.351 117.305 562.858 116.08 562.118 114.474C561.4 112.847 559.731 111.453 558.421 111.326C557.111 111.199 556.604 112.425 557.322 114.03Z" fill="#DEDEDE"/>
<path d="M549.653 113.334C550.35 114.96 551.998 116.355 553.329 116.482C554.66 116.609 555.167 115.383 554.47 113.777C553.773 112.15 552.125 110.756 550.793 110.629C549.462 110.502 548.955 111.728 549.653 113.334Z" fill="#DEDEDE"/>
<path d="M541.961 112.657C542.637 114.263 544.285 115.679 545.617 115.805C546.948 115.932 547.476 114.707 546.779 113.08C546.081 111.474 544.454 110.058 543.123 109.953C541.813 109.826 541.285 111.03 541.961 112.657Z" fill="#DEDEDE"/>
<path d="M534.27 112.002C534.925 113.608 536.552 115.023 537.904 115.129C539.235 115.235 539.785 114.03 539.108 112.403C538.432 110.798 536.805 109.382 535.474 109.276C534.164 109.171 533.615 110.375 534.27 112.002Z" fill="#DEDEDE"/>
<path d="M526.581 111.347C527.236 112.953 528.841 114.369 530.193 114.474C531.546 114.58 532.095 113.375 531.44 111.749C530.785 110.143 529.158 108.748 527.827 108.622C526.475 108.516 525.926 109.741 526.581 111.347Z" fill="#DEDEDE"/>
<path d="M518.847 110.713C519.481 112.319 521.086 113.713 522.418 113.819C523.77 113.925 524.34 112.699 523.685 111.093C523.051 109.488 521.446 108.093 520.115 107.988C518.783 107.903 518.213 109.107 518.847 110.713Z" fill="#DEDEDE"/>
<path d="M511.113 110.122C511.726 111.727 513.311 113.122 514.663 113.227C516.015 113.333 516.586 112.108 515.973 110.502C515.339 108.896 513.754 107.502 512.423 107.396C511.092 107.29 510.5 108.516 511.113 110.122Z" fill="#DEDEDE"/>
<path d="M503.379 109.53C503.971 111.136 505.556 112.53 506.908 112.636C508.26 112.742 508.852 111.516 508.239 109.91C507.626 108.305 506.042 106.91 504.711 106.826C503.38 106.699 502.767 107.924 503.379 109.53Z" fill="#DEDEDE"/>
<path d="M495.605 108.959C496.175 110.565 497.76 111.96 499.112 112.044C500.464 112.15 501.077 110.924 500.486 109.319C499.894 107.713 498.33 106.34 496.978 106.234C495.647 106.128 495.034 107.354 495.605 108.959Z" fill="#DEDEDE"/>
<path d="M487.852 108.41C488.423 110.016 489.965 111.389 491.317 111.495C492.669 111.601 493.303 110.354 492.733 108.748C492.162 107.143 490.599 105.769 489.268 105.664C487.916 105.579 487.282 106.804 487.852 108.41Z" fill="#DEDEDE"/>
<path d="M480.075 107.861C480.624 109.466 482.167 110.84 483.519 110.924C484.871 111.009 485.526 109.783 484.956 108.178C484.385 106.572 482.843 105.198 481.512 105.114C480.16 105.051 479.526 106.276 480.075 107.861Z" fill="#DEDEDE"/>
<path d="M472.28 107.354C472.808 108.96 474.35 110.333 475.703 110.418C477.055 110.502 477.731 109.277 477.182 107.671C476.632 106.065 475.111 104.692 473.759 104.607C472.407 104.523 471.752 105.748 472.28 107.354Z" fill="#DEDEDE"/>
<path d="M464.462 106.868C464.969 108.474 466.49 109.847 467.863 109.932C469.215 110.016 469.892 108.791 469.363 107.185C468.835 105.579 467.314 104.227 465.962 104.142C464.631 104.037 463.954 105.262 464.462 106.868Z" fill="#DEDEDE"/>
<path d="M456.666 106.382C457.152 107.988 458.673 109.34 460.025 109.425C461.377 109.509 462.075 108.284 461.568 106.678C461.06 105.072 459.56 103.72 458.208 103.636C456.856 103.551 456.159 104.777 456.666 106.382Z" fill="#DEDEDE"/>
<path d="M448.827 105.917C449.313 107.523 450.792 108.875 452.165 108.96C453.539 109.044 454.236 107.798 453.75 106.213C453.264 104.607 451.764 103.255 450.412 103.171C449.059 103.107 448.362 104.333 448.827 105.917Z" fill="#DEDEDE"/>
<path d="M441.01 105.494C441.474 107.1 442.953 108.452 444.306 108.537C445.679 108.621 446.397 107.375 445.911 105.769C445.447 104.163 443.968 102.811 442.615 102.748C441.263 102.663 440.545 103.888 441.01 105.494Z" fill="#DEDEDE"/>
<path d="M433.171 105.072C433.615 106.677 435.072 108.03 436.446 108.093C437.819 108.156 438.559 106.931 438.094 105.325C437.629 103.719 436.171 102.388 434.819 102.304C433.467 102.24 432.727 103.466 433.171 105.072Z" fill="#DEDEDE"/>
<path d="M425.311 104.671C425.733 106.255 427.191 107.608 428.564 107.692C429.938 107.755 430.677 106.53 430.255 104.924C429.811 103.318 428.374 101.987 427.001 101.924C425.649 101.839 424.888 103.086 425.311 104.671Z" fill="#DEDEDE"/>
<path d="M417.451 104.29C417.853 105.875 419.29 107.227 420.663 107.29C422.036 107.354 422.797 106.107 422.374 104.523C421.952 102.938 420.515 101.586 419.163 101.522C417.811 101.459 417.05 102.706 417.451 104.29Z" fill="#DEDEDE"/>
<path d="M409.592 103.931C409.972 105.516 411.409 106.868 412.782 106.932C414.155 106.995 414.937 105.748 414.536 104.164C414.134 102.579 412.697 101.227 411.345 101.163C409.972 101.1 409.19 102.347 409.592 103.931Z" fill="#DEDEDE"/>
<path d="M401.71 103.593C402.09 105.178 403.484 106.53 404.858 106.593C406.231 106.657 407.034 105.41 406.654 103.804C406.273 102.22 404.858 100.867 403.506 100.825C402.132 100.762 401.329 102.008 401.71 103.593Z" fill="#DEDEDE"/>
<path d="M393.829 103.276C394.189 104.861 395.583 106.213 396.956 106.255C398.33 106.318 399.154 105.072 398.773 103.466C398.414 101.881 396.999 100.55 395.646 100.487C394.294 100.445 393.47 101.67 393.829 103.276Z" fill="#DEDEDE"/>
<path d="M385.927 102.959C386.265 104.544 387.638 105.875 389.032 105.938C390.406 105.98 391.251 104.734 390.892 103.149C390.554 101.564 389.159 100.233 387.786 100.17C386.434 100.128 385.589 101.374 385.927 102.959Z" fill="#DEDEDE"/>
<path d="M378.026 102.684C378.343 104.269 379.716 105.6 381.09 105.663C382.463 105.706 383.308 104.459 382.991 102.874C382.653 101.29 381.28 99.9586 379.928 99.9164C378.554 99.853 377.709 101.1 378.026 102.684Z" fill="#DEDEDE"/>
<path d="M370.123 102.431C370.419 104.016 371.771 105.347 373.166 105.389C374.539 105.431 375.405 104.185 375.089 102.579C374.772 100.994 373.419 99.6632 372.046 99.6209C370.694 99.5787 369.828 100.825 370.123 102.431Z" fill="#DEDEDE"/>
<path d="M362.2 102.177C362.474 103.762 363.827 105.093 365.2 105.135C366.573 105.178 367.461 103.91 367.165 102.325C366.869 100.741 365.538 99.4096 364.165 99.3885C362.813 99.3462 361.925 100.593 362.2 102.177Z" fill="#DEDEDE"/>
<path d="M354.298 101.966C354.551 103.551 355.882 104.882 357.277 104.903C358.65 104.945 359.559 103.677 359.284 102.093C359.009 100.508 357.678 99.1981 356.305 99.1558C354.931 99.1136 354.023 100.381 354.298 101.966Z" fill="#DEDEDE"/>
<path d="M346.375 101.755C346.607 103.339 347.938 104.67 349.312 104.692C350.685 104.734 351.615 103.466 351.361 101.881C351.108 100.297 349.798 98.9869 348.424 98.9446C347.03 98.9235 346.121 100.17 346.375 101.755Z" fill="#DEDEDE"/>
<path d="M338.431 101.585C338.664 103.17 339.953 104.48 341.347 104.522C342.72 104.543 343.65 103.297 343.418 101.712C343.185 100.128 341.875 98.8176 340.502 98.7965C339.15 98.7331 338.22 100.001 338.431 101.585Z" fill="#DEDEDE"/>
<path d="M330.507 101.417C330.719 103.002 331.986 104.312 333.381 104.333C334.754 104.354 335.705 103.086 335.494 101.502C335.282 99.9171 333.994 98.6071 332.62 98.586C331.247 98.5648 330.296 99.8325 330.507 101.417Z" fill="#DEDEDE"/>
<path d="M322.563 101.269C322.753 102.854 324.021 104.164 325.394 104.185C326.789 104.206 327.739 102.938 327.549 101.353C327.359 99.7689 326.07 98.4589 324.718 98.4378C323.345 98.4166 322.373 99.6843 322.563 101.269Z" fill="#DEDEDE"/>
<path d="M314.619 101.142C314.788 102.727 316.034 104.037 317.429 104.058C318.823 104.079 319.795 102.811 319.605 101.226C319.415 99.6418 318.168 98.3529 316.795 98.3318C315.422 98.3107 314.45 99.5573 314.619 101.142Z" fill="#DEDEDE"/>
<path d="M306.674 101.058C306.822 102.643 308.069 103.953 309.442 103.953C310.837 103.974 311.83 102.685 311.661 101.1C311.492 99.5155 310.266 98.2267 308.893 98.2056C307.519 98.2056 306.526 99.4733 306.674 101.058Z" fill="#DEDEDE"/>
<path d="M298.709 100.973C298.836 102.558 300.061 103.847 301.456 103.868C302.85 103.889 303.843 102.6 303.716 101.015C303.568 99.4308 302.343 98.142 300.97 98.1208C299.596 98.1208 298.582 99.3886 298.709 100.973Z" fill="#DEDEDE"/>
<path d="M290.764 100.91C290.87 102.495 292.096 103.784 293.469 103.805C294.863 103.805 295.878 102.537 295.751 100.952C295.624 99.3677 294.42 98.0789 293.046 98.0789C291.673 98.0577 290.659 99.3255 290.764 100.91Z" fill="#DEDEDE"/>
<path d="M282.799 100.867C282.884 102.452 284.088 103.741 285.483 103.741C286.877 103.741 287.912 102.473 287.807 100.888C287.701 99.3037 286.497 98.0149 285.123 98.0149C283.75 98.0149 282.715 99.2826 282.799 100.867Z" fill="#DEDEDE"/>
<path d="M274.834 100.846C274.897 102.431 276.08 103.72 277.475 103.72C278.869 103.72 279.926 102.431 279.841 100.846C279.757 99.2617 278.574 97.9729 277.2 97.9729C275.827 97.994 274.77 99.2617 274.834 100.846Z" fill="#DEDEDE"/>
<path d="M266.868 100.847C266.911 102.431 268.094 103.72 269.467 103.72C270.862 103.72 271.918 102.431 271.855 100.847C271.791 99.2619 270.629 97.973 269.256 97.9942C267.904 97.973 266.826 99.2619 266.868 100.847Z" fill="#DEDEDE"/>
<path d="M258.902 100.868C258.944 102.452 260.085 103.741 261.48 103.741C262.874 103.741 263.952 102.452 263.91 100.868C263.867 99.2829 262.705 98.0151 261.332 98.0151C259.959 97.994 258.881 99.2829 258.902 100.868Z" fill="#DEDEDE"/>
<path d="M250.937 100.909C250.958 102.494 252.078 103.783 253.472 103.762C254.867 103.762 255.965 102.452 255.944 100.867C255.923 99.2826 254.782 98.0149 253.409 98.0149C252.035 98.036 250.916 99.3249 250.937 100.909Z" fill="#DEDEDE"/>
<path d="M242.972 100.973C242.972 102.558 244.092 103.826 245.486 103.826C246.881 103.804 248.001 102.516 247.98 100.931C247.98 99.3463 246.86 98.0786 245.465 98.0786C244.092 98.0997 242.972 99.3886 242.972 100.973Z" fill="#DEDEDE"/>
<path d="M235.006 101.057C234.985 102.642 236.083 103.91 237.478 103.91C238.872 103.889 240.013 102.6 240.013 101.015C240.034 99.4304 238.914 98.1627 237.541 98.1839C236.168 98.1627 235.027 99.4727 235.006 101.057Z" fill="#DEDEDE"/>
<path d="M227.019 101.142C226.977 102.727 228.076 103.995 229.449 103.973C230.843 103.952 231.984 102.642 232.027 101.079C232.048 99.4942 230.97 98.2264 229.597 98.2476C228.224 98.2687 227.083 99.5575 227.019 101.142Z" fill="#DEDEDE"/>
<path d="M219.054 101.269C218.991 102.853 220.069 104.121 221.463 104.1C222.858 104.079 224.02 102.769 224.062 101.184C224.104 99.5994 223.027 98.3317 221.653 98.3528C220.28 98.3951 219.118 99.6839 219.054 101.269Z" fill="#DEDEDE"/>
<path d="M211.089 101.417C211.005 103.001 212.061 104.269 213.456 104.248C214.85 104.227 216.033 102.917 216.097 101.332C216.16 99.7475 215.104 98.4798 213.73 98.522C212.357 98.522 211.174 99.832 211.089 101.417Z" fill="#DEDEDE"/>
<path d="M203.123 101.586C203.018 103.17 204.074 104.438 205.448 104.417C206.842 104.396 208.025 103.086 208.131 101.501C208.215 99.9167 207.18 98.649 205.807 98.6912C204.412 98.6912 203.208 100.001 203.123 101.586Z" fill="#DEDEDE"/>
<path d="M187.171 101.966C187.023 103.551 188.037 104.819 189.432 104.776C190.826 104.734 192.052 103.424 192.179 101.839C192.305 100.255 191.291 99.0082 189.918 99.0293C188.544 99.0716 187.319 100.382 187.171 101.966Z" fill="#DEDEDE"/>
<path d="M568.414 106.361C569.154 107.966 570.823 109.382 572.133 109.509C573.443 109.636 573.908 108.431 573.147 106.825C572.386 105.22 570.738 103.804 569.428 103.677C568.118 103.55 567.675 104.734 568.414 106.361Z" fill="#DEDEDE"/>
<path d="M560.872 105.621C561.59 107.227 563.259 108.643 564.569 108.769C565.879 108.896 566.365 107.692 565.625 106.086C564.886 104.48 563.238 103.086 561.928 102.959C560.597 102.832 560.132 104.015 560.872 105.621Z" fill="#DEDEDE"/>
<path d="M553.286 104.924C554.004 106.53 555.652 107.924 556.962 108.051C558.272 108.178 558.758 106.973 558.04 105.368C557.321 103.762 555.673 102.367 554.364 102.241C553.075 102.114 552.589 103.318 553.286 104.924Z" fill="#DEDEDE"/>
<path d="M545.723 104.227C546.42 105.832 548.047 107.227 549.378 107.354C550.688 107.48 551.195 106.276 550.498 104.67C549.801 103.065 548.152 101.67 546.842 101.564C545.511 101.438 545.025 102.621 545.723 104.227Z" fill="#DEDEDE"/>
<path d="M538.137 103.572C538.813 105.178 540.44 106.572 541.771 106.678C543.102 106.783 543.61 105.579 542.912 103.994C542.215 102.389 540.609 100.994 539.278 100.889C537.968 100.762 537.44 101.966 538.137 103.572Z" fill="#DEDEDE"/>
<path d="M530.53 102.917C531.185 104.522 532.812 105.917 534.122 106.023C535.453 106.128 535.981 104.924 535.305 103.318C534.629 101.712 533.023 100.339 531.713 100.212C530.382 100.107 529.854 101.311 530.53 102.917Z" fill="#DEDEDE"/>
<path d="M522.903 102.283C523.558 103.889 525.143 105.262 526.474 105.389C527.805 105.495 528.333 104.29 527.678 102.684C527.023 101.079 525.418 99.7054 524.108 99.5997C522.798 99.473 522.269 100.677 522.903 102.283Z" fill="#DEDEDE"/>
<path d="M515.276 101.649C515.91 103.255 517.494 104.628 518.826 104.734C520.157 104.839 520.706 103.635 520.072 102.029C519.438 100.424 517.833 99.0503 516.523 98.9446C515.213 98.8601 514.642 100.064 515.276 101.649Z" fill="#DEDEDE"/>
<path d="M507.649 101.057C508.262 102.642 509.846 104.036 511.178 104.142C512.509 104.248 513.079 103.043 512.445 101.438C511.811 99.8319 510.248 98.4585 508.917 98.374C507.586 98.2472 507.015 99.4727 507.649 101.057Z" fill="#DEDEDE"/>
<path d="M499.979 100.487C500.57 102.072 502.134 103.466 503.486 103.551C504.817 103.657 505.409 102.431 504.796 100.846C504.183 99.2618 502.62 97.8885 501.31 97.7828C499.979 97.6772 499.387 98.8815 499.979 100.487Z" fill="#DEDEDE"/>
<path d="M492.331 99.9166C492.922 101.501 494.465 102.875 495.796 102.98C497.127 103.086 497.74 101.86 497.148 100.276C496.556 98.6912 494.993 97.3178 493.683 97.2122C492.352 97.1065 491.739 98.332 492.331 99.9166Z" fill="#DEDEDE"/>
<path d="M484.662 99.3676C485.232 100.952 486.774 102.326 488.106 102.41C489.437 102.495 490.049 101.29 489.479 99.7056C488.908 98.121 487.366 96.7476 486.035 96.6631C484.704 96.5786 484.091 97.7829 484.662 99.3676Z" fill="#DEDEDE"/>
<path d="M476.97 98.839C477.519 100.424 479.04 101.797 480.393 101.882C481.724 101.966 482.358 100.762 481.808 99.156C481.238 97.5713 479.717 96.2191 478.385 96.1135C477.054 96.0501 476.42 97.2544 476.97 98.839Z" fill="#DEDEDE"/>
<path d="M469.279 98.3324C469.807 99.9171 471.328 101.29 472.659 101.375C473.99 101.459 474.645 100.234 474.096 98.6493C473.547 97.0647 472.025 95.7125 470.715 95.628C469.384 95.5435 468.751 96.7478 469.279 98.3324Z" fill="#DEDEDE"/>
<path d="M461.568 97.8459C462.075 99.4305 463.596 100.783 464.927 100.867C466.28 100.952 466.934 99.7263 466.406 98.1417C465.878 96.5571 464.378 95.2048 463.047 95.1203C461.716 95.0569 461.061 96.2613 461.568 97.8459Z" fill="#DEDEDE"/>
<path d="M453.856 97.3813C454.342 98.9659 455.842 100.318 457.194 100.403C458.546 100.487 459.223 99.2617 458.715 97.6771C458.208 96.0925 456.708 94.7402 455.377 94.6557C454.046 94.5923 453.349 95.7967 453.856 97.3813Z" fill="#DEDEDE"/>
<path d="M446.123 96.9372C446.608 98.5219 448.087 99.8741 449.419 99.9375C450.771 100.022 451.447 98.7965 450.961 97.2119C450.475 95.6272 448.996 94.2962 447.665 94.2116C446.334 94.1271 445.658 95.3526 446.123 96.9372Z" fill="#DEDEDE"/>
<path d="M438.39 96.494C438.854 98.0787 440.312 99.4309 441.665 99.4943C443.017 99.5788 443.714 98.3322 443.249 96.7687C442.784 95.1841 441.305 93.853 439.974 93.7685C438.643 93.7051 437.925 94.9305 438.39 96.494Z" fill="#DEDEDE"/>
<path d="M430.656 96.0925C431.1 97.6771 432.557 99.0082 433.91 99.0928C435.262 99.1561 435.98 97.9307 435.537 96.346C435.072 94.7614 433.635 93.4303 432.283 93.3669C430.931 93.2824 430.212 94.5079 430.656 96.0925Z" fill="#DEDEDE"/>
<path d="M422.903 95.6911C423.325 97.2758 424.762 98.6069 426.114 98.6702C427.466 98.7336 428.206 97.5082 427.762 95.9235C427.319 94.3389 425.882 93.0078 424.551 92.9444C423.22 92.9022 422.48 94.1276 422.903 95.6911Z" fill="#DEDEDE"/>
<path d="M415.149 95.3318C415.55 96.9165 416.987 98.2476 418.339 98.3109C419.692 98.3743 420.452 97.1489 420.03 95.5642C419.607 93.9796 418.192 92.6485 416.839 92.5851C415.487 92.5217 414.748 93.7472 415.149 95.3318Z" fill="#DEDEDE"/>
<path d="M407.373 94.9726C407.753 96.5573 409.169 97.8884 410.521 97.9517C411.873 98.0151 412.655 96.7897 412.253 95.205C411.852 93.6204 410.436 92.3104 409.105 92.247C407.753 92.1625 406.992 93.388 407.373 94.9726Z" fill="#DEDEDE"/>
<path d="M399.598 94.6343C399.978 96.2189 401.373 97.5289 402.725 97.5923C404.077 97.6556 404.859 96.4091 404.479 94.8456C404.098 93.2609 402.704 91.951 401.352 91.8876C400.021 91.8242 399.239 93.0708 399.598 94.6343Z" fill="#DEDEDE"/>
<path d="M391.822 94.3176C392.181 95.8811 393.555 97.2122 394.907 97.2756C396.259 97.339 397.062 96.0924 396.703 94.5078C396.344 92.9231 394.949 91.6132 393.618 91.5709C392.266 91.5075 391.463 92.7541 391.822 94.3176Z" fill="#DEDEDE"/>
<path d="M384.026 94.0218C384.364 95.5853 385.737 96.9164 387.089 96.9587C388.441 97.0009 389.265 95.7755 388.927 94.1908C388.589 92.6062 387.216 91.2962 385.864 91.254C384.511 91.2117 383.709 92.4583 384.026 94.0218Z" fill="#DEDEDE"/>
<path d="M376.229 93.7469C376.546 95.3104 377.898 96.6415 379.271 96.6838C380.623 96.726 381.469 95.5006 381.131 93.9159C380.792 92.3524 379.44 91.0425 378.109 90.9791C376.757 90.9368 375.912 92.1834 376.229 93.7469Z" fill="#DEDEDE"/>
<path d="M368.433 93.4934C368.728 95.057 370.081 96.3881 371.433 96.4303C372.785 96.4726 373.651 95.226 373.334 93.6625C373.017 92.099 371.686 90.789 370.334 90.7467C368.982 90.6834 368.137 91.9088 368.433 93.4934Z" fill="#DEDEDE"/>
<path d="M360.636 93.2608C360.911 94.8243 362.242 96.1343 363.615 96.1766C364.967 96.2188 365.834 94.9722 365.559 93.4087C365.263 91.8452 363.932 90.5353 362.601 90.493C361.207 90.4507 360.361 91.6762 360.636 93.2608Z" fill="#DEDEDE"/>
<path d="M352.818 93.0285C353.071 94.592 354.403 95.902 355.755 95.9442C357.107 95.9865 357.994 94.7399 357.72 93.1553C357.445 91.5918 356.135 90.2818 354.783 90.2395C353.431 90.2184 352.564 91.465 352.818 93.0285Z" fill="#DEDEDE"/>
<path d="M345.001 92.8385C345.233 94.402 346.543 95.7119 347.896 95.7542C349.269 95.7965 350.156 94.5287 349.903 92.9652C349.649 91.4017 348.339 90.0918 347.008 90.0706C345.656 90.0284 344.747 91.2749 345.001 92.8385Z" fill="#DEDEDE"/>
<path d="M337.162 92.6484C337.395 94.2119 338.662 95.5218 340.036 95.543C341.409 95.5641 342.318 94.3175 342.085 92.754C341.853 91.1905 340.564 89.9017 339.212 89.8594C337.86 89.8383 336.951 91.0848 337.162 92.6484Z" fill="#DEDEDE"/>
<path d="M329.345 92.5004C329.557 94.0639 330.824 95.3739 332.177 95.395C333.55 95.4162 334.48 94.1696 334.247 92.6061C334.036 91.0425 332.768 89.7537 331.416 89.7114C330.064 89.6903 329.134 90.9369 329.345 92.5004Z" fill="#DEDEDE"/>
<path d="M321.506 92.3527C321.696 93.9162 322.942 95.2262 324.316 95.2473C325.689 95.2684 326.619 94.0219 326.429 92.4372C326.238 90.8737 324.971 89.5849 323.619 89.5637C322.266 89.5426 321.316 90.7892 321.506 92.3527Z" fill="#DEDEDE"/>
<path d="M313.668 92.2471C313.837 93.8106 315.083 95.0995 316.435 95.1206C317.809 95.1417 318.759 93.874 318.59 92.3105C318.421 90.747 317.175 89.4582 315.823 89.437C314.47 89.4159 313.499 90.6625 313.668 92.2471Z" fill="#DEDEDE"/>
<path d="M305.83 92.1412C305.977 93.7047 307.203 94.9935 308.576 95.0146C309.95 95.0358 310.922 93.7681 310.753 92.2046C310.584 90.641 309.358 89.3522 308.006 89.3311C306.675 89.3099 305.682 90.5777 305.83 92.1412Z" fill="#DEDEDE"/>
<path d="M297.99 92.0568C298.117 93.6203 299.321 94.9092 300.695 94.9303C302.068 94.9514 303.061 93.6837 302.913 92.1202C302.765 90.5567 301.561 89.2678 300.209 89.2678C298.857 89.2467 297.864 90.4933 297.99 92.0568Z" fill="#DEDEDE"/>
<path d="M290.131 91.9936C290.237 93.5571 291.441 94.8459 292.814 94.8459C294.188 94.8459 295.202 93.5993 295.075 92.0147C294.948 90.4512 293.765 89.1835 292.392 89.1624C291.04 89.1835 290.025 90.4301 290.131 91.9936Z" fill="#DEDEDE"/>
<path d="M282.292 91.9513C282.376 93.5149 283.559 94.8037 284.933 94.8037C286.306 94.8037 287.32 93.536 287.214 91.9725C287.109 90.409 285.926 89.1412 284.573 89.1201C283.221 89.1412 282.207 90.3878 282.292 91.9513Z" fill="#DEDEDE"/>
<path d="M274.433 91.951C274.496 93.5145 275.679 94.8033 277.032 94.8033C278.405 94.8033 279.44 93.5356 279.356 91.9721C279.271 90.4086 278.109 89.1409 276.757 89.1409C275.405 89.1198 274.37 90.3663 274.433 91.951Z" fill="#DEDEDE"/>
<path d="M266.572 91.9512C266.614 93.5147 267.776 94.7825 269.149 94.7825C270.523 94.7825 271.579 93.5147 271.516 91.9301C271.452 90.3666 270.29 89.0989 268.938 89.0989C267.586 89.0989 266.529 90.3666 266.572 91.9512Z" fill="#DEDEDE"/>
<path d="M258.713 91.9725C258.734 93.536 259.875 94.8037 261.248 94.8037C262.622 94.8037 263.699 93.5149 263.636 91.9513C263.593 90.3878 262.452 89.1201 261.1 89.1201C259.769 89.1201 258.692 90.3878 258.713 91.9725Z" fill="#DEDEDE"/>
<path d="M250.852 91.9933C250.873 93.5569 251.993 94.8246 253.366 94.8246C254.74 94.8246 255.817 93.5357 255.796 91.9722C255.775 90.4087 254.655 89.141 253.282 89.1621C251.93 89.1621 250.852 90.4298 250.852 91.9933Z" fill="#DEDEDE"/>
<path d="M242.993 92.0568C242.993 93.6203 244.091 94.888 245.465 94.888C246.838 94.8669 247.937 93.5992 247.937 92.0357C247.937 90.4722 246.817 89.2045 245.465 89.2256C244.113 89.2256 243.014 90.4933 242.993 92.0568Z" fill="#DEDEDE"/>
<path d="M203.694 92.6695C203.588 94.233 204.624 95.4796 205.997 95.4585C207.37 95.4374 208.553 94.1274 208.638 92.5639C208.723 91.0004 207.687 89.7538 206.335 89.7749C204.962 89.7961 203.778 91.106 203.694 92.6695Z" fill="#DEDEDE"/>
<path d="M195.813 92.8386C195.686 94.4021 196.7 95.6487 198.074 95.6276C199.447 95.5853 200.651 94.2965 200.757 92.733C200.863 91.1695 199.848 89.9229 198.496 89.9652C197.144 89.9863 195.94 91.2751 195.813 92.8386Z" fill="#DEDEDE"/>
<path d="M571.667 98.036C572.428 99.6418 574.097 101.036 575.386 101.163C576.675 101.29 577.118 100.107 576.336 98.5008C575.576 96.8951 573.907 95.5006 572.618 95.3738C571.35 95.247 570.928 96.4302 571.667 98.036Z" fill="#DEDEDE"/>
<path d="M564.232 97.2966C564.971 98.9024 566.619 100.297 567.929 100.424C569.239 100.55 569.662 99.3461 568.922 97.7614C568.162 96.1557 566.514 94.7823 565.225 94.6556C563.936 94.5077 563.492 95.6908 564.232 97.2966Z" fill="#DEDEDE"/>
<path d="M556.752 96.5782C557.47 98.1628 559.118 99.5573 560.428 99.6841C561.738 99.8108 562.182 98.6065 561.442 97.0219C560.703 95.4372 559.055 94.0428 557.766 93.916C556.498 93.8103 556.033 94.9935 556.752 96.5782Z" fill="#DEDEDE"/>
<path d="M549.272 95.8814C549.99 97.466 551.617 98.8605 552.927 98.9873C554.237 99.1141 554.702 97.9097 553.983 96.3251C553.265 94.7405 551.638 93.346 550.349 93.2192C549.039 93.1136 548.574 94.2968 549.272 95.8814Z" fill="#DEDEDE"/>
<path d="M541.793 95.2051C542.491 96.7898 544.117 98.1843 545.406 98.2899C546.716 98.4167 547.202 97.2123 546.484 95.6277C545.787 94.0431 544.16 92.6486 542.871 92.5429C541.582 92.4373 541.096 93.6205 541.793 95.2051Z" fill="#DEDEDE"/>
<path d="M534.292 94.5499C534.968 96.1345 536.574 97.529 537.884 97.6346C539.194 97.7403 539.701 96.5571 539.003 94.9724C538.306 93.3878 536.7 92.0144 535.412 91.8877C534.102 91.782 533.616 92.9652 534.292 94.5499Z" fill="#DEDEDE"/>
<path d="M526.77 93.9162C527.425 95.5009 529.031 96.8742 530.341 97.001C531.651 97.1066 532.158 95.9234 531.482 94.3177C530.806 92.733 529.2 91.3597 527.911 91.254C526.623 91.1273 526.115 92.3316 526.77 93.9162Z" fill="#DEDEDE"/>
<path d="M519.248 93.2823C519.903 94.8669 521.488 96.2403 522.798 96.3459C524.108 96.4516 524.636 95.2473 523.981 93.6626C523.326 92.078 521.742 90.7046 520.432 90.599C519.122 90.5145 518.593 91.6977 519.248 93.2823Z" fill="#DEDEDE"/>
<path d="M511.706 92.6696C512.34 94.2543 513.903 95.6276 515.234 95.7333C516.544 95.8389 517.094 94.6346 516.439 93.0499C515.805 91.4653 514.22 90.1131 512.91 90.0074C511.621 89.9018 511.072 91.1061 511.706 92.6696Z" fill="#DEDEDE"/>
<path d="M504.162 92.0777C504.775 93.6623 506.339 95.0145 507.649 95.1202C508.959 95.2258 509.529 94.0215 508.895 92.4368C508.261 90.8522 506.698 89.5 505.409 89.3943C504.099 89.3098 503.55 90.5141 504.162 92.0777Z" fill="#DEDEDE"/>
<path d="M496.599 91.5075C497.191 93.0922 498.754 94.4444 500.064 94.55C501.374 94.6557 501.966 93.4513 501.353 91.8667C500.74 90.2821 499.198 88.9298 497.888 88.8453C496.578 88.7397 496.008 89.944 496.599 91.5075Z" fill="#DEDEDE"/>
<path d="M489.035 90.958C489.605 92.5427 491.147 93.8949 492.479 93.9794C493.788 94.085 494.38 92.8807 493.789 91.2961C493.197 89.7114 491.655 88.3592 490.345 88.2747C489.035 88.1902 488.464 89.3945 489.035 90.958Z" fill="#DEDEDE"/>
<path d="M481.449 90.4297C482.02 92.0143 483.541 93.3666 484.872 93.4511C486.203 93.5356 486.795 92.3313 486.224 90.7678C485.654 89.1831 484.112 87.852 482.802 87.7464C481.492 87.6619 480.9 88.8662 481.449 90.4297Z" fill="#DEDEDE"/>
<path d="M473.864 89.9231C474.413 91.4866 475.935 92.8388 477.245 92.9444C478.576 93.029 479.188 91.8246 478.618 90.2611C478.069 88.6976 476.547 87.3454 475.237 87.2609C473.949 87.1341 473.315 88.3384 473.864 89.9231Z" fill="#DEDEDE"/>
<path d="M466.279 89.4155C466.808 90.9791 468.308 92.3313 469.639 92.4158C470.97 92.5003 471.604 91.296 471.055 89.7325C470.505 88.169 469.005 86.8167 467.695 86.7322C466.385 86.6477 465.73 87.852 466.279 89.4155Z" fill="#DEDEDE"/>
<path d="M458.672 88.9297C459.179 90.4932 460.679 91.8454 462.011 91.9299C463.342 92.0145 463.976 90.8101 463.468 89.2255C462.94 87.662 461.461 86.3098 460.13 86.2464C458.799 86.1619 458.144 87.3662 458.672 88.9297Z" fill="#DEDEDE"/>
<path d="M451.046 88.486C451.531 90.0495 453.01 91.4017 454.342 91.4651C455.673 91.5496 456.328 90.3242 455.821 88.7607C455.314 87.1971 453.835 85.866 452.525 85.7815C451.215 85.697 450.56 86.9014 451.046 88.486Z" fill="#DEDEDE"/>
<path d="M443.418 88.0426C443.904 89.6061 445.362 90.9372 446.693 91.0218C448.024 91.1063 448.7 89.8808 448.214 88.3173C447.728 86.7538 446.249 85.4227 444.939 85.3382C443.629 85.2537 442.953 86.458 443.418 88.0426Z" fill="#DEDEDE"/>
<path d="M435.79 87.6199C436.255 89.1834 437.692 90.5145 439.023 90.599C440.354 90.6624 441.051 89.458 440.565 87.8945C440.1 86.331 438.643 84.9999 437.333 84.9365C436.023 84.8309 435.325 86.0563 435.79 87.6199Z" fill="#DEDEDE"/>
<path d="M428.142 87.1971C428.586 88.7606 430.022 90.0917 431.354 90.1551C432.685 90.2184 433.403 89.0141 432.938 87.4506C432.473 85.8871 431.037 84.556 429.727 84.4926C428.417 84.4292 427.698 85.6336 428.142 87.1971Z" fill="#DEDEDE"/>
<path d="M420.493 86.8169C420.916 88.3804 422.352 89.7115 423.683 89.7749C425.015 89.8383 425.733 88.634 425.31 87.0704C424.867 85.5069 423.451 84.197 422.12 84.1336C420.81 84.0491 420.071 85.2534 420.493 86.8169Z" fill="#DEDEDE"/>
<path d="M412.846 86.4576C413.247 88.0211 414.663 89.3311 415.994 89.3945C417.325 89.4579 418.064 88.2324 417.642 86.6689C417.219 85.1054 415.804 83.7954 414.494 83.732C413.184 83.6898 412.444 84.8941 412.846 86.4576Z" fill="#DEDEDE"/>
<path d="M405.176 86.0985C405.556 87.662 406.972 88.9719 408.303 89.0353C409.634 89.0987 410.394 87.8733 409.993 86.3098C409.592 84.7462 408.197 83.4363 406.866 83.3729C405.556 83.3306 404.795 84.5561 405.176 86.0985Z" fill="#DEDEDE"/>
<path d="M397.505 85.7818C397.865 87.3453 399.259 88.6553 400.59 88.7187C401.921 88.782 402.703 87.5566 402.323 85.9931C401.942 84.4296 400.548 83.1196 399.238 83.0773C397.907 83.014 397.125 84.2183 397.505 85.7818Z" fill="#DEDEDE"/>
<path d="M389.836 85.4647C390.195 87.0282 391.569 88.3382 392.9 88.3804C394.231 88.4438 395.034 87.2184 394.654 85.6549C394.294 84.0913 392.921 82.8025 391.59 82.7391C390.259 82.6969 389.477 83.9012 389.836 85.4647Z" fill="#DEDEDE"/>
<path d="M382.145 85.1687C382.483 86.7322 383.835 88.0422 385.166 88.0845C386.497 88.1267 387.3 86.9013 386.962 85.3589C386.624 83.7954 385.251 82.5065 383.941 82.4643C382.609 82.4009 381.807 83.6263 382.145 85.1687Z" fill="#DEDEDE"/>
<path d="M374.455 84.8941C374.771 86.4576 376.103 87.7465 377.455 87.8099C378.786 87.8521 379.61 86.6267 379.293 85.0631C378.955 83.4996 377.624 82.2108 376.293 82.1685C374.962 82.1263 374.138 83.3517 374.455 84.8941Z" fill="#DEDEDE"/>
<path d="M366.764 84.6621C367.06 86.2256 368.391 87.5144 369.722 87.5567C371.074 87.5989 371.898 86.3735 371.581 84.81C371.264 83.2465 369.933 81.9576 368.623 81.9154C367.292 81.8731 366.447 83.0986 366.764 84.6621Z" fill="#DEDEDE"/>
<path d="M359.051 84.4085C359.325 85.9509 360.635 87.2609 361.988 87.3031C363.34 87.3454 364.185 86.1199 363.889 84.5564C363.593 82.9929 362.283 81.7041 360.952 81.6829C359.621 81.6407 358.776 82.8661 359.051 84.4085Z" fill="#DEDEDE"/>
<path d="M351.34 84.1969C351.593 85.7604 352.903 87.0493 354.235 87.0704C355.587 87.1127 356.453 85.8661 356.178 84.3237C355.904 82.7602 354.615 81.4925 353.284 81.4502C351.953 81.4291 351.086 82.6545 351.34 84.1969Z" fill="#DEDEDE"/>
<path d="M343.627 84.0067C343.859 85.5491 345.148 86.838 346.5 86.8802C347.853 86.9225 348.719 85.6759 348.465 84.1335C348.212 82.57 346.923 81.3023 345.592 81.26C344.282 81.2389 343.394 82.4644 343.627 84.0067Z" fill="#DEDEDE"/>
<path d="M335.916 83.8379C336.149 85.3802 337.416 86.6691 338.747 86.7113C340.1 86.7325 340.987 85.507 340.755 83.9435C340.522 82.4011 339.254 81.1123 337.923 81.0911C336.592 81.0489 335.684 82.2743 335.916 83.8379Z" fill="#DEDEDE"/>
<path d="M328.183 83.6692C328.394 85.2116 329.641 86.5004 330.993 86.5216C332.345 86.5427 333.254 85.3172 333.042 83.7537C332.831 82.2113 331.563 80.9225 330.232 80.9014C328.901 80.9014 327.993 82.1268 328.183 83.6692Z" fill="#DEDEDE"/>
<path d="M320.471 83.542C320.661 85.0844 321.887 86.3732 323.239 86.3944C324.591 86.4155 325.521 85.1689 325.309 83.6265C325.119 82.0842 323.873 80.7953 322.542 80.7742C321.21 80.7531 320.281 81.9996 320.471 83.542Z" fill="#DEDEDE"/>
<path d="M312.738 83.4365C312.907 84.9789 314.133 86.2677 315.485 86.2888C316.837 86.31 317.788 85.0634 317.598 83.521C317.408 81.9786 316.182 80.7109 314.851 80.6898C313.52 80.6475 312.569 81.873 312.738 83.4365Z" fill="#DEDEDE"/>
<path d="M305.005 83.3305C305.153 84.8729 306.357 86.1617 307.709 86.1617C309.062 86.1828 310.012 84.9362 309.864 83.3727C309.695 81.8303 308.491 80.5626 307.16 80.5415C305.808 80.5415 304.857 81.7881 305.005 83.3305Z" fill="#DEDEDE"/>
<path d="M297.271 83.246C297.398 84.7884 298.602 86.0561 299.955 86.0772C301.307 86.0983 302.279 84.8306 302.152 83.2882C302.004 81.7459 300.821 80.4782 299.49 80.457C298.116 80.457 297.145 81.7036 297.271 83.246Z" fill="#DEDEDE"/>
<path d="M289.518 83.1829C289.624 84.7253 290.807 85.993 292.16 86.0141C293.512 86.0141 294.505 84.7675 294.378 83.2251C294.251 81.6828 293.068 80.4151 291.737 80.4151C290.406 80.3939 289.413 81.6405 289.518 83.1829Z" fill="#DEDEDE"/>
<path d="M281.784 83.1618C281.869 84.7042 283.031 85.9719 284.383 85.9719C285.735 85.9719 286.75 84.7253 286.644 83.1829C286.538 81.6405 285.376 80.3728 284.045 80.3728C282.693 80.3517 281.679 81.5983 281.784 83.1618Z" fill="#DEDEDE"/>
<path d="M274.03 83.1409C274.093 84.6833 275.255 85.951 276.607 85.951C277.96 85.951 278.995 84.7044 278.91 83.1409C278.826 81.5985 277.685 80.3308 276.333 80.3308C274.98 80.3308 273.966 81.5774 274.03 83.1409Z" fill="#DEDEDE"/>
<path d="M266.277 83.1405C266.319 84.6829 267.46 85.9506 268.812 85.9506C270.164 85.9506 271.2 84.6829 271.136 83.1405C271.073 81.5982 269.932 80.3516 268.601 80.3516C267.27 80.3305 266.234 81.577 266.277 83.1405Z" fill="#DEDEDE"/>
<path d="M196.489 84.0283C196.363 85.5707 197.356 86.8173 198.708 86.775C200.06 86.7539 201.243 85.465 201.349 83.9015C201.455 82.3591 200.462 81.1337 199.13 81.1548C197.799 81.1971 196.616 82.4859 196.489 84.0283Z" fill="#DEDEDE"/>
<path d="M188.735 84.2182C188.587 85.7606 189.58 87.0072 190.932 86.9649C192.284 86.9227 193.489 85.6338 193.615 84.0915C193.742 82.5491 192.749 81.3236 191.418 81.3448C190.087 81.387 188.883 82.6759 188.735 84.2182Z" fill="#DEDEDE"/>
<path d="M49.6249 91.1061C49.1601 92.6696 49.8574 93.8739 51.1885 93.7894C52.5196 93.6837 53.9774 92.3315 54.4422 90.768C54.9071 89.2045 54.1887 88.0002 52.8576 88.1058C51.5476 88.1903 50.1109 89.5426 49.6249 91.1061Z" fill="#DEDEDE"/>
<path d="M41.9556 91.6763C41.4697 93.2398 42.1669 94.4441 43.498 94.3385C44.8291 94.2328 46.3081 92.8806 46.7729 91.3171C47.2377 89.7536 46.5616 88.5492 45.2305 88.6549C43.8994 88.7394 42.4416 90.0916 41.9556 91.6763Z" fill="#DEDEDE"/>
<path d="M574.752 89.7754C575.513 91.36 577.182 92.7545 578.471 92.8813C579.738 93.008 580.161 91.8248 579.379 90.2402C578.598 88.6556 576.928 87.2611 575.661 87.1343C574.393 87.0075 573.992 88.1907 574.752 89.7754Z" fill="#DEDEDE"/>
<path d="M567.4 89.0355C568.161 90.6201 569.809 92.0146 571.097 92.1414C572.386 92.2682 572.788 91.085 572.027 89.5003C571.267 87.9157 569.618 86.5212 568.351 86.4156C567.062 86.2677 566.639 87.4509 567.4 89.0355Z" fill="#DEDEDE"/>
<path d="M560.026 88.3174C560.765 89.902 562.413 91.2754 563.681 91.4022C564.97 91.5289 565.393 90.3457 564.653 88.7611C563.892 87.1765 562.266 85.8031 560.998 85.6763C559.73 85.5707 559.286 86.7328 560.026 88.3174Z" fill="#DEDEDE"/>
<path d="M552.653 87.6201C553.371 89.2047 555.019 90.5781 556.287 90.7049C557.576 90.8316 558.02 89.6484 557.28 88.0638C556.541 86.4792 554.914 85.1058 553.646 84.979C552.378 84.8523 551.934 86.0355 552.653 87.6201Z" fill="#DEDEDE"/>
<path d="M545.257 86.9436C545.975 88.5282 547.581 89.9016 548.87 90.0072C550.159 90.134 550.623 88.9297 549.905 87.3661C549.187 85.7815 547.56 84.4082 546.292 84.3025C545.003 84.1757 544.56 85.3589 545.257 86.9436Z" fill="#DEDEDE"/>
<path d="M537.863 86.2677C538.56 87.8313 540.166 89.2046 541.454 89.3314C542.743 89.437 543.208 88.2538 542.511 86.6903C541.814 85.1057 540.208 83.7534 538.919 83.6267C537.63 83.521 537.165 84.7042 537.863 86.2677Z" fill="#DEDEDE"/>
<path d="M530.447 85.6129C531.123 87.1764 532.729 88.5498 534.018 88.6765C535.307 88.7822 535.793 87.599 535.117 86.0355C534.419 84.472 532.835 83.0986 531.546 82.993C530.257 82.8662 529.771 84.0494 530.447 85.6129Z" fill="#DEDEDE"/>
<path d="M523.031 85C523.686 86.5635 525.27 87.9368 526.58 88.0425C527.869 88.1481 528.376 86.9649 527.7 85.4014C527.024 83.8379 525.439 82.4646 524.151 82.3589C522.862 82.2321 522.355 83.4153 523.031 85Z" fill="#DEDEDE"/>
<path d="M515.593 84.3873C516.248 85.9508 517.811 87.3242 519.1 87.4298C520.389 87.5355 520.917 86.3523 520.262 84.7888C519.607 83.2252 518.022 81.873 516.755 81.7674C515.466 81.6195 514.938 82.8027 515.593 84.3873Z" fill="#DEDEDE"/>
<path d="M508.135 83.7746C508.768 85.3381 510.332 86.6903 511.621 86.796C512.91 86.9016 513.459 85.7184 512.804 84.1338C512.17 82.5703 510.607 81.2181 509.318 81.1124C508.05 81.0279 507.522 82.2111 508.135 83.7746Z" fill="#DEDEDE"/>
<path d="M500.675 83.2038C501.288 84.7673 502.831 86.1195 504.141 86.2252C505.429 86.3308 505.979 85.1265 505.366 83.563C504.732 81.9994 503.19 80.6472 501.901 80.5627C500.612 80.4571 500.063 81.6403 500.675 83.2038Z" fill="#DEDEDE"/>
<path d="M493.218 82.6547C493.81 84.2182 495.352 85.5705 496.662 85.655C497.972 85.7606 498.521 84.5563 497.909 82.9928C497.296 81.4293 495.754 80.0982 494.465 79.9925C493.197 79.908 492.626 81.0912 493.218 82.6547Z" fill="#DEDEDE"/>
<path d="M485.738 82.1052C486.308 83.6687 487.85 84.9998 489.139 85.1054C490.449 85.1899 491.02 84.0067 490.428 82.4432C489.837 80.8797 488.315 79.5486 487.026 79.443C485.738 79.3585 485.167 80.5417 485.738 82.1052Z" fill="#DEDEDE"/>
<path d="M478.259 81.5771C478.83 83.1406 480.33 84.4717 481.64 84.5562C482.95 84.6407 483.541 83.4575 482.95 81.894C482.379 80.3305 480.858 78.9994 479.569 78.9149C478.301 78.8304 477.71 80.0136 478.259 81.5771Z" fill="#DEDEDE"/>
<path d="M470.779 81.0702C471.329 82.6337 472.829 83.9648 474.139 84.0493C475.449 84.1338 476.04 82.9506 475.491 81.3871C474.942 79.8236 473.421 78.4925 472.132 78.408C470.822 78.3235 470.23 79.5278 470.779 81.0702Z" fill="#DEDEDE"/>
<path d="M463.279 80.5841C463.807 82.1477 465.307 83.4788 466.596 83.5633C467.906 83.6478 468.519 82.4435 467.99 80.9011C467.441 79.3376 465.962 78.0276 464.673 77.9431C463.363 77.8374 462.75 79.0206 463.279 80.5841Z" fill="#DEDEDE"/>
<path d="M455.757 80.1193C456.264 81.6617 457.743 82.9928 459.053 83.0773C460.363 83.1618 460.997 81.9575 460.468 80.4151C459.94 78.8516 458.461 77.5417 457.172 77.4571C455.884 77.3726 455.25 78.5558 455.757 80.1193Z" fill="#DEDEDE"/>
<path d="M448.235 79.6544C448.721 81.1967 450.2 82.5278 451.51 82.6123C452.82 82.6969 453.475 81.4925 452.968 79.9502C452.461 78.4078 451.003 77.0767 449.693 77.0133C448.404 76.9288 447.749 78.112 448.235 79.6544Z" fill="#DEDEDE"/>
<path d="M440.713 79.2319C441.199 80.7742 442.636 82.1053 443.946 82.1687C445.256 82.2532 445.932 81.0489 445.425 79.4854C444.939 77.943 443.481 76.633 442.192 76.5485C440.904 76.4851 440.249 77.6895 440.713 79.2319Z" fill="#DEDEDE"/>
<path d="M433.192 78.8089C433.657 80.3513 435.094 81.6824 436.404 81.7458C437.714 81.8092 438.39 80.626 437.925 79.0625C437.46 77.5201 436.023 76.2101 434.713 76.1256C433.403 76.0834 432.727 77.2666 433.192 78.8089Z" fill="#DEDEDE"/>
<path d="M425.649 78.408C426.092 79.9504 427.508 81.2604 428.839 81.3449C430.149 81.4083 430.846 80.204 430.402 78.6616C429.938 77.1192 428.522 75.8092 427.233 75.7458C425.902 75.6825 425.205 76.8657 425.649 78.408Z" fill="#DEDEDE"/>
<path d="M418.085 78.0485C418.508 79.5909 419.923 80.9009 421.233 80.9643C422.543 81.0277 423.262 79.8233 422.818 78.281C422.374 76.7386 420.98 75.4286 419.67 75.3652C418.381 75.3018 417.663 76.485 418.085 78.0485Z" fill="#DEDEDE"/>
<path d="M410.542 77.6898C410.943 79.2322 412.338 80.5422 413.669 80.6056C414.979 80.669 415.718 79.4646 415.296 77.9222C414.873 76.3799 413.479 75.091 412.169 75.0276C410.859 74.9431 410.14 76.1474 410.542 77.6898Z" fill="#DEDEDE"/>
<path d="M402.978 77.3302C403.358 78.8725 404.752 80.1825 406.062 80.2248C407.372 80.2882 408.133 79.0838 407.732 77.5415C407.33 75.9991 405.957 74.7102 404.647 74.6468C403.337 74.6046 402.597 75.7878 402.978 77.3302Z" fill="#DEDEDE"/>
<path d="M395.414 77.0132C395.773 78.5555 397.146 79.8444 398.478 79.9078C399.787 79.9711 400.548 78.7457 400.168 77.2033C399.788 75.6609 398.414 74.3721 397.104 74.3087C395.794 74.2664 395.034 75.4708 395.414 77.0132Z" fill="#DEDEDE"/>
<path d="M387.829 76.7177C388.188 78.2601 389.541 79.5489 390.851 79.6123C392.161 79.6546 392.942 78.4502 392.583 76.9079C392.224 75.3655 390.872 74.0766 389.562 74.0344C388.252 73.971 387.491 75.1753 387.829 76.7177Z" fill="#DEDEDE"/>
<path d="M380.243 76.422C380.581 77.9644 381.912 79.2532 383.244 79.2955C384.575 79.3378 385.356 78.1334 385.018 76.591C384.68 75.0487 383.328 73.7598 382.018 73.7176C380.708 73.6964 379.926 74.8796 380.243 76.422Z" fill="#DEDEDE"/>
<path d="M372.658 76.1681C372.975 77.7105 374.306 78.9994 375.616 79.0416C376.947 79.0839 377.75 77.8796 377.412 76.3372C377.095 74.7948 375.764 73.5271 374.454 73.4848C373.165 73.4214 372.341 74.6258 372.658 76.1681Z" fill="#DEDEDE"/>
<path d="M365.073 75.9145C365.369 77.4569 366.679 78.7246 368.01 78.788C369.341 78.8302 370.165 77.6048 369.848 76.0835C369.531 74.5411 368.221 73.2734 366.912 73.2312C365.602 73.1678 364.778 74.3721 365.073 75.9145Z" fill="#DEDEDE"/>
<path d="M357.467 75.6823C357.742 77.2247 359.051 78.4924 360.361 78.5347C361.693 78.5769 362.517 77.3515 362.242 75.8302C361.946 74.2878 360.657 73.0201 359.347 72.9779C358.037 72.9356 357.192 74.1611 357.467 75.6823Z" fill="#DEDEDE"/>
<path d="M349.882 75.4709C350.135 77.0133 351.424 78.281 352.755 78.3232C354.087 78.3655 354.932 77.14 354.657 75.5977C354.382 74.0553 353.093 72.7876 351.783 72.7664C350.474 72.7242 349.607 73.9496 349.882 75.4709Z" fill="#DEDEDE"/>
<path d="M342.275 75.281C342.508 76.8234 343.776 78.0911 345.107 78.1122C346.438 78.1545 347.304 76.929 347.051 75.3867C346.797 73.8443 345.529 72.5977 344.219 72.5554C342.888 72.5343 342.022 73.7598 342.275 75.281Z" fill="#DEDEDE"/>
<path d="M334.648 75.1116C334.859 76.654 336.127 77.9217 337.458 77.9428C338.789 77.9639 339.676 76.7385 339.444 75.2172C339.212 73.6749 337.944 72.4283 336.634 72.386C335.324 72.3649 334.437 73.5903 334.648 75.1116Z" fill="#DEDEDE"/>
<path d="M327.042 74.9641C327.254 76.5065 328.479 77.7742 329.81 77.7953C331.141 77.8164 332.029 76.591 331.817 75.0697C331.606 73.5273 330.359 72.2808 329.049 72.2596C327.74 72.2174 326.831 73.4217 327.042 74.9641Z" fill="#DEDEDE"/>
<path d="M319.414 74.837C319.604 76.3794 320.83 77.626 322.161 77.6471C323.492 77.6683 324.401 76.4428 324.21 74.9004C324.02 73.358 322.795 72.1115 321.464 72.0903C320.154 72.0903 319.224 73.2947 319.414 74.837Z" fill="#DEDEDE"/>
<path d="M311.787 74.7103C311.956 76.2316 313.161 77.4993 314.492 77.5204C315.823 77.5415 316.753 76.3161 316.584 74.7737C316.415 73.2313 315.189 71.9848 313.879 71.9636C312.569 71.9636 311.64 73.1891 311.787 74.7103Z" fill="#DEDEDE"/>
<path d="M304.159 74.6261C304.307 76.1473 305.511 77.415 306.821 77.4362C308.152 77.4573 309.103 76.2107 308.934 74.6894C308.765 73.1682 307.582 71.9005 306.272 71.9005C304.962 71.8582 304.032 73.0837 304.159 74.6261Z" fill="#DEDEDE"/>
<path d="M296.533 74.5414C296.659 76.0626 297.843 77.3303 299.174 77.3303C300.505 77.3515 301.477 76.1049 301.329 74.5836C301.181 73.0624 300.019 71.8158 298.688 71.7947C297.378 71.7947 296.406 73.0201 296.533 74.5414Z" fill="#DEDEDE"/>
<path d="M288.906 74.499C289.011 76.0203 290.173 77.288 291.505 77.288C292.836 77.288 293.808 76.0625 293.702 74.5202C293.575 72.9989 292.413 71.7523 291.103 71.7312C289.772 71.7312 288.8 72.9567 288.906 74.499Z" fill="#DEDEDE"/>
<path d="M281.278 74.4565C281.362 75.9777 282.524 77.2454 283.855 77.2454C285.186 77.2454 286.179 76.02 286.074 74.4776C285.968 72.9563 284.827 71.7097 283.496 71.7097C282.186 71.6886 281.172 72.9141 281.278 74.4565Z" fill="#DEDEDE"/>
<path d="M273.63 74.4354C273.693 75.9567 274.834 77.2033 276.165 77.2244C277.496 77.2244 278.51 75.9778 278.426 74.4566C278.341 72.9353 277.201 71.6887 275.891 71.6887C274.581 71.6676 273.566 72.9142 273.63 74.4354Z" fill="#DEDEDE"/>
<path d="M266.002 74.4356C266.044 75.9568 267.164 77.2034 268.495 77.2034C269.826 77.2034 270.861 75.9568 270.798 74.4356C270.735 72.9143 269.615 71.6677 268.305 71.6677C266.974 71.6677 265.938 72.9143 266.002 74.4356Z" fill="#DEDEDE"/>
<path d="M258.353 74.4566C258.374 75.9778 259.494 77.2244 260.825 77.2244C262.156 77.2244 263.192 75.9778 263.149 74.4354C263.107 72.9142 262.009 71.6676 260.677 71.6887C259.367 71.6887 258.311 72.9353 258.353 74.4566Z" fill="#DEDEDE"/>
<path d="M250.704 74.4986C250.726 76.0198 251.803 77.2664 253.134 77.2664C254.465 77.2664 255.522 75.9987 255.501 74.4774C255.479 72.9562 254.381 71.7096 253.071 71.7307C251.761 71.7307 250.683 72.9562 250.704 74.4986Z" fill="#DEDEDE"/>
<path d="M243.057 74.5626C243.057 76.0839 244.134 77.3305 245.465 77.3094C246.796 77.3094 247.874 76.0416 247.874 74.5204C247.874 72.9991 246.796 71.7737 245.465 71.7737C244.134 71.7737 243.078 73.0203 243.057 74.5626Z" fill="#DEDEDE"/>
<path d="M204.835 75.1325C204.73 76.6538 205.723 77.8792 207.075 77.8581C208.406 77.837 209.568 76.5693 209.653 75.0269C209.737 73.5056 208.744 72.2802 207.413 72.3013C206.082 72.3436 204.92 73.6113 204.835 75.1325Z" fill="#DEDEDE"/>
<path d="M59.8707 81.7458C59.427 83.2882 60.1243 84.4925 61.4553 84.3869C62.7864 84.3024 64.202 82.9502 64.6457 81.4078C65.0894 79.8654 64.3711 78.6822 63.0611 78.7667C61.7511 78.8512 60.3355 80.1823 59.8707 81.7458Z" fill="#DEDEDE"/>
<path d="M52.2871 82.2745C51.8222 83.838 52.4983 85.0212 53.8294 84.9155C55.1605 84.831 56.5973 83.4788 57.0621 81.9364C57.5269 80.3729 56.8297 79.2108 55.5197 79.2953C54.2098 79.3799 52.7519 80.711 52.2871 82.2745Z" fill="#DEDEDE"/>
<path d="M44.7011 82.8234C44.2151 84.3869 44.8912 85.5701 46.2012 85.4645C47.5323 85.3589 48.969 84.0278 49.455 82.4642C49.9198 80.9007 49.2437 79.7387 47.9549 79.8232C46.6449 79.9288 45.187 81.2811 44.7011 82.8234Z" fill="#DEDEDE"/>
<path d="M635.349 88.0638C636.236 89.6696 637.969 91.0852 639.216 91.2331C640.462 91.381 640.737 90.2189 639.849 88.6132C638.941 87.0074 637.208 85.5918 635.983 85.4439C634.736 85.296 634.462 86.4792 635.349 88.0638Z" fill="#DEDEDE"/>
<path d="M628.186 87.1972C629.053 88.7818 630.785 90.1974 632.032 90.3664C633.278 90.5143 633.574 89.3523 632.687 87.7465C631.799 86.1407 630.088 84.7463 628.841 84.5984C627.595 84.4293 627.299 85.6125 628.186 87.1972Z" fill="#DEDEDE"/>
<path d="M621.003 86.3523C621.87 87.937 623.581 89.3526 624.828 89.5005C626.074 89.6484 626.391 88.4652 625.504 86.8806C624.637 85.2959 622.905 83.8803 621.679 83.7324C620.454 83.5845 620.137 84.7466 621.003 86.3523Z" fill="#DEDEDE"/>
<path d="M613.819 85.507C614.664 87.0916 616.375 88.5073 617.622 88.6552C618.868 88.8031 619.185 87.6199 618.319 86.0352C617.453 84.4506 615.762 83.035 614.516 82.9082C613.269 82.7603 612.952 83.9224 613.819 85.507Z" fill="#DEDEDE"/>
<path d="M606.615 84.704C607.46 86.2886 609.15 87.6831 610.397 87.831C611.643 87.9789 611.981 86.7957 611.136 85.2111C610.291 83.6264 608.601 82.2319 607.354 82.084C606.107 81.9361 605.769 83.1193 606.615 84.704Z" fill="#DEDEDE"/>
<path d="M599.388 83.9015C600.212 85.4862 601.903 86.8806 603.149 87.0285C604.417 87.1764 604.755 85.9932 603.91 84.4086C603.065 82.824 601.395 81.4295 600.149 81.3027C598.902 81.1337 598.564 82.3169 599.388 83.9015Z" fill="#DEDEDE"/>
<path d="M592.163 83.0982C592.966 84.6829 594.657 86.0774 595.903 86.2041C597.171 86.3309 597.509 85.1688 596.706 83.5842C595.882 81.9996 594.213 80.6051 592.966 80.4783C591.72 80.3515 591.361 81.5347 592.163 83.0982Z" fill="#DEDEDE"/>
<path d="M584.915 82.3378C585.718 83.9225 587.388 85.2958 588.634 85.4437C589.902 85.5705 590.261 84.4084 589.458 82.8238C588.655 81.2391 586.986 79.8658 585.74 79.739C584.493 79.5911 584.134 80.7532 584.915 82.3378Z" fill="#DEDEDE"/>
<path d="M577.669 81.5768C578.451 83.1403 580.12 84.5348 581.367 84.6616C582.634 84.7883 583.015 83.6263 582.212 82.0416C581.409 80.457 579.761 79.0836 578.514 78.9569C577.268 78.8512 576.887 80.0133 577.669 81.5768Z" fill="#DEDEDE"/>
<path d="M570.4 80.8375C571.161 82.401 572.809 83.7955 574.076 83.9223C575.344 84.0491 575.745 82.8659 574.964 81.3023C574.182 79.7388 572.534 78.3655 571.287 78.2387C570.041 78.1119 569.639 79.274 570.4 80.8375Z" fill="#DEDEDE"/>
<path d="M563.132 80.119C563.893 81.6825 565.519 83.0559 566.787 83.1827C568.055 83.3094 568.456 82.1262 567.696 80.5627C566.935 78.9992 565.287 77.6259 564.041 77.4991C562.794 77.3935 562.392 78.5555 563.132 80.119Z" fill="#DEDEDE"/>
<path d="M555.843 79.4222C556.582 80.9857 558.209 82.3591 559.477 82.4859C560.744 82.6126 561.167 81.4294 560.427 79.8659C559.688 78.3024 558.061 76.9291 556.793 76.8234C555.547 76.6967 555.124 77.8587 555.843 79.4222Z" fill="#DEDEDE"/>
<path d="M548.553 78.7462C549.272 80.3097 550.899 81.6831 552.166 81.7887C553.434 81.9155 553.878 80.7323 553.138 79.1688C552.399 77.6053 550.793 76.253 549.525 76.1263C548.279 75.9995 547.835 77.1827 548.553 78.7462Z" fill="#DEDEDE"/>
<path d="M541.243 78.07C541.94 79.6335 543.567 80.9857 544.835 81.1125C546.103 81.2181 546.546 80.0561 545.828 78.4925C545.11 76.929 543.504 75.5768 542.236 75.45C540.989 75.3444 540.546 76.5065 541.243 78.07Z" fill="#DEDEDE"/>
<path d="M533.932 77.4147C534.629 78.9782 536.214 80.3304 537.503 80.4361C538.77 80.5417 539.235 79.3796 538.538 77.8161C537.841 76.2526 536.235 74.9004 534.967 74.7948C533.7 74.6891 533.256 75.8512 533.932 77.4147Z" fill="#DEDEDE"/>
<path d="M526.622 76.7809C527.298 78.3444 528.883 79.6966 530.151 79.8023C531.418 79.9079 531.904 78.7459 531.207 77.1823C530.51 75.6188 528.946 74.2877 527.678 74.161C526.411 74.0553 525.925 75.2174 526.622 76.7809Z" fill="#DEDEDE"/>
<path d="M519.269 76.1686C519.924 77.7321 521.509 79.0632 522.777 79.1688C524.065 79.2745 524.551 78.0913 523.875 76.5489C523.199 74.9854 521.636 73.6543 520.368 73.5487C519.1 73.443 518.614 74.6051 519.269 76.1686Z" fill="#DEDEDE"/>
<path d="M511.937 75.5557C512.592 77.098 514.135 78.4503 515.424 78.5559C516.713 78.6616 517.22 77.4784 516.543 75.936C515.888 74.3936 514.325 73.0414 513.057 72.9357C511.79 72.8512 511.282 74.0133 511.937 75.5557Z" fill="#DEDEDE"/>
<path d="M504.584 74.9853C505.218 76.5277 506.76 77.8799 508.049 77.9856C509.338 78.0912 509.845 76.908 509.211 75.3656C508.577 73.8232 507.014 72.471 505.746 72.3865C504.478 72.2597 503.95 73.4429 504.584 74.9853Z" fill="#DEDEDE"/>
<path d="M497.211 74.4146C497.824 75.957 499.366 77.3092 500.634 77.3937C501.923 77.4994 502.451 76.3162 501.817 74.7738C501.183 73.2314 499.662 71.9003 498.394 71.7946C497.148 71.689 496.598 72.8722 497.211 74.4146Z" fill="#DEDEDE"/>
<path d="M489.837 73.865C490.429 75.4074 491.95 76.7385 493.239 76.8441C494.528 76.9498 495.077 75.7666 494.464 74.2242C493.852 72.6818 492.33 71.3507 491.063 71.2662C489.795 71.1605 489.246 72.3226 489.837 73.865Z" fill="#DEDEDE"/>
<path d="M482.463 73.3375C483.033 74.8798 484.555 76.2109 485.844 76.2955C487.132 76.38 487.703 75.1968 487.111 73.6544C486.52 72.112 485.02 70.7809 483.731 70.6964C482.442 70.6119 481.893 71.7951 482.463 73.3375Z" fill="#DEDEDE"/>
<path d="M475.068 72.8299C475.638 74.3723 477.138 75.7033 478.427 75.7879C479.716 75.8724 480.287 74.6892 479.716 73.1468C479.146 71.6044 477.646 70.2944 476.378 70.2099C475.089 70.1043 474.519 71.2875 475.068 72.8299Z" fill="#DEDEDE"/>
<path d="M467.673 72.3227C468.222 73.8651 469.701 75.1751 470.99 75.2596C472.279 75.3441 472.871 74.1609 472.321 72.6185C471.772 71.0762 470.272 69.7662 469.004 69.6817C467.737 69.6183 467.145 70.7804 467.673 72.3227Z" fill="#DEDEDE"/>
<path d="M460.279 71.8577C460.807 73.4001 462.286 74.7101 463.575 74.7946C464.864 74.8791 465.476 73.6959 464.927 72.1535C464.378 70.6112 462.92 69.3012 461.631 69.2167C460.363 69.1321 459.751 70.3153 460.279 71.8577Z" fill="#DEDEDE"/>
<path d="M452.862 71.3928C453.369 72.9352 454.827 74.2452 456.137 74.3297C457.426 74.4142 458.06 73.231 457.531 71.6886C457.003 70.1462 455.545 68.8574 454.278 68.7729C452.968 68.6884 452.355 69.8504 452.862 71.3928Z" fill="#DEDEDE"/>
<path d="M445.446 70.9497C445.932 72.4921 447.39 73.7809 448.679 73.8654C449.968 73.9499 450.602 72.7667 450.094 71.2244C449.587 69.682 448.13 68.3931 446.862 68.3086C445.594 68.2452 444.96 69.4073 445.446 70.9497Z" fill="#DEDEDE"/>
<path d="M438.01 70.5269C438.496 72.0481 439.912 73.3581 441.222 73.4426C442.51 73.506 443.165 72.3228 442.679 70.8015C442.193 69.2803 440.757 67.9703 439.468 67.9069C438.2 67.8224 437.545 68.9845 438.01 70.5269Z" fill="#DEDEDE"/>
<path d="M430.593 70.1255C431.058 71.6467 432.474 72.9567 433.762 73.0201C435.051 73.0835 435.727 71.9003 435.263 70.379C434.798 68.8578 433.361 67.5478 432.093 67.4844C430.804 67.421 430.128 68.5831 430.593 70.1255Z" fill="#DEDEDE"/>
<path d="M423.135 69.7238C423.578 71.2451 424.994 72.555 426.283 72.6184C427.572 72.6818 428.269 71.4986 427.804 69.9774C427.339 68.4561 425.945 67.1673 424.656 67.1039C423.388 67.0194 422.712 68.2026 423.135 69.7238Z" fill="#DEDEDE"/>
<path d="M415.697 69.3647C416.12 70.886 417.514 72.1748 418.824 72.2382C420.113 72.3016 420.81 71.1184 420.388 69.576C419.944 68.0547 418.549 66.7659 417.282 66.7025C415.972 66.6603 415.274 67.8435 415.697 69.3647Z" fill="#DEDEDE"/>
<path d="M408.239 69.0056C408.64 70.5268 410.035 71.8157 411.324 71.8791C412.634 71.9424 413.331 70.7381 412.929 69.2169C412.507 67.6956 411.133 66.4068 409.845 66.3434C408.556 66.3011 407.837 67.4843 408.239 69.0056Z" fill="#DEDEDE"/>
<path d="M400.78 68.6674C401.16 70.1887 402.534 71.4775 403.844 71.5409C405.154 71.6043 405.872 70.4 405.47 68.8787C405.069 67.3575 403.696 66.0686 402.407 66.0264C401.118 65.963 400.4 67.1462 400.78 68.6674Z" fill="#DEDEDE"/>
<path d="M393.322 68.3505C393.681 69.8718 395.055 71.1606 396.344 71.2029C397.654 71.2663 398.393 70.062 398.013 68.5407C397.632 67.0194 396.28 65.7517 394.991 65.6884C393.703 65.6461 392.942 66.8293 393.322 68.3505Z" fill="#DEDEDE"/>
<path d="M385.842 68.0546C386.201 69.5759 387.532 70.8436 388.842 70.907C390.152 70.9492 390.913 69.766 390.532 68.2448C390.173 66.7235 388.821 65.4558 387.532 65.4136C386.265 65.3502 385.483 66.5334 385.842 68.0546Z" fill="#DEDEDE"/>
<path d="M378.363 67.7805C378.702 69.3017 380.033 70.5694 381.321 70.6328C382.631 70.6751 383.392 69.4919 383.054 67.9706C382.716 66.4494 381.385 65.1817 380.096 65.1394C378.807 65.076 378.025 66.2592 378.363 67.7805Z" fill="#DEDEDE"/>
<path d="M370.884 67.5264C371.201 69.0476 372.511 70.3153 373.821 70.3576C375.131 70.3999 375.912 69.1955 375.595 67.6743C375.278 66.153 373.947 64.8853 372.658 64.8431C371.37 64.8219 370.567 66.0051 370.884 67.5264Z" fill="#DEDEDE"/>
<path d="M363.404 67.2945C363.7 68.8157 364.989 70.0834 366.299 70.1257C367.609 70.1679 368.412 68.9636 368.095 67.4424C367.778 65.9211 366.489 64.6745 365.2 64.6323C363.911 64.5689 363.108 65.7732 363.404 67.2945Z" fill="#DEDEDE"/>
<path d="M355.903 67.0618C356.178 68.583 357.467 69.8507 358.777 69.8719C360.087 69.9141 360.911 68.7098 360.615 67.1885C360.319 65.6673 359.052 64.4207 357.742 64.3785C356.453 64.3573 355.629 65.5405 355.903 67.0618Z" fill="#DEDEDE"/>
<path d="M348.403 66.8506C348.657 68.3718 349.925 69.6184 351.235 69.6607C352.545 69.7029 353.39 68.4986 353.115 66.9774C352.84 65.4561 351.573 64.2095 350.284 64.1673C348.995 64.1461 348.15 65.3505 348.403 66.8506Z" fill="#DEDEDE"/>
<path d="M340.902 66.6817C341.135 68.2029 342.402 69.4495 343.712 69.4918C345.022 69.534 345.867 68.3086 345.614 66.8084C345.36 65.2872 344.114 64.0406 342.804 64.0195C341.515 63.9561 340.67 65.1604 340.902 66.6817Z" fill="#DEDEDE"/>
<path d="M333.402 66.5123C333.613 68.0336 334.86 69.2802 336.17 69.3013C337.48 69.3224 338.346 68.1181 338.113 66.5969C337.881 65.0756 336.634 63.829 335.346 63.8079C334.057 63.7868 333.169 64.9911 333.402 66.5123Z" fill="#DEDEDE"/>
<path d="M325.88 66.3649C326.091 67.8861 327.316 69.1327 328.626 69.1538C329.936 69.175 330.824 67.9706 330.612 66.4494C330.401 64.9281 329.176 63.7027 327.887 63.6604C326.577 63.6393 325.689 64.8436 325.88 66.3649Z" fill="#DEDEDE"/>
<path d="M318.379 66.2168C318.569 67.7381 319.774 68.9846 321.084 69.0058C322.394 69.0269 323.302 67.8226 323.091 66.3013C322.901 64.7801 321.675 63.5546 320.386 63.5335C319.098 63.5123 318.189 64.7167 318.379 66.2168Z" fill="#DEDEDE"/>
<path d="M310.857 66.1114C311.026 67.6327 312.21 68.8581 313.519 68.8793C314.829 68.9004 315.738 67.6749 315.569 66.1748C315.4 64.6536 314.196 63.4281 312.907 63.407C311.618 63.407 310.688 64.6113 310.857 66.1114Z" fill="#DEDEDE"/>
<path d="M303.336 66.0263C303.484 67.5475 304.667 68.773 305.977 68.7941C307.287 68.8152 308.217 67.5898 308.069 66.0896C307.9 64.5684 306.738 63.3429 305.428 63.3218C304.139 63.3007 303.188 64.505 303.336 66.0263Z" fill="#DEDEDE"/>
<path d="M295.814 65.942C295.941 67.4421 297.103 68.6887 298.413 68.7098C299.723 68.731 300.674 67.5055 300.526 65.9843C300.378 64.463 299.216 63.2375 297.927 63.2375C296.638 63.2375 295.687 64.4419 295.814 65.942Z" fill="#DEDEDE"/>
<path d="M288.293 65.8994C288.399 67.3995 289.561 68.6461 290.871 68.6461C292.181 68.6461 293.153 67.4418 293.026 65.9205C292.899 64.4204 291.758 63.1738 290.448 63.1738C289.159 63.1738 288.187 64.3781 288.293 65.8994Z" fill="#DEDEDE"/>
<path d="M280.771 65.8574C280.855 67.3575 281.996 68.6041 283.306 68.6041C284.616 68.6041 285.609 67.3787 285.504 65.8785C285.398 64.3784 284.257 63.153 282.968 63.1318C281.658 63.1318 280.665 64.3573 280.771 65.8574Z" fill="#DEDEDE"/>
<path d="M273.227 65.8366C273.291 67.3367 274.411 68.5833 275.721 68.5833C277.031 68.5833 278.024 67.3578 277.939 65.8577C277.855 64.3576 276.735 63.1321 275.446 63.1321C274.157 63.111 273.164 64.3364 273.227 65.8366Z" fill="#DEDEDE"/>
<path d="M265.706 65.8364C265.749 67.3365 266.869 68.5831 268.178 68.5831C269.488 68.5831 270.503 67.3577 270.439 65.8364C270.376 64.3363 269.277 63.1108 267.967 63.1108C266.657 63.1108 265.643 64.3363 265.706 65.8364Z" fill="#DEDEDE"/>
<path d="M258.163 65.8575C258.205 67.3577 259.283 68.5831 260.593 68.5831C261.903 68.5831 262.938 67.3577 262.896 65.8364C262.853 64.3363 261.755 63.1108 260.466 63.1108C259.156 63.132 258.142 64.3574 258.163 65.8575Z" fill="#DEDEDE"/>
<path d="M250.641 65.8997C250.663 67.3998 251.719 68.6253 253.05 68.6253C254.36 68.6253 255.416 67.3787 255.374 65.8785C255.353 64.3784 254.276 63.153 252.966 63.1741C251.656 63.1741 250.62 64.3995 250.641 65.8997Z" fill="#DEDEDE"/>
<path d="M243.098 65.9635C243.098 67.4637 244.155 68.6891 245.465 68.6891C246.775 68.6891 247.831 67.4425 247.831 65.9213C247.831 64.4212 246.753 63.1957 245.465 63.2168C244.155 63.2168 243.098 64.4634 243.098 65.9635Z" fill="#DEDEDE"/>
<path d="M235.555 66.0474C235.534 67.5475 236.57 68.773 237.88 68.7519C239.19 68.7307 240.267 67.5053 240.288 65.984C240.309 64.4839 239.253 63.2584 237.964 63.2796C236.654 63.3007 235.577 64.5261 235.555 66.0474Z" fill="#DEDEDE"/>
<path d="M145.105 68.4355C144.852 69.9567 145.718 71.1399 147.028 71.0977C148.338 71.0343 149.605 69.7666 149.838 68.2453C150.07 66.7241 149.225 65.5409 147.915 65.6042C146.605 65.6465 145.359 66.9142 145.105 68.4355Z" fill="#DEDEDE"/>
<path d="M137.561 68.7521C137.286 70.2734 138.132 71.4566 139.441 71.4143C140.751 71.3509 142.019 70.0832 142.294 68.562C142.547 67.0407 141.702 65.8575 140.413 65.9209C139.103 65.9631 137.836 67.252 137.561 68.7521Z" fill="#DEDEDE"/>
<path d="M69.9287 72.4922C69.485 74.0346 70.2034 75.1967 71.5133 75.1122C72.8233 75.0276 74.2178 73.7177 74.6404 72.1753C75.063 70.6329 74.3446 69.4708 73.0557 69.5554C71.7669 69.661 70.3513 70.971 69.9287 72.4922Z" fill="#DEDEDE"/>
<path d="M62.4284 73.0203C61.9847 74.5627 62.6608 75.7248 63.9708 75.6402C65.2808 75.5557 66.6964 74.2246 67.1401 72.7034C67.5838 71.161 66.8865 69.9989 65.5977 70.0834C64.2877 70.1679 62.8932 71.4779 62.4284 73.0203Z" fill="#DEDEDE"/>
<path d="M54.9494 73.527C54.4845 75.0694 55.1607 76.2315 56.4706 76.147C57.7806 76.0624 59.1962 74.7313 59.661 73.189C60.1259 71.6466 59.4497 70.4845 58.1398 70.569C56.8298 70.6747 55.4142 72.0058 54.9494 73.527Z" fill="#DEDEDE"/>
<path d="M47.4479 74.0764C46.9619 75.6187 47.6169 76.7808 48.9269 76.6963C50.2368 76.5906 51.6736 75.2807 52.1595 73.7383C52.6244 72.1959 51.9694 71.0338 50.6805 71.1395C49.3917 71.224 47.9338 72.534 47.4479 74.0764Z" fill="#DEDEDE"/>
<path d="M39.9688 74.6468C39.4617 76.1892 40.1167 77.3512 41.4055 77.2667C42.7155 77.1611 44.1734 75.83 44.6593 74.2876C45.1453 72.7452 44.5114 71.5832 43.2226 71.6888C41.9337 71.7733 40.4759 73.1044 39.9688 74.6468Z" fill="#DEDEDE"/>
<path d="M32.4899 75.2173C31.9617 76.7597 32.5956 77.9218 33.9055 77.8373C35.2155 77.7316 36.6734 76.4005 37.1805 74.8582C37.6876 73.3158 37.0537 72.1537 35.7649 72.2594C34.4972 72.365 33.0182 73.675 32.4899 75.2173Z" fill="#DEDEDE"/>
<path d="M637.399 79.9498C638.307 81.5345 640.04 82.9501 641.265 83.098C642.491 83.2459 642.765 82.0838 641.836 80.4992C640.927 78.9145 639.195 77.4989 637.969 77.351C636.765 77.2031 636.511 78.3441 637.399 79.9498Z" fill="#DEDEDE"/>
<path d="M630.32 79.0838C631.208 80.6684 632.94 82.084 634.166 82.2319C635.391 82.3798 635.666 81.2177 634.779 79.6331C633.87 78.0485 632.159 76.654 630.933 76.5061C629.708 76.3371 629.433 77.4991 630.32 79.0838Z" fill="#DEDEDE"/>
<path d="M623.222 78.2176C624.088 79.8023 625.821 81.1968 627.046 81.3447C628.272 81.4926 628.568 80.3305 627.68 78.7459C626.793 77.1612 625.081 75.7667 623.856 75.6188C622.63 75.4921 622.356 76.6541 623.222 78.2176Z" fill="#DEDEDE"/>
<path d="M616.123 77.3936C616.989 78.9782 618.679 80.3727 619.926 80.5206C621.172 80.6685 621.468 79.5064 620.581 77.9218C619.714 76.3371 618.003 74.9426 616.778 74.8159C615.552 74.668 615.256 75.8089 616.123 77.3936Z" fill="#DEDEDE"/>
<path d="M609.002 76.5695C609.847 78.133 611.537 79.5486 612.784 79.6753C614.03 79.8232 614.326 78.6612 613.481 77.0765C612.615 75.4919 610.924 74.1185 609.699 73.9706C608.452 73.8439 608.157 75.0059 609.002 76.5695Z" fill="#DEDEDE"/>
<path d="M601.86 75.7666C602.705 77.3302 604.375 78.7246 605.621 78.8725C606.868 79.0204 607.185 77.8372 606.34 76.2737C605.494 74.7102 603.804 73.3157 602.579 73.189C601.353 73.0411 601.036 74.2031 601.86 75.7666Z" fill="#DEDEDE"/>
<path d="M594.74 74.9847C595.564 76.5483 597.233 77.9427 598.48 78.0695C599.726 78.2174 600.064 77.0342 599.219 75.4707C598.395 73.9072 596.705 72.5127 595.479 72.3859C594.233 72.2592 593.916 73.4212 594.74 74.9847Z" fill="#DEDEDE"/>
<path d="M587.578 74.2242C588.38 75.7877 590.05 77.161 591.296 77.3089C592.543 77.4357 592.881 76.2736 592.057 74.7101C591.233 73.1466 589.564 71.7733 588.338 71.6465C587.113 71.4986 586.775 72.6607 587.578 74.2242Z" fill="#DEDEDE"/>
<path d="M580.415 73.4635C581.218 75.027 582.866 76.4004 584.112 76.5271C585.359 76.6539 585.718 75.4918 584.915 73.9283C584.112 72.3648 582.464 70.9915 581.218 70.8647C579.992 70.759 579.633 71.9 580.415 73.4635Z" fill="#DEDEDE"/>
<path d="M573.252 72.7246C574.034 74.2881 575.682 75.6615 576.928 75.7882C578.175 75.915 578.555 74.7529 577.753 73.1894C576.971 71.6259 575.323 70.2737 574.076 70.1469C572.851 70.0201 572.47 71.1822 573.252 72.7246Z" fill="#DEDEDE"/>
<path d="M566.069 72.0056C566.83 73.5691 568.478 74.9214 569.724 75.0481C570.971 75.1749 571.351 74.0128 570.59 72.4493C569.809 70.8858 568.182 69.5336 566.935 69.4068C565.689 69.3012 565.308 70.4632 566.069 72.0056Z" fill="#DEDEDE"/>
<path d="M558.885 71.3087C559.646 72.8511 561.252 74.2245 562.519 74.3512C563.766 74.478 564.167 73.3159 563.407 71.7524C562.646 70.1889 561.019 68.8367 559.773 68.7311C558.526 68.6043 558.125 69.7452 558.885 71.3087Z" fill="#DEDEDE"/>
<path d="M551.68 70.6113C552.419 72.1537 554.025 73.5271 555.293 73.6327C556.539 73.7595 556.962 72.5974 556.201 71.0339C555.462 69.4915 553.835 68.1393 552.588 68.0125C551.363 67.9069 550.94 69.069 551.68 70.6113Z" fill="#DEDEDE"/>
<path d="M544.476 69.9567C545.194 71.4991 546.8 72.8513 548.068 72.9781C549.314 73.1048 549.758 71.9216 549.019 70.3793C548.279 68.8369 546.695 67.4846 545.448 67.379C544.18 67.2522 543.758 68.3932 544.476 69.9567Z" fill="#DEDEDE"/>
<path d="M537.25 69.3015C537.947 70.8439 539.553 72.1961 540.799 72.3018C542.067 72.4074 542.489 71.2454 541.771 69.703C541.053 68.1606 539.468 66.8084 538.222 66.7027C536.996 66.5971 536.552 67.7591 537.25 69.3015Z" fill="#DEDEDE"/>
<path d="M530.024 68.6469C530.721 70.1892 532.305 71.5415 533.552 71.6471C534.82 71.7527 535.263 70.5907 534.566 69.0483C533.869 67.5059 532.284 66.1748 531.038 66.0692C529.791 65.9635 529.326 67.1256 530.024 68.6469Z" fill="#DEDEDE"/>
<path d="M522.798 68.0339C523.474 69.5763 525.037 70.9074 526.305 71.0131C527.573 71.1187 528.038 69.9566 527.34 68.4143C526.664 66.8719 525.08 65.5408 523.833 65.4351C522.587 65.3295 522.122 66.4916 522.798 68.0339Z" fill="#DEDEDE"/>
<path d="M515.55 67.4421C516.205 68.9845 517.769 70.3156 519.036 70.4212C520.304 70.5269 520.769 69.3648 520.114 67.8224C519.438 66.28 517.896 64.949 516.628 64.8644C515.36 64.7377 514.874 65.8997 515.55 67.4421Z" fill="#DEDEDE"/>
<path d="M508.283 66.8503C508.917 68.3927 510.48 69.7238 511.727 69.8083C512.994 69.914 513.48 68.7519 512.825 67.2095C512.17 65.6672 510.628 64.3572 509.381 64.2515C508.135 64.1459 507.649 65.308 508.283 66.8503Z" fill="#DEDEDE"/>
<path d="M501.036 66.28C501.669 67.8013 503.191 69.1324 504.458 69.238C505.726 69.3437 506.233 68.1605 505.599 66.6392C504.965 65.0968 503.423 63.7869 502.177 63.7023C500.909 63.5967 500.402 64.7376 501.036 66.28Z" fill="#DEDEDE"/>
<path d="M493.768 65.7305C494.38 67.2517 495.902 68.5828 497.169 68.6673C498.437 68.773 498.965 67.5898 498.331 66.0685C497.719 64.5473 496.197 63.2162 494.93 63.1317C493.662 63.026 493.155 64.1881 493.768 65.7305Z" fill="#DEDEDE"/>
<path d="M486.478 65.1815C487.069 66.7028 488.591 68.0339 489.858 68.1184C491.126 68.2029 491.654 67.0408 491.063 65.5196C490.45 63.9983 488.95 62.6883 487.682 62.5827C486.414 62.4982 485.886 63.6603 486.478 65.1815Z" fill="#DEDEDE"/>
<path d="M479.189 64.6742C479.759 66.1955 481.259 67.5054 482.527 67.6111C483.795 67.6956 484.344 66.5335 483.753 65.0123C483.161 63.491 481.661 62.181 480.414 62.0965C479.168 61.9909 478.618 63.1529 479.189 64.6742Z" fill="#DEDEDE"/>
<path d="M471.899 64.1671C472.469 65.6884 473.948 66.9983 475.216 67.0828C476.484 67.1674 477.054 66.0053 476.484 64.484C475.913 62.9628 474.434 61.6528 473.167 61.5683C471.899 61.4838 471.35 62.6459 471.899 64.1671Z" fill="#DEDEDE"/>
<path d="M464.61 63.6813C465.159 65.2025 466.617 66.5125 467.906 66.597C469.173 66.6815 469.765 65.5195 469.195 63.9982C468.645 62.477 467.166 61.1881 465.899 61.1036C464.631 60.998 464.06 62.16 464.61 63.6813Z" fill="#DEDEDE"/>
<path d="M457.3 63.2167C457.828 64.7379 459.286 66.0268 460.554 66.1113C461.822 66.1958 462.413 65.0337 461.885 63.5125C461.336 61.9912 459.878 60.7024 458.631 60.6179C457.363 60.5333 456.772 61.6954 457.3 63.2167Z" fill="#DEDEDE"/>
<path d="M449.969 62.7726C450.476 64.2938 451.934 65.5827 453.201 65.6672C454.469 65.7517 455.082 64.5685 454.575 63.0472C454.046 61.526 452.61 60.2372 451.342 60.1738C450.074 60.0893 449.461 61.2513 449.969 62.7726Z" fill="#DEDEDE"/>
<path d="M442.658 62.3288C443.144 63.8501 444.58 65.1389 445.869 65.2023C447.137 65.2868 447.771 64.1036 447.264 62.5824C446.756 61.0611 445.32 59.7934 444.052 59.7089C442.784 59.6666 442.172 60.8287 442.658 62.3288Z" fill="#DEDEDE"/>
<path d="M435.326 61.9276C435.791 63.4489 437.228 64.7166 438.495 64.8011C439.763 64.8645 440.418 63.7024 439.932 62.1811C439.446 60.6599 438.03 59.3922 436.763 59.3288C435.495 59.2443 434.861 60.4063 435.326 61.9276Z" fill="#DEDEDE"/>
<path d="M427.994 61.5261C428.459 63.0262 429.853 64.315 431.142 64.3784C432.431 64.4418 433.086 63.2797 432.6 61.7585C432.135 60.2372 430.719 58.9695 429.452 58.9061C428.184 58.8639 427.529 60.026 427.994 61.5261Z" fill="#DEDEDE"/>
<path d="M420.642 61.1457C421.085 62.6458 422.48 63.9347 423.747 63.9981C425.036 64.0614 425.691 62.8994 425.248 61.3781C424.804 59.878 423.409 58.5892 422.142 58.5258C420.895 58.4835 420.219 59.6456 420.642 61.1457Z" fill="#DEDEDE"/>
<path d="M413.31 60.7866C413.732 62.2867 415.106 63.5755 416.394 63.6389C417.683 63.7023 418.359 62.5191 417.937 61.019C417.493 59.5189 416.12 58.2512 414.852 58.1878C413.563 58.1244 412.887 59.2865 413.31 60.7866Z" fill="#DEDEDE"/>
<path d="M405.956 60.4488C406.358 61.9489 407.731 63.2166 408.999 63.28C410.288 63.3434 410.985 62.1602 410.562 60.6601C410.14 59.1599 408.787 57.8922 407.52 57.8288C406.252 57.7655 405.555 58.9487 405.956 60.4488Z" fill="#DEDEDE"/>
<path d="M398.605 60.1317C398.986 61.6318 400.338 62.8995 401.627 62.9629C402.916 63.0263 403.634 61.8431 403.233 60.343C402.831 58.8429 401.479 57.5752 400.211 57.5329C398.922 57.4484 398.204 58.6105 398.605 60.1317Z" fill="#DEDEDE"/>
<path d="M391.23 59.8147C391.59 61.3148 392.942 62.5825 394.231 62.6248C395.52 62.6881 396.238 61.5049 395.858 60.0048C395.477 58.5047 394.146 57.237 392.879 57.1947C391.59 57.1525 390.871 58.3145 391.23 59.8147Z" fill="#DEDEDE"/>
<path d="M383.857 59.5185C384.216 61.0187 385.526 62.2864 386.815 62.3286C388.104 62.3709 388.843 61.1877 388.484 59.6876C388.125 58.1875 386.794 56.9409 385.526 56.8775C384.259 56.8564 383.519 58.0184 383.857 59.5185Z" fill="#DEDEDE"/>
<path d="M376.482 59.2441C376.821 60.7442 378.131 61.9908 379.419 62.0542C380.708 62.0964 381.469 60.9132 381.131 59.4131C380.793 57.913 379.483 56.6664 378.194 56.6241C376.926 56.5819 376.166 57.7651 376.482 59.2441Z" fill="#DEDEDE"/>
<path d="M369.109 59.0115C369.426 60.5117 370.715 61.7583 372.004 61.8005C373.293 61.8428 374.074 60.6596 373.736 59.1594C373.419 57.6593 372.109 56.4127 370.842 56.3705C369.574 56.3282 368.792 57.5114 369.109 59.0115Z" fill="#DEDEDE"/>
<path d="M361.735 58.758C362.031 60.2581 363.319 61.5047 364.608 61.547C365.897 61.5892 366.7 60.406 366.383 58.9059C366.066 57.4058 364.798 56.1592 363.51 56.1381C362.221 56.0958 361.439 57.279 361.735 58.758Z" fill="#DEDEDE"/>
<path d="M354.34 58.5473C354.615 60.0474 355.883 61.294 357.172 61.3363C358.46 61.3785 359.263 60.1953 358.989 58.6952C358.693 57.1951 357.425 55.9696 356.157 55.9274C354.869 55.8851 354.066 57.0472 354.34 58.5473Z" fill="#DEDEDE"/>
<path d="M346.946 58.357C347.199 59.8571 348.467 61.1037 349.756 61.1248C351.045 61.1671 351.869 59.9628 351.594 58.4838C351.319 56.9837 350.073 55.7582 348.805 55.7159C347.516 55.6737 346.692 56.8569 346.946 58.357Z" fill="#DEDEDE"/>
<path d="M339.55 58.1667C339.782 59.6669 341.029 60.8923 342.318 60.9346C343.606 60.9557 344.451 59.7725 344.198 58.2724C343.944 56.7723 342.698 55.5468 341.43 55.5257C340.162 55.5045 339.317 56.6666 339.55 58.1667Z" fill="#DEDEDE"/>
<path d="M332.154 57.9973C332.366 59.4974 333.591 60.7229 334.88 60.7651C336.169 60.7863 337.035 59.6031 336.803 58.1029C336.57 56.6028 335.345 55.3774 334.077 55.3562C332.788 55.3351 331.922 56.5183 332.154 57.9973Z" fill="#DEDEDE"/>
<path d="M324.739 57.8712C324.95 59.3713 326.155 60.5968 327.444 60.6179C328.732 60.639 329.599 59.4559 329.387 57.9557C329.176 56.4556 327.972 55.2301 326.683 55.209C325.415 55.1879 324.549 56.3711 324.739 57.8712Z" fill="#DEDEDE"/>
<path d="M317.344 57.7442C317.534 59.2443 318.717 60.4698 320.006 60.4909C321.295 60.5121 322.182 59.3077 321.992 57.8287C321.802 56.3286 320.597 55.1243 319.33 55.1031C318.041 55.0609 317.153 56.2441 317.344 57.7442Z" fill="#DEDEDE"/>
<path d="M309.927 57.6383C310.096 59.1384 311.28 60.3638 312.569 60.385C313.857 60.4061 314.766 59.2018 314.597 57.7228C314.428 56.2226 313.245 55.0183 311.956 54.9972C310.667 54.9549 309.758 56.1381 309.927 57.6383Z" fill="#DEDEDE"/>
<path d="M302.513 57.5329C302.66 59.0331 303.822 60.2585 305.111 60.2585C306.4 60.2796 307.33 59.0753 307.161 57.5752C306.992 56.0751 305.83 54.8707 304.562 54.8496C303.294 54.8496 302.365 56.0539 302.513 57.5329Z" fill="#DEDEDE"/>
<path d="M295.096 57.4691C295.223 58.9692 296.364 60.1736 297.674 60.1947C298.962 60.2158 299.913 59.0115 299.765 57.5114C299.617 56.0113 298.476 54.8069 297.188 54.8069C295.92 54.7858 294.969 55.969 295.096 57.4691Z" fill="#DEDEDE"/>
<path d="M287.68 57.4064C287.786 58.8854 288.926 60.1108 290.215 60.1319C291.504 60.1319 292.455 58.9276 292.349 57.4486C292.223 55.9485 291.103 54.7442 289.814 54.7442C288.525 54.723 287.574 55.9274 287.68 57.4064Z" fill="#DEDEDE"/>
<path d="M280.264 57.385C280.348 58.864 281.468 60.0894 282.757 60.0894C284.046 60.0894 285.018 58.8851 284.912 57.4061C284.807 55.9271 283.687 54.7017 282.419 54.7017C281.151 54.7017 280.179 55.8849 280.264 57.385Z" fill="#DEDEDE"/>
<path d="M272.848 57.3636C272.912 58.8426 274.031 60.0681 275.32 60.0681C276.609 60.0681 277.602 58.8638 277.518 57.3636C277.433 55.8846 276.335 54.6803 275.046 54.6592C273.757 54.6803 272.764 55.8846 272.848 57.3636Z" fill="#DEDEDE"/>
<path d="M265.41 57.3635C265.452 58.8425 266.551 60.0679 267.84 60.0679C269.129 60.0679 270.122 58.8636 270.079 57.3635C270.016 55.8845 268.917 54.6802 267.65 54.6802C266.361 54.6802 265.368 55.8845 265.41 57.3635Z" fill="#DEDEDE"/>
<path d="M257.994 57.3846C258.015 58.8636 259.093 60.0891 260.403 60.0679C261.691 60.0679 262.706 58.8425 262.663 57.3635C262.621 55.8634 261.544 54.6802 260.255 54.6802C258.966 54.7013 257.952 55.9056 257.994 57.3846Z" fill="#DEDEDE"/>
<path d="M250.557 57.4271C250.578 58.9061 251.634 60.1316 252.923 60.1104C254.212 60.1104 255.247 58.885 255.226 57.406C255.205 55.927 254.149 54.7227 252.86 54.7227C251.571 54.7227 250.557 55.9481 250.557 57.4271Z" fill="#DEDEDE"/>
<path d="M168.81 59.0753C168.62 60.5755 169.508 61.7587 170.796 61.7164C172.085 61.6742 173.29 60.4276 173.48 58.9274C173.67 57.4273 172.761 56.2653 171.494 56.3075C170.205 56.3498 169 57.5752 168.81 59.0753Z" fill="#DEDEDE"/>
<path d="M161.373 59.3284C161.162 60.8285 162.028 62.0117 163.338 61.9484C164.627 61.9061 165.852 60.6384 166.042 59.1594C166.254 57.6593 165.366 56.4972 164.077 56.5394C162.81 56.6028 161.584 57.8494 161.373 59.3284Z" fill="#DEDEDE"/>
<path d="M153.957 59.6244C153.724 61.1246 154.591 62.3078 155.879 62.2444C157.168 62.2021 158.394 60.9344 158.626 59.4343C158.837 57.9342 157.992 56.7721 156.703 56.8143C155.415 56.8777 154.189 58.1243 153.957 59.6244Z" fill="#DEDEDE"/>
<path d="M146.521 59.9201C146.267 61.4202 147.112 62.6034 148.401 62.54C149.69 62.4978 150.936 61.2301 151.169 59.7299C151.401 58.2298 150.556 57.0677 149.288 57.11C148.021 57.1734 146.774 58.42 146.521 59.9201Z" fill="#DEDEDE"/>
<path d="M139.104 60.2372C138.829 61.7374 139.653 62.9206 140.963 62.8572C142.252 62.7938 143.519 61.5261 143.773 60.0471C144.027 58.547 143.203 57.3849 141.914 57.4483C140.625 57.4694 139.357 58.7371 139.104 60.2372Z" fill="#DEDEDE"/>
<path d="M87.1904 62.9419C86.789 64.442 87.5073 65.6252 88.7962 65.5407C90.085 65.4562 91.4373 64.1673 91.8387 62.6672C92.219 61.1671 91.5007 60.005 90.2118 60.0895C88.9441 60.1529 87.5707 61.4418 87.1904 62.9419Z" fill="#DEDEDE"/>
<path d="M79.7746 63.4067C79.352 64.9279 80.0704 66.09 81.3592 66.0055C82.6481 65.921 84.0214 64.6321 84.4229 63.1109C84.8243 61.5896 84.1271 60.4487 82.8382 60.5332C81.5705 60.6177 80.1972 61.9066 79.7746 63.4067Z" fill="#DEDEDE"/>
<path d="M72.3799 63.8928C71.9362 65.414 72.6334 66.5761 73.9222 66.4916C75.2111 66.4071 76.6056 65.1182 77.0281 63.597C77.4507 62.0757 76.7535 60.9348 75.4858 61.0193C74.1969 61.0827 72.8024 62.3715 72.3799 63.8928Z" fill="#DEDEDE"/>
<path d="M64.9842 64.3785C64.5405 65.8998 65.2166 67.0618 66.5055 66.9773C67.7943 66.8928 69.1888 65.5828 69.6325 64.0616C70.0762 62.5403 69.4001 61.3994 68.1112 61.4839C66.8435 61.5684 65.4279 62.8784 64.9842 64.3785Z" fill="#DEDEDE"/>
<path d="M57.5903 64.9068C57.1255 66.4281 57.7804 67.5902 59.0693 67.5056C60.3581 67.4211 61.7737 66.1112 62.2386 64.5899C62.7034 63.0686 62.0273 61.9277 60.7596 62.0122C59.4707 62.0967 58.0551 63.3856 57.5903 64.9068Z" fill="#DEDEDE"/>
<path d="M50.1947 65.435C49.7087 66.9563 50.3637 68.1184 51.6526 68.0127C52.9414 67.9282 54.3781 66.5971 54.843 65.097C55.3078 63.5757 54.6739 62.4348 53.3851 62.5193C52.1174 62.6249 50.6807 63.9138 50.1947 65.435Z" fill="#DEDEDE"/>
<path d="M42.8213 65.9842C42.3142 67.5055 42.948 68.6676 44.2369 68.5619C45.5257 68.4563 46.9625 67.1463 47.4484 65.6251C47.9344 64.1038 47.3005 62.9629 46.0328 63.0474C44.7651 63.153 43.3283 64.463 42.8213 65.9842Z" fill="#DEDEDE"/>
<path d="M639.321 71.8579C640.229 73.4426 641.962 74.8371 643.187 74.985C644.413 75.1329 644.645 73.9708 643.715 72.4073C642.786 70.8227 641.075 69.4282 639.87 69.2803C638.645 69.1324 638.412 70.2944 639.321 71.8579Z" fill="#DEDEDE"/>
<path d="M632.307 71.0127C633.216 72.5762 634.927 73.9918 636.153 74.1397C637.378 74.2876 637.632 73.1255 636.723 71.562C635.814 69.9774 634.103 68.5829 632.878 68.435C631.673 68.2871 631.42 69.4281 632.307 71.0127Z" fill="#DEDEDE"/>
<path d="M625.293 70.1677C626.18 71.7312 627.892 73.1257 629.117 73.2736C630.343 73.4215 630.596 72.2594 629.709 70.6959C628.821 69.1324 627.11 67.7379 625.885 67.59C624.68 67.4421 624.406 68.5831 625.293 70.1677Z" fill="#DEDEDE"/>
<path d="M618.278 69.3224C619.144 70.8859 620.856 72.2804 622.081 72.4283C623.306 72.5762 623.581 71.4141 622.694 69.8506C621.806 68.2871 620.116 66.8926 618.891 66.7658C617.686 66.6179 617.39 67.7589 618.278 69.3224Z" fill="#DEDEDE"/>
<path d="M611.241 68.5196C612.108 70.0831 613.798 71.4776 615.023 71.6043C616.249 71.7522 616.545 70.5902 615.657 69.0267C614.791 67.4631 613.101 66.0898 611.875 65.9419C610.671 65.794 610.375 66.9561 611.241 68.5196Z" fill="#DEDEDE"/>
<path d="M604.206 67.7164C605.051 69.2799 606.741 70.6533 607.967 70.8012C609.192 70.9491 609.488 69.787 608.643 68.2235C607.777 66.66 606.108 65.2866 604.882 65.1599C603.657 65.012 603.361 66.1529 604.206 67.7164Z" fill="#DEDEDE"/>
<path d="M597.148 66.935C597.972 68.4985 599.663 69.8718 600.888 69.9986C602.114 70.1254 602.43 68.9844 601.585 67.4209C600.74 65.8574 599.071 64.484 597.846 64.3573C596.62 64.2305 596.303 65.3714 597.148 66.935Z" fill="#DEDEDE"/>
<path d="M590.092 66.1742C590.916 67.7166 592.585 69.0899 593.811 69.2378C595.036 69.3646 595.353 68.2236 594.529 66.6601C593.705 65.0966 592.036 63.7444 590.811 63.6176C589.585 63.4697 589.268 64.6107 590.092 66.1742Z" fill="#DEDEDE"/>
<path d="M583.014 65.414C583.816 66.9564 585.465 68.3298 586.711 68.4566C587.937 68.5833 588.275 67.4424 587.472 65.8789C586.648 64.3365 585 62.9631 583.774 62.8364C582.549 62.7307 582.211 63.8717 583.014 65.414Z" fill="#DEDEDE"/>
<path d="M575.936 64.6738C576.739 66.2162 578.366 67.5896 579.612 67.7163C580.838 67.8431 581.197 66.681 580.394 65.1387C579.591 63.5963 577.943 62.2441 576.718 62.1173C575.492 61.9905 575.133 63.1315 575.936 64.6738Z" fill="#DEDEDE"/>
<path d="M568.837 63.9558C569.618 65.4982 571.245 66.8504 572.492 66.9772C573.717 67.104 574.098 65.9419 573.295 64.3995C572.513 62.8571 570.865 61.5049 569.661 61.3781C568.435 61.2725 568.055 62.4134 568.837 63.9558Z" fill="#DEDEDE"/>
<path d="M561.738 63.2586C562.499 64.8009 564.126 66.1532 565.351 66.2799C566.598 66.4067 566.957 65.2446 566.196 63.7023C565.415 62.1599 563.809 60.8076 562.583 60.702C561.358 60.5752 560.978 61.7162 561.738 63.2586Z" fill="#DEDEDE"/>
<path d="M554.639 62.5827C555.379 64.1251 557.006 65.4562 558.231 65.583C559.478 65.7098 559.858 64.5477 559.097 63.0053C558.337 61.4629 556.731 60.1318 555.505 60.0051C554.28 59.8994 553.878 61.0404 554.639 62.5827Z" fill="#DEDEDE"/>
<path d="M547.518 61.9065C548.258 63.4489 549.842 64.78 551.089 64.9068C552.335 65.0124 552.737 63.8715 551.976 62.3291C551.237 60.7867 549.631 59.4556 548.405 59.35C547.18 59.2232 546.779 60.3641 547.518 61.9065Z" fill="#DEDEDE"/>
<path d="M540.398 61.2518C541.117 62.7731 542.701 64.1253 543.948 64.231C545.195 64.3366 545.596 63.1957 544.878 61.6533C544.138 60.1109 542.554 58.8009 541.328 58.6742C540.103 58.5685 539.68 59.7306 540.398 61.2518Z" fill="#DEDEDE"/>
<path d="M533.257 60.6177C533.954 62.1389 535.539 63.47 536.785 63.5968C538.032 63.7024 538.454 62.5615 537.736 61.0191C537.018 59.4979 535.454 58.1668 534.208 58.0611C532.982 57.9344 532.56 59.0753 533.257 60.6177Z" fill="#DEDEDE"/>
<path d="M526.115 59.9835C526.813 61.5047 528.376 62.8358 529.623 62.9415C530.869 63.0471 531.313 61.9062 530.616 60.3638C529.919 58.8425 528.355 57.5114 527.109 57.4058C525.883 57.3213 525.439 58.4622 526.115 59.9835Z" fill="#DEDEDE"/>
<path d="M518.973 59.3922C519.649 60.9134 521.192 62.2445 522.438 62.3502C523.685 62.4558 524.129 61.2938 523.453 59.7725C522.776 58.2512 521.213 56.9413 519.987 56.8356C518.762 56.73 518.297 57.8709 518.973 59.3922Z" fill="#DEDEDE"/>
<path d="M511.811 58.8008C512.466 60.3221 514.009 61.6321 515.255 61.7377C516.502 61.8433 516.967 60.6813 516.312 59.16C515.636 57.6388 514.093 56.3288 512.868 56.2232C511.642 56.1386 511.156 57.2796 511.811 58.8008Z" fill="#DEDEDE"/>
<path d="M504.648 58.2301C505.282 59.7513 506.824 61.0613 508.071 61.167C509.317 61.2726 509.803 60.1105 509.148 58.5893C508.493 57.068 506.972 55.758 505.726 55.6735C504.5 55.5679 504.014 56.7088 504.648 58.2301Z" fill="#DEDEDE"/>
<path d="M497.486 57.6807C498.12 59.202 499.641 60.5119 500.888 60.5965C502.135 60.7021 502.642 59.54 502.008 58.0188C501.374 56.4975 499.853 55.2087 498.606 55.103C497.36 55.0185 496.852 56.1595 497.486 57.6807Z" fill="#DEDEDE"/>
<path d="M490.302 57.1313C490.915 58.6315 492.415 59.9414 493.662 60.0471C494.908 60.1316 495.415 58.9906 494.802 57.4694C494.19 55.9481 492.668 54.6593 491.443 54.5748C490.218 54.4691 489.689 55.6312 490.302 57.1313Z" fill="#DEDEDE"/>
<path d="M483.118 56.6034C483.71 58.1035 485.21 59.4135 486.457 59.498C487.703 59.5825 488.232 58.4416 487.64 56.9203C487.027 55.4202 485.548 54.1102 484.302 54.0257C483.055 53.9623 482.527 55.1033 483.118 56.6034Z" fill="#DEDEDE"/>
<path d="M475.935 56.0964C476.506 57.5965 477.985 58.9064 479.253 58.991C480.499 59.0755 481.048 57.9134 480.457 56.4133C479.865 54.9132 478.386 53.6243 477.14 53.5398C475.893 53.4553 475.365 54.5962 475.935 56.0964Z" fill="#DEDEDE"/>
<path d="M468.73 55.6097C469.279 57.1099 470.758 58.3987 472.005 58.4832C473.251 58.5677 473.822 57.4057 473.251 55.9055C472.681 54.4054 471.202 53.1166 469.976 53.0321C468.73 52.9687 468.18 54.1096 468.73 55.6097Z" fill="#DEDEDE"/>
<path d="M461.524 55.1454C462.074 56.6456 463.532 57.9344 464.778 58.0189C466.025 58.1034 466.595 56.9414 466.046 55.4412C465.497 53.9411 464.039 52.6523 462.792 52.5889C461.546 52.5044 460.996 53.6453 461.524 55.1454Z" fill="#DEDEDE"/>
<path d="M454.321 54.7016C454.849 56.2018 456.286 57.4906 457.554 57.554C458.8 57.6385 459.392 56.4764 458.864 54.9763C458.335 53.4762 456.878 52.2085 455.631 52.124C454.384 52.0394 453.793 53.2015 454.321 54.7016Z" fill="#DEDEDE"/>
<path d="M447.095 54.2577C447.602 55.7579 449.038 57.0256 450.285 57.1101C451.553 57.1946 452.144 56.0325 451.616 54.5324C451.088 53.0323 449.672 51.7646 448.426 51.7012C447.2 51.6167 446.588 52.7576 447.095 54.2577Z" fill="#DEDEDE"/>
<path d="M439.868 53.8353C440.354 55.3355 441.77 56.6032 443.038 56.6666C444.305 56.7299 444.918 55.589 444.411 54.0889C443.904 52.5887 442.488 51.321 441.242 51.2576C439.995 51.1943 439.382 52.3352 439.868 53.8353Z" fill="#DEDEDE"/>
<path d="M432.642 53.434C433.107 54.9341 434.522 56.2018 435.79 56.2652C437.058 56.3286 437.67 55.1665 437.184 53.6875C436.698 52.1874 435.283 50.9408 434.057 50.8563C432.79 50.7929 432.177 51.9338 432.642 53.434Z" fill="#DEDEDE"/>
<path d="M425.416 53.0539C425.881 54.5329 427.255 55.8006 428.522 55.8851C429.79 55.9485 430.424 54.7864 429.959 53.3074C429.494 51.8073 428.1 50.5607 426.853 50.4973C425.607 50.4128 424.952 51.5538 425.416 53.0539Z" fill="#DEDEDE"/>
<path d="M418.17 52.6735C418.614 54.1525 419.987 55.4203 421.255 55.4836C422.522 55.547 423.177 54.385 422.734 52.906C422.29 51.427 420.917 50.1592 419.649 50.0959C418.381 50.0536 417.726 51.1945 418.17 52.6735Z" fill="#DEDEDE"/>
<path d="M410.922 52.3351C411.345 53.8141 412.718 55.0818 413.965 55.1452C415.233 55.2086 415.909 54.0465 415.465 52.5675C415.042 51.0885 413.669 49.842 412.422 49.7786C411.176 49.6941 410.5 50.835 410.922 52.3351Z" fill="#DEDEDE"/>
<path d="M403.675 51.9969C404.076 53.4759 405.428 54.7436 406.696 54.7859C407.964 54.8492 408.64 53.6872 408.238 52.1871C407.816 50.7081 406.464 49.4615 405.217 49.3981C403.949 49.3558 403.273 50.5179 403.675 51.9969Z" fill="#DEDEDE"/>
<path d="M396.407 51.68C396.787 53.159 398.118 54.4056 399.386 54.469C400.654 54.5324 401.351 53.3703 400.95 51.8702C400.548 50.3912 399.217 49.1446 397.971 49.1023C396.724 49.0389 396.027 50.201 396.407 51.68Z" fill="#DEDEDE"/>
<path d="M389.16 51.3846C389.519 52.8636 390.85 54.1102 392.118 54.1736C393.386 54.2158 394.104 53.0538 393.724 51.5748C393.343 50.0958 392.012 48.8492 390.766 48.8069C389.498 48.7435 388.78 49.9056 389.16 51.3846Z" fill="#DEDEDE"/>
<path d="M381.892 51.1097C382.23 52.5887 383.54 53.8353 384.808 53.8775C386.075 53.9198 386.815 52.7577 386.456 51.2787C386.097 49.7997 384.787 48.5743 383.54 48.5109C382.272 48.4686 381.533 49.6307 381.892 51.1097Z" fill="#DEDEDE"/>
<path d="M374.623 50.8349C374.961 52.3139 376.25 53.5605 377.518 53.6028C378.786 53.645 379.525 52.4829 379.187 51.0039C378.849 49.525 377.56 48.2995 376.292 48.2572C375.025 48.215 374.285 49.3559 374.623 50.8349Z" fill="#DEDEDE"/>
<path d="M367.334 50.6029C367.651 52.0819 368.919 53.3073 370.187 53.3496C371.454 53.3918 372.215 52.2298 371.898 50.7508C371.581 49.2718 370.292 48.0463 369.046 48.004C367.799 47.9618 367.038 49.1239 367.334 50.6029Z" fill="#DEDEDE"/>
<path d="M360.066 50.3702C360.361 51.8492 361.629 53.0746 362.897 53.1169C364.164 53.1592 364.946 51.976 364.629 50.5181C364.333 49.0391 363.066 47.8136 361.798 47.7714C360.551 47.7291 359.77 48.8912 360.066 50.3702Z" fill="#DEDEDE"/>
<path d="M352.777 50.1588C353.051 51.6378 354.298 52.8632 355.566 52.9055C356.833 52.9477 357.636 51.7645 357.34 50.2855C357.045 48.8065 355.798 47.5811 354.551 47.56C353.305 47.5177 352.502 48.6798 352.777 50.1588Z" fill="#DEDEDE"/>
<path d="M345.488 49.9693C345.742 51.4483 346.988 52.6738 348.256 52.6949C349.524 52.7372 350.348 51.554 350.073 50.075C349.798 48.596 348.573 47.3917 347.305 47.3494C346.037 47.3283 345.235 48.4903 345.488 49.9693Z" fill="#DEDEDE"/>
<path d="M323.598 49.4825C323.788 50.9615 324.992 52.1658 326.26 52.187C327.528 52.2081 328.394 51.0249 328.183 49.567C327.972 48.088 326.767 46.8837 325.521 46.8626C324.274 46.8415 323.408 48.0246 323.598 49.4825Z" fill="#DEDEDE"/>
<path d="M316.309 49.377C316.5 50.856 317.662 52.0604 318.95 52.0815C320.218 52.1026 321.106 50.9194 320.894 49.4615C320.704 47.9825 319.521 46.7782 318.253 46.7782C317.007 46.736 316.119 47.898 316.309 49.377Z" fill="#DEDEDE"/>
<path d="M308.999 49.2718C309.168 50.7508 310.33 51.9551 311.598 51.9763C312.865 51.9974 313.753 50.8142 313.584 49.3352C313.415 47.8562 312.253 46.673 310.985 46.6519C309.738 46.6308 308.83 47.7928 308.999 49.2718Z" fill="#DEDEDE"/>
<path d="M301.688 49.1872C301.836 50.6662 302.977 51.8705 304.266 51.8705C305.533 51.8917 306.442 50.7085 306.294 49.2295C306.146 47.7505 304.984 46.5673 303.716 46.5461C302.448 46.5461 301.561 47.7082 301.688 49.1872Z" fill="#DEDEDE"/>
<path d="M294.378 49.1027C294.505 50.5606 295.646 51.7649 296.913 51.7861C298.181 51.8072 299.111 50.6029 298.963 49.145C298.815 47.666 297.695 46.4828 296.427 46.4617C295.181 46.4617 294.272 47.6449 294.378 49.1027Z" fill="#DEDEDE"/>
<path d="M287.067 49.0602C287.173 50.518 288.293 51.7224 289.56 51.7435C290.828 51.7435 291.779 50.5603 291.652 49.1024C291.525 47.6446 290.405 46.4402 289.159 46.4402C287.912 46.4191 286.961 47.5812 287.067 49.0602Z" fill="#DEDEDE"/>
<path d="M279.756 49.0182C279.841 50.476 280.939 51.6803 282.228 51.6803C283.496 51.6803 284.447 50.4972 284.362 49.0182C284.257 47.5603 283.158 46.356 281.89 46.356C280.622 46.3771 279.672 47.5603 279.756 49.0182Z" fill="#DEDEDE"/>
<path d="M272.446 49.0182C272.509 50.476 273.608 51.6803 274.876 51.6803C276.143 51.6803 277.115 50.4972 277.031 49.0182C276.946 47.5603 275.869 46.356 274.601 46.356C273.354 46.356 272.382 47.5392 272.446 49.0182Z" fill="#DEDEDE"/>
<path d="M265.135 49.0178C265.177 50.4757 266.255 51.68 267.544 51.68C268.812 51.68 269.805 50.4757 269.741 49.0178C269.678 47.5599 268.6 46.3767 267.333 46.3767C266.065 46.3556 265.072 47.5388 265.135 49.0178Z" fill="#DEDEDE"/>
<path d="M118.672 52.8423C118.355 54.3213 119.116 55.4833 120.383 55.4199C121.651 55.3566 122.94 54.0889 123.257 52.6099C123.574 51.1309 122.792 49.9899 121.545 50.0533C120.278 50.1167 118.989 51.3633 118.672 52.8423Z" fill="#DEDEDE"/>
<path d="M111.361 53.2224C111.023 54.7014 111.763 55.8635 113.052 55.8001C114.319 55.7367 115.629 54.469 115.967 52.99C116.306 51.511 115.545 50.37 114.277 50.4334C113.009 50.4968 111.7 51.7434 111.361 53.2224Z" fill="#DEDEDE"/>
<path d="M104.052 53.6241C103.693 55.1031 104.432 56.2652 105.7 56.2018C106.968 56.1384 108.299 54.8707 108.637 53.3706C108.975 51.8916 108.236 50.7507 106.989 50.814C105.721 50.8774 104.411 52.1451 104.052 53.6241Z" fill="#DEDEDE"/>
<path d="M96.7413 54.0466C96.361 55.5256 97.0793 56.6877 98.3682 56.6032C99.6359 56.5398 100.967 55.251 101.347 53.772C101.706 52.293 100.988 51.152 99.7204 51.2154C98.4527 51.2788 97.1216 52.5465 96.7413 54.0466Z" fill="#DEDEDE"/>
<path d="M89.4318 54.4692C89.0303 55.9694 89.7487 57.1103 91.0164 57.0258C92.2841 56.9413 93.6363 55.6736 94.0166 54.1946C94.3969 52.7156 93.6997 51.5746 92.432 51.638C91.1643 51.7225 89.8332 52.9902 89.4318 54.4692Z" fill="#DEDEDE"/>
<path d="M82.1206 54.9128C81.6981 56.4129 82.3953 57.5538 83.663 57.4693C84.9307 57.3848 86.3041 56.1171 86.7055 54.617C87.107 53.1168 86.4097 51.997 85.142 52.0604C83.8954 52.1661 82.5432 53.4338 82.1206 54.9128Z" fill="#DEDEDE"/>
<path d="M74.8308 55.399C74.4083 56.8991 75.0844 58.0401 76.3521 57.9555C77.6198 57.871 78.9932 56.5822 79.4157 55.1032C79.8383 53.6031 79.1622 52.4621 77.8945 52.5466C76.6268 52.6312 75.2534 53.8989 74.8308 55.399Z" fill="#DEDEDE"/>
<path d="M67.5215 55.8847C67.0778 57.3848 67.7328 58.5258 69.0005 58.4413C70.2682 58.3568 71.6627 57.0679 72.1064 55.5678C72.5501 54.0677 71.874 52.9267 70.6274 53.0112C69.3597 53.0958 67.9863 54.3846 67.5215 55.8847Z" fill="#DEDEDE"/>
<path d="M60.2311 56.3708C59.7663 57.8709 60.4213 59.0119 61.689 58.9274C62.9567 58.8428 64.3512 57.554 64.816 56.0539C65.2809 54.5538 64.6259 53.4128 63.3582 53.4973C62.1116 53.603 60.696 54.8707 60.2311 56.3708Z" fill="#DEDEDE"/>
<path d="M52.9418 56.8991C52.4559 58.3992 53.0897 59.5401 54.3574 59.4556C55.6252 59.3711 57.0408 58.0611 57.5056 56.561C57.9704 55.0609 57.3366 53.9199 56.09 54.0256C54.8434 54.1101 53.4278 55.3989 52.9418 56.8991Z" fill="#DEDEDE"/>
<path d="M45.6516 57.4484C45.1445 58.9485 45.7784 60.0894 47.0461 60.0049C48.3138 59.8993 49.7505 58.6104 50.2365 57.1103C50.7225 55.6102 50.1097 54.4693 48.842 54.5749C47.5954 54.6383 46.1587 55.9271 45.6516 57.4484Z" fill="#DEDEDE"/>
<path d="M634.166 62.9843C635.074 64.5478 636.786 65.9423 637.99 66.0902C639.194 66.2381 639.427 65.076 638.518 63.5125C637.61 61.949 635.877 60.5545 634.694 60.4066C633.489 60.2798 633.257 61.4208 634.166 62.9843Z" fill="#DEDEDE"/>
<path d="M627.236 62.1391C628.124 63.7027 629.835 65.076 631.04 65.2239C632.244 65.3718 632.497 64.2097 631.589 62.6462C630.68 61.0827 628.969 59.7094 627.786 59.5615C626.582 59.4347 626.328 60.5968 627.236 62.1391Z" fill="#DEDEDE"/>
<path d="M620.285 61.3144C621.172 62.878 622.862 64.2513 624.067 64.3992C625.271 64.5471 625.524 63.385 624.637 61.8427C623.75 60.2791 622.059 58.9058 620.855 58.779C619.672 58.6311 619.397 59.7721 620.285 61.3144Z" fill="#DEDEDE"/>
<path d="M613.333 60.5117C614.2 62.0541 615.89 63.4486 617.094 63.5754C618.299 63.7233 618.573 62.5612 617.686 61.0188C616.799 59.4553 615.129 58.1031 613.925 57.9552C612.742 57.8284 612.467 58.9693 613.333 60.5117Z" fill="#DEDEDE"/>
<path d="M606.383 59.7303C607.249 61.2726 608.918 62.646 610.123 62.7939C611.327 62.9418 611.623 61.7797 610.735 60.2373C609.869 58.6949 608.2 57.3216 606.996 57.1948C605.812 57.0258 605.517 58.1667 606.383 59.7303Z" fill="#DEDEDE"/>
<path d="M599.409 58.948C600.254 60.4904 601.924 61.8637 603.128 61.9905C604.353 62.1173 604.628 60.9764 603.783 59.434C602.938 57.8916 601.269 56.5182 600.064 56.3915C598.86 56.2647 598.564 57.4056 599.409 58.948Z" fill="#DEDEDE"/>
<path d="M592.437 58.1877C593.261 59.7301 594.93 61.0823 596.135 61.2302C597.36 61.357 597.656 60.2161 596.811 58.6737C595.965 57.1313 594.317 55.7791 593.113 55.6523C591.909 55.5044 591.592 56.6453 592.437 58.1877Z" fill="#DEDEDE"/>
<path d="M585.443 57.4276C586.267 58.97 587.916 60.3222 589.12 60.449C590.345 60.5757 590.641 59.4348 589.817 57.8924C588.993 56.35 587.345 54.9978 586.141 54.871C584.936 54.7654 584.619 55.9063 585.443 57.4276Z" fill="#DEDEDE"/>
<path d="M578.45 56.7087C579.253 58.2511 580.901 59.5822 582.105 59.7301C583.331 59.8569 583.648 58.7159 582.845 57.1736C582.021 55.6312 580.394 54.3001 579.19 54.1733C577.985 54.0254 577.647 55.1663 578.45 56.7087Z" fill="#DEDEDE"/>
<path d="M571.456 55.9898C572.238 57.5111 573.886 58.8633 575.091 58.9901C576.316 59.1169 576.654 57.9759 575.851 56.4335C575.048 54.8912 573.421 53.5601 572.217 53.4333C571.013 53.3277 570.654 54.4686 571.456 55.9898Z" fill="#DEDEDE"/>
<path d="M564.442 55.2933C565.224 56.8146 566.83 58.1668 568.055 58.2724C569.281 58.3992 569.619 57.2583 568.837 55.7159C568.055 54.1946 566.428 52.8635 565.224 52.7368C564.02 52.6311 563.661 53.7721 564.442 55.2933Z" fill="#DEDEDE"/>
<path d="M557.428 54.596C558.189 56.1173 559.795 57.4484 561.02 57.5752C562.246 57.7019 562.605 56.5399 561.844 55.0186C561.062 53.4974 559.457 52.1663 558.252 52.0606C557.027 51.955 556.668 53.0748 557.428 54.596Z" fill="#DEDEDE"/>
<path d="M550.413 53.9408C551.152 55.462 552.758 56.7931 553.983 56.8988C555.209 57.0044 555.589 55.8635 554.828 54.3422C554.068 52.821 552.483 51.4899 551.258 51.3842C550.032 51.2786 549.652 52.4195 550.413 53.9408Z" fill="#DEDEDE"/>
<path d="M543.377 53.2857C544.117 54.807 545.701 56.1169 546.927 56.2437C548.152 56.3494 548.533 55.2084 547.793 53.6872C547.054 52.1659 545.469 50.8559 544.244 50.7503C543.039 50.6447 542.638 51.7645 543.377 53.2857Z" fill="#DEDEDE"/>
<path d="M536.341 52.6519C537.059 54.1732 538.644 55.4832 539.87 55.5888C541.095 55.6944 541.496 54.5535 540.778 53.0534C540.06 51.5321 538.475 50.2222 537.271 50.1165C536.024 50.0109 535.623 51.1307 536.341 52.6519Z" fill="#DEDEDE"/>
<path d="M529.285 52.0395C529.982 53.5396 531.546 54.8707 532.771 54.9763C533.996 55.082 534.419 53.941 533.701 52.4409C532.982 50.9196 531.419 49.6308 530.214 49.5252C529.01 49.3984 528.588 50.5182 529.285 52.0395Z" fill="#DEDEDE"/>
<path d="M522.227 51.4267C522.903 52.9269 524.467 54.2368 525.692 54.3425C526.918 54.4481 527.361 53.3072 526.664 51.807C525.967 50.3069 524.424 48.9969 523.199 48.8913C521.973 48.7857 521.551 49.9266 522.227 51.4267Z" fill="#DEDEDE"/>
<path d="M515.17 50.8354C515.846 52.3356 517.389 53.6455 518.614 53.7512C519.84 53.8568 520.283 52.7159 519.607 51.2158C518.931 49.7156 517.389 48.4057 516.163 48.3211C514.959 48.1944 514.494 49.3353 515.17 50.8354Z" fill="#DEDEDE"/>
<path d="M508.113 50.2648C508.768 51.7649 510.289 53.0749 511.536 53.1594C512.761 53.265 513.226 52.1241 512.55 50.6239C511.874 49.1238 510.353 47.835 509.127 47.7293C507.923 47.6448 507.458 48.7646 508.113 50.2648Z" fill="#DEDEDE"/>
<path d="M501.035 49.7153C501.669 51.2154 503.191 52.5043 504.416 52.6099C505.641 52.7156 506.127 51.5746 505.472 50.0745C504.817 48.5744 503.296 47.2855 502.092 47.201C500.866 47.0743 500.402 48.2152 501.035 49.7153Z" fill="#DEDEDE"/>
<path d="M493.958 49.1661C494.571 50.6662 496.092 51.955 497.318 52.0395C498.543 52.124 499.029 50.9831 498.395 49.5041C497.761 48.004 496.261 46.7363 495.036 46.6306C493.831 46.5461 493.324 47.6871 493.958 49.1661Z" fill="#DEDEDE"/>
<path d="M486.879 48.6589C487.492 50.1591 488.971 51.4268 490.217 51.5324C491.443 51.6169 491.95 50.476 491.337 48.997C490.724 47.4969 489.224 46.2291 487.999 46.1446C486.773 46.0179 486.266 47.1588 486.879 48.6589Z" fill="#DEDEDE"/>
<path d="M479.78 48.152C480.371 49.631 481.85 50.9198 483.097 51.0043C484.343 51.0888 484.85 49.9479 484.238 48.4689C483.646 46.9688 482.167 45.7011 480.942 45.6165C479.695 45.532 479.188 46.6518 479.78 48.152Z" fill="#DEDEDE"/>
<path d="M472.681 47.6448C473.251 49.1238 474.73 50.4126 475.956 50.4971C477.202 50.5816 477.71 49.4407 477.139 47.9617C476.547 46.4827 475.09 45.215 473.864 45.1305C472.639 45.0459 472.11 46.1658 472.681 47.6448Z" fill="#DEDEDE"/>
<path d="M465.582 47.18C466.131 48.659 467.589 49.9267 468.836 50.0112C470.082 50.0957 470.61 48.9548 470.04 47.4758C469.469 45.9968 468.012 44.7291 466.786 44.6445C465.561 44.56 465.011 45.701 465.582 47.18Z" fill="#DEDEDE"/>
<path d="M458.461 46.7151C459.011 48.1941 460.447 49.4618 461.673 49.5463C462.919 49.6308 463.469 48.4899 462.919 47.0109C462.37 45.5319 460.933 44.2642 459.708 44.2008C458.482 44.1163 457.933 45.2361 458.461 46.7151Z" fill="#DEDEDE"/>
<path d="M451.341 46.2713C451.869 47.7503 453.285 49.018 454.531 49.0814C455.778 49.1659 456.348 48.025 455.799 46.546C455.271 45.067 453.834 43.8204 452.609 43.7359C451.404 43.6725 450.834 44.8135 451.341 46.2713Z" fill="#DEDEDE"/>
<path d="M444.222 45.8485C444.729 47.3275 446.144 48.5952 447.391 48.6586C448.638 48.7431 449.208 47.5811 448.701 46.1232C448.173 44.6442 446.778 43.3976 445.532 43.3342C444.306 43.2497 443.715 44.3907 444.222 45.8485Z" fill="#DEDEDE"/>
<path d="M437.1 45.4476C437.586 46.9266 439.002 48.1732 440.227 48.2577C441.474 48.3211 442.065 47.1802 441.579 45.7012C441.072 44.2222 439.678 42.9756 438.452 42.9122C437.227 42.8488 436.614 43.9687 437.1 45.4476Z" fill="#DEDEDE"/>
<path d="M429.98 45.0666C430.445 46.5456 431.839 47.7922 433.086 47.8556C434.332 47.919 434.945 46.778 434.459 45.2991C433.973 43.8201 432.579 42.5946 431.353 42.5101C430.128 42.4467 429.494 43.5876 429.98 45.0666Z" fill="#DEDEDE"/>
<path d="M422.839 44.6866C423.282 46.1656 424.677 47.4122 425.923 47.4756C427.17 47.539 427.804 46.398 427.339 44.919C426.874 43.44 425.501 42.2146 424.275 42.1512C423.008 42.0878 422.374 43.2288 422.839 44.6866Z" fill="#DEDEDE"/>
<path d="M415.697 44.3272C416.141 45.7851 417.493 47.0317 418.74 47.0951C419.986 47.1584 420.641 46.0175 420.176 44.5385C419.733 43.0806 418.359 41.8341 417.134 41.7707C415.909 41.7284 415.253 42.8693 415.697 44.3272Z" fill="#DEDEDE"/>
<path d="M408.556 43.9894C408.979 45.4472 410.331 46.6938 411.577 46.7572C412.824 46.8206 413.479 45.6796 413.056 44.2006C412.634 42.7428 411.282 41.4962 410.035 41.4539C408.788 41.3905 408.133 42.5315 408.556 43.9894Z" fill="#DEDEDE"/>
<path d="M401.393 43.6729C401.795 45.1308 403.126 46.3774 404.372 46.4196C405.619 46.483 406.295 45.3209 405.872 43.8631C405.45 42.4052 404.119 41.1798 402.893 41.1164C401.668 41.0741 400.992 42.2151 401.393 43.6729Z" fill="#DEDEDE"/>
<path d="M394.251 43.3556C394.632 44.8134 395.963 46.0389 397.209 46.1023C398.456 46.1657 399.153 45.0036 398.752 43.5457C398.35 42.0879 397.04 40.8624 395.794 40.8201C394.547 40.7568 393.85 41.8977 394.251 43.3556Z" fill="#DEDEDE"/>
<path d="M387.089 43.0807C387.448 44.5385 388.758 45.764 390.005 45.8274C391.251 45.8696 391.97 44.7287 391.59 43.2708C391.209 41.813 389.899 40.5875 388.674 40.5452C387.427 40.4819 386.709 41.6228 387.089 43.0807Z" fill="#DEDEDE"/>
<path d="M379.927 42.8062C380.266 44.2641 381.575 45.4895 382.822 45.5318C384.069 45.5741 384.787 44.4331 384.428 42.9753C384.069 41.5174 382.78 40.2919 381.533 40.2497C380.287 40.2074 379.568 41.3484 379.927 42.8062Z" fill="#DEDEDE"/>
<path d="M372.742 42.5528C373.08 44.0107 374.348 45.2362 375.595 45.2784C376.841 45.3207 377.581 44.1797 377.243 42.7219C376.905 41.264 375.616 40.0597 374.39 40.0174C373.165 39.954 372.425 41.095 372.742 42.5528Z" fill="#DEDEDE"/>
<path d="M365.58 42.2993C365.897 43.7571 367.165 44.9826 368.412 45.0037C369.658 45.046 370.419 43.8839 370.081 42.4472C369.764 40.9893 368.496 39.785 367.249 39.7427C366.024 39.7216 365.263 40.8625 365.58 42.2993Z" fill="#DEDEDE"/>
<path d="M351.213 41.8767C351.487 43.3345 352.713 44.5389 353.981 44.5811C355.227 44.6234 356.009 43.4613 355.734 42.0034C355.438 40.5456 354.213 39.3412 352.966 39.3201C351.741 39.299 350.959 40.4399 351.213 41.8767Z" fill="#DEDEDE"/>
<path d="M344.05 41.6868C344.304 43.1447 345.529 44.349 346.776 44.3701C348.023 44.4124 348.826 43.2503 348.551 41.7925C348.276 40.3346 347.072 39.1514 345.825 39.1091C344.579 39.1091 343.776 40.2501 344.05 41.6868Z" fill="#DEDEDE"/>
<path d="M336.845 41.5173C337.078 42.9752 338.282 44.1795 339.55 44.2006C340.796 44.2217 341.62 43.0808 341.367 41.6229C341.113 40.1651 339.909 38.9819 338.662 38.9607C337.437 38.9396 336.613 40.0806 336.845 41.5173Z" fill="#DEDEDE"/>
<path d="M329.662 41.3694C329.873 42.8273 331.078 44.0105 332.324 44.0528C333.571 44.0739 334.416 42.9118 334.183 41.4751C333.951 40.0172 332.768 38.834 331.521 38.8129C330.275 38.7706 329.451 39.9327 329.662 41.3694Z" fill="#DEDEDE"/>
<path d="M322.478 41.2425C322.668 42.7004 323.851 43.8836 325.119 43.9047C326.365 43.9258 327.211 42.7638 326.999 41.327C326.788 39.8692 325.605 38.686 324.358 38.6648C323.112 38.6437 322.266 39.7847 322.478 41.2425Z" fill="#DEDEDE"/>
<path d="M315.274 41.116C315.464 42.5528 316.626 43.7571 317.873 43.7782C319.119 43.7994 319.986 42.6373 319.795 41.1794C319.605 39.7427 318.443 38.5384 317.197 38.5384C315.95 38.5172 315.105 39.6793 315.274 41.116Z" fill="#DEDEDE"/>
<path d="M308.088 41.0313C308.257 42.4681 309.398 43.6724 310.645 43.6935C311.892 43.7147 312.779 42.5526 312.61 41.0947C312.441 39.658 311.279 38.4748 310.053 38.4537C308.786 38.4325 307.919 39.5735 308.088 41.0313Z" fill="#DEDEDE"/>
<path d="M300.885 40.9467C301.033 42.3834 302.153 43.5878 303.421 43.5878C304.667 43.6089 305.576 42.4257 305.407 40.989C305.259 39.5522 304.118 38.369 302.871 38.3479C301.625 38.3479 300.737 39.4888 300.885 40.9467Z" fill="#DEDEDE"/>
<path d="M293.68 40.8838C293.807 42.3205 294.927 43.5248 296.173 43.5248C297.42 43.546 298.329 42.3628 298.202 40.926C298.054 39.4893 296.955 38.3061 295.709 38.285C294.462 38.2638 293.553 39.4259 293.68 40.8838Z" fill="#DEDEDE"/>
<path d="M286.475 40.8204C286.581 42.2571 287.68 43.4403 288.947 43.4615C290.194 43.4615 291.123 42.2994 290.997 40.8627C290.87 39.4259 289.771 38.2427 288.525 38.2427C287.278 38.2216 286.37 39.3837 286.475 40.8204Z" fill="#DEDEDE"/>
<path d="M279.27 40.7989C279.354 42.2356 280.453 43.4188 281.7 43.4188C282.967 43.4188 283.897 42.2568 283.791 40.7989C283.686 39.3622 282.608 38.179 281.362 38.179C280.115 38.179 279.185 39.341 279.27 40.7989Z" fill="#DEDEDE"/>
<path d="M134.921 43.8419C134.625 45.2997 135.428 46.4407 136.674 46.3773C137.942 46.3139 139.168 45.0884 139.442 43.6306C139.717 42.1727 138.935 41.0529 137.689 41.0952C136.463 41.1585 135.217 42.384 134.921 43.8419Z" fill="#DEDEDE"/>
<path d="M127.716 44.1797C127.42 45.6376 128.181 46.7786 129.449 46.7152C130.716 46.6518 131.963 45.4263 132.259 43.9685C132.554 42.5106 131.773 41.3908 130.526 41.433C129.28 41.4753 128.012 42.7008 127.716 44.1797Z" fill="#DEDEDE"/>
<path d="M120.49 44.5177C120.173 45.9755 120.912 47.1165 122.18 47.0531C123.448 46.9897 124.715 45.7431 125.011 44.2853C125.328 42.8274 124.567 41.6864 123.321 41.7498C122.095 41.8343 120.828 43.0598 120.49 44.5177Z" fill="#DEDEDE"/>
<path d="M113.285 44.8978C112.947 46.3557 113.687 47.4966 114.933 47.4332C116.201 47.3698 117.468 46.1233 117.807 44.6654C118.145 43.2075 117.384 42.0666 116.159 42.13C114.912 42.1933 113.623 43.4188 113.285 44.8978Z" fill="#DEDEDE"/>
<path d="M106.08 45.2786C105.721 46.7365 106.439 47.8774 107.707 47.814C108.954 47.7506 110.264 46.5041 110.623 45.0251C110.961 43.5672 110.242 42.4262 108.996 42.5108C107.749 42.5741 106.439 43.8207 106.08 45.2786Z" fill="#DEDEDE"/>
<path d="M98.8753 45.6797C98.495 47.1376 99.2134 48.2785 100.46 48.2151C101.707 48.1518 103.038 46.884 103.397 45.4262C103.756 43.9683 103.059 42.8274 101.812 42.9119C100.566 42.9753 99.2557 44.2218 98.8753 45.6797Z" fill="#DEDEDE"/>
<path d="M91.6696 46.1021C91.2682 47.5811 91.9654 48.7009 93.2331 48.6375C94.4797 48.553 95.8108 47.3064 96.2122 45.8485C96.5926 44.3907 95.8953 43.2497 94.6487 43.3342C93.4022 43.3976 92.0711 44.6442 91.6696 46.1021Z" fill="#DEDEDE"/>
<path d="M84.466 46.5459C84.0434 48.0249 84.7407 49.1447 85.9873 49.0813C87.2339 48.9968 88.586 47.7502 88.9875 46.2712C89.3889 44.7922 88.7128 43.6724 87.4663 43.7569C86.2197 43.8203 84.8886 45.0669 84.466 46.5459Z" fill="#DEDEDE"/>
<path d="M77.2608 47.0107C76.8383 48.4897 77.4933 49.6095 78.761 49.5462C80.0076 49.4616 81.3809 48.1939 81.7823 46.7361C82.2049 45.2571 81.5288 44.1373 80.2822 44.2218C79.0568 44.2852 77.7045 45.5317 77.2608 47.0107Z" fill="#DEDEDE"/>
<path d="M70.0769 47.4755C69.6332 48.9545 70.2882 50.0743 71.5347 50.0109C72.7813 49.9264 74.1547 48.6587 74.5984 47.1797C75.0421 45.7007 74.3871 44.5809 73.1405 44.6654C71.8939 44.7499 70.5206 45.9965 70.0769 47.4755Z" fill="#DEDEDE"/>
<path d="M62.8717 47.9617C62.4069 49.4407 63.0407 50.5816 64.2873 50.476C65.5339 50.3915 66.9284 49.1238 67.3721 47.6448C67.8158 46.1658 67.1819 45.0459 65.9353 45.1305C64.731 45.2361 63.3365 46.4827 62.8717 47.9617Z" fill="#DEDEDE"/>
<path d="M55.6882 48.4689C55.2022 49.9479 55.8361 51.0888 57.0826 50.9832C58.3292 50.8987 59.7449 49.6098 60.2097 48.1308C60.6745 46.6518 60.0618 45.532 58.8152 45.6165C57.5686 45.7222 56.1741 46.9899 55.6882 48.4689Z" fill="#DEDEDE"/>
<path d="M48.4835 48.997C47.9764 50.476 48.5891 51.6169 49.8357 51.5113C51.0823 51.4268 52.4979 50.1379 52.9839 48.6589C53.4698 47.1799 52.8571 46.0601 51.6316 46.1446C50.4062 46.2503 48.9906 47.518 48.4835 48.997Z" fill="#DEDEDE"/>
<path d="M41.3006 49.5464C40.7724 51.0254 41.3851 52.1664 42.6317 52.0607C43.8783 51.9551 45.315 50.6874 45.8221 49.1872C46.3292 47.7082 45.7376 46.5884 44.491 46.6729C43.2444 46.7786 41.8288 48.0463 41.3006 49.5464Z" fill="#DEDEDE"/>
<path d="M622.165 53.3709C623.052 54.9132 624.743 56.2866 625.947 56.4345C627.151 56.5824 627.384 55.4415 626.475 53.8779C625.567 52.3356 623.876 50.9622 622.693 50.8143C621.51 50.6875 621.277 51.8285 622.165 53.3709Z" fill="#DEDEDE"/>
<path d="M615.299 52.5674C616.186 54.1098 617.855 55.4831 619.06 55.6099C620.264 55.7578 620.496 54.6169 619.609 53.0534C618.722 51.511 617.031 50.1587 615.848 50.0108C614.665 49.8841 614.411 51.025 615.299 52.5674Z" fill="#DEDEDE"/>
<path d="M608.41 51.7858C609.277 53.3282 610.946 54.6804 612.15 54.8283C613.355 54.9762 613.608 53.8141 612.721 52.2929C611.833 50.7505 610.164 49.3983 608.981 49.2715C607.819 49.1025 607.544 50.2434 608.41 51.7858Z" fill="#DEDEDE"/>
<path d="M601.522 51.0038C602.367 52.5462 604.036 53.8984 605.241 54.0252C606.445 54.1519 606.72 53.011 605.853 51.4897C604.987 49.9474 603.318 48.6163 602.135 48.4684C600.952 48.3416 600.677 49.4825 601.522 51.0038Z" fill="#DEDEDE"/>
<path d="M587.747 49.5249C588.571 51.0462 590.219 52.3984 591.424 52.5252C592.628 52.652 592.924 51.511 592.079 49.9898C591.233 48.4685 589.606 47.1163 588.402 46.9895C587.198 46.8627 586.902 47.9826 587.747 49.5249Z" fill="#DEDEDE"/>
<path d="M580.837 48.7857C581.661 50.307 583.288 51.6381 584.492 51.7649C585.697 51.8916 585.993 50.7507 585.169 49.2294C584.345 47.7082 582.718 46.3771 581.513 46.2503C580.33 46.1447 580.013 47.2645 580.837 48.7857Z" fill="#DEDEDE"/>
<path d="M573.908 48.088C574.711 49.6093 576.338 50.9404 577.542 51.0672C578.746 51.1939 579.063 50.053 578.26 48.5317C577.457 47.0105 575.831 45.6794 574.626 45.5737C573.443 45.447 573.105 46.5668 573.908 48.088Z" fill="#DEDEDE"/>
<path d="M566.999 47.391C567.781 48.9122 569.408 50.2222 570.612 50.349C571.816 50.4757 572.154 49.3348 571.352 47.8135C570.549 46.2923 568.943 44.9823 567.739 44.8555C566.534 44.7499 566.196 45.8697 566.999 47.391Z" fill="#DEDEDE"/>
<path d="M560.068 46.715C560.85 48.2151 562.456 49.5462 563.66 49.673C564.864 49.7998 565.203 48.6588 564.421 47.1376C563.639 45.6163 562.033 44.3063 560.829 44.2007C559.646 44.0739 559.287 45.1937 560.068 46.715Z" fill="#DEDEDE"/>
<path d="M553.138 46.0388C553.899 47.5389 555.484 48.87 556.709 48.9756C557.913 49.1024 558.272 47.9615 557.512 46.4613C556.751 44.9612 555.145 43.6512 553.962 43.5245C552.737 43.4188 552.378 44.5387 553.138 46.0388Z" fill="#DEDEDE"/>
<path d="M546.187 45.4055C546.926 46.9057 548.511 48.2156 549.715 48.3424C550.92 48.448 551.3 47.3282 550.539 45.8281C549.779 44.328 548.194 43.018 547.011 42.9124C545.807 42.7856 545.447 43.9054 546.187 45.4055Z" fill="#DEDEDE"/>
<path d="M539.236 44.7709C539.955 46.2711 541.539 47.581 542.744 47.6867C543.948 47.7923 544.328 46.6725 543.589 45.1724C542.849 43.6723 541.286 42.3834 540.082 42.2567C538.898 42.151 538.518 43.2708 539.236 44.7709Z" fill="#DEDEDE"/>
<path d="M532.284 44.1579C533.002 45.658 534.566 46.9469 535.77 47.0525C536.974 47.1582 537.376 46.0384 536.657 44.5382C535.939 43.0381 534.375 41.7493 533.171 41.6436C531.967 41.538 531.565 42.6578 532.284 44.1579Z" fill="#DEDEDE"/>
<path d="M525.334 43.546C526.031 45.0461 527.574 46.3349 528.799 46.4406C530.004 46.5462 530.426 45.4264 529.708 43.9263C528.989 42.4262 527.447 41.1373 526.243 41.0528C525.038 40.9472 524.637 42.067 525.334 43.546Z" fill="#DEDEDE"/>
<path d="M518.36 42.9755C519.036 44.4545 520.578 45.7645 521.804 45.849C523.008 45.9547 523.431 44.8137 522.755 43.3347C522.057 41.8557 520.515 40.5669 519.332 40.4612C518.106 40.3556 517.684 41.4754 518.36 42.9755Z" fill="#DEDEDE"/>
<path d="M511.388 42.4048C512.064 43.8838 513.586 45.1726 514.79 45.2783C515.994 45.3839 516.438 44.243 515.762 42.764C515.086 41.285 513.564 39.9962 512.36 39.9116C511.156 39.7849 510.712 40.9047 511.388 42.4048Z" fill="#DEDEDE"/>
<path d="M504.416 41.8347C505.071 43.3137 506.571 44.6025 507.797 44.6871C509.022 44.7927 509.466 43.6517 508.79 42.1728C508.135 40.6938 506.614 39.426 505.409 39.3204C504.205 39.2359 503.761 40.3557 504.416 41.8347Z" fill="#DEDEDE"/>
<path d="M497.423 41.3062C498.057 42.7852 499.557 44.0529 500.783 44.1586C502.008 44.2431 502.452 43.1233 501.818 41.6443C501.163 40.1653 499.663 38.8976 498.458 38.8131C497.254 38.7074 496.789 39.8272 497.423 41.3062Z" fill="#DEDEDE"/>
<path d="M490.45 40.7779C491.063 42.2569 492.563 43.5246 493.789 43.6091C495.014 43.6936 495.479 42.5738 494.845 41.0948C494.211 39.6158 492.732 38.3481 491.528 38.2636C490.302 38.2002 489.816 39.2989 490.45 40.7779Z" fill="#DEDEDE"/>
<path d="M483.456 40.2713C484.069 41.7503 485.548 43.018 486.752 43.1026C487.978 43.1871 488.464 42.0673 487.851 40.5883C487.238 39.1093 485.759 37.8627 484.555 37.7782C483.329 37.6937 482.843 38.8135 483.456 40.2713Z" fill="#DEDEDE"/>
<path d="M476.442 39.7847C477.033 41.2426 478.491 42.5103 479.717 42.5948C480.942 42.6793 481.449 41.5595 480.837 40.0805C480.245 38.6015 478.766 37.3549 477.562 37.2704C476.357 37.207 475.871 38.3268 476.442 39.7847Z" fill="#DEDEDE"/>
<path d="M469.448 39.32C470.018 40.7779 471.476 42.0456 472.701 42.1301C473.927 42.2146 474.434 41.0948 473.863 39.6158C473.272 38.1579 471.835 36.8902 470.631 36.8268C469.384 36.7423 468.877 37.841 469.448 39.32Z" fill="#DEDEDE"/>
<path d="M462.434 38.855C462.983 40.3129 464.42 41.5806 465.645 41.644C466.871 41.7285 467.399 40.5875 466.828 39.1297C466.258 37.6718 464.821 36.4252 463.617 36.3407C462.412 36.2773 461.884 37.3971 462.434 38.855Z" fill="#DEDEDE"/>
<path d="M455.419 38.4117C455.947 39.8696 457.384 41.1162 458.609 41.2007C459.835 41.2852 460.384 40.1443 459.835 38.6864C459.285 37.2285 457.87 35.9819 456.644 35.9185C455.419 35.834 454.891 36.9538 455.419 38.4117Z" fill="#DEDEDE"/>
<path d="M448.404 37.9893C448.932 39.4471 450.347 40.6937 451.552 40.7571C452.777 40.8416 453.326 39.7007 452.798 38.2428C452.27 36.7849 450.854 35.5595 449.65 35.475C448.446 35.4116 447.875 36.5314 448.404 37.9893Z" fill="#DEDEDE"/>
<path d="M441.368 37.5875C441.875 39.0454 443.27 40.2919 444.495 40.3553C445.72 40.4187 446.291 39.2989 445.784 37.841C445.277 36.3832 443.861 35.1577 442.657 35.0943C441.453 35.0098 440.861 36.1296 441.368 37.5875Z" fill="#DEDEDE"/>
<path d="M399.132 35.4534C399.533 36.8902 400.843 38.1156 402.09 38.1579C403.315 38.2213 403.992 37.0803 403.569 35.6436C403.168 34.2069 401.836 33.0025 400.632 32.9391C399.407 32.8969 398.731 34.0167 399.132 35.4534Z" fill="#DEDEDE"/>
<path d="M392.075 35.1576C392.455 36.5943 393.765 37.8198 394.991 37.862C396.216 37.9043 396.892 36.7845 396.512 35.3478C396.111 33.911 394.822 32.7067 393.596 32.6644C392.371 32.601 391.695 33.7209 392.075 35.1576Z" fill="#DEDEDE"/>
<path d="M300.06 32.8332C300.208 34.2488 301.328 35.432 302.553 35.432C303.8 35.4531 304.666 34.291 304.518 32.8754C304.37 31.4598 303.251 30.2977 302.025 30.2766C300.8 30.2766 299.933 31.4176 300.06 32.8332Z" fill="#DEDEDE"/>
<path d="M150.767 35.0522C150.514 36.4889 151.317 37.6088 152.563 37.5665C153.81 37.5242 154.993 36.3199 155.247 34.8832C155.479 33.4464 154.676 32.3266 153.451 32.39C152.225 32.4323 151.021 33.6155 150.767 35.0522Z" fill="#DEDEDE"/>
<path d="M143.669 35.3479C143.394 36.7846 144.197 37.9044 145.422 37.8622C146.669 37.8199 147.873 36.5944 148.127 35.1788C148.38 33.7421 147.599 32.6223 146.373 32.6857C145.148 32.7068 143.922 33.9111 143.669 35.3479Z" fill="#DEDEDE"/>
<path d="M136.547 35.6649C136.251 37.1016 137.033 38.2214 138.28 38.1792C139.526 38.1158 140.752 36.9115 141.026 35.4747C141.301 34.038 140.519 32.9182 139.294 32.9816C138.068 33.0238 136.843 34.2281 136.547 35.6649Z" fill="#DEDEDE"/>
<path d="M129.427 35.9819C129.132 37.4187 129.871 38.5385 131.118 38.4751C132.364 38.4117 133.59 37.2074 133.885 35.7706C134.181 34.3339 133.421 33.2141 132.195 33.2775C130.991 33.3409 129.744 34.5452 129.427 35.9819Z" fill="#DEDEDE"/>
<path d="M122.328 36.3197C122.011 37.7564 122.751 38.8762 123.997 38.8129C125.244 38.7495 126.49 37.524 126.807 36.0873C127.124 34.6505 126.364 33.5307 125.138 33.5941C123.913 33.6786 122.645 34.883 122.328 36.3197Z" fill="#DEDEDE"/>
<path d="M115.207 36.6786C114.869 38.1153 115.587 39.2351 116.834 39.1718C118.08 39.1084 119.348 37.8829 119.665 36.4462C120.003 35.0094 119.264 33.8896 118.038 33.953C116.834 34.0375 115.566 35.2418 115.207 36.6786Z" fill="#DEDEDE"/>
<path d="M108.108 37.0594C107.749 38.4962 108.467 39.616 109.693 39.5526C110.939 39.4892 112.207 38.2638 112.566 36.827C112.904 35.3903 112.207 34.2705 110.982 34.3339C109.756 34.3972 108.467 35.6227 108.108 37.0594Z" fill="#DEDEDE"/>
<path d="M101.01 37.4609C100.629 38.8977 101.327 40.0175 102.573 39.9541C103.82 39.8907 105.109 38.6441 105.468 37.2074C105.827 35.7707 105.13 34.6508 103.904 34.7142C102.679 34.7987 101.39 36.0031 101.01 37.4609Z" fill="#DEDEDE"/>
<path d="M93.8882 37.8618C93.4867 39.2986 94.184 40.4184 95.4094 40.355C96.656 40.2916 97.966 39.045 98.3463 37.6083C98.7266 36.1715 98.0505 35.0517 96.8251 35.1151C95.5996 35.1996 94.2896 36.4251 93.8882 37.8618Z" fill="#DEDEDE"/>
<path d="M86.79 38.285C86.3674 39.7428 87.0435 40.8626 88.2901 40.7781C89.5367 40.6936 90.8678 39.4682 91.2481 38.0103C91.6495 36.5524 90.9734 35.4537 89.748 35.5383C88.5437 35.6228 87.2126 36.8482 86.79 38.285Z" fill="#DEDEDE"/>
<path d="M79.6909 38.7286C79.2683 40.1864 79.9233 41.3062 81.1487 41.2217C82.3953 41.1372 83.7265 39.8906 84.149 38.4539C84.5716 36.996 83.9166 35.8973 82.6912 35.9819C81.4657 36.0452 80.1346 37.2918 79.6909 38.7286Z" fill="#DEDEDE"/>
<path d="M72.5911 39.1933C72.1474 40.6512 72.7812 41.771 74.0278 41.6865C75.2744 41.6019 76.6266 40.3554 77.0492 38.8975C77.4929 37.4396 76.8379 36.341 75.6125 36.4255C74.4081 36.51 73.0348 37.7354 72.5911 39.1933Z" fill="#DEDEDE"/>
<path d="M65.492 39.6794C65.0272 41.1372 65.661 42.2571 66.8865 42.1725C68.1331 42.088 69.4853 40.8415 69.9502 39.3836C70.3939 37.9257 69.7811 36.827 68.5557 36.9115C67.3302 36.9749 65.9569 38.2215 65.492 39.6794Z" fill="#DEDEDE"/>
<path d="M58.4152 40.1651C57.9292 41.623 58.5419 42.7428 59.7885 42.6583C61.0351 42.5738 62.4085 41.3061 62.8733 39.8482C63.3381 38.3903 62.7254 37.2916 61.4999 37.3761C60.2745 37.4607 58.88 38.7072 58.4152 40.1651Z" fill="#DEDEDE"/>
<path d="M51.3139 40.6719C50.8068 42.1298 51.4195 43.2496 52.645 43.1651C53.8704 43.0806 55.286 41.8129 55.772 40.355C56.2579 38.8971 55.6663 37.7985 54.4409 37.883C53.2154 37.9675 51.8209 39.2141 51.3139 40.6719Z" fill="#DEDEDE"/>
<path d="M44.2368 41.2005C43.7297 42.6584 44.3002 43.7782 45.5468 43.6937C46.7723 43.6092 48.1879 42.3415 48.6949 40.8625C49.202 39.4046 48.6104 38.2848 47.385 38.3904C46.1595 38.475 44.7439 39.7427 44.2368 41.2005Z" fill="#DEDEDE"/>
<path d="M37.1371 41.7499C36.6089 43.2077 37.1793 44.3275 38.4048 44.243C39.6302 44.1374 41.0669 42.8697 41.5952 41.4118C42.1234 39.9539 41.5529 38.8341 40.3275 38.9398C39.102 39.0243 37.6653 40.2709 37.1371 41.7499Z" fill="#DEDEDE"/>
<path d="M589.881 41.6652C590.726 43.1865 592.353 44.5176 593.557 44.6443C594.74 44.7711 595.015 43.6513 594.17 42.13C593.325 40.6088 591.677 39.2988 590.493 39.1721C589.331 39.0242 589.057 40.144 589.881 41.6652Z" fill="#DEDEDE"/>
<path d="M583.056 40.9469C583.88 42.4471 585.507 43.7782 586.711 43.9049C587.894 44.0317 588.19 42.9119 587.345 41.3906C586.521 39.8694 584.873 38.5594 583.711 38.4326C582.528 38.3059 582.232 39.4257 583.056 40.9469Z" fill="#DEDEDE"/>
<path d="M576.231 40.2498C577.034 41.7499 578.661 43.081 579.865 43.2078C581.048 43.3345 581.344 42.1936 580.541 40.6935C579.717 39.1934 578.09 37.8834 576.928 37.7566C575.724 37.6298 575.428 38.7497 576.231 40.2498Z" fill="#DEDEDE"/>
<path d="M569.386 39.5524C570.189 41.0526 571.795 42.3625 572.978 42.4893C574.161 42.6161 574.478 41.4751 573.675 39.975C572.872 38.4749 571.267 37.1649 570.083 37.0381C568.9 36.9325 568.604 38.0523 569.386 39.5524Z" fill="#DEDEDE"/>
<path d="M562.562 38.8974C563.343 40.3975 564.949 41.7075 566.132 41.8132C567.316 41.9399 567.654 40.799 566.851 39.2989C566.048 37.7987 564.463 36.4888 563.28 36.3831C562.097 36.2775 561.759 37.3973 562.562 38.8974Z" fill="#DEDEDE"/>
<path d="M555.696 38.2211C556.456 39.7212 558.062 41.0101 559.245 41.1368C560.428 41.2425 560.767 40.1227 559.985 38.6437C559.203 37.1435 557.618 35.8547 556.435 35.749C555.273 35.6223 554.935 36.7421 555.696 38.2211Z" fill="#DEDEDE"/>
<path d="M548.849 37.5875C549.61 39.0665 551.194 40.3764 552.378 40.4821C553.561 40.5877 553.92 39.4679 553.159 37.9889C552.399 36.4888 550.814 35.1999 549.631 35.0943C548.448 34.9887 548.089 36.1085 548.849 37.5875Z" fill="#DEDEDE"/>
<path d="M541.982 36.9749C542.722 38.4539 544.285 39.7427 545.49 39.8695C546.694 39.9752 547.053 38.8553 546.293 37.3763C545.532 35.8974 543.968 34.6085 542.785 34.5029C541.623 34.3761 541.264 35.4748 541.982 36.9749Z" fill="#DEDEDE"/>
<path d="M535.137 36.3621C535.855 37.8411 537.419 39.13 538.623 39.2356C539.827 39.3412 540.187 38.2214 539.447 36.7424C538.708 35.2634 537.165 33.9746 535.961 33.869C534.778 33.7633 534.397 34.8831 535.137 36.3621Z" fill="#DEDEDE"/>
<path d="M528.27 35.7704C528.988 37.2494 530.531 38.5382 531.714 38.6439C532.918 38.7495 533.298 37.6297 532.58 36.1507C531.862 34.6717 530.319 33.404 529.136 33.2984C527.932 33.1716 527.551 34.2914 528.27 35.7704Z" fill="#DEDEDE"/>
<path d="M521.382 35.1791C522.079 36.6581 523.621 37.9258 524.805 38.0315C526.009 38.1371 526.41 37.0173 525.692 35.5383C524.995 34.0593 523.452 32.7916 522.269 32.686C521.086 32.6014 520.685 33.7001 521.382 35.1791Z" fill="#DEDEDE"/>
<path d="M514.516 34.6083C515.192 36.0662 516.713 37.355 517.918 37.4395C519.122 37.5452 519.523 36.4254 518.826 34.9464C518.129 33.4674 516.608 32.2208 515.425 32.1152C514.241 32.0518 513.819 33.1505 514.516 34.6083Z" fill="#DEDEDE"/>
<path d="M507.627 34.0589C508.282 35.5167 509.803 36.7845 511.008 36.8901C512.212 36.9957 512.635 35.8759 511.958 34.3969C511.282 32.9391 509.782 31.6714 508.578 31.5868C507.395 31.5023 506.951 32.601 507.627 34.0589Z" fill="#DEDEDE"/>
<path d="M500.74 33.5307C501.395 34.9886 502.895 36.2563 504.078 36.3408C505.282 36.4253 505.726 35.3266 505.05 33.8476C504.395 32.3897 502.895 31.122 501.712 31.0375C500.528 30.9741 500.085 32.0728 500.74 33.5307Z" fill="#DEDEDE"/>
<path d="M493.852 33.0023C494.486 34.4601 495.965 35.7278 497.169 35.8123C498.373 35.8969 498.817 34.7982 498.183 33.3403C497.549 31.8824 496.049 30.6359 494.866 30.5513C493.662 30.4457 493.218 31.5655 493.852 33.0023Z" fill="#DEDEDE"/>
<path d="M486.942 32.5166C487.555 33.9745 489.034 35.2211 490.238 35.3056C491.442 35.3901 491.907 34.2703 491.273 32.8336C490.64 31.3757 489.182 30.1291 487.977 30.0446C486.794 29.9601 486.329 31.0588 486.942 32.5166Z" fill="#DEDEDE"/>
<path d="M459.307 30.6574C459.856 32.0942 461.293 33.3408 462.497 33.4041C463.702 33.4887 464.23 32.3688 463.66 30.9321C463.089 29.4954 461.673 28.2699 460.49 28.1854C459.286 28.1009 458.758 29.2207 459.307 30.6574Z" fill="#DEDEDE"/>
<path d="M452.377 30.2349C452.905 31.6717 454.32 32.8971 455.525 32.9816C456.729 33.0661 457.257 31.9463 456.729 30.5096C456.18 29.0728 454.764 27.8474 453.581 27.784C452.398 27.6784 451.848 28.7982 452.377 30.2349Z" fill="#DEDEDE"/>
<path d="M445.468 29.8121C445.975 31.2488 447.39 32.4742 448.595 32.5376C449.799 32.601 450.348 31.5023 449.82 30.0656C449.292 28.6289 447.897 27.4245 446.693 27.34C445.489 27.2766 444.939 28.3753 445.468 29.8121Z" fill="#DEDEDE"/>
<path d="M396.893 27.3821C397.294 28.7978 398.583 30.0021 399.809 30.0443C401.013 30.0866 401.668 28.9879 401.267 27.5723C400.865 26.1567 399.555 24.9735 398.351 24.9101C397.147 24.8679 396.492 25.9665 396.893 27.3821Z" fill="#DEDEDE"/>
<path d="M389.921 27.1075C390.301 28.5231 391.59 29.7063 392.794 29.7697C393.998 29.8119 394.674 28.7133 394.273 27.2977C393.872 25.882 392.604 24.6988 391.4 24.6566C390.216 24.5721 389.561 25.6919 389.921 27.1075Z" fill="#DEDEDE"/>
<path d="M159.197 26.7698C158.965 28.1854 159.768 29.2841 160.993 29.2419C162.219 29.1996 163.381 28.0164 163.592 26.6008C163.803 25.1852 163 24.1076 161.796 24.1499C160.592 24.1921 159.43 25.3542 159.197 26.7698Z" fill="#DEDEDE"/>
<path d="M145.167 27.3191C144.893 28.7347 145.674 29.8334 146.9 29.7911C148.125 29.7489 149.33 28.5445 149.583 27.1501C149.837 25.7344 149.055 24.6358 147.851 24.6991C146.625 24.7203 145.442 25.9035 145.167 27.3191Z" fill="#DEDEDE"/>
<path d="M138.152 27.6146C137.856 29.0302 138.617 30.1289 139.842 30.0867C141.068 30.0233 142.272 28.8401 142.547 27.4245C142.822 26.0089 142.061 24.9102 140.857 24.9736C139.652 25.0158 138.448 26.199 138.152 27.6146Z" fill="#DEDEDE"/>
<path d="M131.159 27.9318C130.863 29.3474 131.602 30.4461 132.828 30.4038C134.053 30.3404 135.279 29.1572 135.575 27.7416C135.87 26.326 135.131 25.2273 133.906 25.2907C132.68 25.333 131.455 26.5162 131.159 27.9318Z" fill="#DEDEDE"/>
<path d="M124.145 28.2484C123.828 29.664 124.547 30.7627 125.772 30.7204C126.997 30.6571 128.244 29.4527 128.54 28.0583C128.857 26.6426 128.117 25.544 126.913 25.6073C125.709 25.6496 124.462 26.8328 124.145 28.2484Z" fill="#DEDEDE"/>
<path d="M117.13 28.6083C116.792 30.0239 117.51 31.1226 118.736 31.0592C119.961 30.9958 121.208 29.7915 121.546 28.3759C121.863 26.9603 121.166 25.8616 119.94 25.925C118.736 25.9884 117.468 27.1927 117.13 28.6083Z" fill="#DEDEDE"/>
<path d="M110.136 28.9672C109.777 30.3828 110.474 31.4815 111.7 31.4181C112.925 31.3547 114.193 30.1504 114.531 28.7348C114.869 27.3192 114.172 26.2205 112.968 26.2839C111.763 26.3472 110.496 27.5516 110.136 28.9672Z" fill="#DEDEDE"/>
<path d="M103.122 29.347C102.742 30.7626 103.439 31.8824 104.643 31.7979C105.869 31.7345 107.158 30.509 107.517 29.0934C107.876 27.6778 107.2 26.5791 105.974 26.6425C104.791 26.727 103.502 27.9313 103.122 29.347Z" fill="#DEDEDE"/>
<path d="M96.129 29.7486C95.7276 31.1642 96.4037 32.284 97.6292 32.1995C98.8546 32.1361 100.143 30.9106 100.524 29.495C100.904 28.0794 100.228 26.9807 99.0236 27.0441C97.8193 27.1286 96.5093 28.333 96.129 29.7486Z" fill="#DEDEDE"/>
<path d="M89.1138 30.1716C88.7123 31.6083 89.3672 32.707 90.5716 32.6225C91.797 32.5591 93.107 31.3336 93.5085 29.8969C93.9099 28.4813 93.2549 27.3826 92.0506 27.446C90.8463 27.5305 89.5363 28.7348 89.1138 30.1716Z" fill="#DEDEDE"/>
<path d="M82.12 30.594C81.6974 32.0307 82.3313 33.1294 83.5567 33.0449C84.7822 32.9604 86.1133 31.7349 86.5148 30.3193C86.9373 28.8825 86.2823 27.805 85.078 27.8684C83.8737 27.9529 82.5426 29.1572 82.12 30.594Z" fill="#DEDEDE"/>
<path d="M75.1262 31.0377C74.6825 32.4744 75.3163 33.5731 76.5207 33.4886C77.7461 33.4041 79.0772 32.1786 79.5209 30.7419C79.9435 29.3052 79.3308 28.2276 78.1264 28.291C76.901 28.3966 75.5699 29.601 75.1262 31.0377Z" fill="#DEDEDE"/>
<path d="M68.1115 31.5023C67.6466 32.939 68.2594 34.0377 69.4848 33.9532C70.7103 33.8687 72.0625 32.6432 72.5062 31.2065C72.9499 29.7698 72.3372 28.6922 71.1329 28.7556C69.9285 28.8401 68.5763 30.0656 68.1115 31.5023Z" fill="#DEDEDE"/>
<path d="M61.1183 31.9675C60.6324 33.4042 61.2451 34.5029 62.4494 34.4184C63.6749 34.3339 65.0271 33.0873 65.5131 31.6505C65.9779 30.2138 65.3863 29.1151 64.182 29.1996C62.9776 29.3053 61.6043 30.5307 61.1183 31.9675Z" fill="#DEDEDE"/>
<path d="M54.126 32.4746C53.619 33.9113 54.2105 35.01 55.436 34.9255C56.6615 34.8409 58.0348 33.5944 58.5208 32.1576C59.0067 30.7209 58.4151 29.6222 57.2108 29.7067C56.0065 29.7912 54.6331 31.0378 54.126 32.4746Z" fill="#DEDEDE"/>
<path d="M47.1315 32.9818C46.6244 34.4185 47.1949 35.5172 48.3992 35.4327C49.6247 35.3482 51.0191 34.1016 51.5051 32.6437C52.0122 31.207 51.4417 30.1083 50.2374 30.1928C49.0331 30.2984 47.6597 31.545 47.1315 32.9818Z" fill="#DEDEDE"/>
<path d="M40.1376 33.5098C39.6094 34.9466 40.1587 36.0664 41.3841 35.9607C42.6096 35.8762 44.0041 34.6085 44.5323 33.1718C45.0605 31.735 44.49 30.6364 43.2857 30.7209C42.0814 30.8265 40.6658 32.0731 40.1376 33.5098Z" fill="#DEDEDE"/>
<path d="M558.124 30.4883C558.905 31.9673 560.49 33.2562 561.673 33.3618C562.856 33.4675 563.173 32.3688 562.371 30.8898C561.589 29.4108 560.004 28.1219 558.821 28.0163C557.659 27.9107 557.342 29.0093 558.124 30.4883Z" fill="#DEDEDE"/>
<path d="M551.364 29.8547C552.124 31.3337 553.709 32.6225 554.892 32.7281C556.075 32.8338 556.392 31.7351 555.632 30.2561C554.85 28.7771 553.286 27.5094 552.103 27.4038C550.92 27.277 550.603 28.3757 551.364 29.8547Z" fill="#DEDEDE"/>
<path d="M544.601 29.2415C545.362 30.6994 546.926 31.9882 548.088 32.0939C549.271 32.1995 549.609 31.1008 548.848 29.6218C548.088 28.1428 546.524 26.8751 545.362 26.7695C544.179 26.6638 543.841 27.7625 544.601 29.2415Z" fill="#DEDEDE"/>
<path d="M537.82 28.6291C538.559 30.0869 540.102 31.3758 541.285 31.4814C542.468 31.5871 542.806 30.4884 542.067 29.0094C541.327 27.5515 539.764 26.2838 538.602 26.1782C537.44 26.0725 537.08 27.1712 537.82 28.6291Z" fill="#DEDEDE"/>
<path d="M531.038 28.0581C531.756 29.516 533.298 30.7837 534.482 30.8894C535.665 30.995 536.024 29.8752 535.284 28.4173C534.545 26.9595 533.003 25.6918 531.84 25.5861C530.678 25.5016 530.319 26.6003 531.038 28.0581Z" fill="#DEDEDE"/>
<path d="M524.256 27.4886C524.953 28.9464 526.496 30.2141 527.679 30.2987C528.862 30.4043 529.242 29.2845 528.524 27.8266C527.806 26.3688 526.284 25.1222 525.101 25.0165C523.918 24.932 523.538 26.0307 524.256 27.4886Z" fill="#DEDEDE"/>
<path d="M510.671 26.3683C511.347 27.8051 512.847 29.0728 514.03 29.1573C515.213 29.2418 515.615 28.1431 514.939 26.7064C514.263 25.2485 512.741 24.0231 511.579 23.9174C510.396 23.8329 509.995 24.9316 510.671 26.3683Z" fill="#DEDEDE"/>
<path d="M497.084 25.333C497.718 26.7697 499.218 28.0163 500.401 28.1008C501.584 28.1853 502.007 27.0866 501.352 25.6499C500.697 24.2131 499.218 22.9666 498.035 22.8821C496.873 22.8187 496.429 23.8962 497.084 25.333Z" fill="#DEDEDE"/>
<path d="M490.28 24.8472C490.914 26.2839 492.372 27.5305 493.577 27.615C494.76 27.6996 495.203 26.6009 494.57 25.1641C493.936 23.7274 492.457 22.5019 491.295 22.4174C490.09 22.3118 489.647 23.4105 490.28 24.8472Z" fill="#DEDEDE"/>
<path d="M483.456 24.3607C484.069 25.7975 485.526 27.0229 486.71 27.1075C487.893 27.192 488.358 26.0933 487.724 24.6565C487.09 23.2198 485.653 21.9944 484.47 21.9098C483.308 21.8253 482.843 22.924 483.456 24.3607Z" fill="#DEDEDE"/>
<path d="M435.705 21.3823C436.191 22.7979 437.565 24.0022 438.769 24.0656C439.952 24.129 440.501 23.0303 439.994 21.6358C439.487 20.2202 438.114 19.037 436.931 18.9736C435.769 18.9102 435.219 19.9878 435.705 21.3823Z" fill="#DEDEDE"/>
<path d="M428.882 21.0225C429.368 22.4381 430.72 23.6213 431.903 23.6847C433.087 23.7481 433.657 22.6494 433.171 21.2549C432.685 19.8393 431.333 18.6561 430.15 18.5927C428.966 18.5293 428.396 19.628 428.882 21.0225Z" fill="#DEDEDE"/>
<path d="M415.191 20.3465C415.635 21.741 416.966 22.9453 418.149 22.9876C419.332 23.051 419.945 21.9523 419.48 20.5578C419.016 19.1633 417.684 17.9801 416.522 17.9167C415.339 17.8745 414.748 18.952 415.191 20.3465Z" fill="#DEDEDE"/>
<path d="M408.345 20.0295C408.767 21.424 410.098 22.6072 411.282 22.6705C412.465 22.7339 413.078 21.6352 412.634 20.2408C412.19 18.8463 410.88 17.6631 409.697 17.6208C408.535 17.5574 407.922 18.635 408.345 20.0295Z" fill="#DEDEDE"/>
<path d="M401.499 19.7339C401.9 21.1284 403.21 22.3116 404.415 22.3538C405.598 22.4172 406.232 21.3185 405.809 19.9241C405.387 18.5296 404.077 17.3675 402.893 17.3041C401.71 17.2407 401.097 18.3394 401.499 19.7339Z" fill="#DEDEDE"/>
<path d="M146.668 19.4166C146.393 20.811 147.154 21.8886 148.358 21.8463C149.563 21.8041 150.746 20.6209 150.999 19.2475C151.253 17.853 150.492 16.7966 149.309 16.8389C148.126 16.8811 146.943 18.0221 146.668 19.4166Z" fill="#DEDEDE"/>
<path d="M139.759 19.6917C139.485 21.0861 140.224 22.1637 141.428 22.1214C142.633 22.0792 143.837 20.896 144.091 19.5226C144.365 18.1282 143.605 17.0506 142.422 17.114C141.238 17.1562 140.055 18.3183 139.759 19.6917Z" fill="#DEDEDE"/>
<path d="M132.85 20.0089C132.554 21.4034 133.273 22.481 134.477 22.4387C135.681 22.3753 136.886 21.2133 137.182 19.8188C137.477 18.4243 136.738 17.3467 135.555 17.4101C134.371 17.4524 133.167 18.6145 132.85 20.0089Z" fill="#DEDEDE"/>
<path d="M125.962 20.3256C125.645 21.7201 126.363 22.7976 127.567 22.7554C128.772 22.692 129.997 21.5299 130.314 20.1354C130.631 18.741 129.913 17.6634 128.708 17.7268C127.504 17.769 126.279 18.9311 125.962 20.3256Z" fill="#DEDEDE"/>
<path d="M119.053 20.6634C118.714 22.0579 119.412 23.1354 120.616 23.0931C121.82 23.0298 123.067 21.8466 123.384 20.4521C123.701 19.0576 123.004 17.98 121.82 18.0434C120.616 18.1068 119.391 19.2689 119.053 20.6634Z" fill="#DEDEDE"/>
<path d="M112.144 21.0232C111.785 22.4177 112.482 23.5164 113.687 23.453C114.891 23.3896 116.138 22.2064 116.497 20.8119C116.835 19.4175 116.159 18.3399 114.954 18.4033C113.75 18.4456 112.503 19.6076 112.144 21.0232Z" fill="#DEDEDE"/>
<path d="M105.234 21.382C104.854 22.7765 105.53 23.8752 106.735 23.8118C107.939 23.7484 109.207 22.5652 109.566 21.1496C109.925 19.7551 109.249 18.6776 108.066 18.7409C106.882 18.8043 105.615 19.9875 105.234 21.382Z" fill="#DEDEDE"/>
<path d="M98.326 21.7618C97.9245 23.1563 98.6007 24.2549 99.805 24.1704C101.009 24.107 102.298 22.9027 102.678 21.5082C103.059 20.1138 102.404 19.0362 101.199 19.0996C100.016 19.1841 98.7274 20.3673 98.326 21.7618Z" fill="#DEDEDE"/>
<path d="M91.4373 22.1639C91.0358 23.5584 91.6697 24.657 92.8741 24.5725C94.0784 24.5091 95.3672 23.3048 95.7687 21.9103C96.1701 20.5159 95.5151 19.4383 94.3319 19.5017C93.1276 19.5651 91.8387 20.7694 91.4373 22.1639Z" fill="#DEDEDE"/>
<path d="M84.5289 22.5864C84.1063 24.002 84.7402 25.0795 85.9445 24.995C87.1488 24.9316 88.4588 23.7273 88.8814 22.3117C89.3039 20.9172 88.6701 19.8397 87.4658 19.903C86.2614 19.9876 84.9515 21.1708 84.5289 22.5864Z" fill="#DEDEDE"/>
<path d="M77.6195 23.0088C77.1758 24.4244 77.7885 25.502 78.9929 25.4174C80.1972 25.3329 81.5283 24.1286 81.9508 22.7341C82.3734 21.3185 81.7607 20.2621 80.5775 20.3255C79.3943 20.41 78.0632 21.5932 77.6195 23.0088Z" fill="#DEDEDE"/>
<path d="M70.7318 23.4523C70.267 24.8679 70.8797 25.9454 72.0841 25.8609C73.2884 25.7764 74.6195 24.5721 75.0843 23.1565C75.528 21.7409 74.9364 20.6633 73.7321 20.7478C72.5277 20.8323 71.1967 22.0367 70.7318 23.4523Z" fill="#DEDEDE"/>
<path d="M63.823 23.9175C63.337 25.3331 63.9286 26.4106 65.1329 26.3472C66.3373 26.2627 67.6895 25.0584 68.1543 23.6428C68.6191 22.2272 68.0276 21.1496 66.8444 21.2341C65.6612 21.2975 64.3089 22.5019 63.823 23.9175Z" fill="#DEDEDE"/>
<path d="M56.9354 24.4034C56.4494 25.819 57.0199 26.9177 58.2242 26.8332C59.4286 26.7487 60.8019 25.5232 61.2667 24.1076C61.7527 22.692 61.1822 21.6145 59.9779 21.699C58.7947 21.7624 57.4214 22.9667 56.9354 24.4034Z" fill="#DEDEDE"/>
<path d="M50.0264 24.8893C49.5193 26.3049 50.0687 27.4036 51.273 27.3191C52.4773 27.2345 53.8507 26.0091 54.3578 24.5935C54.8648 23.1779 54.2944 22.1003 53.1112 22.1848C51.928 22.2482 50.5335 23.4737 50.0264 24.8893Z" fill="#DEDEDE"/>
<path d="M43.1379 25.3967C42.6097 26.8123 43.1591 27.911 44.3634 27.8265C45.5677 27.742 46.9622 26.4954 47.4693 25.0798C47.9764 23.6642 47.4481 22.5866 46.2438 22.6712C45.0606 22.7557 43.6661 23.9811 43.1379 25.3967Z" fill="#DEDEDE"/>
<path d="M36.2289 25.9246C35.6796 27.3613 36.2078 28.4389 37.4121 28.3543C38.6165 28.2698 40.0109 27.0233 40.5603 25.6076C41.0885 24.192 40.5603 23.1145 39.3771 23.199C38.1939 23.2624 36.7783 24.4878 36.2289 25.9246Z" fill="#DEDEDE"/>
<path d="M533.658 20.4313C534.397 21.8681 535.919 23.1358 537.102 23.2414C538.264 23.347 538.602 22.2484 537.862 20.7905C537.123 19.3538 535.58 18.1072 534.418 18.0015C533.277 17.8959 532.939 18.9734 533.658 20.4313Z" fill="#DEDEDE"/>
<path d="M526.96 19.8606C527.679 21.2974 529.2 22.5439 530.362 22.6496C531.524 22.7552 531.883 21.6565 531.165 20.2198C530.447 18.7831 528.904 17.5365 527.763 17.452C526.601 17.3463 526.263 18.4239 526.96 19.8606Z" fill="#DEDEDE"/>
<path d="M520.262 19.3114C520.959 20.7481 522.481 21.9947 523.643 22.0792C524.805 22.1849 525.185 21.0862 524.467 19.6494C523.748 18.2127 522.248 16.9872 521.086 16.8816C519.945 16.7971 519.565 17.8746 520.262 19.3114Z" fill="#DEDEDE"/>
<path d="M473.293 15.8884C473.885 17.2829 475.321 18.5083 476.484 18.5717C477.646 18.6562 478.11 17.5576 477.519 16.1631C476.906 14.7475 475.49 13.5643 474.328 13.4797C473.145 13.3952 472.702 14.4728 473.293 15.8884Z" fill="#DEDEDE"/>
<path d="M284.109 9.25411C284.214 10.6063 285.25 11.705 286.433 11.705C287.616 11.705 288.482 10.6275 288.356 9.27524C288.25 7.92301 287.194 6.84546 286.032 6.84546C284.869 6.84546 284.003 7.92301 284.109 9.25411Z" fill="#DEDEDE"/>
<path d="M270.523 9.23318C270.586 10.5854 271.6 11.6841 272.783 11.6841C273.967 11.6841 274.854 10.5854 274.791 9.2543C274.706 7.90208 273.692 6.82452 272.53 6.82452C271.368 6.8034 270.459 7.88095 270.523 9.23318Z" fill="#DEDEDE"/>
<path d="M263.741 9.23314C263.783 10.5854 264.776 11.6629 265.98 11.6629C267.163 11.6629 268.072 10.5642 268.009 9.21201C267.945 7.85978 266.952 6.78223 265.79 6.78223C264.607 6.80336 263.698 7.90204 263.741 9.23314Z" fill="#DEDEDE"/>
<path d="M161.775 11.1347C161.542 12.4869 162.324 13.5644 163.507 13.5222C164.691 13.4799 165.831 12.339 166.043 10.9868C166.254 9.63454 165.472 8.57811 164.31 8.62037C163.148 8.66262 162.007 9.78244 161.775 11.1347Z" fill="#DEDEDE"/>
<path d="M154.971 11.3883C154.718 12.7405 155.478 13.8181 156.682 13.7758C157.866 13.7336 159.028 12.5926 159.26 11.2404C159.493 9.88819 158.732 8.83177 157.549 8.87403C156.366 8.89516 155.225 10.0361 154.971 11.3883Z" fill="#DEDEDE"/>
<path d="M148.168 11.6626C147.894 13.0149 148.654 14.0924 149.838 14.0501C151.021 14.0079 152.183 12.867 152.436 11.4936C152.69 10.1414 151.929 9.08494 150.767 9.1272C149.605 9.16946 148.443 10.2893 148.168 11.6626Z" fill="#DEDEDE"/>
<path d="M141.364 11.9375C141.089 13.2898 141.807 14.3673 142.991 14.3251C144.174 14.2828 145.357 13.1207 145.632 11.7685C145.906 10.4163 145.167 9.35985 144.005 9.4021C142.822 9.44436 141.638 10.5642 141.364 11.9375Z" fill="#DEDEDE"/>
<path d="M134.561 12.2334C134.265 13.6068 134.984 14.6632 136.167 14.6209C137.35 14.5787 138.554 13.4166 138.829 12.0644C139.125 10.7122 138.407 9.65575 137.223 9.698C136.061 9.71913 134.857 10.8601 134.561 12.2334Z" fill="#DEDEDE"/>
<path d="M127.758 12.5292C127.441 13.9026 128.138 14.959 129.321 14.9168C130.504 14.8534 131.709 13.7124 132.026 12.3391C132.321 10.9657 131.624 9.90929 130.462 9.97268C129.279 10.0149 128.075 11.177 127.758 12.5292Z" fill="#DEDEDE"/>
<path d="M120.954 12.8459C120.616 14.2192 121.314 15.2757 122.497 15.2334C123.68 15.17 124.906 14.0079 125.222 12.6557C125.539 11.2824 124.863 10.2259 123.68 10.2893C122.518 10.3316 121.293 11.4937 120.954 12.8459Z" fill="#DEDEDE"/>
<path d="M114.152 13.1837C113.792 14.557 114.469 15.6134 115.652 15.5712C116.835 15.5078 118.082 14.3457 118.42 12.9724C118.758 11.599 118.081 10.5426 116.919 10.606C115.736 10.6694 114.49 11.8314 114.152 13.1837Z" fill="#DEDEDE"/>
<path d="M107.348 13.5433C106.968 14.9166 107.644 15.9942 108.827 15.9308C110.011 15.8674 111.257 14.7053 111.616 13.332C111.976 11.9586 111.321 10.9022 110.137 10.9656C108.954 11.0079 107.708 12.1699 107.348 13.5433Z" fill="#DEDEDE"/>
<path d="M100.544 13.9235C100.164 15.2969 100.797 16.3744 101.981 16.3111C103.164 16.2477 104.432 15.0645 104.812 13.6911C105.192 12.3178 104.537 11.2613 103.375 11.3247C102.192 11.3881 100.924 12.5502 100.544 13.9235Z" fill="#DEDEDE"/>
<path d="M93.7197 14.3039C93.3182 15.6773 93.9521 16.7548 95.1353 16.6914C96.3185 16.628 97.6073 15.4448 97.9876 14.0715C98.3891 12.6981 97.7552 11.6417 96.572 11.7051C95.41 11.7474 94.1422 12.9306 93.7197 14.3039Z" fill="#DEDEDE"/>
<path d="M86.9158 14.7057C86.4933 16.079 87.106 17.1566 88.2892 17.0932C89.4724 17.0298 90.7823 15.8466 91.1838 14.4521C91.5852 13.0788 90.9725 12.0223 89.8104 12.0857C88.6484 12.1491 87.3595 13.3112 86.9158 14.7057Z" fill="#DEDEDE"/>
<path d="M80.1139 15.1068C79.6702 16.5013 80.2829 17.5577 81.4661 17.4943C82.6493 17.4309 83.9592 16.2266 84.3818 14.8532C84.8044 13.4799 84.2128 12.4235 83.0296 12.4868C81.8675 12.5502 80.5576 13.7334 80.1139 15.1068Z" fill="#DEDEDE"/>
<path d="M73.3101 15.5503C72.8453 16.9448 73.4369 18.0013 74.6201 17.9379C75.8033 17.8534 77.1344 16.6702 77.5781 15.2757C78.0218 13.8812 77.4302 12.8248 76.2681 12.9093C75.106 12.9727 73.7749 14.1559 73.3101 15.5503Z" fill="#DEDEDE"/>
<path d="M52.8983 16.9238C52.3912 18.3183 52.9406 19.3958 54.1238 19.3113C55.307 19.2268 56.6803 18.0225 57.1663 16.628C57.6523 15.2335 57.1029 14.1771 55.9408 14.2616C54.7788 14.325 53.4265 15.5293 52.8983 16.9238Z" fill="#DEDEDE"/>
<path d="M46.118 17.41C45.5898 18.8045 46.118 19.882 47.3224 19.7975C48.5055 19.713 49.8789 18.5087 50.4071 17.0931C50.9142 15.6986 50.386 14.6421 49.2028 14.7267C48.0196 14.8112 46.6251 16.0155 46.118 17.41Z" fill="#DEDEDE"/>
<path d="M39.3138 17.917C38.7645 19.3115 39.2927 20.3891 40.4759 20.3046C41.6591 20.2201 43.0535 18.9946 43.5818 17.6001C44.11 16.2056 43.5818 15.1281 42.4197 15.2337C41.2365 15.2971 39.842 16.5014 39.3138 17.917Z" fill="#DEDEDE"/>
<path d="M503.064 10.2687C503.74 11.6632 505.198 12.8886 506.36 12.9731C507.522 13.0577 507.902 11.9801 507.226 10.5856C506.55 9.17001 505.071 7.98681 503.93 7.9023C502.789 7.79666 502.409 8.85309 503.064 10.2687Z" fill="#DEDEDE"/>
<path d="M469.935 8.00791C470.526 9.38126 471.942 10.5856 473.104 10.649C474.266 10.7335 474.71 9.65593 474.118 8.26145C473.527 6.86697 472.111 5.7049 470.97 5.62038C469.808 5.557 469.364 6.61343 469.935 8.00791Z" fill="#DEDEDE"/>
<path d="M456.686 7.18323C457.235 8.55658 458.63 9.73978 459.792 9.80317C460.954 9.86655 461.419 8.81013 460.848 7.43677C460.278 6.06342 458.883 4.90135 457.742 4.83797C456.601 4.77458 456.115 5.80988 456.686 7.18323Z" fill="#DEDEDE"/>
<path d="M423.472 5.40876C423.937 6.76099 425.268 7.92306 426.43 7.98645C427.592 8.04983 428.142 6.97228 427.656 5.62005C427.17 4.26783 425.839 3.12689 424.698 3.0635C423.557 3.00012 423.008 4.05654 423.472 5.40876Z" fill="#DEDEDE"/>
<path d="M416.817 5.09163C417.282 6.44386 418.592 7.5848 419.733 7.64819C420.895 7.71157 421.444 6.63402 420.979 5.28179C420.515 3.92956 419.205 2.78862 418.064 2.74637C416.923 2.70411 416.373 3.73941 416.817 5.09163Z" fill="#DEDEDE"/>
<path d="M410.183 4.79598C410.626 6.1482 411.915 7.28914 413.077 7.35253C414.239 7.41591 414.81 6.33836 414.366 4.98613C413.922 3.63391 412.612 2.49296 411.471 2.45071C410.309 2.38732 409.739 3.44375 410.183 4.79598Z" fill="#DEDEDE"/>
<path d="M403.528 4.50005C403.95 5.85228 405.239 6.99322 406.401 7.03548C407.563 7.07773 408.155 6.02131 407.711 4.66908C407.267 3.31686 406 2.19705 404.838 2.15479C403.697 2.11253 403.105 3.16896 403.528 4.50005Z" fill="#DEDEDE"/>
<path d="M396.871 4.22564C397.273 5.57787 398.54 6.69768 399.703 6.76107C400.865 6.80332 401.456 5.74689 401.055 4.39467C400.632 3.04244 399.364 1.92263 398.224 1.88038C397.083 1.83812 396.47 2.89454 396.871 4.22564Z" fill="#DEDEDE"/>
<path d="M383.561 3.73983C383.941 5.07092 385.167 6.21186 386.329 6.25412C387.491 6.29638 388.125 5.23995 387.744 3.88773C387.364 2.5355 386.118 1.43682 384.977 1.39456C383.836 1.3523 383.202 2.3876 383.561 3.73983Z" fill="#DEDEDE"/>
<path d="M376.906 3.50709C377.265 4.83819 378.49 5.958 379.652 6.00026C380.814 6.04252 381.448 4.98609 381.089 3.63386C380.73 2.30277 379.504 1.18296 378.342 1.1407C377.201 1.11957 376.547 2.15487 376.906 3.50709Z" fill="#DEDEDE"/>
<path d="M370.25 3.27478C370.588 4.60588 371.792 5.72569 372.954 5.76795C374.117 5.81021 374.771 4.75378 374.433 3.40156C374.095 2.07046 372.87 0.950645 371.729 0.929517C370.567 0.908388 369.912 1.94369 370.25 3.27478Z" fill="#DEDEDE"/>
<path d="M363.594 3.08453C363.911 4.41562 365.116 5.53544 366.278 5.57769C367.44 5.61995 368.116 4.56353 367.778 3.2113C367.44 1.8802 366.257 0.781519 365.094 0.739262C363.953 0.697005 363.277 1.75343 363.594 3.08453Z" fill="#DEDEDE"/>
<path d="M316.901 2.04969C317.091 3.38078 318.19 4.45834 319.352 4.47947C320.514 4.5006 321.296 3.42304 321.084 2.11307C320.873 0.781975 319.774 -0.295581 318.634 -0.295581C317.493 -0.316709 316.711 0.71859 316.901 2.04969Z" fill="#DEDEDE"/>
<path d="M303.567 1.88025C303.715 3.19022 304.793 4.2889 305.955 4.31003C307.117 4.33116 307.92 3.2536 307.751 1.94363C307.582 0.633664 306.504 -0.443892 305.363 -0.465021C304.201 -0.486149 303.398 0.570279 303.567 1.88025Z" fill="#DEDEDE"/>
<path d="M290.194 1.77442C290.321 3.08439 291.356 4.18307 292.518 4.18307C293.68 4.18307 294.526 3.12665 294.378 1.79555C294.251 0.485581 293.194 -0.591974 292.053 -0.591974C290.913 -0.613102 290.089 0.443324 290.194 1.77442Z" fill="#DEDEDE"/>
<path d="M270.164 1.71125C270.227 3.02122 271.22 4.09878 272.382 4.09878C273.545 4.09878 274.432 3.02122 274.347 1.71125C274.263 0.401286 273.27 -0.655141 272.129 -0.67627C270.967 -0.67627 270.101 0.401286 270.164 1.71125Z" fill="#DEDEDE"/>
<path d="M183.157 2.9364C182.988 4.2675 183.791 5.32392 184.953 5.28167C186.115 5.23941 187.192 4.14073 187.361 2.83076C187.53 1.49966 186.706 0.464363 185.565 0.50662C184.403 0.50662 183.326 1.6053 183.157 2.9364Z" fill="#DEDEDE"/>
<path d="M176.459 3.12696C176.269 4.45806 177.051 5.51449 178.234 5.47223C179.396 5.42997 180.495 4.33129 180.664 3.00019C180.833 1.66909 180.051 0.633796 178.889 0.676053C177.748 0.697182 176.649 1.79587 176.459 3.12696Z" fill="#DEDEDE"/>
<path d="M163.063 3.54921C162.831 4.88031 163.591 5.93674 164.775 5.89448C165.937 5.85222 167.057 4.73241 167.289 3.40132C167.5 2.07022 166.74 1.03492 165.577 1.07718C164.415 1.11943 163.296 2.21812 163.063 3.54921Z" fill="#DEDEDE"/>
<path d="M136.252 4.5845C135.956 5.9156 136.653 6.97203 137.837 6.92977C138.999 6.88751 140.182 5.74657 140.478 4.41548C140.773 3.08438 140.055 2.04908 138.893 2.09134C137.731 2.13359 136.548 3.25341 136.252 4.5845Z" fill="#DEDEDE"/>
<path d="M129.554 4.88065C129.237 6.21174 129.934 7.26817 131.096 7.22591C132.259 7.18366 133.463 6.04272 133.759 4.71162C134.054 3.38052 133.378 2.34522 132.216 2.38748C131.054 2.42974 129.85 3.54955 129.554 4.88065Z" fill="#DEDEDE"/>
<path d="M122.835 5.19742C122.497 6.54965 123.173 7.58495 124.356 7.54269C125.518 7.4793 126.744 6.35949 127.061 5.00727C127.378 3.67617 126.702 2.61974 125.54 2.68313C124.377 2.72539 123.173 3.8452 122.835 5.19742Z" fill="#DEDEDE"/>
<path d="M116.137 5.51462C115.777 6.86684 116.454 7.90214 117.616 7.85988C118.778 7.79649 120.003 6.65556 120.341 5.32446C120.679 3.97223 120.024 2.93693 118.862 3.00032C117.7 3.04258 116.475 4.18352 116.137 5.51462Z" fill="#DEDEDE"/>
<path d="M109.418 5.8524C109.059 7.20462 109.693 8.23992 110.876 8.19766C112.038 8.13427 113.285 6.99334 113.644 5.64111C114.003 4.28888 113.348 3.25359 112.186 3.31697C111.024 3.38036 109.798 4.50017 109.418 5.8524Z" fill="#DEDEDE"/>
<path d="M102.72 6.21128C102.34 7.56351 102.974 8.61993 104.136 8.55655C105.298 8.49316 106.566 7.35222 106.925 6C107.305 4.64777 106.671 3.61247 105.509 3.67586C104.368 3.71811 103.101 4.85906 102.72 6.21128Z" fill="#DEDEDE"/>
<path d="M89.3037 6.95116C88.8812 8.30339 89.4939 9.35981 90.6559 9.29642C91.818 9.23304 93.1069 8.07097 93.5083 6.71875C93.9097 5.36652 93.3181 4.33122 92.156 4.39461C91.0151 4.45799 89.7263 5.59894 89.3037 6.95116Z" fill="#DEDEDE"/>
<path d="M82.6063 7.35266C82.1626 8.70488 82.7542 9.76131 83.9374 9.69792C85.0995 9.63454 86.4094 8.47247 86.832 7.09912C87.2546 5.74689 86.663 4.71159 85.5009 4.77498C84.3389 4.83836 83.0289 6.00043 82.6063 7.35266Z" fill="#DEDEDE"/>
    </svg>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.mapbox{
    min-height: 350px
}
.dot, img{
  z-index: 10
}
/* .dot1{
  top: 60%;
  left: 40%;
} */
.group{
  z-index: 10
}
.group1{
  top: 40%;
  left: 34%;
}
.pin1{
  animation-name: firstpin;
  animation-duration: 5s;
  animation-iteration-count: infinite;
}

.group2{
  top: 10%;
  right: 20%
}
.pin2{
  animation-name: secondpin;
  animation-duration: 5s;
  animation-iteration-count: infinite;
}
.group3{
  top: -5%;
  left: 10%
}
.pin3{
  animation-name: thirdpin;
  animation-duration: 5s;
  animation-iteration-count: infinite;
}
@keyframes firstpin {
  0%{
    opacity: 0;
    transform: translateY(10px);
  }
  40%{
    opacity: 1;
    transform: translateY(0);
  }
  60%{
    opacity: 0;
    transform: translateY(10px);
  }
  100%{
    opacity: 0;
    transform: translateY(10px);
  }
}
@keyframes secondpin {
  0%{
    opacity: 0;
    transform: translateY(10px);
  }
  40%{
    opacity: 0;
    transform: translateY(10px);
  }
  60%{
    opacity: 1;
    transform: translateY(0);
  }
  80%{
    opacity: 0;
    transform: translateY(10px);
  }
  100%{
    opacity: 0;
    transform: translateY(10px);
  }
}
@keyframes thirdpin {
  0%{
    opacity: 0;
    transform: translateY(10px);
  }
  40%{
    opacity: 0;
    transform: translateY(10px);
  }
  60%{
    opacity: 0;
    transform: translateY(10px);
  }
  80%{
    opacity: 1;
    transform: translateY(0);
  }
  100%{
    opacity: 0;
    transform: translateY(10px);
  }
}
@media only screen and (min-width: 768px){
  .group1{
    top: 55%;
    left: 37%;
  }
}
@media only screen and (min-width: 1024px){
  .dot1{
    top: 70%
  }
  .pin1{
    top: 50%;
    left: 36.5%
  }
  .pin2{
    right: 16.5%;
    top: 1%
  }
  .pin3{
    left: 6.5%
  }
}
</style>