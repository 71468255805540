<template>
  <ul class="mt-8 grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-4 items-center px-6">
      <li>
            <div class="">
                <img class="w-full" src="@/assets/images/landingpage/partners/aiico.png" alt="">
            </div>
      </li>  
      <li>
            <div class="">
                <img class="w-full" src="@/assets/images/landingpage/partners/chi.png" alt="">
            </div>
      </li>  
      <li>
            <div class="">
                <img class="w-full" src="@/assets/images/landingpage/partners/FBNGI.png" alt="">
            </div>
      </li>  
      <li>
            <div class="">
                <img class="w-full" src="@/assets/images/landingpage/partners/vfd.jpg" alt="">
            </div>
      </li>  
      <li>
            <div class="">
                <img class="w-full" src="@/assets/images/landingpage/partners/tizetti.jpg" alt="">
            </div>
      </li>  
      <li>
            <div class="">
                <img class="w-full" src="@/assets/images/landingpage/partners/reliance.png" alt="">
            </div>
      </li>  
      <li>
            <div class="">
                <img class="w-full" src="@/assets/images/landingpage/partners/mayfair2.png" alt="">
            </div>
      </li>  
      <li>
            <div class="">
                <img class="w-full" src="@/assets/images/landingpage/partners/enterprise.png" alt="">
            </div>
      </li>  
      <li>
            <div class="">
                <img class="w-full" src="@/assets/images/landingpage/partners/axa.png" alt="">
            </div>
      </li>  
      <li>
            <div class="">
                <img class="w-full" src="@/assets/images/landingpage/partners/custodian.png" alt="">
            </div>
      </li>  
  </ul>
</template>

<script>
export default {

}
</script>

<style scoped>
li div{
  max-width: 100px;
  max-height: 100px  
}
</style>